import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  VStack,
  Text,
  HStack,
  Pressable,
  Center,
  Switch,
} from "native-base";
import { getCountry, getState } from "@helpers/get_country_state_city";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  useDynamicSelector,
  gel_all_idle_members,
  gel_all_idle_members_reports,
} from "@services/redux";
import {
  Button,
  Checkbox,
  Image,
  Pagination,
  Popconfirm,
  Space,
  Table,
} from "antd";
import { showNotification } from "@helpers/notify";
import { IMAGE_URL } from "@helpers/constants";
import { getImageUrl, getProfileUrl } from "@helpers/get_profile_url";
import { CalculateAge, CalculateAgeNumber } from "@helpers/calculate_age";
import { useParams } from "react-router-dom";
import { navigateBack } from "@helpers/navigator";
import moment from "moment";
import { useRef } from "react";
import { debounce } from "lodash";
import { BiUpArrowAlt } from "react-icons/bi";
import { generateExcelFromJSON } from "@helpers/functions";

const AdminTwoMonthsNotUsedProfiles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const top_ref = useRef();

  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [report_data, setReportData] = useState([]);

  const {
    items: reports,
    loading,
    pagination,
  } = useDynamicSelector("get_all_idle_members");
  const {
    items: get_all_idle_members_reports_items,
    loading: get_all_idle_members_reports_loading,
  } = useDynamicSelector("get_all_idle_members_reports");
  useEffect(() => {
    let keys = [{ key: "get_all_idle_members", loading: true }];
    dispatch(
      dynamicRequest(keys, gel_all_idle_members, {
        page_number: current,
        page_limit: current_limit,
      })
    );
  }, []);

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);
  const goToTop = () => {
    debouncedHandleTitleClick();
  };
  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_idle_members", loading: true }];
    dispatch(
      dynamicRequest(keys, gel_all_idle_members, {
        page_number: page_number,
        page_limit: page_limit,
      })
    );
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };
  const getReports = () => {
    let keys = [{ key: "get_all_idle_members_reports", loading: true }];
    dispatch(
      dynamicRequest(keys, gel_all_idle_members_reports, {
        page_number: 1,
        page_limit: 1000000,
      })
    );
  };

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
      width: 150,
    },
    {
      title: "Profile Number",
      dataIndex: "profile_id",
      align: "left",
      key: "profile_id",
      width: 150,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      align: "left",
      key: "gender",
      width: 100,

      render: (record, i) => {
        return (
          <>
            <Text>{record === "m" ? "M" : "F"}</Text>
          </>
        );
      },
    },
    {
      title: "Age",
      dataIndex: "dob",
      align: "left",
      key: "dob",
      width: 80,
      render: (record) => {
        return (
          <>
            <Text>{CalculateAgeNumber(record)}</Text>
          </>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      align: "left",
      width: 150,
      key: "mobile",
    },
    {
      title: "Location",
      dataIndex: "",
      align: "left",
      width: 150,
      key: "location",
      render: (record, i) => {
        let country = record.country;
        return (
          <>
            <Text>
              {record.country ? <>{getCountry(record.country)},</> : null}
              {record.state ? <>{getState(record.state, country)},</> : null}
              {record.district ? <>{record.district}.</> : null}
            </Text>
          </>
        );
      },
    },
    {
      title: "Franchise Name",
      dataIndex: "franchise_name",
      align: "left",
      width: 150,
      key: "name",
    },
    {
      title: "Created On",
      dataIndex: "",
      align: "left",
      width: 150,
      key: "name",
      render: (record) => {
        return (
          <Text>
            {moment(record?.created_datetime).format("DD/MM/YYYY h:mm a")}
          </Text>
        );
      },
    },
    {
      title: "Last Login",
      dataIndex: "",
      width: 250,
      align: "left",
      key: "last_login_datetime",
      render: (record) => {
        return (
          <Box width={150}>
            <Text
              style={{
                width: 150,
              }}
            >
              {moment(record?.last_login_datetime).format("DD-MM-YYYY, h:mm a")}
            </Text>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (get_all_idle_members_reports_items?.length > 0) {
      setReportData(get_all_idle_members_reports_items);
    }
  }, [get_all_idle_members_reports_items]);

  useEffect(() => {
    getReports();
  }, []);
  const fieldMappings = [
    {
      label: "Name",
      field: "name",
    },
    {
      label: "Profile Number",
      field: "profile_id",
    },

    {
      label: "Mobile",
      field: "mobile",
    },
    {
      label: "Created At",
      field: "created_datetime",
    },
    {
      label: "Date of Birth",
      field: "dob",
    },
    {
      label: "Gender",
      field: "gender",
    },
    {
      label: "Franchise",
      field: "franchise_name",
    },
    {
      label: "District",
      field: "district",
    },
  ];

  return (
    <Box ref={top_ref} width="100%" mt="20px" marginBottom="20px">
      <Text fontSize="md" fontWeight="bold">
        {`${t("two_months_not_used_members")} (${
          pagination?.total_count ? pagination?.total_count : 0
        })`}
      </Text>
      <Box my={5} width={"150px"}>
        <Button
          loading={get_all_idle_members_reports_loading}
          colorScheme={"danger.700"}
          onClick={() => {
            generateExcelFromJSON(
              report_data,
              "IdleMembers.xlsx",
              fieldMappings
            );
          }}
          style={{
            height: "50px",
            label: t("table:back"),
            variant: "outline",
          }}
        >
          {get_all_idle_members_reports_loading ? "Wait..." : "Download Report"}
        </Button>
      </Box>
      <Box my={5} width={"80px"}>
        <Button
          colorScheme={"danger.700"}
          onClick={() => {
            navigateBack();
          }}
          style={{
            label: t("table:back"),
            variant: "outline",
          }}
        >
          Back
        </Button>
      </Box>
      <VStack space={5}>
        <Wrapper>
          <Table
            columns={columns}
            pagination={false}
            loading={loading}
            dataSource={reports}
            scroll={{
              x: 2000,
              y: 1000,
            }}
          />
        </Wrapper>
        <HStack alignItems={"center"}>
          <Box width={"70%"}>
            <Pagination
              showSizeChanger={true}
              current={current}
              hideOnSinglePage={false}
              onChange={fetchData}
              total={pagination?.total_count}
              pageSizeOptions={[5, 10, 15, 20, 50, 100, 1000]}
            />
          </Box>
          <Box alignItems={"flex-end"} my={5} width={"30%"}>
            <Button
              colorScheme={"danger.700"}
              onClick={() => {
                goToTop();
              }}
              style={{
                label: t("table:back"),
                variant: "outline",
              }}
            >
              <HStack width={"100%"} alignItems={"center"}>
                <BiUpArrowAlt size={20} />
                <Text>{"Go to Top"}</Text>
              </HStack>
            </Button>
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};

export default AdminTwoMonthsNotUsedProfiles;

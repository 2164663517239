import {
  Box,
  Card,
  Divider,
  Hidden,
  HStack,
  Skeleton,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input } from "antd";
import "../../../index.css";

import { useDispatch } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { CountriesOptions } from "@master_data/common_json/countries";
import {
  getDistrictsList,
  getOneCountry,
  getOneState,
  getStatesList,
} from "@helpers/functions";
import _ from "lodash";
import { useDynamicSelector } from "@services/redux";
import { StatesOptions } from "@master_data/common_json/states";

const UserPreferenceFormLocationDetails = (props) => {
  const { getOptions, form } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selected_countries = Form.useWatch("countries", form);
  const selected_states = Form.useWatch("states", form);
  const selected_districts = Form.useWatch("districts", form);
  const [countries_loading, setCountriesLoading] = useState(false);
  const [states_loading, setStatesLoading] = useState(false);
  const [districts_loading, setDistrictsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const { loading: my_preference_loading } =
    useDynamicSelector("my_preference");
  useEffect(() => {
    setCountriesLoading(true);
    let _countries = [];
    try {
      _countries = CountriesOptions;
      _countries = _countries?.map((x) => ({
        name: x.name,
        value: _.snakeCase(x.i_c),
      }));
      _countries = [{ name: "Any", value: "any" }, ..._countries];
    } catch (e) {}

    setCountries(_countries);
    setCountriesLoading(false);
  }, []);

  useEffect(() => {
    setStatesLoading(true);
    let grouped_states = [];
    let _states = [];
    let _country = [];

    if (selected_countries) {
      for (let i = 0; i < selected_countries.length; i++) {
        let selected_country = _.snakeCase(selected_countries[i]);
        if (selected_country === "any") {
          _states = [{ label: "Any", value: "any" }];

          grouped_states.push({
            label: "States",
            options: _states,
          });
        } else {
          _country = getOneCountry(selected_country);
          _states = getStatesList(selected_country);
          _states = _states.map((obj) => {
            return { label: obj.name, value: obj.i_c };
          });
          _states = [{ label: "Any", value: "any" }, ..._states];
          grouped_states.push({
            label: _country?.name,
            options: _states,
          });
        }
      }
    }

    setStates(grouped_states);
    let new_selected_states = [];
    if (selected_countries && selected_states) {
      for (let i = 0; i < selected_countries.length; i++) {
        let selected_country = _.snakeCase(selected_countries[i]);

        for (let j = 0; j < selected_states.length; j++) {
          let selected_state = _.snakeCase(selected_states[j]);
          if (selected_state === "any") {
            new_selected_states.push("any");
          } else {
            let _selected_state = getOneState(selected_state, selected_country);

            if (
              _selected_state &&
              new_selected_states.indexOf(_.snakeCase(_selected_state.i_c)) ===
                -1
            ) {
              new_selected_states.push(_.snakeCase(_selected_state.i_c));
            }
          }
        }
      }
    }
    form.setFields([
      {
        name: "states",
        value: new_selected_states,
      },
    ]);
    setStatesLoading(false);
  }, [selected_countries, StatesOptions]);

  useEffect(() => {
    setDistrictsLoading(true);
    let grouped_districts = [];
    let _districts = [];
    if (selected_countries && selected_states) {
      for (let i = 0; i < selected_countries.length; i++) {
        let selected_country = _.snakeCase(selected_countries[i]);
        for (let j = 0; j < selected_states.length; j++) {
          let selected_state = _.snakeCase(selected_states[j]);

          let _state = getOneState(
            _.snakeCase(selected_state),
            _.snakeCase(selected_country)
          );
          if (_state) {
            _districts = getDistrictsList(
              _.snakeCase(selected_country),
              _.snakeCase(selected_state)
            );

            if (_districts) {
              _districts = _districts.map((obj) => {
                return { label: obj.name, value: obj.value };
              });
              _districts = [{ label: "Any", value: "any" }, ..._districts];
              if (!grouped_districts.find((x) => x.label === _state.name)) {
                grouped_districts.push({
                  label: _state?.name,
                  options: _districts,
                });
              }
            }
          } else {
            _districts = [{ label: "Any", value: "any" }];
            grouped_districts.push({
              label: "Districts",
              options: _districts,
            });
          }
        }
      }
    }

    // if (selected_countries && selected_states) {
    //   try {
    //     _cities = City.getCitiesOfState(selected_countries, selected_states);
    //     _cities.sort((a, b) =>
    //       a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    //     );
    //   } catch (e) {}
    // }
    setDistricts(grouped_districts);

    let new_selected_districts = [];
    if (selected_countries && selected_states && selected_districts) {
      for (let i = 0; i < selected_countries.length; i++) {
        let selected_country = _.snakeCase(selected_countries[i]);
        for (let j = 0; j < selected_states.length; j++) {
          let selected_state = _.snakeCase(selected_states[j]);

          if (selected_state === "any") {
            new_selected_districts.push("any");
          } else {
            for (let k = 0; k < selected_districts.length; k++) {
              let selected_city = selected_districts[k];
              let districts = getDistrictsList(
                _.snakeCase(selected_country),
                _.snakeCase(selected_state)
              );

              if (
                districts &&
                districts.find((x) => x.value === selected_city)
              ) {
                new_selected_districts.push(
                  districts.find((x) => x.value === selected_city).value
                );
              }
            }
          }
        }
      }
    }

    form.setFields([
      {
        name: "districts",
        value: new_selected_districts,
      },
    ]);
    setDistrictsLoading(false);
  }, [selected_states]);

  const handleCountriesValuesChange = (values) => {
    let any_id = "any";
    let filtered_ids = values;
    if (values && values.length > 0 && values[values.length - 1] === any_id) {
      filtered_ids = filtered_ids?.filter((id) => id === any_id);
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => id !== any_id);
    }
    form.setFieldsValue({
      countries: filtered_ids,
      states: null,
      districts: null,
    });
  };

  const handleStatesValuesChange = (values) => {
    let any_id = "any";
    let filtered_ids = values;
    if (values && values.length > 0 && values[values.length - 1] === any_id) {
      filtered_ids = filtered_ids?.filter((id) => id === any_id);
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => id !== any_id);
    }

    form.setFieldsValue({
      states: filtered_ids,
      districts: null,
    });
  };

  const handleDistrictsValuesChange = (values) => {
    let any_id = "any";
    let filtered_ids = values;
    if (values && values.length > 0 && values[values.length - 1] === any_id) {
      filtered_ids = filtered_ids?.filter((id) => id === any_id);
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => id !== any_id);
    }
    form.setFieldsValue({
      districts: filtered_ids,
    });
  };

  return (
    <>
      {my_preference_loading ? (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("location_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <Hidden from="md">
            <Box width={"100%"}>
              <Skeleton.Text lines={5} />
            </Box>
          </Hidden>
          <Hidden till="md">
            <HStack space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
          </Hidden>
        </Card>
      ) : (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={14} bold color={"#712e5a"}>
              {t("location_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <VForm.Select
            label={t("country")}
            field={"countries"}
            loading={countries_loading}
            showSearch={true}
            options={countries}
            labelField={"name"}
            valueField={"value"}
            isMulti
            onChange={handleCountriesValuesChange}
          />
          {!selected_countries?.includes("any") && (
            <VForm.FormGroupSelect
              label={t("state")}
              field={"states"}
              loading={states_loading}
              showSearch={true}
              options={states}
              labelField={"label"}
              valueField={"value"}
              isMulti
              onChange={handleStatesValuesChange}
            />
          )}
          {!selected_countries?.includes("any") &&
            !selected_states?.includes("any") && (
              <VForm.FormGroupSelect
                label={t("district")}
                field={"districts"}
                loading={districts_loading}
                showSearch={true}
                options={districts}
                labelField={"label"}
                valueField={"value"}
                isMulti
                onChange={handleDistrictsValuesChange}
              />
            )}
        </Card>
      )}
    </>
  );
};
export default UserPreferenceFormLocationDetails;

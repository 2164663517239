export const ReligionsOptions = [
  {
    value: "any",
    p_o: [],
    en_label: "Any",
    ta_label: "ஏதேனும்",
  },
  {
    value: "hindu",
    p_o: [],
    en_label: "Hindu",
    ta_label: "இந்து",
  },
  {
    value: "christian",
    p_o: [],
    en_label: "Christian",
    ta_label: "கிறிஸ்தவம்",
  },
  {
    value: "muslim",
    p_o: [],
    en_label: "Muslim",
    ta_label: "முஸ்லிம்",
  },
  {
    value: "inter_religion",
    p_o: [],
    en_label: "Inter religion",
    ta_label: null,
  },
];

import React from "react";
import { Box, Center, Text, HStack, Divider, Hidden } from "native-base";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillHeart,
} from "react-icons/ai";
import { useTranslation } from "react-i18next";
import {
  getCity,
  getCountry,
  getDistrict,
  getState,
} from "@helpers/get_country_state_city";
import { calculateAgeForCompare } from "@helpers/calculate_age";
import {
  getOneState,
  getStatesList,
  getUserDetailsFromValue,
} from "@helpers/functions";
import _ from "lodash";
import { AgeFromOptions } from "@master_data/preference_json/age_from";
import { AgeToOptions } from "@master_data/preference_json/age_to";
import { AnnualIncomeFromOptions } from "@master_data/preference_json/annual_income_from";
import { AnnualIncomeToOptions } from "@master_data/preference_json/annual_income_to";
const PreferenceComparison = (props) => {
  const {
    preference_matches,
    _loginUserDetails,
    key_value,
    key_value_id,
    title,
    icon,
    options,
  } = props;
  const { t } = useTranslation();

  let new_selected_states = [];
  if (preference_matches.countries && preference_matches.states) {
    for (let i = 0; i < preference_matches.countries.length; i++) {
      let selected_country = preference_matches.countries[i];
      for (let j = 0; j < preference_matches.states.length; j++) {
        let selected_state = preference_matches.states[j];

        let _selected_state = getOneState(
          _.snakeCase(selected_state),
          _.snakeCase(selected_country)
        );

        if (
          _selected_state &&
          new_selected_states.indexOf(_selected_state.i_c) === -1
        ) {
          new_selected_states.push(_selected_state.name);
        }
      }
    }
  }
  return (
    <>
      <HStack>
        <Box marginLeft={5} py={2} width={"30px"}>
          {icon}
          {/* <AiFillCheckCircle size={18} color={"green"} /> */}
        </Box>
        <Box flex={1}>
          <Box>
            <Text py={2} bold>
              {t(`${title}`)}
            </Text>
          </Box>
          <Box>
            <HStack flexWrap="wrap" width={"100%"}>
              {key_value === "age" && (
                <Text fontFamily={"Montserrat-Regular"} py={2}>
                  {`${getUserDetailsFromValue(
                    AgeFromOptions,
                    preference_matches?.age_from
                  )} - ${getUserDetailsFromValue(
                    AgeToOptions,
                    preference_matches?.age_to
                  )}`}
                </Text>
              )}
              {key_value === "annual_income" && (
                <Text fontFamily={"Montserrat-Regular"} py={2}>
                  {`${getUserDetailsFromValue(
                    AnnualIncomeFromOptions,
                    preference_matches?.annual_income_from
                  )} - ${getUserDetailsFromValue(
                    AnnualIncomeToOptions,
                    preference_matches?.annual_income_to
                  )}`}
                </Text>
              )}
              {key_value === "countries" &&
                preference_matches?.[key_value]?.map((x, i) => {
                  return (
                    <Text key={i} py={2}>
                      {`${getCountry(_.snakeCase(x))}${
                        i === preference_matches?.[key_value]?.length - 1
                          ? ""
                          : ", "
                      }`}
                    </Text>
                  );
                })}
              {key_value === "states" &&
                new_selected_states?.map((x, i) => {
                  return (
                    <Text key={i} py={2}>
                      {`${x}${
                        i === new_selected_states?.length - 1 ? "" : ", "
                      }`}
                    </Text>
                  );
                })}
              {key_value === "districts" &&
                preference_matches?.[key_value]?.map((x, i) => {
                  return (
                    <Text key={i} py={2}>
                      {`${getDistrict(_.snakeCase(x))}${
                        i === preference_matches?.[key_value]?.length - 1
                          ? ""
                          : ", "
                      }`}
                    </Text>
                  );
                })}

              {key_value !== "districts" &&
                key_value !== "states" &&
                key_value !== "countries" &&
                key_value !== "age" &&
                key_value !== "annual_income" &&
                preference_matches?.[key_value]?.map((x, i) => {
                  return (
                    <Text key={i} py={2}>
                      {`${getUserDetailsFromValue(options, x)}${
                        i === preference_matches?.[key_value]?.length - 1
                          ? ""
                          : ", "
                      }`}
                    </Text>
                  );
                })}
            </HStack>
          </Box>
        </Box>
        {key_value === "countries" && (
          <Center mx={5} width={"50px"}>
            {preference_matches?.[key_value]?.filter((p) => {
              return _loginUserDetails?.[key_value_id] === _.snakeCase(p);
            }).length || preference_matches?.[key_value]?.[0] === "any" ? (
              <AiFillHeart size={25} color={"#712e5a"} />
            ) : (
              <AiFillCloseCircle size={25} color={"grey"} />
            )}
          </Center>
        )}
        {key_value === "states" && (
          <Center mx={5} width={"50px"}>
            {preference_matches?.[key_value]?.filter((p) => {
              return _loginUserDetails?.[key_value_id] === _.snakeCase(p);
            }).length || preference_matches?.[key_value]?.[0] === "any" ? (
              <AiFillHeart size={25} color={"#712e5a"} />
            ) : (
              <AiFillCloseCircle size={25} color={"grey"} />
            )}
          </Center>
        )}
        {key_value === "age" && (
          <Center mx={5} width={"50px"}>
            {preference_matches?.age_from <
            calculateAgeForCompare(_loginUserDetails?.dob) <
            preference_matches?.age_to ? (
              <AiFillHeart size={25} color={"#712e5a"} />
            ) : (
              <AiFillCloseCircle size={25} color={"grey"} />
            )}
          </Center>
        )}

        {key_value === "annual_income" && (
          <Center mx={5} width={"50px"}>
            {Number(_loginUserDetails?.annual_income_from) !== -99 &&
            Number(preference_matches?.annual_income_from) <=
              Number(_loginUserDetails?.annual_income_from) &&
            Number(_loginUserDetails?.annual_income_from) <=
              Number(preference_matches?.annual_income_to) ? (
              <AiFillHeart size={25} color={"#712e5a"} />
            ) : (
              <AiFillCloseCircle size={25} color={"grey"} />
            )}
          </Center>
        )}
        {key_value === "districts" && (
          <Center mx={5} width={"50px"}>
            {preference_matches?.[key_value]?.filter((p) => {
              return _loginUserDetails?.[key_value_id] === _.snakeCase(p);
            }).length || preference_matches?.[key_value]?.[0] === "any" ? (
              <AiFillHeart size={25} color={"#712e5a"} />
            ) : (
              <AiFillCloseCircle size={25} color={"grey"} />
            )}
          </Center>
        )}

        {key_value !== "countries" &&
          key_value !== "states" &&
          key_value !== "districts" &&
          key_value !== "age" &&
          key_value !== "annual_income" && (
            <Center mx={5} width={"50px"}>
              {preference_matches?.[key_value]?.filter((p) => {
                return _loginUserDetails?.[key_value_id] === p;
              }).length || preference_matches?.[key_value]?.[0] === "any" ? (
                <AiFillHeart size={25} color={"#712e5a"} />
              ) : (
                <AiFillCloseCircle size={25} color={"grey"} />
              )}
            </Center>
          )}
      </HStack>
      <Divider />
    </>
  );
};

export default PreferenceComparison;

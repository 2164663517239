import { Box, Card, Divider, Pressable, Text } from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input, Spin } from "antd";
import "../../../index.css";
import {
  dynamicClear,
  dynamicRequest,
  getMyProfile,
  updateUserProfile,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import UserProfileFormFamilyDetails from "./user_profile_family_details";
import UserProfileFormReligionDetails from "./user_profile_religion_details";
import UserProfileFormBasicDetails from "./user_profile_basic_details";
import UserProfileFormLocationDetails from "./user_profile_location_details";
import UserProfileFormProfessionalDetails from "./user_profile_professional_details";
import UserPhotos from "./user_photos";
import toast from "react-hot-toast";
import UserProfileFormHoroscopeDetails from "./user_profile_horoscope_details";
import moment from "moment";
import { navigate, navigateBack } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
// import UserProfileFormHoroscopeDetails from "./user_profile_horoscope_details";
import { AnnualIncomeOptions } from "@master_data/profile_json/annual_income";
import { getAnnualIncomeOptions } from "@helpers/functions";

const UserProfileForm = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [initial_values, setInitialValues] = useState({});
  const {
    user_profile: my_profile_details,
    user_horoscope: my_horoscope_details,
  } = useDynamicSelector("my_profile");
  console.log("my_profile_details", my_profile_details);

  const {
    status: updateProfileStatus,
    error: update_profile_error,
    loading: update_profile_loading,
  } = useDynamicSelector("updateUserProfile");

  useEffect(() => {
    let get_my_user_key = [{ key: "my_profile", loading: true }];
    let get_my_user_query = getMyProfile;
    let get_my_user_variables = {};
    dispatch(
      dynamicRequest(get_my_user_key, get_my_user_query, get_my_user_variables)
    );
  }, []);

  useEffect(() => {
    if (updateProfileStatus === "success") {
      toast.success(t("profile_updated_successfully"));
      navigate(ROUTES.USER_SETTINGS);

      dispatch(dynamicClear("updateUserProfile"));
    } else if (update_profile_error) {
      toast.error(update_profile_error?.message);
      dispatch(dynamicClear("updateUserProfile"));
    }
  }, [updateProfileStatus, update_profile_error]);
  const getAnnualIncomeRange = (value) => {
    let range = [];
    let income_data = AnnualIncomeOptions?.find((x) => x.en_label === value);
    range.push({
      r_f_v: income_data?.r_f_v,
      r_t_v: income_data?.r_t_v,
    });
    return range;
  };

  const handleSubmit = async (values) => {
    console.log("values", values);
    delete values.created_for;
    delete values.name;
    delete values.gender;
    delete values.dob;
    values = {
      ...values,
      annual_income_from: getAnnualIncomeRange(values?.annual_income)?.[0]
        ?.r_f_v,
      annual_income_to: getAnnualIncomeRange(values?.annual_income)?.[0]?.r_t_v,
      doshams: values?.doshams?.map((x) => x),
      educations: values?.educations?.map((x) => x),
      sub_educations: values?.sub_educations?.map((x) => x),
    };
    delete values.annual_income;

    let create_profile_key = [{ key: "updateUserProfile", loading: true }];
    let create_profile_query = updateUserProfile;
    let create_profile_variables = { data: values };
    dispatch(
      dynamicRequest(
        create_profile_key,
        create_profile_query,
        create_profile_variables,
        "M"
      )
    );
  };

  const getOptions = (all_options, element_name) => {
    //let _options = all_options?.filter((x) => x.element?.name === element_name);
    let filtered_options = all_options?.filter((x) => x?.value !== "any");

    return filtered_options;
  };

  const onValueChange = (_values) => {};
  useEffect(() => {
    if (my_profile_details) {
      let _my_profile_details = JSON.parse(JSON.stringify(my_profile_details));

      Object.keys(_my_profile_details).forEach(function (key) {
        if (Array.isArray(_my_profile_details[key])) {
          let _key_values = _my_profile_details[key].map((x) => x.id);
          _my_profile_details[key] = _key_values;
        }
      });
      setInitialValues({
        ..._my_profile_details,
        doshams: my_profile_details?.doshams?.map((x) => x),
        educations: my_profile_details?.educations?.map((x) => x),
        sub_educations: my_profile_details?.sub_educations?.map((x) => x),
        annual_income: getAnnualIncomeOptions(_my_profile_details),
      });
    }
  }, [my_profile_details]);
  console.log("initial_values", initial_values);
  useEffect(() => {
    // let m = moment(ti, "HH:mm:ss").utc().format();
    // req format = 2022-12-13T00:36:00.795Z
    // curret 2022-12-13T01:36:00Z
    initial_values.place_of_birth = my_horoscope_details?.place_of_birth;
    initial_values.time_of_birth = my_horoscope_details?.time_of_birth;
    form.setFieldsValue({
      ...initial_values,
      initial_values,
    });
  }, [initial_values, my_horoscope_details]);
  const handleChangesValues = (val, values) => {
    console.log("handleChangesValues", val, values);
  };
  return (
    <Box>
      <Form
        form={form}
        name={"profile_form"}
        className="user_form ant-select-selector ant-select-selection-search"
        layout={"horizontal"}
        onFinish={handleSubmit}
        initialValues={initial_values}
        onValuesChange={handleChangesValues}
      >
        <Box alignItems={"center"}>
          <Card
            backgroundColor={"#fff"}
            maxWidth={"700px"}
            width={"90%"}
            alignItems={"center"}
            my={5}
          >
            <Box>
              <Text fontSize={16} color={"#712e5a"} bold>
                {t("my_profile")}
              </Text>
            </Box>

            <UserPhotos />
            <UserProfileFormBasicDetails getOptions={getOptions} form={form} />
            <UserProfileFormReligionDetails
              getOptions={getOptions}
              form={form}
              initial_values={initial_values}
            />
            <UserProfileFormHoroscopeDetails
              form={form}
              initial_values={initial_values}
            />
            <UserProfileFormLocationDetails
              getOptions={getOptions}
              form={form}
              initial_values={initial_values}
            />
            <UserProfileFormProfessionalDetails
              getOptions={getOptions}
              form={form}
              getAnnualIncomeOptions={getAnnualIncomeOptions}
            />
            <UserProfileFormFamilyDetails getOptions={getOptions} form={form} />
            <Box alignItems={"center"}>
              <Button
                loading={update_profile_loading}
                type="primary"
                htmlType="submit"
              >
                {"Submit"}
              </Button>
            </Box>
          </Card>
        </Box>
      </Form>
    </Box>
  );
};
export default UserProfileForm;

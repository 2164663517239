import UserProfileForm from "@views/components/user_manage_profile/user_profile_form";
import { Box, Text } from "native-base";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
const UserManageProfile = (props) => {
  const { t } = useTranslation();
  return (
    <Scrollbars flex={1}>
      <Box backgroundColor={"#fef5fb"}>
        <UserProfileForm />
      </Box>
    </Scrollbars>
  );
};
export default UserManageProfile;

import { Box, Card, HStack } from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { Button, Form } from "antd";
import { CasteOptions } from "@master_data/common_json/castes";
import {
  dynamicRequest,
  get_all_franchise_members_for_filter,
  get_franchise_member_pincodes,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

const MemberFilterForm = (props) => {
  const { onFilterClose, form } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pin_codes, setPinCodes] = useState();

  const franchise = Form.useWatch("franchise_id", form);

  const { items: franchises_items } = useDynamicSelector("filter_franchises");
  const { pincodes } = useDynamicSelector("filter_franchise_pincodes");

  const getFranchisePincodes = (id) => {
    let keys = [{ key: "filter_franchise_pincodes", loading: true }];
    let variables = { id };
    dispatch(
      dynamicRequest(keys, get_franchise_member_pincodes, variables, "M")
    );
  };

  useEffect(() => {
    if (franchise) {
      getFranchisePincodes(franchise);
    }
  }, [franchise]);

  useEffect(() => {
    if (pincodes) {
      let _pincodes = pincodes?.map((x) => ({
        label: x,
        value: x,
      }));
      setPinCodes(_pincodes);
    }
  }, [pincodes]);

  return (
    <Box>
      <Card backgroundColor={"#fff"}>
        <VForm.Select
          label={t("gender")}
          field={"gender"}
          options={[
            {
              label: "Male",
              value: "m",
            },
            {
              label: "Female",
              value: "f",
            },
          ]}
          labelField={"label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
        />
        <VForm.Select
          label={t("caste")}
          field={"caste"}
          options={CasteOptions}
          labelField={"en_label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
        />
        <VForm.Select
          label={t("franchise")}
          field={"franchise_id"}
          options={franchises_items}
          labelField={"name"}
          valueField={"id"}
          form_type={"profile"}
          allowClear
        />
        <VForm.Select
          label={t("pin_code")}
          field={"pincode"}
          options={pin_codes}
          labelField={"label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
        />

        <HStack justifyContent={"center"} space={5} alignItems={"center"}>
          {/* <Button width="150px" onClick={() => onFilterClose()}>
            Close
          </Button> */}
          <VForm.Button
            width="150px"
            style={{
              backgroundColor: "#712e5b",
              color: "white",
            }}
          >
            Apply
          </VForm.Button>
        </HStack>
      </Card>
    </Box>
  );
};

export default MemberFilterForm;

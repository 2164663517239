import React from "react";
import { Box, Text, HStack } from "native-base";
import { getUserDetailsFromValue } from "@helpers/functions";
import { DoshamsOptions } from "@master_data/common_json/doshams";

const MemberDetailCardAdmin = (props) => {
  const { text, element, icon, list, type, options } = props;

  return (
    <>
      {type === "multi" ? (
        <HStack
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
          width={"100%"}
        >
          <Box width={"5%"}>{icon}</Box>
          <Box
            style={{
              paddingHorizontal: 5,
            }}
            width={"50%"}
          >
            <Text fontSize={16} bold color={"black"}>
              {element}
            </Text>
          </Box>
          <Box
            style={{
              paddingHorizontal: 5,
            }}
            width={"50%"}
          >
            {list?.map((x, i) => {
              return (
                <Text py={1} color={"black"}>
                  {`${getUserDetailsFromValue(options, x)}${
                    i === list?.length - 1 ? "" : ", "
                  }`}
                </Text>
              );
            })}
          </Box>
          {/* {list?.length > 0 ? (
            <Box
              style={{
                paddingHorizontal: 5,
              }}
              width={"45%"}
            >
              {list?.map((x, i) => {
                return (
                  <Text fontSize={16} py={1} color={"black"}>
                    {`${x.en_label}${i === list?.length - 1 ? "" : ", "}`}
                  </Text>
                );
              })}
            </Box>
          ) : (
            <Box
              style={{
                paddingHorizontal: 5,
              }}
              width={"45%"}
            >
              <Text fontSize={16} py={1} color={"black"}>
                {text || "-"}
              </Text>
            </Box>
          )}
          <br /> */}
        </HStack>
      ) : (
        <HStack
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
          width={"100%"}
        >
          <Box width={"5%"}>{icon}</Box>

          <Box
            style={{
              paddingHorizontal: 5,
            }}
            width={"50%"}
          >
            <Text fontSize={16} bold color={"black"}>
              {element}
            </Text>
          </Box>
          {list?.length > 0 ? (
            <Box
              style={{
                paddingHorizontal: 5,
              }}
              width={"45%"}
            >
              {list?.map((x, i) => {
                return (
                  <Text fontSize={16} py={1} color={"black"}>
                    {`${x.en_label}${i === list?.length - 1 ? "" : ", "}`}
                  </Text>
                );
              })}
            </Box>
          ) : (
            <Box
              style={{
                paddingHorizontal: 5,
              }}
              width={"45%"}
            >
              <Text fontSize={16} py={1} color={"black"}>
                {text || "-"}
              </Text>
            </Box>
          )}
          <br />
        </HStack>
      )}
    </>
  );
};
export default MemberDetailCardAdmin;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, HStack, ScrollView, Drawer } from "native-base";
import { useTranslation } from "react-i18next";
import { navigate } from "@helpers/navigator";

import { SearchOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";

import {
  dynamicRequest,
  useDynamicSelector,
  get_all_members_query,
  get_user_session_query,
  dynamicClear,
} from "@services/redux";
import {
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Tooltip,
  Space,
  Button,
  Input,
} from "antd";

import moment from "moment";
import { debounce } from "lodash";
import { BiUpArrowAlt } from "react-icons/bi";
import { useRef } from "react";
import { ROUTES } from "@views/routes/my_routes";
import { storeItem } from "@helpers/storage";

const TelecallerHomeList = () => {
  const dispatch = useDispatch();
  const top_ref = useRef();

  const { t } = useTranslation();

  const [current, setCurrent] = useState(1);

  const {
    items: members_items,
    loading: manage_members_list_loading,
    pagination: members_pagination,
  } = useDynamicSelector("manage_members_list");
  console.log("members_items", members_items);

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);
  const goToTop = () => {
    debouncedHandleTitleClick();
  };

  const getAllMembersList = (search_string) => {
    let keys = [{ key: "manage_members_list", loading: true }];
    let variables = {
      search_string: search_string ? search_string : "",
      page_number: current,
      page_limit: 10,
      sort_by: "created",
    };
    dispatch(dynamicRequest(keys, get_all_members_query, variables));
  };

  const handleMemberSearch = (e) => {
    if (e.target.value?.length > 3) {
      getAllMembersList(e.target.value);
    } else if (e.target.value?.length === 0) {
      getAllMembersList();
    }
  };
  const handleEnterSearch = (e) => {
    getAllMembersList(e.target.value);
  };

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "manage_members_list", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_members_query, {
        page_number: page_number,
        page_limit: page_limit,
        sort_by: "created",
      })
    );
    setCurrent(page_number);
  };

  const get_user_session_detail = (mobile) => {
    let keys = [{ key: "getUserSession", loading: true }];
    let variables = {
      mobile,
    };
    dispatch(dynamicRequest(keys, get_user_session_query, variables));
  };

  const handleView = (item) => {
    navigate(`/member-details/${item?.id}`);
    storeItem("user_session_id", null);
    dispatch(dynamicClear("getUserSession"));
  };

  const handleUserView = (item) => {
    get_user_session_detail(item?.mobile);
    navigate(ROUTES.USER_PHOTOS_PRIVACY_UPDATE);
    storeItem("user_session_id", null);
    dispatch(dynamicClear("getUserSession"));
  };

  useEffect(() => {
    getAllMembersList();
  }, []);

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "",
      align: "left",
      key: "name",
      fixed: "left",
      width: 120,
      render: (record) => {
        return <Text>{record?.name}</Text>;
      },
    },
    {
      title: t("table:profile_number"),
      dataIndex: "",
      key: "profile_number",
      align: "left",
      width: 100,

      render: (record) => {
        return <Text>{record?.profile_number}</Text>;
      },
    },
    {
      title: t("mobile"),
      dataIndex: "",
      align: "left",
      key: "mobile",
      width: 120,
      render: (record) => {
        return <Text>{record?.mobile}</Text>;
      },
    },

    {
      title: t("table:gender"),
      dataIndex: "",
      key: "gender",
      align: "left",
      width: 80,
      render: (record) => {
        return <Text>{record?.gender === "m" ? "Male" : "Female"}</Text>;
      },
    },
    {
      title: t("table:dob"),
      dataIndex: "",
      key: "username",
      align: "left",
      width: 120,
      render: (record) => {
        return <Text>{moment(record?.dob).format("DD/MM/YYYY")}</Text>;
      },
    },
    {
      title: t("subscription"),
      dataIndex: "",
      key: "subscription_status",
      align: "left",
      width: 120,
      render: (record) => {
        return <Text>{record?.subscription_status}</Text>;
      },
    },
    {
      title: "Created On",
      dataIndex: "",
      align: "left",
      key: "name",
      width: 120,
      render: (record) => {
        return (
          <Text>{moment(record?.created).format("DD/MM/YYYY h:mm a")}</Text>
        );
      },
    },
    {
      title: "Last Login",
      dataIndex: "",
      align: "left",
      key: "name",
      width: 120,
      render: (record) => {
        // let temp = parseInt(record?.last_login_datetime, 10) || 0;

        const dateString = moment(record?.last_login_datetime).format(
          "DD/MM/YYYY h:mm a"
        );

        return <Text>{dateString}</Text>;
      },
    },
    {
      title: t("sent_req_count"),
      dataIndex: "sent_request_count",
      key: "sent_req_count",
      width: 100,
      align: "left",
    },
    {
      title: t("receive_req_count"),
      dataIndex: "received_request_count",
      key: "receive_req_count",
      align: "left",
      width: 100,
    },
    {
      title: "Accepted Request Count",
      dataIndex: "accepted_request_count",
      key: "accepted_request_count",
      align: "left",
      width: 100,
    },
    {
      title: "Pincode",
      dataIndex: "",
      align: "left",
      key: "pincode",
      width: 100,

      render: (record) => {
        return <Text>{record?.pincode || "-"}</Text>;
      },
    },
    {
      title: "Franchise",
      dataIndex: "",
      align: "left",
      key: "franchise_name",
      width: 120,

      render: (record) => {
        return <Text>{record?.franchise_name || "-"}</Text>;
      },
    },
    {
      title: "Promo Code",
      dataIndex: "",
      align: "left",
      key: "promo_code",
      width: 100,

      render: (record) => {
        return <Text>{record?.promo_code || "-"}</Text>;
      },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      width: 100,

      render: (record) => {
        return (
          <Space>
            <Space>
              <Typography.Link onClick={() => handleUserView(record)}>
                <Space>
                  <Tooltip title="Edit" placement="bottom">
                    <EditOutlined />
                  </Tooltip>
                </Space>
              </Typography.Link>
            </Space>
            <Space>
              <Typography.Link onClick={() => handleView(record)}>
                <Space>
                  <Tooltip title="View" placement="bottom">
                    <EyeOutlined />
                    {/* <AiFillEye color="#111" size={20} /> */}
                  </Tooltip>
                </Space>
              </Typography.Link>
            </Space>
          </Space>
        );
      },
    },
  ];

  return (
    <Box height={"100vh"} ref={top_ref}>
      <Box width="100%" mt="20px" marginBottom="20px">
        <Box top="0px" zIndex="2">
          <Text fontSize="md" fontWeight="bold">
            {`${t("total_members")} (${
              members_pagination?.total_count
                ? members_pagination?.total_count
                : 0
            })`}
          </Text>
        </Box>

        <VStack space={5}>
          <Box style={{ alignItems: "flex-end" }}>
            <Input
              placeholder="Search Member"
              onChange={handleMemberSearch}
              suffix={<SearchOutlined />}
              allowClear
              style={{ width: 200 }}
              onPressEnter={handleEnterSearch}
            />
          </Box>
          <Table
            columns={columns}
            dataSource={members_items}
            pagination={false}
            loading={manage_members_list_loading}
            scroll={{
              x: 1300,
            }}
          />
          <HStack alignItems={"center"}>
            <Box width={"70%"}>
              <Pagination
                total={members_pagination?.total_count}
                showSizeChanger={true}
                current={current}
                hideOnSinglePage={false}
                onChange={fetchData}
                pageSizeOptions={[5, 10, 15, 20, 50, 100, 1000]}
              />
            </Box>
            <Box alignItems={"flex-end"} my={5} width={"30%"}>
              <Button
                colorScheme={"danger.700"}
                onClick={() => {
                  goToTop();
                }}
                style={{
                  label: t("table:back"),
                  variant: "outline",
                }}
              >
                <HStack width={"100%"} alignItems={"center"}>
                  <BiUpArrowAlt size={20} />
                  <Text>{"Go to Top"}</Text>
                </HStack>
              </Button>
            </Box>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};
export default TelecallerHomeList;

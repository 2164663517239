export const ComplexionOptions = [
  {
    value: "very_fair",
    p_o: [],
    en_label: "Very fair",
    ta_label: "மிக அழகு",
  },
  {
    value: "fair",
    p_o: [],
    en_label: "Fair",
    ta_label: "அழகு",
  },
  {
    value: "dark",
    p_o: [],
    en_label: "Dark",
    ta_label: "null",
  },
  {
    value: "brown",
    p_o: [],
    en_label: "Brown",
    ta_label: "null",
  },
];

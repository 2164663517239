import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text, HStack, ScrollView } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import AntdModal from "../ui/antd_modal";
import {
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Space,
  Tooltip,
  Button,
} from "antd";

import ActionButton from "../ui/dialog/action_button";
import AppVersionDetails from "./app_version_details";
import {
  dynamicRequest,
  get_all_versions_query,
  useDynamicSelector,
} from "@services/redux";

const AppVersionsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [infoMode, setInfoMode] = useState(false);
  const [page_number, setPageNumber] = useState(1);

  const [page_limit, setPageLimit] = useState(10);
  console.log("page_limitttt:", page_limit);

  const {
    items,
    error: app_version_list_error,
    pagination,
  } = useDynamicSelector("getAllVersionLog");
  console.log("items", items);

  const handleInfo = (item) => {
    setActionItem(item);
    setActionType("info");
    setInfoMode(true); // Set infoMode to true when viewing info
    setModalOpen(true);
  };

  const handleDelete = (record) => {};
  const handleAdd = () => {
    // debugger;
    setActionType("add");
    setActionItem({});
    setModalOpen(true);
  };
  const fetch_data = (page, pageSize) => {
    console.log("page1111", page, pageSize);

    setPageNumber(page);

    setPageLimit(pageSize);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const get_all_version_query = () => {
    let key = [{ key: "getAllVersionLog", loading: true }];
    let query = get_all_versions_query;
    let variable = { page_number: page_number, page_limit: page_limit };

    dispatch(dynamicRequest(key, query, variable));
  };
  useEffect(() => {
    get_all_version_query();
  }, [page_number, page_limit]);

  let columns = [
    {
      title: t("version"),
      dataIndex: "version",
      align: "center",
      key: "version",
    },

    {
      title: t("is_mandatory"),
      dataIndex: "",
      align: "center",
      key: "is_mandatory",
      render: (record) => {
        return <Text>{record.is_mandatory === true ? "Yes" : "No"}</Text>;
      },
    },

    {
      title: t("description"),
      dataIndex: "current_changes",
      align: "center",
      key: "description",
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <Space>
            <Typography.Link
              style={{
                marginLeft: 5,
                marginRight: 5,
              }}
              onClick={() => handleInfo(record)}
            >
              <Space>
                <Tooltip title="Info">
                  <InfoCircleOutlined />
                </Tooltip>
              </Space>
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  // let header_actions = [
  //   {
  //     height: "50px",
  //     label: t("add_version"),
  //     colorScheme: "danger.700",
  //     variant: "outline",
  //     onPress: handleAdd,
  //   },
  // ];

  return (
    <>
      <Box width="100%" mt="20px" marginBottom="20px">
        <Text fontWeight={"15px"} bold={"10px"} mx={4} fontSize={"16px"}>
          {" "}
          {t("add_version")}
        </Text>
        <Box style={{ alignItems: "flex-end", margin: "20px" }}>
          <Button
            fontSize="md"
            fontWeight="bold"
            style={{
              padding: "20px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "5px",
              borderColor: "black",
            }}
            onClick={handleAdd}
          >
            {t("App Versions")}
          </Button>
        </Box>

        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            {/* {header_actions.map((headerAction, index) => (
              <Box key={`header-action-${index}`}>
                <ActionButton {...headerAction} />
              </Box>
            ))} */}
          </HStack>
          <Wrapper style={{ overflowY: "scroll", maxHeight: "300px" }}>
            <Table
              columns={columns}
              dataSource={items}
              pagination={false}
              loading={false}
            />
          </Wrapper>
          <Box alignItems={"flex-end"} marginRight={"20px"}>
            <Pagination
              // page_number={page_number}
              // onChange={fetch_data}
              // total={pagination?.total_count}
              current={page_number}
              pageSize={page_limit}
              total={pagination?.total_count}
              onChange={fetch_data}
            />
          </Box>
        </VStack>
      </Box>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        destroyOnClose={true}
        header={actionType === "add" ? t("add_version") : t("info_version")}
        component={
          <AppVersionDetails
            isOpen={modalOpen}
            label={actionType === "add" ? t("add") : t("info")}
            close={handleModalClose}
            initialValues={actionItem}
            readOnly={infoMode}
            actionType={actionType}
            get_all_version_query={get_all_version_query}
          />
        }
      />
    </>
  );
};
export default AppVersionsList;

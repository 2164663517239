import {
  Box,
  Card,
  Divider,
  Hidden,
  HStack,
  Skeleton,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input } from "antd";
import "../../../index.css";

import { useDispatch } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { FamilyValueOptions } from "@master_data/profile_json/family_value";
import { FamilyTypeOptions } from "@master_data/profile_json/family_type";
import { FamilyStatusOptions } from "@master_data/profile_json/family_status";
import { FathersOccupationOptions } from "@master_data/profile_json/fathers_occupations";
import { MothersOccupationOptions } from "@master_data/profile_json/mothers_occupation";
import { NumberOfBrothersOptions } from "@master_data/profile_json/number_of_brothers";
import { NumberOfSisters } from "@master_data/profile_json/number_of_sisters";
import { MarriedBrothersOptions } from "@master_data/profile_json/married_brothers";
import { MarriedSistersOptions } from "@master_data/profile_json/married_sisters";
import { useDynamicSelector } from "@services/redux";
import colors from "@helpers/colors";

const UserProfileFormFamilyDetailsOperator = (props) => {
  const { form, getOptions } = props;
  const { t } = useTranslation();
  // const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [married_brothers, setMarriedBrothers] = useState([]);
  const [married_sisters, setMarriedSisters] = useState([]);

  const number_of_brothers = Form.useWatch("number_of_brothers", form);
  const number_of_sisters = Form.useWatch("number_of_sisters", form);

  const { loading: my_profile_loading } = useDynamicSelector("my_profile");

  useEffect(() => {
    let _options = [];
    let temp_value = [];

    if (MarriedBrothersOptions) {
      _options = MarriedBrothersOptions;
    }
    for (let i = 0; i < _options?.length; i++) {
      let temp_1 = _options[i]?.p_o;

      for (let j = 0; j < temp_1?.length; j++) {
        let temp_2 = temp_1[j];
        if (number_of_brothers === temp_2) {
          // _options = _options?.filter((x) => x);

          temp_value.push(_options[i]);

          setMarriedBrothers(temp_value);
        }
      }
    }
  }, [number_of_brothers, MarriedBrothersOptions]);
  useEffect(() => {
    let _options = [];
    let temp_value = [];

    if (MarriedSistersOptions) {
      _options = MarriedSistersOptions;
    }
    for (let i = 0; i < _options?.length; i++) {
      let temp_1 = _options[i]?.p_o;
      for (let j = 0; j < temp_1?.length; j++) {
        let temp_2 = temp_1[j];
        if (number_of_sisters === temp_2) {
          // _options = _options?.filter((x) => x);
          temp_value.push(_options[i]);
          setMarriedSisters(temp_value);
        }
      }
    }
  }, [number_of_sisters, MarriedSistersOptions]);
  return (
    <>
      {my_profile_loading ? (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("family_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <Hidden from="md">
            <Box width={"100%"}>
              <Skeleton.Text lines={5} />
            </Box>
          </Hidden>
          <Hidden till="md">
            <HStack space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
          </Hidden>
        </Card>
      ) : (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={14} bold color={"#712e5a"}>
              {t("family_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <VForm.Select
            label={t("family_value")}
            field={"family_value"}
            options={getOptions(FamilyValueOptions, "Family Value")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            // rules={[
            //   {
            //     required: true,
            //     message: "Family Value is required",
            //   },
            // ]}
          />
          <VForm.Select
            label={t("family_type")}
            field={"family_type"}
            options={getOptions(FamilyTypeOptions, "Family Type")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            // rules={[
            //   {
            //     required: true,
            //     message: "Family Type is required",
            //   },
            // ]}
          />
          <VForm.Select
            label={t("family_status")}
            field={"family_status"}
            options={getOptions(FamilyStatusOptions, "Family Status")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            // rules={[
            //   {
            //     required: true,
            //     message: "Family Status is required",
            //   },
            // ]}
          />
          <VForm.TextBox
            label={t("father_name")}
            field={"father_name"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Father Name is required",
              },
            ]}
          />
          <VForm.TextBox
            label={t("mother_name")}
            field={"mother_name"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Mother Name is required",
              },
            ]}
          />

          <VForm.Select
            label={t("father_occupation")}
            field={"father_occupation"}
            options={getOptions(
              FathersOccupationOptions,
              "Father's Occupation"
            )}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Father's Occupation is required",
              },
            ]}
          />
          <VForm.Select
            label={t("mother_occupation")}
            field={"mother_occupation"}
            options={getOptions(
              MothersOccupationOptions,
              "Mother's Occupation"
            )}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Mother's Occupation is required",
              },
            ]}
          />
          <VForm.Select
            label={t("number_of_brothers")}
            field={"number_of_brothers"}
            options={getOptions(NumberOfBrothersOptions, "No. of Brothers")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "No. of Brothers is required",
              },
            ]}
          />
          <VForm.Select
            label={t("number_of_married_brothers")}
            field={"number_of_married_brothers"}
            options={married_brothers}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Married Brothers is required",
              },
            ]}
          />
          <VForm.Select
            label={t("number_of_sisters")}
            field={"number_of_sisters"}
            options={getOptions(NumberOfSisters, "No. of Sisters")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "No. of Sisters is required",
              },
            ]}
          />

          <VForm.Select
            label={t("number_of_married_sisters")}
            field={"number_of_married_sisters"}
            options={married_sisters}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Married Sisters is required",
              },
            ]}
          />
          <VForm.TextArea
            label={t("about_family")}
            field={"about_family"}
            form_type={"profile"}
            // rules={[
            //   {
            //     required: true,
            //     message: "Mother Name is required",
            //   },
            // ]}
          />
          <HStack width={"100%"}>
            <Box width={"45%"}></Box>
            <Box alignItems={"flex-end"} width={"55%"}>
              <HStack>
                <Text color={colors.red}>*</Text>
                <Text>{t("family_expectation_instructions")}</Text>
              </HStack>
            </Box>
          </HStack>
        </Card>
      )}
    </>
  );
};
export default UserProfileFormFamilyDetailsOperator;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, ScrollView, HStack } from "native-base";
import { getCountry, getState } from "@helpers/get_country_state_city";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  useDynamicSelector,
  get_all_rejected_members,
  get_all_rejected_members_reports,
} from "@services/redux";
import { Button, Pagination, Table } from "antd";
import { navigateBack } from "@helpers/navigator";
import { CalculateAgeNumber } from "@helpers/calculate_age";
import moment from "moment";
import { generateExcelFromJSON } from "@helpers/functions";
import { useRef } from "react";
import { debounce } from "lodash";
import { BiUpArrowAlt } from "react-icons/bi";

const AdminRejectedUsers = () => {
  const { t } = useTranslation();
  const top_ref = useRef();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [report_data, setReportData] = useState([]);

  const {
    items: reports,
    loading,
    pagination,
  } = useDynamicSelector("get_all_rejected_members");
  const {
    items: get_all_rejected_members_reports_items,
    loading: get_all_rejected_members_reports_loading,
  } = useDynamicSelector("get_all_rejected_members_report");
  useEffect(() => {
    let keys = [{ key: "get_all_rejected_members", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_rejected_members, {
        page_number: current,
        page_limit: current_limit,
      })
    );
  }, []);
  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_rejected_members", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_rejected_members, {
        page_number: page_number,
        page_limit: page_limit,
      })
    );
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };
  const getReports = () => {
    let keys = [{ key: "get_all_rejected_members_report", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_rejected_members_reports, {
        page_number: 1,
        page_limit: 1000000,
      })
    );
  };
  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    if (get_all_rejected_members_reports_items?.length > 0) {
      setReportData(get_all_rejected_members_reports_items);
    }
  }, [get_all_rejected_members_reports_items]);
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
    },
    {
      title: "Profile Number",
      dataIndex: "",
      align: "left",
      key: "profile_id",
      render: (record) => {
        return <Text>{record?.profile_id?.split("_d")?.[0]}</Text>;
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      align: "left",
      key: "gender",
      render: (record, i) => {
        return (
          <>
            <Text>{record === "m" ? "Male" : "Female"}</Text>
          </>
        );
      },
    },
    {
      title: "Age",
      dataIndex: "dob",
      align: "left",
      key: "dob",
      render: (record, i) => {
        return (
          <>
            <Text>{CalculateAgeNumber(record)}</Text>
          </>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "",
      align: "left",
      key: "mobile",
      render: (record) => {
        return <Text>{record?.mobile.split("_d")?.[0]}</Text>;
      },
    },
    {
      title: "Location",
      dataIndex: "",
      align: "left",
      key: "location",
      render: (record, i) => {
        let country = record.country;
        return (
          <>
            <Text>
              {record.country ? <>{getCountry(record.country)},</> : null}
              {record.state ? <>{getState(record.state, country)},</> : null}
              {record.district ? <>{record.district}.</> : null}
            </Text>
          </>
        );
      },
    },
    {
      title: "Franchise Name",
      dataIndex: "",
      align: "left",
      key: "name",
      render: (record) => {
        return <Text>{record?.franchise_name || "-"}</Text>;
      },
    },
    {
      title: "Created On",
      dataIndex: "",
      align: "left",
      key: "name",
      render: (record) => {
        return (
          <Text>
            {moment(record?.created_datetime).format("DD/MM/YYYY h:mm a")}
          </Text>
        );
      },
    },
    {
      title: "Rejected On",
      dataIndex: "",
      align: "left",
      key: "rejected_datetime",
      render: (record) => {
        return (
          <>
            <Text>
              {moment(record?.rejected_datetime).format("DD/MM/YYYY h:mm a")}
            </Text>
          </>
        );
      },
    },
    {
      title: "Reason",
      dataIndex: "reject_reason",
      align: "left",
      key: "reject_reason",
    },
  ];
  const fieldMappings = [
    {
      label: "Name",
      field: "name",
    },
    {
      label: "Profile Number",
      field: "profile_id",
    },

    {
      label: "Mobile",
      field: "mobile",
    },
    {
      label: "Created At",
      field: "created_datetime",
    },
    {
      label: "Rejected At",
      field: "rejected_datetime",
    },
    {
      label: "Date of Birth",
      field: "dob",
    },
    {
      label: "Gender",
      field: "gender",
    },
    {
      label: "Franchise",
      field: "franchise_name",
    },
    {
      label: "District",
      field: "district",
    },
    {
      label: "Reason",
      field: "reject_reason",
    },
  ];
  const handlePagePosition = () => {
    const element = top_ref?.current;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);
  const goToTop = () => {
    debouncedHandleTitleClick();
  };
  return (
    <Box ref={top_ref} back width="100%" mt="20px" marginBottom="20px">
      <ScrollView>
        <Box width="100%" mt="20px" marginBottom="20px">
          <Text fontSize="md" fontWeight="bold">
            {`${t("rejected_user")} (${
              pagination?.total_count ? pagination?.total_count : 0
            })`}
          </Text>
          <Box my={5} width={"150px"}>
            <Button
              loading={get_all_rejected_members_reports_loading}
              colorScheme={"danger.700"}
              onClick={() => {
                generateExcelFromJSON(
                  report_data,
                  "RejectedMembers.xlsx",
                  fieldMappings
                );
              }}
              style={{
                height: "50px",
                label: t("table:back"),
                variant: "outline",
              }}
            >
              {get_all_rejected_members_reports_loading
                ? "Wait..."
                : "Download Report"}
            </Button>
          </Box>
          <Box my={5} width={"80px"}>
            <Button
              colorScheme={"danger.700"}
              onClick={() => {
                navigateBack();
              }}
              style={{
                label: t("table:back"),
                variant: "outline",
              }}
            >
              Back
            </Button>
          </Box>
          <VStack space={5}>
            <Wrapper>
              <Table
                columns={columns}
                pagination={false}
                loading={loading}
                dataSource={reports}
              />
            </Wrapper>
            <HStack alignItems={"center"}>
              <Box width={"70%"}>
                <Pagination
                  showSizeChanger={true}
                  hideOnSinglePage={false}
                  onChange={fetchData}
                  total={pagination?.total_count}
                  current={current}
                  pageSizeOptions={[5, 10, 15, 20, 50, 100, 1000]}
                />
              </Box>
              <Box alignItems={"flex-end"} my={5} width={"30%"}>
                <Button
                  colorScheme={"danger.700"}
                  onClick={() => {
                    goToTop();
                  }}
                  style={{
                    label: t("table:back"),
                    variant: "outline",
                  }}
                >
                  <HStack width={"100%"} alignItems={"center"}>
                    <BiUpArrowAlt size={20} />
                    <Text>{"Go to Top"}</Text>
                  </HStack>
                </Button>
              </Box>
            </HStack>
          </VStack>
        </Box>
      </ScrollView>
    </Box>
  );
};

export default AdminRejectedUsers;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, HStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import {
  dynamicRequest,
  useDynamicSelector,
  get_unverified_members,
  delete_member_mutation,
} from "@services/redux";
import {
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Tooltip,
  Space,
  Button,
  Input,
} from "antd";

import { AiFillEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import moment from "moment";

const UserDetailsList = () => {
  const dispatch = useDispatch();

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [current, setCurrent] = useState(1);

  const {
    items: members_items,
    loading,
    pagination: members_pagination,
  } = useDynamicSelector("unverified_members_list");
  const handleView = (item) => {
    navigate(`/user-details/${item?.id}`);
  };

  const getAllUnverifiedMembersList = (search_string) => {
    let keys = [{ key: "unverified_members_list", loading: true }];
    let variables = {
      search_string: search_string ? search_string : "",
      page_number: current,
      page_limit: 10,
      sort_by: "created",
    };
    dispatch(dynamicRequest(keys, get_unverified_members, variables));
  };

  const handleMemberSearch = (e) => {
    if (e.target.value?.length > 3) {
      getAllUnverifiedMembersList(e.target.value?.trim());
    } else if (e.target.value?.length === 0) {
      getAllUnverifiedMembersList();
    }
  };
  const handleEnterSearch = (e) => {
    getAllUnverifiedMembersList(e.target.value?.trim());
  };

  useEffect(() => {
    getAllUnverifiedMembersList();
  }, []);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "unverified_members_list", loading: true }];
    dispatch(
      dynamicRequest(keys, get_unverified_members, {
        page_number: page_number,
        page_limit: page_limit,
        sort_by: "created",
      })
    );
    setCurrent(page_number);
  };

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "",
      align: "left",
      key: "name",
      render: (record) => {
        return <Text>{record?.name}</Text>;
      },
    },
    {
      title: t("table:gender"),
      dataIndex: "",
      key: "gender",
      align: "left",
      render: (record) => {
        return <Text>{record?.gender === "m" ? "Male" : "Female"}</Text>;
      },
    },
    {
      title: t("table:profile_number"),
      dataIndex: "",
      key: "profile_number",
      align: "left",
      render: (record) => {
        return <Text>{record?.profile_number}</Text>;
      },
    },
    {
      title: t("mobile"),
      dataIndex: "",
      align: "left",
      key: "mobile",
      render: (record) => {
        return <Text>{record?.mobile}</Text>;
      },
    },

    {
      title: t("created_datetime"),
      dataIndex: "",
      key: "created_datetime",
      align: "left",
      render: (record) => {
        return (
          <Text>{moment(record?.created).format("DD/MM/YYYY hh:mm a")}</Text>
        );
      },
    },
    {
      title: t("table:dob"),
      dataIndex: "",
      key: "username",
      align: "left",
      render: (record) => {
        return <Text>{moment(record?.dob).format("DD/MM/YYYY")}</Text>;
      },
    },
    {
      title: "Pincode",
      dataIndex: "",
      align: "left",
      key: "pincode",
      render: (record) => {
        return <Text>{record?.pincode || "-"}</Text>;
      },
    },
    {
      title: "Franchise",
      dataIndex: "",
      align: "left",
      key: "franchise_name",
      render: (record) => {
        return <Text>{record?.franchise_name || "-"}</Text>;
      },
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <Space>
            <Typography.Link onClick={() => handleView(record)}>
              <Space>
                <Tooltip title="View" placement="bottom">
                  <AiFillEye color="#111" />
                </Tooltip>
              </Space>
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Box width="100%" mt="20px" marginBottom="20px">
        <Box top="0px" zIndex="2">
          <Text fontSize="md" fontWeight="bold">
            {`${t("unverified_members")} (${
              members_pagination?.total_count
                ? members_pagination?.total_count
                : 0
            })`}
          </Text>
        </Box>

        <VStack space={5}>
          <Box alignItems={"flex-end"}>
            <Input
              placeholder="Search Member"
              onChange={handleMemberSearch}
              suffix={<SearchOutlined />}
              allowClear
              style={{ width: 200 }}
              onPressEnter={handleEnterSearch}
            />
          </Box>

          <Wrapper>
            <Table
              columns={columns}
              dataSource={members_items}
              pagination={false}
              loading={loading}
            />
          </Wrapper>
          <Pagination
            total={members_pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
            // pageSizeOptions={[5, 10, 15, 20, 50]}
          />
        </VStack>
      </Box>
    </>
  );
};
export default UserDetailsList;

import UserPreferenceForm from "@views/components/user_manage_preference/user_preference_form";
import { Box } from "native-base";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
const UserManagePreference = (props) => {
  return (
    <Scrollbars flex={1}>
      <Box backgroundColor={"#fef5fb"}>
        <UserPreferenceForm />
      </Box>
    </Scrollbars>
  );
};
export default UserManagePreference;

export const SubOccupationsOptions = [
  {
    value: "administrative_manager",
    en_label: "AdministrativeManager",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "administrative_professional",
    en_label: "AdministrativeProfessional",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "clerk",
    en_label: "Clerk",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "executive",
    en_label: "Executive",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "human_resources_professionals",
    en_label: "HumanResourcesProfessionals",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "officer",
    en_label: "Officer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "secretary_front_office_receptionist",
    en_label: "Secretary/FrontOffice/Receptionist",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "supervisor",
    en_label: "Supervisor",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "agribusiness_manager",
    en_label: "AgribusinessManager",
    ta_label: null,
    p_o: ["agriculture"],
  },
  {
    value: "agricultural_consultant",
    en_label: "AgriculturalConsultant",
    ta_label: null,
    p_o: ["agriculture"],
  },
  {
    value: "agricultural_scientist",
    en_label: "AgriculturalScientist",
    ta_label: null,
    p_o: ["agriculture"],
  },
  {
    value: "agriculture_farming_professional",
    en_label: "Agriculture&FarmingProfessional",
    ta_label: null,
    p_o: ["agriculture"],
  },
  {
    value: "crop_farmer",
    en_label: "CropFarmer",
    ta_label: null,
    p_o: ["agriculture"],
  },
  {
    value: "dairy_farmer",
    en_label: "DairyFarmer",
    ta_label: null,
    p_o: ["agriculture"],
  },
  {
    value: "farm_labour",
    en_label: "FarmLabour",
    ta_label: null,
    p_o: ["agriculture"],
  },
  {
    value: "farmer",
    en_label: "Farmer",
    ta_label: null,
    p_o: ["agriculture"],
  },
  {
    value: "horticulturist",
    en_label: "Horticulturist",
    ta_label: null,
    p_o: ["agriculture"],
  },
  {
    value: "poultry_farmer",
    en_label: "PoultryFarmer",
    ta_label: null,
    p_o: ["agriculture"],
  },
  {
    value: "landlord",
    en_label: "Landlord",
    ta_label: null,
    p_o: ["agriculture"],
  },
  {
    value: "air_hostess_flight_attendant",
    en_label: "AirHostess/FlightAttendant",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "aircraftMechanic_technician",
    en_label: "AircraftMechanic/Technician",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "pilot",
    en_label: "Pilot",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "air_line_ticket_agent_business",
    en_label: "Airlineticketagent/business",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "bank_clerk",
    en_label: "BankClerk",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "bank_cashier",
    en_label: "BankCashier",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "bank_assistant",
    en_label: "BankAssistant",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "bank_assistant_manager",
    en_label: "BankAssistantManager",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "bank_relationship_manager",
    en_label: "BankRelationshipManager",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "bank_branch_manager",
    en_label: "BankBranchManager",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "bank_deputy_manager",
    en_label: "BankDeputyManager",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "bank_senior_manager",
    en_label: "BankSeniorManager",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "bank_assistant_general_manager",
    en_label: "BankAssistantGeneralManager",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "bank_deputy_general_manager",
    en_label: "BankDeputyGeneralManager",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "bank_general_manager",
    en_label: "BankGeneralManager",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "bank_vice_president",
    en_label: "BankVicePresident",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "banking_other_positions",
    en_label: "BankingOtherPositions",
    ta_label: null,
    p_o: ["private", "governmet_psu"],
  },
  {
    value: "civil_services_ias_ips_irs_ies_ifs_icas",
    en_label: "Civilservices(IAS/IPS/IRS/IES/IFS/ICAS)",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "manager",
    en_label: "Manager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "supervisor",
    en_label: "Supervisor",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "team_leader",
    en_label: "TeamLeader",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "project_manager",
    en_label: "ProjectManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "business_analyst",
    en_label: "BusinessAnalyst",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "financial_analyst",
    en_label: "FinancialAnalyst",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "accounts_manager",
    en_label: "AccountsManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "marketing_manager",
    en_label: "MarketingManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "sales_manager",
    en_label: "SalesManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "human_resources_manager",
    en_label: "HumanResourcesManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "legal_counsel",
    en_label: "LegalCounsel",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "information_technology_manager",
    en_label: "InformationTechnologyManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "data_scientist",
    en_label: "DataScientist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "product_manager",
    en_label: "ProductManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "supply_chain_manager",
    en_label: "SupplyChainManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "risk_analyst",
    en_label: "RiskAnalyst",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "compliance_manager",
    en_label: "ComplianceManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "procurement_manager",
    en_label: "ProcurementManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "training_and_development_manager",
    en_label: "TrainingandDevelopmentManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "public_relations_manager",
    en_label: "PublicRelationsManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "internal_auditor",
    en_label: "InternalAuditor",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "operations_manager",
    en_label: "OperationsManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "quality_assurance_manager",
    en_label: "QualityAssuranceManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "research_and_development_manager",
    en_label: "ResearchandDevelopmentManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "customer_service_manager",
    en_label: "CustomerServiceManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "facilities_manager",
    en_label: "FacilitiesManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "change_management_specialist",
    en_label: "ChangeManagementSpecialist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "organizational_development_consultant",
    en_label: "OrganizationalDevelopmentConsultant",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "employee_relations_specialist",
    en_label: "EmployeeRelationsSpecialist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "soldier",
    en_label: "Soldier",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "paratrooper",
    en_label: "Paratrooper",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "combat_medic",
    en_label: "CombatMedic",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "military_police_officer",
    en_label: "MilitaryPoliceOfficer",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "logistics_specialist",
    en_label: "LogisticsSpecialist",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "radar_technician",
    en_label: "RadarTechnician",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "air_traffic_controller",
    en_label: "AirTrafficController",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "combat_engineer",
    en_label: "CombatEngineer",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "cybersecurity_specialist",
    en_label: "CybersecuritySpecialist",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "intelligence_analyst",
    en_label: "IntelligenceAnalyst",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "submarine_crew_member",
    en_label: "SubmarineCrewMember",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "armor_crewman",
    en_label: "ArmorCrewman",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "sergeant",
    en_label: "Sergeant",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "sergeant_major",
    en_label: "SergeantMajor",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "warrant_officer",
    en_label: "WarrantOfficer",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "lieutenant_junior_grade",
    en_label: "LieutenantJuniorGrade",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "lieutenant",
    en_label: "Lieutenant",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "lieutenant_commander",
    en_label: "LieutenantCommander",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "commander",
    en_label: "Commander",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "captain",
    en_label: "Captain",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "major",
    en_label: "Major",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "lieutenant_colonel",
    en_label: "LieutenantColonel",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "colonel",
    en_label: "Colonel",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "brigadier_general",
    en_label: "BrigadierGeneral",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "major_general",
    en_label: "MajorGeneral",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "lieutenant_general",
    en_label: "LieutenantGeneral",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "general",
    en_label: "General",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "rear_admiral",
    en_label: "RearAdmiral",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "vice_admiral",
    en_label: "ViceAdmiral",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "admiral",
    en_label: "Admiral",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "defence_others",
    en_label: "Defenceothers",
    ta_label: null,
    p_o: ["defence"],
  },
  {
    value: "gv_general_practitioner",
    en_label: "GeneralPractitioner (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_dentist",
    en_label: "Dentist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_surgeon",
    en_label: "Surgeon (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_pediatrician",
    en_label: "Pediatrician (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_dermatologist",
    en_label: "Dermatologist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_ophthalmologist",
    en_label: "Ophthalmologist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_psychiatrist",
    en_label: "Psychiatrist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_radiologist",
    en_label: "Radiologist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_anesthesiologist",
    en_label: "Anesthesiologist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_obstetrician_gynecologist",
    en_label: "Obstetrician-Gynecologist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_cardiologist",
    en_label: "Cardiologist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_orthopedic_surgeon",
    en_label: "OrthopedicSurgeon (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_emergency_medicine_physician",
    en_label: "EmergencyMedicinePhysician (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_neurologist",
    en_label: "Neurologist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_endocrinologist",
    en_label: "Endocrinologist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_gastroenterologist",
    en_label: "Gastroenterologist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_pulmonologist",
    en_label: "Pulmonologist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_infectious_disease_specialist",
    en_label: "InfectiousDiseaseSpecialist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_rheumatologist",
    en_label: "Rheumatologist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_pathologist",
    en_label: "Pathologist (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_physician",
    en_label: "Physician (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gv_veterinary_doctor",
    en_label: "VeterinaryDoctor (Govt)",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "general_practitioner",
    en_label: "GeneralPractitioner",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "dentist",
    en_label: "Dentist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "surgeon",
    en_label: "Surgeon",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "pediatrician",
    en_label: "Pediatrician",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "dermatologist",
    en_label: "Dermatologist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "ophthalmologist",
    en_label: "Ophthalmologist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "psychiatrist",
    en_label: "Psychiatrist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "radiologist",
    en_label: "Radiologist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "anesthesiologist",
    en_label: "Anesthesiologist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "obstetrician_gynecologist",
    en_label: "Obstetrician-Gynecologist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "cardiologist",
    en_label: "Cardiologist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "orthopedic_surgeon",
    en_label: "OrthopedicSurgeon",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "emergency_medicine_physician",
    en_label: "EmergencyMedicinePhysician",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "neurologist",
    en_label: "Neurologist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "endocrinologist",
    en_label: "Endocrinologist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "gastroenterologist",
    en_label: "Gastroenterologist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "pulmonologist",
    en_label: "Pulmonologist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "infectious_disease_specialist",
    en_label: "InfectiousDiseaseSpecialist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "rheumatologist",
    en_label: "Rheumatologist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "pathologist",
    en_label: "Pathologist",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "physician",
    en_label: "Physician",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "veterinary_doctor",
    en_label: "VeterinaryDoctor",
    ta_label: null,
    p_o: ["doctor"],
  },
  {
    value: "own_clinic",
    en_label: "OwnClinic",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "own_hospital",
    en_label: "OwnHospital",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "professor_lecturer",
    en_label: "Professor_Lecturer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "teaching_academician",
    en_label: "Teaching_Academician",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "education_professional",
    en_label: "EducationProfessional",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "training_professional",
    en_label: "TrainingProfessional",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "research_assistant",
    en_label: "ResearchAssistant",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "research_scholar",
    en_label: "ResearchScholar",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "school_principal",
    en_label: "SchoolPrincipal",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "correspondent",
    en_label: "Correspondent",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "teacher",
    en_label: "Teacher",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "principal",
    en_label: "Principal",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "vice_principal",
    en_label: "Vice-Principal",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "school_counselor",
    en_label: "SchoolCounselor",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "librarian",
    en_label: "Librarian",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "school_administrator",
    en_label: "SchoolAdministrator",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "college_dean",
    en_label: "CollegeDean",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "academic_advisor",
    en_label: "AcademicAdvisor",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "curriculum_developer",
    en_label: "CurriculumDeveloper",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "school_nurse",
    en_label: "SchoolNurse",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "student_affairs_coordinator",
    en_label: "StudentAffairsCoordinator",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "admissions_officer",
    en_label: "AdmissionsOfficer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "sports_coach",
    en_label: "SportsCoach",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "school_psychologist",
    en_label: "SchoolPsychologist",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "college_registrar",
    en_label: "CollegeRegistrar",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "career_counselor",
    en_label: "CareerCounselor",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "education_consultant",
    en_label: "EducationConsultant",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "music_teacher",
    en_label: "MusicTeacher",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "art_teacher",
    en_label: "ArtTeacher",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "special_education_teacher",
    en_label: "SpecialEducationTeacher",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "language_teacher",
    en_label: "LanguageTeacher",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "physical_education_teacher",
    en_label: "PhysicalEducationTeacher",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "science_teacher",
    en_label: "ScienceTeacher",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "mathematics_teacher",
    en_label: "MathematicsTeacher",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "humanities_teacher",
    en_label: "HumanitiesTeacher",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "lecturer",
    en_label: "Lecturer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "professor",
    en_label: "Professor",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "research_assistant",
    en_label: "ResearchAssistant",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "lab_technician",
    en_label: "LabTechnician",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "administrative_staff",
    en_label: "AdministrativeStaff",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "software_professional",
    en_label: "SoftwareProfessional",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "hardware_professional",
    en_label: "HardwareProfessional",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "network_professional",
    en_label: "NetworkProfessional",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "database_administrator",
    en_label: "DatabaseAdministrator",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "software_engineer",
    en_label: "SoftwareEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "senior_software_engineer",
    en_label: "SeniorSoftwareEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "project_manager",
    en_label: "ProjectManager",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "program_manager",
    en_label: "ProgramManager",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "programmer",
    en_label: "Programmer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "animator",
    en_label: "Animator",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "cyber_network_security",
    en_label: "Cyber/NetworkSecurity",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "ui_ux_designer",
    en_label: "UI/UXDesigner",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "web_graphic_designer",
    en_label: "Web/GraphicDesigner",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "data_analyst",
    en_label: "DataAnalyst",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "data_scientist",
    en_label: "DataScientist",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "system_analyst",
    en_label: "SystemAnalyst",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "quality_assurance_analyst",
    en_label: "QualityAssuranceAnalyst",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "zonal_manager",
    en_label: "ZonalManager",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "regional_manager",
    en_label: "RegionalManager",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "director",
    en_label: "Director",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "own_software_company",
    en_label: "OwnSoftwareCompany",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "software_developer",
    en_label: "SoftwareDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "web_developer",
    en_label: "WebDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "mobile_app_developer",
    en_label: "MobileAppDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "front_end_developer",
    en_label: "Front-EndDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "back_end_developer",
    en_label: "Back-EndDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "full_stack_developer",
    en_label: "Full-StackDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "ui_ux_designer",
    en_label: "UI/UXDesigner",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "senior_software_engineer",
    en_label: "SeniorSoftwareEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "systems_administrator",
    en_label: "SystemsAdministrator",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "database_administrator",
    en_label: "DatabaseAdministrator",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "quality_assurance_tester",
    en_label: "QualityAssurance(QA)Tester",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "it_support_specialist",
    en_label: "ITSupportSpecialist",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "dev_ops_engineer",
    en_label: "DevOpsEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "network_engineer",
    en_label: "NetworkEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "data_scientist",
    en_label: "DataScientist",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "data_analyst",
    en_label: "DataAnalyst",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "business_intelligence_developer",
    en_label: "BusinessIntelligenceDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "cloud_solutions_architect",
    en_label: "CloudSolutionsArchitect",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "it_project_manager",
    en_label: "ITProjectManager",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "scrum_master",
    en_label: "ScrumMaster",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "technical_writer",
    en_label: "TechnicalWriter",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "release_manager",
    en_label: "ReleaseManager",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "user_researcher",
    en_label: "UserResearcher",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "accessibility_specialist",
    en_label: "AccessibilitySpecialist",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "software_trainer",
    en_label: "SoftwareTrainer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "systems_integration_engineer",
    en_label: "SystemsIntegrationEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "application_support_analyst",
    en_label: "ApplicationSupportAnalyst",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "cloud_engineer",
    en_label: "CloudEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "chatbot_developer",
    en_label: "ChatbotDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "api_developer",
    en_label: "APIDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "e_commerce_developer",
    en_label: "E-commerceDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "microservices_developer",
    en_label: "MicroservicesDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "robotics_engineer",
    en_label: "RoboticsEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "automation_engineer",
    en_label: "AutomationEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "big_data_engineer",
    en_label: "BigDataEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "site_reliability_engineer",
    en_label: "SiteReliabilityEngineer(SRE)",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "software_development_manager",
    en_label: "SoftwareDevelopmentManager",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "it_director",
    en_label: "ITDirector",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "it_consultant",
    en_label: "ITConsultant",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "graphics_programmer",
    en_label: "GraphicsProgrammer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "video_game_tester",
    en_label: "VideoGameTester",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "ar_vr_content_creator",
    en_label: "AR/VRContentCreator",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "computational_scientist",
    en_label: "ComputationalScientist",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "it_auditor",
    en_label: "ITAuditor",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "computer_forensic_analyst",
    en_label: "ComputerForensicAnalyst",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "cloud_security_engineer",
    en_label: "CloudSecurityEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "blockchain_analyst",
    en_label: "BlockchainAnalyst",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "application_security_analyst",
    en_label: "ApplicationSecurityAnalyst",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "network_security_engineer",
    en_label: "NetworkSecurityEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "dev_sec_ops_engineer",
    en_label: "DevSecOpsEngineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "cloud_solutions_developer",
    en_label: "CloudSolutionsDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "systems_security_administrator",
    en_label: "SystemsSecurityAdministrator",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "robotics_process_automation_developer",
    en_label: "RoboticsProcessAutomation(RPA)Developer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "web_accessibility_consultant",
    en_label: "WebAccessibilityConsultant",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "data_privacy_officer",
    en_label: "DataPrivacyOfficer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "ai_ethics_consultant",
    en_label: "AIEthicsConsultant",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "cloud_security_architect",
    en_label: "CloudSecurityArchitect",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "sitecore_developer",
    en_label: "SitecoreDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "salesforce_developer",
    en_label: "SalesforceDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "react_developer",
    en_label: "ReactDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "angular_developer",
    en_label: "AngularDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "vue_js_developer",
    en_label: "Vue.jsDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "python_developer",
    en_label: "PythonDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "java_developer",
    en_label: "JavaDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "c#_developer",
    en_label: "C#Developer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "ruby_developer",
    en_label: "RubyDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "php_developer",
    en_label: "PHPDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "node_js_developer",
    en_label: "Node.jsDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "swift_developer",
    en_label: "SwiftDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "kotlin_developer",
    en_label: "KotlinDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "rust_developer",
    en_label: "RustDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "type_script_developer",
    en_label: "TypeScriptDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "go_developer",
    en_label: "GoDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "scala_developer",
    en_label: "ScalaDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "perl_developer",
    en_label: "PerlDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "htm_css_developer",
    en_label: "HTML/CSSDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "javascript_developer",
    en_label: "JavaScriptDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: ".net_developer",
    en_label: ".NETDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "android_developer",
    en_label: "AndroidDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "ios_developer",
    en_label: "iOSDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "windows_app_developer",
    en_label: "WindowsAppDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "linux_developer",
    en_label: "LinuxDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "mac_os_developer",
    en_label: "macOSDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "unity_developer",
    en_label: "UnityDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "unreal_engine_developer",
    en_label: "UnrealEngineDeveloper",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "3d_modeler",
    en_label: "3DModeler",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "cad_designer",
    en_label: "CADDesigner",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "ui_ux_researcher",
    en_label: "UI/UXResearcher",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "interaction_designer",
    en_label: "InteractionDesigner",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "product_designer",
    en_label: "ProductDesigner",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "service_designer",
    en_label: "ServiceDesigner",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "game_artist",
    en_label: "GameArtist",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "motion_graphics_designer",
    en_label: "MotionGraphicsDesigner",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "visual_designer",
    en_label: "VisualDesigner",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "user_interface_designer",
    en_label: "UserInterfaceDesigner",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "user_experience_designer",
    en_label: "UserExperienceDesigner",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "usability_tester",
    en_label: "UsabilityTester",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "accessibility_tester",
    en_label: "AccessibilityTester",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "quality_assurance_manager",
    en_label: "QualityAssuranceManager",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "test_lead",
    en_label: "TestLead",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "test_manager",
    en_label: "TestManager",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "test_architect",
    en_label: "TestArchitect",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "test_data_analyst",
    en_label: "TestDataAnalyst",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "chief_technology_officer",
    en_label: "ChiefTechnologyOfficer(CTO)",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "chief_information_officer",
    en_label: "ChiefInformationOfficer(CIO)",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "own_software_company",
    en_label: "OwnSoftwareCompany",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "software_eng",
    en_label: "Software Engineer",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "software_others",
    en_label: "Software Others",
    ta_label: null,
    p_o: ["software_engineer"],
  },
  {
    value: "engineering_trainee",
    en_label: "EngineeringTrainee",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "junior_engineer",
    en_label: "JuniorEngineer",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "assistant_engineer",
    en_label: "AssistantEngineer",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "senior_engineer",
    en_label: "SeniorEngineer",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "production_engineer",
    en_label: "ProductionEngineer",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "quality_assurance_engineer",
    en_label: "QualityAssuranceEngineer",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "assistant_manager",
    en_label: "AssistantManager",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "deputy_manager",
    en_label: "DeputyManager",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "engineering_supervisor",
    en_label: "EngineeringSupervisor",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "manager",
    en_label: "Manager",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "product_project_manager",
    en_label: "Product/ProjectManager",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "assistant_general_manager",
    en_label: "AssistantGeneralManager",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "deputy_general_manager",
    en_label: "DeputyGeneralManager",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "general_manager",
    en_label: "GeneralManager",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "senior_general_manager",
    en_label: "SeniorGeneralManager",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "assistant_vice_president",
    en_label: "AssistantVicePresident",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "vice_president",
    en_label: "VicePresident",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "director",
    en_label: "Director",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "engineering_scientist",
    en_label: "Engineeringscientist",
    ta_label: null,
    p_o: ["engineer_non_it"],
  },
  {
    value: "chartered_account",
    en_label: "CharteredAccount",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "company_secretary",
    en_label: "CompanySecretary",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "accounts_finance_professional",
    en_label: "Accounts/FinanceProfessional",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "auditor",
    en_label: "Auditor",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "financial_analyst",
    en_label: "FinancialAnalyst",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "tax_consultant",
    en_label: "TaxConsultant",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "financial_accountant",
    en_label: "FinancialAccountant",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "investment_professional",
    en_label: "InvestmentProfessional",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "stock_insurance_agent",
    en_label: "Stock/InsuranceAgent",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "investment_banker",
    en_label: "InvestmentBanker",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "accountant",
    en_label: "Accountant",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "financial_planner",
    en_label: "FinancialPlanner",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "loan_officer",
    en_label: "LoanOfficer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "credit_analyst",
    en_label: "CreditAnalyst",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "risk_analyst",
    en_label: "RiskAnalyst",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "asset_manager",
    en_label: "AssetManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "stockbroker",
    en_label: "Stockbroker",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "mortgage_broker",
    en_label: "MortgageBroker",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "actuary",
    en_label: "Actuary",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "fund_manager",
    en_label: "FundManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "corporate_treasurer",
    en_label: "CorporateTreasurer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "investment_analyst",
    en_label: "InvestmentAnalyst",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "compliance_officer",
    en_label: "ComplianceOfficer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "insurance_underwriter",
    en_label: "InsuranceUnderwriter",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "wealth_manager",
    en_label: "WealthManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "financial_controller",
    en_label: "FinancialController",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "portfolio_manager",
    en_label: "PortfolioManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "finance_manager",
    en_label: "FinanceManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "budget_analyst",
    en_label: "BudgetAnalyst",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "economic_analyst",
    en_label: "EconomicAnalyst",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "quantitative_analyst",
    en_label: "Quantitative Analyst",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "accounts_finance_others",
    en_label: "Accounts/Finance others",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "clerk",
    en_label: "Clerk",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "data_entry_operator",
    en_label: "DataEntryOperator",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_engineer",
    en_label: "JuniorEngineer",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_accountant",
    en_label: "JuniorAccountant",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_stenographer",
    en_label: "JuniorStenographer",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_technician",
    en_label: "JuniorTechnician",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_assistant",
    en_label: "JuniorAssistant",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_inspector",
    en_label: "JuniorInspector",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_draftsman",
    en_label: "JuniorDraftsman",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_laboratory_technician",
    en_label: "JuniorLaboratoryTechnician",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_research_assistant",
    en_label: "JuniorResearchAssistant",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_translator",
    en_label: "JuniorTranslator",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_librarian",
    en_label: "JuniorLibrarian",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_technical_officer",
    en_label: "JuniorTechnicalOfficer",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "junior_scientist",
    en_label: "JuniorScientist",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "assistant_manager",
    en_label: "AssistantManager",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "deputy_manager",
    en_label: "DeputyManager",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_engineer",
    en_label: "SeniorEngineer",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_accountant",
    en_label: "SeniorAccountant",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_stenographer",
    en_label: "SeniorStenographer",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_technician",
    en_label: "SeniorTechnician",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_assistant",
    en_label: "SeniorAssistant",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_inspector",
    en_label: "SeniorInspector",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_draftsman",
    en_label: "SeniorDraftsman",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_laboratory_technician",
    en_label: "SeniorLaboratoryTechnician",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_research_officer",
    en_label: "SeniorResearchOfficer",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_translator",
    en_label: "SeniorTranslator",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_librarian",
    en_label: "SeniorLibrarian",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_technical_officer",
    en_label: "SeniorTechnicalOfficer",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_scientist",
    en_label: "SeniorScientist",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "manager",
    en_label: "Manager",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "general_manager",
    en_label: "GeneralManager",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_engineer",
    en_label: "ChiefEngineer",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_accountant",
    en_label: "ChiefAccountant",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_stenographer",
    en_label: "ChiefStenographer",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_technician",
    en_label: "ChiefTechnician",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_assistant",
    en_label: "ChiefAssistant",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_inspector",
    en_label: "ChiefInspector",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_draftsman",
    en_label: "ChiefDraftsman",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_laboratory_technician",
    en_label: "ChiefLaboratoryTechnician",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_research_officer",
    en_label: "ChiefResearchOfficer",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_translator",
    en_label: "ChiefTranslator",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_librarian",
    en_label: "ChiefLibrarian",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_technical_officer",
    en_label: "ChiefTechnicalOfficer",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "chief_scientist",
    en_label: "ChiefScientist",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "director",
    en_label: "Director",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "government_psu_others",
    en_label: "Government/PSUothers",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "lawyer",
    en_label: "Lawyer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "legal_consultant",
    en_label: "LegalConsultant",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "legal_assistant",
    en_label: "LegalAssistant",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "corporate_legal_advisor",
    en_label: "CorporateLegalAdvisor",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "attorney",
    en_label: "Attorney",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "solicitor",
    en_label: "Solicitor",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "barrister",
    en_label: "Barrister",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "legal_consultant",
    en_label: "LegalConsultant",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "corporate_lawyer",
    en_label: "CorporateLawyer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "family_lawyer",
    en_label: "FamilyLawyer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "criminal_defense_lawyer",
    en_label: "CriminalDefenseLawyer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "patent_attorney",
    en_label: "PatentAttorney",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "real_estate_lawyer",
    en_label: "RealEstateLawyer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "immigration_lawyer",
    en_label: "ImmigrationLawyer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "environmental_lawyer",
    en_label: "EnvironmentalLawyer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "tax_lawyer",
    en_label: "TaxLawyer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "employment_lawyer",
    en_label: "EmploymentLawyer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "civil_litigation_lawyer",
    en_label: "CivilLitigationLawyer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "intellectual_property_lawyer",
    en_label: "IntellectualPropertyLawyer",
    ta_label: null,
    p_o: ["private", "government_psu"],
  },
  {
    value: "director_general_of_police",
    en_label: "DirectorGeneralofPolice",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "special_director_general_of_police",
    en_label: "SpecialDirectorGeneralofPolice",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "additional_director_general_of_police",
    en_label: "AdditionalDirectorGeneralofPolice",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "inspector_general_of_police",
    en_label: "InspectorGeneralofPolice",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "deputy_inspector_general_of_police",
    en_label: "DeputyInspectorGeneralofPolice",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "superintendent_of_police",
    en_label: "SuperintendentofPolice",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "additional_superintendent_of_police",
    en_label: "AdditionalSuperintendentofPolice",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "assistant_sp",
    en_label: "AssistantSP",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "deputy_sp",
    en_label: "DeputySP",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "inspector",
    en_label: "Inspector",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "sub_inspector",
    en_label: "SubInspector",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "sub_inspector_of_police",
    en_label: "SubInspectorofPolice",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "head_constable",
    en_label: "HeadConstable",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "senior_constable",
    en_label: "SeniorConstable",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "police_constable",
    en_label: "PoliceConstable",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "law_enforcement_officer",
    en_label: "LawEnforcementOfficer",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "constable",
    en_label: "Constable",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "police_department",
    en_label: "Policedepartment",
    ta_label: null,
    p_o: ["government_psu"],
  },
  {
    value: "marketing_manager",
    en_label: "MarketingManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "sales_manager",
    en_label: "SalesManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "brand_manager",
    en_label: "BrandManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "product_manager",
    en_label: "ProductManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "advertising_manager",
    en_label: "AdvertisingManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "public_relations_manager",
    en_label: "PublicRelationsManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "digital_marketing_manager",
    en_label: "DigitalMarketingManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "social_media_manager",
    en_label: "SocialMediaManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "content_marketing_manager",
    en_label: "ContentMarketingManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "market_research_analyst",
    en_label: "MarketResearchAnalyst",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "business_development_manager",
    en_label: "BusinessDevelopmentManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "account_executive",
    en_label: "AccountExecutive",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "sales_representative",
    en_label: "SalesRepresentative",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "marketing_coordinator",
    en_label: "MarketingCoordinator",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "event_manager",
    en_label: "EventManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "e_commerce_manager",
    en_label: "E-commerceManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "marketing_director",
    en_label: "MarketingDirector",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "healthcare_professional",
    en_label: "HealthcareProfessional",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "paramedical_professional",
    en_label: "ParamedicalProfessional",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "nurse",
    en_label: "Nurse",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "pharmacist",
    en_label: "Pharmacist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "physiotherapist",
    en_label: "Physiotherapist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "psychologist",
    en_label: "Psychologist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "therapist",
    en_label: "Therapist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "medical_transcriptionist",
    en_label: "MedicalTranscriptionist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "dietician_nutritionist",
    en_label: "Dietician/Nutritionist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "lab_technician",
    en_label: "LabTechnician",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "medical_representative",
    en_label: "MedicalRepresentative",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "journalist",
    en_label: "Journalist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "media_professional",
    en_label: "MediaProfessional",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "sound_graphics_engineer",
    en_label: "Sound/GraphicsEngineer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "event_management_professional",
    en_label: "EventManagementProfessional",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "advertising",
    en_label: "Advertising",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "pr_professional",
    en_label: "PRProfessional",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "designer",
    en_label: "Designer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "artist",
    en_label: "Artist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "actor",
    en_label: "Actor",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "actress",
    en_label: "Actress",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "director",
    en_label: "Director",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "producer",
    en_label: "Producer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "screenwriter",
    en_label: "Screenwriter",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "cinematographer",
    en_label: "Cinematographer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "film_editor",
    en_label: "FilmEditor",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "art_director",
    en_label: "ArtDirector",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "set_designer",
    en_label: "SetDesigner",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "sound_engineer",
    en_label: "SoundEngineer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "music_composer",
    en_label: "MusicComposer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "costume_designer",
    en_label: "CostumeDesigner",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "makeup_artist",
    en_label: "MakeupArtist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "stunt_performer",
    en_label: "StuntPerformer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "talent_agent",
    en_label: "TalentAgent",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "casting_director",
    en_label: "CastingDirector",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "public_relations_specialist",
    en_label: "PublicRelationsSpecialist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "event_coordinator",
    en_label: "EventCoordinator",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "video_game_developer",
    en_label: "VideoGameDeveloper",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "animator",
    en_label: "Animator",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "graphic_designer",
    en_label: "GraphicDesigner",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "radio_host",
    en_label: "RadioHost",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "tv_host",
    en_label: "TVHost",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "social_media",
    en_label: "SocialMedia",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "entertainment_lawyer",
    en_label: "EntertainmentLawyer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "entertainment_publicist",
    en_label: "EntertainmentPublicist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "talent_manager",
    en_label: "TalentManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "post_production_supervisor",
    en_label: "Post-productionSupervisor",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "production_assistant",
    en_label: "ProductionAssistant",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "entertainment_accountant",
    en_label: "EntertainmentAccountant",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "special_effects_artist",
    en_label: "SpecialEffectsArtist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "voice_actor",
    en_label: "VoiceActor",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "location_scout",
    en_label: "LocationScout",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "music_producer",
    en_label: "MusicProducer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "dance_choreographer",
    en_label: "DanceChoreographer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "music_video_director",
    en_label: "MusicVideoDirector",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "film_critic",
    en_label: "FilmCritic",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "film_distributor",
    en_label: "FilmDistributor",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "media_sales_representative",
    en_label: "MediaSalesRepresentative",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "video_editor",
    en_label: "VideoEditor",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "entertainment_event_planner",
    en_label: "EntertainmentEventPlanner",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "visual_effects_artist",
    en_label: "VisualEffectsArtist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "casting_agent",
    en_label: "CastingAgent",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "camera_operator",
    en_label: "CameraOperator",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "entertainment_marketing_manager",
    en_label: "EntertainmentMarketingManager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "entertainment_news_anchor",
    en_label: "EntertainmentNewsAnchor",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "music_promoter",
    en_label: "MusicPromoter",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "lighting_designer",
    en_label: "LightingDesigner",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "entertainment_technician",
    en_label: "EntertainmentTechnician",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "vj",
    en_label: "VJ(VideoJockey)",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "rj",
    en_label: "RJ(RadioJockey)",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "youtuber",
    en_label: "YouTuber",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "ceo_cfo_cto_cio_cxo",
    en_label: "CEO/CFO/CTO/CIO/CXO",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "president_director_chairman",
    en_label: "President/Director/Chairman",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "vp_avp_gm_dgm_agm",
    en_label: "VP/AVP/GM/DGM/AGM",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "painter",
    en_label: "Painter",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "sculptor",
    en_label: "Sculptor",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "photographer",
    en_label: "Photographer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "illustrator",
    en_label: "Illustrator",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "graphicDesigner",
    en_label: "GraphicDesigner",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "printmaker",
    en_label: "Printmaker",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "digital_artist",
    en_label: "DigitalArtist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "ceramicist",
    en_label: "Ceramicist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "glassblower",
    en_label: "Glassblower",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "jewelry_designer",
    en_label: "JewelryDesigner",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "fashion_designer",
    en_label: "Fashion Designer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "textile_artist",
    en_label: "Textile Artist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "cartoonist",
    en_label: "Cartoonist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "visual_artist",
    en_label: "VisualArtist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "installation_artist",
    en_label: "InstallationArtist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "collage_artist",
    en_label: "CollageArtist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "mixed_media_artist",
    en_label: "MixedMediaArtist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "engraver",
    en_label: "Engraver",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "woodworker",
    en_label: "Woodworker",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "metal_artist",
    en_label: "MetalArtist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "paper_artist",
    en_label: "PaperArtist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "calligrapher",
    en_label: "Calligrapher",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "tattoo_atist",
    en_label: "TattooArtist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "makeup_artist",
    en_label: "MakeupArtist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "hair_stylist",
    en_label: "HairStylist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "performance_artist",
    en_label: "PerformanceArtist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "dancer",
    en_label: "Dancer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "choreographer",
    en_label: "Choreographer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "actor",
    en_label: "Actor",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "actress",
    en_label: "Actress",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "singer",
    en_label: "Singer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "musician",
    en_label: "Musician",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "composer",
    en_label: "Composer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "dj",
    en_label: "DJ(DiscJockey)",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "band_member",
    en_label: "BandMember",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "sound_engineer",
    en_label: "SoundEngineer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "lighting_designer",
    en_label: "LightingDesigner",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "stage_designer",
    en_label: "StageDesigner",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "makeup_artist",
    en_label: "MakeupArtist(Theatrical)",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "special_effects_artist",
    en_label: "SpecialEffectsArtist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "textile_manufacturing_business",
    en_label: "TextileManufacturingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "food_processing_business",
    en_label: "FoodProcessingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "electronics_manufacturing_business",
    en_label: "ElectronicsManufacturingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "automotive_manufacturing_business",
    en_label: "AutomotiveManufacturingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "furniture_production_business",
    en_label: "FurnitureProductionBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "metal_fabrication_business",
    en_label: "MetalFabricationBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "pharmaceutical_manufacturing_business",
    en_label: "PharmaceuticalManufacturingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "paper_and_printing_business",
    en_label: "PaperandPrintingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "plastic_product_manufacturing_business",
    en_label: "PlasticProductManufacturingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "woodworking_and_carpentry_business",
    en_label: "WoodworkingandCarpentryBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "sole_proprietorship_business",
    en_label: "SoleProprietorshipBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "partnership_business",
    en_label: "PartnershipBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "limited_liability_company_business",
    en_label: "LimitedLiabilityCompany(LLC)Business",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "corporation_business",
    en_label: "CorporationBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "s_corporation_business",
    en_label: "SCorporationBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "cooperative_business",
    en_label: "CooperativeBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "nonprofit_organization_business",
    en_label: "NonprofitOrganizationBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "franchise_business",
    en_label: "FranchiseBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "limited_partnership_business",
    en_label: "LimitedPartnershipBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "joint_venture_business",
    en_label: "JointVentureBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "holding_company_business",
    en_label: "HoldingCompanyBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "e_commerce_business",
    en_label: "E-commerceBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "manufacturing_business",
    en_label: "ManufacturingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "retail_business",
    en_label: "RetailBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "service_business",
    en_label: "ServiceBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "import_export_business",
    en_label: "Import/ExportBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "real_estate_business",
    en_label: "RealEstateBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "restaurant_food_service_business",
    en_label: "Restaurant/FoodServiceBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "agriculture_business",
    en_label: "AgricultureBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "technology_startup_business",
    en_label: "TechnologyStartupBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "consulting_firm_business",
    en_label: "ConsultingFirmBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "health_and_wellness_business",
    en_label: "HealthandWellnessBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "entertainment_and_media_business",
    en_label: "EntertainmentandMediaBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "tourism_and_hospitality_business",
    en_label: "TourismandHospitalityBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "environmental_and_sustainable_business",
    en_label: "EnvironmentalandSustainableBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "freelancing_business",
    en_label: "FreelancingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "online_marketplace_business",
    en_label: "OnlineMarketplaceBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "health_care_practice_business",
    en_label: "HealthCarePracticeBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "educational_institution_business",
    en_label: "EducationalInstitutionBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "financial_services_business",
    en_label: "FinancialServicesBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "legal_services_business",
    en_label: "LegalServicesBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "transportation_and_logistics_business",
    en_label: "TransportationandLogisticsBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "construction_and_contracting_business",
    en_label: "ConstructionandContractingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "architecture_and_design_business",
    en_label: "ArchitectureandDesignBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "home_improvement_business",
    en_label: "HomeImprovementBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "fitness_and_recreation_business",
    en_label: "FitnessandRecreationBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "beauty_and_wellness_business",
    en_label: "BeautyandWellnessBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "event_planning_business",
    en_label: "EventPlanningBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "it_and_software_development_business",
    en_label: "ITandSoftwareDevelopmentBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "marketing_and_advertising_business",
    en_label: "MarketingandAdvertisingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "real_estate",
    en_label: "RealEstate",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "real_estate_agency_business",
    en_label: "RealEstateAgencyBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "pet_care_services_business",
    en_label: "PetCareServicesBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "childcare_and_education_business",
    en_label: "ChildcareandEducationBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "automotive_services_business",
    en_label: "AutomotiveServicesBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "arts_and_crafts_business",
    en_label: "ArtsandCraftsBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "cleaning_services_business",
    en_label: "CleaningServicesBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "travel_and_tourism_business",
    en_label: "TravelandTourismBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "home_appliance_repair_business",
    en_label: "HomeApplianceRepairBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "photography_and_videography_business",
    en_label: "PhotographyandVideographyBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "waste_management_and_recycling_business",
    en_label: "WasteManagementandRecyclingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "clothing_boutique",
    en_label: "ClothingBoutique",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "electronics_store",
    en_label: "ElectronicsStore",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "mobile_phone_shop",
    en_label: "MobilePhoneShop",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "jewellery_store",
    en_label: "JewelleryStore",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "footwear_retailer",
    en_label: "FootwearRetailer",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "supermarket",
    en_label: "Supermarket",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "hypermarket",
    en_label: "Hypermarket",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "traditional_handicraft_store",
    en_label: "TraditionalHandicraftStore",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "bookstore",
    en_label: "Bookstore",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "furniture_showroom",
    en_label: "FurnitureShowroom",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "stationery_and_office_supplies_store",
    en_label: "StationeryandOfficeSuppliesStore",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "vegetables_and_fruits_market_stall",
    en_label: "VegetablesandFruitsMarketStall",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "cosmetics_and_beauty_store",
    en_label: "CosmeticsandBeautyStore",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "home_appliance_retailer",
    en_label: "HomeApplianceRetailer",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "grocery_store_business",
    en_label: "GroceryStoreBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "clothing_boutique_business",
    en_label: "ClothingBoutiqueBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "electronics_store_business",
    en_label: "ElectronicsStoreBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "mobile_phone_shop_business",
    en_label: "MobilePhoneShopBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "jewellery_making_business",
    en_label: "JewellerymakingBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "jewellery_store_business",
    en_label: "JewelleryStoreBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "footwear_retailer_business",
    en_label: "FootwearRetailerBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "supermarket_business",
    en_label: "SupermarketBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "hypermarket_business",
    en_label: "HypermarketBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "traditional_handicraft_store_business",
    en_label: "TraditionalHandicraftStoreBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "bookstore_business",
    en_label: "BookstoreBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "furniture_showroom_business",
    en_label: "FurnitureShowroomBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "stationery_and_office_supplies_store_business",
    en_label: "StationeryandOfficeSuppliesStoreBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "vegetables_and_fruits_market_stall_business",
    en_label: "VegetablesandFruitsMarketStallBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "cosmetics_and_beauty_store_business",
    en_label: "CosmeticsandBeautyStoreBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "home_appliance_retailer_business",
    en_label: "HomeApplianceRetailerBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "medical_store",
    en_label: "Medicalstore",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "agriculture_fertilizer_business",
    en_label: "Agriculture/FertilizerBusiness",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "small_business_owner",
    en_label: "SmallBusinessOwner",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "startup_founder",
    en_label: "StartupFounder",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "franchise_owner",
    en_label: "FranchiseOwner",
    ta_label: null,
    p_o: ["business"],
  },
  {
    value: "hotel_hospitality_professional",
    en_label: "Hotel/HospitalityProfessional",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "restaurant_catering_professional",
    en_label: "Restaurant/CateringProfessional",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "chef_cook",
    en_label: "Chef/Cook",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "event_planner",
    en_label: "EventPlanner",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "own_hotel",
    en_label: "OwnHotel",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "own_restaurant",
    en_label: "OwnRestaurant",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "beautician",
    en_label: "Beautician",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "hair_stylist",
    en_label: "HairStylist",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "spa_manager",
    en_label: "Spa Manager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "beauty_consultant_sales_representative",
    en_label: "Beauty Consultant/Sales Representative",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "fashion_designer",
    en_label: "Fashion Designer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "costume_textile_designer",
    en_label: "Costume/Textile Designer",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "designer_others",
    en_label: "Designer(others)",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "own_studio",
    en_label: "Own Studio",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "bpo_kpo_ites_professional",
    en_label: "BPO/KPO/ITESProfessional",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "customer_service_professional",
    en_label: "Customer Service Professional",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "call_center_agent",
    en_label: "CallCenterAgent",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "customer_support_supervisor_manager",
    en_label: "Customer Support Supervisor / Manager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "bpo_team_leader",
    en_label: "BPOTeamLeader",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "bpo_operations_manager",
    en_label: "BPO Operations Manager",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "bpo_owner_business",
    en_label: "BPO Owner/business",
    ta_label: null,
    p_o: ["private"],
  },
  {
    value: "technician",
    en_label: "Technician",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "art_craftsman",
    en_label: "Art&Craftsman",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "librarian",
    en_label: "Librarian",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "transportation_logistics",
    en_label: "Transportation/Logistics",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "agent_broker_trader",
    en_label: "Agent/Broker/Trader",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "contractor",
    en_label: "Contractor",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "fitness_professional",
    en_label: "FitnessProfessional",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "security_professional",
    en_label: "Security Professional",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "social_worker_ngo_volunteer",
    en_label: "SocialWorker/NGO/Volunteer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "singer",
    en_label: "Singer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "writer",
    en_label: "Writer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "associate",
    en_label: "Associate",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "builder",
    en_label: "Builder",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "cnc_operator",
    en_label: "CNCoperator",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "distributor",
    en_label: "Distributor",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "driver",
    en_label: "Driver",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "freelancer",
    en_label: "Freelancer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "mechanic",
    en_label: "Mechanic",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "musician",
    en_label: "Musician",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "photo_videographer",
    en_label: "Photo/Videographer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "tailors",
    en_label: "Tailors",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "others",
    en_label: "Others",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "freelance_writer",
    en_label: "Freelancewriter",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "graphic_designer",
    en_label: "Graphic designer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "web_developer",
    en_label: "Web Developer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "content_creator_you_tuber_blogger_podcaster",
    en_label: "Content Creator(YouTuber, Blogger, Pod Caster)",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "digital_marketing_consultant",
    en_label: "Digital Marketing Consultant",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "photographer",
    en_label: "Photographer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "event_planner",
    en_label: "Eventplanner",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "fitness_trainer",
    en_label: "Fitnesstrainer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "yoga_instructor",
    en_label: "Yogainstructor",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "personal_chef_or_caterer",
    en_label: "Personalcheforcaterer",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "mobile_app_developer",
    en_label: "Mobileappdeveloper",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "real_estate_agent",
    en_label: "Realestateagent",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "tour_guide",
    en_label: "Tourguide",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "music_teacher",
    en_label: "Musicteacher",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "artist_painter_sculptor_etc",
    en_label: "Artist(painter,sculptor,etc.)",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "wedding_planner",
    en_label: "Weddingplanner",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "tailor_or_fashion_designer",
    en_label: "Tailororfashiondesigner",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "beauty_therapist_or_makeup_artist",
    en_label: "Beautytherapistormakeupartist",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "tuition_teacher",
    en_label: "Tuitionteacher",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "fitness_coach",
    en_label: "Fitnesscoach",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "handicraft_seller_online_or_in_markets",
    en_label: "Handicraftseller(onlineorinmarkets)",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "consultant_management_finance_hr_etc",
    en_label: "Consultant(management,finance,HR,etc.)",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "interior_designer",
    en_label: "Interiordesigner",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "software_consultant",
    en_label: "Softwareconsultant",
    ta_label: null,
    p_o: ["self_employed"],
  },
  {
    value: "e_commerce_seller_selling_products_online",
    en_label: "E-commerceseller(sellingproductsonline)",
    ta_label: null,
    p_o: ["self_employed"],
  },
];

import React from "react";
import { Box, Center, HStack, Pressable, Skeleton, Text } from "native-base";
import { Dimensions } from "react-native";
import MemberAvatar from "./member_avatar";
import moment from "moment";
import { CalculateAge } from "@helpers/calculate_age";
import { navigate } from "@helpers/navigator";
import { getProfileUrl } from "@helpers/get_profile_url";

const MemberSquareViewSimilarMatches = (props) => {
  const { member, loading } = props;
  return (
    <>
      {!member || loading === true ? (
        <>
          <HStack>
            <Center
              width={180}
              height={180}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                alignItems={"center"}
                justifyContent={"center"}
                w={"95%"}
                h={"95%"}
                bg="white"
                rounded={"5px"}
                padding={5}
                shadow={2}
                marginLeft={10}
              >
                <Skeleton
                  alignItems={"center"}
                  justifyContent={"center"}
                  w={"75%"}
                  h={"75%"}
                  bg="white"
                  // rounded={"5px"}
                  shadow={2}
                  rounded="full"
                />
                <Skeleton.Text width={"60%"} my={2} lines={1} />
                <Skeleton.Text width={"60%"} lines={1} />
              </Box>
            </Center>
            <Center
              marginLeft={5}
              width={180}
              height={180}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                alignItems={"center"}
                justifyContent={"center"}
                w={"95%"}
                h={"95%"}
                bg="white"
                rounded={"5px"}
                padding={5}
                shadow={2}
                marginLeft={10}
              >
                <Skeleton
                  alignItems={"center"}
                  justifyContent={"center"}
                  w={"75%"}
                  h={"75%"}
                  bg="white"
                  // rounded={"5px"}
                  shadow={2}
                  rounded="full"
                />
                <Skeleton.Text width={"60%"} my={2} lines={1} />
                <Skeleton.Text width={"60%"} lines={1} />
              </Box>
            </Center>
            <Center
              marginLeft={5}
              width={180}
              height={180}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                alignItems={"center"}
                justifyContent={"center"}
                w={"95%"}
                h={"95%"}
                bg="white"
                rounded={"5px"}
                padding={5}
                shadow={2}
                marginLeft={10}
              >
                <Skeleton
                  alignItems={"center"}
                  justifyContent={"center"}
                  w={"75%"}
                  h={"75%"}
                  bg="white"
                  // rounded={"5px"}
                  shadow={2}
                  rounded="full"
                />
                <Skeleton.Text width={"60%"} my={2} lines={1} />
                <Skeleton.Text width={"60%"} lines={1} />
              </Box>
            </Center>
            <Center
              marginLeft={5}
              width={180}
              height={180}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                alignItems={"center"}
                justifyContent={"center"}
                w={"95%"}
                h={"95%"}
                bg="white"
                rounded={"5px"}
                padding={5}
                shadow={2}
                marginLeft={10}
              >
                <Skeleton
                  alignItems={"center"}
                  justifyContent={"center"}
                  w={"75%"}
                  h={"75%"}
                  bg="white"
                  // rounded={"5px"}
                  shadow={2}
                  rounded="full"
                />
                <Skeleton.Text width={"60%"} my={2} lines={1} />
                <Skeleton.Text width={"60%"} lines={1} />
              </Box>
            </Center>
          </HStack>
        </>
      ) : (
        <Center
          width={180}
          height={180}
          alignItems={"center"}
          justifyContent={"center"}
          marginRight={"15px"}
        >
          <Pressable
            alignItems={"center"}
            justifyContent={"center"}
            w={"95%"}
            h={"95%"}
            bg="white"
            rounded={"5px"}
            padding={5}
            shadow={2}
            onPress={() => {
              // window.history.replaceState(null, null, `/member/${member?.id}`);
              navigate(`/member/${member?.id}`);
            }}
          >
            <Box justifyContent="center">
              <MemberAvatar
                src={getProfileUrl(
                  member?.member_cover_photo?.cropped_photo_url,
                  member?.member_profile?.gender
                )}
                width={120}
                height={120}
              />
            </Box>
            <Box alignItems="center" justifyContent="center" h={45}>
              <Box alignItems="center" justifyContent="center">
                <Text fontSize={14} fontWeight={"700"} mt={2}>
                  {member?.member_profile?.name}
                </Text>
              </Box>

              {/* <Box alignItems="center" justifyContent="center">
            <Text fontSize={12} fontWeight={"700"}>
              {member?.recentText}
            </Text>
          </Box> */}

              <Box alignItems="center" justifyContent="center">
                <Text color="#64748B" fontSize={10} fontWeight={"400"}>
                  {CalculateAge(member?.member_profile?.dob)}
                </Text>
              </Box>
            </Box>
          </Pressable>
        </Center>
      )}
    </>
  );
};
export default MemberSquareViewSimilarMatches;

import { navigate } from "@helpers/navigator";
import { storeItem } from "@helpers/storage";
import {
  dynamicClear,
  dynamicRequest,
  getUserLoginByOTP,
  getUserOTP,
  getUserTokenByAdmin,
  useDynamicSelector,
} from "@services/redux";
import { Button, Input } from "antd";
import {
  Box,
  Card,
  Center,
  Hidden,
  HStack,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const OperatorLoginByUser = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    status,
    user_id,
    name,
    token,
    gender,
    profile_id,
    promo_code,
    is_profile_added,
    is_preference_added,
    main_photo,
    session_id: user_session_id,
    loading,
    error: login_error,
  } = useDynamicSelector("userLoginByOTP");

  const {
    status: otp_status,
    loading: send_otp_loading,
    error,
  } = useDynamicSelector("initializeUserSessionByAdmin");
  const [userName, setUserName] = useState("");
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (otp_status === "success") {
      toast.success("OTP Sent");
      // setIsModalOpen(true);
    } else if (error) {
      if (error?.message === "invalid user") {
        toast.error("Invalid user");
      } else {
        toast.error(error?.message);
      }
    }
  }, [otp_status]);

  const UserOTP = () => {
    let key = [{ key: "initializeUserSessionByAdmin", loading: true }];
    let query = getUserOTP;
    let variables = { username: userName };
    dispatch(dynamicRequest(key, query, variables));
  };

  const userLoginByOtp = () => {
    let key = [{ key: "userLoginByOTP", loading: true }];
    let query = getUserLoginByOTP;
    let variables = { username: userName, otp: otp };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleValidation();
    }
  };
  const handleValidation = () => {
    if (userName.length === 10 && otp.length) {
      userLoginByOtp();
    } else if (userName.length < 10 || userName.length > 10) {
      toast.error("Please enter valid mobile number");
    } else if (!otp.length) {
      toast.error("Please enter valid otp");
    }
  };

  useEffect(() => {
    if (login_error) {
      if (login_error?.message === "invalid otp") {
        toast.error("Invalid OTP");
      } else {
        toast.error(login_error?.message);
      }
    }
    if (status === "success") {
      storeItem("is_profile_added", is_profile_added);
      storeItem("is_preference_added", is_preference_added);

      storeItem("name", name);
      storeItem("user_id", user_id);
      storeItem("user_session_id", user_session_id);

      navigate("/operator/matches");
      dispatch(dynamicClear("userLoginByOTP"));
      dispatch(dynamicClear("initializeUserSessionByAdmin"));
    }
  }, [status, name, is_profile_added, is_preference_added, login_error]);

  return (
    <Box marginTop={20} alignItems={"center"} flex={1}>
      <Card
        alignItems={"center"}
        justifyContent={"center"}
        height={300}
        width={600}
      >
        <Box my={5} width={800}>
          <Text bold fontSize={20} color={"#712e5a"} textAlign={"center"}>
            {"Login"}
          </Text>
        </Box>
        <Box mx={5}>
          <VStack>
            <Box width={300}>
              <Text pt={2} color={"#64748B"} fontWeight={"600"} fontSize={15}>
                Mobile Number
              </Text>
              <Input
                placeholder="Mobile Number"
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                value={userName}
              />
              {otp_status === "success" && (
                <>
                  <Text
                    pt={2}
                    color={"#64748B"}
                    fontWeight={"600"}
                    fontSize={15}
                  >
                    OTP
                  </Text>

                  <Input
                    w={{
                      base: "100%",
                    }}
                    placeholder="OTP"
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                    value={otp}
                    onPressEnter={handleKeypress}
                  />
                </>
              )}
            </Box>
          </VStack>
        </Box>
        <Box marginTop={5} width={300}>
          <Button
            loading={loading || send_otp_loading}
            type="primary"
            onClick={() => {
              otp_status === "success" ? handleValidation() : UserOTP();

              // navigate("/operator/matches");
            }}
          >
            {otp_status === "success" ? " SIGN IN" : "SEND OTP"}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};
export default OperatorLoginByUser;

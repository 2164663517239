import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, HStack } from "native-base";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import MemberDetailsCardView from "../components/admin_verification/member_details_card_view";
import {
  dynamicClear,
  dynamicRequest,
  getMember,
  getMemberForAdmin,
  useDynamicSelector,
} from "@services/redux";
import { Button } from "antd";
import { storeItem } from "@helpers/storage";

const UserDetails = () => {
  const dispatch = useDispatch();
  const { user_id } = useParams();

  const [actionItem, setActionItem] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    storeItem("user_session_id", null);
    dispatch(dynamicClear("getUserSession"));
  }, []);

  const getMemberById = () => {
    let key = [{ key: "member_for_admin", loading: true }];
    let query = getMemberForAdmin;
    let variables = { id: user_id };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getMemberById();
  }, [user_id]);

  return (
    <>
      <Box width="100%">
        <MemberDetailsCardView />
      </Box>
    </>
  );
};
export default UserDetails;

export const SmokingHabitsOptions = [
  {
    value: "any",
    en_label: "Any",
    ta_label: "ஏதேனும்",
    p_o: [],
  },
  {
    value: "no",
    en_label: "No",
    ta_label: "இல்லை",
    p_o: [],
  },
  {
    value: "occasionally",
    en_label: "Occasionally",
    ta_label: "எப்போதாவது",
    p_o: [],
  },
  {
    value: "yes",
    en_label: "Yes",
    ta_label: "ஆம்",
    p_o: [],
  },
];

import {
  Box,
  Card,
  Divider,
  Hidden,
  HStack,
  Skeleton,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input } from "antd";
import "../../../index.css";

import { useDispatch } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { AgeFromOptions } from "@master_data/preference_json/age_from";
import { AgeToOptions } from "@master_data/preference_json/age_to";
import { MaritalStatusOptions } from "@master_data/common_json/marital_status";
import { MotherTongueOptions } from "@master_data/common_json/mother_tongue";
import { EatingHabitsOptions } from "@master_data/common_json/eating_habits";
import { DrinkingHabitsOptions } from "@master_data/common_json/drinking_habits";
import { SmokingHabitsOptions } from "@master_data/common_json/smoking_habits";
import { HeightToOptions } from "@master_data/preference_json/height_to";
import { HeightFromOptions } from "@master_data/preference_json/height_from";
import { PhysicalStatusOptions } from "@master_data/common_json/physical_status";
import { useDynamicSelector } from "@services/redux";

const UserPreferenceFormBasicDetailsOperator = (props) => {
  const { getOptions, form } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [age_to_values, setAgeToValues] = useState([]);
  const [age_from_values, setAgeFromValues] = useState([]);
  const [height_to_values, setHeightToValues] = useState([]);
  const [height_from_values, setHeightFromValues] = useState([]);

  const age_value = Form.useWatch("age_from", form);
  const age_to_value = Form.useWatch("age_to", form);
  const height_value = Form.useWatch("height_from", form);
  const height_to_value = Form.useWatch("height_to", form);

  const { loading: my_preference_loading } =
    useDynamicSelector("my_preference");

  useEffect(() => {
    let _options = [];
    let _options1 = [];

    if (AgeFromOptions || AgeToOptions) {
      _options = AgeFromOptions;
      _options1 = AgeToOptions;
      setAgeFromValues(_options);
    }
    for (let i = 0; i < _options?.length; i++) {
      let temp_value1 = _options?.[i];
      if (temp_value1?.value === age_value) {
        let temp_value3 = _options1.filter((x) => {
          return x.value >= temp_value1?.value;
        });
        setAgeToValues(temp_value3);
      }
    }

    for (let j = 0; j < _options1?.length; j++) {
      let temp_value1 = _options1?.[j];

      if (temp_value1?.value === age_to_value) {
        let temp_value3 = _options.filter((x) => {
          return x.value <= temp_value1?.value;
        });
        setAgeFromValues(temp_value3);
      }
    }
  }, [age_value, AgeFromOptions, AgeToOptions, age_to_value]);
  useEffect(() => {
    let _options = [];
    let _options1 = [];

    if (HeightFromOptions || HeightToOptions) {
      _options = HeightFromOptions;
      _options1 = HeightToOptions;
      setHeightFromValues(_options);
    }
    for (let i = 0; i < _options?.length; i++) {
      let temp_value1 = _options?.[i];

      if (temp_value1?.value === height_value) {
        let temp_value3 = _options1.filter((x) => {
          return x.value >= temp_value1?.value;
        });
        setHeightToValues(temp_value3);
      }
    }

    for (let j = 0; j < _options1?.length; j++) {
      let temp_value1 = _options1?.[j];

      if (temp_value1?.value === height_to_value) {
        let temp_value3 = _options.filter((x) => {
          return x.value <= temp_value1?.value;
        });
        setHeightFromValues(temp_value3);
      }
    }
  }, [height_value, HeightFromOptions, HeightToOptions, height_to_value]);

  const handleMaritalStatusChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1].startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id.startsWith("any"));
    }
    form.setFieldsValue({
      marital_statuses: filtered_ids,
    });
  };
  const handleBodyTypeChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1].startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id.startsWith("any"));
    }
    form.setFieldsValue({
      body_types: filtered_ids,
    });
  };

  const handlePhysicalChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1].startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id.startsWith("any"));
    }
    form.setFieldsValue({
      physical_statuses: filtered_ids,
    });
  };
  const handleMotherTongueChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1].startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id.startsWith("any"));
    }
    form.setFieldsValue({
      mother_tongues: filtered_ids,
    });
  };
  const handleEatingHabitChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1].startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id.startsWith("any"));
    }
    form.setFieldsValue({
      eating_habits: filtered_ids,
    });
  };

  const handleDrinkingHabitChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1].startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id.startsWith("any"));
    }
    form.setFieldsValue({
      drinking_habits: filtered_ids,
    });
  };

  const handleSmokingHabitChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1].startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id.startsWith("any"));
    }
    form.setFieldsValue({
      smoking_habits: filtered_ids,
    });
  };
  return (
    <>
      {my_preference_loading ? (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("basic_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <Hidden from="md">
            <Box width={"100%"}>
              <Skeleton.Text lines={5} />
            </Box>
          </Hidden>
          <Hidden till="md">
            <HStack space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
          </Hidden>
        </Card>
      ) : (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("basic_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <HStack>
            <Box width={"45%"}>
              <Text py={2} color={"#712e5a"} textAlign={"left"}>
                {"Age"}
              </Text>
            </Box>

            <Box width={"25%"}>
              <VForm.Select
                // label={t("From")}
                field={"age_from"}
                options={age_from_values}
                labelField={"en_label"}
                valueField={"value"}
              />
            </Box>
            <Box alignItems={"center"} mx={3}>
              -
            </Box>
            <Box width={"25%"}>
              <VForm.Select
                // label={t("To")}
                field={"age_to"}
                options={age_to_values}
                labelField={"en_label"}
                valueField={"value"}
              />
            </Box>
          </HStack>
          <HStack>
            <Box width={"45%"}>
              <Text py={2} color={"#712e5a"} textAlign={"left"}>
                {"Height"}
              </Text>
            </Box>
            <Box width={"25%"}>
              <VForm.Select
                // label={t("height_from")}
                field={"height_from"}
                options={height_from_values}
                labelField={"en_label"}
                valueField={"value"}
              />
            </Box>
            <Box alignItems={"center"} mx={3}>
              -
            </Box>
            <Box width={"25%"}>
              <VForm.Select
                // label={t("height_to")}
                field={"height_to"}
                options={height_to_values}
                labelField={"en_label"}
                valueField={"value"}
              />
            </Box>
          </HStack>

          <VForm.Select
            label={t("marital_status")}
            field={"marital_statuses"}
            options={getOptions(MaritalStatusOptions, "Marital Status")}
            labelField={"en_label"}
            valueField={"value"}
            isMulti
            onChange={handleMaritalStatusChange}
          />
          {/* <VForm.Select
            label={t("body_type")}
            field={"body_types"}
            labelField={"en_label"}
            valueField={"value"}
            isMulti
            onChange={handleBodyTypeChange}
          /> */}
          <VForm.Select
            label={t("physical_status")}
            field={"physical_statuses"}
            options={getOptions(PhysicalStatusOptions, "Physical Status")}
            labelField={"en_label"}
            valueField={"value"}
            isMulti
            onChange={handlePhysicalChange}
          />
          <VForm.Select
            label={t("mother_tongue")}
            field={"mother_tongues"}
            options={getOptions(MotherTongueOptions, "Mother Tongue")}
            labelField={"en_label"}
            valueField={"value"}
            isMulti
            onChange={handleMotherTongueChange}
          />
          <VForm.Select
            label={t("eating_habit")}
            field={"eating_habits"}
            options={getOptions(EatingHabitsOptions, "Eating Habits")}
            labelField={"en_label"}
            valueField={"value"}
            isMulti
            onChange={handleEatingHabitChange}
          />
          {/* <VForm.Select
            label={t("drinking_habit")}
            field={"drinking_habits"}
            options={getOptions(DrinkingHabitsOptions, "Drinking Habits")}
            labelField={"en_label"}
            valueField={"value"}
            isMulti
            onChange={handleDrinkingHabitChange}
          />
          <VForm.Select
            label={t("smoking_habit")}
            field={"smoking_habits"}
            options={getOptions(SmokingHabitsOptions, "Smoking Habits")}
            labelField={"en_label"}
            valueField={"value"}
            isMulti
            onChange={handleSmokingHabitChange}
          /> */}
        </Card>
      )}
    </>
  );
};
export default UserPreferenceFormBasicDetailsOperator;

import React from "react";
import { Box, Center, Text, VStack, HStack } from "native-base";

import { useTranslation } from "react-i18next";
import { HOROSCOPE_URL } from "@helpers/constants";

const SvgImages = (props) => {
  const { item, login_user_horoscope } = props;
  const { t } = useTranslation();
  return (
    <>
      <Box width={"100%"} alignItems={"center"}>
        <Box>
          <HStack>
            <Box marginX={2}>
              <img src={login_user_horoscope?.birth_chart_url} width="250" />
              <Text textAlign={"center"}>{"ராசி"}</Text>
            </Box>
            <Box marginX={2}>
              <img
                src={login_user_horoscope?.navamansha_chart_url}
                width="250"
              />
              <Text textAlign={"center"}>{"நவாம்சம்"}</Text>
            </Box>
          </HStack>
        </Box>
      </Box>
    </>
  );
};

export default SvgImages;

export const AnnualIncomeToOptions = [
  {
    value: 100000,
    p_o: [],
    g_t_e: null,
    l_t_e: 100000,
    en_label: "1 L",
    ta_label: null,
  },
  {
    value: 200000,
    p_o: [],
    g_t_e: null,
    l_t_e: 200000,
    en_label: "2 L",
    ta_label: null,
  },
  {
    value: 300000,
    p_o: [],
    g_t_e: null,
    l_t_e: 300000,
    en_label: "3 L",
    ta_label: null,
  },
  {
    value: 400000,
    p_o: [],
    g_t_e: null,
    l_t_e: 400000,
    en_label: "4 L",
    ta_label: null,
  },
  {
    value: 500000,
    p_o: [],
    g_t_e: null,
    l_t_e: 500000,
    en_label: "5 L",
    ta_label: null,
  },
  {
    value: 600000,
    p_o: [],
    g_t_e: null,
    l_t_e: 600000,
    en_label: "6 L",
    ta_label: null,
  },
  {
    value: 700000,
    p_o: [],
    g_t_e: null,
    l_t_e: 700000,
    en_label: "7 L",
    ta_label: null,
  },
  {
    value: 800000,
    p_o: [],
    g_t_e: null,
    l_t_e: 800000,
    en_label: "8 L",
    ta_label: null,
  },
  {
    value: 900000,
    p_o: [],
    g_t_e: null,
    l_t_e: 900000,
    en_label: "9 L",
    ta_label: null,
  },
  {
    value: 1000000,
    p_o: [],
    g_t_e: null,
    l_t_e: 1000000,
    en_label: "10 L",
    ta_label: null,
  },
  {
    value: 1100000,
    p_o: [],
    g_t_e: null,
    l_t_e: 1100000,
    en_label: "11 L",
    ta_label: null,
  },
  {
    value: 1200000,
    p_o: [],
    g_t_e: null,
    l_t_e: 1200000,
    en_label: "12 L",
    ta_label: null,
  },
  {
    value: 1300000,
    p_o: [],
    g_t_e: null,
    l_t_e: 1300000,
    en_label: "13 L",
    ta_label: null,
  },
  {
    value: 1400000,
    p_o: [],
    g_t_e: null,
    l_t_e: 1400000,
    en_label: "14 L",
    ta_label: null,
  },
  {
    value: 1500000,
    p_o: [],
    g_t_e: null,
    l_t_e: 1500000,
    en_label: "15 L",
    ta_label: null,
  },
  {
    value: 1600000,
    p_o: [],
    g_t_e: null,
    l_t_e: 1600000,
    en_label: "16 L",
    ta_label: null,
  },
  {
    value: 1700000,
    p_o: [],
    g_t_e: null,
    l_t_e: 1700000,
    en_label: "17 L",
    ta_label: null,
  },
  {
    value: 1800000,
    p_o: [],
    g_t_e: null,
    l_t_e: 1800000,
    en_label: "18 L",
    ta_label: null,
  },
  {
    value: 1900000,
    p_o: [],
    g_t_e: null,
    l_t_e: 1900000,
    en_label: "19 L",
    ta_label: null,
  },
  {
    value: 2000000,
    p_o: [],
    g_t_e: null,
    l_t_e: 2000000,
    en_label: "20 L",
    ta_label: null,
  },
  {
    value: 2100000,
    p_o: [],
    g_t_e: null,
    l_t_e: 2100000,
    en_label: "21 L",
    ta_label: null,
  },
  {
    value: 2200000,
    p_o: [],
    g_t_e: null,
    l_t_e: 2200000,
    en_label: "22 L",
    ta_label: null,
  },
  {
    value: 2300000,
    p_o: [],
    g_t_e: null,
    l_t_e: 2300000,
    en_label: "23 L",
    ta_label: null,
  },
  {
    value: 2400000,
    p_o: [],
    g_t_e: null,
    l_t_e: 2400000,
    en_label: "24 L",
    ta_label: null,
  },
  {
    value: 2500000,
    p_o: [],
    g_t_e: null,
    l_t_e: 2500000,
    en_label: "25 L",
    ta_label: null,
  },
  {
    value: 2600000,
    p_o: [],
    g_t_e: null,
    l_t_e: 2600000,
    en_label: "26 L",
    ta_label: null,
  },
  {
    value: 2700000,
    p_o: [],
    g_t_e: null,
    l_t_e: 2700000,
    en_label: "27 L",
    ta_label: null,
  },
  {
    value: 2800000,
    p_o: [],
    g_t_e: null,
    l_t_e: 2800000,
    en_label: "28 L",
    ta_label: null,
  },
  {
    value: 2900000,
    p_o: [],
    g_t_e: null,
    l_t_e: 2900000,
    en_label: "29 L",
    ta_label: null,
  },
  {
    value: 3000000,
    p_o: [],
    g_t_e: null,
    l_t_e: 3000000,
    en_label: "30 L",
    ta_label: null,
  },
  {
    value: 3100000,
    p_o: [],
    g_t_e: null,
    l_t_e: 3100000,
    en_label: "31 L",
    ta_label: null,
  },
  {
    value: 3200000,
    p_o: [],
    g_t_e: null,
    l_t_e: 3200000,
    en_label: "32 L",
    ta_label: null,
  },
  {
    value: 3300000,
    p_o: [],
    g_t_e: null,
    l_t_e: 3300000,
    en_label: "33 L",
    ta_label: null,
  },
  {
    value: 3400000,
    p_o: [],
    g_t_e: null,
    l_t_e: 3400000,
    en_label: "34 L",
    ta_label: null,
  },
  {
    value: 3500000,
    p_o: [],
    g_t_e: null,
    l_t_e: 3500000,
    en_label: "35 L",
    ta_label: null,
  },
  {
    value: 3600000,
    p_o: [],
    g_t_e: null,
    l_t_e: 3600000,
    en_label: "36 L",
    ta_label: null,
  },
  {
    value: 3700000,
    p_o: [],
    g_t_e: null,
    l_t_e: 3700000,
    en_label: "37 L",
    ta_label: null,
  },
  {
    value: 3800000,
    p_o: [],
    g_t_e: null,
    l_t_e: 3800000,
    en_label: "38 L",
    ta_label: null,
  },
  {
    value: 3900000,
    p_o: [],
    g_t_e: null,
    l_t_e: 3900000,
    en_label: "39 L",
    ta_label: null,
  },
  {
    value: 4000000,
    p_o: [],
    g_t_e: null,
    l_t_e: 4000000,
    en_label: "40 L ",
    ta_label: null,
  },
  {
    value: 4100000,
    p_o: [],
    g_t_e: null,
    l_t_e: 4100000,
    en_label: "41 L",
    ta_label: null,
  },
  {
    value: 4200000,
    p_o: [],
    g_t_e: null,
    l_t_e: 4200000,
    en_label: "42 L",
    ta_label: null,
  },
  {
    value: 4300000,
    p_o: [],
    g_t_e: null,
    l_t_e: 4300000,
    en_label: "43 L",
    ta_label: null,
  },
  {
    value: 4400000,
    p_o: [],
    g_t_e: null,
    l_t_e: 4400000,
    en_label: "44 L",
    ta_label: null,
  },
  {
    value: 4500000,
    p_o: [],
    g_t_e: null,
    l_t_e: 4500000,
    en_label: "45 L",
    ta_label: null,
  },
  {
    value: 4600000,
    p_o: [],
    g_t_e: null,
    l_t_e: 4600000,
    en_label: "46 L",
    ta_label: null,
  },
  {
    value: 4700000,
    p_o: [],
    g_t_e: null,
    l_t_e: 4700000,
    en_label: "47 L",
    ta_label: null,
  },
  {
    value: 4800000,
    p_o: [],
    g_t_e: null,
    l_t_e: 4800000,
    en_label: "48 L",
    ta_label: null,
  },
  {
    value: 4900000,
    p_o: [],
    g_t_e: null,
    l_t_e: 4900000,
    en_label: "49 L",
    ta_label: null,
  },
  {
    value: 5000000,
    p_o: [],
    g_t_e: null,
    l_t_e: 5000000,
    en_label: "50 L",
    ta_label: null,
  },
  {
    value: 999999999,
    p_o: [],
    g_t_e: null,
    l_t_e: -1,
    en_label: "Any",
    ta_label: "ஏதேனும்",
  },
];

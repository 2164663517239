export const PhysicalStatusOptions = [
  {
    value: "any",
    en_label: "Any",
    ta_label: "ஏதேனும்",
    p_o: [],
  },
  {
    value: "normal",
    en_label: "Normal",
    ta_label: "சாதாரண நபர்",
    p_o: [],
  },
  {
    value: "physically_challenged",
    en_label: "Physically Challenged",
    ta_label: "மாற்றுதிறனாளி",
    p_o: [],
  },
];

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, HStack } from "native-base";

import { useParams } from "react-router-dom";
import {
  dynamicRequest,
  getMember,
  getMemberForAdmin,
  useDynamicSelector,
} from "@services/redux";
import UserPhotos from "@views/components/user_manage_profile/user_photos";
import MemberDetailsCardViewTeleCaller from "@views/components/admin_verification/member_details_card_view_in_tele_caller";
import { Button } from "antd";
import { navigateBack } from "@helpers/navigator";
import { useTranslation } from "react-i18next";
import UserPrivacySettingsTeleCaller from "./user_privacy_settings_telecaller";
import { retrieveItem, storeItem } from "@helpers/storage";

const UserDetailsForTeleCaller = () => {
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const { t } = useTranslation();
  const role = retrieveItem("role");

  useEffect(() => {
    getMemberById();
  }, [user_id]);

  const getMemberById = () => {
    let key = [{ key: "member_for_admin", loading: true }];
    let query = getMemberForAdmin;
    let variables = { id: user_id };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <>
      <Box mx={5} my={5} alignItems={"flex-start"}>
        <Button
          loading={false}
          type="default"
          onClick={() => {
            navigateBack();
          }}
        >
          {t("back")}
        </Button>
      </Box>
      <Box width="100%">
        <MemberDetailsCardViewTeleCaller />
      </Box>
    </>
  );
};
export default UserDetailsForTeleCaller;

import { gql } from "@apollo/client";
export const get_all_no_photo_members = gql`
  query getNoPhotoMembers(
    $franchise_id: String
    $page_number: Float
    $page_limit: Float
  ) {
    get_all_no_photo_members: getNoPhotoMembers(
      franchise_id: $franchise_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        created_datetime
        franchise_name
        mobile
        profile_id
        dob
        gender
        country
        state
        district
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_all_no_photo_members_report = gql`
  query getNoPhotoMembers(
    $franchise_id: String
    $page_number: Float
    $page_limit: Float
  ) {
    get_all_no_photo_members_report: getNoPhotoMembers(
      franchise_id: $franchise_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        created_datetime
        franchise_name
        mobile
        profile_id
        dob
        gender
        country
        state
        district
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

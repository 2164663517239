import { gql } from "@apollo/client";

export const get_reports_query = gql`
  query getFranchiseMembers(
    $franchise_id: String
    $page_number: Float
    $page_limit: Float
  ) {
    getFranchiseMembers(
      franchise_id: $franchise_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        dob
        name
        gender
        country
        state
        city
        district
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_promo_users_query = gql`
  query getPromoCodeMembers(
    $promo_code_id: String!
    $page_number: Float
    $page_limit: Float
  ) {
    getPromoCodeMembers(
      promo_code_id: $promo_code_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        dob
        name
        gender
        country
        state
        district
        profile_id
        pincode
        subscriptions {
          discounted_price
          subscription_from
          subscription_till
          subscription_plan {
            en_label
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

import UserHomeBanner from "@views/components/banner/user_home_banner";
import UserCover from "@views/components/user_home/user_cover";
import {
  Box,
  Center,
  Heading,
  Hidden,
  HStack,
  Modal,
  Pressable,
  Skeleton,
  Text,
  VStack,
} from "native-base";
import ScrollView from "@views/components/ui/scroll_view/scroll_view";
import React, { useEffect, useState } from "react";
import MembersHorizontalList from "@views/components/member/members_horizontal_list";
import { navigate, navigateBack } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { retrieveItem, storeItem } from "@helpers/storage";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  getMatches,
  useDynamicSelector,
} from "@services/redux";
import { useCheckLogin } from "@helpers/auth";
import UnderVerification from "@views/components/common/under_verification";
import PopupAd from "@views/components/banner/ad_popup";
import { get_pop_up_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_ad_pop_up";

const UserHome = (props) => {
  const is_logged_in = useCheckLogin();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const is_approved = retrieveItem("is_approved");
  const is_preference_added = retrieveItem("is_preference_added");
  const is_profile_added = retrieveItem("is_profile_added");

  const [matches_list, setMatchesList] = useState([]);
  const [visited_me, setVisitedMe] = useState([]);
  const [is_popup_visible, setIsPopupVisible] = useState(false);
  const [show_ad_image, setShowAdImage] = useState(false);

  const {
    items: all_matches_list,
    pagination: all_matches_pagination,
    loading: all_matches_loading,
  } = useDynamicSelector("matches");

  const {
    members_visited_me: members_visited_me_list,
    loading: members_visited_me_loading,
    members_visited_me_count,
  } = useDynamicSelector("members_visited_me");
  const {
    id: web_img_url_id,
    web_img_url,
    day_interval,
  } = useDynamicSelector("get_ad_pop_up");

  const getPopUPImage = () => {
    let key = [{ key: "get_ad_pop_up", loading: true }];
    let query = get_pop_up_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const checkDayInterval = () => {
    const lastAdShownDate = retrieveItem("lastAdShownDate");
    const currentDate = new Date();

    if (
      !lastAdShownDate ||
      (currentDate - new Date(lastAdShownDate)) / (1000 * 60 * 60 * 24) >=
        day_interval
    ) {
      setShowAdImage(true);
      storeItem("lastAdShownDate", currentDate.toISOString());
    }
  };

  const openPopup = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    storeItem("web_img_url_id", web_img_url_id);
  };
  useEffect(() => {
    if (all_matches_list || members_visited_me_list) {
      setMatchesList(all_matches_list);
      setVisitedMe(members_visited_me_list);
    }
  }, [all_matches_list, members_visited_me_list]);

  useEffect(() => {
    getPopUPImage();
  }, []);
  useEffect(() => {
    checkDayInterval();
  }, [day_interval]);
  useEffect(() => {
    let local_image_id = retrieveItem("web_img_url_id");
    if (local_image_id !== web_img_url_id && show_ad_image) {
      openPopup();
    }
  }, [web_img_url_id]);

  if (!is_profile_added) {
    navigate(ROUTES.USER_MANAGE_PROFILE);
  } else if (!is_preference_added) {
    navigate(ROUTES.USER_MANAGE_PREFERENCE);
  } else if (is_approved === null) {
    return <UnderVerification />;
  }
  return (
    <Box flex={1}>
      <HStack>
        <Box
          height={"100vh"}
          width={{
            base: "100%",

            // xs: 200,
            // sm: 200,
            md: 300,
            lg: 300,
            xl: 300,
            "2xl": 300,
            "3xl": 300,
          }}
        >
          <UserCover
            all_matches_pagination={all_matches_pagination}
            all_matches_loading={all_matches_loading}
            members_visited_me_loading={members_visited_me_loading}
            matches_list={matches_list}
            visited_me={visited_me}
          />
        </Box>

        <Scrollbars flex={1}>
          <Hidden till="md">
            <Box>
              <UserHomeBanner />
            </Box>
          </Hidden>
          {all_matches_loading ? (
            <>
              <Box backgroundColor={"#fef5fb"}>
                <Heading ml={3} fontSize="xl" pl="4" pb="3">
                  {t("all_matches")}
                </Heading>
              </Box>

              <HStack backgroundColor={"#fef5fb"}>
                <Center
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
                <Center
                  marginLeft={5}
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
                <Center
                  marginLeft={5}
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
                <Center
                  marginLeft={5}
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
                <Center
                  marginLeft={5}
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
                <Center
                  marginLeft={5}
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
                <Center
                  marginLeft={5}
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
              </HStack>
            </>
          ) : (
            matches_list?.length > 0 && (
              <Box backgroundColor={"#fef5fb"}>
                <MembersHorizontalList
                  loading={all_matches_loading}
                  title={t("all_matches")}
                  total_count={
                    all_matches_pagination
                      ? all_matches_pagination.total_count
                      : 0
                  }
                  members={matches_list}
                  has_more={
                    all_matches_pagination?.total_count > 10 ? true : false
                  }
                  more_path={ROUTES.USER_MATCHES}
                />
              </Box>
            )
          )}
          <Box height={10} backgroundColor={"#fef5fb"}></Box>
          {members_visited_me_loading ? (
            <>
              <Box backgroundColor={"#fef5fb"}>
                <Heading ml={3} fontSize="xl" pl="4" pb="3">
                  {t("viewed_you")}
                </Heading>
              </Box>

              <HStack backgroundColor={"#fef5fb"}>
                <Center
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
                <Center
                  marginLeft={5}
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
                <Center
                  marginLeft={5}
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
                <Center
                  marginLeft={5}
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
                <Center
                  marginLeft={5}
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
                <Center
                  marginLeft={5}
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
                <Center
                  marginLeft={5}
                  width={180}
                  height={180}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"95%"}
                    h={"95%"}
                    bg="white"
                    rounded={"5px"}
                    padding={5}
                    shadow={2}
                    marginLeft={10}
                  >
                    <Skeleton
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"75%"}
                      h={"75%"}
                      bg="white"
                      // rounded={"5px"}
                      shadow={2}
                      rounded="full"
                    />
                    <Skeleton.Text width={"60%"} my={2} lines={1} />
                    <Skeleton.Text width={"60%"} lines={1} />
                  </Box>
                </Center>
              </HStack>
            </>
          ) : (
            visited_me?.length > 0 && (
              <Box backgroundColor={"#fef5fb"}>
                <MembersHorizontalList
                  loading={members_visited_me_loading}
                  title={t("viewed_you")}
                  total_count={members_visited_me_count || 0}
                  members={visited_me}
                  has_more={members_visited_me_count > 10 ? true : false}
                  more_path={ROUTES.USER_MATCHES}
                />
              </Box>
            )
          )}
          {matches_list?.length === 0 &&
            visited_me?.length === 0 &&
            !members_visited_me_loading &&
            !all_matches_loading && (
              <Center height={100} backgroundColor={"#fef5fb"}>
                <Text
                  style={{
                    textAlign: "center",
                  }}
                  color={"#712e5a"}
                  fontSize={16}
                >
                  {t("all_matches_no_data")}
                </Text>
              </Center>
            )}
          <Box height={100} backgroundColor={"#fef5fb"}></Box>
        </Scrollbars>
      </HStack>
      <Modal
        isOpen={is_popup_visible}
        onClose={closePopup}
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PopupAd url={web_img_url} onClose={closePopup} />
      </Modal>
    </Box>
  );
};
export default UserHome;

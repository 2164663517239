export const CasteOptions = [
  {
    value: "any",
    p_o: ["hindu", "christian", "muslim", "inter_religion", "any"],
    en_label: "Any",
    ta_label: "ஏதேனும்",
  },

  {
    value: "pillai",
    p_o: ["hindu"],
    en_label: "Pillai",
    ta_label: "பிள்ளை",
  },
  {
    value: "naidu",
    p_o: ["hindu"],
    en_label: "Naidu",
    ta_label: "நாயுடு",
  },
  {
    value: "nadar",
    p_o: ["hindu"],
    en_label: "Nadar",
    ta_label: "நாடார்",
  },
  {
    value: "chettiar",
    p_o: ["hindu"],
    en_label: "Chettiar",
    ta_label: "செட்டியார்",
  },
  {
    value: "mudaliyar",
    p_o: ["hindu"],
    en_label: "Mudaliyar",
    ta_label: "முதலியார்",
  },
  {
    value: "parkava_kulam_udayar",
    p_o: [],
    en_label: "Parkava Kulam / Udayar",
    ta_label: "பார்க்கவகுலம் / உடையார்",
  },
  {
    value: "kulalar",
    p_o: ["hindu"],
    en_label: "Kulalar",
    ta_label: "குலாலர்",
  },
  {
    value: "boyar",
    p_o: ["hindu"],
    en_label: "Boyar",
    ta_label: "போயர்",
  },
  {
    value: "reddy_reddyar",
    p_o: ["hindu"],
    en_label: "Reddy / Reddyar",
    ta_label: "ரெட்டி / ரெட்டியார்",
  },
  {
    value: "veera_saivam_pandaram ",
    p_o: ["hindu"],
    en_label: "Veera Saivam ( Pandaram )",
    ta_label: "வீர சைவம் (பண்டாரம்)",
  },
  {
    value: "moopanar",
    p_o: ["hindu"],
    en_label: "Moopanar",
    ta_label: "மூப்பனார்",
  },
  {
    value: "gounder",
    p_o: ["hindu"],
    en_label: "Gounder",
    ta_label: "கவுண்டர்",
  },
  {
    value: "vanniyar_vanniya_kula_kshatriyar",
    p_o: ["hindu"],
    en_label: "Vanniyar / Vanniya Kula Kshatriyar",
    ta_label: "வன்னியர் / வன்னிய குல க்ஷத்திரியர்",
  },
  {
    value: "naicker",
    p_o: ["hindu"],
    en_label: "Naicker",
    ta_label: "நாயக்கர்",
  },
  {
    value: "devar_thevar_mukkulathor_agamudaiyar",
    p_o: ["hindu"],
    en_label: "Devar / Thevar / Mukkulathor/Agamudaiyar",
    ta_label: "தேவர் / முக்குலத்தோர்/அகமுடையார்",
  },
  {
    value: "vishwakarma",
    p_o: ["hindu"],
    en_label: "Vishwakarma",
    ta_label: "விஸ்வகர்மா",
  },
  {
    value: "meenavar",
    p_o: ["hindu"],
    en_label: "Meenavar",
    ta_label: "மீனவர்",
  },
  {
    value: "muthuraja_mutharaiyar",
    p_o: ["hindu"],
    en_label: "Muthuraja / Mutharaiyar",
    ta_label: "முத்துராஜா / முத்தரையர்",
  },
  {
    value: "adi_dravidar",
    p_o: ["hindu"],
    en_label: "Adi Dravidar",
    ta_label: "ஆதி திராவிடர்",
  },
  {
    value: "devandra_kula_vellalar",
    p_o: ["hindu"],
    en_label: "Devandra Kula Vellalar",
    ta_label: "தேவேந்திர குல வேளாளர்",
  },
  {
    value: "arunthathiyar",
    p_o: ["hindu"],
    en_label: "Arunthathiyar",
    ta_label: "அருந்ததியர்",
  },
  {
    value: "maruthuvar",
    p_o: ["hindu"],
    en_label: "Maruthuvar",
    ta_label: "மருத்துவர்",
  },
  {
    value: "vannar",
    p_o: ["hindu"],
    en_label: "Vannar",
    ta_label: "வண்ணார்",
  },
  {
    value: "caste_no_bar",
    p_o: ["hindu", "christian", "muslim"],
    en_label: "Caste No Bar",
    ta_label: "சாதி தடை இல்லை",
  },
  {
    value: "brahmin_gurukal",
    p_o: ["hindu"],
    en_label: "Brahmin - Gurukal",
    ta_label: "பிராமணர் - குருக்கள்",
  },
  {
    value: "brahmin_iyengar",
    p_o: ["hindu"],
    en_label: "Brahmin - Iyengar",
    ta_label: "பிராமணர் - ஐயங்கார்",
  },
  {
    value: "brahmin_iyer",
    p_o: ["hindu"],
    en_label: "Brahmin / Iyer",
    ta_label: "பிராமணர் / ஐயர்",
  },
  {
    value: "inter_caste",
    p_o: [],
    en_label: "Inter Caste",
    ta_label: "கலப்புத் திருமணம்",
  },
  {
    value: "church_of_sounth_india",
    p_o: ["christian"],
    en_label: "Church of South India",
    ta_label: "CSI",
  },
  {
    value: "roman_catholic",
    p_o: ["christian"],
    en_label: "Roman Catholic",
    ta_label: "RC",
  },
  {
    value: "syrian_catholic",
    p_o: ["christian"],
    en_label: "Syrian Catholic",
    ta_label: "Syrian Catholic",
  },
  {
    value: "pentecost",
    p_o: ["christian"],
    en_label: "Pentecost",
    ta_label: "Pentecost",
  },
  {
    value: "christian_Nadar",
    p_o: ["christian"],
    en_label: "Christian Nadar",
    ta_label: "Christian Nadar",
  },
  {
    value: "christian_others",
    p_o: ["christian"],
    en_label: "Christian others",
    ta_label: "கிறிஸ்துவர் மற்றவை",
  },
  {
    value: "yadav",
    p_o: ["hindu"],
    en_label: "Yadav / Konar",
    ta_label: "யாதவ் / கோனார்",
  },
  {
    value: "udayar",
    p_o: ["hindu"],
    en_label: "Udayar",
    ta_label: "உடையார்",
  },
  {
    value: "christian_telc",
    p_o: ["christian"],
    en_label: "Christian  T E L C ",
    ta_label: "கிறிஸ்தவம் TELC",
  },
  {
    value: "lebbai",
    p_o: ["muslim"],
    en_label: "Lebbai",
    ta_label: "லெப்பை",
  },
  {
    value: "rowther",
    p_o: ["muslim"],
    en_label: "Rowther",
    ta_label: "ராவுத்தர்",
  },
  {
    value: "pattani",
    p_o: ["muslim"],
    en_label: "Pattan / Pattani",
    ta_label: "பட்டாணி",
  },
  {
    value: "maraikayar",
    p_o: ["muslim"],
    en_label: "Maraikayar",
    ta_label: "மரைக்காயர்",
  },
  {
    value: "kayalar",
    p_o: ["muslim"],
    en_label: "Kayalar",
    ta_label: "காயலர்",
  },
  {
    value: "saurashtra",
    p_o: ["hindu"],
    en_label: "Saurashtra",
    ta_label: "சௌராஷ்டிரா",
  },
  {
    value: "vokkaliga_gowda",
    p_o: ["hindu"],
    en_label: "Vokkaliga Gowda",
    ta_label: "ஒக்கலிக கவுடா",
  },
  {
    value: "karuneegar",
    p_o: ["hindu"],
    en_label: "Karuneegar",
    ta_label: "கருணீகர்",
  },
  {
    value: "sunny",
    p_o: ["muslim"],
    en_label: "Sunny",
    ta_label: "சன்னி",
  },
  {
    value: "senaithalaivar",
    p_o: ["hindu"],
    en_label: "Senaithalaivar",
    ta_label: "சேனைத்தலைவர்",
  },
  {
    value: "pallar ",
    p_o: ["hindu"],
    en_label: "Pallar ",
    ta_label: "பள்ளர்",
  },
];

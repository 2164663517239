export const MothersOccupationOptions = [
  {
    value: "homemaker",
    en_label: "Homemaker",
    ta_label: "இல்லத்தரசி",
    p_o: [],
  },
  {
    value: "employed",
    en_label: "Employed",
    ta_label: "பணியாளர்",
    p_o: [],
  },
  {
    value: "business_women",
    en_label: "Business Women",
    ta_label: "வணிகம்",
    p_o: [],
  },
  {
    value: "professional",
    en_label: "Professional",
    ta_label: "தொழில்முறை வேலை",
    p_o: [],
  },
  {
    value: "retired",
    en_label: "Retired",
    ta_label: "ஓய்வு பெற்றவர்",
    p_o: [],
  },
  {
    value: "self_employee",
    en_label: "Self employee",
    ta_label: "சுயதொழில்",
    p_o: [],
  },
  {
    value: "late",
    en_label: "Late",
    ta_label: "இல்லை",
    p_o: [],
  },
  {
    value: "farmer",
    en_label: "Farmer",
    ta_label: "விவசாயி",
    p_o: [],
  },
  {
    value: "lawer",
    en_label: "Lawer",
    ta_label: "வழக்கறிஞர்",
    p_o: [],
  },
  {
    value: "government_employee",
    en_label: "Government employee",
    ta_label: "அரசாங்க ஊழியர்",
    p_o: [],
  },
  {
    value: "doctor",
    en_label: "Doctor",
    ta_label: "மருத்துவர்",
    p_o: [],
  },
];

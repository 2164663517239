import { navigateBack } from "@helpers/navigator";
import PrivacySettings from "@views/components/user_privacy_settings/user_privacy_settings";
import { Box, Pressable, Text } from "native-base";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft } from "react-icons/ai";
const UserPrivacySettings = (props) => {
  const { t } = useTranslation();
  return (
    <Box bgImage={"linear-gradient(234deg , #F0ECFC, #B6739F)"} flex={1}>
      <Box
        shadow={1}
        height={30}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100vw"}
      >
        <Pressable
          onPress={() => {
            navigateBack();
          }}
          cursor={"pointer"}
          position={"absolute"}
          left={5}
        >
          <AiOutlineArrowLeft size={20} color={"#111"} />
        </Pressable>
        <Text color={"#712e5a"} fontSize={16} bold>
          {t("privacy_settings")}
        </Text>
      </Box>
      <Scrollbars>
        <PrivacySettings />
        {/* <Box height={30}></Box> */}
      </Scrollbars>
    </Box>
  );
};
export default UserPrivacySettings;

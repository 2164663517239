import React, { useEffect, useState } from "react";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  useDynamicSelector,
  get_ui_modules_query,
  create_ui_module_mutation,
  update_ui_module_mutation,
  delete_ui_module_mutation,
  create_ui_module_component_mutation,
  update_ui_module_component_mutation,
  delete_ui_module_component_mutation,
  link_endpoints_ui_module_component_mutation,
} from "@services/redux";
import { Button, Modal, Form, Input, Select } from "antd";
import { Box, Text } from "native-base";
import { useDispatch } from "react-redux";
import { showNotification } from "@helpers/notify";
const ManagePage = () => {
  const dispatch = useDispatch();
  const { loading: create_page_loading, status: create_page_status } =
    useDynamicSelector("create_page");
  const { loading: update_page_loading, status: update_page_status } =
    useDynamicSelector("update_page");
  const { loading: delete_page_loading, status: delete_page_status } =
    useDynamicSelector("delete_page");
  const { loading: endpoints_loading, items: endpoints } =
    useDynamicSelector("endpoints");
  const page_action = useDynamicSelector("page_action");
  const page_item = useDynamicSelector("page_item");
  const [open, setOpen] = useState(false);

  const {
    loading: create_page_component_loading,
    status: create_page_component_status,
  } = useDynamicSelector("create_page_component");
  const {
    loading: update_page_component_loading,
    status: update_page_component_status,
  } = useDynamicSelector("update_page_component");
  const {
    loading: link_endpoints_page_component_loading,
    status: link_endpoints_page_component_status,
  } = useDynamicSelector("link_endpoints_page_component");

  const {
    loading: delete_page_component_loading,
    status: delete_page_component_status,
  } = useDynamicSelector("delete_page_component");
  const page_id = useDynamicSelector("page_component_page_id");
  const page_component_action = useDynamicSelector("page_component_action");
  const page_component_item = useDynamicSelector("page_component_item");
  const [page_component_open, setPageComponentOpen] = useState(false);
  const [endpoint_options, setEndpointOptions] = useState([]);

  const [form] = Form.useForm();
  useEffect(() => {
    loadPages(true);
  }, []);

  useEffect(() => {
    let _options = [];
    if (endpoints) {
      for (let i = 0; i < endpoints.length; i++) {
        _options.push({
          value: endpoints[i].id,
          label: endpoints[i].name,
        });
      }
      setEndpointOptions(_options);
    }
  }, [endpoints]);

  useEffect(() => {
    if (page_action === "Add") {
      form.resetFields();
      setOpen(true);
    } else if (page_action === "Edit") {
      form.setFieldsValue(page_item);
      setOpen(true);
    } else if (page_action === "Delete") {
      setOpen(true);
    }
  }, [page_action]);

  useEffect(() => {
    if (create_page_status === "success") {
      showNotification({
        type: "success",
        message: `Page added successfully`,
      });
      loadPages(false);
      closeModal();
      dispatch(dynamicClear("create_page"));
    } else if (create_page_status === "failure") {
      showNotification({
        type: "error",
        message: `Page cannot be created`,
      });
      dispatch(dynamicClear("create_page"));
    }
  }, [create_page_status]);

  useEffect(() => {
    if (update_page_status === "success") {
      showNotification({
        type: "success",
        message: `Page updated successfully`,
      });
      loadPages(false);
      closeModal();
      dispatch(dynamicClear("update_page"));
    } else if (update_page_status === "failure") {
      showNotification({
        type: "error",
        message: `Page cannot be updated`,
      });
      dispatch(dynamicClear("update_page"));
    }
  }, [update_page_status]);

  useEffect(() => {
    if (delete_page_status === "success") {
      showNotification({
        type: "success",
        message: `Page deleted successfully`,
      });
      loadPages(false);
      closeModal();
      dispatch(dynamicClear("delete_page"));
    } else if (delete_page_status === "failure") {
      showNotification({
        type: "error",
        message: `Page cannot be deleted`,
      });
      dispatch(dynamicClear("delete_page"));
    }
  }, [delete_page_status]);

  useEffect(() => {
    if (page_component_action === "Add") {
      form.resetFields();
      setPageComponentOpen(true);
    } else if (page_component_action === "Edit") {
      form.setFieldsValue(page_component_item);
      setPageComponentOpen(true);
    } else if (page_component_action === "Link Endpoint To") {
      let json = {
        id: page_component_item.id,
        api_module_id_list: page_component_item.endpoints.map((x) => x.id),
      };
      form.setFieldsValue(json);
      setPageComponentOpen(true);
    } else if (page_component_action === "Delete") {
      setPageComponentOpen(true);
    }
  }, [page_component_action]);

  useEffect(() => {
    if (create_page_component_status === "success") {
      showNotification({
        type: "success",
        message: `Page Component added successfully`,
      });
      loadPages(false);
      closePageComponentModal();
      dispatch(dynamicClear("create_page_component"));
    } else if (create_page_component_status === "failure") {
      showNotification({
        type: "error",
        message: `Page Component cannot be created`,
      });
      dispatch(dynamicClear("create_page_component"));
    }
  }, [create_page_component_status]);
  useEffect(() => {
    if (update_page_component_status === "success") {
      let message = `Page Component updated successfully`;
      if (page_action === "Link Endpoint To") {
        message = `Page Component Endpoints linked successfully`;
      }
      showNotification({
        type: "success",
        message: message,
      });
      loadPages(false);
      closePageComponentModal();
      dispatch(dynamicClear("update_page_component"));
    } else if (update_page_component_status === "failure") {
      let message = `Page Component cannot be updated`;
      if (page_action === "Link Endpoint To") {
        message = `Page Component Endpoints cannot be linked.`;
      }
      showNotification({
        type: "error",
        message: message,
      });
      dispatch(dynamicClear("update_page_component"));
    }
  }, [update_page_component_status]);

  useEffect(() => {
    if (link_endpoints_page_component_status === "success") {
      let message = `Page Component Endpoints linked successfully`;
      showNotification({
        type: "success",
        message: message,
      });
      loadPages(false);
      closePageComponentModal();
      dispatch(dynamicClear("link_endpoints_page_component"));
    } else if (update_page_component_status === "failure") {
      let message = `Page Component Endpoints cannot be linked.`;
      showNotification({
        type: "error",
        message: message,
      });
      dispatch(dynamicClear("link_endpoints_page_component"));
    }
  }, [link_endpoints_page_component_status]);

  useEffect(() => {
    if (delete_page_component_status === "success") {
      showNotification({
        type: "success",
        message: `Page Component deleted successfully`,
      });
      loadPages(false);
      closePageComponentModal();
      dispatch(dynamicClear("delete_page_component"));
    } else if (delete_page_component_status === "failure") {
      showNotification({
        type: "error",
        message: `Page Component cannot be deleted`,
      });
      dispatch(dynamicClear("delete_page_component"));
    }
  }, [delete_page_component_status]);

  const loadPages = (should_load) => {
    let keys = [{ key: "pages", loading: should_load }];
    let variables = {};
    dispatch(dynamicRequest(keys, get_ui_modules_query, variables));
  };

  const openAddPage = () => {
    dispatch(dynamicSet("page_action", "Add"));
  };

  const closeModal = () => {
    dispatch(dynamicSet("page_action", ""));
    setOpen(false);
  };

  const handleSubmit = (values) => {
    if (page_action === "Add") {
      let keys = [{ key: "create_page", loading: true }];
      let variables = {
        data: values,
      };
      dispatch(dynamicRequest(keys, create_ui_module_mutation, variables, "M"));
    } else if (page_action === "Edit") {
      let keys = [{ key: "update_page", loading: true }];
      let variables = {
        id: page_item.id,
        data: values,
      };
      dispatch(dynamicRequest(keys, update_ui_module_mutation, variables, "M"));
    }
  };

  const handleDelete = () => {
    let keys = [{ key: "delete_page", loading: true }];
    let variables = {
      id: page_item.id,
    };
    dispatch(dynamicRequest(keys, delete_ui_module_mutation, variables, "M"));
  };

  const closePageComponentModal = () => {
    dispatch(dynamicSet("page_component_page_id", ""));
    dispatch(dynamicSet("page_component_action", ""));
    setPageComponentOpen(false);
  };

  const handlePageComponentSubmit = (values) => {
    if (page_component_action === "Add") {
      values.ui_module_id = page_id;
      let keys = [{ key: "create_page_component", loading: true }];
      let variables = {
        data: values,
      };
      dispatch(
        dynamicRequest(
          keys,
          create_ui_module_component_mutation,
          variables,
          "M"
        )
      );
    } else if (page_component_action === "Edit") {
      let keys = [{ key: "update_page_component", loading: true }];
      let variables = {
        id: page_component_item.id,
        data: values,
      };
      dispatch(
        dynamicRequest(
          keys,
          update_ui_module_component_mutation,
          variables,
          "M"
        )
      );
    } else if (page_component_action === "Link Endpoint To") {
      let keys = [{ key: "link_endpoints_page_component", loading: true }];
      let variables = {
        id: page_component_item.id,
        data: values,
      };
      dispatch(
        dynamicRequest(
          keys,
          link_endpoints_ui_module_component_mutation,
          variables,
          "M"
        )
      );
    }
  };

  const handlePageComponentDelete = () => {
    let keys = [{ key: "delete_page_component", loading: true }];
    let variables = {
      id: page_component_item.id,
    };
    dispatch(
      dynamicRequest(keys, delete_ui_module_component_mutation, variables, "M")
    );
  };
  return (
    <>
      <Box w="100%">
        <Button type="primary" onClick={openAddPage}>
          Add Page
        </Button>
      </Box>
      <Modal
        title={`${page_action} Page`}
        open={open}
        onOk={closeModal}
        onCancel={closeModal}
        footer={
          <>
            <Button key="cancel" danger onClick={closeModal}>
              Cancel
            </Button>
            {page_action === "Delete" && (
              <Button
                key="delete"
                type="primary"
                danger
                onClick={handleDelete}
                loading={delete_page_loading}
              >
                Delete
              </Button>
            )}
            {page_action !== "Delete" && (
              <Button
                key="submit"
                type="primary"
                form="manage_page"
                htmlType="submit"
                loading={
                  page_action === "Add"
                    ? create_page_loading
                    : update_page_loading
                }
              >
                {page_action === "Add" && "Add"}
                {page_action === "Edit" && "Update"}
              </Button>
            )}
          </>
        }
      >
        {page_action !== "Delete" && (
          <Form
            form={form}
            name={"manage_page"}
            className="access_rights_form"
            layout={"horizontal"}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="name"
              style={{ color: "black" }}
              rules={[{ required: true, message: "Name is mandatory" }]}
            >
              <Input />
            </Form.Item>
          </Form>
        )}
        {page_action === "Delete" && (
          <Text>
            Do you want to delete the page "
            <Text fontWeight="bold">{page_item.name}</Text>"?
          </Text>
        )}
      </Modal>
      <Modal
        title={`${page_component_action} Page Component`}
        open={page_component_open}
        onOk={closePageComponentModal}
        onCancel={closePageComponentModal}
        footer={
          <>
            <Button key="cancel" danger onClick={closePageComponentModal}>
              Cancel
            </Button>
            {page_component_action === "Delete" && (
              <Button
                key="delete"
                type="primary"
                danger
                onClick={handlePageComponentDelete}
                loading={delete_page_component_loading}
              >
                Delete
              </Button>
            )}
            {page_component_action === "Link Endpoint To" && (
              <Button
                key="submit"
                type="primary"
                form="manage_page_component"
                htmlType="submit"
                loading={link_endpoints_page_component_loading}
              >
                Link
              </Button>
            )}
            {(page_component_action === "Add" ||
              page_component_action === "Edit") && (
              <Button
                key="submit"
                type="primary"
                form="manage_page_component"
                htmlType="submit"
                loading={
                  page_component_action === "Add"
                    ? create_page_component_loading
                    : update_page_component_loading
                }
              >
                {page_component_action === "Add" && "Add"}
                {page_component_action === "Edit" && "Update"}
              </Button>
            )}
          </>
        }
      >
        {(page_component_action === "Add" ||
          page_component_action === "Edit") && (
          <Form
            form={form}
            name={"manage_page_component"}
            className="access_rights_form"
            layout={"horizontal"}
            onFinish={handlePageComponentSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="name"
              style={{ color: "black" }}
              rules={[{ required: true, message: "Name is mandatory" }]}
            >
              <Input />
            </Form.Item>
          </Form>
        )}
        {page_component_action === "Link Endpoint To" && (
          <Form
            form={form}
            name={"manage_page_component"}
            className="access_rights_form"
            layout={"horizontal"}
            onFinish={handlePageComponentSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Endpoints"
              name="api_module_id_list"
              style={{ color: "black" }}
            >
              <Select
                mode="multiple"
                placeholder="Select applicable endpoints"
                style={{ width: "100%" }}
                options={endpoint_options}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Form>
        )}
        {page_component_action === "Delete" && (
          <Text>
            Do you want to delete the page component "
            <Text fontWeight="bold">{page_component_item.name}</Text>"?
          </Text>
        )}
      </Modal>
    </>
  );
};
export default ManagePage;

import {
  Box,
  Hidden,
  Text,
  VStack,
  HStack,
  Pressable,
  Divider,
  Image,
  Header,
  Body,
  Card,
} from "native-base";
import React, { useEffect, useState, useRef } from "react";
import { Animated, Dimensions } from "react-native";

import { AiFillStar, AiOutlineHeart } from "react-icons/ai";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  addMultipleContactsViewMutation,
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  getMyPlan,
  getNotifications,
  markNotificationsQuery,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { justifyContent } from "styled-system";
import { navigate, navigateWithProps } from "@helpers/navigator";
import { getNotificationCustomMessage } from "@helpers/notification_message_function";
import { retrieveItem, storeItem } from "@helpers/storage";
import {
  CalculateAge,
  LastSeen,
  LastSeenNotification,
} from "@helpers/calculate_age";
import { Button, Modal } from "antd";
import HoroscopeChart from "../member/horoscope_chart";
import HoroscopeChartMultiple from "../member/horoscope_chart_multiple";
import { FiHeart } from "react-icons/fi";
import { FaHeart } from "react-icons/fa";
import { getUUID } from "@helpers/uuid";
import { getProfileUrl } from "@helpers/get_profile_url";
import { useReactToPrint } from "react-to-print";
import ChartDetailsMultiple from "../member/chart_details_multiple";
import SvgImages from "../member/svg_images";
import colors from "@helpers/colors";
import { ROUTES } from "@views/routes/my_routes";

const PrintShortlists = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRerender, setIsRerender] = useState(false);
  let svg_convert_ref = useRef();

  const { my_subscription: subscribed_plan } = useDynamicSelector("my_plan");
  const { status: checked_status, error } = useDynamicSelector(
    "addMultipleMemberContactView"
  );

  const { uuid } = useDynamicSelector("shortlisted_count");
  let get_shortlisted_profiles = retrieveItem("shortlisted_profiles");
  let shortlisted_array = [];

  const [data, setData] = useState([]);
  const [data_ids, setDataIds] = useState([]);

  const getTotalCount = () => {
    let total = retrieveItem("shortlisted_profiles");
    return total?.length;
  };
  const handlePrint = useReactToPrint({
    content: () => svg_convert_ref.current,
  });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const myPlan = () => {
    let get_plan_key = [{ key: "my_plan", loading: true }];
    let get_plan_query = getMyPlan;
    let get_plan_variables = null;
    dispatch(dynamicRequest(get_plan_key, get_plan_query, get_plan_variables));
  };

  const checkViewedContacts = () => {
    let key = [{ key: "addMultipleMemberContactView", loading: true }];
    let query = addMultipleContactsViewMutation;
    let variables = { data: { contact_ids: data_ids } };
    dispatch(dynamicRequest(key, query, variables));
  };

  // const getTotalProfiles = () => {
  //   let toPrintData = retrieveItem("shortlisted_profiles");

  //   setData(toPrintData);
  // };

  useEffect(() => {
    if (checked_status === "success") {
      showModal();
      myPlan();
      dispatch(dynamicClear("addMultipleMemberContactView"));
    } else if (checked_status === "failure") {
      toast.error(error?.message);
    }
  }, [checked_status]);
  useEffect(() => {
    let toPrintData = retrieveItem("shortlisted_profiles");
    const idArray = toPrintData?.map((obj) => obj.id);
    setDataIds(idArray);
    setData(toPrintData);

    // getTotalProfiles();
  }, [props.rerender, isRerender]);

  const UserImage = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          objectFit: "cover",
          borderRadius: "50%",
        }}
        width={40}
        height={40}
      />
    );
  });

  return (
    <Box>
      <Box
        alignItems={"center"}
        mt={1}
        py={5}
        top={"50px"}
        width={400}
        position={"fixed"}
        backgroundColor={"#fef5fb"}
        zIndex={99}
      >
        {data?.length > 0 && (
          <Button
            style={{ width: "150px", backgroundColor: "#ff00ff" }}
            type="primary"
            onClick={() => {
              {
                subscribed_plan
                  ? checkViewedContacts()
                  : navigate(ROUTES.USER_SUBSCRIPTION_BY_OPERATOR);
              }
              // handlePrint();
            }}
          >
            {subscribed_plan ? "Print All" : "Buy Plan"}
          </Button>
        )}
      </Box>
      <Box height={10} width={400} />
      <Box mx={5} marginTop={10} flex={1} width={"100%"}>
        {data?.length > 0 ? (
          data?.map((member, index) => {
            return (
              <Box key={index}>
                <Pressable
                  alignItems={"center"}
                  my={5}
                  onPress={() => {
                    onClose();
                  }}
                >
                  <Box width={"100%"}>
                    <HStack alignItems={"center"}>
                      <Box width={"20%"}>
                        <Image
                          source={{
                            uri: getProfileUrl(
                              member?.member_cover_photo?.cropped_photo_url,
                              member?.member_profile?.gender
                            ),
                          }}
                          borderRadius={"50%"}
                          size={"xs"}
                        />
                      </Box>

                      <Box width={"80%"} mx={2}>
                        <Text
                          textAlign={"left"}
                          fontSize={14}
                          fontWeight={member?.is_read === true ? "400" : "700"}
                        >
                          {member?.member_profile?.name}
                        </Text>
                        <Text textAlign={"left"} fontSize={12}>
                          {CalculateAge(member?.member_profile?.dob)}
                        </Text>
                        <Text textAlign={"left"} fontSize={12}>
                          {`( ${member?.member_root?.profile_number} )`}
                        </Text>
                      </Box>

                      <Pressable
                        position={"absolute"}
                        right={5}
                        alignItems={"flex-end"}
                        onPress={() => {
                          let _uuid = getUUID();
                          let temp = [];
                          if (get_shortlisted_profiles?.length)
                            temp = get_shortlisted_profiles?.filter(
                              (x) => x.id !== member?.id
                            );
                          setIsRerender(!isRerender);
                          if (temp.length === get_shortlisted_profiles?.length)
                            temp.push(member);
                          shortlisted_array.push(member);
                          storeItem("shortlisted_profiles", temp);
                          dispatch(dynamicSet("print_count", _uuid));
                        }}
                        w="80px"
                      >
                        {get_shortlisted_profiles?.find(
                          (x) => x.id === member?.id
                        ) ? (
                          <Box>
                            <FaHeart color="#e33653" size={30} />
                          </Box>
                        ) : (
                          <Box>
                            <FiHeart color="#e33653" size={30} />
                          </Box>
                        )}
                      </Pressable>
                    </HStack>
                  </Box>
                  <Divider my={2} />
                </Pressable>
              </Box>
            );
          })
        ) : (
          <Box alignItems={"center"} justifyContent={"center"}>
            <Text>{t("no_profiles")}</Text>
          </Box>
        )}
      </Box>
      <div style={{ visibility: "hidden" }}>
        <Box ref={svg_convert_ref}>
          {data?.map((x) => {
            console.log("data", x);

            return (
              <Box
                marginY={10}
                // marginTop={20}
                display={"flex"}
                width={"100%"}
                height={"93vh"}
              >
                <Box mx={10} my={5} flex={1}>
                  <ChartDetailsMultiple
                    item={x?.member_profile}
                    login_user_horoscope={x?.member_horoscope}
                  />
                  <Box position={"absolute"} right={0}>
                    <Image
                      source={{
                        uri: getProfileUrl(
                          x?.member_cover_photo?.cropped_photo_url,
                          x?.member_profile?.gender
                        ),
                      }}
                      width={40}
                      height={40}
                    />
                  </Box>
                </Box>
                <Box marginTop={10} height={"25vh"}>
                  <SvgImages login_user_horoscope={x?.member_horoscope} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </div>

      <Modal
        width={800}
        title="Members List"
        open={isModalOpen}
        okText={
          getTotalCount() <=
          subscribed_plan?.allowed_contact_details_view -
            subscribed_plan?.viewed_contact_details_count
            ? "Print"
            : "Buy Plan"
        }
        onOk={() => {
          getTotalCount() <= subscribed_plan?.allowed_contact_details_view
            ? handlePrint()
            : navigate(ROUTES.USER_SUBSCRIPTION_BY_OPERATOR);
        }}
        onCancel={handleCancel}
      >
        <Card>
          <VStack>
            <Text bold fontSize={18}>
              {`Allowed View Count - ${subscribed_plan?.allowed_contact_details_view}`}
            </Text>
            <Text bold fontSize={18}>
              {`Already Viewed Count - ${subscribed_plan?.viewed_contact_details_count}`}
            </Text>
            <Text bold fontSize={18}>
              {`Shortlisted Count - ${getTotalCount()}`}
            </Text>
          </VStack>
        </Card>

        {data?.map((member, index) => {
          return (
            <Box key={index}>
              <Pressable
                alignItems={"center"}
                my={5}
                onPress={() => {
                  onClose();
                }}
              >
                <Box width={"90%"}>
                  <HStack alignItems={"center"}>
                    <Box width={"20%"}>
                      <Image
                        source={{
                          uri: getProfileUrl(
                            member?.member_cover_photo?.cropped_photo_url,
                            member?.member_profile?.gender
                          ),
                        }}
                        borderRadius={"50%"}
                        size={"xs"}
                      />
                    </Box>

                    <Box width={"70%"} mx={2}>
                      <Text
                        textAlign={"left"}
                        fontSize={14}
                        fontWeight={member?.is_read === true ? "400" : "700"}
                      >
                        {member?.member_profile?.name}
                      </Text>
                      <Text textAlign={"left"} fontSize={12}>
                        {CalculateAge(member?.member_profile?.dob)}
                      </Text>
                      <Text textAlign={"left"} fontSize={12}>
                        {`( ${member?.member_root?.profile_number} )`}
                      </Text>
                    </Box>

                    <Pressable
                      position={"absolute"}
                      right={5}
                      alignItems={"flex-end"}
                      onPress={() => {
                        let _uuid = getUUID();
                        let temp = [];
                        if (get_shortlisted_profiles?.length)
                          temp = get_shortlisted_profiles?.filter(
                            (x) => x.id !== member?.id
                          );
                        setIsRerender(!isRerender);
                        if (temp.length === get_shortlisted_profiles?.length)
                          temp.push(member);
                        shortlisted_array.push(member);
                        storeItem("shortlisted_profiles", temp);
                        dispatch(dynamicSet("print_count", _uuid));
                      }}
                      w="80px"
                    >
                      {get_shortlisted_profiles?.find(
                        (x) => x.id === member?.id
                      ) ? (
                        <Box>
                          <FaHeart color="#e33653" size={30} />
                        </Box>
                      ) : (
                        <Box>
                          <FiHeart color="#e33653" size={30} />
                        </Box>
                      )}
                    </Pressable>
                  </HStack>
                </Box>
                <Divider my={2} />
              </Pressable>
            </Box>
          );
        })}
      </Modal>
    </Box>
  );
};

export default PrintShortlists;

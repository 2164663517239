import React from "react";
import { Layout } from "antd";
import SuperAdminLayoutHeader from "./header/super_admin_layout_header";
import SuperAdminLayoutContent from "./content/super_admin_layout_content";
import SuperAdminLayoutSider from "./sider/super_admin_layout_sider";

const SuperAdminLayout = ({ children }) => {
  return (
    <Layout>
      <SuperAdminLayoutHeader />
      <Layout>
        <SuperAdminLayoutSider />
        <SuperAdminLayoutContent children={children} />
      </Layout>
    </Layout>
  );
};

export default SuperAdminLayout;

import {
  Box,
  Hidden,
  Text,
  VStack,
  HStack,
  Pressable,
  Divider,
  Image,
} from "native-base";
import React, { useEffect, useState, useRef } from "react";
import { Animated, Dimensions } from "react-native";

import { AiFillStar, AiOutlineHeart } from "react-icons/ai";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  dynamicClear,
  dynamicRequest,
  getNotifications,
  markNotificationsQuery,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { justifyContent } from "styled-system";
import { navigate, navigateWithProps } from "@helpers/navigator";
import { getNotificationCustomMessage } from "@helpers/notification_message_function";
import { retrieveItem } from "@helpers/storage";
import { LastSeen, LastSeenNotification } from "@helpers/calculate_age";
import { getProfileUrl } from "@helpers/get_profile_url";

const UserNotifications = (props) => {
  const { notifications_data, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [notification_data, setNotificationData] = useState([]);
  const [notification_ids, setNotificationIds] = useState([]);
  const [_user_id, setUserID] = useState("");

  // const { my_notifications: notifications_data } =
  //   useDynamicSelector("notifications");

  const { status: marked_read_status } = useDynamicSelector(
    "markNotificationsAsRead"
  );

  const getAllNotificationsList = () => {
    let get_notification_key = [{ key: "notifications", loading: true }];
    let get_notification_query = getNotifications;
    let get_notification_variables = null;
    dispatch(
      dynamicRequest(
        get_notification_key,
        get_notification_query,
        get_notification_variables
      )
    );
  };

  const markNotifications = (ids) => {
    let shortlist_member_key = [
      { key: "markNotificationsAsRead", loading: true },
    ];
    let shortlist_member_query = markNotificationsQuery;
    let shortlist_member_variables = { notification_ids: ids };
    dispatch(
      dynamicRequest(
        shortlist_member_key,
        shortlist_member_query,
        shortlist_member_variables,
        "M"
      )
    );
  };

  useEffect(() => {
    let login_user_id = retrieveItem("user_id");
    setUserID(login_user_id);
  }, []);
  useEffect(() => {
    if (notifications_data) {
      setNotificationData(notifications_data);
      let _is_read_false_ = notifications_data?.filter((x) => {
        return x.is_read === false;
      });
      let _ids = _is_read_false_?.map((x) => {
        return x.id;
      });
      setNotificationIds(_ids);
    }
  }, [notifications_data]);
  // useEffect(() => {
  //   if (notification_ids) {
  //     markNotifications(notification_ids);
  //   }
  // }, [notification_ids]);

  useEffect(() => {
    if (marked_read_status === "success") {
      // getAllNotificationsList();
    }
    dispatch(dynamicClear("markNotificationsAsRead"));
  }, [marked_read_status]);

  const data = [];
  const UserImage = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          objectFit: "cover",
          borderRadius: "50%",
        }}
        width={40}
        height={40}
      />
    );
  });
  return (
    <Box paddingX={5} marginY={"5px"} width={"100%"}>
      {notification_data?.length > 0 ? (
        notification_data?.map((notify, index) => {
          return (
            <Box key={index}>
              <Pressable
                cursor={notify?.type === "verification" ? "default" : "pointer"}
                alignItems={"center"}
                my={5}
                onPress={() => {
                  if (notify?.member_id && notify?.member_id !== _user_id) {
                    navigate(`/member/${notify?.member_id}`);
                    onClose();
                    markNotifications(notification_ids);
                  }
                }}
              >
                <Box width={"100%"}>
                  <HStack alignItems={"center"}>
                    <Box width={"20%"}>
                      {notify?.type !== "verification" && (
                        <Image
                          source={{
                            uri: getProfileUrl(
                              notify?.member?.member_cover_photo
                                ?.cropped_photo_url,
                              notify?.member?.member_profile?.gender
                            ),
                          }}
                          borderRadius={"50%"}
                          size={"xs"}
                        />
                      )}
                      {notify?.type === "verification" && (
                        <Image
                          source={{
                            uri: "https://img.freepik.com/free-vector/red-exclamation-mark-symbol-attention-caution-sign-icon-alert-danger-problem_40876-3505.jpg?w=2000",
                          }}
                          borderRadius={"50%"}
                          size={"xs"}
                        />
                      )}
                    </Box>

                    <Box width={"80%"} mx={2}>
                      <Text
                        color={notify?.is_read === true ? "#111" : "#712e5a"}
                        textAlign={"left"}
                        fontSize={14}
                        fontWeight={notify?.is_read === true ? "400" : "700"}
                      >
                        {getNotificationCustomMessage(t, notify, _user_id)}
                      </Text>
                    </Box>
                  </HStack>

                  <Text textAlign={"right"} fontSize={10}>
                    {LastSeenNotification(JSON.parse(notify.datetime))}
                    {/* {moment(JSON.parse(notify.datetime)).format(
                        "h:m A, DD-MM-YYYY"
                      )} */}
                  </Text>
                </Box>
                <Divider />
              </Pressable>
            </Box>
          );
        })
      ) : (
        <Box alignItems={"center"} justifyContent={"center"}>
          <Text>{t("no_notifications")}</Text>
        </Box>
      )}
    </Box>
  );
};

export default UserNotifications;

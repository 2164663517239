import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  DatabaseOutlined,
  UserOutlined,
  FontSizeOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { dynamicClear } from "@services/redux";
import { useDispatch } from "react-redux";
import { clearLocalStorage, storeItem } from "@helpers/storage";
import { navigate } from "@helpers/navigator";
const { Sider } = Layout;

const SuperAdminLayoutSider = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selected_page, setSelectedPage] = useState(null);

  useEffect(() => {
    if (
      location.pathname.indexOf("/admin/entity/") > -1 ||
      location.pathname.indexOf("/admin/entities") > -1
    ) {
      setSelectedPage("/admin/entities");
    } else if (location.pathname.indexOf(ROUTES.Superadmin_Users) > -1) {
      setSelectedPage(ROUTES.Superadmin_Users);
    } else if (location.pathname.indexOf(ROUTES.Superadmin_Languages) > -1) {
      setSelectedPage(ROUTES.Superadmin_Languages);
    } else if (location.pathname.indexOf(ROUTES.Superadmin_Settings) > -1) {
      setSelectedPage(ROUTES.Superadmin_Settings);
    }
  }, [location]);

  const items = [
    getItem("Entity", ROUTES.Superadmin_Entities, <DatabaseOutlined />),
    // getItem("User", ROUTES.Superadmin_Users, <UserOutlined />),
    // getItem("Language", ROUTES.Superadmin_Languages, <FontSizeOutlined />),
    // getItem("Settings", ROUTES.Superadmin_Settings, <SettingOutlined />),
    getItem("Logout", ROUTES.Superadmin_Settings, <LogoutOutlined />),
  ];

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const handleLogout = () => {
    clearLocalStorage();

    dispatch(dynamicClear("admin_login"));
    storeItem("session_id", "");
    storeItem("operator_name", "");
    storeItem("role", "");
    navigate(ROUTES.ADMIN_LOGIN);
  };
  const onClick = (e) => {
    if (e.key === "tmp-1") {
      handleLogout();
    } else {
      history.push(`${e.key}`);
    }
  };

  return (
    <Sider
      style={{
        backgroundColor: "#191919",
        borderRight: "1px solid #564f4f",
        cursor: "pointer",
      }}
      width="256px"
    >
      <div className="layout-sider-menu">
        <Menu
          onClick={onClick}
          style={{
            width: 256,
            backgroundColor: "#191919",
            color: "white",
            borderRight: "1px solid #564f4f",
          }}
          selectedKeys={[selected_page]}
          mode="inline"
          items={items}
        />
      </div>
    </Sider>
  );
};
export default SuperAdminLayoutSider;

import React from "react";
import { Box, Center, Text, VStack, HStack, Divider } from "native-base";
import { Dimensions } from "react-native";
import { FaHeart, FaPrayingHands } from "react-icons/fa";
import MemberAvatar from "./member_avatar";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillHeart,
  AiFillStar,
} from "react-icons/ai";
import { useTranslation } from "react-i18next";
import PreferenceComparison from "./components/preference_compare";
import { GiBigDiamondRing, GiBodyHeight } from "react-icons/gi";
import {
  MdAttachMoney,
  MdLanguage,
  MdOutlineWheelchairPickup,
  MdSmokingRooms,
  MdWork,
} from "react-icons/md";
import { IoMdSchool } from "react-icons/io";
import { ImLocation, ImSpoonKnife } from "react-icons/im";
import { BsJournalBookmarkFill, BsPersonFill } from "react-icons/bs";
import { BiDrink } from "react-icons/bi";
import { EmploymentTypesOptions } from "@master_data/common_json/employment_types";
import { EducationsOptions } from "@master_data/common_json/educations";
import { StatesOptions } from "@master_data/common_json/states";
import { CountriesOptions } from "@master_data/common_json/countries";
import { RaasiOptions } from "@master_data/profile_json/raasi";
import { StarsOptions } from "@master_data/common_json/stars";
import { SubCastesOptions } from "@master_data/common_json/sub_castes";
import { CasteOptions } from "@master_data/common_json/castes";
import { ReligionsOptions } from "@master_data/common_json/religions";
import { SmokingHabitsOptions } from "@master_data/common_json/smoking_habits";
import { DrinkingHabitsOptions } from "@master_data/common_json/drinking_habits";
import { EatingHabitsOptions } from "@master_data/common_json/eating_habits";
import { MotherTongueOptions } from "@master_data/common_json/mother_tongue";
import { BodyTypesOptions } from "@master_data/profile_json/body_type";
import { MaritalStatusOptions } from "@master_data/common_json/marital_status";
import { PhysicalStatusOptions } from "@master_data/common_json/physical_status";
import { DistrictsOptions } from "@master_data/common_json/districts";

const MemberSquareList = (props) => {
  const { t } = useTranslation();
  const { preference_matches, _loginUserDetails } = props;
  return (
    <>
      {preference_matches?.age_from && preference_matches?.age_to && (
        <PreferenceComparison
          preference_matches={preference_matches}
          key_value={"age"}
          key_value_id={"age"}
          title={"Age"}
          _loginUserDetails={_loginUserDetails}
          icon={<BsPersonFill size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.marital_statuses?.length > 0 && (
        <PreferenceComparison
          options={MaritalStatusOptions}
          preference_matches={preference_matches}
          key_value={"marital_statuses"}
          key_value_id={"marital_status"}
          title={"marital_status"}
          _loginUserDetails={_loginUserDetails}
          icon={<GiBigDiamondRing size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.body_types?.length > 0 && (
        <PreferenceComparison
          options={BodyTypesOptions}
          preference_matches={preference_matches}
          key_value={"body_types"}
          key_value_id={"body_type"}
          title={"body_type"}
          _loginUserDetails={_loginUserDetails}
          icon={<GiBodyHeight size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.physical_statuses?.length > 0 && (
        <PreferenceComparison
          options={PhysicalStatusOptions}
          preference_matches={preference_matches}
          key_value={"physical_statuses"}
          key_value_id={"physical_status"}
          title={"physical_status"}
          _loginUserDetails={_loginUserDetails}
          icon={<MdOutlineWheelchairPickup size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.mother_tongues?.length > 0 && (
        <PreferenceComparison
          options={MotherTongueOptions}
          preference_matches={preference_matches}
          key_value={"mother_tongues"}
          key_value_id={"mother_tongue"}
          title={"mother_tongue"}
          _loginUserDetails={_loginUserDetails}
          icon={<MdLanguage size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.eating_habits?.length > 0 && (
        <PreferenceComparison
          options={EatingHabitsOptions}
          preference_matches={preference_matches}
          key_value={"eating_habits"}
          key_value_id={"eating_habit"}
          title={"eating_habit"}
          _loginUserDetails={_loginUserDetails}
          icon={<ImSpoonKnife size={18} color={"#ab6c95"} />}
        />
      )}
      {/* {preference_matches?.drinking_habits?.length > 0 && (
        <PreferenceComparison
          options={DrinkingHabitsOptions}
          preference_matches={preference_matches}
          key_value={"drinking_habits"}
          key_value_id={"drinking_habit"}
          title={"drinking_habit"}
          _loginUserDetails={_loginUserDetails}
          icon={<BiDrink size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.smoking_habits?.length > 0 && (
        <PreferenceComparison
          options={SmokingHabitsOptions}
          preference_matches={preference_matches}
          key_value={"smoking_habits"}
          key_value_id={"smoking_habit"}
          title={"smoking_habit"}
          _loginUserDetails={_loginUserDetails}
          icon={<MdSmokingRooms size={18} color={"#ab6c95"} />}
        />
      )} */}
      {preference_matches?.religions?.length > 0 && (
        <PreferenceComparison
          options={ReligionsOptions}
          preference_matches={preference_matches}
          key_value={"religions"}
          key_value_id={"religion"}
          title={"religion"}
          _loginUserDetails={_loginUserDetails}
          icon={<FaPrayingHands size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.castes?.length > 0 && (
        <PreferenceComparison
          options={CasteOptions}
          preference_matches={preference_matches}
          key_value={"castes"}
          key_value_id={"caste"}
          title={"caste"}
          _loginUserDetails={_loginUserDetails}
          icon={<BsJournalBookmarkFill size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.sub_castes?.length > 0 && (
        <PreferenceComparison
          options={SubCastesOptions}
          preference_matches={preference_matches}
          key_value={"sub_castes"}
          key_value_id={"sub_caste"}
          title={"sub_caste"}
          _loginUserDetails={_loginUserDetails}
          icon={<BsJournalBookmarkFill size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.stars?.length > 0 && (
        <PreferenceComparison
          options={StarsOptions}
          preference_matches={preference_matches}
          key_value={"stars"}
          key_value_id={"star"}
          title={"star"}
          _loginUserDetails={_loginUserDetails}
          icon={<AiFillStar size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.raasis?.length > 0 && (
        <PreferenceComparison
          options={RaasiOptions}
          preference_matches={preference_matches}
          key_value={"raasis"}
          key_value_id={"raasi"}
          title={"raasi"}
          _loginUserDetails={_loginUserDetails}
          icon={<AiFillStar size={18} color={"#ab6c95"} />}
        />
      )}
      {/* {preference_matches?.doshams?.length>0 && (
        <PreferenceComparison
          preference_matches={preference_matches}
          key_value={"doshams"}
          key_value_id={"dosham"}
          title={"doshams"}
          _loginUserDetails={_loginUserDetails}
            icon={<AiFillStar size={18} color={"#ab6c95"} />}
        />
      )} */}
      {preference_matches?.countries?.length > 0 && (
        <PreferenceComparison
          options={CountriesOptions}
          preference_matches={preference_matches}
          key_value={"countries"}
          key_value_id={"country"}
          title={"country"}
          _loginUserDetails={_loginUserDetails}
          icon={<ImLocation size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.states?.length > 0 && (
        <PreferenceComparison
          options={StatesOptions}
          preference_matches={preference_matches}
          key_value={"states"}
          key_value_id={"state"}
          title={"state"}
          _loginUserDetails={_loginUserDetails}
          icon={<ImLocation size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.districts?.length > 0 && (
        <PreferenceComparison
          options={DistrictsOptions}
          preference_matches={preference_matches}
          key_value={"districts"}
          key_value_id={"district"}
          title={"district"}
          _loginUserDetails={_loginUserDetails}
          icon={<ImLocation size={18} color={"#ab6c95"} />}
        />
      )}
      {/* {preference_matches?.educations?.length > 0 && (
        <PreferenceComparison
          options={EducationsOptions}
          preference_matches={preference_matches}
          key_value={"educations"}
          key_value_id={"education"}
          title={"education"}
          _loginUserDetails={_loginUserDetails}
          icon={<IoMdSchool size={18} color={"#ab6c95"} />}
        />
      )} */}
      {preference_matches?.employment_types?.length > 0 && (
        <PreferenceComparison
          options={EmploymentTypesOptions}
          preference_matches={preference_matches}
          key_value={"employment_types"}
          key_value_id={"employment_type"}
          title={"employment_type"}
          _loginUserDetails={_loginUserDetails}
          icon={<MdWork size={18} color={"#ab6c95"} />}
        />
      )}
      {preference_matches?.annual_income_from &&
        preference_matches?.annual_income_to && (
          <PreferenceComparison
            preference_matches={preference_matches}
            key_value={"annual_income"}
            key_value_id={"annual_income"}
            title={"Annual Income"}
            _loginUserDetails={_loginUserDetails}
            icon={<MdAttachMoney size={18} color={"#ab6c95"} />}
          />
        )}
    </>
  );
};
export default MemberSquareList;

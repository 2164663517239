import AdminLogin from "@views/components/auth/admin_login";
import { Box, Pressable, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
const AdminLoginPage = (props) => {
  const dispatch = useDispatch();
  return (
    <Box flex={1}>
      <AdminLogin />
    </Box>
  );
};
export default AdminLoginPage;

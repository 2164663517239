export const FamilyTypeOptions = [
  {
    value: "joint_family",
    p_o: [],
    en_label: "Joint Family",
    ta_label: "கூட்டுக் குடும்பம்",
  },
  {
    value: "nuclear_family",
    p_o: [],
    en_label: "Nuclear Family",
    ta_label: "தனிக்குடும்பம்",
  },
  {
    value: "others",
    p_o: [],
    en_label: "Others",
    ta_label: "மற்றவை",
  },
];

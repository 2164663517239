import { CitiesOptions } from "@master_data/common_json/cities";
import { CountriesOptions } from "@master_data/common_json/countries";
import { StatesOptions } from "@master_data/common_json/states";
import { NationalityOptions } from "@master_data/profile_json/nationality";
import _ from "lodash";

export const getCountry = (values) => {
  if (values === "any") {
    return "Any";
  } else {
    let _country = CountriesOptions.find((x) => {
      return _.snakeCase(x.i_c) === _.snakeCase(values);
    });
    return _country?.name;
  }
};

export const getCity = (value) => {
  if (value === "any") {
    return "Any";
  } else {
    let _city = CitiesOptions.find((x) => {
      return _.snakeCase(x.name) === value;
    });
    return _city?.name;
  }
};
export const getState = (state_code, country_code) => {
  if (state_code === "any") {
    return "Any";
  } else {
    let selected_state = StatesOptions?.find(
      (item) =>
        _.snakeCase(item.i_c) === state_code &&
        _.snakeCase(item.c_c) === country_code
    );

    return selected_state?.name;
  }
};
export const getStateInvoiceCode = (state_code, country_code) => {
  let selected_state = StatesOptions?.find(
    (item) =>
      _.snakeCase(item.i_c) === _.snakeCase(state_code) &&
      _.snakeCase(item.c_c) === _.snakeCase(country_code)
  );

  return selected_state;
};

export const getDistrict = (value) => {
  return _.startCase(value);
};

export const getNationality = (options, value) => {
  let selected_nationality = options?.find(
    (item) => _.snakeCase(item.nationality) === value
  );
  return selected_nationality?.nationality;
};

import {
  Box,
  Card,
  Divider,
  Hidden,
  HStack,
  Skeleton,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input } from "antd";
import "../../../index.css";

import { useDynamicSelector } from "@services/redux";
import { ReligionsOptions } from "@master_data/common_json/religions";
import { DoshamsOptions } from "@master_data/common_json/doshams";
import { StarsOptions } from "@master_data/common_json/stars";
import { RaasiOptions } from "@master_data/profile_json/raasi";
import { CasteOptions } from "@master_data/common_json/castes";
import { SubCastesOptions } from "@master_data/common_json/sub_castes";

const UserProfileFormReligionDetailsOperator = (props) => {
  const { form, getOptions, initial_values } = props;
  const { t } = useTranslation();
  const [caste_options, setCasteOptions] = useState([]);
  const [sub_caste_options, setSubCasteOptions] = useState([]);
  const [raasi_options, setRaasiOptions] = useState([]);
  const [star_options, setStarOptions] = useState([]);
  const caste = Form.useWatch("caste", form);
  const sub_caste = Form.useWatch("sub_caste", form);
  const star = Form.useWatch("star", form);
  const raasi = Form.useWatch("raasi", form);
  const religion = Form.useWatch("religion", form);

  const { loading: my_profile_loading } = useDynamicSelector("my_profile");

  useEffect(() => {
    let _options = [];
    if (StarsOptions) {
      _options = StarsOptions;
    }
    if (raasi && StarsOptions) {
      _options = _options?.filter(
        (x) => x.p_o?.map((x) => x).indexOf(raasi) > -1
      );
    }
    // _options = [{ id: null, en_label: "Dont Know" }, ..._options];

    setStarOptions(_options);
    let index = _options.map((x) => x.value).indexOf(star);
    if (index === -1) {
      form.setFields([
        {
          name: "star",
          value: null,
        },
      ]);
    }
  }, [raasi, StarsOptions]);

  useEffect(() => {
    let _options = [];
    if (RaasiOptions) {
      _options = RaasiOptions;
    }
    if (star && RaasiOptions) {
      _options = _options?.filter(
        (x) => x.p_o?.map((x) => x).indexOf(star) > -1
      );
    }

    // _options = [{ id: null, en_label: "Dont Know" }, ..._options];
    setRaasiOptions(_options);
    let index = _options.map((x) => x.value).indexOf(raasi);
    if (index === -1) {
      form.setFields([
        {
          name: "raasi",
          value: null,
        },
      ]);
    }
  }, [star, RaasiOptions]);

  useEffect(() => {
    let _options = [];
    if (CasteOptions) {
      _options = CasteOptions;
    }
    if (religion && CasteOptions) {
      _options = _options?.filter(
        (x) => x.p_o?.map((x) => x).indexOf(religion) > -1
      );
    }
    _options = _options?.filter((x) => x?.number_value !== -1);

    // _options = [{ id: null, en_label: "Dont Know" }, ..._options];
    setCasteOptions(_options);
    let index = _options.map((x) => x.value).indexOf(caste);
    if (index === -1) {
      form.setFields([
        {
          name: "caste",
          value: null,
        },
      ]);
    }
  }, [religion, CasteOptions]);

  useEffect(() => {
    let _options = [];
    if (SubCastesOptions) {
      _options = SubCastesOptions;
    }
    if (caste && SubCastesOptions) {
      _options = _options?.filter(
        (x) => x.p_o?.map((x) => x).indexOf(caste) > -1
      );
    }
    _options = _options?.filter((x) => x?.number_value !== -1);

    // _options = [{ id: null, en_label: "Dont Know" }, ..._options];
    setSubCasteOptions(_options);
    let index = _options.map((x) => x.value).indexOf(sub_caste);
    if (index === -1) {
      form.setFields([
        {
          name: "sub_caste",
          value: null,
        },
      ]);
    }
  }, [caste, SubCastesOptions]);

  const handleDoshamsChange = (values) => {
    let no_id = "no_dosham";
    let filtered_ids = values;
    if (values && values.length > 0 && values[values.length - 1] === no_id) {
      filtered_ids = filtered_ids?.filter((id) => id === no_id);
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => id !== no_id);
    }
    form.setFieldsValue({
      doshams: filtered_ids,
    });
  };

  return (
    <>
      {my_profile_loading ? (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("religion_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <Hidden from="md">
            <Box width={"100%"}>
              <Skeleton.Text lines={5} />
            </Box>
          </Hidden>
          <Hidden till="md">
            <HStack space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
          </Hidden>
        </Card>
      ) : (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={14} bold color={"#712e5a"}>
              {t("religion_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <VForm.Select
            label={t("religion")}
            field={"religion"}
            options={getOptions(ReligionsOptions, "Religion")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Religion is required",
              },
            ]}
          />
          <VForm.Select
            label={t("caste")}
            field={"caste"}
            options={caste_options}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            disabled={initial_values?.caste}
            rules={[
              {
                required: true,
                message: "Caste is required",
              },
            ]}
          />
          <VForm.Select
            label={t("sub_caste")}
            field={"sub_caste"}
            options={sub_caste_options}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            disabled={initial_values?.sub_caste}
          />
          <VForm.Select
            label={t("raasi")}
            field={"raasi"}
            options={raasi_options}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Raasi is required",
              },
            ]}
          />

          <VForm.Select
            label={t("star")}
            field={"star"}
            options={star_options}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Star is required",
              },
            ]}
          />
          <VForm.Select
            isMulti
            label={t("doshams")}
            field={"doshams"}
            options={getOptions(DoshamsOptions, "Dosham")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            onChange={handleDoshamsChange}
            rules={[
              {
                required: true,
                message: "Dosham is required",
              },
            ]}
          />
          <VForm.TextBox
            label={t("gothram")}
            field={"gothram"}
            form_type={"profile"}
            // rules={[
            //   {
            //     required: true,
            //     message: "Gothram is required",
            //   },
            // ]}
          />
        </Card>
      )}
    </>
  );
};
export default UserProfileFormReligionDetailsOperator;

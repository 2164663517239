import React from "react";
import {
  Box,
  Text,
  HStack,
  Divider,
  Pressable,
  Skeleton,
  Image as _Image,
  Image,
} from "native-base";
import { AiFillStar } from "react-icons/ai";
import {
  FaFemale,
  FaHeart,
  FaMale,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaPrayingHands,
} from "react-icons/fa";
import MemberDetailOperator from "./member_detail_for_operator";
import {
  addMemberContactViewMutation,
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  getMember,
  getMyPlan,
  getMyProfile,
  send_request_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { backgroundColor } from "styled-system";
import { Modal, Popconfirm } from "antd";
import toast from "react-hot-toast";
import { CalculateAge, CalculateAgeForMember } from "@helpers/calculate_age";
import {
  BsCardList,
  BsFillFlagFill,
  BsJournalBookmarkFill,
  BsPersonFill,
} from "react-icons/bs";
import { GiBigDiamondRing, GiBodyHeight } from "react-icons/gi";
import {
  GrLanguage,
  GrLocationPin,
  GrMap,
  GrMapLocation,
} from "react-icons/gr";
import { ImLocation, ImSpoonKnife } from "react-icons/im";
import {
  MdFamilyRestroom,
  MdLanguage,
  MdOutlineWheelchairPickup,
  MdSmokingRooms,
  MdSupervisedUserCircle,
  MdWork,
} from "react-icons/md";
import { BiDrink, BiFemale, BiMale } from "react-icons/bi";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { HiCurrencyRupee, HiUserGroup } from "react-icons/hi";
import {
  FcBusinessman,
  FcBusinesswoman,
  FcDiploma2,
  FcManager,
} from "react-icons/fc";
import { IoIosMan, IoMdSchool } from "react-icons/io";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import {
  getCity,
  getCountry,
  getDistrict,
  getNationality,
  getState,
} from "@helpers/get_country_state_city";
import { useState } from "react";
import { getMemberHoroscopeResponse } from "@helpers/member_cover_photo_response_function";
import SvgConverter from "./horoscope_chart";
import HoroscopeChart from "./horoscope_chart";
import { FiHeart } from "react-icons/fi";
import { retrieveItem, storeItem } from "@helpers/storage";
import { getUUID } from "@helpers/uuid";
import { getCroppedImageUrl, getProfileUrl } from "@helpers/get_profile_url";
import {
  getAnnualIncome,
  getUserDetailsFromValue,
  getUserDetailsFromValueForTamil,
} from "@helpers/functions";
import { HeightFromOptions } from "@master_data/preference_json/height_from";
import { WeightOptions } from "@master_data/profile_json/weight";
import { MaritalStatusOptions } from "@master_data/common_json/marital_status";
import _ from "lodash";
import { CreatedForOptions } from "@master_data/profile_json/created_for";
import { PhysicalStatusOptions } from "@master_data/common_json/physical_status";
import { MotherTongueOptions } from "@master_data/common_json/mother_tongue";
import { EatingHabitsOptions } from "@master_data/common_json/eating_habits";
import { DrinkingHabitsOptions } from "@master_data/common_json/drinking_habits";
import { SmokingHabitsOptions } from "@master_data/common_json/smoking_habits";
import { ReligionsOptions } from "@master_data/common_json/religions";
import { CasteOptions } from "@master_data/common_json/castes";
import { SubCastesOptions } from "@master_data/common_json/sub_castes";
import { RaasiOptions } from "@master_data/profile_json/raasi";
import { StarsOptions } from "@master_data/common_json/stars";
import { NationalityOptions } from "@master_data/profile_json/nationality";
import { EducationsOptions } from "@master_data/common_json/educations";
import { EmploymentTypesOptions } from "@master_data/common_json/employment_types";
import { AnnualIncomeFromOptions } from "@master_data/preference_json/annual_income_from";
import { AnnualIncomeOptions } from "@master_data/profile_json/annual_income";
import { FamilyTypeOptions } from "@master_data/profile_json/family_type";
import { FamilyStatusOptions } from "@master_data/profile_json/family_status";
import { FamilyValueOptions } from "@master_data/profile_json/family_value";
import { FathersOccupationOptions } from "@master_data/profile_json/fathers_occupations";
import { MothersOccupationOptions } from "@master_data/profile_json/mothers_occupation";
import { AnnualIncomeToOptions } from "@master_data/preference_json/annual_income_to";
import { DoshamsOptions } from "@master_data/common_json/doshams";

const MemberCardOperatorViewTamil = (props) => {
  const { matches_list, _member_id, login_user_current_plan, matches_loading } =
    props;

  let shortlisted_array = [];
  let temp = [];

  const { t } = useTranslation();
  const dispatch = useDispatch();
  let get_shortlisted_profiles = [];
  get_shortlisted_profiles = retrieveItem("shortlisted_profiles");
  const [horoscope_response, setHoroscopeResponse] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRerender, setIsRerender] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [user_plan, setUserPlan] = useState([]);

  const showPlanModal = () => {
    setIsPlanModalOpen(true);
  };
  const handleOk = () => {
    addMemberContact();

    // setIsPlanModalOpen(false);
  };
  const handlePlanModalCancel = () => {
    setIsPlanModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { _uuid } = useDynamicSelector("print_count");

  const { my_subscription: subscribed_plan, loading: plan_loading } =
    useDynamicSelector("my_plan");

  const { status: send_request_status, error: send_request_error } =
    useDynamicSelector("send_request");
  const { status: add_member_contact_status, error: add_member_contact_error } =
    useDynamicSelector("addMemberContactView");
  const { loading } = useDynamicSelector("member");

  const getMemberReCall = () => {
    let get_member_key = [{ key: "member", loading: false }];
    let get_member_query = getMember;
    let get_member_variables = { id: _member_id };
    dispatch(
      dynamicRequest(get_member_key, get_member_query, get_member_variables)
    );
  };
  const myPlan = () => {
    let get_plan_key = [{ key: "my_plan", loading: true }];
    let get_plan_query = getMyPlan;
    let get_plan_variables = null;
    dispatch(dynamicRequest(get_plan_key, get_plan_query, get_plan_variables));
  };

  const addMemberContact = () => {
    let key = [{ key: "addMemberContactView", loading: true }];
    let query = addMemberContactViewMutation;
    let variables = {
      id: _member_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const sendContactRequest = (_member_id, type) => {
    let send_contact_request_key = [{ key: "send_request", loading: true }];
    let send_contact_request_query = send_request_mutation;
    let send_contact_request_variables = {
      member_id: _member_id,
      type: type,
    };
    dispatch(
      dynamicRequest(
        send_contact_request_key,
        send_contact_request_query,
        send_contact_request_variables
      )
    );
  };

  const checkShortlistProfiles = () => {
    let uuid = getUUID();
    if (get_shortlisted_profiles?.length)
      temp = get_shortlisted_profiles?.filter(
        (x) => x.id !== matches_list?.[0]?.id
      );
    setIsRerender(!isRerender);
    if (temp.length === get_shortlisted_profiles?.length)
      temp.push(matches_list?.[0]);
    shortlisted_array.push(matches_list?.[0]);
    storeItem("shortlisted_profiles", temp);
    dispatch(dynamicSet("shortlisted_count", uuid));
  };
  useEffect(() => {
    myPlan();
    storeItem("shortlisted_profiles", []);
  }, []);
  useEffect(() => {}, [_uuid]);
  useEffect(() => {
    get_shortlisted_profiles = retrieveItem("shortlisted_profiles");
  }, [isReload]);
  useEffect(() => {
    if (subscribed_plan) {
      setUserPlan(subscribed_plan);
    }
  }, [subscribed_plan]);
  useEffect(() => {
    if (send_request_status === "success") {
      toast.success(t("request_sent"));
      dispatch(dynamicClear("send_request"));
    }
    if (send_request_status === "failure") {
      toast.error(t("Request already sent"));

      dispatch(dynamicClear("send_request"));
    }

    if (add_member_contact_status === "success") {
      setIsPlanModalOpen(false);
      getMemberReCall();
      dispatch(dynamicClear("addMemberContactView"));
    }
  }, [send_request_status, send_request_error, add_member_contact_status]);

  return (
    <>
      {matches_loading ? (
        <Box mx={{ base: 5, sm: 2, xs: 2 }}>
          <Box>
            <HStack alignItems={"center"}>
              <Skeleton width={200} height={200} rounded="full" />
              <Skeleton.Text
                alignItems="center"
                lines={2}
                width={"30%"}
                px={5}
              />
            </HStack>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",

              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <BsCardList size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("basic_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsPersonFill size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsPersonFill size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <GiBodyHeight size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaHeart size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <GiBigDiamondRing size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaHeart size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdOutlineWheelchairPickup size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdLanguage size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <ImSpoonKnife size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BiDrink size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdSmokingRooms size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaPhoneAlt size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",

              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <FaPrayingHands size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("religion_information")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsJournalBookmarkFill size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <AiFillStar size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <VscTypeHierarchySub size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaHeart size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcDiploma2 size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",

              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <FaMapMarkedAlt size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("location_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <ImLocation size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <ImLocation size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsFillFlagFill size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",

              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <MdWork size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("professional_information")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <IoMdSchool size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdWork size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <HiCurrencyRupee size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",

              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <MdFamilyRestroom size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("family_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <HiUserGroup size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinessman size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinesswoman size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinesswoman size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcManager size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinesswoman size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcManager size={25} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          {matches_list?.length > 0 ? (
            <Box my={5} mx={2}>
              <Box
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: 8,
                  boxShadow: "0 0 10px #dcdcdc",
                  elevation: 15,
                  shadowOpacity: 0.22,
                  shadowRadius: 2.22,
                  borderBottomWidth: 3,
                  borderBottomColor: "#712e5a",

                  marginHorizontal: 2,
                  paddingHorizontal: 5,

                  marginTop: 10,
                }}
              >
                <HStack alignItems={"center"}>
                  <Box
                    style={{
                      paddingHorizontal: 5,
                    }}
                  >
                    <BsCardList size={30} color={"#712e5a"} />
                  </Box>
                  <Box>
                    <Text fontSize={25} fontWeight={"bold"}>
                      {t("basic_details")}
                    </Text>
                  </Box>
                </HStack>
                <Divider my={2} />
                {matches_list?.[0]?.member_profile?.dob && (
                  <MemberDetailOperator
                    icon={<BsPersonFill size={25} color={"#ab6c95"} />}
                    text={CalculateAgeForMember(
                      matches_list?.[0]?.member_profile?.dob
                    )}
                    label={"வயது"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.created_for && (
                  <MemberDetailOperator
                    icon={<BsPersonFill size={25} color={"#ab6c95"} />}
                    text={`${getUserDetailsFromValueForTamil(
                      CreatedForOptions,
                      matches_list?.[0]?.member_profile?.created_for
                    )}`}
                    label={"உருவாக்கப்பட்ட சுயவிவரம்"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.height && (
                  <MemberDetailOperator
                    icon={<GiBodyHeight size={25} color={"#ab6c95"} />}
                    text={getUserDetailsFromValue(
                      HeightFromOptions,
                      matches_list?.[0]?.member_profile?.height
                    )}
                    label={"உயரம்"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.weight && (
                  <MemberDetailOperator
                    icon={<FaHeart size={25} color={"#ab6c95"} />}
                    text={getUserDetailsFromValue(
                      WeightOptions,
                      matches_list?.[0]?.member_profile?.weight
                    )}
                    label={"எடை"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.marital_status && (
                  <MemberDetailOperator
                    icon={<GiBigDiamondRing size={25} color={"#ab6c95"} />}
                    text={getUserDetailsFromValueForTamil(
                      MaritalStatusOptions,
                      matches_list?.[0]?.member_profile?.marital_status
                    )}
                    label={"திருமண நிலை"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.number_of_children && (
                  <MemberDetailOperator
                    icon={<BsPersonFill size={25} color={"#ab6c95"} />}
                    text={
                      matches_list?.[0]?.member_profile?.number_of_children > 0
                        ? matches_list?.[0]?.member_profile?.number_of_children
                        : "இல்லை"
                    }
                    label={"குழந்தைகளின் எண்ணிக்கை"}
                  />
                )}
                {/* {matches_list?.[0]?.member_profile?.body_type?.ta_label && (
                  <MemberDetailOperator
                    icon={<FaHeart size={25} color={"#ab6c95"} />}
                    text={
                      matches_list?.[0]?.member_profile?.body_type?.ta_label
                    }
                    label={"உடல் அமைப்பு"}
                  />
                )} */}
                {matches_list?.[0]?.member_profile?.physical_status && (
                  <MemberDetailOperator
                    icon={
                      <MdOutlineWheelchairPickup size={25} color={"#ab6c95"} />
                    }
                    text={getUserDetailsFromValueForTamil(
                      PhysicalStatusOptions,
                      matches_list?.[0]?.member_profile?.physical_status
                    )}
                    label={"உடல் நிலை"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.mother_tongue && (
                  <MemberDetailOperator
                    icon={<MdLanguage size={25} color={"#ab6c95"} />}
                    text={`${getUserDetailsFromValueForTamil(
                      MotherTongueOptions,
                      matches_list?.[0]?.member_profile?.mother_tongue
                    )}`}
                    label={"தாய் மொழி"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.eating_habit && (
                  <MemberDetailOperator
                    icon={<ImSpoonKnife size={25} color={"#ab6c95"} />}
                    text={getUserDetailsFromValueForTamil(
                      EatingHabitsOptions,
                      matches_list?.[0]?.member_profile?.eating_habit
                    )}
                    label={"உணவு பழக்கம்"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.drinking_habit && (
                  <MemberDetailOperator
                    icon={<BiDrink size={25} color={"#ab6c95"} />}
                    text={
                      getUserDetailsFromValueForTamil(
                        DrinkingHabitsOptions,
                        matches_list?.[0]?.member_profile?.drinking_habit
                      )

                      // matches_list?.[0]?.member_profile?.drinking_habit
                      //   ?.ta_label === "No"
                      //   ? "Doesn't Drink"
                      //   : matches_list?.[0]?.member_profile?.drinking_habit
                      //       ?.ta_label === "Yes"
                      //   ? "Drinks Reqularly"
                      //   : "Drinks Occasionally"
                    }
                    label={"குடிப்பழக்கம்"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.smoking_habit && (
                  <MemberDetailOperator
                    icon={<MdSmokingRooms size={25} color={"#ab6c95"} />}
                    text={
                      getUserDetailsFromValueForTamil(
                        SmokingHabitsOptions,
                        matches_list?.[0]?.member_profile?.smoking_habit
                      )
                      // matches_list?.[0]?.member_profile?.smoking_habit
                      //   ?.ta_label === "No"
                      //   ? "Doesn't Smoke"
                      //   : matches_list?.[0]?.member_profile?.drinking_habit
                      //       ?.ta_label === "Yes"
                      //   ? "Smokes Reqularly"
                      //   : "Smokes Occasionally"
                    }
                    label={"புகைபிடிக்கும் பழக்கம்"}
                  />
                )}
                {/* {matches_list?.[0]?.member_profile?.mobile ===
                  "need_new_subscription" && (
                  <Popconfirm
                    title={"Subscribe plan to view mobile number"}
                    onConfirm={() => {
                      navigate(ROUTES.USER_SUBSCRIPTION);
                    }}
                  >
                    <Box my={2} cursor={"pointer"}>
                      <HStack alignItems={"center"}>
                        <MemberDetailOperator
                          icon={<FaPhoneAlt size={25} color={"#ab6c95"} />}
                          text={``}
                        />
                        <Text
                          color={"#712e5a"}
                          bold
                        >{`Subscribe Plan To View Mobile Number`}</Text>
                      </HStack>
                    </Box>
                  </Popconfirm>
                )}
                {matches_list?.[0]?.member_profile?.mobile ===
                  "need_subscription" && (
                  <Popconfirm
                    title={"Subscribe plan to view mobile number"}
                    onConfirm={() => {
                      navigate(ROUTES.USER_SUBSCRIPTION);
                    }}
                  >
                    <Box my={2} cursor={"pointer"}>
                      <HStack alignItems={"center"}>
                        <MemberDetailOperator
                          icon={<FaPhoneAlt size={25} color={"#ab6c95"} />}
                          text={``}
                        />
                        <Text
                          color={"#712e5a"}
                          bold
                        >{`Subscribe Plan To View Mobile Number`}</Text>
                      </HStack>
                    </Box>
                  </Popconfirm>
                )}
                {matches_list?.[0]?.member_profile?.mobile ===
                  "use_subscription" && (
                  <Popconfirm
                    title={"Use Plan To view Mobile Number"}
                    onConfirm={() => {
                      showPlanModal();
                    }}
                  >
                    <Box my={2} cursor={"pointer"}>
                      <HStack alignItems={"center"}>
                        <MemberDetailOperator
                          icon={<FaPhoneAlt size={25} color={"#ab6c95"} />}
                          text={``}
                        />
                        <Text
                          color={"#712e5a"}
                          bold
                        >{`View Mobile Number`}</Text>
                      </HStack>
                    </Box>
                  </Popconfirm>
                )}
                {matches_list?.[0]?.member_profile?.mobile === "hidden" && (
                  <Popconfirm
                    title={"Send request to view contact details"}
                    onConfirm={() => {
                      sendContactRequest(_member_id, "contact");
                    }}
                  >
                    <Box my={2} cursor={"pointer"}>
                      <HStack alignItems={"center"}>
                        <MemberDetailOperator
                          icon={<FaPhoneAlt size={25} color={"#ab6c95"} />}
                          text={``}
                        />
                        <Text color={"#712e5a"} bold>{`-  XXXXXXXXXX`}</Text>
                      </HStack>
                    </Box>
                  </Popconfirm>
                )} */}
                {/* {matches_list?.[0]?.member_profile?.mobile !== "hidden" &&
                  matches_list?.[0]?.member_profile?.mobile !==
                    "need_subscription" &&
                  matches_list?.[0]?.member_profile?.mobile !==
                    "need_new_subscription" &&
                  matches_list?.[0]?.member_profile?.mobile !==
                    "use_subscription" && (
                    // <Popconfirm
                    //   title={"View mobile number"}
                    //   onConfirm={() => {
                    //     // sendContactRequest(_member_id, "contact");
                    //   }}
                    // >
                    <Box my={2} cursor={"pointer"}>
                      <MemberDetailOperator
                        icon={<FaPhoneAlt size={25} color={"#ab6c95"} />}
                        text={`${matches_list?.[0]?.member_profile?.mobile}`}
                        label={"கைபேசி எண்"}
                      />
                    </Box>
                    // </Popconfirm>
                  )} */}

                <Box position={"absolute"} top={10} right={5} my={5}>
                  <_Image
                    // className="ant-image-mask-info"
                    onClick={() => {
                      // setVisible(true);
                    }}
                    width={40}
                    height={40}
                    style={{
                      objectFit: "cover",
                      zIndex: -999,
                      // borderRadius: "50%",
                    }}
                    src={getCroppedImageUrl(
                      matches_list?.[0]?.member_cover_photo?.cropped_photo_url,
                      matches_list?.[0]?.member_profile?.gender
                    )}
                    preview={{ visible: false }}
                  />
                  <Box justifyContent={"center"} alignItems={"center"}>
                    <Text
                      fontSize={25}
                      textAlign={"center"}
                      bold
                      color={"#712e5a"}
                    >
                      {matches_list?.[0]?.member_profile?.name}
                    </Text>
                    <Text>{`( ${matches_list?.[0]?.member_root?.profile_number} )`}</Text>
                  </Box>
                </Box>

                <Pressable
                  position={"absolute"}
                  right={5}
                  top={2}
                  alignItems={"flex-end"}
                  onPress={() => {
                    let uuid_alternative = getUUID();

                    if (
                      user_plan?.allowed_contact_details_view -
                        user_plan?.viewed_contact_details_count >=
                      get_shortlisted_profiles?.length + 1
                    ) {
                      setIsReload(true);

                      checkShortlistProfiles();
                    } else if (
                      get_shortlisted_profiles?.find(
                        (x) => x.id === matches_list?.[0]?.id
                      )
                    ) {
                      if (get_shortlisted_profiles?.length)
                        temp = get_shortlisted_profiles?.filter(
                          (x) => x.id !== matches_list?.[0]?.id
                        );
                      setIsRerender(!isRerender);
                      if (temp.length === get_shortlisted_profiles?.length)
                        temp.push(matches_list?.[0]?.id);
                      shortlisted_array.push(matches_list?.[0]?.id);
                      storeItem("shortlisted_profiles", temp);
                      dispatch(
                        dynamicSet("print_count_alternative", uuid_alternative)
                      );
                    } else {
                      toast.error(`Shortlist limit reached`);
                    }
                  }}
                  w="80px"
                >
                  {get_shortlisted_profiles?.find(
                    (x) => x.id === matches_list?.[0]?.id
                  ) ? (
                    <Box>
                      <FaHeart color="#e33653" size={30} />
                    </Box>
                  ) : (
                    <Box>
                      <FiHeart color="#e33653" size={30} />
                    </Box>
                  )}
                </Pressable>
              </Box>

              <Box
                // key={i}
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: 8,
                  boxShadow: "0 0 10px #dcdcdc",
                  elevation: 15,
                  shadowOpacity: 0.22,
                  shadowRadius: 2.22,
                  borderBottomWidth: 3,
                  borderBottomColor: "#712e5a",

                  marginHorizontal: 2,
                  paddingHorizontal: 5,

                  marginTop: 10,
                  //width: "40%",
                }}
              >
                <HStack alignItems={"center"}>
                  <Box
                    style={{
                      paddingHorizontal: 5,
                    }}
                  >
                    <FaPrayingHands size={30} color={"#712e5a"} />
                  </Box>
                  <Box>
                    <Text fontSize={25} fontWeight={"bold"}>
                      {t("religion_information")}
                    </Text>
                  </Box>
                </HStack>
                <Divider my={2} />

                {matches_list?.[0]?.member_profile?.religion && (
                  <MemberDetailOperator
                    icon={<FaPrayingHands size={25} color={"#ab6c95"} />}
                    text={getUserDetailsFromValueForTamil(
                      ReligionsOptions,
                      matches_list?.[0]?.member_profile?.religion
                    )}
                    label={"மதம்"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.caste && (
                  <MemberDetailOperator
                    icon={<BsJournalBookmarkFill size={25} color={"#ab6c95"} />}
                    text={`${getUserDetailsFromValueForTamil(
                      CasteOptions,
                      matches_list?.[0]?.member_profile?.caste
                    )}`}
                    label={"சாதி"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.sub_caste && (
                  <MemberDetailOperator
                    icon={<BsJournalBookmarkFill size={25} color={"#ab6c95"} />}
                    text={`${getUserDetailsFromValueForTamil(
                      SubCastesOptions,
                      matches_list?.[0]?.member_profile?.sub_caste
                    )}`}
                    label={"உட்பிரிவு"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.raasi && (
                  <MemberDetailOperator
                    icon={<AiFillStar size={25} color={"#ab6c95"} />}
                    text={getUserDetailsFromValueForTamil(
                      RaasiOptions,
                      matches_list?.[0]?.member_profile?.raasi
                    )}
                    label={"ராசி"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.star && (
                  <MemberDetailOperator
                    icon={<AiFillStar size={25} color={"#ab6c95"} />}
                    text={`${getUserDetailsFromValueForTamil(
                      StarsOptions,
                      matches_list?.[0]?.member_profile?.star
                    )}`}
                    label={"நட்சத்திரம்"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.gothram && (
                  <MemberDetailOperator
                    icon={<VscTypeHierarchySub size={25} color={"#ab6c95"} />}
                    text={matches_list?.[0]?.member_profile?.gothram}
                    label={"கோத்திரம்"}
                  />
                )}
                {/* {matches_list?.[0]?.member_profile?.dhosams?.length > 0 && ( */}
                <MemberDetailOperator
                  icon={<FaHeart size={25} color={"#ab6c95"} />}
                  text={matches_list?.[0]?.member_profile?.doshams}
                  label={"தோசம்"}
                  type={"multi"}
                  options={DoshamsOptions}
                />
                {/* )} */}
                {/* {matches_list?.[0]?.member_horoscope?.birth_chart_url &&
                  matches_list?.[0]?.member_horoscope?.navamansha_chart_url &&
                  matches_list?.[0]?.member_horoscope?.status === "success" &&
                  login_user_current_plan && (
                    <Pressable
                      onPress={() => {
                        showModal();
                      }}
                    >
                      <MemberDetailOperator
                        icon={<FcDiploma2 size={25} color={"#ab6c95"} />}
                        text={"View Horoscope"}
                      />
                    </Pressable>
                  )} */}
                {/* {matches_list?.[0]?.member_horoscope?.birth_chart_url &&
                  matches_list?.[0]?.member_horoscope?.navamansha_chart_url &&
                  !matches_list?.[0]?.member_horoscope?.status &&
                  !login_user_current_plan && (
                    <Popconfirm
                      title={"Subscribe plan to view horoscope"}
                      onConfirm={() => {
                      }}
                    >
                      <MemberDetailOperator
                        icon={<FcDiploma2 size={25} color={"#ab6c95"} />}
                        text={"View Horoscope"}
                      />
                    </Popconfirm>
                  )} */}
              </Box>

              <Box
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: 8,
                  boxShadow: "0 0 10px #dcdcdc",
                  elevation: 15,
                  shadowOpacity: 0.22,
                  shadowRadius: 2.22,
                  borderBottomWidth: 3,
                  borderBottomColor: "#712e5a",

                  marginHorizontal: 2,
                  paddingHorizontal: 5,

                  marginTop: 10,
                }}
              >
                <HStack alignItems={"center"}>
                  <Box
                    style={{
                      paddingHorizontal: 5,
                    }}
                  >
                    <FaMapMarkedAlt size={30} color={"#712e5a"} />
                  </Box>
                  <Box>
                    <Text fontSize={25} fontWeight={"bold"}>
                      {t("location_details")}
                    </Text>
                  </Box>
                </HStack>
                <Divider my={2} />
                {matches_list?.[0]?.member_profile?.country &&
                  matches_list?.[0]?.member_profile?.state &&
                  matches_list?.[0]?.member_profile?.district && (
                    <MemberDetailOperator
                      icon={<ImLocation size={25} color={"#ab6c95"} />}
                      text={`${getDistrict(
                        matches_list?.[0]?.member_profile?.district
                      )}, ${getState(
                        matches_list?.[0]?.member_profile?.state,
                        matches_list?.[0]?.member_profile?.country
                      )}, ${getCountry(
                        matches_list?.[0]?.member_profile?.country
                      )}`}
                      label={"இருப்பிடம்"}
                    />
                  )}

                {matches_list?.[0]?.member_profile?.ancestor_origin && (
                  <MemberDetailOperator
                    icon={<ImLocation size={25} color={"#ab6c95"} />}
                    text={`${getDistrict(
                      matches_list?.[0]?.member_profile?.ancestor_origin
                    )}`}
                    label={"பூர்வீகம்"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.citizenship && (
                  <MemberDetailOperator
                    icon={<BsFillFlagFill size={25} color={"#ab6c95"} />}
                    text={`${getNationality(
                      NationalityOptions,
                      matches_list?.[0]?.member_profile?.citizenship
                    )}`}
                    label={"குடியுரிமை"}
                  />
                )}
              </Box>

              <Box
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: 8,
                  boxShadow: "0 0 10px #dcdcdc",
                  elevation: 15,
                  shadowOpacity: 0.22,
                  shadowRadius: 2.22,
                  borderBottomWidth: 3,
                  borderBottomColor: "#712e5a",

                  marginHorizontal: 2,
                  paddingHorizontal: 5,

                  marginTop: 10,
                }}
              >
                <HStack alignItems={"center"}>
                  <Box
                    style={{
                      paddingHorizontal: 5,
                    }}
                  >
                    <MdWork size={30} color={"#712e5a"} />
                  </Box>
                  <Box>
                    <Text fontSize={25} fontWeight={"bold"}>
                      {t("professional_information")}
                    </Text>
                  </Box>
                </HStack>
                <Divider my={2} />
                {/* {matches_list?.[0]?.member_profile?.education?.en_label &&
                  matches_list?.[0]?.member_profile?.institution && (
                    <MemberDetailOperator
                      icon={<IoMdSchool size={25} color={"#ab6c95"} />}
                      text={`${matches_list?.[0]?.member_profile?.education?.ta_label} from ${matches_list?.[0]?.member_profile?.institution}`}
                      label={"கல்வி"}
                    />
                  )} */}
                {matches_list?.[0]?.member_profile?.educations?.length > 0 && (
                  <MemberDetailOperator
                    icon={<IoMdSchool size={25} color={"#ab6c95"} />}
                    text={matches_list?.[0]?.member_profile?.educations}
                    label={"கல்வி"}
                    type={"multi"}
                    options={EducationsOptions}
                  />
                )}

                {matches_list?.[0]?.member_profile?.employment_type && (
                  <MemberDetailOperator
                    icon={<MdWork size={25} color={"#ab6c95"} />}
                    text={`${getUserDetailsFromValueForTamil(
                      EmploymentTypesOptions,
                      matches_list?.[0]?.member_profile?.employment_type
                    )}`}
                    label={"தொழில்"}
                  />
                )}

                {matches_list?.[0]?.member_profile?.annual_income_to && (
                  <MemberDetailOperator
                    icon={<HiCurrencyRupee size={25} color={"#ab6c95"} />}
                    text={`${getAnnualIncome(
                      AnnualIncomeOptions,
                      matches_list?.[0]?.member_profile?.annual_income_to
                    )}`}
                    label={"ஆண்டு வருமானம்"}
                  />
                )}
              </Box>

              <Box
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: 8,
                  boxShadow: "0 0 10px #dcdcdc",
                  elevation: 15,
                  shadowOpacity: 0.22,
                  shadowRadius: 2.22,
                  borderBottomWidth: 3,
                  borderBottomColor: "#712e5a",

                  marginHorizontal: 2,
                  paddingHorizontal: 5,

                  marginTop: 10,
                }}
              >
                <HStack alignItems={"center"}>
                  <Box
                    style={{
                      paddingHorizontal: 5,
                    }}
                  >
                    <MdFamilyRestroom size={30} color={"#712e5a"} />
                  </Box>
                  <Box>
                    <Text fontSize={25} fontWeight={"bold"}>
                      {t("family_details")}
                    </Text>
                  </Box>
                </HStack>
                <Divider my={2} />
                {matches_list?.[0]?.member_profile?.family_type &&
                  matches_list?.[0]?.member_profile?.family_status &&
                  matches_list?.[0]?.member_profile?.family_value && (
                    <MemberDetailOperator
                      icon={<HiUserGroup size={25} color={"#ab6c95"} />}
                      text={`${getUserDetailsFromValueForTamil(
                        FamilyTypeOptions,
                        matches_list?.[0]?.member_profile?.family_type
                      )}, ${getUserDetailsFromValueForTamil(
                        FamilyStatusOptions,
                        matches_list?.[0]?.member_profile?.family_status
                      )} மற்றும் ${getUserDetailsFromValueForTamil(
                        FamilyValueOptions,
                        matches_list?.[0]?.member_profile?.family_value
                      )}`}
                      label={"குடும்பம்"}
                    />
                  )}
                {matches_list?.[0]?.member_profile?.family_type &&
                  !matches_list?.[0]?.member_profile?.family_status &&
                  !matches_list?.[0]?.member_profile?.family_value && (
                    <MemberDetailOperator
                      icon={<HiUserGroup size={25} color={"#ab6c95"} />}
                      text={getUserDetailsFromValueForTamil(
                        FamilyTypeOptions,
                        matches_list?.[0]?.member_profile?.family_type
                      )}
                      label={"குடும்பம்"}
                    />
                  )}
                {matches_list?.[0]?.member_profile?.family_status &&
                  !matches_list?.[0]?.member_profile?.family_type &&
                  !matches_list?.[0]?.member_profile?.family_value && (
                    <MemberDetailOperator
                      icon={<HiUserGroup size={25} color={"#ab6c95"} />}
                      text={getUserDetailsFromValueForTamil(
                        FamilyStatusOptions,
                        matches_list?.[0]?.member_profile?.family_status
                      )}
                      label={"குடும்ப நிலை"}
                    />
                  )}

                {matches_list?.[0]?.member_profile?.family_value &&
                  !matches_list?.[0]?.member_profile?.family_type &&
                  !matches_list?.[0]?.member_profile?.family_status && (
                    <MemberDetailOperator
                      icon={<HiUserGroup size={25} color={"#ab6c95"} />}
                      text={getUserDetailsFromValueForTamil(
                        FamilyValueOptions,
                        matches_list?.[0]?.member_profile?.family_value
                      )}
                      label={"குடும்ப மதிப்பு"}
                    />
                  )}
                {matches_list?.[0]?.member_profile?.father_occupation && (
                  <MemberDetailOperator
                    icon={<IoIosMan size={25} color={"#ab6c95"} />}
                    text={`${getUserDetailsFromValueForTamil(
                      FathersOccupationOptions,
                      matches_list?.[0]?.member_profile?.father_occupation
                    )}`}
                    label={"தந்தையின் தொழில்"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.mother_occupation && (
                  <MemberDetailOperator
                    icon={<BiFemale size={28} color={"#ab6c95"} />}
                    text={`${getUserDetailsFromValueForTamil(
                      MothersOccupationOptions,
                      matches_list?.[0]?.member_profile?.mother_occupation
                    )}`}
                    label={"தாயின் தொழில்"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.number_of_brothers > -1 && (
                  <MemberDetailOperator
                    icon={<FaMale size={25} color={"#ab6c95"} />}
                    text={
                      matches_list?.[0]?.member_profile?.number_of_brothers > 0
                        ? `${matches_list?.[0]?.member_profile?.number_of_brothers} brother(s)`
                        : "இல்லை"
                    }
                    label={"சகோதரர்கள்"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.number_of_married_brothers >
                  -1 && (
                  <MemberDetailOperator
                    icon={<FaMale size={25} color={"#ab6c95"} />}
                    text={
                      matches_list?.[0]?.member_profile
                        ?.number_of_married_brothers > 0
                        ? `${matches_list?.[0]?.member_profile?.number_of_married_brothers} brother(s) married`
                        : "இல்லை"
                    }
                    label={"திருமணமான சகோதரர்கள்"}
                  />
                )}
                {matches_list?.[0]?.member_profile?.number_of_sisters > -1 && (
                  <MemberDetailOperator
                    icon={<FaFemale size={25} color={"#ab6c95"} />}
                    text={
                      matches_list?.[0]?.member_profile?.number_of_sisters > 0
                        ? `${matches_list?.[0]?.member_profile?.number_of_sisters} sister(s)`
                        : "இல்லை"
                    }
                    label={"சகோதரிகள்"}
                  />
                )}

                {matches_list?.[0]?.member_profile?.number_of_married_sisters >
                  -1 && (
                  <MemberDetailOperator
                    icon={<FaFemale size={25} color={"#ab6c95"} />}
                    text={
                      matches_list?.[0]?.member_profile
                        .number_of_married_sisters > 0
                        ? `${matches_list?.[0]?.member_profile?.number_of_married_sisters} sister(s) married`
                        : "இல்லை"
                    }
                    label={"திருமணமான சகோதரிகள்"}
                  />
                )}
              </Box>
              <Box
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: 8,
                  boxShadow: "0 0 10px #dcdcdc",
                  elevation: 15,
                  shadowOpacity: 0.22,
                  shadowRadius: 2.22,
                  borderBottomWidth: 3,
                  borderBottomColor: "#712e5a",

                  marginHorizontal: 2,
                  paddingHorizontal: 5,

                  marginTop: 10,
                }}
              >
                <HStack alignItems={"center"}>
                  <Box
                    style={{
                      paddingHorizontal: 5,
                    }}
                  >
                    <MdSupervisedUserCircle size={30} color={"#712e5a"} />
                  </Box>
                  <Box>
                    <Text fontSize={25} fontWeight={"bold"}>
                      {t("preferences")}
                    </Text>
                  </Box>
                </HStack>
                <Divider my={2} />

                {matches_list?.[0]?.member_preference?.age_from &&
                  matches_list?.[0]?.member_preference?.age_to && (
                    <MemberDetailOperator
                      icon={<BsPersonFill size={25} color={"#ab6c95"} />}
                      text={`${
                        matches_list?.[0]?.member_preference?.age_from === -1
                          ? "Any"
                          : matches_list?.[0]?.member_preference?.age_from
                      } - ${
                        matches_list?.[0]?.member_preference?.age_to > 60
                          ? "Any"
                          : matches_list?.[0]?.member_preference?.age_to
                      }`}
                      label={"வயது"}
                    />
                  )}
                {matches_list?.[0]?.member_preference?.annual_income_from &&
                  matches_list?.[0]?.member_preference?.annual_income_to && (
                    <MemberDetailOperator
                      icon={<HiCurrencyRupee size={25} color={"#ab6c95"} />}
                      text={`${getUserDetailsFromValueForTamil(
                        AnnualIncomeFromOptions,
                        matches_list?.[0]?.member_preference?.annual_income_from
                      )}-${getUserDetailsFromValueForTamil(
                        AnnualIncomeToOptions,
                        matches_list?.[0]?.member_preference?.annual_income_to
                      )}`}
                      label={"ஆண்டு வருமானம்"}
                    />
                  )}
                {matches_list?.[0]?.member_preference?.educations?.length >
                  0 && (
                  <HStack
                    // key={index}
                    alignItems={"center"}
                    paddingX={2}
                    paddingVertical={5}
                  >
                    <Box
                      style={{
                        paddingHorizontal: 5,
                        width: "5%",
                      }}
                    >
                      <MdWork size={25} color={"#ab6c95"} />
                    </Box>
                    <Box width={"35%"}>
                      <Text py={1} fontSize={20} color={"black"}>
                        {"கல்வி"}
                      </Text>
                    </Box>
                    <Box width={"40%"}>
                      <HStack>
                        {matches_list?.[0]?.member_preference?.educations?.map(
                          (x, i) => {
                            return (
                              <Text py={1} fontSize={20} color={"black"}>
                                {`${getUserDetailsFromValue(
                                  EducationsOptions,
                                  x
                                )}${
                                  i ===
                                  matches_list?.[0]?.member_preference
                                    ?.educations?.length -
                                    1
                                    ? ""
                                    : ", "
                                }`}
                              </Text>
                            );
                          }
                        )}
                      </HStack>
                    </Box>

                    <br />
                  </HStack>
                )}

                {matches_list?.[0]?.member_preference?.employment_types
                  ?.length > 0 && (
                  <HStack
                    // key={index}
                    alignItems={"center"}
                    paddingX={2}
                    paddingVertical={5}
                  >
                    <Box
                      style={{
                        paddingHorizontal: 5,
                        width: "5%",
                      }}
                    >
                      <MdWork size={25} color={"#ab6c95"} />
                    </Box>
                    <Box width={"35%"}>
                      <Text py={1} fontSize={20} color={"black"}>
                        {"வேலை வகை"}
                      </Text>
                    </Box>
                    <Box width={"40%"}>
                      <HStack>
                        {matches_list?.[0]?.member_preference?.employment_types?.map(
                          (x, i) => {
                            return (
                              <Text py={1} fontSize={20} color={"black"}>
                                {`${getUserDetailsFromValueForTamil(
                                  EmploymentTypesOptions,
                                  x
                                )}${
                                  i ===
                                  matches_list?.[0]?.member_preference
                                    ?.employment_types?.length -
                                    1
                                    ? ""
                                    : ", "
                                }`}
                              </Text>
                            );
                          }
                        )}
                      </HStack>
                    </Box>

                    <br />
                  </HStack>
                )}
                {matches_list?.[0]?.member_preference?.districts?.length >
                  0 && (
                  <HStack
                    // key={index}
                    alignItems={"center"}
                    paddingX={2}
                    paddingVertical={5}
                  >
                    <Box
                      style={{
                        paddingHorizontal: 5,
                        width: "5%",
                      }}
                    >
                      <ImLocation size={25} color={"#ab6c95"} />
                    </Box>
                    <Box width={"35%"}>
                      <Text py={1} fontSize={20} color={"black"}>
                        {"நகரங்கள்"}
                      </Text>
                    </Box>
                    <Box width={"40%"}>
                      <HStack>
                        {matches_list?.[0]?.member_preference?.districts?.map(
                          (x, i) => {
                            return (
                              <Text py={1} fontSize={20} color={"black"}>
                                {`${getDistrict(x)}${
                                  i ===
                                  matches_list?.[0]?.member_preference
                                    ?.districts?.length -
                                    1
                                    ? ""
                                    : ", "
                                }`}
                              </Text>
                            );
                          }
                        )}
                      </HStack>
                    </Box>

                    <br />
                  </HStack>
                )}
              </Box>
            </Box>
          ) : (
            <Box
              flex={1}
              alignItems={"center"}
              justifyContent={"center"}
              marginTop={10}
              height={100}
            >
              <Image
                source={{
                  uri: "https://blr1.vultrobjects.com/assets/no_data.gif",
                }}
                width={40}
                height={40}
              />
              <Box
                // width={40}
                // height={40}
                alignItems={"center"}
                position={"absolute"}
                bottom={0}
              >
                <Text
                  style={{
                    textAlign: "center",
                  }}
                  mt={10}
                  color={"#712e5a"}
                >
                  {t("all_matches_no_data")}
                </Text>
              </Box>
            </Box>
          )}
        </>
      )}
      <Modal
        width={800}
        title="Chart"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <HoroscopeChart
          login_user_horoscope={matches_list?.[0]?.member_horoscope}
          item={matches_list?.[0]?.member_profile}
        />
      </Modal>
      <Modal
        title="View contact number"
        open={isPlanModalOpen}
        onOk={handleOk}
        onCancel={handlePlanModalCancel}
      >
        <Text>{"View mobile number"}</Text>
        {/* <p>{`Allowed contact details view - ${user_plan?.allowed_contact_details_view}`}</p> */}
        {/* <p>{`Viewed contact details count - ${user_plan?.viewed_contact_details_count}`}</p> */}
      </Modal>
    </>
  );
};
export default MemberCardOperatorViewTamil;

import React from "react";
import { useParams } from "react-router-dom";
import { Box, Center } from "native-base";
import AdminSubscriptionPlanList from "@views/components/admin_subscription_plan/admin_subscription_plan_list";
import Scrollbars from "react-custom-scrollbars-2";

const AdminSubscriptionPlan = () => {
  // const { element_id } = useParams();
  return (
    <Center>
      <Box alignItems="center" width={"90%"}>
        <AdminSubscriptionPlanList />
      </Box>
    </Center>
  );
};
export default AdminSubscriptionPlan;

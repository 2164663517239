export const FathersOccupationOptions = [
  {
    value: "employed",
    p_o: [],
    en_label: "Employed",
    ta_label: "பணியாளர்",
  },
  {
    value: "business_man",
    p_o: [],
    en_label: "Business/Entrepreneur",
    ta_label: "வணிகம்",
  },
  {
    value: "professional",
    p_o: [],
    en_label: "Professional",
    ta_label: "தொழில்முறை வேலை",
  },
  {
    value: "retired",
    p_o: [],
    en_label: "Retired",
    ta_label: "ஓய்வு பெற்றவர்",
  },
  {
    value: "farmer",
    p_o: [],
    en_label: "Farmer / Agriculturist",
    ta_label: "விவசாயம்",
  },
  {
    value: "not_working",
    p_o: [],
    en_label: "Not Working",
    ta_label: "வேலை செய்யவில்லை",
  },
  {
    value: "late",
    p_o: [],
    en_label: "Late",
    ta_label: "இல்லை",
  },
  {
    value: "lawer",
    p_o: [],
    en_label: "Lawyer/Legal Professional",
    ta_label: "வழக்கறிஞர்",
  },
  {
    value: "doctor",
    number_value: null,
    p_o: [],
    en_label: "Doctor/Medical Professional",
    ta_label: "மருத்துவர்",
  },
  {
    value: "self_employed",
    p_o: [],
    en_label: "Self Employed",
    ta_label: "சுயதொழில்",
  },
  {
    value: "government_employee",
    p_o: [],
    en_label: "Government employee",
    ta_label: "அரசாங்க ஊழியர்",
  },
];

import { gql } from "@apollo/client";

export const getOperatorMatches = gql`
  query getOperatorMatches(
    $page_number: Float
    $page_limit: Float
    $filter: member_filter_input
  ) {
    operator_matches: getOperatorMatches(
      filter: $filter
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        member_root {
          profile_number
          last_login_datetime
        }
        member_profile {
          name
          dob
          mobile
          gender
          mobile
          country
          state
          city
          district
          complexion
          created_for
          height
          weight
          marital_status
          number_of_children
          body_type
          physical_status
          mother_tongue
          eating_habit
          drinking_habit
          smoking_habit
          religion
          caste
          sub_caste
          raasi
          star
          gothram
          doshams
          ancestor_origin
          citizenship
          education
          educations
          institution
          employment_type
          employer
          annual_income_to
          annual_income_from
          family_value
          family_type
          family_status
          father_occupation
          mother_occupation
          number_of_brothers
          number_of_sisters
          number_of_married_brothers
          number_of_married_sisters
        }
        member_preference {
          age_from
          age_to
          height_from
          height_to
          annual_income_from
          annual_income_to
          marital_statuses
          body_types
          physical_statuses
          mother_tongues
          eating_habits
          drinking_habits
          smoking_habits
          religions
          castes
          sub_castes
          stars
          raasis
          doshams
          countries
          states
          cities
          educations
          employment_types
        }
        member_interest_status {
          status
          request_id
        }
        member_requests_from_me {
          id

          type
          requested_datetime
          reminder_datetime
          accepted_datetime
          rejected_datetime
          updated_datetime
          user {
            id
            member_profile {
              name
            }
          }
          member {
            id
            member_profile {
              name
            }
          }
        }
        member_horoscope {
          birth_chart_url
          navamansha_chart_url
          status
          time_of_birth
          place_of_birth
        }
        member_cover_photo {
          is_main_photo
          photo_url
          photo_view
          cropped_photo_url
        }
        member_photos {
          is_main_photo
          photo_url
        }
        is_shortlisted
        member_interest_status {
          status
          request_id
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const getMatches = gql`
  query getMatches($page_number: Float, $page_limit: Float) {
    matches: getMatches(page_number: $page_number, page_limit: $page_limit) {
      items {
        id
        member_root {
          profile_number
          last_login_datetime
        }
        member_profile {
          id
          name
          dob
          gender
          religion
          caste
          raasi
          star
          education
          educations
          employment_type
        }
        member_cover_photo {
          photo_url
          photo_view
          cropped_photo_url
        }
        is_shortlisted
        member_interest_status {
          status
          request_id
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const getMember = gql`
  query getMemberById($id: String) {
    member: getMemberById(id: $id) {
      id
      member_root {
        profile_number
        last_login_datetime
      }
      member_profile {
        name
        dob
        mobile
        gender
        mobile
        country
        state
        city
        district
        complexion
        number_of_children
        created_for
        height
        weight
        marital_status
        body_type
        physical_status
        mother_tongue
        eating_habit
        drinking_habit
        smoking_habit
        religion
        caste
        sub_caste
        raasi
        star
        gothram
        doshams
        ancestor_origin
        citizenship
        education
        educations
        institution
        employment_type
        employer
        annual_income_from
        annual_income_to
        family_value
        family_type
        family_status
        father_name
        mother_name
        father_occupation
        mother_occupation
        number_of_brothers
        number_of_sisters
        number_of_married_brothers
        number_of_married_sisters
        about_family
      }
      member_preference {
        age_from
        age_to
        height_from
        height_to
        annual_income_from
        annual_income_to
        marital_statuses
        body_types
        physical_statuses
        mother_tongues
        eating_habits
        drinking_habits
        smoking_habits
        religions
        castes
        sub_castes
        stars
        raasis
        doshams
        countries
        states
        cities
        districts
        educations
        employment_types
      }
      member_interest_status {
        status
        request_id
      }
      member_requests_from_me {
        id
        type
        requested_datetime
        reminder_datetime
        accepted_datetime
        rejected_datetime
        updated_datetime
        user {
          id
          member_profile {
            name
          }
        }
        member {
          id
          member_profile {
            name
          }
        }
      }
      member_horoscope {
        birth_chart_url
        navamansha_chart_url
        status
        time_of_birth
        place_of_birth
      }
      member_cover_photo {
        is_main_photo
        photo_url
        photo_view
        cropped_photo_url
      }
      member_photos {
        is_main_photo
        photo_url
      }
      is_shortlisted
      member_interest_status {
        status
        request_id
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const getMySimilarMembers = gql`
  query getSimilarMembers($id: String) {
    similar_matches: getSimilarMembers(id: $id) {
      items {
        id
        member_profile {
          id
          name
          dob
          gender
          religion
          caste
        }
        member_cover_photo {
          photo_url
          photo_view
          cropped_photo_url
        }
      }
    }
  }
`;

export const addMultipleContactsViewMutation = gql`
  mutation addMultipleMemberContactView($data: contact_view_check) {
    addMultipleMemberContactView(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const reduceContactsViewMutation = gql`
  mutation reduceContactsView($id: String) {
    reduceContactsView(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const getMemberForAdmin = gql`
  query getMemberByIdForAdmin($id: String) {
    member_for_admin: getMemberByIdForAdmin(id: $id) {
      id
      member_root {
        profile_number
        last_login_datetime
      }
      member_horoscope {
        time_of_birth
        place_of_birth
      }
      member_profile {
        name
        dob
        gender
        institution
        employer
        country
        state
        city
        district
        religion
        caste
        sub_caste
        annual_income_to
        annual_income_from
        education
        educations
        complexion
        number_of_children
        created_for
        height
        weight
        marital_status
        body_type
        physical_status
        mother_tongue
        eating_habit
        drinking_habit
        smoking_habit
        religion
        caste
        sub_caste
        raasi
        star
        gothram
        doshams
        ancestor_origin
        citizenship
        education
        educations
        institution
        employment_type
        employer
        family_value
        family_type
        family_status
        father_name
        mother_name
        father_occupation
        mother_occupation
        number_of_brothers
        number_of_sisters
        number_of_married_brothers
        number_of_married_sisters
        about_family
      }
      member_preference {
        age_from
        age_to
        height_from
        height_to
        annual_income_from
        annual_income_to
        complexions
        marital_statuses
        body_types
        physical_statuses
        mother_tongues
        eating_habits
        drinking_habits
        smoking_habits
        religions
        castes
        sub_castes
        stars
        raasis
        doshams
        countries
        states
        districts
        educations
        employment_types
      }
      error {
        status_code
        message
      }
    }
  }
`;

import { gql } from "@apollo/client";
export const get_all_deleted_members = gql`
  query getDeletedMembers(
    $franchise_id: String
    $page_number: Float
    $page_limit: Float
    $search_string: String
  ) {
    get_all_deleted_members: getDeletedMembers(
      franchise_id: $franchise_id
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        name
        gender
        mobile
        dob
        country
        state
        district
        profile_id
        deleted_datetime
        created_datetime
        franchise_name
        delete_reason
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;

export const get_all_deleted_members_reports = gql`
  query getDeletedMembers(
    $franchise_id: String
    $page_number: Float
    $page_limit: Float
  ) {
    get_all_deleted_members_reports: getDeletedMembers(
      franchise_id: $franchise_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        gender
        mobile
        dob
        country
        state
        city
        district
        profile_id
        deleted_datetime
        created_datetime
        franchise_name
        delete_reason
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;

import AdminInvoiceReport from "@views/components/invoce_report/invoice_report";
import { Box, Center } from "native-base";
import React from "react";

const AdminInvoicePage = () => {
  return (
    <Center>
      <Box width={"100%"} mx="60px" height={"100%"}>
        <AdminInvoiceReport />
      </Box>
    </Center>
  );
};

export default AdminInvoicePage;

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
import { CitiesOptions } from "@master_data/common_json/cities";
import { StatesOptions } from "@master_data/common_json/states";
import _ from "lodash";
import { CountriesOptions } from "@master_data/common_json/countries";
import { AnnualIncomeOptions } from "@master_data/profile_json/annual_income";
import { DistrictsOptions } from "@master_data/common_json/districts";
import { getCountry, getDistrict, getState } from "./get_country_state_city";

export function generateExcelFromJSON(jsonData, filename, fieldMappings) {
  const getUserDetailsFromValue = (options, value, field, country) => {
    let user_detail = "";
    if (field === "district") {
      user_detail = getDistrict(value);
    } else if (field === "state") {
      user_detail = getState(value, country);
    } else {
      user_detail = options?.find((x) => x.value === value)?.en_label;
    }
    return user_detail;
  };
  const arrayElements = (items, options) => {
    let res = items?.map((x) => getUserDetailsFromValue(options, x));

    return res?.join(", ");
  };

  const flatData = jsonData.map((item) => {
    const flatItem = {};
    for (const fieldMapping of fieldMappings) {
      const keys = fieldMapping.field.split(".");
      let value = item;
      for (const key of keys) {
        if (value === null || value === undefined) {
          value = null;
          break;
        }
        value = value[key];
      }

      if (Array.isArray(value)) {
        // flatItem[fieldMapping.label] = value.join(", ");
        flatItem[fieldMapping.label] = arrayElements(
          value,
          fieldMapping.options
        );
      } else {
        if (
          fieldMapping.options &&
          Array.isArray(fieldMapping.options)
          //  &&
          // fieldMapping.field in fieldMapping.options
        ) {
          flatItem[fieldMapping.label] = getUserDetailsFromValue(
            fieldMapping.options,
            value,
            fieldMapping.field,
            flatItem["country"]
          );
        } else if (typeof value === "string" && value.includes(":")) {
          const date = new Date(value);
          flatItem[fieldMapping.label] = moment(date).format(
            fieldMapping.field === "dob" ? "DD/MM/YYYY" : "DD/MM/YYYY hh:mm a"
          );
        } else {
          flatItem[fieldMapping.label] = value === null ? null : value;
        }
      }
    }

    return flatItem;
  });

  const headers = fieldMappings.map((fieldMapping) => fieldMapping.label);
  const data = [
    headers,
    ...flatData.map((item) => headers.map((header) => item[header])),
  ];

  const ws = XLSX.utils.aoa_to_sheet(data);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  const blob = new Blob([XLSX.write(wb, { bookType: "xlsx", type: "array" })], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(blob, filename);
}

export const getOneCountry = (country_code) => {
  let selected_country = CountriesOptions?.find(
    (item) => _.snakeCase(item.i_c) === _.snakeCase(country_code)
  );
  return selected_country;
};
export const getOneState = (state_code, country_code) => {
  let selected_state = StatesOptions?.find(
    (item) =>
      _.snakeCase(item.i_c) === state_code &&
      _.snakeCase(item.c_c) === country_code
  );
  return selected_state;
};

export const getStatesList = (country_code) => {
  let state_list = StatesOptions?.filter(
    (x) => _.snakeCase(x.c_c) === country_code
  );
  state_list = state_list.map((x) => ({
    name: x.name,
    i_c: _.snakeCase(x.i_c),
    c_c: _.snakeCase(x.c_c),
  }));
  return state_list;
};

export const getCitiesList = (country_code, state_code) => {
  let cities_list = CitiesOptions.filter(
    (item) =>
      _.snakeCase(item.s_c) === state_code &&
      _.snakeCase(item.c_c) === country_code
  );
  cities_list = cities_list.map((x) => ({
    name: x.name,
    value: _.snakeCase(x.name),
  }));

  return cities_list;
};
export const getDistrictsList = (country_code, state_code) => {
  let districts_list = DistrictsOptions?.filter(
    (item) =>
      _.snakeCase(item.s_c) === state_code &&
      _.snakeCase(item.c_c) === country_code
  );

  districts_list = districts_list?.map((x) => ({
    name: x.name,
    value: _.snakeCase(x.name),
  }));

  return districts_list;
};

export const getUserDetailsFromValue = (options, value) => {
  let user_detail = options?.find((x) => x.value === value)?.en_label;

  return user_detail;
};

export const getUserDetailsFromValueForTamil = (options, value) => {
  let user_detail = options?.find((x) => x.value === value)?.ta_label;

  return user_detail;
};
export const getDoshamDetailsFromValueForTamil = (options, dosham_options) => {
  const matchingDoshamValues = dosham_options
    ?.map((d) => {
      const dosham = options.find((option) => option.value === d)?.ta_label;

      return dosham;
    })
    .filter((dosham) => dosham !== undefined);
  return matchingDoshamValues.join(", ");
};
export const getDoshamDetailsFromValue = (options, dosham_options) => {
  const matchingDoshamValues = dosham_options
    ?.map((d) => {
      const dosham = options.find((option) => option.value === d)?.en_label;

      return dosham;
    })
    .filter((dosham) => dosham !== undefined);
  return matchingDoshamValues.join(", ");
};

export const getAnnualIncome = (options, value) => {
  let annual_income = options?.find((x) => x.r_f_v === value)?.en_label;
  return annual_income;
};
export const getAnnualIncomeFromValue = (options, value) => {
  let annual_income = options?.find((x) => x.r_t_v === value)?.en_label;
  return annual_income;
};
export const getAnnualIncomeOptions = (user_detail) => {
  let filtered_options = AnnualIncomeOptions?.find(
    (x) =>
      user_detail?.annual_income_from === x.r_f_v &&
      user_detail?.annual_income_to === x.r_t_v
  );
  return filtered_options?.en_label;
};

import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  create_promo_code_franchises_mutation,
  update_promo_code_franchise_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { HStack, useBreakpointValue } from "native-base";
import "../../../../../src/index.css";

const AdminFranchisePromoCodeDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, id } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);
  const handleChange = (b, a) => {};
  const { loading: createLoading } = useDynamicSelector("create_promo_code");
  const { loading: updateLoading } = useDynamicSelector("update_promo_code");
  const handleSubmit = (values) => {
    if (initialValues.id) {
      let variables = {
        id: initialValues.id,
        data: values,
      };
      let keys = [{ key: "update_promo_code", loading: true }];
      dispatch(
        dynamicRequest(
          keys,
          update_promo_code_franchise_mutation,
          variables,
          "M"
        )
      );
    } else {
      let variables = {
        data: {
          name: values.name,
          franchise_id: id,
          discount_percentage: values.discount_percentage,
        },
      };
      let keys = [{ key: "create_promo_code", loading: true }];
      dispatch(
        dynamicRequest(
          keys,
          create_promo_code_franchises_mutation,
          variables,
          "M"
        )
      );
    }
  };
  const columns = useBreakpointValue({
    base: 1,
    md: 2,
  });
  return (
    <Form
      form={form}
      name={"admin_plans"}
      layout={"vertical"}
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.TextBox
        label={t("name")}
        field={"name"}
        rules={[
          {
            required: true,
            message: "name is required",
          },
        ]}
      />
      <VForm.Number
        label={t("discount_percentage")}
        field={"discount_percentage"}
        rules={[
          {
            required: true,
            message: "Discount Percentage is required",
          },
        ]}
      />
      <HStack space="3" justifyContent={"flex-end"}>
        <Button onClick={props.close}>{t("close")}</Button>
        <VForm.Button
          isLoading={initialValues.id ? updateLoading : createLoading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
      </HStack>
    </Form>
  );
};
export default AdminFranchisePromoCodeDetails;

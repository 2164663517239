import React from "react";
// import { useParams } from "react-router-dom";
import { Box, Center } from "native-base";
import OperatorList from "@views/components/manage_operator/operator_list";
// import Scrollbars from "react-custom-scrollbars-2";

const AdminFranchiseOperator = () => {
  // const { element_id } = useParams();
  return (
    <Center>
      <Box alignItems="center" width={"90%"}>
        <OperatorList />
      </Box>
    </Center>
  );
};
export default AdminFranchiseOperator;

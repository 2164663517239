import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Input, Button, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import {
  dynamicRequest,
  create_admin_subscription_plan_mutation,
  update_admin_subscription_plan_mutation,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { Box, HStack, Text, useBreakpointValue } from "native-base";
import "../../../index.css";

const AdminSubscriptionPlanDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, type } = props;
  console.log("initialValues", initialValues);
  const dispatch = useDispatch();
  const [isRecommended, setIsRecommended] = useState(false);
  const [is_active, set_is_active] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (type === "add") {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
      setIsRecommended(initialValues?.is_recommended);
      set_is_active(initialValues?.is_active);
    }
  }, [initialValues, type]);

  const onChangeRecommended = () => {
    setIsRecommended(!isRecommended);
  };
  const on_change_active = () => {
    set_is_active(!is_active);
  };
  const handleSubmit = (values) => {
    values.is_recommended = isRecommended;
    values.is_active = is_active;
    if (initialValues.id) {
      let variables = {
        id: initialValues.id,
        data: values,
      };
      let keys = [{ key: "update_subscription_plan", loading: true }];
      dispatch(
        dynamicRequest(
          keys,
          update_admin_subscription_plan_mutation,
          variables,
          "M"
        )
      );
    } else {
      let variables = {
        data: values,
      };
      let keys = [{ key: "create_subscription_plan", loading: true }];
      dispatch(
        dynamicRequest(
          keys,
          create_admin_subscription_plan_mutation,
          variables,
          "M"
        )
      );
    }
  };
  const columns = useBreakpointValue({
    base: 1,
    md: 2,
  });
  const handleChange = (b, a) => {};
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  return (
    <Form
      form={form}
      name={"admin_plans"}
      layout={"vertical"}
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.Grid columns={columns} spacingY={10} spacingX={10}>
        <VForm.TextBox
          label={t("label_in_english")}
          field={"en_label"}
          rules={[
            {
              required: true,
              message: "Label In English is required",
            },
          ]}
        />
        <VForm.TextBox label={t("label_in_tamil")} field={"ta_label"} />
        <VForm.Number
          label={t("no_of_days")}
          field={"period_in_days"}
          rules={[
            {
              required: true,
              message: "Number of Days is required",
            },
          ]}
        />

        <VForm.Number
          label={t("actual_price")}
          field={"actual_price"}
          rules={[
            {
              required: true,
              message: "Actual Price is required",
            },
          ]}
        />
        {/* <VForm.Number
          label={t("discount_price")}
          field={"discounted_price"}
          rules={[
            {
              required: true,
              message: "Discount Price is required",
            },
          ]}
        /> */}
        <VForm.Number
          label={t("allowed_purchase_count")}
          field={"allowed_purchase_count"}
          rules={[
            {
              required: true,
              message: "Allowed Purchase Count is required",
            },
          ]}
        />
        <VForm.Number
          label={t("allowed_contacts")}
          field={"allowed_contact_details_view"}
          rules={[
            {
              required: true,
              message: "Allowed Contacts is required",
            },
          ]}
        />
        <Box mt="3">
          <HStack alignItems={"center"}>
            <Checkbox
              style={{
                padding: 5,
              }}
              onChange={onChangeRecommended}
            />
            <Text>{t("is_recommended")}</Text>
          </HStack>
        </Box>
        <Box mt="3">
          <HStack alignItems={"center"}>
            <Checkbox
              style={{
                padding: 5,
              }}
              onChange={on_change_active}
              checked={is_active}
            />
            <Text>{t("is_active")}</Text>
          </HStack>
        </Box>
      </VForm.Grid>
      <Form.List name="description">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...formItemLayoutWithOutLabel}
                // label={index === 0 ? "Description" : ""}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Description is required",
                    },
                  ]}
                  noStyle
                >
                  <Input style={{ width: "60%" }} />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "150px", margin: "4" }}
                icon={<PlusOutlined />}
              >
                Add Description
              </Button>

              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <HStack space="3" justifyContent={"flex-end"}>
        <Button onClick={props.close}>{t("close")}</Button>
        <VForm.Button loading={false} disabled={false}>
          {t("Submit")}
        </VForm.Button>
      </HStack>
    </Form>
  );
};
export default AdminSubscriptionPlanDetails;

import { Box, Center, ScrollView, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import FooterBar from "../layouts/admin/footer_bar";
import HeaderBar from "../layouts/admin/header_bar";

const OperatorLayout = ({ children }) => {
  return (
    <Box flexDirection="row" flexGrow="1">
      <VStack width="100%" flex={1}>
        <HeaderBar color="white" projectName={"Standard Matrimony"} />
        <Box flex={1}>{children}</Box>
        {/* <FooterBar /> */}
      </VStack>
    </Box>
  );
};
export default OperatorLayout;

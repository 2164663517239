import React from "react";
import {
  Box,
  Text,
  HStack,
  Divider,
  Pressable,
  Skeleton,
  Image as _Image,
} from "native-base";
import { AiFillStar } from "react-icons/ai";
import {
  FaHeart,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaPrayingHands,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import MemberDetailCardAdmin from "@views/components/member/member_detail_card";
import { ImLocation } from "react-icons/im";
import { BsFillFlagFill } from "react-icons/bs";
import {
  getCity,
  getCountry,
  getDistrict,
  getNationality,
  getState,
} from "@helpers/get_country_state_city";
import _ from "lodash";
import { NationalityOptions } from "@master_data/profile_json/nationality";

const LocationDetails = (props) => {
  const { member } = props;

  const { t } = useTranslation();

  return (
    <Box
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 8,
        boxShadow: "0 0 10px #dcdcdc",
        elevation: 5,
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        borderBottomWidth: 3,
        borderBottomColor: "#712e5a",
        // marginVertical: 10,
        marginHorizontal: 5,
        paddingHorizontal: 5,
      }}
    >
      <HStack alignItems={"center"}>
        <Box
          style={{
            paddingHorizontal: 5,
          }}
        >
          <FaMapMarkedAlt size={30} color={"#712e5a"} />
        </Box>
        <Box>
          <Text fontSize={25} fontWeight={"bold"}>
            {t("location_details")}
          </Text>
        </Box>
      </HStack>
      <Divider my={2} />
      <MemberDetailCardAdmin
        element={t("country")}
        icon={<ImLocation size={18} color={"#ab6c95"} />}
        text={getCountry(_.snakeCase(member?.member_profile?.country))}
      />
      <MemberDetailCardAdmin
        element={t("state")}
        icon={<ImLocation size={18} color={"#ab6c95"} />}
        text={getState(
          _.snakeCase(member?.member_profile?.state),
          _.snakeCase(member?.member_profile?.country)
        )}
      />
      <MemberDetailCardAdmin
        element={t("district")}
        icon={<ImLocation size={18} color={"#ab6c95"} />}
        text={getDistrict(_.snakeCase(member?.member_profile?.district))}
      />

      <MemberDetailCardAdmin
        element={t("ancestor_origin")}
        icon={<ImLocation size={18} color={"#ab6c95"} />}
        text={getDistrict(_.snakeCase(member?.member_profile?.ancestor_origin))}
      />
      <MemberDetailCardAdmin
        element={t("citizenship")}
        icon={<BsFillFlagFill size={18} color={"#ab6c95"} />}
        text={getNationality(
          NationalityOptions,
          member?.member_profile?.citizenship
        )}
      />
    </Box>
  );
};
export default LocationDetails;

import { gql } from "@apollo/client";

export const get_all_version_query = gql`
  query {
    getVersionLogs {
      id
      version
      created_at
      is_mandatory
      current_changes
      error {
        status_code
        message
      }
    }
  }
`;
export const get_all_versions_query = gql`
  query {
    getAllVersionLog {
      items {
        id
        version
        created_at
        is_mandatory
        current_changes
      }
      pagination {
        page_number
        page_limit
        total_count
      }

      error {
        status_code
        message
      }
    }
  }
`;

export const create_new_version = gql`
  mutation createVersion($json: version_input) {
    createVersion(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

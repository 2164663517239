import UserRequestGroup from "@views/components/user_requests/user_request_group";
import UserRequestThread from "@views/components/user_requests/user_request_thread";
import { Box, Hidden, HStack, Pressable, Text } from "native-base";
import { Drawer } from "antd";
import "../../index.css";

import ScrollView from "@views/components/ui/scroll_view/scroll_view";

import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  get_request_groups_query,
  get_user_requests,
  useDynamicSelector,
} from "@services/redux";
import { useLocation, useParams } from "react-router-dom";
import UnderVerification from "@views/components/common/under_verification";
import { retrieveItem } from "@helpers/storage";
import { ROUTES } from "@views/routes/my_routes";
import { navigate } from "@helpers/navigator";
const UserRequests = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { request_id } = useParams();
  const is_approved = retrieveItem("is_approved");
  const is_preference_added = retrieveItem("is_preference_added");
  const is_profile_added = retrieveItem("is_profile_added");

  const [requested_groups, setRequestedGroups] = useState([]);
  const [user_requests, setUserRequests] = useState([]);
  const [request_group_id, setRequestGroupId] = useState();

  const { items: user_requests_list } = useDynamicSelector("user_requests");
  const { items: request_groups_list } = useDynamicSelector("request_groups");

  const getUserRequests = (request_id) => {
    let get_user_requests_key = [{ key: "user_requests", loading: true }];
    let get_user_requests_query = get_user_requests;
    let get_user_requests_variables = { group_id: request_id };
    dispatch(
      dynamicRequest(
        get_user_requests_key,
        get_user_requests_query,
        get_user_requests_variables
      )
    );
  };

  useEffect(() => {
    if (request_id) {
      getUserRequests(request_id);
    }
  }, [request_id]);

  useEffect(() => {
    let get_requests_key = [{ key: "request_groups", loading: true }];
    let get_requests_query = get_request_groups_query;
    let get_requests_variables = null;
    dispatch(
      dynamicRequest(
        get_requests_key,
        get_requests_query,
        get_requests_variables
      )
    );
  }, []);

  useEffect(() => {
    if (request_groups_list) {
      setRequestedGroups(request_groups_list);
    }
  }, [request_groups_list]);

  useEffect(() => {
    if (user_requests_list) {
      setUserRequests(user_requests_list);
    }
  }, [user_requests_list]);

  if (!is_profile_added) {
    navigate(ROUTES.USER_MANAGE_PROFILE);
  } else if (!is_preference_added) {
    navigate(ROUTES.USER_MANAGE_PREFERENCE);
  } else if (is_approved === null) {
    return <UnderVerification />;
  }

  return (
    <Box flex={1}>
      <HStack>
        <Box
          height={"100vh"}
          width={{
            base: 0,
            // xs: 200,
            // sm: 200,
            md: 250,
            lg: 300,
            xl: 300,
            "2xl": 300,
            "3xl": 300,
          }}
        >
          <UserRequestGroup
            setRequestGroupId={setRequestGroupId}
            getUserRequests={getUserRequests}
          />
        </Box>

        <ScrollView>
          <Box height={"100vh"} backgroundColor={"#fef5fb"}>
            <UserRequestThread
              request_group_id={request_group_id}
              getUserRequests={getUserRequests}
              user_requests={user_requests}
            />
          </Box>
          <Box backgroundColor={"#fef5fb"} height={40} />
        </ScrollView>
      </HStack>
    </Box>
  );
};
export default UserRequests;

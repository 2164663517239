export const AgeToOptions = [
  {
    value: 18,
    p_o: [],
    g_t_e: null,
    l_t_e: 18,
    en_label: "18",
    ta_label: null,
  },
  {
    value: 19,
    p_o: [],
    g_t_e: null,
    l_t_e: 19,
    en_label: "19",
    ta_label: null,
  },
  {
    value: 20,
    p_o: [],
    g_t_e: null,
    l_t_e: 20,
    en_label: "20",
    ta_label: null,
  },
  {
    value: 21,
    p_o: [],
    g_t_e: null,
    l_t_e: 21,
    en_label: "21",
    ta_label: null,
  },
  {
    value: 22,
    p_o: [],
    g_t_e: null,
    l_t_e: 22,
    en_label: "22",
    ta_label: null,
  },
  {
    value: 23,
    p_o: [],
    g_t_e: null,
    l_t_e: 23,
    en_label: "23",
    ta_label: null,
  },
  {
    value: 24,
    p_o: [],
    g_t_e: null,
    l_t_e: 24,
    en_label: "24",
    ta_label: null,
  },
  {
    value: 25,
    p_o: [],
    g_t_e: null,
    l_t_e: 25,
    en_label: "25",
    ta_label: null,
  },
  {
    value: 26,
    p_o: [],
    g_t_e: null,
    l_t_e: 26,
    en_label: "26",
    ta_label: null,
  },
  {
    value: 27,
    p_o: [],
    g_t_e: null,
    l_t_e: 27,
    en_label: "27",
    ta_label: null,
  },
  {
    value: 28,
    p_o: [],
    g_t_e: null,
    l_t_e: 28,
    en_label: "28",
    ta_label: null,
  },
  {
    value: 29,
    p_o: [],
    g_t_e: null,
    l_t_e: 29,
    en_label: "29",
    ta_label: null,
  },
  {
    value: 30,
    p_o: [],
    g_t_e: null,
    l_t_e: 30,
    en_label: "30",
    ta_label: null,
  },
  {
    value: 31,
    p_o: [],
    g_t_e: null,
    l_t_e: 31,
    en_label: "31",
    ta_label: null,
  },
  {
    value: 32,
    p_o: [],
    g_t_e: null,
    l_t_e: 32,
    en_label: "32",
    ta_label: null,
  },
  {
    value: 33,
    p_o: [],
    g_t_e: null,
    l_t_e: 33,
    en_label: "33",
    ta_label: null,
  },
  {
    value: 34,
    p_o: [],
    g_t_e: null,
    l_t_e: 34,
    en_label: "34",
    ta_label: null,
  },
  {
    value: 35,
    p_o: [],
    g_t_e: null,
    l_t_e: 35,
    en_label: "35",
    ta_label: null,
  },
  {
    value: 36,
    p_o: [],
    g_t_e: null,
    l_t_e: 36,
    en_label: "36",
    ta_label: null,
  },
  {
    value: 37,
    p_o: [],
    g_t_e: null,
    l_t_e: 37,
    en_label: "37",
    ta_label: null,
  },
  {
    value: 38,
    p_o: [],
    g_t_e: null,
    l_t_e: 38,
    en_label: "38",
    ta_label: null,
  },
  {
    value: 39,
    p_o: [],
    g_t_e: null,
    l_t_e: 39,
    en_label: "39",
    ta_label: null,
  },
  {
    value: 40,
    p_o: [],
    g_t_e: null,
    l_t_e: 40,
    en_label: "40",
    ta_label: null,
  },
  {
    value: 41,
    p_o: [],
    g_t_e: null,
    l_t_e: 41,
    en_label: "41",
    ta_label: null,
  },
  {
    value: 42,
    p_o: [],
    g_t_e: null,
    l_t_e: 42,
    en_label: "42",
    ta_label: null,
  },
  {
    value: 43,
    p_o: [],
    g_t_e: null,
    l_t_e: 43,
    en_label: "43",
    ta_label: null,
  },
  {
    value: 44,
    p_o: [],
    g_t_e: null,
    l_t_e: 44,
    en_label: "44",
    ta_label: null,
  },
  {
    value: 45,
    p_o: [],
    g_t_e: null,
    l_t_e: 45,
    en_label: "45",
    ta_label: null,
  },
  {
    value: 46,
    p_o: [],
    g_t_e: null,
    l_t_e: 46,
    en_label: "46",
    ta_label: null,
  },
  {
    value: 47,
    p_o: [],
    g_t_e: null,
    l_t_e: 47,
    en_label: "47",
    ta_label: null,
  },
  {
    value: 48,
    p_o: [],
    g_t_e: null,
    l_t_e: 48,
    en_label: "48",
    ta_label: null,
  },
  {
    value: 49,
    p_o: [],
    g_t_e: null,
    l_t_e: 49,
    en_label: "49",
    ta_label: null,
  },
  {
    value: 50,
    p_o: [],
    g_t_e: null,
    l_t_e: 50,
    en_label: "50",
    ta_label: null,
  },
  {
    value: 51,
    p_o: [],
    g_t_e: null,
    l_t_e: 51,
    en_label: "51",
    ta_label: null,
  },
  {
    value: 52,
    p_o: [],
    g_t_e: null,
    l_t_e: 52,
    en_label: "52",
    ta_label: null,
  },
  {
    value: 53,
    p_o: [],
    g_t_e: null,
    l_t_e: 53,
    en_label: "53",
    ta_label: null,
  },
  {
    value: 54,
    p_o: [],
    g_t_e: null,
    l_t_e: 54,
    en_label: "54",
    ta_label: null,
  },
  {
    value: 55,
    p_o: [],
    g_t_e: null,
    l_t_e: 55,
    en_label: "55",
    ta_label: null,
  },
  {
    value: 56,
    p_o: [],
    g_t_e: null,
    l_t_e: 56,
    en_label: "56",
    ta_label: null,
  },
  {
    value: 57,
    p_o: [],
    g_t_e: null,
    l_t_e: 57,
    en_label: "57",
    ta_label: null,
  },
  {
    value: 58,
    p_o: [],
    g_t_e: null,
    l_t_e: 58,
    en_label: "58",
    ta_label: null,
  },
  {
    value: 59,
    p_o: [],
    g_t_e: null,
    l_t_e: 59,
    en_label: "59",
    ta_label: null,
  },
  {
    value: 60,
    p_o: [],
    g_t_e: null,
    l_t_e: 60,
    en_label: "60",
    ta_label: null,
  },
  {
    value: 999999999,
    p_o: [],
    g_t_e: null,
    l_t_e: -1,
    en_label: "Any",
    ta_label: "ஏதேனும்",
  },
];

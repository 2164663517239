import { gql } from "@apollo/client";

export const send_request_mutation = gql`
  mutation sendRequest($member_id: String!, $type: String!) {
    send_request: sendRequest(member_id: $member_id, type: $type) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const send_request_to_add_photo = gql`
  mutation sendRequest($member_id: String!, $type: String!) {
    send_request_add_photo: sendRequest(member_id: $member_id, type: $type) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const accept_request_mutation = gql`
  mutation acceptRequest($request_id: String!) {
    accept_request: acceptRequest(request_id: $request_id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const reject_request_mutation = gql`
  mutation rejectRequest($request_id: String!) {
    reject_request: rejectRequest(request_id: $request_id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_request_groups_query = gql`
  query getRequestGroups {
    request_groups: getRequestGroups {
      items {
        has_sent
        has_received
        id
        member {
          id
          member_profile {
            id
            dob
            name
            gender
          }
          member_cover_photo {
            photo_url
            photo_view
            cropped_photo_url
          }
        }
        last_user_request {
          type
          updated_datetime
        }
      }
      error {
        message
      }
    }
  }
`;

export const get_user_requests = gql`
  query getUserRequests($group_id: String!) {
    user_requests: getUserRequests(group_id: $group_id) {
      items {
        id
        user_id
        member_id
        type
        requested_datetime
        reminder_datetime
        accepted_datetime
        rejected_datetime
        updated_datetime
        user {
          id
          member_profile {
            name
          }
        }
        member {
          id
          member_profile {
            name
          }
        }
      }
      error {
        message
      }
    }
  }
`;

import {
  Box,
  Center,
  HStack,
  IconButton,
  Pressable,
  ScrollView,
  Text,
} from "native-base";
import React, { useEffect, useRef, useState } from "react";
import UserMemberDetail from "@views/pages/user_member_detail";
import {
  dynamicRequest,
  dynamicSet,
  getMatches,
  getOperatorMatches,
  useDynamicSelector,
} from "@services/redux";
import MemberCardOperatorView from "../member/member_card_view_operator";
import Scrollbars from "react-custom-scrollbars-2";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { operator_matches_page_count } from "@helpers/constants";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import MemberCardOperatorViewTamil from "../member/member_card_view_operator_tamil";

const OperatorMatchesContent = () => {
  const dispatch = useDispatch();

  const [render_screen, setRenderScreen] = useState(false);
  const [key_value, setKeyValue] = useState(false);

  const secondary_filter_variables = useDynamicSelector(
    "secondary_filter_variables"
  );
  const initial_filter_variables = useDynamicSelector(
    "initial_filter_variables"
  );
  const {
    items: matches_list,
    pagination: all_matches_pagination,
    loading: matches_loading,
    error,
  } = useDynamicSelector("operator_matches");

  const getNextMatch = (page_number) => {
    let get_my_matches_key = [{ key: "operator_matches", loading: true }];
    let get_my_matches_query = getOperatorMatches;
    let get_my_matches_variables = {
      filter:
        Object.keys(initial_filter_variables).length > 0
          ? initial_filter_variables
          : secondary_filter_variables,
      page_number: page_number,
      page_limit: operator_matches_page_count,
    };
    dispatch(
      dynamicRequest(
        get_my_matches_key,
        get_my_matches_query,
        get_my_matches_variables
      )
    );
  };

  useEffect(() => {
    setRenderScreen(true);
  }, [initial_filter_variables, secondary_filter_variables, matches_list]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [all_matches_pagination]);
  const handleKeyPress = (event) => {
    if (
      event.key === "ArrowLeft" &&
      all_matches_pagination?.total_count > 0 &&
      all_matches_pagination?.page_number > 1
    ) {
      getNextMatch(
        all_matches_pagination?.page_number
          ? all_matches_pagination?.page_number - 1
          : 1
      );
    } else if (
      event.key === "ArrowRight" &&
      all_matches_pagination?.total_count > 0 &&
      all_matches_pagination?.page_number !==
        all_matches_pagination?.total_count
    ) {
      getNextMatch(
        all_matches_pagination?.page_number
          ? all_matches_pagination?.page_number + 1
          : 1
      );
    }
  };

  useEffect(() => {
    dispatch(dynamicSet("open_drawer", { is_drawer_open: true }));
  }, []);
  return (
    <Box width={"100%"}>
      {!matches_loading && (
        <Box my={5} alignItems={"center"}>
          <Text fontSize={20} bold>
            {all_matches_pagination?.total_count > 0
              ? `Profile (${all_matches_pagination?.page_number}/${all_matches_pagination?.total_count})`
              : "No matches"}
          </Text>
        </Box>
      )}

      <HStack alignItems={"center"}>
        <Box alignItems={"center"} width={"150px"}>
          {all_matches_pagination?.total_count > 0 &&
            all_matches_pagination?.page_number > 1 && (
              <Pressable>
                <IconButton
                  variant="unstyled"
                  icon={<AiOutlineLeft size={50} color={"primary.900"} />}
                  onPress={() => {
                    getNextMatch(
                      all_matches_pagination?.page_number
                        ? all_matches_pagination?.page_number - 1
                        : 1
                    );
                  }}
                />
              </Pressable>
            )}
        </Box>
        <div
          style={{
            flex: 1,
            backgroundColor: "#fff",
            height: "90vh",
            overflowY: "auto",
          }}
          // flex={1}
          // backgroundColor={"#fff"}
          // h={"90vh"}
          // overflowY={"auto"}
          className="hide-scrollbar"
        >
          {/* <MemberCardOperatorView
            matches_list={matches_list}
            matches_loading={matches_loading}
          /> */}
          <MemberCardOperatorViewTamil
            matches_list={matches_list}
            matches_loading={matches_loading}
          />
        </div>

        <Box alignItems={"center"} width={"150px"}>
          {/* {all_matches_pagination?.page_number <
            all_matches_pagination?.total_count && ( */}
          {all_matches_pagination?.total_count > 0 &&
            all_matches_pagination?.page_number !==
              all_matches_pagination?.total_count && (
              <Pressable>
                <IconButton
                  variant="unstyled"
                  icon={<AiOutlineRight size={50} color={"primary.900"} />}
                  onPress={() => {
                    if (
                      all_matches_pagination?.page_number <
                      all_matches_pagination?.total_count
                    ) {
                      getNextMatch(
                        all_matches_pagination?.page_number
                          ? all_matches_pagination?.page_number + 1
                          : 1
                      );
                    } else {
                      getNextMatch(1);
                    }
                  }}
                />
              </Pressable>
            )}
          {/* )} */}
        </Box>
      </HStack>
    </Box>
  );
};

export default OperatorMatchesContent;

import SuperAdminLayout from "@views/layouts/super_admin/super_admin_layout";
import UserLayout from "@views/layouts/user_layout";
import ForgotPassword from "@views/pages/forgot_password";
import Login from "@views/pages/login";
import Register from "@views/pages/register";
import Redirector from "@views/pages/redirector";
import UserHome from "@views/pages/user_home";
import UserManagePreference from "@views/pages/user_manage_preference";
import UserManageProfile from "@views/pages/user_manage_profile";
import UserMatches from "@views/pages/user_matches";
import UserMemberDetail from "@views/pages/user_member_detail";
import UserSubscription from "@views/pages/user_subscription";
import UserRequests from "@views/pages/user_requests";
import UserSearch from "@views/pages/user_search";
import UserSettings from "@views/pages/user_settings";

import AdminElements from "@views/pages/admin_elements";
import Policy from "@views/pages/privacy_policy";
import UserPrivacySettings from "@views/pages/user_privacy_settings";
import AdminSubscriptionPlan from "@views/pages/admin_subscription_plan";
import AdminLayout from "@views/layouts/admin/admin_layout";
import AdminVerificationList from "@views/components/admin_verification/admin_verification_list";
import AdminFranchisePlanList from "@views/components/admin_franchise/admin_franchise_list";
import AdminPromoCode from "@views/pages/admin_promo_code";
import AdminLoginPage from "@views/pages/admin_login";
import SuperAdminUserCreate from "@views/components/super_admin_user_create/super_admin_user_create_details";
import OperatorHome from "@views/pages/operator_home";
import OperatorLayout from "@views/layouts/operator_layout";
import UserBlockedMembers from "@views/pages/user_blocked_members";
import UserIgnoredMembers from "@views/pages/user_ignored_members";
import UserChangePassword from "@views/pages/user_change_password";
import OperatorMatches from "@views/pages/operator_matches";
import UserSubscriptionByOperator from "@views/pages/user_subscription_by_operator";
import PaymentConfirmationScreen from "@views/pages/confirmation_payment";
import OperatorList from "@views/components/manage_operator/operator_list";
import AdminFranchiseOperator from "@views/pages/admin_franchise_operators";
import AdminCropPhotoVerification from "@views/components/admin_verification/admin_crop_photo_verification";
import AdminManageReports from "@views/components/admin_verification/admin_franchise_user";
import AdminManageFranchiseUser from "@views/components/admin_verification/admin_franchise_user";
import AdminNonPremiumMembers from "@views/components/admin_reports/admin_non_premium_members";
import AdminDeletedUsers from "@views/components/admin_reports/admin_deleted_user";
import AdminPaymentTriedMembers from "@views/components/admin_reports/admin_payment_tried_members";
import AdminTwoMonthsNotUsedProfiles from "@views/components/admin_reports/admin_two_month_not_used_users";
import InvoicePage from "@views/pages/invoice_page";
import VerifierList from "@views/components/verifier/verifier_list";
import AdminManagePromoCodeUser from "@views/components/admin_verification/admin_promo_code_user";
import Terms from "@views/pages/terms_and_condition";
import AccessRights from "@views/pages/access_rights";
import Logout from "@views/pages/logout";
import AboutUs from "@views/pages/about_us";
import ContactUs from "@views/pages/contact_us";
import ManageMembersList from "@views/components/manage_members/manage_members_list";
import PaymentComplete from "@views/pages/payment_complete";
import UserDetailsList from "@views/pages/user_details_verification";
import UserDetails from "@views/pages/user_details";
import Franchise from "@views/pages/franchise";
import AdImage from "@views/pages/ad_image";
import OperatorUserPrivacySettings from "@views/pages/operator_user_privacy_settings";
import ManageAppVersions from "@views/pages/manage_app_versions";
import TeleCallerList from "@views/components/tele_caller/telecaller_list";
import NewReportList from "@views/components/send_received_report/send_received_request";
import AdminInvoicePage from "@views/pages/admin_invoice_report";
import PaymentReport from "@views/pages/payment_report";
import UserDetailsForAdmin from "../pages/user_details_for_admin";
import TelecallerHome from "@views/pages/telecaller_home";
import InterestCountReport from "@views/pages/interest_count_report";
import UserDetailsForTeleCaller from "@views/pages/user_details_for_tele_caller";
import AdminRejectedUsers from "@views/components/admin_reports/admin_rejected_user";
import UserPhotosPrivacyUpdate from "@views/pages/user_photos_privacy_update";

export const ROUTES = {
  DEVELOPMENTMATCHES: "/development-matches",
  DEVELOPMENT: "/development",
  REDIRECTOR: "/",
  LOGOUT: "/logout",
  ACCESS_RIGHTS: "/access-rights",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  USER_HOME: "/home",
  USER_MANAGE_PROFILE: "/manage-profile",
  USER_MANAGE_PREFERENCE: "/manage-preference",
  USER_MATCHES: "/matches",
  USER_MEMBER_DETAIL: "/member/:member_id",
  USER_SUBSCRIPTION: "/subscription",
  USER_SUBSCRIPTION_BY_OPERATOR: "/user_subscription",
  USER_REQUESTS: "/requests",
  USER_REQUESTS_PARAM: "/requests/:request_id",
  USER_SEARCH: "/search",
  USER_SETTINGS: "/settings",
  POLICY: "/privacy-policy",
  TERMS: "/terms",
  ABOUT_US: "/about-us",
  CONTACT_US: "/contact-us",
  INVOICE: "/invoice",
  USER_PRIVACY_SETTINGS: "/privacy-settings",
  USER_BLOCKED_MEMBERS: "/blocked-members",
  USER_IGNORED_MEMBERS: "/ignored-members",
  USER_CHANGE_PASSWORD: "/change-password",

  ELEMENTS: "/admin/elements",
  ELEMENT: "/admin/element/:element_id",
  ADMIN_VERIFICATIONS: "/admin/admin-verification",
  ADMIN_FRANCHISE_USER: "/admin/admin-franchise-user/:franchise_id",
  ADMIN_PROMO_USER: "/admin/promo-code-user/:promo_code_id",
  ADMIN_CROP_PHOTO_VERIFICATION: "/admin/admin-photo-verification",
  ADMIN_USER_DETAILS_VERIFICATION: "/admin/user-details-verification",
  USER_DETAILS: "/user-details/:user_id",
  USER_DETAILS_FOR_ADMIN: "/member-details-view/:user_id",
  USER_DETAILS_FOR_TELE_CALLER: "/member-details/:user_id",
  USER_PHOTOS_PRIVACY_UPDATE: "/member-details-update",
  USERS_INTEREST_COUNT: "/admin/users-interest-count",
  OPERATOR_LIST: "/admin/admin-operators/:franchise_id",
  ADMIN_SUBSCRIPTION_PLAN: "/admin/admin-subscription",
  ADMIN_FRANCHISE: "/admin/admin-franchise",
  ADMIN_PROMO_CODE: "/admin/admin-promo-code",
  ADMIN_LOGIN: "/admin/admin-login",
  CONFIRM_PAYMENT: "/confirm-payment/:orderId",
  // CONFIRM_PAYMENT: "/pinelabs_complete_payment/:orderId",
  SUPER_ADMIN_USER_CREATE: "/admin/user-create",
  OPERATOR_HOME: "/operator/home",
  OPERATOR_MATCHES: "/operator/matches",
  OPERATOR_USER_PRIVACY_SETTINGS: "/operator/privacy-settings",
  NO_PROFILE_UPDATE: "/admin/no-profile-photo-update",
  PAYMENT_TRIED_MEMBERS: "/admin/payment-tried-members",
  NOT_USED_MEMBERS: "/admin/idle-members",
  DELETED_USERS: "/admin/deleted-members",
  REJECTED_USERS: "/admin/rejected-members",
  VERIFIER: "/admin/admin-verifier",
  TELECALLER: "/admin/tele-caller",
  TELE_CALLER_HOME: "/admin/tele-caller-home",
  MANAGE_MEMBERS: "/admin/manage-members",
  PAYMENT_COMPLETE: "/payment-complete",
  FRANCHISE_MEMBERS: "/franchise-members",
  AD_IMAGE: "/admin/ad-image",
  MANAGE_APP_VERSIONS: "/admin/app-versions",
  PAYMENT_REPORT: "/payment-report",
  SEND_RECEIVED: "/send-received-request-report",
  INVOICE_REPORT: "/invoice-report",
};

const myRoutes = [
  {
    path: ROUTES.REDIRECTOR,
    exact: true,
    key: "redirector",
    component: Redirector,
    authenticate: false,
  },
  {
    path: ROUTES.LOGOUT,
    key: "logout",
    component: Logout,
    authenticate: false,
  },

  {
    path: `${ROUTES.REGISTER}`,
    key: "register",
    component: Register,
    authenticate: false,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    key: "forgot_password",
    component: ForgotPassword,
    authenticate: false,
  },
  {
    path: ROUTES.LOGIN,
    key: "user_login",
    authenticate: false,
    component: Login,
  },
  {
    path: ROUTES.ACCESS_RIGHTS,
    key: "Access Rights",
    authenticate: false,
    component: AccessRights,
  },
  {
    path: ROUTES.MANAGE_APP_VERSIONS,
    key: "Versions",
    component: ManageAppVersions,
    authenticate: false,
  },
  {
    key: "Franchise",
    component: AdminLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.FRANCHISE_MEMBERS,
        key: "Franchise",
        component: AdminManageFranchiseUser,
        authenticate: true,
      },
    ],
  },

  {
    key: "Verification",
    component: AdminLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.ADMIN_CROP_PHOTO_VERIFICATION,
        key: "Verification",
        component: AdminCropPhotoVerification,
        authenticate: true,
      },
      {
        path: ROUTES.ADMIN_USER_DETAILS_VERIFICATION,
        key: "Verification",
        component: UserDetailsList,
        authenticate: true,
      },
      {
        path: ROUTES.USER_DETAILS,
        key: "Verification",
        component: UserDetails,
        authenticate: true,
      },
    ],
  },
  {
    key: "Telecaller Home",
    component: AdminLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.TELE_CALLER_HOME,
        key: "Telecaller Home",
        component: TelecallerHome,
        authenticate: true,
      },
      {
        path: ROUTES.USER_DETAILS_FOR_TELE_CALLER,
        key: "Telecaller Home",
        component: UserDetailsForTeleCaller,
        authenticate: true,
      },
      {
        path: ROUTES.USER_PHOTOS_PRIVACY_UPDATE,
        key: "Telecaller Home",
        component: UserPhotosPrivacyUpdate,
        authenticate: true,
      },
    ],
  },
  {
    key: "User Home",
    component: UserLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.USER_HOME,
        exact: true,
        key: "User Home",
        authenticate: true,
        component: UserHome,
      },
      {
        path: ROUTES.USER_MANAGE_PREFERENCE,
        key: "My Account",
        exact: true,
        authenticate: true,
        component: UserManagePreference,
      },

      {
        path: ROUTES.USER_MANAGE_PROFILE,
        key: "My Account",
        exact: true,
        authenticate: true,
        component: UserManageProfile,
      },
      {
        path: ROUTES.USER_MATCHES,
        key: "User Matches",
        exact: true,
        authenticate: true,
        component: UserMatches,
      },
      {
        path: ROUTES.USER_MEMBER_DETAIL,
        key: "Member Profile",
        exact: true,
        authenticate: true,
        component: UserMemberDetail,
      },
      {
        path: ROUTES.USER_REQUESTS,
        key: "User Request",
        authenticate: true,
        exact: true,
        component: UserRequests,
      },
      {
        path: ROUTES.USER_REQUESTS_PARAM,
        key: "User Request",
        authenticate: true,
        exact: true,
        component: UserRequests,
      },
      {
        path: ROUTES.USER_SEARCH,
        key: "Member Profile",
        exact: true,
        authenticate: true,
        component: UserSearch,
      },
      {
        path: ROUTES.USER_SUBSCRIPTION,
        key: "My Account",
        exact: true,
        authenticate: true,
        component: UserSubscription,
      },
      {
        path: ROUTES.USER_SETTINGS,
        key: "My Account",
        exact: true,
        authenticate: true,
        component: UserSettings,
      },
      {
        path: ROUTES.USER_PRIVACY_SETTINGS,
        key: "My Account",
        exact: true,
        authenticate: true,
        component: UserPrivacySettings,
      },
      {
        path: ROUTES.USER_BLOCKED_MEMBERS,
        key: "My Account",
        exact: true,
        authenticate: true,
        component: UserBlockedMembers,
      },
      {
        path: ROUTES.USER_IGNORED_MEMBERS,
        key: "My Account",
        exact: true,
        authenticate: true,
        component: UserIgnoredMembers,
      },
      {
        path: ROUTES.USER_CHANGE_PASSWORD,
        key: "My Account",
        exact: true,
        authenticate: true,
        component: UserChangePassword,
      },
    ],
  },

  {
    key: "Super Admin",
    component: SuperAdminLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.ELEMENTS,
        key: "Super Admin",
        exact: true,
        authenticate: true,
        component: AdminElements,
      },
      {
        path: ROUTES.ELEMENT,
        exact: true,
        key: "Super Admin",
        component: AdminElements,
        authenticate: true,
      },
    ],
  },

  {
    key: "Admin",
    component: AdminLayout,
    authenticate: true,
    children: [
      // {
      //   path: ROUTES.ADMIN_VERIFICATIONS,
      //   key: "admin_verification",
      //   component: AdminVerificationList,
      //   authenticate: true,
      // },
      {
        path: ROUTES.ADMIN_FRANCHISE_USER,
        key: "Admin",
        component: AdminManageFranchiseUser,
        authenticate: true,
      },
      {
        path: ROUTES.ADMIN_PROMO_USER,
        key: "Admin",
        component: AdminManagePromoCodeUser,
        authenticate: true,
      },

      {
        path: ROUTES.OPERATOR_LIST,
        key: "Admin",
        component: AdminFranchiseOperator,
        authenticate: true,
      },
      {
        path: ROUTES.ADMIN_SUBSCRIPTION_PLAN,
        key: "Admin",
        component: AdminSubscriptionPlan,
        authenticate: true,
      },
      {
        path: ROUTES.ADMIN_FRANCHISE,
        key: "Admin",
        component: AdminFranchisePlanList,
        authenticate: true,
      },
      {
        path: `${ROUTES.ADMIN_PROMO_CODE}/:id?`,
        key: "Admin",
        component: AdminPromoCode,
        authenticate: true,
      },
      {
        path: ROUTES.NOT_USED_MEMBERS,
        key: "Admin",
        component: AdminTwoMonthsNotUsedProfiles,
        authenticate: true,
      },
      {
        path: ROUTES.NO_PROFILE_UPDATE,
        key: "Admin",
        component: AdminNonPremiumMembers,
        authenticate: true,
      },
      {
        path: ROUTES.PAYMENT_TRIED_MEMBERS,
        key: "Admin",
        component: AdminPaymentTriedMembers,
        authenticate: true,
      },
      {
        path: ROUTES.DELETED_USERS,
        key: "Admin",
        component: AdminDeletedUsers,
        authenticate: true,
      },
      {
        path: ROUTES.REJECTED_USERS,
        key: "Admin",
        component: AdminRejectedUsers,
        authenticate: true,
      },
      {
        path: ROUTES.SEND_RECEIVED,
        key: "Admin",
        component: NewReportList,
        authenticate: true,
      },
      {
        path: ROUTES.INVOICE_REPORT,
        key: "Admin",
        component: AdminInvoicePage,
        authenticate: true,
      },
      {
        path: ROUTES.VERIFIER,
        key: "Admin",
        component: VerifierList,
        authenticate: true,
      },
      {
        path: ROUTES.TELECALLER,
        key: "Admin",
        component: TeleCallerList,
        authenticate: true,
      },
      {
        path: ROUTES.MANAGE_MEMBERS,
        key: "Admin",
        component: ManageMembersList,
        authenticate: true,
      },
      {
        path: ROUTES.AD_IMAGE,
        key: "Admin",
        component: AdImage,
        authenticate: true,
      },
      {
        path: ROUTES.PAYMENT_REPORT,
        key: "Admin",
        component: PaymentReport,
        authenticate: true,
      },
      {
        path: ROUTES.USER_DETAILS_FOR_ADMIN,
        key: "Admin",
        component: UserDetailsForAdmin,
        authenticate: true,
      },
      {
        path: ROUTES.USERS_INTEREST_COUNT,
        key: "Admin",
        component: InterestCountReport,
        authenticate: true,
      },
    ],
  },
  {
    key: "Operator Home",
    component: OperatorLayout,
    authenticate: true,
    children: [
      {
        path: ROUTES.OPERATOR_HOME,
        key: "Operator Home",
        component: OperatorHome,
        authenticate: true,
      },
      {
        path: ROUTES.USER_SUBSCRIPTION_BY_OPERATOR,
        key: "Operator Member Subscription",
        exact: true,
        authenticate: true,
        component: UserSubscriptionByOperator,
      },
    ],
  },
  {
    path: ROUTES.OPERATOR_MATCHES,
    key: "Operator Member Matches",
    component: OperatorMatches,
    authenticate: true,
  },
  {
    path: ROUTES.OPERATOR_USER_PRIVACY_SETTINGS,
    key: "Operator User Privacy Settings",
    exact: true,
    authenticate: true,
    component: OperatorUserPrivacySettings,
  },

  {
    path: ROUTES.POLICY,
    exact: true,
    key: "policy",
    component: Policy,
    authenticate: false,
  },
  {
    path: ROUTES.TERMS,
    exact: true,
    key: "terms",
    component: Terms,
    authenticate: false,
  },
  {
    path: ROUTES.ABOUT_US,
    exact: true,
    key: "about_us",
    component: AboutUs,
    authenticate: false,
  },
  {
    path: ROUTES.CONTACT_US,
    exact: true,
    key: "contact_us",
    component: ContactUs,
    authenticate: false,
  },
  {
    path: ROUTES.INVOICE,
    key: "invoice",
    component: InvoicePage,
    authenticate: false,
  },
  {
    path: ROUTES.ADMIN_LOGIN,
    key: "admin_login",
    authenticate: false,
    component: AdminLoginPage,
  },
  // {
  //   path: ROUTES.SUPER_ADMIN_USER_CREATE,
  //   exact: true,
  //   key: "super_admin_user_create",
  //   component: SuperAdminUserCreate,
  //   authenticate: false,
  //   layout: AdminLayout,
  // },
  {
    path: ROUTES.CONFIRM_PAYMENT,
    exact: true,
    key: "confirm_payment",
    // component: PaymentConfirmationScreen,
    component: PaymentComplete,
    authenticate: false,
  },
  {
    path: ROUTES.PAYMENT_COMPLETE,
    exact: true,
    key: "payment_complete",
    component: PaymentComplete,
    authenticate: false,
  },
];

export default myRoutes;

import React, { useEffect, useState } from "react";
import { Box, Center, Pressable, Skeleton } from "native-base";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { dynamicClear, useDynamicSelector } from "@services/redux";
import { Button, Card, Typography } from "antd";
import { clearLocalStorage, retrieveItem } from "@helpers/storage";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";

const UnderVerification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [matches_count, set_matches_count] = useState(0);
  const [profile_number, set_profile_number] = useState("");
  const { pagination: all_matches_pagination, loading: all_matches_loading } =
    useDynamicSelector("matches");
  useEffect(() => {
    let _profile_number = retrieveItem("profile_id");
    set_profile_number(_profile_number);
    if (all_matches_pagination?.total_count > 0) {
      set_matches_count(all_matches_pagination?.total_count);
    }
  }, [all_matches_pagination]);

  const handleLogout = () => {
    dispatch(dynamicClear("user_login"));

    clearLocalStorage();
    navigate(ROUTES.REDIRECTOR);
  };
  return (
    <Center my="auto" bg={"#712e5a"} flex="1">
      <Card
        style={{
          backgroundColor: "#fff",
          marginRight: 10,
          marginLeft: 10,
        }}
      >
        <Typography
          style={{
            fontSize: 30,
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: 5,
          }}
        >
          <u>{"Account under verification"}</u>
        </Typography>
        <ul style={{ listStyleType: "disc", paddingLeft: 20 }}>
          <li>
            <Typography
              style={{
                fontSize: 24,
              }}
              color={"#712e5a"}
            >
              {` Your profile has been successfully created, and your ID is:
              (${profile_number}). It is currently undergoing verification.`}
            </Typography>
          </li>
          <li>
            <Typography
              style={{
                fontSize: 24,
              }}
              color={"#712e5a"}
            >
              You will receive a call from this number:
              <b>
                <u>+91 7305970950 </u>
              </b>
              for verification. Please respond to the call or call above number
              to verify your profile (Monday to Saturday, 10 am to 6 pm).
            </Typography>
          </li>
          <li>
            <Typography
              style={{
                fontSize: 24,
              }}
              color={"#712e5a"}
            >
              {all_matches_loading
                ? "Loading..."
                : `Based on your current preferences, you have (${matches_count}) matches.`}
            </Typography>
          </li>
        </ul>
        <Box alignItems={"center"}>
          <Button
            onClick={() => {
              handleLogout();
            }}
            type="primary"
          >
            <Typography
              style={{
                fontSize: 14,
                color: "#ffff",
              }}
            >
              {"Logout"}
            </Typography>
          </Button>
        </Box>
      </Card>
    </Center>
  );
};
export default UnderVerification;

import { IMAGE_URL } from "./constants";

export function getProfileUrl(url, member_gender) {
  if (url && member_gender) {
    return url;
  } else if (member_gender === "f" && !url) {
    return "https://assets.blr1.vultrobjects.com/female-avatar.png";
  } else if (member_gender === "m" && !url) {
    return "https://assets.blr1.vultrobjects.com/male-avatar.png";
  }
}

export function getImageUrl(url) {
  if (url) {
    return url;
  } else {
    return "No photos";
  }
}

export function getCroppedImageUrl(url, member_gender) {
  // let ext = url?.split(".")[1];
  // let file = url?.split(".")[0];
  // let fileName = `${file}_c.${ext}`;
  if (url) {
    return url;
  } else if (member_gender === "m" && !url) {
    return "https://assets.blr1.vultrobjects.com/male-avatar.png";
  } else if (member_gender === "f" && !url) {
    return "https://assets.blr1.vultrobjects.com/female-avatar.png";
  }
}

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  VStack,
  Text,
  HStack,
  Pressable,
  Center,
  Switch,
} from "native-base";
import {
  getCountry,
  getDistrict,
  getState,
} from "@helpers/get_country_state_city";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  useDynamicSelector,
  get_all_no_photo_members,
  interest_count_query,
  interest_count_report_query,
} from "@services/redux";
import { Button, Pagination, Select, Table } from "antd";
import {
  CalculateAge,
  CalculateAgeForMember,
  CalculateAgeNumber,
} from "@helpers/calculate_age";
import { navigateBack } from "@helpers/navigator";
import { PDFViewer } from "@react-pdf/renderer";
import moment from "moment";
import {
  complete_payment_query,
  complete_payment_report_query,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_completed_payment_members";
import { generateExcelFromJSON } from "@helpers/functions";

const InterestCountReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [report_data, setReportData] = useState([]);
  const [filter_value, set_filter_value] = useState("");

  const {
    items: Reports_items,
    loading: Reports_loading,
    pagination,
  } = useDynamicSelector("getMemberRequestCount");

  const {
    items: interest_count_report_items,
    loading: interest_count_report_loading,
  } = useDynamicSelector("interest_count_report");

  useEffect(() => {
    get_all_users_interest_count("all");
    getReports("all");
  }, []);

  useEffect(() => {
    if (interest_count_report_items?.length > 0) {
      setReportData(interest_count_report_items);
    }
  }, [interest_count_report_items, filter_value]);

  const get_all_users_interest_count = (filter_value) => {
    let key = [{ key: "getMemberRequestCount", loading: true }];
    let query = interest_count_query;
    let variable = {
      page_number: current,
      page_limit: current_limit,
      interval: filter_value,
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getReports = (filter_value) => {
    let keys = [{ key: "interest_count_report", loading: true }];
    dispatch(
      dynamicRequest(keys, interest_count_report_query, {
        page_number: 1,
        page_limit: 1000000,
        interval: filter_value,
      })
    );
  };

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "getCompletedPaymentMembers", loading: true }];
    dispatch(
      dynamicRequest(keys, interest_count_query, {
        page_number: page_number,
        page_limit: page_limit,
        interval: filter_value,
      })
    );
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };

  const on_change = (value) => {
    set_filter_value(value);
    getReports(value);
    get_all_users_interest_count(value);
  };

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
      width: 150,
      fixed: "left",
      render: (item, items) => {
        console.log("items", items);
        return items?.name;
      },
    },
    {
      title: "Age",
      dataIndex: "dob",
      align: "left",
      key: "dob",
      width: 100,
      render: (record, i) => {
        return (
          <>
            <Text>{CalculateAgeForMember(record)}</Text>
          </>
        );
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      align: "left",
      key: "gender",
      render: (record, i) => {
        return (
          <>
            <Text>{record === "m" ? "Male" : "Female"}</Text>
          </>
        );
      },
    },
    {
      title: "Profile Number",
      dataIndex: "profile_id",
      align: "left",
      key: "profile_id",
      render: (item, items) => {
        return items?.profile_id;
      },
    },
    {
      title: "Location",
      dataIndex: "",
      align: "left",
      key: "location",
      render: (item, items) => {
        let country = items?.country;
        return (
          <>
            <Text>
              {items?.country ? <>{getCountry(items?.country)},</> : null}
              {items?.state ? <>{getState(items?.state, country)},</> : null}
              {items?.district ? <>{getDistrict(items?.district)}.</> : null}
            </Text>
          </>
        );
      },
    },
    {
      title: "Franchise Name",
      dataIndex: "",
      align: "center",
      key: "name",
      render: (item, items) => {
        return <Text>{items?.franchise_name || "-"}</Text>;
      },
    },
    {
      title: "Mobile",
      dataIndex: "",
      align: "center",
      key: "name",
      render: (item, items) => {
        return <Text>{items?.mobile || "-"}</Text>;
      },
    },
    {
      title: "Received Request",
      dataIndex: "",
      align: "center",
      key: "name",
      render: (item, items) => {
        return <Text>{items?.received_request_count || "-"}</Text>;
      },
    },
    {
      title: "Send Request",
      dataIndex: "",
      align: "center",
      key: "name",
      render: (item, items) => {
        return <Text>{items?.sent_request_count || "-"}</Text>;
      },
    },
    {
      title: "Accepted Request",
      dataIndex: "",
      align: "center",
      key: "name",
      render: (item, items) => {
        return <Text>{items?.accept_request_count || "-"}</Text>;
      },
    },

    {
      title: "Pincode",
      dataIndex: "",
      align: "center",
      key: "name",
      render: (item, items) => {
        return <Text>{items?.pincode || "-"}</Text>;
      },
    },

    {
      title: "Created On",
      dataIndex: "",
      align: "left",
      key: "name",
      render: (items) => {
        return (
          <Text>{moment(items?.created_at).format("DD/MM/YYYY h:mm a")}</Text>
        );
      },
    },
  ];
  const fieldMappings = [
    {
      label: "Name",
      field: "name",
    },
    {
      label: "Profile Number",
      field: "profile_id",
    },

    {
      label: "Mobile",
      field: "mobile",
    },
    {
      label: "Created At",
      field: "created_at",
    },
    {
      label: "Date of Birth",
      field: "dob",
    },
    {
      label: "Gender",
      field: "gender",
    },
    {
      label: "Received Request",
      field: "received_request_count",
    },
    {
      label: "Send Request",
      field: "sent_request_count",
    },
    {
      label: "Accepted Request",
      field: "accept_request_count",
    },
    {
      label: "Franchise",
      field: "franchise_name",
    },
    {
      label: "Pincode",
      field: "pincode",
    },
    {
      label: "District",
      field: "district",
    },
  ];

  return (
    <Box width="100%" mt="20px" marginBottom="20px">
      <Box top="0px" zIndex="2" mb="5">
        <Text fontSize="md" fontWeight="bold">
          {`${t("users_interest_report")} (${
            pagination?.total_count ? pagination?.total_count : 0
          })`}
        </Text>
      </Box>
      <Box my={5} width={"150px"}>
        <Button
          loading={interest_count_report_loading}
          colorScheme={"danger.700"}
          onClick={() => {
            generateExcelFromJSON(
              report_data,
              "UsersInterestReport.xlsx",
              fieldMappings
            );
          }}
          style={{
            height: "50px",
            label: t("table:back"),
            variant: "outline",
          }}
        >
          {interest_count_report_loading ? "Wait..." : "Download Report"}
        </Button>
      </Box>
      <HStack width={"100%"}>
        <Box my={5} width={"50%"}>
          <Button
            colorScheme={"danger.700"}
            onClick={() => {
              navigateBack();
            }}
            style={{
              label: t("table:back"),
              variant: "outline",
              width: "120px",
            }}
          >
            Back
          </Button>
        </Box>

        <Box width={"50%"} alignItems={"flex-end"} my={5}>
          <Select
            placeholder="Select"
            style={{ width: "200px" }}
            options={[
              {
                label: "ALL",
                value: "all",
              },
              {
                label: "Last 30 days",
                value: "last_30_days",
              },
            ]}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={on_change}
          />
        </Box>
      </HStack>

      <VStack width={"100%"} space={5}>
        <Wrapper>
          <Table
            columns={columns}
            pagination={false}
            loading={Reports_loading}
            dataSource={Reports_items}
            scroll={{
              x: 2000,
              y: 1000,
            }}
          />
        </Wrapper>
        <Pagination
          showSizeChanger={true}
          current={current}
          hideOnSinglePage={false}
          onChange={fetchData}
          total={pagination?.total_count}
        />
      </VStack>
    </Box>
  );
};

export default InterestCountReport;

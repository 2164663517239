import { gql } from "@apollo/client";
export const get_all_invoice_report = gql`
  query getInvoiceReport($page_number: Float, $page_limit: Float) {
    getInvoiceReport(page_number: $page_number, page_limit: $page_limit) {
      items {
        id
        invoice_no
        invoice_date
        profile_id
        name
        mobile
        plan_name
        plan_amount
        discount_amount
        after_discount
        basic_price
        sgst_price
        cgst_price
        total_amount
        franchise_name
        caste
        sub_caste
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_invoice_report_download = gql`
  query getInvoiceReport($page_number: Float, $page_limit: Float) {
    get_all_invoice_download: getInvoiceReport(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        invoice_no
        invoice_date
        profile_id
        name
        mobile
        plan_name
        plan_amount
        discount_amount
        after_discount
        basic_price
        sgst_price
        cgst_price
        total_amount
        franchise_name
        caste
        sub_caste
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

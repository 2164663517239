import {
  Box,
  Hidden,
  HStack,
  Image,
  Input,
  Pressable,
  ScrollView,
  SimpleGrid,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useState } from "react";
import * as IMAGE_CONSTANTS from "@helpers/image_constants";
import Select from "react-select";
import {
  AiFillCloseCircle,
  AiFillCrown,
  AiFillDelete,
  AiOutlineArrowLeft,
} from "react-icons/ai";
import {
  FaCrown,
  FaEdit,
  FaRemoveFormat,
  FaUserFriends,
  FaUserLock,
  FaUserSlash,
} from "react-icons/fa";
// import ScrollView from "@views/components/ui/scroll_view/scroll_view";
import { ROUTES } from "@views/routes/my_routes";
import { navigate, navigateBack } from "@helpers/navigator";
import { useTranslation } from "react-i18next";
import { RiLockPasswordLine } from "react-icons/ri";
import toast from "react-hot-toast";

import {
  deleteUserAccount,
  dynamicClear,
  dynamicRequest,
  getMyPlan,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { Modal, Popconfirm } from "antd";
import { clearLocalStorage, retrieveItem } from "@helpers/storage";
import { MdLogout } from "react-icons/md";
import { getCroppedImageUrl, getProfileUrl } from "@helpers/get_profile_url";
import moment from "moment";
import colors from "@helpers/colors";

const UserSettingsContent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [user_plan, setUserPlan] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [delete_reason, setDeleteReason] = useState("");
  const [delete_reason_text, setDeleteReasonText] = useState("");
  const [profile_details, setprofileDetails] = useState("");
  const [image_list, setImageList] = useState([]);

  const { my_subscription: subscriped_plan, user_profile } =
    useDynamicSelector("my_plan");
  const { user_photos: userPhotos } = useDynamicSelector("my_photo");
  const { status: delete_my_account_status } =
    useDynamicSelector("deleteMyAccount");

  const Logout = () => {
    dispatch(dynamicClear("user_login"));
    clearLocalStorage();
    navigate(ROUTES.LOGIN);
  };

  useEffect(() => {
    let login_user_photo = retrieveItem("profile_photo");
    setProfilePhoto(login_user_photo);
  }, []);

  useEffect(() => {
    let get_plan_key = [{ key: "my_plan", loading: true }];
    let get_plan_query = getMyPlan;
    let get_plan_variables = null;
    dispatch(dynamicRequest(get_plan_key, get_plan_query, get_plan_variables));
  }, []);

  useEffect(() => {
    if (subscriped_plan || user_profile) {
      setUserPlan(subscriped_plan);
      setprofileDetails(user_profile);
    }
  }, [subscriped_plan, user_profile]);

  const deleteMyUserAccount = () => {
    let key = [{ key: "deleteMyAccount", loading: true }];
    let query = deleteUserAccount;
    let variables = {
      reason: delete_reason_text ? delete_reason_text : delete_reason,
    };

    dispatch(dynamicRequest(key, query, variables));
  };
  const handleDeleteModalCancel = () => {
    setIsDeleteModalOpen(false);
  };
  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (delete_my_account_status === "success") {
      toast.success("Profile Deleted");
      dispatch(dynamicClear("user_login"));
      dispatch(dynamicClear("deleteMyAccount"));
      clearLocalStorage();
      navigate(ROUTES.LOGIN);
    }
  }, [delete_my_account_status]);
  useEffect(() => {
    if (userPhotos) {
      let arr = userPhotos?.find((x) => x.is_main_photo === true);
      setImageList(arr);
    }
  }, [userPhotos]);
  const UserImage = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          borderRadius: "50%",
          objectFit: "cover",
        }}
        width={120}
        height={120}
      />
    );
  });

  return (
    <Box
      alignItems={"center"}
      justifyContent={"center"}
      background="#fef5fb"
      height={"100vh"}
    >
      <Box
        shadow={1}
        height={30}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100vw"}
      >
        <Hidden till="md">
          <Text color={"#712e5a"} fontSize={14} bold>
            {t("settings")}
          </Text>
        </Hidden>
        <Hidden from="md">
          <Popconfirm
            onConfirm={() => {
              Logout();
            }}
            title={t("logout")}
          >
            <Box cursor={"pointer"} position={"absolute"} right={5}>
              <MdLogout size={20} color={"#111"} />
            </Box>
          </Popconfirm>
        </Hidden>
      </Box>

      <ScrollView showsVerticalScrollIndicator={false} flex={1}>
        <Box my={5} alignItems="center" justifyContent="center">
          <HStack>
            <UserImage
              src={getCroppedImageUrl(
                image_list?.photo_url,
                profile_details?.gender
              )}
            />
            <Box mx={5} justifyContent="center" alignItems={"center"}>
              {user_plan?.en_label ? (
                <VStack>
                  <HStack alignItems={"center"}>
                    <FaCrown size={20} />
                    <Text mx={2} fontSize={14} color={"#111"}>
                      {user_plan?.en_label}
                    </Text>
                  </HStack>
                  <Text fontSize={14}>
                    {`${user_plan?.viewed_contact_details_count} / ${user_plan?.allowed_contact_details_view} Contacts viewed`}
                  </Text>

                  <Text fontSize={14}>{`Valid till ${moment
                    .unix(user_plan?.subscription_till / 1000)
                    .format("Do MMMM YYYY")}`}</Text>
                </VStack>
              ) : (
                <Pressable
                  alignItems={"center"}
                  width={"100%"}
                  onPress={() => {
                    // if (user_plan) {
                    //   showModal();
                    // } else {
                    navigate(ROUTES.USER_SUBSCRIPTION);
                    // }
                  }}
                  style={{
                    shadowOffset: { width: 0, height: 1 },
                    shadowColor: "#ffffff",
                    elevation: 15,
                    shadowOpacity: 0.62,
                    shadowRadius: 2.22,
                  }}
                  backgroundColor={"#6cb0f0"}
                  marginTop={"10px"}
                  marginRight={"5px"}
                  borderColor="#ffffff"
                  borderWidth={0.8}
                  rounded={5}
                  elevation={5}
                  paddingY={2}
                >
                  <HStack>
                    <FaCrown size={20} color="#ffffff" />

                    <Text mx={2} color="#fff">
                      {"Buy Plan"}
                    </Text>
                  </HStack>
                </Pressable>
              )}
            </Box>
          </HStack>
        </Box>

        <SimpleGrid
          columns={{
            base: 2,
            sm: 2,
            xs: 2,
            md: 3,
            lg: 6,
            xl: 6,
            "2xl": 6,
            "3xl": 6,
          }}
          space={5}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Pressable
            _hover={{
              shadow: 8,
            }}
            borderRadius={10}
            shadow={2}
            backgroundColor={"#fff"}
            alignItems={"center"}
            justifyContent={"center"}
            width={150}
            height={150}
            py={2}
            onPress={() => {
              navigate(ROUTES.USER_MANAGE_PROFILE);
            }}
          >
            <VStack py={2} alignItems={"center"} px={5}>
              <FaEdit size={30} color={"#712e5a"} />
              <Text fontSize={14} px={5}>
                {t("edit_profile")}
              </Text>
            </VStack>
          </Pressable>

          <Pressable
            _hover={{
              shadow: 8,
            }}
            shadow={2}
            borderRadius={10}
            backgroundColor={"#fff"}
            py={2}
            alignItems={"center"}
            justifyContent={"center"}
            width={150}
            height={150}
            onPress={() => {
              navigate(ROUTES.USER_MANAGE_PREFERENCE);
            }}
          >
            <VStack py={2} alignItems={"center"}>
              <FaUserFriends size={30} color={"#712e5a"} />
              <Text fontSize={14} px={5}>
                {t("edit_preference")}
              </Text>
            </VStack>
          </Pressable>

          <Pressable
            _hover={{
              shadow: 8,
            }}
            shadow={2}
            borderRadius={10}
            backgroundColor={"#fff"}
            py={2}
            alignItems={"center"}
            justifyContent={"center"}
            width={150}
            height={150}
            onPress={() => {
              navigate(ROUTES.USER_PRIVACY_SETTINGS);
            }}
          >
            <VStack py={2} alignItems={"center"}>
              <FaUserLock size={30} color={"#712e5a"} />
              <Text fontSize={14} px={5}>
                {t("privacy_settings")}
              </Text>
            </VStack>
          </Pressable>

          <Pressable
            _hover={{
              shadow: 8,
            }}
            borderRadius={10}
            shadow={2}
            backgroundColor={"#fff"}
            py={2}
            alignItems={"center"}
            justifyContent={"center"}
            width={150}
            height={150}
            onPress={() => {
              navigate(ROUTES.USER_BLOCKED_MEMBERS);
            }}
          >
            <VStack py={2} alignItems={"center"}>
              <FaUserSlash size={30} color={"#712e5a"} />
              <Text fontSize={14} px={5}>
                {t("blocked_profiles")}
              </Text>
            </VStack>
          </Pressable>

          <Pressable
            _hover={{
              shadow: 8,
            }}
            backgroundColor={"#fff"}
            borderRadius={10}
            shadow={2}
            py={2}
            alignItems={"center"}
            justifyContent={"center"}
            width={150}
            height={150}
            onPress={() => {
              navigate(ROUTES.USER_IGNORED_MEMBERS);
            }}
          >
            <VStack py={2} alignItems={"center"}>
              <AiFillCloseCircle size={30} color={"#712e5a"} />
              <Text fontSize={14} px={5}>
                {t("ignored_profiles")}
              </Text>
            </VStack>
          </Pressable>

          <Pressable
            _hover={{
              shadow: 8,
            }}
            backgroundColor={"#fff"}
            borderRadius={10}
            shadow={2}
            py={2}
            alignItems={"center"}
            justifyContent={"center"}
            width={150}
            height={150}
            onPress={() => {
              navigate(ROUTES.USER_CHANGE_PASSWORD);
            }}
          >
            <VStack alignItems={"center"}>
              <RiLockPasswordLine size={30} color={"#712e5a"} />
              <Text fontSize={14}>{t("change_password")}</Text>
            </VStack>
          </Pressable>
        </SimpleGrid>
      </ScrollView>
      <Box height={50} />
      {/* <Pressable
        height={"30px"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"20%"}
        onPress={() => {
          showDeleteModal();
        }}
        style={{
          shadowOffset: { width: 0, height: 1 },
          shadowColor: "#ffffff",
          elevation: 15,
          shadowOpacity: 0.62,
          shadowRadius: 2.22,
        }}
        backgroundColor={colors.darkRed}
        marginTop={"10px"}
        marginRight={"5px"}
        borderColor="#ffffff"
        borderWidth={0.8}
        rounded={5}
        elevation={5}
        paddingY={2}
      >
        <HStack>
          <AiFillDelete size={20} color="#ffffff" />

          <Text mx={2} color="#fff">
            {"Delete Profile"}
          </Text>
        </HStack>
      </Pressable> */}
      <Box height={90} />
      <Modal
        title="Current Plan"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <VStack>
          <Text fontSize={20} color={colors.primary}>
            {`( ${user_plan?.viewed_contact_details_count} / ${user_plan?.allowed_contact_details_view} ) Contacts viewed`}
          </Text>
          <HStack alignItems={"center"}>
            <FaCrown size={20} color={colors.primary} />

            <Text
              fontSize={20}
              mx={2}
              color={colors.primary}
            >{`Valid Till ${moment
              .unix(user_plan?.subscription_till / 1000)
              .format("Do MMMM YYYY")}`}</Text>
          </HStack>
        </VStack>
      </Modal>
      {/* <Modal
        title="Delete Profile"
        open={isDeleteModalOpen}
        onOk={() => {
          if (delete_reason === "Any other reason") {
            if (delete_reason_text.trim() === "") {
              toast.error("Please enter reason");
            } else {
              deleteMyUserAccount();
            }
          } else if (delete_reason && delete_reason !== "Any other reason") {
            deleteMyUserAccount();
          }
        }}
        // onOk={() => {
        //   if (delete_reason) {
        //     deleteMyUserAccount();
        //   }
        // }}
        onCancel={handleDeleteModalCancel}
      >
        <Box pt={"10px"} marginBottom={"10px"}>
          <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
            {t("reason_for_delete")}
          </Text>
        </Box>

        <Select
          onChange={(e) => {
            setDeleteReason(e.value);
            setDeleteReasonText("");
          }}
          style={{
            rounded: 25,
          }}
          options={deleteOptions}
        />
        {delete_reason === "Any other reason" && (
          <Box width={"470px"} my={5}>
            <Input
              size="2xl"
              height={"50px"}
              placeholder="Reason"
              onChangeText={(e) => {
                setDeleteReasonText(e);
              }}
            />
          </Box>
        )}
      </Modal> */}
    </Box>
  );
};
export default UserSettingsContent;

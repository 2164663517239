import { gql } from "@apollo/client";
export const get_all_operators_query = gql`
  query getOperators($franchise_id: String!) {
    getOperators(franchise_id: $franchise_id) {
      items {
        id
        name
        mobile
        username
        operator_franchise_id
        franchise {
          name
        }
        system_user_id
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const create_operator_mutation = gql`
  mutation registerOperator($data: register_operator_user_input) {
    registerOperator(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_operator_mutation = gql`
  mutation updateOperator($data: register_operator_user_input, $id: String!) {
    updateOperator(data: $data, id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_franchise_operators = gql`
  query getAllFranchises($page_number: Float, $page_limit: Float) {
    get_all_franchises_for_operators: getAllFranchises(
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
      }
    }
  }
`;

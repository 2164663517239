import { gql } from "@apollo/client";

export const approve_verification_mutation = gql`
  mutation approveVerification(
    $user_id: String!
    $photo_id: String
    $type: String
  ) {
    approveVerification(user_id: $user_id, photo_id: $photo_id, type: $type) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const reject_verification_mutation = gql`
  mutation rejectVerification(
    $user_id: String!
    $photo_id: String
    $type: String
  ) {
    verification_reject: rejectVerification(
      user_id: $user_id
      photo_id: $photo_id
      type: $type
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_verifications_query = gql`
  query getVerifications(
    $page_number: Float
    $page_limit: Float
    $search_string: String
  ) {
    verification: getVerifications(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        gender
        gothram
        gothram_verification_status
        anchestor_origin
        anchestor_origin_verification_status
        institution
        institution_verification_status
        employer
        employer_verification_status
        name
        profile {
          profile_id
          verification_pending_reason
        }

        name_verification_status
        about_family
        about_family_verification_status
        user_photos {
          id
          photo_url
          verification_status
        }
        franchise_name
        promocode
        pincode
        created_at
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const create_admin_user_mutation = gql`
  mutation registerAdminUser($data: register_admin_user_input) {
    user_create: registerAdminUser(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_districts_query = gql`
  query getDistricts {
    districts: getDistricts {
      items {
        id
        name
      }
    }
  }
`;

export const get_approve_query = gql`
  mutation approveText($user_id: String!, $types: [String]) {
    approveText(user_id: $user_id, types: $types) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const get_reject_query = gql`
  mutation rejectText($user_id: String!, $types: [String]) {
    rejectText(user_id: $user_id, types: $types) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const get_approve_photo_query = gql`
  mutation approvePhoto(
    $photo_id: String!
    $left: Float
    $top: Float
    $width: Float
    $height: Float
  ) {
    approvePhoto(
      photo_id: $photo_id
      left: $left
      top: $top
      width: $width
      height: $height
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const get_reject_photo_query = gql`
  mutation rejectPhoto($photo_id: String!) {
    rejectPhoto(photo_id: $photo_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const approve_profile_query = gql`
  mutation approveProfile($user_id: String) {
    approveProfile(user_id: $user_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const reject_profile_query = gql`
  mutation rejectProfile($user_id: String, $reject_reason: String) {
    rejectProfile(user_id: $user_id, reject_reason: $reject_reason) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const update_pending_reason = gql`
  mutation updateVerificationPendingReason(
    $user_id: String!
    $verification_pending_reason: String!
  ) {
    updateVerificationPendingReason(
      user_id: $user_id
      verification_pending_reason: $verification_pending_reason
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

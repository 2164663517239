export const NumberOfChildrenOptions = [
  {
    value: 0,
    p_o: [],
    en_label: "0",
    ta_label: null,
  },
  {
    value: 1,
    p_o: [],
    en_label: "1",
    ta_label: null,
  },
  {
    value: 2,
    p_o: [],
    en_label: "2",
    ta_label: null,
  },
  {
    value: 3,
    p_o: [],
    en_label: "3",
    ta_label: null,
  },
  {
    value: 4,
    p_o: [],
    en_label: "4",
    ta_label: null,
  },
  {
    value: 5,
    p_o: [],
    en_label: "5",
    ta_label: null,
  },
];

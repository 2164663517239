export const CreatedForOptions = [
  {
    value: "myself",
    p_o: [],
    en_label: "Myself",
    ta_label: "எனக்காக",
  },
  {
    value: "relative",
    p_o: [],
    en_label: "Relative",
    ta_label: "உறவினருக்கு",
  },
  {
    value: "friend",
    p_o: [],
    en_label: "Friend",
    ta_label: "நண்பருக்கு",
  },
  {
    value: "son",
    p_o: [],
    en_label: "Son",
    ta_label: "மகனுக்கு",
  },
  {
    value: "daughter",
    p_o: [],
    en_label: "Daughter",
    ta_label: "மகளுக்கு",
  },
  {
    value: "brother",
    p_o: [],
    en_label: "Brother",
    ta_label: "சகோதரனுக்கு",
  },
  {
    value: "sister",
    p_o: [],
    en_label: "Sister",
    ta_label: "சகோதரிக்கு",
  },
  {
    value: "grand_son",
    p_o: [],
    en_label: "Grand son",
    ta_label: "பேரனுக்கு",
  },
  {
    value: "grand_daughter",
    p_o: [],
    en_label: "Grand daughter",
    ta_label: "பேத்திக்கு",
  },
];

import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button, Input, Tag } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  create_admin_franchise_mutation,
  update_admin_franchise_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  HStack,
  Text,
  VStack,
  useBreakpointValue,
} from "native-base";
import "../../../index.css";
import colors from "@helpers/colors";
import { CountriesOptions } from "@master_data/common_json/countries";
import { getDistrictsList, getStatesList } from "@helpers/functions";
import _ from "lodash";
const AdminFranchiseDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const country = Form.useWatch("country", form);
  const state = Form.useWatch("state", form);
  const [countries_loading, setCountriesLoading] = useState(false);
  const [states_loading, setStatesLoading] = useState(false);
  const [districts_loading, setDistrictsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const { loading: UpdateLoading } = useDynamicSelector("update_franchise");
  const { loading: createLoading } = useDynamicSelector("create_franchise");
  useEffect(() => {
    // form.resetFields();
    form.setFieldsValue({
      ...initialValues,
      username: initialValues?.admin_user?.username,
      country: _.snakeCase(initialValues?.country),
      state: _.snakeCase(initialValues?.state),
      district: _.snakeCase(initialValues?.district),
    });
    setTags(initialValues?.pincodes || []);
  }, [initialValues]);

  useEffect(() => {
    setCountriesLoading(true);
    let _countries = [];
    try {
      _countries = CountriesOptions;
    } catch (e) {}
    _countries = _countries?.map((x) => ({
      name: x.name,
      value: _.snakeCase(x.i_c),
    }));
    setCountries(_countries);
    setCountriesLoading(false);
  }, []);

  useEffect(() => {
    setStatesLoading(true);
    let _states = [];
    if (country) {
      try {
        _states = getStatesList(country);
        _states.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      } catch (e) {}
    }
    setStates(_states);
    setStatesLoading(false);
  }, [country]);

  useEffect(() => {
    setDistrictsLoading(true);
    let _districts = [];
    if (country && state) {
      try {
        _districts = getDistrictsList(country, state);
        _districts.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      } catch (e) {}
    }
    setDistricts(_districts);
    setDistrictsLoading(false);
  }, [state]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputEnter = () => {
    if (inputValue.trim() !== "") {
      setTags([...tags, inputValue]);
      setInputValue("");
    }
  };

  const handleTagClose = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
  };

  const handleSubmit = (values) => {
    values.pincodes = tags;

    initialValues.id && delete values.code;
    if (initialValues.id) {
      let variables = {
        id: initialValues.id,
        data: values,
      };
      let keys = [{ key: "update_franchise", loading: true }];
      dispatch(
        dynamicRequest(keys, update_admin_franchise_mutation, variables, "M")
      );
    } else {
      let variables = {
        data: values,
      };
      let keys = [{ key: "create_franchise", loading: true }];
      dispatch(
        dynamicRequest(keys, create_admin_franchise_mutation, variables, "M")
      );
    }
  };

  const columns = useBreakpointValue({
    base: 1,
    md: 2,
  });

  const handleChange = (b, a) => {
    if (b.country) form.setFieldsValue({ state: "" });
    if (b.state) form.setFieldsValue({ district: "" });
  };

  const handleFormFinish = (event) => {
    event.preventDefault();
    form.submit();
  };
  const handleInputSubmit = () => {
    if (inputValue.trim() !== "") {
      setTags([...tags, inputValue]);
      setInputValue("");
    }
  };

  const handleStateChange = (val) => {
    if (val) {
      form.setFieldsValue({
        ...initialValues,
        district: null,
      });
    }
  };
  return (
    <Form
      form={form}
      name={"admin_plans"}
      layout={"vertical"}
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      onSubmit={handleFormFinish}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.Grid columns={columns} spacingY={10} spacingX={10}>
        <VForm.TextBox
          label={t("name")}
          field={"name"}
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        />
        {!initialValues.id && (
          <VForm.TextBox
            label={t("code")}
            field={"code"}
            rules={[
              {
                required: true,
                message: "Code is required",
              },
            ]}
          />
        )}
        <VForm.TextBox
          label={t("mobile")}
          field={"mobile"}
          rules={[
            {
              required: true,
              message: "Mobile is required",
            },
          ]}
        />

        <VForm.Select
          label={t("country")}
          field={"country"}
          loading={countries_loading}
          showSearch={true}
          options={countries}
          labelField={"name"}
          valueField={"value"}
          form_type={"profile"}
          rules={[
            {
              required: true,
              message: "Country is required",
            },
          ]}
        />
        <VForm.Select
          label={t("state")}
          field={"state"}
          loading={states_loading}
          showSearch={true}
          onChange={handleStateChange}
          options={states}
          labelField={"name"}
          valueField={"i_c"}
          form_type={"profile"}
          rules={[
            {
              required: true,
              message: "State is required",
            },
          ]}
        />
        <VForm.Select
          label={t("district")}
          field={"district"}
          loading={districts_loading}
          showSearch={true}
          options={districts}
          labelField={"name"}
          valueField={"value"}
          form_type={"profile"}
          rules={[
            {
              required: true,
              message: "District is required",
            },
          ]}
        />
        <VStack>
          <Text mb={2} color={"#712e5a"}>
            {"Pincode"}
          </Text>
          <HStack>
            <Input
              value={inputValue}
              onChange={handleInputChange}
              onPressEnter={handleInputEnter}
              placeholder="Enter pincode"
            />
            <Button
              style={{
                marginLeft: 5,
              }}
              onClick={handleInputSubmit}
            >
              Add
            </Button>
          </HStack>
          <div
            style={{
              marginTop: 10,
            }}
          >
            {tags?.map((tag) => (
              <Tag key={tag} closable onClose={() => handleTagClose(tag)}>
                {tag}
              </Tag>
            ))}
          </div>
        </VStack>
      </VForm.Grid>
      <Text fontSize={20} my={5} bold>
        {"Admin"}
      </Text>
      <HStack alignItems={"center"}>
        <Box mx={2} width={"50%"}>
          <VForm.TextBox
            label={t("username")}
            field={["username"]}
            disabled={initialValues?.admin_user?.username}
            rules={[
              {
                required: true,
                message: "Username is required",
              },
            ]}
          />
        </Box>
        <Box width={"50%"}>
          <VForm.Password
            label={t("password")}
            field={"password"}
            rules={[
              {
                required: initialValues?.id ? false : true,
                message: "Password is required",
              },
            ]}
          />
        </Box>
      </HStack>
      <HStack space="3" justifyContent={"flex-end"}>
        <Button onClick={props.close}>{t("close")}</Button>
        <VForm.Button
          isLoading={initialValues.id ? UpdateLoading : createLoading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
      </HStack>
    </Form>
  );
};
export default AdminFranchiseDetails;

import { Box, Card, Divider, HStack, ScrollView, Text } from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input, Checkbox } from "antd";
import "../../../index.css";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  getMyPreference,
  getOperatorMatches,
  get_user_search_filter,
  updateMyPreference,
  updateUserProfile,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

import toast from "react-hot-toast";
import UserSearchFormBasicDetails from "./user_filter_basic_details";
import UserSearchFormReligionDetails from "./user_filter_religion_details";
import UserSearchFormProfessionalDetails from "./user_filter_professional_details";
import UserSearchFormLocationDetails from "./user_filter_location_details";
import { operator_matches_page_count, page_count } from "@helpers/constants";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";

const UserFilterForm = (props) => {
  const { onFilterClose } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [initial_values, setInitialValues] = useState([]);
  const [filter_variables, setFilterVariables] = useState(false);

  const { user_preference: my_preference_details } =
    useDynamicSelector("my_preference");
  const { status: new_token_status } = useDynamicSelector("user_new_token");

  const { status: updatePreferenceStatus } =
    useDynamicSelector("updateMyPreference");

  const handleSubmit = (values) => {
    let get_filter_variables = {};
    const cleanedObject = Object.keys(values).reduce((acc, key) => {
      if (typeof values[key] !== "undefined") {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    dispatch(dynamicSet("secondary_filter_variables", {}));
    dispatch(
      dynamicSet("initial_filter_variables", {
        ...cleanedObject,
        show_only_with_open_photos: filter_variables,
      })
    );

    let get_filter_key = [{ key: "operator_matches", loading: true }];
    let get_filter_query = getOperatorMatches;
    if (cleanedObject?.countries?.includes("any")) {
      dispatch(
        dynamicSet("initial_filter_variables", {
          ...cleanedObject,
          countries: [],
          states: [],
          districts: [],
          show_only_with_open_photos: filter_variables,
        })
      );
      get_filter_variables = {
        filter: {
          ...cleanedObject,
          countries: [],
          states: [],
          districts: [],
          show_only_with_open_photos: filter_variables,
        },

        page_number: 1,
        page_limit: operator_matches_page_count,
      };
    } else if (cleanedObject?.states?.includes("any")) {
      dispatch(
        dynamicSet("initial_filter_variables", {
          ...cleanedObject,
          states: [],
          districts: [],
          show_only_with_open_photos: filter_variables,
        })
      );
      get_filter_variables = {
        filter: {
          ...cleanedObject,
          states: [],
          districts: [],
          show_only_with_open_photos: filter_variables,
        },

        page_number: 1,
        page_limit: operator_matches_page_count,
      };
    } else if (cleanedObject?.districts?.includes("any")) {
      dispatch(
        dynamicSet("initial_filter_variables", {
          ...cleanedObject,
          districts: [],
          show_only_with_open_photos: filter_variables,
        })
      );
      get_filter_variables = {
        filter: {
          ...cleanedObject,
          districts: [],
          show_only_with_open_photos: filter_variables,
        },

        page_number: 1,
        page_limit: operator_matches_page_count,
      };
    } else {
      dispatch(
        dynamicSet("initial_filter_variables", {
          ...cleanedObject,
          show_only_with_open_photos: filter_variables,
        })
      );
      get_filter_variables = {
        filter: {
          ...cleanedObject,
          show_only_with_open_photos: filter_variables,
        },

        page_number: 1,
        page_limit: operator_matches_page_count,
      };
    }

    dispatch(
      dynamicRequest(get_filter_key, get_filter_query, get_filter_variables)
    );
    onFilterClose();
  };
  const getMyPreferencesList = () => {
    let get_my_preference_key = [{ key: "my_preference", loading: false }];
    let get_my_preference_query = getMyPreference;
    let get_my_preference_variables = {};
    dispatch(
      dynamicRequest(
        get_my_preference_key,
        get_my_preference_query,
        get_my_preference_variables
      )
    );
  };
  useEffect(() => {
    getMyPreferencesList();
  }, []);

  const getOptions = (all_options, element_name, parent_key) => {
    let _options = all_options;

    if (parent_key) {
      let parent_element_option = eval(parent_key);
      _options = _options?.filter(
        (x) => x.p_o.indexOf(parent_element_option) > -1
      );
    }
    if (!_options) {
      _options = [];
    }

    return _options;
  };

  useEffect(() => {
    let _initial_values = JSON.parse(JSON.stringify(initial_values));
    if (_initial_values.countries?.length === 0) {
      _initial_values.countries = ["any"];
    }
    if (
      _initial_values.countries?.length > 0 &&
      _initial_values.states?.length === 0
    ) {
      _initial_values.states = ["any"];
    }
    if (
      _initial_values.countries?.length > 0 &&
      _initial_values.states?.length > 0 &&
      _initial_values.districts?.length === 0
    ) {
      _initial_values.districts = ["any"];
    }
    form.setFieldsValue(_initial_values);
  }, [initial_values]);

  // useEffect(() => {
  //   if (new_token_status === "success") {
  //     getMyPreferencesList();
  //     dispatch(dynamicClear("user_new_token"));
  //   }
  // }, [new_token_status]);
  return (
    <Box>
      <Form
        form={form}
        name={"preference_form"}
        className="user_form"
        layout={"horizontal"}
        onFinish={handleSubmit}
        initialValues={initial_values}
      >
        <Box
          width={"100%"}
          backgroundColor={"#fff"}
          zIndex={99}
          position={"sticky"}
          top={0}
        >
          <HStack alignItems={"center"}>
            <Box mx={5} my={5} width={"50%"}>
              <Text bold fontSize={18} mb={2} color="#712e5a">
                {"Filter by Preferences"}
              </Text>
            </Box>
            <Box mb={2} marginLeft={3} width={"30%"}>
              <Button color="#fff" type="primary" htmlType="submit">
                {t("apply_filter")}
              </Button>
            </Box>
          </HStack>
        </Box>

        <ScrollView>
          <UserSearchFormBasicDetails getOptions={getOptions} form={form} />
          <UserSearchFormReligionDetails getOptions={getOptions} form={form} />
          <UserSearchFormLocationDetails getOptions={getOptions} form={form} />
          <UserSearchFormProfessionalDetails
            setFilterVariables={setFilterVariables}
            getOptions={getOptions}
            form={form}
          />
        </ScrollView>
      </Form>
    </Box>
  );
};
export default UserFilterForm;

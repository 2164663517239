import { gql } from "@apollo/client";
export const gel_all_idle_members = gql`
  query getIdleMembers(
    $franchise_id: String
    $page_number: Float
    $page_limit: Float
  ) {
    get_all_idle_members: getIdleMembers(
      franchise_id: $franchise_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        dob
        gender
        mobile
        profile_id
        country
        state
        district
        last_login_datetime
        created_datetime
        franchise_name
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const gel_all_idle_members_reports = gql`
  query getIdleMembers(
    $franchise_id: String
    $page_number: Float
    $page_limit: Float
  ) {
    get_all_idle_members_reports: getIdleMembers(
      franchise_id: $franchise_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        dob
        gender
        mobile
        profile_id
        country
        state
        district
        last_login_datetime
        created_datetime
        franchise_name
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

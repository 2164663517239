import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, VStack, Text, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import IoMdAddCircle from "react-icons/io";
import {
  EditOutlined,
  DeleteOutlined,
  FileAddOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import {
  delete_franchise_plan_mutation,
  dynamicRequest,
  useDynamicSelector,
  get_all_franchise_plans_query,
  dynamicClear,
} from "@services/redux";
import AdminFranchiseDetails from "./admin_franchise_details";
import AntdModal from "../ui/antd_modal";
import {
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Space,
  Tooltip,
} from "antd";
import { showNotification } from "@helpers/notify";
import lodash from "lodash";
import ActionButton from "../ui/dialog/action_button";
import { TbCurrentLocation } from "react-icons/tb";
import { ROUTES } from "@views/routes/my_routes";
import { navigate, navigateWithProps } from "@helpers/navigator";
import { AiOutlineUser } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { GrUserSettings } from "react-icons/gr";
import {
  getCountry,
  getDistrict,
  getState,
} from "@helpers/get_country_state_city";
import _ from "lodash";

const AdminFranchiseList = () => {
  const dispatch = useDispatch();

  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);

  const history = useHistory();

  const { status: createStatus, error: createError } =
    useDynamicSelector("create_franchise");
  const { status: updateStatus, error: updateError } =
    useDynamicSelector("update_franchise");
  const { items: get_all_franchises, loading } =
    useDynamicSelector("get_all_franchises");
  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useDynamicSelector("delete_franchise");
  const handleAdd = () => {
    setActionType("add");
    setModalOpen(true);
    dispatch(dynamicClear("get_franchise"));
  };
  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_franchise", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_franchise_plan_mutation, {
        id: record?.id,
      })
    );
  };
  const handleAddPromo = (record) => {
    setActionType("promocode");
    setModalOpen(true);
    history.push(`/admin/admin-promo-code/${record.id}`, record?.name);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    let keys = [{ key: "get_all_franchises", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_franchise_plans_query, {
        page_number: current,
        page_limit: 10,
      })
    );
  }, []);

  useEffect(() => {
    if (!modalOpen) {
      setActionItem({});
    }
  }, [modalOpen]);

  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${"Frenches"} added successfully`,
      });
      let keys = [{ key: "get_all_franchises", loading: true }];

      dispatch(dynamicRequest(keys, get_all_franchise_plans_query, "M"));
      dispatch(dynamicClear("create_franchise"));
    } else if (
      createError?.message?.includes("Unique constraint failed on the fields")
    ) {
      showNotification({
        type: "failed",
        message: `Code already assigned!`,
      });
      dispatch(dynamicClear("create_franchise"));
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${"Franchise"} updated successfully`,
      });
      dispatch(dynamicClear("update_franchise"));
      let keys = [{ key: "get_all_franchises", loading: true }];
      dispatch(dynamicRequest(keys, get_all_franchise_plans_query));
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `${"Franchise"} deleted successfully`,
      });
      dispatch(dynamicClear("create_franchise"));

      let keys = [{ key: "delete_franchise", loading: true }];

      dispatch(dynamicRequest(keys, get_all_franchise_plans_query, "M"));
    }
  }, [createStatus, createError, updateStatus, deleteStatus]);
  const fetchData = (value) => {
    let keys = [{ key: "get_all_franchises", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_franchise_plans_query, {
        page_number: value,
        page_limit: 10,
      })
    );
    setCurrent(value);
  };

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      align: "left",
      key: "name",
    },
    {
      title: t("table:code"),
      dataIndex: "code",
      align: "left",
      key: "code",
    },
    {
      title: t("table:country"),
      dataIndex: "",
      align: "left",
      key: "country",
      render: (record) => {
        return <Text>{getCountry(_.snakeCase(record?.country))}</Text>;
      },
    },
    {
      title: t("table:state"),
      dataIndex: "",
      key: "state",
      align: "left",
      render: (record) => {
        return (
          <Text>
            {getState(_.snakeCase(record?.state), _.snakeCase(record?.country))}
          </Text>
        );
      },
    },
    {
      title: t("table:district"),
      dataIndex: "",
      key: "district",
      align: "left",
      render: (record) => {
        return <Text>{getDistrict(_.snakeCase(record?.district))}</Text>;
      },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span style={{}}>
            <Space>
              <Typography.Link
                style={{
                  marginLeft: 5,
                }}
                onClick={() =>
                  navigateWithProps(
                    `/admin/admin-operators/${record?.id}`,
                    record?.name
                  )
                }
              >
                <Space>
                  <Tooltip title="Operators">
                    <GrUserSettings />
                  </Tooltip>
                </Space>
              </Typography.Link>
              <Typography.Link
                style={{
                  marginLeft: 5,
                }}
                onClick={() =>
                  navigateWithProps(
                    `/admin/admin-franchise-user/${record?.id}`,
                    record?.name
                  )
                }
              >
                <Space>
                  <Tooltip title="Franchise User">
                    <AiOutlineUser />
                  </Tooltip>
                </Space>
              </Typography.Link>
              <Typography.Link
                style={{
                  marginLeft: 5,
                }}
                onClick={() => handleAddPromo(record)}
              >
                <Space>
                  <Tooltip title="Promo Code">
                    <FileAddOutlined />
                  </Tooltip>
                </Space>
              </Typography.Link>
              <Typography.Link
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                }}
                onClick={() => handleEdit(record)}
              >
                <Space>
                  <Tooltip title="Edit">
                    <EditOutlined />
                  </Tooltip>
                </Space>
              </Typography.Link>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record)}
              >
                <Space>
                  <Tooltip title="Delete">
                    <DeleteOutlined />
                  </Tooltip>
                </Space>
              </Popconfirm>
            </Space>
          </span>
        );
      },
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_franchise"),
      colorScheme: "danger.700",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box width="100%" mt="20px" marginBottom="20px">
        <Box position="sticky" top="0px" zIndex="2" bg="white">
          <Text fontSize="md" fontWeight="bold">
            {t("Franchises")}
          </Text>
        </Box>

        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            {header_actions.map((headerAction, index) => (
              <Box key={`header-action-${index}`}>
                <ActionButton {...headerAction} />
              </Box>
            ))}
          </HStack>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={get_all_franchises}
              pagination={false}
              loading={loading}
            />
          </Wrapper>
          <Pagination
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </VStack>
      </Box>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("table:add_franchise")
            : t("table:update_franchise")
        }
        component={
          <AdminFranchiseDetails
            isOpen={modalOpen}
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
      />
    </>
  );
};
export default AdminFranchiseList;

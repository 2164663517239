import { retrieveItem } from "@helpers/storage";
import {
  getInterestType,
  getRequestDescription,
  showButtonInrequest,
} from "@helpers/timeline_functions";
import {
  accept_request_mutation,
  dynamicClear,
  dynamicRequest,
  reject_request_mutation,
  send_request_mutation,
  useDynamicSelector,
} from "@services/redux";
import { Button, Drawer, Popconfirm, Spin } from "antd";
import moment from "moment";
import {
  Box,
  Hidden,
  HStack,
  Pressable,
  Spinner,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { AiFillHeart, AiOutlineMenu } from "react-icons/ai";
import { BsImage } from "react-icons/bs";
import { FaMobileAlt } from "react-icons/fa";
import { IoMdPlanet } from "react-icons/io";
import { useDispatch } from "react-redux";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import UserRequestGroup from "./user_request_group";
import "../../../index.css";
import { navigate } from "@helpers/navigator";
import { LastSeen, LastSeenNotification } from "@helpers/calculate_age";
import { useParams } from "react-router-dom";

const UserRequestThread = (props) => {
  const { user_requests, getUserRequests, request_group_id } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request_id } = useParams();

  const [responsiveOpen, setResponsiveOpen] = useState(false);
  const [_user_id, setUserID] = useState("");

  const [user_request_list, setUserRequestList] = useState([]);

  const { loading: user_requests_loading } =
    useDynamicSelector("user_requests");

  const { status: send_request_status } = useDynamicSelector("send_request");
  const { status: accept_status } = useDynamicSelector("accept_request");

  const { status: reject_status } = useDynamicSelector("reject_request");

  const showResponsiveDrawer = () => {
    setResponsiveOpen(true);
  };

  const onResponsiveClose = () => {
    setResponsiveOpen(false);
  };

  const acceptRequest = (request_id) => {
    let accept_request_key = [{ key: "accept_request", loading: true }];
    let accept_request_query = accept_request_mutation;
    let accept_request_variables = { request_id: request_id };
    dispatch(
      dynamicRequest(
        accept_request_key,
        accept_request_query,
        accept_request_variables
      )
    );
  };

  const rejectRequest = (request_id) => {
    let reject_request_key = [{ key: "reject_request", loading: true }];
    let reject_request_query = reject_request_mutation;
    let reject_request_variables = { request_id: request_id };
    dispatch(
      dynamicRequest(
        reject_request_key,
        reject_request_query,
        reject_request_variables
      )
    );
  };

  const sendRequest = (member_id, type) => {
    let send_photo_request_key = [{ key: "send_request", loading: true }];
    let send_photo_request_query = send_request_mutation;
    let send_photo_request_variables = {
      member_id: member_id,
      type: type,
    };

    dispatch(
      dynamicRequest(
        send_photo_request_key,
        send_photo_request_query,
        send_photo_request_variables
      )
    );
  };
  useEffect(() => {
    let login_user_id = retrieveItem("user_id");
    setUserID(login_user_id);
  }, []);

  useEffect(() => {
    if (accept_status === "success") {
      toast.success(t("request_accepted"));
      getUserRequests(request_group_id);
      dispatch(dynamicClear("accept_request"));
    }
    if (reject_status === "success") {
      toast.success(t("request_rejected"));
      getUserRequests(request_group_id);

      dispatch(dynamicClear("reject_request"));
    }
    if (send_request_status === "success") {
      toast.success(t("reminder_sent"));
      getUserRequests(request_group_id);

      dispatch(dynamicClear("send_request"));
    }
    if (send_request_status === "failure") {
      toast.error(t("Request already sent"));

      dispatch(dynamicClear("send_request"));
    }
  }, [accept_status, reject_status, send_request_status]);

  useEffect(() => {
    if (user_requests) {
      let time_line_format = [];
      user_requests?.map((a, _index) => {
        let obj = {
          time: a.requested_datetime,
          title: getInterestType(a.type),
          description: getRequestDescription(t, a, "request", _user_id),
          icon:
            a.type === "photo" ? (
              <BsImage color="white" />
            ) : a.type === "add_photo" ? (
              <BsImage color="white" />
            ) : a.type === "horoscope" ? (
              <IoMdPlanet color="white" />
            ) : a.type === "contact" ? (
              <FaMobileAlt color="white" />
            ) : (
              <AiFillHeart color="white" />
            ),
          button_type: showButtonInrequest(a, _user_id),
          data: a,
        };
        time_line_format.push(obj);

        if (a.reminder_datetime) {
          let reminder_obj = {
            time: a.reminder_datetime,
            title: getInterestType(a.type),
            description: getRequestDescription(t, a, "reminder", _user_id),
            icon:
              a.type === "photo" ? (
                <BsImage color="white" />
              ) : a.type === "horoscope" ? (
                <IoMdPlanet color="white" />
              ) : a.type === "contact" ? (
                <FaMobileAlt color="white" />
              ) : (
                <AiFillHeart color="white" />
              ),
            button_type: showButtonInrequest(a, _user_id),
            data: a,
          };
          time_line_format.push(reminder_obj);
        }

        if (a.accepted_datetime) {
          let accepted_obj = {
            time: a.accepted_datetime,
            title: getInterestType(a.type),
            description: getRequestDescription(t, a, "accepted", _user_id),
            icon:
              a.type === "photo" ? (
                <BsImage color="white" />
              ) : a.type === "horoscope" ? (
                <IoMdPlanet color="white" />
              ) : a.type === "contact" ? (
                <FaMobileAlt color="white" />
              ) : (
                <AiFillHeart color="white" />
              ),
            button_type: showButtonInrequest(a, _user_id),
            data: a,
          };
          time_line_format.push(accepted_obj);
        }

        if (a.rejected_datetime) {
          let rejected_obj = {
            time: a.rejected_datetime,
            title: getInterestType(a.type),
            description: getRequestDescription(t, a, "rejected", _user_id),
            icon:
              a.type === "photo" ? (
                <BsImage color="white" />
              ) : a.type === "horoscope" ? (
                <IoMdPlanet color="white" />
              ) : a.type === "contact" ? (
                <FaMobileAlt color="white" />
              ) : (
                <AiFillHeart color="white" />
              ),
            button_type: showButtonInrequest(a, _user_id),
            data: a,
          };

          time_line_format.push(rejected_obj);
        }
      });
      time_line_format.sort((a, b) =>
        a.time < b.time ? 1 : b.time < a.time ? -1 : 0
      );
      setUserRequestList(time_line_format);
    }
  }, [user_requests]);

  const renderButton = (request) => {
    if (request?.button_type === "acceptAndReject")
      return (
        <HStack mt={2} space={2}>
          <Popconfirm
            title="Accept request"
            onConfirm={() => {
              acceptRequest(request?.data?.id);
            }}
          >
            <Button
              style={{
                background: "#4096ff",
                borderColor: "#fff",
              }}
              type="primary"
            >
              {t("accept")}
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Reject request"
            onConfirm={() => {
              rejectRequest(request?.data?.id);
            }}
          >
            <Button
              className="ant-btn ant-btn-primary ant-btn-dangerous"
              danger
              style={{
                background: "#ff4d4f",
              }}
              type="primary"
              // onClick={() => {
              //   rejectRequest(request?.id);
              // }}
            >
              {t("reject")}
            </Button>
          </Popconfirm>
        </HStack>
      );

    if (request?.button_type === "reminder")
      return (
        <Box
          flexDirection={"row"}
          justifyContent={"space-evenly"}
          marginTop={"10px"}
        >
          <Pressable
            style={{
              backgroundColor: "#712e5a",
              paddingHorizontal: 25,
              borderRadius: 5,
              paddingVertical: 6,
            }}
            onPress={() => {
              sendRequest(
                user_requests?.[0]?.member?.id === _user_id
                  ? user_requests?.[0]?.user?.id
                  : user_requests?.[0]?.member?.id,
                "interest"
              );
            }}
          >
            <Box>
              <Text color={"white"} fontSize={17}>
                Send Reminder
              </Text>
            </Box>
          </Pressable>
        </Box>
      );
  };

  return (
    <>
      {user_requests_loading ? (
        <Box
          flex={1}
          alignItems={"center"}
          justifyContent={"center"}
          backgroundColor={"#fef5fb"}
          // backgroundColor={"#ff00ff"}
        >
          <Spin tip="Loading" size="large" />
        </Box>
      ) : (
        <Box backgroundColor={"#fef5fb"} flex={1}>
          <Hidden from="md">
            <Box
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"#fef5fb"}
              position={"fixed"}
              shadow={1}
              height={30}
              zIndex={3}
            >
              <HStack alignItems={"center"}>
                <Pressable
                  onPress={() => {
                    showResponsiveDrawer();
                  }}
                  style={{
                    position: "fixed",
                    left: 20,
                    alignItems: "center",
                  }}
                >
                  <AiOutlineMenu color="#712e5a" size={20} />
                </Pressable>
                <Pressable
                  onPress={() => {
                    user_requests?.[0]?.member?.id === _user_id
                      ? navigate(`/member/${user_requests?.[0]?.user?.id}`)
                      : navigate(`/member/${user_requests?.[0]?.member?.id}`);
                  }}
                >
                  {user_requests?.[0]?.member?.id === _user_id ? (
                    <Text bold color={"#712e5a"}>
                      {user_requests?.[0]?.user?.member_profile?.name}
                    </Text>
                  ) : (
                    <Text bold color={"#712e5a"}>
                      {user_requests?.[0]?.member?.member_profile.name}
                    </Text>
                  )}
                </Pressable>
              </HStack>
            </Box>
          </Hidden>
          {user_requests?.[0]?.member?.id && (
            <Hidden till="md">
              <Box
                width={{ base: "calc(100% - 300px)", md: "calc(100% - 250px)" }}
                justifyContent={"center"}
                alignItems={"center"}
                backgroundColor={"#fef5fb"}
                position={"fixed"}
                shadow={1}
                height={30}
                zIndex={3}
              >
                <Pressable
                  onPress={() => {
                    user_requests?.[0]?.member?.id === _user_id
                      ? navigate(`/member/${user_requests?.[0]?.user?.id}`)
                      : navigate(`/member/${user_requests?.[0]?.member?.id}`);
                  }}
                >
                  {user_requests?.[0]?.member?.id === _user_id ? (
                    <Text bold color={"#712e5a"}>
                      {user_requests?.[0]?.user?.member_profile?.name}
                    </Text>
                  ) : (
                    <Text bold color={"#712e5a"}>
                      {user_requests?.[0]?.member?.member_profile.name}
                    </Text>
                  )}
                </Pressable>
              </Box>
            </Hidden>
          )}

          <Box my={10}>
            <VerticalTimeline layout={"1-column-left"} animate={true}>
              {user_request_list?.map((request, _ind) => {
                return (
                  <VerticalTimelineElement
                    key={_ind}
                    dateClassName="date-pos"
                    className="vertical-timeline-element--work"
                    contentStyle={{
                      background: "#fff",
                    }}
                    contentArrowStyle={{
                      width: "50%",
                      borderRight: "7px solid #e8e8e8",
                    }}
                    date={LastSeenNotification(request.time)}
                    iconStyle={{ background: "#059e05", color: "#fff" }}
                    // icon={<BsImage color="white" />}
                    icon={request?.icon}
                  >
                    <Box>
                      <VStack>
                        <HStack>
                          <Text fontFamily={"Roboto"} bold>
                            {/* {t("photo_request")} */}
                            {request?.title}
                          </Text>
                        </HStack>

                        <Text py={2} fontFamily={"Roboto"}>
                          {request?.description}
                        </Text>

                        {renderButton(request)}
                      </VStack>
                    </Box>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          </Box>

          <Hidden from="md">
            <Drawer
              className="ant-drawer-close anticon anticon-close ant-drawer-header-title"
              headerStyle={{
                backgroundColor: "#712e5a",
                textDecorationColor: "#fff",
                height: 40,
              }}
              bodyStyle={{ backgroundColor: "#712e5a" }}
              title={"Requests"}
              placement="left"
              onClose={onResponsiveClose}
              open={responsiveOpen}
            >
              <Box>
                <UserRequestGroup
                  onResponsiveClose={onResponsiveClose}
                  getUserRequests={getUserRequests}
                />
              </Box>
            </Drawer>
          </Hidden>
        </Box>
      )}
    </>
  );
};
export default UserRequestThread;

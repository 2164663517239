import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
} from "antd";
import { useDispatch } from "react-redux";
import "../../../index.css";
import { getImageUrl } from "@helpers/get_profile_url";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";

import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import "react-image-crop/dist/ReactCrop.css";
import {
  reject_verification_mutation,
  approve_verification_mutation,
  dynamicRequest,
  useDynamicSelector,
  get_verifications_query,
  get_approve_query,
  get_reject_query,
  get_approve_photo_query,
  get_reject_photo_query,
  dynamicClear,
  update_pending_reason,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  HStack,
  Pressable,
  Text,
  Image as _Image,
  Divider,
  Alert,
  Spinner,
  Center,
} from "native-base";
import { Image } from "antd";
import { showNotification } from "@helpers/notify";
import colors from "@helpers/colors";
import moment from "moment";
import { AiFillEdit, AiFillInfoCircle } from "react-icons/ai";

const AdminCropPhotoVerification = (props) => {
  const { t } = useTranslation();
  const { TextArea } = Input;

  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const [is_approve, setIsApprove] = useState(false);
  const [show_update_reason_modal, set_show_update_reason_modal] =
    useState(false);

  const [is_photo_verification, setIsPhotoVerification] = useState(false);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [active_id, setActiveId] = useState("");
  const [active_url, setActiveUrl] = useState("");
  const [pending_reason, set_pending_reason] = useState("");

  const [types, setTypes] = useState([]);
  const [name, setName] = useState(false);
  const [employer, setEmployer] = useState(false);
  const [institution, setInstitution] = useState(false);
  const [about_family, setAboutFamily] = useState(false);
  const [gothram, setGothram] = useState(false);
  const [aspect, setAspect] = useState(1 / 1);

  let temp_arr = [];

  const [image_preview, setImagePreview] = useState();
  const [current_limit, setCurrentLimit] = useState(10);
  const [hasError, setHasError] = useState(false);

  const {
    status: approveStatus,
    error: createError,
    loading: approve_loading,
  } = useDynamicSelector("approveText");
  const {
    status: rejectStatus,
    error: updateError,
    loading: reject_loading,
  } = useDynamicSelector("rejectText");
  const {
    status: update_verification_pending_reason_status,
    error: update_verification_pending_reason_error,
    loading: update_verification_pending_reason_loading,
  } = useDynamicSelector("updateVerificationPendingReason");
  const {
    status: approve_photo_Status,
    error: approve_photo_error,
    loading: approve_photo_loading,
  } = useDynamicSelector("approvePhoto");
  const {
    status: reject_photo_Status,
    error: reject_photo_error,
    loading: reject_photo_loading,
  } = useDynamicSelector("rejectPhoto");

  const {
    items: verification,
    loading,
    pagination,
  } = useDynamicSelector("verification");

  const getAllUnverifiedMembersList = (search_string) => {
    let keys = [{ key: "verification", loading: true }];
    let variables = {
      search_string: search_string ? search_string : "",
      page_number: current,
      page_limit: 10,
    };
    dispatch(dynamicRequest(keys, get_verifications_query, variables));
  };
  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "verification", loading: true }];
    dispatch(
      dynamicRequest(keys, get_verifications_query, {
        page_number: page_number,
        page_limit: page_limit,
      })
    );
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };
  useEffect(() => {
    let keys = [{ key: "verification", loading: true }];
    dispatch(
      dynamicRequest(keys, get_verifications_query, {
        page_number: current,
        page_limit: current_limit,
      })
    );
  }, []);

  const handleSecondaryModal = () => {
    setIsApprove(false);
    setCompletedCrop("");
  };
  const handle_reason_modal_open = (reason) => {
    set_show_update_reason_modal(true);
    set_pending_reason(reason);
  };
  const handle_reason_modal_close = () => {
    set_show_update_reason_modal(false);
  };
  const handleConfirmationApprove = () => {
    handlePhotoApprove(image_preview?.id);
  };
  const handleModalCancel = () => {
    setIsPhotoVerification(false);
    setCrop(null);
    setCompletedCrop("");
  };
  const handleOk = () => {
    setIsPhotoVerification(false);
    setCrop(null);
  };

  const handleApprove = (record) => {
    let keys = [{ key: "approveText", loading: true }];
    let variables = {
      user_id: record,
      types: types,
    };
    dispatch(dynamicRequest(keys, get_approve_query, variables, "M"));
  };

  const handle_reason_update = () => {
    let keys = [{ key: "updateVerificationPendingReason", loading: true }];
    let variables = {
      user_id: active_id,
      verification_pending_reason: pending_reason,
    };
    dispatch(dynamicRequest(keys, update_pending_reason, variables, "M"));
  };
  const handlePhotoApprove = (photo_id) => {
    let keys = [{ key: "approvePhoto", loading: true }];
    let variables = {
      photo_id: photo_id,
      left: completedCrop?.x || 0,
      top: completedCrop?.y || 0,
      width: completedCrop?.width || 0,
      height: completedCrop?.height || 0,
    };
    dispatch(dynamicRequest(keys, get_approve_photo_query, variables, "M"));
  };
  const handlePhotoReject = (photo_id) => {
    let keys = [{ key: "rejectPhoto", loading: true }];
    let variables = {
      photo_id: photo_id,
    };
    dispatch(dynamicRequest(keys, get_reject_photo_query, variables, "M"));
  };
  const handleReject = (user_id) => {
    let keys = [{ key: "rejectText", loading: true }];
    let variables = {
      user_id: user_id,
      types: types,
    };
    dispatch(dynamicRequest(keys, get_reject_query, variables, "M"));
  };

  useEffect(() => {
    if (approveStatus === "success") {
      showNotification({
        type: "success",
        message: `Approved successfully`,
      });

      setTypes([]);
      setName(false);
      setInstitution(false);
      setEmployer(false);
      setGothram(false);
      let keys = [{ key: "verification", loading: true }];
      dispatch(
        dynamicRequest(keys, get_verifications_query, {
          page_number: current,
          page_limit: current_limit,
        })
      );

      dispatch(dynamicClear("approveText"));
    } else if (rejectStatus === "success") {
      showNotification({
        type: "success",
        message: `Rejected successfully`,
      });
      setTypes([]);
      setName(false);
      setInstitution(false);
      setEmployer(false);
      setGothram(false);
      let keys = [{ key: "verification", loading: true }];
      dispatch(
        dynamicRequest(keys, get_verifications_query, {
          page_number: current,
          page_limit: current_limit,
        })
      );

      dispatch(dynamicClear("rejectText"));
    }
  }, [approveStatus, rejectStatus]);
  useEffect(() => {
    if (approve_photo_Status === "success") {
      showNotification({
        type: "success",
        message: `Approved successfully`,
      });
      setIsPhotoVerification(false);
      setIsApprove(false);

      let keys = [{ key: "verification", loading: true }];
      dispatch(
        dynamicRequest(keys, get_verifications_query, {
          page_number: current,
          page_limit: current_limit,
        })
      );

      dispatch(dynamicClear("approvePhoto"));
    } else if (reject_photo_Status === "success") {
      showNotification({
        type: "success",
        message: `Rejected successfully`,
      });
      setIsPhotoVerification(false);
      setIsApprove(false);

      let keys = [{ key: "verification", loading: true }];
      dispatch(
        dynamicRequest(keys, get_verifications_query, {
          page_number: current,
          page_limit: current_limit,
        })
      );

      dispatch(dynamicClear("rejectPhoto"));
    }
  }, [approve_photo_Status, reject_photo_Status]);

  useEffect(() => {
    if (update_verification_pending_reason_status === "success") {
      showNotification({
        type: "success",
        message: `Reason updated successfully`,
      });
      handle_reason_modal_close();
      let keys = [{ key: "verification", loading: true }];
      dispatch(
        dynamicRequest(keys, get_verifications_query, {
          page_number: current,
          page_limit: current_limit,
        })
      );
      dispatch(dynamicClear("updateVerificationPendingReason"));
    } else if (update_verification_pending_reason_error) {
      showNotification({
        type: "error",
        message: update_verification_pending_reason_error?.message,
      });
      handle_reason_modal_close();

      dispatch(dynamicClear("updateVerificationPendingReason"));
    }
  }, [
    update_verification_pending_reason_status,
    update_verification_pending_reason_error,
  ]);

  useEffect(() => {
    if (name) {
      temp_arr.push("name");
    }
    if (gothram) {
      temp_arr.push("gothram");
    }
    if (institution) {
      temp_arr.push("institution");
    }
    if (about_family) {
      temp_arr.push("about_family");
    }
    if (employer) {
      temp_arr.push("employer");
    }
    setTypes(temp_arr);
  }, [name, gothram, institution, employer, about_family]);

  const handleMemberSearch = (e) => {
    if (e.target.value?.length > 3) {
      getAllUnverifiedMembersList(e.target.value?.trim());
    } else if (e.target.value?.length === 0) {
      getAllUnverifiedMembersList();
    }
  };
  const handleEnterSearch = (e) => {
    getAllUnverifiedMembersList(e.target.value?.trim());
  };
  const placeholderImage =
    "https://blr1.vultrobjects.com/assets/error_photo.jpg";
  const onImageError = (e) => {
    e.target.src = placeholderImage;
  };
  return (
    <>
      <Text fontSize={18} fontWeight="bold">
        {"Verification"}
      </Text>
      <Box alignItems={"flex-end"}>
        <Input
          placeholder="Search Member"
          onChange={handleMemberSearch}
          suffix={<SearchOutlined />}
          allowClear
          style={{ width: 200 }}
          onPressEnter={handleEnterSearch}
        />
      </Box>
      {loading ? (
        <Box alignItems={"center"} justifyContent={"center"} height={700}>
          <Spinner size={"lg"} color={"#712e5a"} />
        </Box>
      ) : (
        <Box>
          {verification?.length > 0 ? (
            verification?.map((x) => {
              return (
                <Card my={2}>
                  <HStack>
                    <Box width={"70%"}>
                      <Box width={"100%"}>
                        <HStack alignItems={"center"}>
                          <Box width={"5%"}></Box>

                          <Text width={"30%"} bold fontSize={18}>
                            {t("franchise")}
                          </Text>
                          <Text width={"10%"} bold fontSize={18}>
                            :
                          </Text>

                          <Text mx={2} fontSize={18}>
                            {x.franchise_name || "-"}
                          </Text>
                        </HStack>
                      </Box>
                      <Box width={"100%"}>
                        <HStack alignItems={"center"}>
                          <Box width={"5%"}></Box>

                          <Text width={"30%"} bold fontSize={18}>
                            {t("pincode")}
                          </Text>
                          <Text width={"10%"} bold fontSize={18}>
                            :
                          </Text>

                          <Text mx={2} fontSize={18}>
                            {x.pincode || "-"}
                          </Text>
                        </HStack>
                      </Box>
                      <Box width={"100%"}>
                        <HStack alignItems={"center"}>
                          <Box width={"5%"}></Box>

                          <Text width={"30%"} bold fontSize={18}>
                            {t("promocode")}
                          </Text>
                          <Text width={"10%"} bold fontSize={18}>
                            :
                          </Text>

                          <Text mx={2} fontSize={18}>
                            {x.promocode || "-"}
                          </Text>
                        </HStack>
                      </Box>
                      <Box width={"100%"}>
                        <HStack alignItems={"center"}>
                          <Box width={"5%"}></Box>

                          <Text width={"30%"} bold fontSize={18}>
                            {t("profile_created_at")}
                          </Text>
                          <Text width={"10%"} bold fontSize={18}>
                            :
                          </Text>

                          <Text mx={2} fontSize={18}>
                            {moment(x.created_at).format(
                              "DD/MM/YYYY hh:mm a"
                            ) || "-"}
                          </Text>
                        </HStack>
                      </Box>
                    </Box>

                    <Box
                      style={{
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <HStack width={"100%"} alignItems={"center"}>
                        <Box width={"80%"}>
                          <Text
                            style={{
                              width: "100%",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            {"Reason"}
                          </Text>
                        </Box>
                        <Box alignItems={"flex-end"} width={"20%"}>
                          <Pressable
                            onPress={() => {
                              handle_reason_modal_open(
                                x?.profile?.verification_pending_reason
                              );
                              setActiveId(x.id);
                            }}
                          >
                            <AiFillEdit color="#712e5a" size={25} />
                          </Pressable>
                        </Box>
                      </HStack>
                      <Divider />
                      <Box width={"100%"} alignItems={"flex-start"}>
                        <TextArea
                          value={x?.profile?.verification_pending_reason}
                          disabled
                        />
                      </Box>
                    </Box>
                  </HStack>

                  <HStack alignItems={"center"}>
                    <Box width={"70%"}>
                      <HStack alignItems={"center"}>
                        {x.name_verification_status === "pending" ? (
                          <Box width={"5%"}>
                            <Checkbox
                              value={name && active_id === x.id}
                              onChange={() => {
                                setActiveId(x.id);
                                if (name) {
                                  setName(false);
                                } else {
                                  setName(true);
                                }
                              }}
                            />
                          </Box>
                        ) : (
                          <Box width={"5%"}></Box>
                        )}
                        <Text width={"30%"} bold fontSize={18}>
                          {t("name")}
                        </Text>
                        <Text width={"10%"} bold fontSize={18}>
                          :
                        </Text>

                        <Text mx={2} fontSize={18}>
                          {`${x.name} ( ${x?.profile?.profile_id} )`}
                        </Text>
                      </HStack>
                      <HStack alignItems={"center"}>
                        <Box width={"5%"}></Box>

                        <Text width={"30%"} bold fontSize={18}>
                          {t("gender")}
                        </Text>
                        <Text width={"10%"} bold fontSize={18}>
                          :
                        </Text>

                        <Text mx={2} fontSize={18}>
                          {x.gender === "m" ? "Male" : "Female"}
                        </Text>
                      </HStack>
                      {x.gothram_verification_status === "pending" && (
                        <HStack>
                          <Box width={"5%"}>
                            <Checkbox
                              value={gothram && active_id === x.id}
                              onChange={() => {
                                setActiveId(x.id);
                                if (gothram) {
                                  setGothram(false);
                                } else {
                                  setGothram(true);
                                }
                              }}
                            />
                          </Box>
                          <Text width={"30%"} bold fontSize={18}>
                            {t("gothram")}
                          </Text>
                          <Text width={"10%"} bold fontSize={18}>
                            :
                          </Text>

                          <Text mx={2} fontSize={18}>
                            {x.gothram}
                          </Text>
                        </HStack>
                      )}
                      {x.employer_verification_status === "pending" && (
                        <HStack>
                          <Box width={"5%"}>
                            <Checkbox
                              value={employer && active_id === x.id}
                              onChange={() => {
                                setActiveId(x.id);
                                if (employer) {
                                  setEmployer(false);
                                } else {
                                  setEmployer(true);
                                }
                              }}
                            />
                          </Box>
                          <Text width={"30%"} bold fontSize={18}>
                            {t("employer")}
                          </Text>
                          <Text width={"10%"} bold fontSize={18}>
                            :
                          </Text>

                          <Text mx={2} fontSize={18}>
                            {x.employer}
                          </Text>
                        </HStack>
                      )}
                      {x.institution_verification_status === "pending" && (
                        <HStack>
                          <Box width={"5%"}>
                            <Checkbox
                              value={institution && active_id === x.id}
                              onChange={() => {
                                setActiveId(x.id);
                                if (institution) {
                                  setInstitution(false);
                                } else {
                                  setInstitution(true);
                                }
                              }}
                            />
                          </Box>
                          <Text width={"30%"} bold fontSize={18}>
                            {t("institution")}
                          </Text>
                          <Text width={"10%"} bold fontSize={18}>
                            :
                          </Text>

                          <Text mx={2} fontSize={18}>
                            {x.institution}
                          </Text>
                        </HStack>
                      )}
                      {x.about_family_verification_status === "pending" && (
                        <HStack>
                          <Box width={"5%"}>
                            <Checkbox
                              value={about_family && active_id === x.id}
                              onChange={() => {
                                setActiveId(x.id);
                                if (about_family) {
                                  setAboutFamily(false);
                                } else {
                                  setAboutFamily(true);
                                }
                              }}
                            />
                          </Box>
                          <Text width={"30%"} bold fontSize={18}>
                            {t("about_family")}
                          </Text>
                          <Text width={"10%"} bold fontSize={18}>
                            :
                          </Text>

                          <Text mx={2} fontSize={18}>
                            {x.about_family}
                          </Text>
                        </HStack>
                      )}
                    </Box>
                    <Box width={"30%"}>
                      <HStack alignItems={"center"} space={5}>
                        <Button
                          loading={approve_loading && active_id === x.id}
                          block
                          type="primary"
                          onClick={() => {
                            setActiveId(x.id);

                            // if (name) {
                            //   temp_arr.push(name);
                            // }
                            // if (gothram) {
                            //   temp_arr.push(gothram);
                            // }
                            // if (institution) {
                            //   temp_arr.push(institution);
                            // }
                            // if (employer) {
                            //   temp_arr.push(employer);
                            // }
                            // setTypes(temp_arr);
                            if (types?.length > 0) {
                              handleApprove(x?.id);
                            }
                          }}
                        >
                          {"Approve"}
                        </Button>
                        <Button
                          loading={reject_loading && active_id === x.id}
                          block
                          type="primary"
                          onClick={() => {
                            setActiveId(x.id);

                            handleReject(x?.id);
                          }}
                        >
                          {"Reject"}
                        </Button>
                      </HStack>
                    </Box>
                  </HStack>

                  {x?.user_photos?.length > 0 && <Divider my={5} />}
                  <HStack space={4}>
                    {x?.user_photos?.map((img, index) => {
                      return (
                        <Box height={20} width={20}>
                          <Pressable
                            onPress={() => {
                              setIsPhotoVerification(true);
                              setImagePreview(img);
                            }}
                          >
                            {/* <_Image
                            width={20}
                            height={20}
                            style={{
                              objectFit: "cover",
                              // zIndex: -999,
                            }}
                            src={getImageUrl(img.photo_url)}
                            preview={{ visible: false }}
                          /> */}

                            <img
                              style={{
                                width: 80,
                                height: 80,
                              }}
                              src={
                                getImageUrl(img.photo_url)
                                  ? getImageUrl(img.photo_url)
                                  : placeholderImage
                              }
                              alt="cover image"
                              onError={onImageError}
                            />

                            {/* <div style={{ display: "none" }}>
                        <Image.PreviewGroup
                          preview={{
                            visible,
                            onVisibleChange: (vis) => setVisible(vis),
                          }}
                        >
                          <Image
                            key={index}
                            onClick={() => {}}
                            style={{
                              objectFit: "cover",
                            }}
                            preview={{
                              src: getImageUrl(image_preview),
                            }}
                          />
                        </Image.PreviewGroup>
                      </div> */}
                          </Pressable>
                        </Box>
                      );
                    })}
                  </HStack>
                </Card>
              );
            })
          ) : (
            <Box flex={1} alignItems={"center"} justifyContent={"center"}>
              <Text fontSize={20} bold>
                {"No Profile"}
              </Text>
            </Box>
          )}
          <Box my={5}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              current={current}
              hideOnSinglePage={false}
              onChange={fetchData}
              // pageSizeOptions={[5, 10, 15, 20, 50, 100, 1000]}
            />
          </Box>

          <Modal
            title="Photo Verification"
            open={is_photo_verification}
            onOk={handleOk}
            onCancel={handleModalCancel}
            footer={[
              <Button
                key="approve"
                type="primary"
                loading={approve_photo_loading}
                onClick={() => {
                  if (completedCrop) {
                    handlePhotoApprove(image_preview?.id);
                  } else {
                    setIsApprove(true);
                  }
                }}
              >
                {/* <Popconfirm
              title="Do you want to approve this photo without crop?"
              onConfirm={() => {
                if (completedCrop) {
                  setCompletedCrop(null);
                } else {
                  alertMessage();
                }
              }}
            > */}
                Approve
                {/* </Popconfirm> */}
              </Button>,
              <Button
                key="reject"
                type="primary"
                // loading={loading}
                onClick={() => {
                  handlePhotoReject(image_preview?.id);
                }}
              >
                Reject
              </Button>,
            ]}
          >
            <Box alignItems={"center"}>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => {
                  setCrop(percentCrop);
                }}
                onComplete={(c) => {
                  setCompletedCrop(c);
                }}
                aspect={aspect}
              >
                {active_url === image_preview?.photo_url ? (
                  <Box
                    alignItems={"center"}
                    justifyContent={"center"}
                    backgroundColor={colors.gray}
                    width={20}
                    height={20}
                  >
                    <Text textAlign={"center"} color={"#111"}>
                      {"Invalid Image"}
                    </Text>
                  </Box>
                ) : (
                  <img
                    style={{ width: "auto", height: 300 }}
                    src={
                      getImageUrl(image_preview?.photo_url)
                        ? getImageUrl(image_preview?.photo_url)
                        : placeholderImage
                    }
                    alt="cover image"
                    onError={onImageError}
                  />
                )}
              </ReactCrop>
            </Box>
          </Modal>
          <Modal
            title="Confirmation"
            open={is_approve}
            onOk={handleConfirmationApprove}
            onCancel={handleSecondaryModal}
            confirmLoading={approve_photo_loading}
          >
            <Text>{"Do you want to approve this image without crop?"}</Text>
          </Modal>
          <Modal
            title="Pending Reason"
            open={show_update_reason_modal}
            onOk={handle_reason_update}
            onCancel={handle_reason_modal_close}
            confirmLoading={update_verification_pending_reason_loading}
            footer={false}
          >
            <Box alignItems={"center"}>
              <TextArea
                rows={4}
                onChange={(e) => {
                  set_pending_reason(e.target.value);
                }}
                value={pending_reason}
              />
              <Button
                loading={update_verification_pending_reason_loading}
                block
                type="primary"
                onClick={() => {
                  handle_reason_update();
                }}
                style={{
                  width: "50%",
                  marginTop: 10,
                }}
              >
                {"Update Pending Reason"}
              </Button>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
};
export default AdminCropPhotoVerification;

import React from "react";
import { Box, Text, HStack, Divider, Pressable, Skeleton } from "native-base";
import { AiFillStar } from "react-icons/ai";
import {
  FaChild,
  FaHeart,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaPrayingHands,
} from "react-icons/fa";
import MemberDetail from "./member_desc";
import {
  addMemberContactViewMutation,
  dynamicClear,
  dynamicRequest,
  getMember,
  getMyPlan,
  getMyProfile,
  send_request_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { backgroundColor } from "styled-system";
import { Modal, Popconfirm } from "antd";
import toast from "react-hot-toast";
import { CalculateAge, CalculateAgeForMember } from "@helpers/calculate_age";
import {
  BsCardList,
  BsFillFlagFill,
  BsJournalBookmarkFill,
  BsPersonFill,
} from "react-icons/bs";
import { GiBigDiamondRing, GiBodyHeight } from "react-icons/gi";
import {
  GrLanguage,
  GrLocationPin,
  GrMap,
  GrMapLocation,
} from "react-icons/gr";
import { ImLocation, ImSpoonKnife } from "react-icons/im";
import {
  MdFamilyRestroom,
  MdLanguage,
  MdOutlineWheelchairPickup,
  MdSmokingRooms,
  MdWork,
} from "react-icons/md";
import { BiDrink } from "react-icons/bi";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { HiCurrencyRupee, HiUserGroup } from "react-icons/hi";
import {
  FcBusinessman,
  FcBusinesswoman,
  FcDiploma2,
  FcManager,
} from "react-icons/fc";
import { IoMdSchool } from "react-icons/io";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import {
  getCountry,
  getNationality,
  getState,
  getCity,
  getDistrict,
} from "@helpers/get_country_state_city";
import { useState } from "react";
import { getMemberHoroscopeResponse } from "@helpers/member_cover_photo_response_function";
import SvgConverter from "./horoscope_chart";
import HoroscopeChart from "./horoscope_chart";
import colors from "@helpers/colors";
import { getAnnualIncome, getUserDetailsFromValue } from "@helpers/functions";
import { HeightOptions } from "@master_data/profile_json/height";
import { WeightOptions } from "@master_data/profile_json/weight";
import { MaritalStatusOptions } from "@master_data/common_json/marital_status";
import { BodyTypesOptions } from "@master_data/profile_json/body_type";
import { PhysicalStatusOptions } from "@master_data/common_json/physical_status";
import { MotherTongueOptions } from "@master_data/common_json/mother_tongue";
import { EatingHabitsOptions } from "@master_data/common_json/eating_habits";
import { DrinkingHabitsOptions } from "@master_data/common_json/drinking_habits";
import { SmokingHabitsOptions } from "@master_data/common_json/smoking_habits";
import { CasteOptions } from "@master_data/common_json/castes";
import { ReligionsOptions } from "@master_data/common_json/religions";
import { RaasiOptions } from "@master_data/profile_json/raasi";
import { StarsOptions } from "@master_data/common_json/stars";
import { DoshamsOptions } from "@master_data/common_json/doshams";
import { NationalityOptions } from "@master_data/profile_json/nationality";
import { EducationsOptions } from "@master_data/common_json/educations";
import { EmploymentTypesOptions } from "@master_data/common_json/employment_types";
import { FamilyTypeOptions } from "@master_data/profile_json/family_type";
import { FamilyStatusOptions } from "@master_data/profile_json/family_status";
import { FamilyValueOptions } from "@master_data/profile_json/family_value";
import { FathersOccupationOptions } from "@master_data/profile_json/fathers_occupations";
import { MothersOccupationOptions } from "@master_data/profile_json/mothers_occupation";
import { AnnualIncomeOptions } from "@master_data/profile_json/annual_income";
import { SubCastesOptions } from "@master_data/common_json/sub_castes";

const MemberCard = (props) => {
  const {
    item,
    _member_id,
    login_user_horoscope,
    member_requests_list,
    member_loading,
    cover_photo,
    login_user_current_plan,
    member_root,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [horoscope_response, setHoroscopeResponse] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [user_plan, setUserPlan] = useState([]);

  const showPlanModal = () => {
    setIsPlanModalOpen(true);
  };
  const handleOk = () => {
    addMemberContact();

    // setIsPlanModalOpen(false);
  };
  const handlePlanModalCancel = () => {
    setIsPlanModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { my_subscription: subscribed_plan, loading: plan_loading } =
    useDynamicSelector("my_plan");

  const { status: send_request_status, error: send_request_error } =
    useDynamicSelector("send_request");
  const { status: add_member_contact_status, error: add_member_contact_error } =
    useDynamicSelector("addMemberContactView");
  const { loading } = useDynamicSelector("member");

  const getMemberReCall = () => {
    let get_member_key = [{ key: "member", loading: false }];
    let get_member_query = getMember;
    let get_member_variables = { id: _member_id };
    dispatch(
      dynamicRequest(get_member_key, get_member_query, get_member_variables)
    );
  };
  const myPlan = () => {
    let get_plan_key = [{ key: "my_plan", loading: true }];
    let get_plan_query = getMyPlan;
    let get_plan_variables = null;
    dispatch(dynamicRequest(get_plan_key, get_plan_query, get_plan_variables));
  };

  const addMemberContact = () => {
    let key = [{ key: "addMemberContactView", loading: true }];
    let query = addMemberContactViewMutation;
    let variables = {
      id: _member_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const sendContactRequest = (_member_id, type) => {
    let send_contact_request_key = [{ key: "send_request", loading: true }];
    let send_contact_request_query = send_request_mutation;
    let send_contact_request_variables = {
      member_id: _member_id,
      type: type,
    };
    dispatch(
      dynamicRequest(
        send_contact_request_key,
        send_contact_request_query,
        send_contact_request_variables
      )
    );
  };

  useEffect(() => {
    if (subscribed_plan) {
      setUserPlan(subscribed_plan);
    }
  }, [subscribed_plan]);
  useEffect(() => {
    if (member_requests_list) {
      let request_horoscope = member_requests_list?.filter(
        (x) => x.type === "horoscope"
      );
      setHoroscopeResponse(request_horoscope);
    }
  }, [member_requests_list]);

  useEffect(() => {
    if (send_request_status === "success") {
      toast.success(t("request_sent"));
      dispatch(dynamicClear("send_request"));
    }
    if (send_request_status === "failure") {
      toast.error(t("Request already sent"));

      dispatch(dynamicClear("send_request"));
    }
    // if (send_request_error) {
    //   toast.error(send_request_error.message);
    //   dispatch(dynamicClear("send_request"));
    // }

    if (add_member_contact_status === "success") {
      setIsPlanModalOpen(false);
      getMemberReCall();
      dispatch(dynamicClear("addMemberContactView"));
    }
  }, [send_request_status, send_request_error, add_member_contact_status]);

  return (
    <>
      {loading ? (
        <Box mx={{ base: 5, sm: 2, xs: 2 }}>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
              paddingVertical: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <BsCardList size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={16} fontWeight={"bold"}>
                  {t("basic_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsPersonFill size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsPersonFill size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <GiBodyHeight size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaHeart size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <GiBigDiamondRing size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaHeart size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdOutlineWheelchairPickup size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdLanguage size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <ImSpoonKnife size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BiDrink size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdSmokingRooms size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaPhoneAlt size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
              paddingVertical: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <FaPrayingHands size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={16} fontWeight={"bold"}>
                  {t("religion_information")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsJournalBookmarkFill size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <AiFillStar size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <VscTypeHierarchySub size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaHeart size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcDiploma2 size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
              paddingVertical: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <FaMapMarkedAlt size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={16} fontWeight={"bold"}>
                  {t("location_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <ImLocation size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <ImLocation size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsFillFlagFill size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
              paddingVertical: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <MdWork size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={16} fontWeight={"bold"}>
                  {t("professional_information")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <IoMdSchool size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdWork size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <HiCurrencyRupee size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
              paddingVertical: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <MdFamilyRestroom size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={16} fontWeight={"bold"}>
                  {t("family_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <HiUserGroup size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinessman size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinesswoman size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinesswoman size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcManager size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinesswoman size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcManager size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box mx={{ base: 5, sm: 2, xs: 2 }}>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
              paddingVertical: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <BsCardList size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={16} fontWeight={"bold"}>
                  {t("basic_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            {item?.dob && (
              <MemberDetail
                icon={<BsPersonFill size={18} color={"#ab6c95"} />}
                text={CalculateAgeForMember(item?.dob)}
              />
            )}
            {item?.created_for?.en_label && (
              <MemberDetail
                icon={<BsPersonFill size={18} color={"#ab6c95"} />}
                text={`Profile created for ${item?.created_for?.toLowerCase()}`}
              />
            )}
            {item?.height && (
              <MemberDetail
                icon={<GiBodyHeight size={18} color={"#ab6c95"} />}
                text={getUserDetailsFromValue(HeightOptions, item?.height)}
              />
            )}
            {item?.weight && (
              <MemberDetail
                icon={<FaHeart size={18} color={"#ab6c95"} />}
                text={getUserDetailsFromValue(WeightOptions, item?.weight)}
              />
            )}
            {item?.marital_status && (
              <MemberDetail
                icon={<GiBigDiamondRing size={18} color={"#ab6c95"} />}
                text={getUserDetailsFromValue(
                  MaritalStatusOptions,
                  item?.marital_status
                )}
              />
            )}
            {item?.body_type && (
              <MemberDetail
                icon={<FaHeart size={18} color={"#ab6c95"} />}
                text={getUserDetailsFromValue(
                  BodyTypesOptions,
                  item?.body_type
                )}
              />
            )}
            {item?.physical_status && (
              <MemberDetail
                icon={<MdOutlineWheelchairPickup size={18} color={"#ab6c95"} />}
                text={getUserDetailsFromValue(
                  PhysicalStatusOptions,
                  item?.physical_status
                )}
              />
            )}
            {item?.mother_tongue && (
              <MemberDetail
                icon={<MdLanguage size={18} color={"#ab6c95"} />}
                text={`Mother tongue ${getUserDetailsFromValue(
                  MotherTongueOptions,
                  item?.mother_tongue
                )}`}
              />
            )}
            {item?.eating_habit && (
              <MemberDetail
                icon={<ImSpoonKnife size={18} color={"#ab6c95"} />}
                text={getUserDetailsFromValue(
                  EatingHabitsOptions,
                  item?.eating_habit
                )}
              />
            )}
            {item?.drinking_habit && (
              <MemberDetail
                icon={<BiDrink size={18} color={"#ab6c95"} />}
                text={
                  getUserDetailsFromValue(
                    DrinkingHabitsOptions,
                    item?.drinking_habit
                  ) === "No"
                    ? "Doesn't Drink"
                    : getUserDetailsFromValue(
                        DrinkingHabitsOptions,
                        item?.drinking_habit
                      ) === "Yes"
                    ? "Drinks Regularly"
                    : "Drinks Occasionally"
                }
              />
            )}

            {item?.smoking_habit && (
              <MemberDetail
                icon={<MdSmokingRooms size={18} color={"#ab6c95"} />}
                text={
                  getUserDetailsFromValue(
                    SmokingHabitsOptions,
                    item?.smoking_habit
                  ) === "No"
                    ? "Doesn't Smoke"
                    : getUserDetailsFromValue(
                        SmokingHabitsOptions,
                        item?.smoking_habit
                      ) === "Yes"
                    ? "Smokes Regularly"
                    : "Smokes Occasionally"
                }
              />
            )}
            {item?.mobile === "need_new_subscription" && (
              <Popconfirm
                title={"Subscribe plan to view mobile number"}
                onConfirm={() => {
                  navigate(ROUTES.USER_SUBSCRIPTION);
                }}
              >
                <Box my={2} cursor={"pointer"}>
                  <HStack alignItems={"center"}>
                    <MemberDetail
                      icon={<FaPhoneAlt size={18} color={"#ab6c95"} />}
                      text={``}
                    />
                    <Text
                      color={"#712e5a"}
                      bold
                    >{`Subscribe Plan To View Mobile Number`}</Text>
                  </HStack>
                </Box>
              </Popconfirm>
            )}
            {item?.mobile === "need_subscription" && (
              <Popconfirm
                title={"Subscribe plan to view mobile number"}
                onConfirm={() => {
                  navigate(ROUTES.USER_SUBSCRIPTION);
                }}
              >
                <Box my={2} cursor={"pointer"}>
                  <HStack alignItems={"center"}>
                    <MemberDetail
                      icon={<FaPhoneAlt size={18} color={"#ab6c95"} />}
                      text={``}
                    />
                    <Text
                      color={"#712e5a"}
                      bold
                    >{`Subscribe Plan To View Mobile Number`}</Text>
                  </HStack>
                </Box>
              </Popconfirm>
            )}
            {item?.mobile === "use_subscription" && (
              <Popconfirm
                title={"Use Plan to view mobile number"}
                onConfirm={() => {
                  addMemberContact();
                }}
              >
                <Box my={2} cursor={"pointer"}>
                  <HStack alignItems={"center"}>
                    <MemberDetail
                      icon={<FaPhoneAlt size={18} color={"#ab6c95"} />}
                      text={``}
                    />
                    <Text color={"#712e5a"} bold>{`View Mobile Number`}</Text>
                  </HStack>
                </Box>
              </Popconfirm>
            )}
            {item?.mobile === "hidden" && (
              <Popconfirm
                title={"Send request to view contact details"}
                onConfirm={() => {
                  sendContactRequest(_member_id, "contact");
                }}
              >
                <Box my={2} cursor={"pointer"}>
                  <HStack alignItems={"center"}>
                    <MemberDetail
                      icon={<FaPhoneAlt size={18} color={"#ab6c95"} />}
                      text={``}
                    />
                    <Text color={"#712e5a"} bold>{`-  XXXXXXXXXX`}</Text>
                  </HStack>
                </Box>
              </Popconfirm>
            )}
            {item?.mobile !== "hidden" &&
              item?.mobile !== "need_subscription" &&
              item?.mobile !== "need_new_subscription" &&
              item?.mobile !== "use_subscription" && (
                // <Popconfirm
                //   title={"View mobile number"}
                //   onConfirm={() => {
                //     // sendContactRequest(_member_id, "contact");
                //   }}
                // >
                <Box my={2} cursor={"pointer"}>
                  <MemberDetail
                    icon={<FaPhoneAlt size={18} color={"#ab6c95"} />}
                    text={`${item?.mobile}`}
                  />
                </Box>
                // </Popconfirm>
              )}
          </Box>

          <Box
            // key={i}
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
              paddingVertical: 5,
              //width: "40%",
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <FaPrayingHands size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={16} fontWeight={"bold"}>
                  {t("religion_information")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            {item?.religon && (
              <MemberDetail
                icon={<FaPrayingHands size={18} color={"#ab6c95"} />}
                text={getUserDetailsFromValue(ReligionsOptions, item?.religon)}
              />
            )}
            {item?.caste && item?.sub_caste && (
              <MemberDetail
                icon={<BsJournalBookmarkFill size={18} color={"#ab6c95"} />}
                text={`${getUserDetailsFromValue(
                  CasteOptions,
                  item?.caste
                )}, ${getUserDetailsFromValue(
                  SubCastesOptions,
                  item?.sub_caste
                )}`}
              />
            )}
            {item?.caste && !item?.sub_caste && (
              <MemberDetail
                icon={<BsJournalBookmarkFill size={18} color={"#ab6c95"} />}
                text={getUserDetailsFromValue(CasteOptions, item?.caste)}
              />
            )}
            {item?.raasi && !item?.star && (
              <MemberDetail
                icon={<AiFillStar size={18} color={"#ab6c95"} />}
                text={getUserDetailsFromValue(RaasiOptions, item?.raasi)}
              />
            )}
            {item?.star && item?.raasi && (
              <MemberDetail
                icon={<AiFillStar size={18} color={"#ab6c95"} />}
                text={`Star is ${getUserDetailsFromValue(
                  StarsOptions,
                  item?.star
                )}, Raasi is ${getUserDetailsFromValue(
                  RaasiOptions,
                  item?.raasi
                )}`}
              />
            )}
            {item?.gothram && (
              <MemberDetail
                icon={<VscTypeHierarchySub size={18} color={"#ab6c95"} />}
                text={item?.gothram}
              />
            )}
            {item?.doshams?.length > 0 && (
              <MemberDetail
                icon={<FaHeart size={18} color={"#ab6c95"} />}
                text={"multi"}
                items={item?.doshams}
                options={DoshamsOptions}
              />
            )}
            {login_user_horoscope?.birth_chart_url &&
              login_user_horoscope?.navamansha_chart_url &&
              login_user_current_plan && (
                <Pressable
                  onPress={() => {
                    showModal();
                  }}
                >
                  <MemberDetail
                    icon={<FcDiploma2 size={18} color={"#ab6c95"} />}
                    text={"View Horoscope"}
                  />
                </Pressable>
              )}
            {login_user_horoscope?.birth_chart_url &&
              login_user_horoscope?.navamansha_chart_url &&
              !login_user_current_plan && (
                <Popconfirm
                  title={"Subscribe plan to view horoscope"}
                  onConfirm={() => {
                    // sendContactRequest(_member_id, "horoscope");
                  }}
                >
                  <MemberDetail
                    icon={<FcDiploma2 size={18} color={"#ab6c95"} />}
                    text={"View Horoscope"}
                    // text={getMemberHoroscopeResponse(horoscope_response)}
                  />
                </Popconfirm>
              )}
          </Box>

          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
              paddingVertical: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <FaMapMarkedAlt size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={16} fontWeight={"bold"}>
                  {t("location_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            {item?.country && item?.state && item?.district && (
              <MemberDetail
                icon={<ImLocation size={18} color={"#ab6c95"} />}
                text={`Lives in ${getDistrict(item?.district)}, ${getState(
                  item?.state,
                  item?.country
                )}, ${getCountry(item?.country)}`}
              />
            )}

            {item?.ancestor_origin && (
              <MemberDetail
                icon={<ImLocation size={18} color={"#ab6c95"} />}
                text={`Ancestral Origin - ${getDistrict(
                  item?.ancestor_origin
                )}`}
              />
            )}
            {item?.citizenship && (
              <MemberDetail
                icon={<BsFillFlagFill size={18} color={"#ab6c95"} />}
                text={`${getNationality(
                  NationalityOptions,
                  item?.citizenship
                )} citizen`}
              />
            )}
          </Box>

          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
              paddingVertical: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <MdWork size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={16} fontWeight={"bold"}>
                  {t("professional_information")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            {item?.educations?.length > 0 && (
              <MemberDetail
                icon={<IoMdSchool size={18} color={"#ab6c95"} />}
                text={"multi"}
                items={item?.educations}
                options={EducationsOptions}

                // text={`${getUserDetailsFromValue(
                //   EducationsOptions,
                //   item?.education
                // )}`}
              />
            )}

            {item?.employment_type && (
              <MemberDetail
                icon={<MdWork size={18} color={"#ab6c95"} />}
                text={`Working in ${getUserDetailsFromValue(
                  EmploymentTypesOptions,
                  item?.employment_type
                )}`}
              />
            )}
            {Number(item?.annual_income_from) > -1 && (
              <MemberDetail
                icon={<HiCurrencyRupee size={18} color={"#ab6c95"} />}
                text={`Earns ${getAnnualIncome(
                  AnnualIncomeOptions,
                  Number(item.annual_income_from)
                )}`}
              />
            )}
          </Box>

          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 15,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
              paddingVertical: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <MdFamilyRestroom size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={16} fontWeight={"bold"}>
                  {t("family_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            {item?.family_type && item?.family_status && item?.family_value && (
              <MemberDetail
                icon={<HiUserGroup size={18} color={"#ab6c95"} />}
                text={`${getUserDetailsFromValue(
                  FamilyTypeOptions,
                  item?.family_type
                )}, ${getUserDetailsFromValue(
                  FamilyStatusOptions,
                  item?.family_status
                )} with ${getUserDetailsFromValue(
                  FamilyValueOptions,
                  item?.family_value
                )}`}
              />
            )}
            {item?.family_type &&
              !item?.family_status &&
              !item?.family_value && (
                <MemberDetail
                  icon={<HiUserGroup size={18} color={"#ab6c95"} />}
                  text={getUserDetailsFromValue(
                    FamilyTypeOptions,
                    item?.family_type
                  )}
                />
              )}
            {item?.family_status &&
              !item?.family_type &&
              !item?.family_value && (
                <MemberDetail
                  icon={<HiUserGroup size={18} color={"#ab6c95"} />}
                  text={getUserDetailsFromValue(
                    FamilyStatusOptions,
                    item?.family_status
                  )}
                />
              )}
            {item?.family_value &&
              !item?.family_type &&
              !item?.family_status && (
                <MemberDetail
                  icon={<HiUserGroup size={18} color={"#ab6c95"} />}
                  text={getUserDetailsFromValue(
                    FamilyValueOptions,
                    item?.family_value
                  )}
                />
              )}
            {item?.father_occupation && (
              <MemberDetail
                icon={<FcBusinessman size={18} color={"#ab6c95"} />}
                text={`Father - ${getUserDetailsFromValue(
                  FathersOccupationOptions,
                  item?.father_occupation
                )}`}
              />
            )}
            {item?.mother_occupation && (
              <MemberDetail
                icon={<FcBusinesswoman size={18} color={"#ab6c95"} />}
                text={`Mother - ${getUserDetailsFromValue(
                  MothersOccupationOptions,
                  item?.mother_occupation
                )}`}
              />
            )}
            {console.log("item", item)}
            {item?.number_of_children > 0 && (
              <MemberDetail
                icon={<FaChild size={18} color={colors.orange} />}
                text={
                  Number(item?.number_of_children) > 0
                    ? `${item?.number_of_children} Children`
                    : "No Children"
                }
              />
            )}
            {item?.number_of_brothers > -1 && (
              <MemberDetail
                icon={<FcManager size={18} color={"#ab6c95"} />}
                text={
                  Number(item?.number_of_brothers) > 0
                    ? `${item?.number_of_brothers} brother(s)`
                    : "No brothers"
                }
              />
            )}
            {item?.number_of_married_brothers > -1 && (
              <MemberDetail
                icon={<FcManager size={18} color={"#ab6c95"} />}
                text={
                  Number(item?.number_of_married_brothers) > 0
                    ? `${item?.number_of_married_brothers} brother(s) married`
                    : "No brother(s) married"
                }
              />
            )}
            {item?.number_of_sisters > -1 && (
              <MemberDetail
                icon={<FcBusinesswoman size={18} color={"#ab6c95"} />}
                text={
                  Number(item?.number_of_sisters) > 0
                    ? `${item?.number_of_sisters} sister(s)`
                    : "No sisters"
                }
              />
            )}

            {item?.number_of_married_sisters > -1 && (
              <MemberDetail
                icon={<FcBusinesswoman size={18} color={"#ab6c95"} />}
                text={
                  item?.number_of_married_sisters === 0
                    ? "No sister(s) married"
                    : `${item?.number_of_married_sisters} sister(s) married`
                }
              />
            )}
            {item?.about_family && (
              <MemberDetail
                icon={<HiUserGroup size={18} color={"#ab6c95"} />}
                text={`About Family and Expectations - ${item?.about_family}`}
              />
            )}
          </Box>
        </Box>
      )}
      <Modal
        width={800}
        title="Chart"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <HoroscopeChart
          login_user_horoscope={login_user_horoscope}
          item={item}
          cover_photo={cover_photo}
          member_root={member_root}
        />
      </Modal>
      <Modal
        title="View contact number"
        open={isPlanModalOpen}
        onOk={handleOk}
        onCancel={handlePlanModalCancel}
      >
        <Text>{"View mobile number"}</Text>
        {/* <p>{`Allowed contact details view - ${user_plan?.allowed_contact_details_view}`}</p> */}
        {/* <p>{`Viewed contact details count - ${user_plan?.viewed_contact_details_count}`}</p> */}
      </Modal>
    </>
  );
};
export default MemberCard;

import { gql } from "@apollo/client";

export const get_all_members_query = gql`
  query getMembers(
    $search_string: String
    $page_number: Float
    $page_limit: Float
    $sort_by: String
    $gender: String
    $caste: String
    $franchise_id: String
    $pincode: String
  ) {
    manage_members_list: getMembers(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      sort_by: $sort_by
      gender: $gender
      caste: $caste
      franchise_id: $franchise_id
      pincode: $pincode
    ) {
      items {
        id
        name
        gender
        profile_number
        mobile
        created
        dob
        sent_request_count
        received_request_count
        accepted_request_count
        app_used_count
        viewed_contacts_count
        pincode
        franchise_name
        promo_code
        profile_status
        time_of_birth
        place_of_birth
        created_for
        caste
        sub_caste
        religion
        district
        city
        education
        educations
        is_blocked_by_admin
        annual_income_from
        annual_income_to
        country
        state
        last_login_datetime
        subscription_status
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_members_for_report_query = gql`
  query getMembers(
    $search_string: String
    $page_number: Float
    $page_limit: Float
    $sort_by: String
  ) {
    manage_members_for_report: getMembers(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      sort_by: $sort_by
    ) {
      items {
        id
        name
        gender
        profile_number
        mobile
        last_login_datetime
        family_status
        created
        dob
        marital_status
        sent_request_count
        received_request_count
        accepted_request_count
        app_used_count
        viewed_contacts_count
        pincode
        franchise_name
        promo_code
        profile_status
        created_for
        caste
        sub_caste
        religion
        annual_income_from
        annual_income_to
        country
        state
        district
        city
        education
        educations
        sub_educations
        employment_type
        sub_employment_type
        is_blocked_by_admin
        subscription_from
        subscription_till
        subscription_status
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const update_member_mutation = gql`
  mutation updateMember(
    $id: String!
    $data: admin_member_profile_update_input
  ) {
    updateMember(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_member_mutation = gql`
  mutation deleteMember($id: String) {
    deleteMember(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_unverified_members = gql`
  query getMembers(
    $search_string: String
    $page_number: Float
    $page_limit: Float
    $sort_by: String
  ) {
    unverified_members_list: getMembers(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      approval_status: "pending"
      profile_status: "filled"
      sort_by: $sort_by
    ) {
      items {
        id
        name
        gender
        profile_number
        mobile
        created
        dob
        sent_request_count
        received_request_count
        accepted_request_count
        app_used_count
        viewed_contacts_count
        pincode
        franchise_name
        promo_code
        profile_status
        created_for
        caste
        sub_caste
        religion
        district
        city
        education
        educations
        is_blocked_by_admin
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const get_user_name = gql`
  query getMyUser {
    get_name: getMyUser {
      user_profile {
        name
      }
    }
  }
`;

export const get_matches_count_query = gql`
  query getMatchesCount {
    getMatchesCount {
      total_count
      error {
        status_code
        message
      }
    }
  }
`;

export const DistrictsOptions = [
  {
    name: "Nicobar",
    c_c: "IN",
    s_c: "AN",
  },
  {
    name: "North Middle Andaman",
    c_c: "IN",
    s_c: "AN",
  },
  {
    name: "South Andaman",
    c_c: "IN",
    s_c: "AN",
  },
  {
    name: "Anantapur",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Chittoor",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "East Godavari",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Alluri Sitarama Raju",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Anakapalli",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Annamaya",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Bapatla",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Eluru",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Guntur",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Kadapa",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Kakinada ",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Konaseema",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Krishna",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Kurnool",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Manyam",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "N T Rama Rao",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Nandyal",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Nellore",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Palnadu",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Prakasam",
    c_c: "IN",
    s_c: "AP",
  },

  {
    name: "Sri Balaji",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Sri Satya Sai",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Srikakulam",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Tirupati",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Visakhapatnam",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Vizianagaram",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "West Godavari",
    c_c: "IN",
    s_c: "AP",
  },
  {
    name: "Anjaw",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Central Siang",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Changlang",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Dibang Valley",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "East Kameng",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "East Siang",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Kamle",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Kra Daadi",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Kurung Kumey",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Lepa Rada",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Lohit",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Longding",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Lower Dibang Valley",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Lower Siang",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Lower Subansiri",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Namsai",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Pakke Kessang",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Papum Pare",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Shi Yomi",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Tawang",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Tirap",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Upper Siang",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Upper Subansiri",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "West Kameng",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "West Siang",
    c_c: "IN",
    s_c: "AR",
  },
  {
    name: "Baksa",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Barpeta",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Bongaigaon",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Cachar",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Charaideo",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Chirang",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Darrang",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Dhemaji",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Dhubri",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Dibrugarh",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Dima Hasao",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Goalpara",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Golaghat",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Hailakandi",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Jorhat",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Kamrup",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Kamrup Metropolitan",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Karbi Anglong",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Karimganj",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Kokrajhar",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Lakhimpur",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Majuli",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Morigaon",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Nagaon",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Nalbari",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Sivasagar",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Sonitpur",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "South Salmara-Mankachar",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Tinsukia",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Udalguri",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "West Karbi Anglong",
    c_c: "IN",
    s_c: "AS",
  },
  {
    name: "Araria",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Arwal",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Aurangabad",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Banka",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Begusarai",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Bhagalpur",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Bhojpur",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Buxar",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Darbhanga",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "East Champaran",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Gaya",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Gopalganj",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Jamui",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Jehanabad",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Kaimur",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Katihar",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Khagaria",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Kishanganj",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Lakhisarai",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Madhepura",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Madhubani",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Munger",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Muzaffarpur",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Nalanda",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Nawada",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Patna",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Purnia",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Rohtas",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Saharsa",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Samastipur",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Saran",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Sheikhpura",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Sheohar",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Sitamarhi",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Siwan",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Supaul",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Vaishali",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "West Champaran",
    c_c: "IN",
    s_c: "BR",
  },
  {
    name: "Chandigarh",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Balod",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Baloda Bazar",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Balrampur",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Bastar",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Bemetara",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Bijapur",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Bilaspur",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Dantewada",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Dhamtari",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Durg",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Gariaband",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Gaurela Pendra Marwahi",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Janjgir Champa",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Jashpur",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Kabirdham",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Kanker",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Khairagarh",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Kondagaon",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Korba",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Koriya",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Mahasamund",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Manendragarh",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Mohla Manpur",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Mungeli",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Narayanpur",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Raigarh",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Raipur",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Rajnandgaon",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Sakti",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Sarangarh Bilaigarh",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Sukma",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Surajpur",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Surguja",
    c_c: "IN",
    s_c: "CH",
  },
  {
    name: "Dadra and Nagar Haveli",
    c_c: "IN",
    s_c: "DH",
  },
  {
    name: "Daman",
    c_c: "IN",
    s_c: "DH",
  },
  {
    name: "Diu",
    c_c: "IN",
    s_c: "DH",
  },
  {
    name: "Central Delhi",
    c_c: "IN",
    s_c: "DL",
  },
  {
    name: "East Delhi",
    c_c: "IN",
    s_c: "DL",
  },
  {
    name: "New Delhi",
    c_c: "IN",
    s_c: "DL",
  },
  {
    name: "North Delhi",
    c_c: "IN",
    s_c: "DL",
  },
  {
    name: "North East Delhi",
    c_c: "IN",
    s_c: "DL",
  },
  {
    name: "North West Delhi",
    c_c: "IN",
    s_c: "DL",
  },
  {
    name: "Shahdara",
    c_c: "IN",
    s_c: "DL",
  },
  {
    name: "South Delhi",
    c_c: "IN",
    s_c: "DL",
  },
  {
    name: "South East Delhi",
    c_c: "IN",
    s_c: "DL",
  },
  {
    name: "South West Delhi",
    c_c: "IN",
    s_c: "DL",
  },
  {
    name: "West Delhi",
    c_c: "IN",
    s_c: "DL",
  },
  {
    name: "North Goa",
    c_c: "IN",
    s_c: "GA",
  },
  {
    name: "South Goa",
    c_c: "IN",
    s_c: "GA",
  },
  {
    name: "Ahmedabad",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Amreli",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Anand",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Aravalli",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Banaskantha",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Bharuch",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Bhavnagar",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Botad",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Chhota Udaipur",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Dahod",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Dang",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Devbhoomi Dwarka",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Gandhinagar",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Gir Somnath",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Jamnagar",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Junagadh",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Kheda",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Kutch",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Mahisagar",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Mehsana",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Morbi",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Narmada",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Navsari",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Panchmahal",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Patan",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Porbandar",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Rajkot",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Sabarkantha",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Surat",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Surendranagar",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Tapi",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Vadodara",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Valsad",
    c_c: "IN",
    s_c: "GJ",
  },
  {
    name: "Ambala",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Bhiwani",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Charkhi Dadri",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Faridabad",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Fatehabad",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Gurugram",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Hisar",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Jhajjar",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Jind",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Kaithal",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Karnal",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Kurukshetra",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Mahendragarh",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Mewat",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Palwal",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Panchkula",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Panipat",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Rewari",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Rohtak",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Sirsa",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Sonipat",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Yamunanagar",
    c_c: "IN",
    s_c: "HR",
  },
  {
    name: "Bilaspur",
    c_c: "IN",
    s_c: "HP",
  },
  {
    name: "Chamba",
    c_c: "IN",
    s_c: "HP",
  },
  {
    name: "Hamirpur",
    c_c: "IN",
    s_c: "HP",
  },
  {
    name: "Kangra",
    c_c: "IN",
    s_c: "HP",
  },
  {
    name: "Kinnaur",
    c_c: "IN",
    s_c: "HP",
  },
  {
    name: "Kullu",
    c_c: "IN",
    s_c: "HP",
  },
  {
    name: "Lahaul Spiti",
    c_c: "IN",
    s_c: "HP",
  },
  {
    name: "Mandi",
    c_c: "IN",
    s_c: "HP",
  },
  {
    name: "Shimla",
    c_c: "IN",
    s_c: "HP",
  },
  {
    name: "Sirmaur",
    c_c: "IN",
    s_c: "HP",
  },
  {
    name: "Solan",
    c_c: "IN",
    s_c: "HP",
  },
  {
    name: "Una",
    c_c: "IN",
    s_c: "HP",
  },
  {
    name: "Anantnag",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Bandipora",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Baramulla",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Budgam",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Doda",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Ganderbal",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Jammu",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Kathua",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Kishtwar",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Kulgam",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Kupwara",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Poonch",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Pulwama",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Rajouri",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Ramban",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Reasi",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Samba",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Shopian",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Srinagar",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Udhampur",
    c_c: "IN",
    s_c: "JK",
  },
  {
    name: "Bokaro",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Chatra",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Deoghar",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Dhanbad",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Dumka",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "East Singhbhum",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Garhwa",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Giridih",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Godda",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Gumla",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Hazaribagh",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Jamtara",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Khunti",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Koderma",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Latehar",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Lohardaga",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Pakur",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Palamu",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Ramgarh",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Ranchi",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Sahebganj",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Seraikela Kharsawan",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Simdega",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "West Singhbhum",
    c_c: "IN",
    s_c: "JH",
  },
  {
    name: "Bagalkot",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Bangalore Rural",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Bangalore Urban",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Belgaum",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Bellary",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Bidar",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Chamarajanagar",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Chikkaballapur",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Chikkamagaluru",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Chitradurga",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Dakshina Kannada",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Davanagere",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Dharwad",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Gadag",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Gulbarga",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Hassan",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Haveri",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Kodagu",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Kolar",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Koppal",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Mandya",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Mysore",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Raichur",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Ramanagara",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Shimoga",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Tumkur",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Udupi",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Uttara Kannada",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Vijayanagara",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Vijayapura ",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Yadgir",
    c_c: "IN",
    s_c: "KA",
  },
  {
    name: "Alappuzha",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Ernakulam",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Idukki",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Kannur",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Kasaragod",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Kollam",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Kottayam",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Kozhikode",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Malappuram",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Palakkad",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Pathanamthitta",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Thiruvananthapuram",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Thrissur",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Wayanad",
    c_c: "IN",
    s_c: "KL",
  },
  {
    name: "Kargil",
    c_c: "IN",
    s_c: "LA",
  },
  {
    name: "Leh",
    c_c: "IN",
    s_c: "LA",
  },
  {
    name: "Lakshadweep",
    c_c: "IN",
    s_c: "LD",
  },
  {
    name: "Agar Malwa",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Alirajpur",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Anuppur",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Ashoknagar",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Balaghat",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Barwani",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Betul",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Bhind",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Bhopal",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Burhanpur",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Chachaura",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Chhatarpur",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Chhindwara",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Damoh",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Datia",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Dewas",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Dhar",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Dindori",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Guna",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Gwalior",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Harda",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Hoshangabad",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Indore",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Jabalpur",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Jhabua",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Katni",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Khandwa",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Khargone",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Maihar",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Mandla",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Mandsaur",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Morena",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Nagda",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Narsinghpur",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Neemuch",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Niwari",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Panna",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Raisen",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Rajgarh",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Ratlam",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Rewa",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Sagar",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Satna",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Sehore",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Seoni",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Shahdol",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Shajapur",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Sheopur",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Shivpuri",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Sidhi",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Singrauli",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Tikamgarh",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Ujjain",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Umaria",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Vidisha",
    c_c: "IN",
    s_c: "MP",
  },
  {
    name: "Ahmednagar",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Akola",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Amravati",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Aurangabad",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Beed",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Bhandara",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Buldhana",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Chandrapur",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Dhule",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Gadchiroli",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Gondia",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Hingoli",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Jalgaon",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Jalna",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Kolhapur",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Latur",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Mumbai City",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Mumbai Suburban",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Nagpur",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Nanded",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Nandurbar",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Nashik",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Osmanabad",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Palghar",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Parbhani",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Pune",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Raigad",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Ratnagiri",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Sangli",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Satara",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Sindhudurg",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Solapur",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Thane",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Wardha",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Washim",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Yavatmal",
    c_c: "IN",
    s_c: "MH",
  },
  {
    name: "Bishnupur",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Chandel",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Churachandpur",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Imphal East",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Imphal West",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Jiribam",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Kakching",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Kamjong",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Kangpokpi",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Noney",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Pherzawl",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Senapati",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Tamenglong",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Tengnoupal",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Thoubal",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "Ukhrul",
    c_c: "IN",
    s_c: "MN",
  },
  {
    name: "East Garo Hills",
    c_c: "IN",
    s_c: "ML",
  },
  {
    name: "East Jaintia Hills",
    c_c: "IN",
    s_c: "ML",
  },
  {
    name: "East Khasi Hills",
    c_c: "IN",
    s_c: "ML",
  },
  {
    name: "Mairang",
    c_c: "IN",
    s_c: "ML",
  },
  {
    name: "North Garo Hills",
    c_c: "IN",
    s_c: "ML",
  },
  {
    name: "Ri Bhoi",
    c_c: "IN",
    s_c: "ML",
  },
  {
    name: "South Garo Hills",
    c_c: "IN",
    s_c: "ML",
  },
  {
    name: "South West Garo Hills",
    c_c: "IN",
    s_c: "ML",
  },
  {
    name: "South West Khasi Hills",
    c_c: "IN",
    s_c: "ML",
  },
  {
    name: "West Garo Hills",
    c_c: "IN",
    s_c: "ML",
  },
  {
    name: "West Jaintia Hills",
    c_c: "IN",
    s_c: "ML",
  },
  {
    name: "West Khasi Hills",
    c_c: "IN",
    s_c: "ML",
  },
  {
    name: "Aizawl",
    c_c: "IN",
    s_c: "MZ",
  },
  {
    name: "Champhai",
    c_c: "IN",
    s_c: "MZ",
  },
  {
    name: "Hnahthial",
    c_c: "IN",
    s_c: "MZ",
  },
  {
    name: "Kolasib",
    c_c: "IN",
    s_c: "MZ",
  },
  {
    name: "Khawzawl",
    c_c: "IN",
    s_c: "MZ",
  },
  {
    name: "Lawngtlai",
    c_c: "IN",
    s_c: "MZ",
  },
  {
    name: "Lunglei",
    c_c: "IN",
    s_c: "MZ",
  },
  {
    name: "Mamit",
    c_c: "IN",
    s_c: "MZ",
  },
  {
    name: "Saiha",
    c_c: "IN",
    s_c: "MZ",
  },
  {
    name: "Serchhip",
    c_c: "IN",
    s_c: "MZ",
  },
  {
    name: "Saitual",
    c_c: "IN",
    s_c: "MZ",
  },
  {
    name: "Chumukedima",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Dimapur",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Kiphire",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Kohima",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Longleng",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Mokokchung",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Mon",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Niuland",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Noklak",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Peren",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Phek",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Shamator",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Tseminyu",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Tuensang",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Wokha",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Zunheboto",
    c_c: "IN",
    s_c: "NL",
  },
  {
    name: "Angul",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Balangir",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Balasore",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Bargarh",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Bhadrak",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Boudh",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Cuttack",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Debagarh",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Dhenkanal",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Gajapati",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Ganjam",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Jagatsinghpur",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Jajpur",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Jharsuguda",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Kalahandi",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Kandhamal",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Kendrapara",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Kendujhar",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Khordha",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Koraput",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Malkangiri",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Mayurbhanj",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Nabarangpur",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Nayagarh",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Nuapada",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Puri",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Rayagada",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Sambalpur",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Subarnapur",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Sundergarh",
    c_c: "IN",
    s_c: "OR",
  },
  {
    name: "Karaikal",
    c_c: "IN",
    s_c: "PY",
  },
  {
    name: "Mahe",
    c_c: "IN",
    s_c: "PY",
  },
  {
    name: "Puducherry",
    c_c: "IN",
    s_c: "PY",
  },
  {
    name: "Yanam",
    c_c: "IN",
    s_c: "PY",
  },
  {
    name: "Amritsar",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Barnala",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Bathinda",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Faridkot",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Fatehgarh Sahib",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Fazilka",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Firozpur",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Gurdaspur",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Hoshiarpur",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Jalandhar",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Kapurthala",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Ludhiana",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Malerkotla",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Mansa",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Moga",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Mohali",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Muktsar",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Pathankot",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Patiala",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Rupnagar",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Sangrur",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Shaheed Bhagat Singh Nagar",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Tarn Taran",
    c_c: "IN",
    s_c: "PB",
  },
  {
    name: "Ajmer",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Alwar",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Banswara",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Baran",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Barmer",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Bharatpur",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Bhilwara",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Bikaner",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Bundi",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Chittorgarh",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Churu",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Dausa",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Dholpur",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Dungarpur",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Hanumangarh",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Jaipur",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Jaisalmer",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Jalore",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Jhalawar",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Jhunjhunu",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Jodhpur",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Karauli",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Kota",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Nagaur",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Pali",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Pratapgarh",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Rajsamand",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Sawai Madhopur",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Sikar",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Sirohi",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Sri Ganganagar",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Tonk",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "Udaipur",
    c_c: "IN",
    s_c: "RJ",
  },
  {
    name: "East Sikkim",
    c_c: "IN",
    s_c: "SK",
  },
  {
    name: "North Sikkim",
    c_c: "IN",
    s_c: "SK",
  },
  {
    name: "Pakyong",
    c_c: "IN",
    s_c: "SK",
  },
  {
    name: "Soreng",
    c_c: "IN",
    s_c: "SK",
  },
  {
    name: "South Sikkim",
    c_c: "IN",
    s_c: "SK",
  },
  {
    name: "West Sikkim",
    c_c: "IN",
    s_c: "SK",
  },
  {
    name: "Ariyalur",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Chengalpattu",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Chennai",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Coimbatore",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Cuddalore",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Dharmapuri",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Dindigul",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Erode",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Kallakurichi",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Kanchipuram",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Kanyakumari",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Karur",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Krishnagiri",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Madurai",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Mayiladuthurai ",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Nagapattinam",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Namakkal",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Nilgiris",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Perambalur",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Pudukkottai",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Ramanathapuram",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Ranipet",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Salem",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Sivaganga",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Tenkasi",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Thanjavur",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Theni",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Thoothukudi",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Tiruchirappalli",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Tirunelveli",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Tirupattur",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Tiruppur",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Tiruvallur",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Tiruvannamalai",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Tiruvarur",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Vellore",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Viluppuram",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Virudhunagar",
    c_c: "IN",
    s_c: "TN",
  },
  {
    name: "Adilabad",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Bhadradri Kothagudem",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Hyderabad",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Jagtial",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Jangaon",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Jayashankar",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Jogulamba",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Kamareddy",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Karimnagar",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Khammam",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Komaram Bheem",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Mahabubabad",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Mahbubnagar",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Mancherial",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Medak",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Medchal",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Mulugu",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Nagarkurnool",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Nalgonda",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Narayanpet",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Nirmal",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Nizamabad",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Peddapalli",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Rajanna Sircilla",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Ranga Reddy",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Sangareddy",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Siddipet",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Suryapet",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Vikarabad",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Wanaparthy",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Warangal",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Hanamkonda",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Yadadri Bhuvanagiri",
    c_c: "IN",
    s_c: "TG",
  },
  {
    name: "Dhalai",
    c_c: "IN",
    s_c: "TR",
  },
  {
    name: "Gomati",
    c_c: "IN",
    s_c: "TR",
  },
  {
    name: "Khowai",
    c_c: "IN",
    s_c: "TR",
  },
  {
    name: "North Tripura",
    c_c: "IN",
    s_c: "TR",
  },
  {
    name: "Sepahijala",
    c_c: "IN",
    s_c: "TR",
  },
  {
    name: "South Tripura",
    c_c: "IN",
    s_c: "TR",
  },
  {
    name: "Unakoti",
    c_c: "IN",
    s_c: "TR",
  },
  {
    name: "West Tripura",
    c_c: "IN",
    s_c: "TR",
  },
  {
    name: "Agra",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Aligarh",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Ambedkar Nagar",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Amethi",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Amroha",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Auraiya",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Ayodhya",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Azamgarh",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Baghpat",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Bahraich",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Ballia",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Balrampur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Banda",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Barabanki",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Bareilly",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Basti",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Bhadohi",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Bijnor",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Budaun",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Bulandshahr",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Chandauli",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Chitrakoot",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Deoria",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Etah",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Etawah",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Farrukhabad",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Fatehpur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Firozabad",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Gautam Buddha Nagar",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Ghaziabad",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Ghazipur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Gonda",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Gorakhpur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Hamirpur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Hapur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Hardoi",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Hathras",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Jalaun",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Jaunpur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Jhansi",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Kannauj",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Kanpur Dehat",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Kanpur Nagar",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Kasganj",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Kaushambi",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Kheri",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Kushinagar",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Lalitpur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Lucknow",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Maharajganj",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Mahoba",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Mainpuri",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Mathura",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Mau",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Meerut",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Mirzapur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Moradabad",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Muzaffarnagar",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Pilibhit",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Pratapgarh",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Prayagraj",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Raebareli",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Rampur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Saharanpur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Sambhal",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Sant Kabir Nagar",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Shahjahanpur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Shamli",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Shravasti",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Siddharthnagar",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Sitapur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Sonbhadra",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Sultanpur",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Unnao",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Varanasi",
    c_c: "IN",
    s_c: "UP",
  },
  {
    name: "Almora",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Bageshwar",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Chamoli",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Champawat",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Dehradun",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Haridwar",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Nainital",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Pauri",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Pithoragarh",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Rudraprayag",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Tehri",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Udham Singh Nagar",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Uttarkashi",
    c_c: "IN",
    s_c: "UT",
  },
  {
    name: "Alipurduar",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Bankura",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Birbhum",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Cooch Behar",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Dakshin Dinajpur",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Darjeeling",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Hooghly",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Howrah",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Jalpaiguri",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Jhargram",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Kalimpong",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Kolkata",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Malda",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Murshidabad",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Nadia",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "North 24 Parganas",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Paschim Bardhaman",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Paschim Medinipur",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Purba Bardhaman",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Purba Medinipur",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Purulia",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "South 24 Parganas",
    c_c: "IN",
    s_c: "WB",
  },
  {
    name: "Uttar Dinajpur",
    c_c: "IN",
    s_c: "WB",
  },
];

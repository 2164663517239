export const MaritalStatusOptions = [
  {
    value: "any",
    en_label: "Any",
    ta_label: "ஏதேனும்",
    p_o: [],
  },
  {
    value: "never_married",
    en_label: "Never Married",
    ta_label: "திருமணம் ஆகாதவர்",
    p_o: [],
  },
  {
    value: "divorced",
    en_label: "Divorced",
    ta_label: "விவாகரத்து",
    p_o: [],
  },
  {
    value: "widowed",
    en_label: "Widowed",
    ta_label: "விதவை",
    p_o: [],
  },
  {
    value: "awaiting_divorce",
    en_label: "Awaiting Divorce",
    ta_label: "விவாகரத்துக்காக காத்திருக்கிறேன்",
    p_o: [],
  },
];

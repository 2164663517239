import { Box, Heading, HStack, Text, VStack } from "native-base";
import React, { Component } from "react";
import "../../index.css";

import Scrollbars from "react-custom-scrollbars-2";

const ContactUs = (props) => {
  return (
    <Scrollbars
      style={{
        backgroundColor: "#fef5fb",
      }}
    >
      <Box mx={5}>
        <Box alignItems={"center"}>
          <Heading>Contact Us</Heading>
        </Box>

        <Box my={5}>
          <Text fontSize={20}>Contact Us:</Text>
        </Box>

        <Box>
          <Text fontSize={20}>Admin office : </Text>
        </Box>
        <Box>
          <Text bold fontSize={18}>
            Standard Matrimony ,{" "}
          </Text>
          <Text bold fontSize={18}>
            2nd floor , door No: 774A,{" "}
          </Text>
          <Text bold fontSize={18}>
            Sathy Road, Gandhipuram,{" "}
          </Text>
          <Text bold fontSize={18}>
            Coimbatore-641012.{" "}
          </Text>
          <Text bold fontSize={18}>
            Tamilnadu , India
          </Text>
          <Text bold fontSize={18}>
            contact person : G Mahendran ,{" "}
          </Text>
          <Text bold fontSize={18}>
            E-mail : standardmatrimony@gmail.com ,{" "}
          </Text>
          <Text bold fontSize={18}>
            Phone : +914224709829
          </Text>
          <Text bold fontSize={18}>
            This policy is effective as of 05/04/2023.
          </Text>
        </Box>
      </Box>
    </Scrollbars>
  );
};

export default ContactUs;

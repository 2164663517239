import React from "react";
import { Box, Center, Text, VStack, HStack } from "native-base";

import { CalculateAge, CalculateAgeForMember } from "@helpers/calculate_age";
import { useTranslation } from "react-i18next";
import PrintElement from "./components/print_element";
import {
  getDoshamDetailsFromValueForTamil,
  getUserDetailsFromValue,
  getUserDetailsFromValueForTamil,
} from "@helpers/functions";
import { ReligionsOptions } from "@master_data/common_json/religions";
import { CasteOptions } from "@master_data/common_json/castes";
import { SubCastesOptions } from "@master_data/common_json/sub_castes";
import { StarsOptions } from "@master_data/common_json/stars";
import { DoshamsOptions } from "@master_data/common_json/doshams";
import { EducationsOptions } from "@master_data/common_json/educations";
import { EmploymentTypesOptions } from "@master_data/common_json/employment_types";
import { WeightOptions } from "@master_data/profile_json/weight";
import { ComplexionOptions } from "@master_data/profile_json/complexion";
import { EatingHabitsOptions } from "@master_data/common_json/eating_habits";
import { MotherTongueOptions } from "@master_data/common_json/mother_tongue";
import { PhysicalStatusOptions } from "@master_data/common_json/physical_status";
import { MaritalStatusOptions } from "@master_data/common_json/marital_status";
import { HeightOptions } from "@master_data/profile_json/height";
import { AnnualIncomeFromOptions } from "@master_data/preference_json/annual_income_from";
import { AnnualIncomeToOptions } from "@master_data/preference_json/annual_income_to";
import { getDistrict } from "@helpers/get_country_state_city";

const ChartDetailsMultiple = (props) => {
  const { item, login_user_horoscope } = props;
  const { t } = useTranslation();
  return (
    <>
      <Box>
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "red",
            fontSize: "20",
            fontWeight: "bold",
            marginTop: "-25px",
          }}
        >
          Standard Matrimony
        </h2>
        <Text
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "10",
            fontWeight: "bold",
            marginTop: "-15px",
          }}
        >
          (Service from the heart)
        </Text>
        <Box style={{ marginTop: "10px" }}>
          <PrintElement label="பெயர்" value={item?.name} />
          <PrintElement label="வயது" value={CalculateAgeForMember(item?.dob)} />
          <PrintElement
            label="உயரம்"
            value={
              item?.height
                ? getUserDetailsFromValue(HeightOptions, item?.height)
                : "-"
            }
          />
          <PrintElement
            label="திருமண நிலை"
            value={
              item?.marital_status
                ? getUserDetailsFromValueForTamil(
                    MaritalStatusOptions,
                    item?.marital_status
                  )
                : "-"
            }
          />
          <PrintElement
            label="மாற்றுத்திறனாளியா "
            value={
              item?.physical_status
                ? getUserDetailsFromValueForTamil(
                    PhysicalStatusOptions,
                    item?.physical_status
                  )
                : "-"
            }
          />
          <PrintElement
            label="தாய்மொழி "
            value={
              item?.mother_tongue
                ? getUserDetailsFromValueForTamil(
                    MotherTongueOptions,
                    item?.mother_tongue
                  )
                : "-"
            }
          />
          <PrintElement
            label="உணவுப்பழக்கம்"
            value={
              item?.eating_habit
                ? getUserDetailsFromValueForTamil(
                    EatingHabitsOptions,
                    item?.eating_habit
                  )
                : "-"
            }
          />
          <PrintElement
            label="நிறம்"
            value={
              item?.complexion
                ? getUserDetailsFromValueForTamil(
                    ComplexionOptions,
                    item?.complexion
                  )
                : "-"
            }
          />
          <PrintElement
            label="எடை"
            value={
              item?.weight
                ? getUserDetailsFromValue(WeightOptions, item?.weight)
                : "-"
            }
          />
          <Text fontSize={15} bold>
            Religion information
          </Text>
          <PrintElement
            label="மதம் "
            value={
              item?.religion
                ? getUserDetailsFromValueForTamil(
                    ReligionsOptions,
                    item?.religion
                  )
                : "-"
            }
          />
          <PrintElement
            label="சாதி "
            value={
              item?.caste
                ? getUserDetailsFromValueForTamil(CasteOptions, item?.caste)
                : "-"
            }
          />
          <PrintElement
            label="உட்பிரிவு"
            value={
              item?.sub_caste
                ? getUserDetailsFromValueForTamil(
                    SubCastesOptions,
                    item?.sub_caste
                  )
                : "-"
            }
          />
          <PrintElement
            label="ராசி/ நட்சத்திரம்"
            value={
              item?.star
                ? getUserDetailsFromValueForTamil(StarsOptions, item?.star)
                : "-"
            }
          />
          <PrintElement
            label="கோத்திரம்/குலம்/கூட்டம்"
            value={item?.gothram ? item?.gothram : "-"}
          />
          <PrintElement
            label="தோசம்"
            value={
              item?.doshams?.length > 0
                ? getDoshamDetailsFromValueForTamil(
                    DoshamsOptions,
                    item?.doshams
                  )
                : "-"
            }
          />
          <PrintElement
            label="பிறந்த நேரம் "
            value={
              login_user_horoscope?.time_of_birth
                ? login_user_horoscope?.time_of_birth
                : "-"
            }
          />
          <PrintElement
            label="பிறந்த இடம் "
            value={
              login_user_horoscope?.place_of_birth
                ? login_user_horoscope?.place_of_birth
                : "-"
            }
          />
          <Text fontSize={15} bold>
            Location Details
          </Text>
          <PrintElement
            label="இருப்பிடம்/ பூர்வீகம்"
            value={
              item?.ancestor_origin ? getDistrict(item?.ancestor_origin) : "-"
            }
          />
          <Text fontSize={15} bold>
            Professional Information
          </Text>
          {console.log("item", item)}

          <PrintElement
            label="கல்வி"
            // value={
            //   item?.education
            //     ? getUserDetailsFromValue(EducationsOptions, item?.education)
            //     : "-"
            // }
            text={"multi"}
            items={item?.educations}
            options={EducationsOptions}
          />
          <PrintElement
            label="தொழில்"
            value={
              item?.employment_type
                ? getUserDetailsFromValueForTamil(
                    EmploymentTypesOptions,
                    item?.employment_type
                  )
                : "-"
            }
          />
          <PrintElement
            label="ஆண்டு வருமானம் "
            value={
              item?.annual_income_from === 0
                ? "0-1 L"
                : item?.annual_income_from
                ? `${getUserDetailsFromValue(
                    AnnualIncomeFromOptions,
                    item?.annual_income_from
                  )} - ${getUserDetailsFromValue(
                    AnnualIncomeToOptions,
                    item?.annual_income_to
                  )}`
                : "-"
            }
          />
          <PrintElement
            label="வேலை"
            value={
              item?.employment_type
                ? getUserDetailsFromValueForTamil(
                    EmploymentTypesOptions,
                    item?.employment_type
                  )
                : "-"
            }
          />
          <Text fontSize={15} bold>
            Family Details
          </Text>
          <PrintElement
            label="தந்தை பெயர்"
            value={item?.father_name ? item?.father_name : "-"}
          />
          <PrintElement
            label="தாயின் பெயர்"
            value={item?.mother_name ? item?.mother_name : "-"}
          />
          <PrintElement
            label="சகோதரர்கள்"
            value={
              item?.number_of_brothers !== null
                ? item?.number_of_brothers === 0
                  ? "இல்லை"
                  : `${item?.number_of_brothers} brother(s)`
                : "-"
            }
          />
          <PrintElement
            label="திருமணமான சகோதரர்கள்"
            value={
              item?.number_of_married_brothers !== null
                ? item?.number_of_married_brothers === 0
                  ? "இல்லை"
                  : `${item?.number_of_married_brothers} brother(s) married`
                : "-"
            }
          />
          <PrintElement
            label="சகோதரிகள்"
            value={
              item?.number_of_sisters !== null
                ? item?.number_of_sisters === 0
                  ? "இல்லை"
                  : `${item?.number_of_sisters} brother(s)`
                : "-"
            }
          />
          {console.log("item", item)}
          <PrintElement
            label="திருமணமான சகோதரிகள்"
            value={
              item?.number_of_married_sisters !== null
                ? item?.number_of_married_sisters === 0
                  ? "இல்லை"
                  : `${item?.number_of_married_sisters} sister(s) married`
                : "-"
            }
          />
          <PrintElement
            label="தொலைபேசி எண் "
            value={
              item?.mobile !== "use_subscription" &&
              item?.mobile !== "need_new_subscription" &&
              item?.mobile !== "need_subscription" &&
              item?.mobile !== "hidden"
                ? item?.mobile
                : "-"
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default ChartDetailsMultiple;

import { Box, Image, Text } from "native-base";
import React, { useEffect, useState } from "react";
import * as IMAGE_CONSTANTS from "@helpers/image_constants";

const UserHomeBanner = (props) => {
  const UserImage = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          borderRadius: "50%",
          objectFit: "cover",
        }}
        width={120}
        height={120}
      />
    );
  });
  return (
    <Box
      alignItems={"center"}
      justifyContent={"center"}
      // height={"100%"}
      width={"100%"}
      backgroundColor={"#fef5fb"}
    >
      <Box
        my={5}
        mx={5}
        bgColor={"#753968"}
        alignItems={"center"}
        // justifyContent={"center"}
        width={"90%"}
        borderRadius={10}
      >
        <Image
          width={"100%"}
          height={{
            base: 80,
            xs: 80,
            sm: 80,
            md: 80,
            lg: 80,
            xl: 80,
            "2xl": 80,
            "3xl": 80,
          }}
          borderColor={"#753968"}
          resizeMode={"contain"}
          // borderRadius={'400px'}
          source={IMAGE_CONSTANTS.BANNER_ICON}
          alt="SM"
        />
      </Box>
    </Box>
  );
};
export default UserHomeBanner;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  delete_subscription_plan_mutation,
  dynamicRequest,
  useDynamicSelector,
  get_subscription_plans_query,
  dynamicClear,
} from "@services/redux";
// import Modal from "@views/components/ui/modal";
import AdminSubscriptionPlanDetails from "./admin_subscription_plan_details";
import AntdModal from "../ui/antd_modal/";
import {
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Tooltip,
  Space,
} from "antd";
import { showNotification } from "@helpers/notify";
import lodash from "lodash";
import ActionButton from "../ui/dialog/action_button";
import { TbCurrentLocation } from "react-icons/tb";

const AdminVerificationList = () => {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);

  const { status: createStatus, error: createError } = useDynamicSelector(
    "create_subscription_plan"
  );
  const { status: updateStatus, error: updateError } = useDynamicSelector(
    "update_subscription_plan"
  );
  const { items: subscription_plans, loading } =
    useDynamicSelector("subscription_plans");
  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useDynamicSelector("delete_subscription_plan");

  const handleAdd = () => {
    setActionItem({});

    setActionType("add");
    setModalOpen(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_subscription_plan", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_subscription_plan_mutation, {
        id: record?.id,
      })
    );
  };
  const handleDeleteModalView = (item) => {
    setActionItem(item);
    setDialogVisible(true);
    handleDelete();
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    let keys = [{ key: "subscription_plans", loading: true }];
    // dispatch(dynamicRequest(keys, get_subscription_plans_query, "M"));
    dispatch(
      dynamicRequest(keys, get_subscription_plans_query, {
        page_number: current,
        page_limit: 1,
      })
    );
    // setCurrent(value);
  }, []);

  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `Subscription Plan Added Successfully`,
      });
      let keys = [{ key: "subscription_plans", loading: true }];

      dispatch(dynamicRequest(keys, get_subscription_plans_query, "M"));
      dispatch(dynamicClear("create_subscription_plan"));
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
        message: `Subscription Plan Updated Successfully`,
      });
      dispatch(dynamicClear("update_subscription_plan"));

      let keys = [{ key: "subscription_plans", loading: true }];

      dispatch(dynamicRequest(keys, get_subscription_plans_query));
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `${"Subscription Plan"} deleted successfully`,
      });
      dispatch(dynamicClear("create_subscription_plan"));

      let keys = [{ key: "delete_subscription_plan", loading: true }];

      dispatch(dynamicRequest(keys, get_subscription_plans_query, "M"));
    }
  }, [createStatus, updateStatus, deleteStatus]);

  const fetchData = (value) => {
    let keys = [{ key: "subscription_plans", loading: true }];
    dispatch(
      dynamicRequest(keys, get_subscription_plans_query, {
        page_number: value,
        page_limit: 1,
      })
    );
    setCurrent(value);
  };
  let columns = [
    {
      title: t("table:en_label"),
      dataIndex: "en_label",
      align: "left",
      key: "en_label",
    },
    {
      title: t("table:ta_label"),
      dataIndex: "ta_label",
      align: "left",
      key: "ta_label",
    },
    {
      title: t("table:no_of_days"),
      dataIndex: "period_in_days",
      align: "left",
      key: "period_in_days",
    },
    {
      title: t("table:actual_price"),
      dataIndex: "actual_price",
      key: "actual_price",
      align: "left",
    },
    {
      title: t("table:discounted_price"),
      dataIndex: "discounted_price",
      key: "discounted_price",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:allowed_purchase_count"),
      dataIndex: "allowed_purchase_count",
      key: "allowed_purchase_count",
      sortable: false,
      align: "left",
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span>
            <Typography.Link
              style={{
                marginRight: 10,
              }}
              onClick={() => handleEdit(record)}
            >
              <Space>
                <Tooltip title="Edit">
                  <EditOutlined />
                </Tooltip>
              </Space>
            </Typography.Link>
            <Popconfirm
              style={{
                marginLeft: 10,
              }}
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <Space>
                <Tooltip title="Delete">
                  <DeleteOutlined />
                </Tooltip>
              </Space>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_subscription"),
      colorScheme: "danger.700",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box width="100%" mt="20px" marginBottom="20px">
        <Box top="0px" zIndex="2">
          <Text fontSize="md" fontWeight="bold">
            {t("subscription_plans")}
          </Text>
        </Box>
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            {header_actions.map((headerAction, index) => (
              <Box key={`header-action-${index}`}>
                <ActionButton {...headerAction} />
              </Box>
            ))}
          </HStack>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={subscription_plans}
              pagination={false}
              loading={loading}
            />
          </Wrapper>
          <Pagination
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </VStack>
      </Box>

      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        header={actionType === "add" ? t("add_plan") : t("update_plan")}
        component={
          <AdminSubscriptionPlanDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
            type={actionType === "add" ? "add" : "update"}
          />
        }
      />
    </>
  );
};
export default AdminVerificationList;

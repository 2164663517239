import { gql } from "@apollo/client";
export const get_invoice_details = gql`
  query getInvoiceDetail($order_id: String!) {
    get_invoice_details: getInvoiceDetail(order_id: $order_id) {
      customer_name
      customer_mobile
      invoice_number
      customer_profile_id
      customer_country
      customer_state
      customer_district
      plan_name
      plan_rate
      plan_discounted_rate
      plan_discounted_rate
      order_datetime
    }
  }
`;

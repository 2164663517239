import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import React, { useState } from "react";
import logo from "@assets/icons/transparent_sm.png";

import {
  Button,
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input,
  ScrollView,
} from "native-base";
import SM from "@assets/icons/bg_design.jpeg";

import { useTranslation } from "react-i18next";
import AdminLoginForm from "./admin_login_form";

const AdminLogin = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        safeAreaTop
        _light={{
          bg: "primary.900",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
      />
      <Center
        my="auto"
        _dark={{
          bg: "#712e5a",
        }}
        _light={{
          bg: "#712e5a",
        }}
        flex="1"
      >
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          maxW={{
            md: "1016px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <Hidden from="md">
            <VStack px="4" mt="4" mb="5" space="9">
              <HStack alignItems="center">
                <Image
                  cursor={"pointer"}
                  px={2}
                  py={2}
                  size="240px"
                  borderRadius={"120px"}
                  alt="logo"
                  resizeMode={"cover"}
                  source={logo}
                />

                <Text
                  textAlign={"center"}
                  fontSize={{ base: 30, xs: 20, sm: 20, md: 25 }}
                  fontWeight="bold"
                  color="#712e5a"
                >
                  {t("standard_matrimony")}
                </Text>
              </HStack>
              {/* <VStack space="2"> */}
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Center
              flex="1"
              bg="#712e5a"
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <Image
                h="24"
                size="full"
                alt="NativeBase Startup+ "
                resizeMode={"cover"}
                source={SM}
                borderTopLeftRadius={20}
                borderBottomLeftRadius={20}
              />
            </Center>
          </Hidden>
          <AdminLoginForm props={props} />
        </Stack>
      </Center>
    </>
  );
};

export default AdminLogin;

/* eslint-disable no-nested-ternary */
// import {View, Text, StyleSheet, Image} from 'react-native';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dimensions } from "react-native";

import {
  Box,
  Card,
  HStack,
  Hidden,
  Pressable,
  Radio,
  Skeleton,
  Text,
  VStack,
} from "native-base";
// import Select from "react-select";

import { useDispatch } from "react-redux";
import { Button, Form, Input, Modal, Select } from "antd";
import { useHistory } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { AiFillDelete, AiOutlineArrowLeft } from "react-icons/ai";
import {
  deleteUserAccount,
  dynamicClear,
  dynamicRequest,
  getMyPrivacySettings,
  updateMyPrivacy,
  useDynamicSelector,
} from "@services/redux";
import { use } from "i18next";
import toast from "react-hot-toast";
import { navigateBack } from "@helpers/navigator";
import colors from "@helpers/colors";
import PrivacySettingsForm from "./operator_privacy_settings_form";
import OperatorPrivacySettingsForm from "./operator_privacy_settings_form";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const OperatorPrivacySettings = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Search } = Input;
  const history = useHistory();
  const [form] = Form.useForm();

  const [initial_values, setInitialValues] = useState([]);

  const [contactDetails, setContactDetails] = useState("all_members");
  const [horoscopeDetails, setHoroscopeDetails] = useState("all_members");
  const [photoDetails, setPhotoDetails] = useState("all_members");
  const [delete_reason, setDeleteReason] = useState("");
  const [delete_reason_text, setDeleteReasonText] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [created_for, setCreatedFor] = useState();

  const { status: privacyUpdateStatus, loading: update_my_privacy_loading } =
    useDynamicSelector("updateMyPrivacy");

  const { user_profile: my_profile_details } = useDynamicSelector("my_profile");

  const { user_privacy: getPrivacyResponse, loading: privacy_loading } =
    useDynamicSelector("my_privacy_settings");

  const deleteOptions = [
    { value: "Married", label: "Married" },
    { value: "Marriage Fixed", label: "Marriage Fixed" },
    {
      value: "Marriage Fixed Through Standard Matrimony",
      label: "Marriage Fixed Through Standard Matrimony",
    },
    {
      value: "Many calls from telecalling executives",
      label: "Many calls from telecalling executives",
    },
    {
      value: "Not getting enough matches",
      label: "Not getting enough matches",
    },
    { value: "Any other reason", label: "Any other reason" },
  ];

  const handleDeleteModalCancel = () => {
    setIsDeleteModalOpen(false);
    setDeleteReasonText("");
    setDeleteReason("");
  };

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const deleteMyUserAccount = () => {
    let key = [{ key: "deleteMyAccount", loading: true }];
    let query = deleteUserAccount;
    let variables = {
      reason: delete_reason_text ? delete_reason_text : delete_reason,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const updatePrivacyFunction = () => {
    let values = {
      show_contact_details: contactDetails,
      // show_horoscope: horoscopeDetails,
      show_photo: photoDetails,
    };

    let update_privacy_key = [{ key: "updateMyPrivacy", loading: true }];
    let update_privacy_query = updateMyPrivacy;
    let update_privacy_variables = { data: values };
    dispatch(
      dynamicRequest(
        update_privacy_key,
        update_privacy_query,
        update_privacy_variables,
        "M"
      )
    );
  };
  const getOptions = (all_options, element_name, parent_key) => {
    let _options = all_options;

    if (parent_key) {
      let parent_element_option = eval(parent_key);
      _options = _options?.filter(
        (x) => x.p_o.indexOf(parent_element_option) > -1
      );
    }
    if (!_options) {
      _options = [];
    }

    return _options;
  };
  const onValueChange = (_values) => {};

  const handleSubmit = (values) => {
    let variables = {
      show_contact_details: contactDetails,
      // show_horoscope: horoscopeDetails,
      show_photo: photoDetails,
      show_only_for_marital_statuses: values?.marital_statuses || [],
      show_only_for_religions: values?.religions || [],
      show_only_for_castes: values?.castes || [],
      show_only_for_sub_castes: values?.sub_castes || [],
    };

    let update_privacy_key = [{ key: "updateMyPrivacy", loading: true }];
    let update_privacy_query = updateMyPrivacy;
    let update_privacy_variables = { data: variables };
    dispatch(
      dynamicRequest(
        update_privacy_key,
        update_privacy_query,
        update_privacy_variables,
        "M"
      )
    );
  };
  useEffect(() => {
    let get_privacy_key = [{ key: "my_privacy_settings", loading: true }];
    let get_privacy_query = getMyPrivacySettings;
    // let get_privacy_variables = {};
    dispatch(dynamicRequest(get_privacy_key, get_privacy_query));
  }, []);

  useEffect(() => {
    if (getPrivacyResponse) {
      setContactDetails(getPrivacyResponse.show_contact_details);
      setHoroscopeDetails(getPrivacyResponse.show_horoscope);
      setPhotoDetails(getPrivacyResponse.show_photo);
      let marital_status_ids =
        getPrivacyResponse?.show_only_for_marital_statuses?.map((x) => x);
      let religion_ids = getPrivacyResponse?.show_only_for_religions?.map(
        (x) => x
      );
      let caste_ids = getPrivacyResponse?.show_only_for_castes?.map((x) => x);
      console.log("caste_ids", caste_ids);
      let sub_caste_ids = getPrivacyResponse?.show_only_for_sub_castes?.map(
        (x) => x
      );

      form.setFieldsValue({
        ...getPrivacyResponse,
        marital_statuses: marital_status_ids,
        religions: religion_ids,
        castes: caste_ids,
        sub_castes: sub_caste_ids,
      });
    }
  }, [getPrivacyResponse]);

  useEffect(() => {
    if (privacyUpdateStatus === "success") {
      toast.success(t("privacy_settings_updated"));
      dispatch(dynamicClear("updateMyPrivacy"));
    }
  }, [privacyUpdateStatus]);

  useEffect(() => {
    if (my_profile_details) {
      setCreatedFor(my_profile_details?.created_for);
    }
  }, [my_profile_details]);
  return (
    <>
      {privacy_loading ? (
        <Box alignItems="center" flex={1}>
          <Card
            my={5}
            maxWidth={"60vw"}
            minWidth={"30vw"}
            width={"90%"}
            backgroundColor={"#fff"}
          >
            {created_for === "myself" && (
              <Box paddingY={2} marginTop={3}>
                <Text fontWeight={"bold"} fontSize={18} mb={5} px={3}>
                  {"Show mobile number to:"}
                </Text>
                <VStack>
                  <Box
                    style={{
                      paddingLeft: 10,
                      marginTop: 5,
                    }}
                  >
                    <Skeleton.Text w={160} lines={2} />
                  </Box>
                </VStack>
              </Box>
            )}
            <Box paddingY={2} marginTop={10}>
              <Text fontWeight={"bold"} fontSize={18} mb={5} px={3}>
                {"Show Photo to:"}
              </Text>
              <VStack>
                <Box
                  style={{
                    paddingLeft: 10,
                    marginTop: 5,
                  }}
                >
                  <Skeleton.Text w={160} my={2} lines={3} />
                </Box>
              </VStack>
            </Box>
            <Box paddingY={2} marginTop={10}>
              <Text fontWeight={"bold"} fontSize={18} mb={2} px={3}>
                {t("show_profiles_only_for")}
              </Text>
              <VStack>
                <HStack space={10} width={"90%"}>
                  <Box width={"50%"}>
                    <Skeleton.Text lines={4} />
                  </Box>
                  <Box width={"50%"}>
                    <Skeleton.Text lines={4} />
                  </Box>
                </HStack>
              </VStack>
            </Box>
          </Card>
        </Box>
      ) : (
        <Box alignItems="center" flex={1}>
          <Card
            my={5}
            maxWidth={"60vw"}
            minWidth={"30vw"}
            width={"90%"}
            backgroundColor={"#fff"}
          >
            {created_for === "myself" && (
              <Box paddingY={2} marginTop={3}>
                <Text fontWeight={"bold"} fontSize={18} mb={2} px={3}>
                  {"Show mobile number to:"}
                </Text>
                <VStack>
                  <Box
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <Radio.Group
                      defaultValue="only_paid_members"
                      name="myRadioGroup"
                      accessibilityLabel="Pick your favorite number"
                      value={contactDetails}
                      onChange={(nextValue) => {
                        setContactDetails(nextValue);
                      }}
                    >
                      {/* <Radio colorScheme={"secondary"} value="all_members" my={1}>
                    <Text mx={2}>{"All members"}</Text>
                  </Radio> */}
                      <Box
                        style={{
                          borderBottomColor: "gray",
                          borderBottomWidth: 0.5,
                          marginHorizontal: 10,
                        }}
                      ></Box>
                      <Radio
                        colorScheme={"secondary"}
                        value="only_permitted_members"
                        my={1}
                      >
                        <Text mx={2}>
                          {"Members whom I contacted/responded only "}
                        </Text>
                      </Radio>
                      <Box
                        style={{
                          borderBottomColor: "gray",
                          borderBottomWidth: 0.5,
                          marginHorizontal: 10,
                        }}
                      ></Box>
                      <Radio
                        colorScheme={"secondary"}
                        value="only_paid_members"
                        my={1}
                      >
                        <Text mx={2}>{"All paid members"}</Text>
                      </Radio>
                      <Box
                        style={{
                          borderBottomColor: "gray",
                          borderBottomWidth: 0.5,
                          marginHorizontal: 10,
                        }}
                      ></Box>
                    </Radio.Group>
                  </Box>
                </VStack>
              </Box>
            )}
            {/* <Box paddingY={2} marginTop={3}>
            <Text fontWeight={"bold"} fontSize={18} mb={2} px={3}>
              {"Show horoscope only to:"}
            </Text>
            <VStack>
              <Box
                style={{
                  paddingLeft: 10,
                }}
              >
                <Radio.Group
                  defaultValue="all_members"
                  name="myRadioGroup1"
                  accessibilityLabel="Pick your favorite number"
                  value={horoscopeDetails}
                  onChange={(val) => {
                    setHoroscopeDetails(val);
                  }}
                >
                  <Radio colorScheme={"secondary"} value="all_members" my={1}>
                    <Text mx={2}>{"All members"}</Text>
                  </Radio>
                  <Box
                    style={{
                      borderBottomColor: "gray",
                      borderBottomWidth: 0.5,
                      marginHorizontal: 10,
                    }}
                  ></Box>
                  <Radio
                    colorScheme={"secondary"}
                    value="only_permitted_members"
                    my={1}
                  >
                    <Text mx={2}>
                      {"Members whom I contacted/responded only "}
                    </Text>
                  </Radio>
                  <Box
                    style={{
                      borderBottomColor: "gray",
                      borderBottomWidth: 0.5,
                      marginHorizontal: 10,
                    }}
                  ></Box>
                  <Radio
                    colorScheme={"secondary"}
                    value="only_paid_members"
                    my={1}
                  >
                    <Text mx={2}>{"Paid members only"}</Text>
                  </Radio>
                  <Box
                    style={{
                      borderBottomColor: "gray",
                      borderBottomWidth: 0.5,
                      marginHorizontal: 10,
                    }}
                  ></Box>
                </Radio.Group>
              </Box>
            </VStack>
          </Box> */}
            <Box paddingY={2} marginTop={3}>
              <Text fontWeight={"bold"} fontSize={18} mb={2} px={3}>
                {"Show Photo to:"}
              </Text>
              <VStack>
                <Box
                  style={{
                    paddingLeft: 10,
                  }}
                >
                  <Radio.Group
                    defaultValue="all_members"
                    name="myRadioGroup2"
                    accessibilityLabel="Pick your favorite number"
                    value={photoDetails}
                    onChange={(val) => {
                      setPhotoDetails(val);
                    }}
                  >
                    <Radio colorScheme={"secondary"} value="all_members" my={1}>
                      <Text mx={2}>{"All members"}</Text>
                    </Radio>
                    <Box
                      style={{
                        borderBottomColor: "gray",
                        borderBottomWidth: 0.5,
                        marginHorizontal: 10,
                      }}
                    ></Box>
                    <Radio
                      colorScheme={"secondary"}
                      value="only_permitted_members"
                      my={1}
                    >
                      <Text mx={2}>
                        {"Members whom I contacted/responded only "}
                      </Text>
                    </Radio>
                    <Box
                      style={{
                        borderBottomColor: "gray",
                        borderBottomWidth: 0.5,
                        marginHorizontal: 10,
                      }}
                    ></Box>
                    <Radio
                      colorScheme={"secondary"}
                      value="only_paid_members"
                      my={1}
                    >
                      <Text mx={2}>{"Paid members only"}</Text>
                    </Radio>
                    <Box
                      style={{
                        borderBottomColor: "gray",
                        borderBottomWidth: 0.5,
                        marginHorizontal: 10,
                      }}
                    ></Box>
                  </Radio.Group>
                </Box>
              </VStack>
            </Box>

            {/* <Box my={5} alignItems={"center"}>
              <Button
                type="primary"
                onClick={() => {
                  updatePrivacyFunction();
                }}
              >
                Submit
              </Button>
            </Box> */}
            <Form
              form={form}
              name={"preference_form"}
              className="user_form"
              layout={"horizontal"}
              onFinish={handleSubmit}
              initialValues={initial_values}
              onValuesChange={onValueChange}
            >
              <PrivacySettingsForm
                getOptions={getOptions}
                form={form}
                options_loading={privacy_loading}
              />
              <Box alignItems={"center"}>
                <Button
                  loading={update_my_privacy_loading}
                  type="primary"
                  htmlType="submit"
                >
                  {"Submit"}
                </Button>
              </Box>
            </Form>
          </Card>
        </Box>
      )}
    </>
  );
};

export default OperatorPrivacySettings;

import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  Menu,
  VStack,
  Button,
  Pressable,
  Text,
  Image,
} from "native-base";
import Avatar from "react-avatar";
import {
  clearLocalStorage,
  retrieveItem,
  useStorageItem,
} from "@helpers/storage";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { navigate } from "@helpers/navigator";

import { useTranslation } from "react-i18next";
import logo from "@assets/icons/transparent_sm.png";
import { dynamicClear } from "@services/redux";

let defaultStyles = {
  height: "70px",
};

const HeaderBar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const name = retrieveItem("name");
  const operator_name = retrieveItem("operator_name");
  const roles = JSON.parse(retrieveItem("roles"));

  let user_profile_pic = retrieveItem("user_profile_pic");
  let height = props.height || props.h || defaultStyles.height;

  const handleLogout = () => {
    dispatch(dynamicClear("admin_login"));
    clearLocalStorage();
    navigate(ROUTES.ADMIN_LOGIN);
  };

  return (
    <Box
      h={height}
      w="100%"
      bgImage={"linear-gradient(234deg,  #7d566f,#fff)"}

      // bgImage={"linear-gradient(234deg,  #712e5a,#B6739F)"}
    >
      <HStack
        h="100%"
        w="100%"
        style={{
          boxShadow: "0px 0px 20px rgb(229 218 255)",
        }}
      >
        <Box
          width={"200px"}
          alignItems={"center"}
          justifyContent={"center"}
          p="1"
        >
          <Image
            cursor={"pointer"}
            // px={2}
            // py={2}
            // size="80px"
            width={80}
            height={80}
            alt="logo"
            resizeMode={"contain"}
            source={logo}
          />
        </Box>
        <Box flex={1} justifyContent="center" pl="4">
          {/* <Text bold fontSize="lg" fontWeight="bold" color={props.color}>
            {props.projectName}
          </Text> */}
        </Box>
        <Box my={"auto"}>
          <HStack>
            <Text bold fontSize="md" fontWeight="" color="white">
              Welcome, {name ? name : operator_name}
            </Text>

            <Box>
              <Box>
                <Box right="10px" ml="6">
                  <Menu
                    w="160"
                    position="absolute"
                    right="0px"
                    top="20px"
                    style={{
                      border: "1px solid #d7d7d7",
                      boxShadow: "-2px 2px #7a7a7a42",
                    }}
                    shouldOverlapWithTrigger={true}
                    placement={"left bottom"}
                    trigger={(triggerProps) => {
                      return (
                        <Pressable
                          alignSelf="center"
                          variant="solid"
                          {...triggerProps}
                        >
                          <Avatar
                            round
                            size="25"
                            src={
                              user_profile_pic
                              // ? user_profile_pic
                              // : "https://aim-assets.s3.ap-south-1.amazonaws.com/default-avatar.png"
                            }
                          />
                        </Pressable>
                      );
                    }}
                  >
                    <Menu.Item onPress={handleLogout}>Logout</Menu.Item>
                  </Menu>
                </Box>
              </Box>
            </Box>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
};

export default HeaderBar;

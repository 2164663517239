export const StarsOptions = [
  {
    value: "any",
    en_label: "Any",
    ta_label: "ஏதேனும்",
    p_o: [],
  },
  {
    value: "dont_know",
    en_label: "Dont know",
    ta_label: "தெரியாது",
    p_o: [
      "mithunam",
      "rishabam",
      "meenam",
      "kadagam",
      "dhanusu",
      "thulam",
      "kanni",
      "viruchigam",
      "mesham",
      "kumbam",
      "magaram",
      "dont_know",
      "simmam",
    ],
  },
  {
    value: "ashwini",
    en_label: "Ashwini",
    ta_label: "அசுவினி",
    p_o: ["mesham", "dont_know"],
  },
  {
    value: "bharanai",
    en_label: "Bharani",
    ta_label: "பரணி",
    p_o: ["mesham", "dont_know"],
  },
  {
    value: "krittigai",
    en_label: "Krittigai",
    ta_label: "கிருத்திகை",
    p_o: ["rishabam", "mesham", "dont_know"],
  },
  {
    value: "rohini",
    en_label: "Rohini",
    ta_label: "ரோகிணி",
    p_o: ["rishabam", "dont_know"],
  },
  {
    value: "mirigasirisham",
    en_label: "Mirigasirisham",
    ta_label: "மிருகசிரீஷம்",
    p_o: ["mithunam", "rishabam", "dont_know"],
  },
  {
    value: "thiruvathirai",
    en_label: "Thiruvathirai",
    ta_label: "திருவாதிரை",
    p_o: ["mithunam", "dont_know"],
  },
  {
    value: "punarpoosam",
    en_label: "Punarpoosam",
    ta_label: "புனர்பூசம்",
    p_o: ["mithunam", "kadagam", "dont_know"],
  },
  {
    value: "poosam",
    en_label: "Poosam",
    ta_label: "பூசம்",
    p_o: ["kadagam", "dont_know"],
  },
  {
    value: "aayilyam",
    en_label: "Aayilyam",
    ta_label: "ஆயில்யம்",
    p_o: ["kadagam", "dont_know"],
  },
  {
    value: "magam",
    en_label: "Magam",
    ta_label: "மகம்",
    p_o: ["dont_know", "simmam"],
  },
  {
    value: "pooram",
    en_label: "Pooram",
    ta_label: "பூரம்",
    p_o: ["dont_know", "simmam"],
  },
  {
    value: "uthiram",
    en_label: "Uthiram",
    ta_label: "உத்திரம்",
    p_o: ["kanni", "dont_know", "simmam"],
  },
  {
    value: "chithirai",
    en_label: "Chithirai",
    ta_label: "சித்திரை",
    p_o: ["thulam", "kanni", "dont_know"],
  },
  {
    value: "hastham",
    en_label: "Hastham",
    ta_label: "அஸ்தம்",
    p_o: ["kanni", "dont_know"],
  },
  {
    value: "swathi",
    en_label: "Swathi",
    ta_label: "சுவாதி",
    p_o: ["thulam", "dont_know"],
  },
  {
    value: "visakam",
    en_label: "Visakam",
    ta_label: "விசாகம்",
    p_o: ["thulam", "viruchigam", "dont_know"],
  },
  {
    value: "anusham",
    en_label: "Anusham",
    ta_label: "அனுஷம்",
    p_o: ["viruchigam", "dont_know"],
  },
  {
    value: "kettai",
    en_label: "Kettai",
    ta_label: "கேட்டை",
    p_o: ["viruchigam", "dont_know"],
  },
  {
    value: "moolam",
    en_label: "Moolam",
    ta_label: "முலம்",
    p_o: ["dhanusu", "dont_know"],
  },
  {
    value: "pooradam",
    en_label: "Pooradam",
    ta_label: "பூராடம்",
    p_o: ["dhanusu", "dont_know"],
  },
  {
    value: "uthradam",
    en_label: "Uthradam",
    ta_label: "உத்திராடம்",
    p_o: ["dhanusu", "magaram", "dont_know"],
  },
  {
    value: "thiruvonam",
    en_label: "Thiruvonam",
    ta_label: "திருவோணம்",
    p_o: ["magaram", "dont_know"],
  },
  {
    value: "avitam",
    en_label: "Avittam",
    ta_label: "அவிட்டம்",
    p_o: ["kumbam", "magaram", "dont_know"],
  },
  {
    value: "sadhayam",
    en_label: "Sadhayam",
    ta_label: "சதயம்",
    p_o: ["kumbam", "dont_know"],
  },
  {
    value: "poorattathi",
    en_label: "Poorattathi",
    ta_label: "பூரட்டாதி",
    p_o: ["meenam", "kumbam", "dont_know"],
  },
  {
    value: "uthirattathi",
    en_label: "Uthirattathi",
    ta_label: "உத்திரட்டாதி",
    p_o: ["meenam", "dont_know"],
  },
  {
    value: "revathi",
    en_label: "Revathi",
    ta_label: "ரேவதி",
    p_o: ["meenam", "dont_know"],
  },
];

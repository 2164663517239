import {
  Box,
  Center,
  HStack,
  Link,
  Pressable,
  ScrollView,
  Spinner,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import { Dimensions } from "react-native";
import { AiFillCheckCircle } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

import "./styles.css";

// import required modules
import { EffectCards } from "swiper";
import { FaCrown } from "react-icons/fa";
import { Popconfirm } from "antd";
import {
  check_user_subscription_plan,
  create_user_subscription_plan,
  dynamicClear,
  dynamicRequest,
  get_user_subscription_plan,
  useDynamicSelector,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import colors from "@helpers/colors";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { useRef } from "react";

const SubscriptionPlanListWebView = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [all_plans, setAllPlans] = useState();
  const [active_id, setActiveId] = useState("");
  const payment_page_ref = useRef();
  const [payment_page_url, setPaymentPageUrl] = useState("");

  const { items: all_plans_list } = useDynamicSelector("getSubscriptionPlans");

  const {
    id: payment_url,
    status,
    loading,
    error,
  } = useDynamicSelector("createUserSubscriptionPlan");
  const { status: check_plan_status, loading: check_plan_loading } =
    useDynamicSelector("checkUserSubscriptionPlan");
  const buyPlan = (plan_id) => {
    let key = [{ key: "createUserSubscriptionPlan", loading: true }];
    let query = create_user_subscription_plan;
    let variables = { subscription_plan_id: plan_id };
    dispatch(dynamicRequest(key, query, variables, "M"));
  };

  const checkPlanStatus = (order_id) => {
    let key = [{ key: "checkUserSubscriptionPlan", loading: true }];
    let query = check_user_subscription_plan;
    let variables = { order_id: order_id };
    dispatch(dynamicRequest(key, query, variables, "M"));
  };
  useEffect(() => {
    if (status === "success") {
      // setPaymentPageUrl(payment_url);
      window.location.href = payment_url;

      // setTimeout(() => {
      //   const paymentFormElement = document.getElementById("nonseamless");
      //   if (paymentFormElement) {
      //     paymentFormElement.submit();
      //   }
      // }, 1000);
      // dispatch(dynamicClear("createUserSubscriptionPlan"));
      // navigate(`/confirm-payment/${orderId}`);
      // history.push({
      //   pathname: `${ROUTES.CONFIRM_PAYMENT}`,
      //   state: { orderId: id },
      // });

      // navigation.navigate('confirmPayment', {orderId: id, item: item});
    } else if (error) {
      toast.error(error?.message);
      dispatch(dynamicClear("createUserSubscriptionPlan"));
    }
    if (check_plan_status === "success") {
      dispatch(dynamicClear("checkUserSubscriptionPlan"));
      navigate(ROUTES.USER_SETTINGS);
      toast.error(t("payment_success"));

      // navigate(`/confirm-payment/${orderId}`);
    } else if (check_plan_status === "failure") {
      dispatch(dynamicClear("checkUserSubscriptionPlan"));
      toast.error(t("payment_failure"));

      navigate(ROUTES.USER_SETTINGS);
    } else if (check_plan_status === "pending") {
      dispatch(dynamicClear("checkUserSubscriptionPlan"));
      toast.error(t("payment_pending"));

      navigate(ROUTES.USER_SETTINGS);
    }
  }, [status, check_plan_status, error]);

  useEffect(() => {
    let get_plan_key = [{ key: "getUserSubscriptionPlans", loading: true }];
    let get_plan_query = get_user_subscription_plan;
    let get_plan_variables = null;
    dispatch(dynamicRequest(get_plan_key, get_plan_query, get_plan_variables));
  }, []);

  const planColors = [
    { ind: 0, color: colors.lightRed },
    { ind: 1, color: colors.green2 },
    { ind: 2, color: colors.skyBlue },
    { ind: 3, color: colors.orange },
    { ind: 4, color: colors.secondary },
  ];

  const randomColor = (index) => {
    let color_item = planColors.find((x) => x.ind === index);

    return color_item;
  };
  useEffect(() => {
    if (all_plans_list) {
      let _filtered_plans = all_plans_list?.filter((x) => {
        return x.is_active === true;
      });

      _filtered_plans = _filtered_plans?.filter((x) => {
        return x.allowed_purchase_count !== 1;
      });

      let list = _filtered_plans?.map((a, index) => {
        let obj = JSON.parse(JSON.stringify(a));
        obj.color = randomColor(index).color;
        return obj;
      });
      setAllPlans(list);
    }
  }, [all_plans_list]);

  return (
    <Box alignItems={"center"} height={"100vh"}>
      <HStack marginTop={10} space={5}>
        {all_plans?.map((item, index) => {
          return (
            <Box
              w={300}
              h={500}
              marginY={"5px"}
              borderRadius={20}
              backgroundColor={"#fff"}
              shadow={8}
            >
              <Box
                backgroundColor={item.color}
                borderTopLeftRadius={20}
                borderTopRightRadius={20}
                height={10}
              />
              <Box backgroundColor={"#fff"}>
                <Text
                  fontFamily={"Montserrat-SemiBold"}
                  textAlign={"center"}
                  padding={2}
                  fontSize={20}
                  color={item.color}
                >
                  {item?.en_label}
                </Text>
              </Box>
              <Box backgroundColor={item.color} height={10} />
              <Box
                backgroundColor={item.color}
                height={10}
                width={300}
                borderBottomLeftRadius={100}
                borderBottomRightRadius={100}
              />
              <Box height={20} justifyContent={"center"} alignItems={"center"}>
                {item?.discounted_price > 0 ? (
                  <>
                    <Text
                      style={{ textDecorationLine: "line-through" }}
                      fontFamily={"Montserrat-SemiBold"}
                      color={"#111"}
                      fontSize={20}
                      fontWeight="200"
                    >
                      {`₹ ${item.actual_price}`}
                    </Text>
                    <Text
                      fontFamily={"Montserrat-SemiBold"}
                      color={item.color}
                      fontSize={35}
                      fontWeight="200"
                    >
                      {`₹ ${item.discounted_price}`}
                    </Text>
                  </>
                ) : (
                  <Text
                    fontFamily={"Montserrat-SemiBold"}
                    color={item.color}
                    fontSize={35}
                    fontWeight="200"
                  >
                    {item?.allowed_purchase_count !== 1
                      ? `₹ ${item.actual_price}`
                      : "Free"}
                  </Text>
                )}
              </Box>
              <Box flex={1} padding={2}>
                {item.description?.map((a, index) => {
                  return (
                    <Box key={index} flexDirection={"row"}>
                      <AiFillCheckCircle size={20} color={item.color} />

                      <Text
                        color={item.color}
                        paddingLeft={"5px"}
                        paddingBottom={"5px"}
                        fontSize={16}
                      >
                        {a}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
              <Pressable
                style={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginBottom: 20,
                  height: 20,
                }}
                onPress={() => {
                  // if (item?.order_id) {
                  //   checkPlanStatus(item?.order_id);
                  //   setActiveId(item.id);
                  // } else {
                  buyPlan(item.id);
                  setActiveId(item.id);
                  // }
                }}
              >
                <Box
                  style={{
                    shadowOffset: { width: 0, height: 1 },
                    shadowColor: item.color,
                    elevation: 15,
                    shadowOpacity: 0.62,
                    shadowRadius: 1,
                  }}
                  alignItems="center"
                  justifyContent="center"
                  rounded={50}
                  backgroundColor={item.color}
                  borderWidth={0.5}
                  flexDirection="row"
                  width={"50%"}
                  height={30}
                  marginTop={4}
                >
                  <Box>
                    {loading && active_id === item.id ? (
                      <Center flex={1}>
                        <Spinner color={"#fff"} />
                      </Center>
                    ) : (
                      <Text
                        fontFamily={"Montserrat-SemiBold"}
                        color={"#fff"}
                        fontSize={18}
                        fontWeight="200"
                      >
                        {`BUY NOW`}
                        {/* {item?.order_id ? "Check Status" : `BUY NOW`} */}
                      </Text>
                    )}
                  </Box>
                </Box>
              </Pressable>
            </Box>
            // <Box
            //   rounded={"5px"}
            //   width={"30vw"}
            //   maxWidth={"40vw"}
            //   my={5}
            //   mx={5}
            //   alignItems={"center"}
            //   height={"60vh"}
            //   bg={
            //     index === 0
            //       ? "#f0d36c"
            //       : index === 1
            //       ? "#6cb0f0"
            //       : index === 2 && "#87CEEB"
            //   }
            // >
            //   <Box marginX={5} paddingX={"20px"} minH={"50vh"}>
            //     {/* <Box position={"absolute"} top={-12} right={-10}>
            //       <FaCrown size={25} />
            //     </Box> */}
            //     <Pressable paddingY={5}>
            //       <Box>
            //         <Text
            //           textAlign={"center"}
            //           padding={2}
            //           fontSize={16}
            //           fontWeight={"bold"}
            //         >
            //           {plan?.en_label}
            //         </Text>
            //       </Box>
            //       <Box
            //         style={{
            //           borderBottomColor: "#111",
            //           borderBottomWidth: 0.5,
            //           marginHorizontal: 10,
            //         }}
            //       ></Box>
            //       {plan?.description?.map((x) => {
            //         return (
            //           <HStack pt={2} width={"100%"}>
            //             <Box width={"10%"}>
            //               <AiFillCheckCircle size={20} color={"#111"} />
            //             </Box>
            //             <Box pl={2} width={"90%"}>
            //               <Text
            //                 color={"#111"}
            //                 fontSize={14}
            //                 fontWeight={"bold"}
            //               >
            //                 {x}
            //               </Text>
            //             </Box>
            //           </HStack>
            //         );
            //       })}

            //       <HStack pt={2} width={"100%"}>
            //         <Box width={"10%"}>
            //           <AiFillCheckCircle size={20} color={"#111"} />
            //         </Box>
            //         <Box pl={2} width={"90%"}>
            //           <Text color={"#111"} fontSize={14} fontWeight={"bold"}>
            //             {`${plan?.period_in_days} days`}
            //           </Text>
            //         </Box>
            //       </HStack>
            //     </Pressable>
            //   </Box>
            //   <Pressable
            //     style={{
            //       position: "absolute",
            //       bottom: 30,
            //       flex: 1,
            //       alignItems: "center",
            //       justifyContent: "flex-end",
            //       width: "50%",
            //     }}
            //     onPress={() => {}}
            //   >
            //     <Box
            //       style={{
            //         shadowOffset: { width: 0, height: 1 },
            //         shadowColor: "#111",
            //         elevation: 15,
            //         shadowOpacity: 0.62,
            //         shadowRadius: 2.22,
            //       }}
            //       bg="#712e5a"
            //       alignItems="center"
            //       justifyContent="center"
            //       rounded={50}
            //       borderColor="#712e5a"
            //       borderWidth={1}
            //       flexDirection="row"
            //       width={"50%"}
            //       height={30}
            //       marginTop={4}
            //     >
            //       <Box>
            //         <Popconfirm
            //           title="Make Payment?"
            //           onConfirm={() => {
            //             toast.success("Payment successful");
            //           }}
            //         >
            //           <Text color={"white"} fontSize={18} fontWeight="200">
            //             {`₹ ${plan?.actual_price}`}
            //           </Text>
            //         </Popconfirm>
            //       </Box>
            //     </Box>
            //   </Pressable>
            // </Box>
          );
        })}
      </HStack>

      <Box>
        {/* <div dangerouslySetInnerHTML={{ __html: paymentForm }} /> */}
      </Box>
    </Box>
  );
};
export default SubscriptionPlanListWebView;

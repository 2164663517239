import { Box, Card, Divider, HStack, ScrollView, Text } from "native-base";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input, Checkbox } from "antd";
import "../../../index.css";
import {
  dynamicRequest,
  dynamicSet,
  getMyPreference,
  getOperatorMatches,
  get_all_franchise_members_query,
  get_user_search_filter,
  updateMyPreference,
  updateUserProfile,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

import FranchiseFilterFormReligionDetails from "./franchise_filter_religion_details";
import { useParams } from "react-router-dom";

const FranchiseFilters = (props) => {
  const { onFilterClose, current_limit, current } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { franchise_id } = useParams();

  const [initial_values, setInitialValues] = useState([]);

  const handleSubmit = async (values) => {
    dispatch(dynamicSet("franchise_filter_values", values));

    let keys = [{ key: "getFranchiseMembers", loading: true }];
    let variables = {
      franchise_id: franchise_id || "",
      gender: values?.gender || "",
      caste: values?.caste || "",
      sub_caste: values?.sub_caste || "",
      religion: values?.religion || "",
      marital_status: values?.marital_status || "",
      page_number: current,
      page_limit: current_limit,
    };
    dispatch(dynamicRequest(keys, get_all_franchise_members_query, variables));
    onFilterClose();
  };

  const getOptions = (all_options, element_name, parent_key) => {
    let _options = all_options;

    if (parent_key) {
      let parent_element_option = eval(parent_key);
      _options = _options?.filter(
        (x) => x.p_o.indexOf(parent_element_option) > -1
      );
    }

    return _options;
  };

  return (
    <Box>
      <Form
        form={form}
        name={"preference_form"}
        className="user_form"
        layout={"horizontal"}
        onFinish={handleSubmit}
        initialValues={initial_values}
      >
        <Box
          width={"100%"}
          backgroundColor={"#fff"}
          zIndex={99}
          position={"sticky"}
          top={0}
        >
          <HStack alignItems={"center"}>
            <Box mx={5} my={5} width={"50%"}>
              <Text bold fontSize={18} mb={2} color="#712e5a">
                {/* {"Filter by Preferences"} */}
              </Text>
            </Box>
            <Box mb={2} marginLeft={3} width={"30%"}>
              <Button color="#fff" type="primary" htmlType="submit">
                {t("apply_filter")}
              </Button>
            </Box>
          </HStack>
        </Box>

        <ScrollView>
          <FranchiseFilterFormReligionDetails
            getOptions={getOptions}
            form={form}
          />
        </ScrollView>
      </Form>
    </Box>
  );
};
export default FranchiseFilters;

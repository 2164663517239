import React from "react";
import {
  Box,
  Text,
  HStack,
  Divider,
  Pressable,
  Skeleton,
  Image as _Image,
} from "native-base";
import { AiFillStar } from "react-icons/ai";
import {
  FaHeart,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaPrayingHands,
} from "react-icons/fa";
import MemberDetailCardAdmin from "@views/components/member/member_detail_card";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Popconfirm } from "antd";
import toast from "react-hot-toast";
import { CalculateAge } from "@helpers/calculate_age";
import {
  BsCardList,
  BsFillFlagFill,
  BsJournalBookmarkFill,
  BsPersonFill,
} from "react-icons/bs";
import { MdFamilyRestroom } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi";
import { FcBusinessman, FcBusinesswoman, FcManager } from "react-icons/fc";
import { getUserDetailsFromValue } from "@helpers/functions";
import { FamilyTypeOptions } from "@master_data/profile_json/family_type";
import { FamilyStatusOptions } from "@master_data/profile_json/family_status";
import { FamilyValueOptions } from "@master_data/profile_json/family_value";
import { FathersOccupationOptions } from "@master_data/profile_json/fathers_occupations";
import { MothersOccupationOptions } from "@master_data/profile_json/mothers_occupation";

const FamilyDetails = (props) => {
  const { t } = useTranslation();
  const { member } = props;
  return (
    <Box
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 8,
        boxShadow: "0 0 10px #dcdcdc",
        elevation: 5,
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        borderBottomWidth: 3,
        borderBottomColor: "#712e5a",
        // marginVertical: 10,
        marginHorizontal: 5,
        paddingHorizontal: 5,
      }}
    >
      <HStack alignItems={"center"}>
        <Box
          style={{
            paddingHorizontal: 5,
          }}
        >
          <MdFamilyRestroom size={30} color={"#712e5a"} />
        </Box>
        <Box>
          <Text fontSize={25} fontWeight={"bold"}>
            {t("family_details")}
          </Text>
        </Box>
      </HStack>
      <Divider my={2} />

      <MemberDetailCardAdmin
        element={t("family_type")}
        icon={<HiUserGroup size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          FamilyTypeOptions,
          member?.member_profile?.family_type
        )}
      />
      <MemberDetailCardAdmin
        element={t("family_status")}
        icon={<HiUserGroup size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          FamilyStatusOptions,
          member?.member_profile?.family_status
        )}
      />
      <MemberDetailCardAdmin
        element={t("family_value")}
        icon={<HiUserGroup size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          FamilyValueOptions,
          member?.member_profile?.family_value
        )}
      />
      <MemberDetailCardAdmin
        element={t("father_name")}
        icon={<FcBusinessman size={18} color={"#ab6c95"} />}
        text={member?.member_profile?.father_name}
      />
      <MemberDetailCardAdmin
        element={t("mother_name")}
        icon={<FcBusinessman size={18} color={"#ab6c95"} />}
        text={member?.member_profile?.mother_name}
      />
      <MemberDetailCardAdmin
        element={t("father_occupation")}
        icon={<FcBusinessman size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          FathersOccupationOptions,
          member?.member_profile?.father_occupation
        )}
      />
      <MemberDetailCardAdmin
        element={t("mother_occupation")}
        icon={<FcBusinesswoman size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          MothersOccupationOptions,
          member?.member_profile?.mother_occupation
        )}
      />
      <MemberDetailCardAdmin
        element={t("number_of_brothers")}
        icon={<FcManager size={18} color={"#ab6c95"} />}
        text={
          Number(member?.member_profile?.number_of_brothers) > 0
            ? `${member?.member_profile?.number_of_brothers} brother(s)`
            : "No brothers"
        }
      />
      <MemberDetailCardAdmin
        element={t("number_of_married_brothers")}
        icon={<FcManager size={18} color={"#ab6c95"} />}
        text={
          Number(member?.member_profile?.number_of_married_brothers) > 0
            ? `${member?.member_profile?.number_of_married_brothers} brother(s)`
            : "No brothers married"
        }
      />
      <MemberDetailCardAdmin
        element={t("number_of_sisters")}
        icon={<FcBusinesswoman size={18} color={"#ab6c95"} />}
        text={
          Number(member?.member_profile?.number_of_sisters) > 0
            ? `${member?.member_profile?.number_of_sisters} sister(s)`
            : "No sisters"
        }
      />
      <MemberDetailCardAdmin
        element={t("number_of_married_sisters")}
        icon={<FcBusinesswoman size={18} color={"#ab6c95"} />}
        text={
          Number(member?.member_profile?.number_of_married_sisters) > 0
            ? `${member?.member_profile?.number_of_married_sisters} sister(s)`
            : "No sister(s) married"
        }
      />
      <MemberDetailCardAdmin
        element={t("about_family")}
        icon={<HiUserGroup size={18} color={"#ab6c95"} />}
        text={
          member?.member_profile?.about_family
            ? `About Family and Expectations - ${member?.member_profile?.about_family}`
            : ""
        }
      />
    </Box>
  );
};
export default FamilyDetails;

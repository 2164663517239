import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  VStack,
  Text,
  HStack,
  Pressable,
  Center,
  Switch,
} from "native-base";
import {
  getCountry,
  getDistrict,
  getState,
} from "@helpers/get_country_state_city";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  useDynamicSelector,
  get_verifications_query,
  dynamicClear,
  get_reports_query,
  get_all_franchise_members_query,
  dynamicSet,
} from "@services/redux";
import {
  Button,
  Checkbox,
  Drawer,
  Image,
  Input,
  Pagination,
  Popconfirm,
  Space,
  Table,
} from "antd";
import { showNotification } from "@helpers/notify";
import { IMAGE_URL } from "@helpers/constants";
import { getImageUrl, getProfileUrl } from "@helpers/get_profile_url";
import { CalculateAge } from "@helpers/calculate_age";
import { useLocation, useParams } from "react-router-dom";
import { navigateBack } from "@helpers/navigator";
import { FaFilter } from "react-icons/fa";
import FranchiseFilters from "../franchise_filter/franchise_filters";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";

const AdminManageFranchiseUser = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { franchise_id } = useParams();
  const location = useLocation();
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [filter_open, setFilterOpen] = useState(false);
  const [franchise_name, setFranchiseName] = useState("");

  const franchise_filter_values = useDynamicSelector("franchise_filter_values");

  const {
    items: reports,
    loading,
    pagination,
  } = useDynamicSelector("getFranchiseMembers");

  const getAllFranchiseMembers = (search_string) => {
    let keys = [{ key: "getFranchiseMembers", loading: true }];
    let variables = {
      gender: franchise_filter_values?.gender || "",
      caste: franchise_filter_values?.caste || "",
      sub_caste: franchise_filter_values?.sub_caste || "",
      religion: franchise_filter_values?.religion || "",
      marital_status: franchise_filter_values?.marital_status || "",
      franchise_id: franchise_id || "",
      page_number: current,
      page_limit: current_limit,
      search_string: search_string || "",
    };
    dispatch(dynamicRequest(keys, get_all_franchise_members_query, variables));
  };
  useEffect(() => {
    getAllFranchiseMembers();
  }, []);
  const showFilterDrawer = () => {
    setFilterOpen(true);
  };

  const onFilterClose = () => {
    setFilterOpen(false);
  };

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "getFranchiseMembers", loading: true }];
    let variables = {
      franchise_id: franchise_id || "",
      gender: franchise_filter_values?.gender || "",
      caste: franchise_filter_values?.caste || "",
      sub_caste: franchise_filter_values?.sub_caste || "",
      religion: franchise_filter_values?.religion || "",
      marital_status: franchise_filter_values?.marital_status || "",
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(keys, get_all_franchise_members_query, variables));
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
    },
    {
      title: "Profile Number",
      dataIndex: "profile_id",
      align: "left",
      key: "profile_number",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      align: "left",
      key: "gender",
      render: (record, i) => {
        return (
          <>
            <Text>{record === "m" ? "Male" : "Female"}</Text>
          </>
        );
      },
    },
    {
      title: "Age",
      dataIndex: "dob",
      align: "left",
      key: "dob",
      render: (record, i) => {
        return (
          <>
            <Text>{CalculateAge(record)}</Text>
          </>
        );
      },
    },
    {
      title: "Created On",
      dataIndex: "",
      align: "left",
      key: "profile_number",
      render: (record) => {
        return (
          <Text>
            {moment(record?.created_datetime).format("DD/MM/YYYY hh:mm a")}
          </Text>
        );
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      align: "left",
      key: "country",
      render: (record, i) => {
        return (
          <>
            <Text>{getCountry(record)}</Text>
          </>
        );
      },
    },
    {
      title: "State",
      dataIndex: "state",
      align: "left",
      key: "state",
      render: (record, i) => {
        let Country = i.country;
        return (
          <>
            <Text>{getState(record, Country)}</Text>
          </>
        );
      },
    },
    {
      title: "District",
      dataIndex: "",
      align: "left",
      key: "district",
      render: (record) => {
        return (
          <>
            <Text>{getDistrict(_.snakeCase(record.district))}</Text>
          </>
        );
      },
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      align: "left",
      key: "pincode",
    },
    {
      title: "Subscription",
      dataIndex: "",
      align: "left",
      key: "plan",
      render: (record) => {
        return (
          <Text>
            {record?.subscriptions?.[0]?.subscription_plan?.en_label
              ? record?.subscriptions?.[0]?.subscription_plan?.en_label
              : "-"}
          </Text>
        );
      },
    },
  ];

  const handleMemberSearch = (e) => {
    if (e.target.value?.length > 3) {
      getAllFranchiseMembers(e.target.value);
    } else if (e.target.value?.length === 0) {
      getAllFranchiseMembers();
    }
  };
  const handleEnterSearch = (e) => {
    getAllFranchiseMembers(e.target.value);
  };

  useEffect(() => {
    setFranchiseName(location?.props_state);
  }, [location]);
  return (
    <Center>
      <Box width="90%" mt="20px" marginBottom="20px">
        <Box top="0px" zIndex="2" mb="5">
          {franchise_name ? (
            <Text fontSize="md" fontWeight="bold">
              {`${franchise_name} - ${t("franchise_users")} (${
                pagination?.total_count ? pagination?.total_count : 0
              })`}
            </Text>
          ) : (
            <Text fontSize="md" fontWeight="bold">
              {`${t("franchise_users")} (${
                pagination?.total_count ? pagination?.total_count : 0
              })`}
            </Text>
          )}
        </Box>
        {franchise_id && (
          <Box my={5} width={"80px"}>
            <Button
              colorScheme={"danger.700"}
              onClick={() => {
                navigateBack();
                dispatch(dynamicSet("franchise_filter_values", {}));
              }}
              style={{
                label: t("table:back"),
                variant: "outline",
              }}
            >
              Back
            </Button>
          </Box>
        )}
        <Box my={5} alignItems={"flex-end"}>
          <Input
            placeholder="Search Member"
            onChange={handleMemberSearch}
            suffix={<SearchOutlined />}
            allowClear
            style={{ width: 200 }}
            onPressEnter={handleEnterSearch}
          />
        </Box>
        <Box zIndex={3} position={"absolute"} right={0}>
          <Pressable
            onPress={() => {
              showFilterDrawer();
            }}
            w="80px"
          >
            <Box>
              <FaFilter color="#712e5a" size={30} />
            </Box>
          </Pressable>
        </Box>

        <VStack space={5}>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={reports}
              pagination={false}
              loading={loading}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </VStack>
      </Box>
      <Drawer
        width={400}
        className="ant-drawer-title ant-drawer-close"
        headerStyle={{
          backgroundColor: "#712e5a",
          textDecorationColor: "#fff",
        }}
        bodyStyle={{ backgroundColor: "#fef5fb" }}
        title={"Filter"}
        placement="right"
        onClose={onFilterClose}
        open={filter_open}
      >
        <Box>
          <FranchiseFilters
            current={current}
            current_limit={current_limit}
            onFilterClose={onFilterClose}
          />
        </Box>
      </Drawer>
    </Center>
  );
};
export default AdminManageFranchiseUser;

export const EmploymentTypesOptions = [
  {
    value: "any",
    en_label: "Any",
    ta_label: "ஏதேனும்",
    p_o: [],
  },
  {
    value: "not_working",
    en_label: "Not Working",
    ta_label: "வேலை செய்யவில்லை",
    p_o: [],
  },
  {
    value: "government_psu",
    en_label: "Government/PSU",
    ta_label: "அரசு/பொதுத்துறை",
    p_o: [],
  },
  {
    value: "private",
    en_label: "Private",
    ta_label: "தனியார்",
    p_o: [],
  },
  {
    value: "business",
    en_label: "Business",
    ta_label: "வணிகம்",
    p_o: [],
  },
  {
    value: "defence",
    en_label: "Defence",
    ta_label: "பாதுகாப்பு",
    p_o: [],
  },
  {
    value: "self_employed",
    en_label: "Self Employed",
    ta_label: "சுயதொழில்",
    p_o: [],
  },
  {
    value: "agriculture",
    en_label: "Agriculture",
    ta_label: "விவசாயம்",
    p_o: [],
  },

  {
    value: "software_engineer",
    en_label: "Software / IT",
    ta_label: "மென்பொறியாளர்",
    p_o: [],
  },
  {
    value: "engineer_non_it",
    en_label: "Engineer non IT",
    ta_label: "பொறியாளர்",
    p_o: [],
  },
  {
    value: "doctor",
    en_label: "Doctor",
    ta_label: "மருத்துவர்",
    p_o: [],
  },
];

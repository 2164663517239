import React from "react";
import { Box } from "native-base";
import AppVersionsList from "@views/components/app_versions/app_versions_list";

const ManageAppVersions = (props) => {
  return (
    <Box flex={1}>
      <Box>
        <AppVersionsList />
      </Box>
    </Box>
  );
};
export default ManageAppVersions;

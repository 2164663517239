import React, { useEffect, useState } from "react";
import MemberRectangleView from "../components/member/member_rectangle_view";
import {
  Box,
  Spinner,
  FlatList,
  Text,
  Skeleton,
  HStack,
  Image,
} from "native-base";
import { useTranslation } from "react-i18next";

const MatchesCommonComponent = (props) => {
  const { type, total_members, members_response, loading, loadMoreItem } =
    props;
  const [is_loading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (is_loading) {
      loadMoreItem(type);
    }
  }, [is_loading]);

  useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  const renderLoader = () => {
    return is_loading ? <Spinner size="lg" color="#712e5a" /> : null;
  };

  return (
    <>
      <Box
        flex={1}
        height={"100vh"}
        backgroundColor={"#fef5fb"}
        alignItems={"center"}
      >
        {loading && (!members_response || members_response.length === 0) ? (
          <>
            <Box
              w={{
                base: 300,
                md: 450,
                lg: 500,
                xl: 500,
                "2xl": 500,
                "3xl": 500,
              }}
              h={150}
              bg="white"
              shadow="2"
              rounded={"5px"}
              marginBottom={"15px"}
              marginX={5}
            >
              <HStack width={"100%"}>
                <Box
                  width={"30%"}
                  h={150}
                  marginLeft={5}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Skeleton
                    borderWidth={1}
                    borderColor="coolGray.200"
                    endColor="warmGray.50"
                    size={20}
                    rounded="full"
                  />
                </Box>
                <Box
                  justifyContent={"center"}
                  // alignItems={"center"}
                  h={150}
                  width={"70%"}
                  mx={2}
                >
                  <Skeleton.Text
                    w={{
                      base: 160,
                      md: 250,
                      lg: 290,
                      xl: 290,
                      "2xl": 290,
                      "3xl": 290,
                    }}
                    lines={3}
                  />
                </Box>
              </HStack>
            </Box>
            <Box
              w={{
                base: 300,
                md: 450,
                lg: 500,
                xl: 500,
                "2xl": 500,
                "3xl": 500,
              }}
              h={160}
              bg="white"
              shadow="2"
              rounded={"5px"}
              marginBottom={"15px"}
              marginX={5}
            >
              <HStack width={"100%"}>
                <Box
                  width={"30%"}
                  h={160}
                  marginLeft={5}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Skeleton
                    borderWidth={1}
                    borderColor="coolGray.200"
                    endColor="warmGray.50"
                    size={20}
                    rounded="full"
                  />
                </Box>
                <Box
                  justifyContent={"center"}
                  // alignItems={"center"}
                  h={160}
                  width={"70%"}
                  mx={2}
                >
                  <Skeleton.Text
                    w={{
                      base: 160,
                      md: 250,
                      lg: 290,
                      xl: 290,
                      "2xl": 290,
                      "3xl": 290,
                    }}
                    lines={3}
                  />
                </Box>
              </HStack>
            </Box>
            <Box
              w={{
                base: 300,
                md: 450,
                lg: 500,
                xl: 500,
                "2xl": 500,
                "3xl": 500,
              }}
              h={160}
              bg="white"
              shadow="2"
              rounded={"5px"}
              marginBottom={"15px"}
              marginX={5}
            >
              <HStack width={"100%"}>
                <Box
                  width={"30%"}
                  h={160}
                  marginLeft={5}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Skeleton
                    borderWidth={1}
                    borderColor="coolGray.200"
                    endColor="warmGray.50"
                    size={20}
                    rounded="full"
                  />
                </Box>
                <Box
                  justifyContent={"center"}
                  // alignItems={"center"}
                  h={160}
                  width={"70%"}
                  mx={2}
                >
                  <Skeleton.Text
                    w={{
                      base: 160,
                      md: 250,
                      lg: 290,
                      xl: 290,
                      "2xl": 290,
                      "3xl": 290,
                    }}
                    lines={3}
                  />
                </Box>
              </HStack>
            </Box>
          </>
        ) : (
          <FlatList
            data={members_response}
            renderItem={({ item, index }) => (
              <MemberRectangleView member={item} index={index} />
            )}
            keyExtractor={(item) => item?.id}
            ListFooterComponent={renderLoader}
            onEndReached={() => {
              if (total_members > members_response?.length) {
                setIsLoading(true);
              }
            }}
            onEndReachedThreshold={0}
            // scrollEnabled={false}
            showsHorizontalScrollIndicator={false}
            ListEmptyComponent={
              <Box
                width={"100%"}
                height={"100vh"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Image
                  source={{
                    uri: "https://blr1.vultrobjects.com/assets/no_data.gif",
                  }}
                  width={250}
                  height={250}
                />
                <Box
                  width={250}
                  height={250}
                  alignItems={"center"}
                  position={"absolute"}
                  bottom={10}
                >
                  {type === "all_matches" && (
                    <Text
                      style={{
                        textAlign: "center",
                      }}
                      color={"#712e5a"}
                    >
                      {t("all_matches_no_data")}
                    </Text>
                  )}
                  {type === "viewed_you" && (
                    <Text
                      style={{
                        textAlign: "center",
                      }}
                      color={"#712e5a"}
                    >
                      {t("visited_me_no_profile")}
                    </Text>
                  )}
                  {type === "viewed_by_you" && (
                    <Text
                      style={{
                        textAlign: "center",
                      }}
                      color={"#712e5a"}
                    >
                      {t("my_visits_no_profile")}
                    </Text>
                  )}
                  {type === "shortlisted_you" && (
                    <Text
                      style={{
                        textAlign: "center",
                      }}
                      color={"#712e5a"}
                    >
                      {t("shortlisted_me_no_profiles")}
                    </Text>
                  )}
                  {type === "shortlisted_by_you" && (
                    <Text
                      style={{
                        textAlign: "center",
                      }}
                      color={"#712e5a"}
                    >
                      {t("no_shortlist_profiles")}
                    </Text>
                  )}
                </Box>
              </Box>
            }
          />
        )}
      </Box>

      {/* <Box w="600px" h="90%">
        {data.length}
      </Box> */}
    </>
  );
};
export default MatchesCommonComponent;

import { Box, Hidden } from "native-base";
import React, { useEffect, useState } from "react";

import { Tabs } from "antd";
import MatchesCommonComponent from "./user_matches_common";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  getMatches,
  getMyShortlistedMembers,
  getMyVisitsProfiles,
  getShortlistedMe,
  getVisitedMeProfiles,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { page_count } from "@helpers/constants";
import UnderVerification from "@views/components/common/under_verification";
import { retrieveItem } from "@helpers/storage";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";

const UserMatches = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [current, setCurrent] = useState(0);
  const is_approved = retrieveItem("is_approved");
  const is_preference_added = retrieveItem("is_preference_added");
  const is_profile_added = retrieveItem("is_profile_added");

  const [all_matches_state, setAllMatches] = useState([]);
  const [member_visited_me_state, setMembersVisitedMe] = useState([]);
  const [my_visits_member_state, setMyVisitsMember] = useState([]);
  const [shortlisted_me_state, setShortlistedMe] = useState([]);
  const [my_shortlists_state, setMyShortlists] = useState([]);

  const {
    members_visited_me: members_visited_me_list,
    loading: members_visited_me_loading,
    members_visited_me_count,
  } = useDynamicSelector("members_visited_me");

  const {
    my_visits_of_members: my_visits_of_members_list,
    loading: my_visits_of_members_loading,
    my_visits_of_members_count,
  } = useDynamicSelector("my_visits_of_members");

  const {
    items: matches_list,
    pagination: all_matches_pagination,
    loading: matches_loading,
  } = useDynamicSelector("matches");

  const {
    my_shortlisted_members: my_shortlisted_members_list,
    loading: my_shortlists_loading,
    my_shortlisted_members_count,
  } = useDynamicSelector("my_shortlists");

  const {
    shortlisted_me: shortlisted_me_list,
    loading: short_listed_me_loading,
    shortlisted_me_count,
  } = useDynamicSelector("short_listed_me");

  const onChange = (key) => {
    setCurrent(key);
  };
  useEffect(() => {
    loadViewedByMe(1, false);
    loadShortlistedMe(1, false);
    loadMyShortlists(1, false);
  }, []);

  useEffect(() => {
    if (
      matches_list ||
      members_visited_me_list ||
      my_visits_of_members_list ||
      shortlisted_me_list ||
      my_shortlisted_members_list
    )
      setAllMatches(matches_list);
    setMembersVisitedMe(members_visited_me_list);
    setMyVisitsMember(my_visits_of_members_list);
    setShortlistedMe(shortlisted_me_list);
    setMyShortlists(my_shortlisted_members_list);
  }, [
    matches_list,
    members_visited_me_list,
    my_visits_of_members_list,
    shortlisted_me_list,
    my_shortlisted_members_list,
  ]);

  const loadAllMatches = (page_number, should_append) => {
    let keys = [
      {
        key: "matches",
        append_keys: ["items"],
        loading: true,
        appending: should_append,
      },
    ];
    let query = getMatches;
    let variables = {
      page_number: page_number,
      page_limit: page_count,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const loadViewedMe = (page_number, should_append) => {
    let keys = [
      {
        key: "members_visited_me",
        append_keys: ["members_visited_me"],
        loading: true,
        appending: should_append,
      },
    ];
    let query = getVisitedMeProfiles;
    let variables = {
      page_number: page_number,
      page_limit: page_count,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const loadViewedByMe = (page_number, should_append) => {
    let keys = [
      {
        key: "my_visits_of_members",
        append_keys: ["my_visits_of_members"],
        loading: true,
        appending: should_append,
      },
    ];
    let query = getMyVisitsProfiles;
    let variables = {
      page_number: page_number,
      page_limit: page_count,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const loadShortlistedMe = (page_number, should_append) => {
    let keys = [
      {
        key: "short_listed_me",
        append_keys: ["short_listed_me"],
        loading: true,
        appending: should_append,
      },
    ];
    let query = getShortlistedMe;
    let variables = {
      page_number: page_number,
      page_limit: page_count,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const loadMyShortlists = (page_number, should_append) => {
    let keys = [
      {
        key: "my_shortlists",
        append_keys: ["my_shortlists"],
        loading: true,
        appending: should_append,
      },
    ];
    let query = getMyShortlistedMembers;
    let variables = {
      page_number: page_number,
      page_limit: page_count,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };
  const loadMoreItem = (type) => {
    if (type === "all_matches") {
      if (
        all_matches_pagination?.total_count > matches_list?.length
          ? matches_list.length
          : 0
      ) {
        loadAllMatches(
          all_matches_pagination ? all_matches_pagination?.page_number + 1 : 1,
          true
        );
      }
    } else if (type === "viewed_you") {
      loadViewedMe(
        members_visited_me_count ? members_visited_me_count + 1 : 1,
        true
      );
    } else if (type === "viewed_by_you") {
      loadViewedByMe(
        my_visits_of_members_count ? my_visits_of_members_count + 1 : 1,
        true
      );
    } else if (type === "shortlisted_you") {
      loadShortlistedMe(
        shortlisted_me_count ? shortlisted_me_count + 1 : 1,
        true
      );
    } else if (type === "shortlisted_by_you") {
      loadMyShortlists(
        my_shortlisted_members_count ? my_shortlisted_members_count + 1 : 1,
        true
      );
    }
  };

  let wizards = [
    {
      key: "wizard1",
      label:
        // all_matches_state?.length > 0
        //   ? t("all_matches_count", {
        //       total_count: all_matches_pagination?.total_count,
        //     })
        //   :
        t("all_matches"),
      isEnabled: true,
      children: (
        <MatchesCommonComponent
          type={"all_matches"}
          total_members={
            all_matches_pagination ? all_matches_pagination?.total_count : 0
          }
          loading={matches_loading}
          members_response={all_matches_state}
          loadMoreItem={loadMoreItem}
        />
      ),
    },
    {
      key: "wizard2",
      label:
        // member_visited_me_state?.length > 0
        //   ? t("viewed_you_header_count", {
        //       total_count: members_visited_me_count,
        //     })
        //   :
        t("viewed_you_header"),

      isEnabled: true,
      children: (
        <MatchesCommonComponent
          type={"viewed_you"}
          loading={members_visited_me_loading}
          members_response={member_visited_me_state}
          loadMoreItem={loadMoreItem}
        />
      ),
    },
    {
      key: "wizard3",
      label:
        // my_visits_member_state?.length > 0
        //   ? t("viewed_by_you_header_count", {
        //       total_count: my_visits_of_members_count,
        //     })
        //   :
        t("viewed_by_you_header"),
      isEnabled: true,
      children: (
        <MatchesCommonComponent
          type={"viewed_by_you"}
          loading={my_visits_of_members_loading}
          members_response={my_visits_member_state}
          loadMoreItem={loadMoreItem}
        />
      ),
    },
    {
      key: "wizard4",
      label:
        // shortlisted_me_state?.length > 0
        //   ? t("shortlisted_you_header_count", {
        //       total_count: shortlisted_me_count,
        //     })
        //   :
        t("shortlisted_you_header"),
      isEnabled: true,
      children: (
        <MatchesCommonComponent
          type={"shortlisted_you"}
          loading={short_listed_me_loading}
          members_response={shortlisted_me_state}
          loadMoreItem={loadMoreItem}
        />
      ),
    },
    {
      key: "wizard5",
      label:
        // my_shortlisted_members_count > 0
        //   ? t("shortlisted_by_you_header_count", {
        //       total_count: my_shortlisted_members_count,
        //     })
        //   :
        t("shortlisted_by_you_header"),
      isEnabled: true,
      children: (
        <MatchesCommonComponent
          type={"shortlisted_by_you"}
          loading={my_shortlists_loading}
          members_response={my_shortlists_state}
          loadMoreItem={loadMoreItem}
        />
      ),
    },
  ];
  // checkVerificationStatus();

  if (!is_profile_added) {
    navigate(ROUTES.USER_MANAGE_PROFILE);
  } else if (!is_preference_added) {
    navigate(ROUTES.USER_MANAGE_PREFERENCE);
  } else if (is_approved === null) {
    return <UnderVerification />;
  }

  return (
    <Box flex={1} backgroundColor={"#fef5fb"} height={"100vh"} width={"100%"}>
      <Hidden from="md">
        <Tabs
          defaultActiveKey="wizard1"
          type="card"
          tabBarStyle={{
            height: 50,
          }}
          onChange={onChange}
          items={wizards.map((item) => {
            return {
              label: item.label,
              key: item.key,
              children: current ? item.children : item.children,
              disabled: !item.isEnabled,
              style:
                item.key === current
                  ? {
                      color: "#712e5a",
                    }
                  : undefined,
            };
          })}
        />
      </Hidden>
      <Hidden till="md">
        <Tabs
          defaultActiveKey="wizard1"
          centered
          type="line"
          style={{
            bg: "white",
            shadow: "2",
            rounded: "5px",
            // marginBottom: "15px",
            marginX: 5,
            paddingTop: "5px",
            alignItems: "center",
          }}
          onChange={onChange}
          items={wizards.map((item) => {
            return {
              label: item.label,
              key: item.key,
              children: current ? item.children : item.children,
              disabled: !item.isEnabled,
              style:
                item.key === current
                  ? {
                      color: "#712e5a",
                    }
                  : undefined,
            };
          })}
        />
      </Hidden>
    </Box>
  );
};
export default UserMatches;

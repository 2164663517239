import React from "react";
import { Box, Center } from "native-base";
import AdminFranchisePlanPromoCode from "@views/components/admin_franchise/admin_franchise_promo_code/admin_franchise_promo_code_list";
const AdminPromoCode = () => {
  return (
    <Center>
      <Box alignItems="center" width={"90%"}>
        <AdminFranchisePlanPromoCode />
      </Box>
    </Center>
  );
};

export default AdminPromoCode;

import React from "react";
import {
  Box,
  Text,
  HStack,
  Divider,
  Pressable,
  Skeleton,
  Image as _Image,
} from "native-base";
import { AiFillStar } from "react-icons/ai";
import {
  FaHeart,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaPrayingHands,
} from "react-icons/fa";

import { Modal, Popconfirm } from "antd";
import toast from "react-hot-toast";
import { CalculateAge, CalculateAgeForMember } from "@helpers/calculate_age";
import {
  BsCardList,
  BsFillFlagFill,
  BsJournalBookmarkFill,
  BsPersonFill,
} from "react-icons/bs";
import { GiBigDiamondRing, GiBodyHeight } from "react-icons/gi";

import { ImLocation, ImSpoonKnife } from "react-icons/im";
import {
  MdFamilyRestroom,
  MdLanguage,
  MdOutlineWheelchairPickup,
  MdSmokingRooms,
  MdWork,
} from "react-icons/md";
import { BiDrink } from "react-icons/bi";

import { IoMdSchool } from "react-icons/io";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import MemberDetailCardAdmin from "@views/components/member/member_detail_card";
import { getUserDetailsFromValue } from "@helpers/functions";
import { HeightOptions } from "@master_data/profile_json/height";
import { WeightOptions } from "@master_data/profile_json/weight";
import { MaritalStatusOptions } from "@master_data/common_json/marital_status";
import _ from "lodash";
import { BodyTypesOptions } from "@master_data/profile_json/body_type";
import { ComplexionOptions } from "@master_data/profile_json/complexion";
import { PhysicalStatusOptions } from "@master_data/common_json/physical_status";
import { MotherTongueOptions } from "@master_data/common_json/mother_tongue";
import { EatingHabitsOptions } from "@master_data/common_json/eating_habits";
import { DrinkingHabitsOptions } from "@master_data/common_json/drinking_habits";
import { SmokingHabitsOptions } from "@master_data/common_json/smoking_habits";
import UserPhotos from "@views/components/user_manage_profile/user_photos";

const BasicDetails = (props) => {
  const { member } = props;

  const { t } = useTranslation();

  return (
    <Box
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 8,
        boxShadow: "0 0 10px #dcdcdc",
        elevation: 5,
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        borderBottomWidth: 3,
        borderBottomColor: "#712e5a",
        // marginVertical: 10,
        marginHorizontal: 5,
        paddingHorizontal: 5,
      }}
    >
      <HStack alignItems={"center"}>
        <Box
          style={{
            paddingHorizontal: 5,
          }}
        >
          <BsCardList size={30} color={"#712e5a"} />
        </Box>
        <Box>
          <Text fontSize={25} fontWeight={"bold"}>
            {t("basic_details")}
          </Text>
        </Box>
      </HStack>

      <Divider my={2} />

      <MemberDetailCardAdmin
        element={t("age")}
        icon={<BsPersonFill size={18} color={"#ab6c95"} />}
        text={CalculateAgeForMember(member?.member_profile?.dob)}
      />
      <MemberDetailCardAdmin
        element={t("profile_created_for")}
        icon={<BsPersonFill size={18} color={"#ab6c95"} />}
        text={_.startCase(member?.member_profile?.created_for)}
      />
      <MemberDetailCardAdmin
        element={t("height")}
        icon={<GiBodyHeight size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          HeightOptions,
          member?.member_profile?.height
        )}
      />
      <MemberDetailCardAdmin
        element={t("weight")}
        icon={<FaHeart size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          WeightOptions,
          member?.member_profile?.weight
        )}
      />
      <MemberDetailCardAdmin
        element={t("marital_status")}
        icon={<GiBigDiamondRing size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          MaritalStatusOptions,
          member?.member_profile?.marital_status
        )}
      />
      <MemberDetailCardAdmin
        element={t("body_type")}
        icon={<FaHeart size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          BodyTypesOptions,
          member?.member_profile?.body_type
        )}
      />
      <MemberDetailCardAdmin
        element={t("complexion")}
        icon={<FaHeart size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          ComplexionOptions,
          member?.member_profile?.complexion
        )}
      />
      <MemberDetailCardAdmin
        element={t("physical_status")}
        icon={<MdOutlineWheelchairPickup size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          PhysicalStatusOptions,
          member?.member_profile?.physical_status
        )}
      />
      <MemberDetailCardAdmin
        element={t("mother_tongue")}
        icon={<MdLanguage size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          MotherTongueOptions,
          member?.member_profile?.mother_tongue
        )}
      />
      <MemberDetailCardAdmin
        element={t("eating_habit")}
        icon={<ImSpoonKnife size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          EatingHabitsOptions,
          member?.member_profile?.eating_habit
        )}
      />
      <MemberDetailCardAdmin
        element={t("drinking_habit")}
        icon={<BiDrink size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          DrinkingHabitsOptions,
          member?.member_profile?.drinking_habit
        )}
      />
      <MemberDetailCardAdmin
        element={t("smoking_habit")}
        icon={<MdSmokingRooms size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          SmokingHabitsOptions,
          member?.member_profile?.smoking_habit
        )}
      />
    </Box>
  );
};
export default BasicDetails;

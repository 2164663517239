import React, { useEffect, useState } from "react";
import {
  Box,
  Pressable,
  Text,
  VStack,
  HStack,
  Menu,
  Divider,
  Hidden,
  Tooltip,
  Spacer,
} from "native-base";
import { Dimensions } from "react-native";
import moment from "moment";
import MemberAvatar from "./member_avatar";
import { navigate } from "@helpers/navigator";
import { useTranslation } from "react-i18next";
import { Button, Space } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  getMyIgnoredProfiles,
  unignoreMember,
  useDynamicSelector,
} from "@services/redux";
import toast from "react-hot-toast";
import { CalculateAge } from "@helpers/calculate_age";
import { useDispatch } from "react-redux";

const MemberIgnore = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { member, index, title } = props;

  const { status: unignoreMemberStatus } = useDynamicSelector("unignoreMember");

  const getIgnoredMemberList = () => {
    let get_my_blocked_user_key = [{ key: "ignored_members", loading: true }];
    let get_my_blocked_user_query = getMyIgnoredProfiles;
    let get_my_blocked_user_variables = null;
    dispatch(
      dynamicRequest(
        get_my_blocked_user_key,
        get_my_blocked_user_query,
        get_my_blocked_user_variables
      )
    );
  };

  const unignoreMemberMutate = (member_id) => {
    let un_block_member_key = [{ key: "unignoreMember", loading: true }];
    let un_block_member_query = unignoreMember;
    let un_block_member_variables = { id: member_id };
    dispatch(
      dynamicRequest(
        un_block_member_key,
        un_block_member_query,
        un_block_member_variables,
        "M"
      )
    );
  };

  useEffect(() => {
    if (unignoreMemberStatus === "success") {
      toast.success(t("profile_unignored"));
      getIgnoredMemberList();
      dispatch(dynamicClear("unignoreMember"));
    }
  }, [unignoreMemberStatus]);
  return (
    <Box
      key={index}
      w={{ base: 300, md: 450, lg: 500, xl: 500, "2xl": 500, "3xl": 500 }}
      h={100}
      bg="white"
      shadow="2"
      rounded={"5px"}
      marginBottom={"15px"}
      justifyContent={"center"}
    >
      <Pressable flexDirection="row" alignItems={"center"}>
        <Box
          cursor={"pointer"}
          onClick={() => {
            navigate(`member/${member?.id}`);
          }}
          alignItems={"center"}
          justifyContent={"center"}
          width={{ base: "120px", xs: "100px", sm: "100px" }}
        >
          <MemberAvatar
            src={
              member?.member_cover_photo?.cropped_photo_url
                ? member?.member_cover_photo?.cropped_photo_url
                : member?.member_profile?.gender === "f"
                ? "https://assets.blr1.vultrobjects.com/female-avatar.png"
                : "https://assets.blr1.vultrobjects.com/male-avatar.png"
            }
            width={80}
            height={80}
          />
        </Box>

        <Box flexDirection="row" flex={1}>
          <Box w={"100%"}>
            <VStack>
              <Text fontSize={16} fontWeight={"700"}>
                {member?.member_profile?.name}
              </Text>
              <Text color="#64748B" fontSize={12} fontWeight={"400"}>
                {CalculateAge(member?.member_profile?.dob)}
              </Text>
            </VStack>
          </Box>
        </Box>
        <Box
          alignItems={"center"}
          mx={5}
          width={{ base: "100px", xs: "80px", sm: "80px" }}
        >
          <Button
            type="primary"
            onClick={() => {
              unignoreMemberMutate(member?.id);
            }}
          >
            {title}
          </Button>
        </Box>
      </Pressable>
    </Box>
  );
};
export default MemberIgnore;

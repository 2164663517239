export const SubCastesOptions = [
  {
    value: "any",
    en_label: "Any",
    ta_label: "ஏதேனும்",
    p_o: [
      "gounder",
      "vishwakarma",
      "vaicker",
      "caste_no_bar",
      "naidu",
      "mudaliyar",
      "veera_saivam",
      "moopanar",
      "kulalar",
      "chettiar",
      "boyar",
      "muthuraja_mutharaiyar",
      "devandra_kula_vellalar",
      "vannar",
      "brahmin_gurukal",
      "vanniyar_vanniya_kula_kshatriyar",
      "pillai",
      "meenavar",
      "pentecost",
      "christian_nadar",
      "christian_others",
      "brahmin_iyengar",
      "brahmin_iyer",
      "syrian_catholic",
      "devar_thevar_mukkulathor_agamudaiyar",
      "inter_caste",
      "roman_catholic",
      "church_of_south_india",
      "reddy_reddyar",
      "parkava_kulam_udayar",
      "yadav_konar",
      "udayar",
      "christian_telc ",
      "any",
      "nadar",
    ],
  },
  {
    value: "aaru_nattu_vellala",
    en_label: "Arunattu Vellalar",
    ta_label: "ஆறுநாட்டு வெள்ளாளர்",
    p_o: ["pillai"],
  },
  {
    value: "agamudayar_arcot_thuluva_vellala",
    en_label: "Agamudayar Arcot Thuluva Vellala",
    ta_label: "அகமுடையார்_ஆற்காடு_துளுவ வெள்ளாளர்",
    p_o: ["pillai"],
  },
  {
    value: "cherakula_vellalar",
    en_label: "Cherakula Vellalar",
    ta_label: "சேரகுல வேளாளர்",
    p_o: ["pillai"],
  },
  {
    value: "desikar",
    en_label: "Desikar",
    ta_label: "தேசிகர்",
    p_o: ["pillai"],
  },
  {
    value: "desikar_thanjavur",
    en_label: "Desikar Thanjavur",
    ta_label: "தேசிகர் தஞ்சாவூர்",
    p_o: ["pillai"],
  },
  {
    value: "illathu_pillai",
    en_label: "Illathu Pillai",
    ta_label: "இல்லத்துப் பிள்ளைமார்",
    p_o: [],
  },
  {
    value: "isai_vellalar",
    en_label: "Isai Vellalar",
    ta_label: "இசை வேளாளர்",
    p_o: ["pillai"],
  },
  {
    value: "karkathar",
    en_label: "Karkathar",
    ta_label: "கார்காத்தார்",
    p_o: ["pillai"],
  },
  {
    value: "kodikal_pilai",
    en_label: "Kodikal Pilai",
    ta_label: "கொடிக்கால் பிள்ளைமார்",
    p_o: ["pillai"],
  },
  {
    value: "nanjil_mudali",
    en_label: "Nanjil Mudali",
    ta_label: "நாஞ்சில் முதலி",
    p_o: ["pillai"],
  },
  {
    value: "nanjil_pillai",
    en_label: "Nanjil Pillai",
    ta_label: "null",
    p_o: ["pillai"],
  },
  {
    value: "nankudi_vellalar",
    en_label: "Nankudi Vellalar",
    ta_label: "நன்குடி வேளாளர்",
    p_o: ["pillai"],
  },
  {
    value: "othuvaar",
    en_label: "Othuvaar",
    ta_label: "ஓதுவார்",
    p_o: ["pillai"],
  },
  {
    value: "pandiya_vellalar",
    en_label: "Pandiya Vellalar",
    ta_label: "பாண்டிய வேளாளர்",
    p_o: ["pillai"],
  },
  {
    value: "saiva_pillai_thanjavur",
    en_label: "Saiva Pillai Thanjavur",
    ta_label: "சைவப் பிள்ளை தஞ்சாவூர்",
    p_o: ["pillai"],
  },
  {
    value: "saiva_pillai_tirunelveli",
    en_label: "Saiva Pillai Tirunelveli",
    ta_label: "சைவப் பிள்ளை திருநெல்வேலி",
    p_o: ["pillai"],
  },
  {
    value: "sengunthar_kaikolar",
    en_label: "Sengunthar_Kaikolar",
    ta_label: "செங்குந்தர்_கைக்கோலர்",
    p_o: ["pillai"],
  },
  {
    value: "sozhiya_vellalar",
    en_label: "Sozhiya Vellalar",
    ta_label: "சோழிய வெள்ளாளர்",
    p_o: ["pillai"],
  },
  {
    value: "thondai_mandala_vellala",
    en_label: "Thondai Mandala Vellala",
    ta_label: "தொண்டை மண்டல வெள்ளாளர்",
    p_o: ["pillai"],
  },
  {
    value: "veerakodi_vellala",
    en_label: "Veerakodi Vellala",
    ta_label: "வீரக்கொடி வெள்ளாளர்",
    p_o: ["pillai"],
  },
  {
    value: "pillai_others",
    en_label: "Pillai Others",
    ta_label: "பிள்ளை மற்றவை",
    p_o: ["pillai"],
  },
  {
    value: "balija_naidu",
    en_label: "Balija Naidu",
    ta_label: "பலிஜா நாயுடு",
    p_o: ["naidu"],
  },
  {
    value: "kamma_naidu",
    en_label: "Kamma Naidu",
    ta_label: "கம்மவார்  நாயுடு",
    p_o: ["naidu"],
  },
  {
    value: "kapu_naidu",
    en_label: "Kapu Naidu",
    ta_label: "காப்பு நாயுடு",
    p_o: ["naidu"],
  },
  {
    value: "gavarav",
    en_label: "Gavarav",
    ta_label: "கவரா",
    p_o: ["naidu"],
  },
  {
    value: "munnuru_kapu",
    en_label: "Munnuru Kapu",
    ta_label: "முன்னூறு காப்பு",
    p_o: ["naidu"],
  },
  {
    value: "naidu_others",
    en_label: "Naidu others",
    ta_label: "நாயுடு மற்றவை",
    p_o: ["naidu"],
  },
  {
    value: "gramani_sanar",
    en_label: "Gramani Sanar",
    ta_label: "கிராமணி_சாணார",
    p_o: ["nadar"],
  },
  {
    value: "hindu_nadar",
    en_label: "Hindhu Nadar",
    ta_label: "இந்து நாடார்",
    p_o: ["nadar"],
  },
  {
    value: "kongu_nadar",
    en_label: "Kongu Nadar",
    ta_label: "கொங்கு நாடார்",
    p_o: ["nadar"],
  },
  {
    value: "nadar_others",
    en_label: "Nadar Others",
    ta_label: "நாடார் மற்றவை",
    p_o: ["nadar"],
  },
  {
    value: "manai_telugu_chettiar",
    en_label: "24 Manai Telugu Chettiar",
    ta_label: "24 மனை தெலுங்கு செட்டியார்",
    p_o: ["chettiar"],
  },
  {
    value: "manai_telugu_chettiar_16_veedu",
    en_label: "24 Manai Telugu Chettiar 16 Veedu",
    ta_label: "24 மனை தெலுங்கு செட்டியார் 16 வீடு",
    p_o: ["chettiar"],
  },
  {
    value: "manai_telugu_chettiar_8_veedu",
    en_label: "24 Manai Telugu Chettiar 8 Veedu",
    ta_label: "24 மனை தெலுங்கு செட்டியார் 8 வீடு",
    p_o: ["chettiar"],
  },
  {
    value: "achirapakkam_chettiar",
    en_label: "Achirapakkam Chettiar",
    ta_label: "அச்சிறுப்பாக்கம் செட்டியார்",
    p_o: ["chettiar"],
  },
  {
    value: "arya_vysya",
    en_label: "Arya Vysya",
    ta_label: "ஆர்ய  வைஸ்யா",
    p_o: ["chettiar"],
  },
  {
    value: "agaram_vellan_chettiar",
    en_label: "Agaram Vellan Chettiar",
    ta_label: "அகரம் வெள்ளாஞ்செட்டியார்",
    p_o: ["chettiar"],
  },
  {
    value: "beri_chettiar",
    en_label: "Beri Chettiar",
    ta_label: "பெரி செட்டியார்",
    p_o: ["chettiar"],
  },
  {
    value: "devanga_chettiar",
    en_label: "Devanga Chettiar",
    ta_label: null,
    p_o: ["chettiar"],
  },
  {
    value: "elur",
    en_label: "Elur",
    ta_label: "ஏழூர்",
    p_o: ["chettiar"],
  },
  {
    value: "gandla_ganiga",
    en_label: "Gandla_ Ganiga",
    ta_label: "null",
    p_o: ["chettiar"],
  },
  {
    value: "kasukara",
    en_label: "Kasukara",
    ta_label: "null",
    p_o: ["chettiar"],
  },
  {
    value: "kongu_chettiar",
    en_label: "Kongu Chettiar",
    ta_label: "கொங்கு செட்டியார்",
    p_o: ["chettiar"],
  },
  {
    value: "kuruhini_chetty",
    en_label: "Kuruhini Chetty",
    ta_label: "குருஹினி செட்டி",
    p_o: ["chettiar"],
  },
  {
    value: "manjapudur_chettiar",
    en_label: "Manjapudur Chettiar",
    ta_label: "மஞ்சப்புதூர் செட்டியார்",
    p_o: ["chettiar"],
  },
  {
    value: "nattukottai_chettiar",
    en_label: "Nattukottai Chettiar",
    ta_label: "நாட்டுக்கோட்டை செட்டியார்",
    p_o: ["chettiar"],
  },
  {
    value: "padma_saliar",
    en_label: "Padma Saliar",
    ta_label: "பத்ம சாலியர்",
    p_o: ["chettiar"],
  },
  {
    value: "parvatha_rajakulam",
    en_label: "Parvatha Rajakulam",
    ta_label: "பர்வத ராஜகுலம்",
    p_o: ["chettiar"],
  },
  {
    value: "pannirandam_chettiar",
    en_label: "Pannirandam Chettiar",
    ta_label: "பன்னிரண்டம் செட்டியார்",
    p_o: ["chettiar"],
  },
  {
    value: "chettiar_others",
    en_label: "Chettiar Others",
    ta_label: "செட்டியார் மற்றவை",
    p_o: ["chettiar"],
  },
  {
    value: "mudaliyar",
    en_label: "Mudaliyar",
    ta_label: "முதலியார்",
    p_o: ["mudaliyar"],
  },
  {
    value: "nanjil_mudali",
    en_label: "Nanjil Mudali",
    ta_label: "நாஞ்சில் முதலி",
    p_o: ["mudaliyar"],
  },
  {
    value: "செங்குந்த முதலியார்",
    en_label: "Senguntha Mudaliyar",
    ta_label: "null",
    p_o: ["mudaliyar"],
  },
  {
    value: "mudaliyar_others",
    en_label: "Mudaliyar Others",
    ta_label: "முதலியார் மற்றவை",
    p_o: ["mudaliyar"],
  },
  {
    value: "kongu_vellala_gounder",
    en_label: "Kongu Vellala Gounder",
    ta_label: "கொங்கு வெள்ளாள கவுண்டர்",
    p_o: ["gounder"],
  },
  {
    value: "vettuva_gounder",
    en_label: "Vettuva Gounder",
    ta_label: "வேட்டுவக் கவுண்டர்",
    p_o: ["gounder"],
  },
  {
    value: "nattu_gounder",
    en_label: "Nattu Gounder",
    ta_label: "நாட்டு கவுண்டர்",
    p_o: ["gounder"],
  },
  {
    value: "urali_gounder",
    en_label: "Urali Gounder",
    ta_label: "உரலி கவுண்டர்",
    p_o: ["gounder"],
  },
  // {
  //   value: "vanniya_kula_kshatriyar",
  //   en_label: "Vanniya Kula Kshatriyar",
  //   ta_label: null,
  //   p_o: ["gounder"],
  // },
  {
    value: "vanniya_gounder",
    en_label: "Vanniya Gounder",
    ta_label: "வன்னிய கவுண்டர்",
    p_o: ["vanniyar_vanniya_kula_kshatriyar"],
  },
  {
    value: "gounders_others",
    en_label: "Gounders Others",
    ta_label: "கவுண்டர்கள் மற்றவை",
    p_o: ["gounder"],
  },
  {
    value: "vanniya_kula_kshatriyar",
    en_label: "Vanniya Kula Kshatriyar",
    ta_label: "வன்னிய குல க்ஷத்திரியர்",
    p_o: ["vanniyar_vanniya_kula_kshatriyar"],
  },
  // {
  //   value: "vanniya_kula_kshatriyar",
  //   en_label: "Vanniya Kula Kshatriyar",
  //   ta_label: null,
  //   p_o: ["vanniyar_vanniya_kula_kshatriyar"],
  // },
  // {
  //   value: "vanniya_kula_kshatriyar",
  //   en_label: "Vanniya Kula Kshatriyar",
  //   ta_label: "வன்னிய குல க்ஷத்திரியர்",
  //   p_o: ["vanniyar_vanniya_kula_kshatriyar"],
  // },
  {
    value: "arasu",
    en_label: "Arasu",
    ta_label: "அரசு",
    p_o: ["vanniyar_vanniya_kula_kshatriyar"],
  },
  {
    value: "gounder",
    en_label: "Gounder",
    ta_label: "கவுண்டர்",
    p_o: ["vanniyar_vanniya_kula_kshatriyar"],
  },
  {
    value: "naicker",
    en_label: "Naicker",
    ta_label: "நாயக்கர்",
    p_o: ["vanniyar_vanniya_kula_kshatriyar"],
  },
  {
    value: "padayachi",
    en_label: "Padayachi",
    ta_label: "படையாச்சி",
    p_o: ["vanniyar_vanniya_kula_kshatriyar"],
  },
  {
    value: "palli",
    en_label: "Palli",
    ta_label: "பள்ளி",
    p_o: ["vanniyar_vanniya_kula_kshatriyar"],
  },
  {
    value: "vanniyar_others",
    en_label: "Vanniyar Others",
    ta_label: "வன்னியர் மற்றவை",
    p_o: ["vanniyar_vanniya_kula_kshatriyar"],
  },
  {
    value: "naicker",
    en_label: "Naicker",
    ta_label: "நாயக்கர்",
    p_o: ["naicker"],
  },
  {
    value: "padayachi",
    en_label: "Padayachi",
    ta_label: "படையாச்சி",
    p_o: ["naicker"],
  },
  {
    value: "pallar",
    en_label: "Pallar",
    ta_label: "பள்ளர்",
    p_o: ["devandra_kula_vellalar"],
  },
  {
    value: "pandal",
    en_label: "Pandal",
    ta_label: "பந்தல்",
    p_o: ["naicker"],
  },
  {
    value: "naicker_others",
    en_label: "Naicker Others",
    ta_label: "நாயக்கர் மற்றவை",
    p_o: ["naicker"],
  },
  {
    value: "agamudayar",
    en_label: "Agamudayar",
    ta_label: "அகமுடையார்",
    p_o: ["naicker"],
  },
  {
    value: "ambalakarar",
    en_label: "Ambalakarar",
    ta_label: "அம்பலகாரர்",
    p_o: ["naicker"],
  },
  {
    value: "appanad_kondayamkottai_maravar",
    en_label: "Appanad Kondayamkottai Maravar",
    ta_label: "அப்பநாடு கொண்டையங்கோட்டை மறவர்",
    p_o: ["naicker"],
  },
  {
    value: "easanattu_kallar",
    en_label: "Easanattu Kallar",
    ta_label: "ஈசநாட்டுக் கள்ளர்",
    p_o: ["devar_thevar_mukkulathor_agamudaiyar"],
  },
  {
    value: "kallar",
    en_label: "Kallar",
    ta_label: "கள்ளர்",
    p_o: ["devar_thevar_mukkulathor_agamudaiyar"],
  },
  {
    value: "maniyakarar",
    en_label: "Maniyakarar",
    ta_label: "மணியகாரர்",
    p_o: ["devar_thevar_mukkulathor_agamudaiyar"],
  },
  {
    value: "maravar",
    en_label: "Maravar",
    ta_label: "மறவர்",
    p_o: ["naicker", "devar_thevar_mukkulathor_agamudaiyar"],
  },
  {
    value: "piramalai_kalla",
    en_label: "Piramalai Kalla",
    ta_label: "பிரமலைக் கள்ளர்",
    p_o: ["naicker"],
  },
  {
    value: "rajakula_agamudayar",
    en_label: "Rajakula Agamudayar",
    ta_label: "ராஜகுல அகமுடையார்",
    p_o: ["naicker"],
  },
  {
    value: "sembanad_maravar",
    en_label: "Sembanad Maravar",
    ta_label: "செம்மநாட்டு மறவர்",
    p_o: ["naicker"],
  },
  {
    value: "servai",
    en_label: "Servai",
    ta_label: "சேர்வை",
    p_o: ["naicker"],
  },
  {
    value: "thanjavur_kallar",
    en_label: "Thanjavur Kallar",
    ta_label: "தஞ்சாவூர் கள்ளர்",
    p_o: ["naicker"],
  },
  {
    value: "vallambar",
    en_label: "Vallambar",
    ta_label: "வல்லம்பர்",
    p_o: ["naicker"],
  },
  {
    value: "others",
    en_label: "Others",
    ta_label: "தேவர்_மற்றவை",
    p_o: ["naicker"],
  },
  {
    value: "vadla_vadrangi",
    en_label: "Vadla, Vadrangi",
    ta_label: null,
    p_o: ["vishwakarma"],
  },
  {
    value: "pathar",
    en_label: "Pathar",
    ta_label: null,
    p_o: ["vishwakarma"],
  },
  {
    value: "sitpi",
    en_label: "Sitpi",
    ta_label: null,
    p_o: ["vishwakarma"],
  },
  {
    value: "thachaasari",
    en_label: "Thachaasari",
    ta_label: "தச்சு ஆசாரி",
    p_o: ["vishwakarma"],
  },
  {
    value: "kamalar",
    en_label: "Kamalar",
    ta_label: null,
    p_o: ["vishwakarma"],
  },
  {
    value: "vishwakarma_others",
    en_label: "Vishwakarma Others",
    ta_label: "விஸ்வகர்மா மற்றவை",
    p_o: ["vishwakarma"],
  },
  {
    value: "vadakalai",
    en_label: "Vadakalai",
    ta_label: "வடகலை",
    p_o: ["brahmin - iyengar"],
  },
  {
    value: "tenkalai",
    en_label: "Tenkalai",
    ta_label: "தென்கலை",
    p_o: ["brahmin - iyengar"],
  },
  {
    value: "gurukal",
    en_label: "Gurukal",
    ta_label: "குருக்கள்",
    p_o: ["brahmin_iyer"],
  },
  {
    value: "vadama",
    en_label: "Vadama",
    ta_label: "வடமா",
    p_o: ["brahmin_iyer"],
  },
  {
    value: "brahacharanam",
    en_label: "Brahacharanam",
    ta_label: "பிரகச்சரணம்",
    p_o: ["brahmin_iyer"],
  },
  {
    value: "vathima",
    en_label: "Vathima",
    ta_label: "வாத்திமா",
    p_o: ["brahmin_iyer"],
  },
  {
    value: "ashtasahasram",
    en_label: "Ashtasahasram",
    ta_label: "அஷ்டசகஸ்ரம்",
    p_o: ["brahmin_iyer"],
  },
  {
    value: "telugu_brahmin",
    en_label: "Telugu Brahmin",
    ta_label: "தெலுங்கு பிராமணர்",
    p_o: ["brahmin_iyer"],
  },
  {
    value: "kannada_brahminr",
    en_label: "Kannada Brahminr",
    ta_label: "கன்னட பிராமணர்",
    p_o: ["brahmin_iyer"],
  },
  {
    value: "kayalar",
    en_label: "Kayalar",
    ta_label: null,
    p_o: [],
  },
  {
    value: "lebbai_Labbai",
    en_label: "Lebbai_Labbai",
    ta_label: "லெப்பை",
    p_o: [],
  },
  {
    value: "maraikayar",
    en_label: "Maraikayar",
    ta_label: "மரைக்காயர்",
    p_o: [],
  },
  {
    value: "pattans_pattani",
    en_label: "Pattans_Pattani",
    ta_label: "பட்டாணி",
    p_o: [],
  },
  {
    value: "rowther",
    en_label: "Rowther",
    ta_label: "ராவுத்தர்",
    p_o: [],
  },
  {
    value: "muslim_others",
    en_label: "Muslim Others",
    ta_label: "முஸ்லிம் மற்றவை",
    p_o: [],
  },
  {
    value: "church_of_sounth_india",
    en_label: "Church of Sounth India",
    ta_label: "தென்னிந்திய தேவாலயம்",
    p_o: [],
  },
  {
    value: "roman_catholic",
    en_label: "Roman Catholic",
    ta_label: "ரோமன் கத்தோலிக்க",
    p_o: [],
  },
  {
    value: "syrian_catholic",
    en_label: "Syrian Catholic",
    ta_label: "கத்தோலிக் சிரியன் ",
    p_o: [],
  },
  {
    value: "pentecost",
    en_label: "Pentecost",
    ta_label: "பெந்தெகொஸ்தே",
    p_o: [],
  },
  {
    value: "christian_nadar",
    en_label: "Christian Nadar",
    ta_label: "கிறிஸ்துவ நாடார்",
    p_o: [],
  },
  {
    value: "don’t_wish_to_specify",
    en_label: "Don’t Wish to Specify",
    ta_label: null,
    p_o: [],
  },
  {
    value: "others",
    en_label: "Others",
    ta_label: null,
    p_o: [],
  },
  {
    value: "konar",
    en_label: "Konar",
    ta_label: "கோனார்",
    p_o: ["yadav_konar"],
  },
  {
    value: "kammavar",
    en_label: "Kammavar",
    ta_label: "கம்மவார் நாயுடு",
    p_o: ["naidu"],
  },
  {
    value: "uppiliya_naicker",
    en_label: "Uppiliya Naicker",
    ta_label: "உப்பிலிய நாயக்கர்",
    p_o: ["naicker"],
  },
  {
    value: "vokkaliga_gowda",
    en_label: "Vokkaliga Gowda",
    ta_label: "ஒக்கலிக கவுடா",
    p_o: ["vokkaliga_gowda"],
  },
  {
    value: "agamudaiyar",
    en_label: "Agamudaiyar",
    ta_label: "அகமுடையார்",
    p_o: ["devar_thevar_mukkulathor_agamudaiyar"],
  },
  {
    value: "vaniya_chettiar",
    en_label: "Vaniya Chettiar",
    ta_label: "வாணிய செட்டியார்",
    p_o: ["chettiar"],
  },
  {
    value: "jangam",
    en_label: "Jangam",
    ta_label: "ஜங்கம்",
    p_o: ["veera_saivam"],
  },
  {
    value: "sozhia_chetty",
    en_label: "Sozhia Chetty",
    ta_label: "சோழிய செட்டி",
    p_o: ["chettiar"],
  },
  {
    value: "saiva_pillai",
    en_label: "Saiva Pillai",
    ta_label: "சைவ பிள்ளை",
    p_o: ["pillai"],
  },
];

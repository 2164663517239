import UserSearchBar from "@views/components/user_search/user_search_bar";
import { Box, Hidden, HStack, Pressable, Text } from "native-base";
import React, { useEffect, useState } from "react";
import ScrollView from "@views/components/ui/scroll_view/scroll_view";
import { Button, Drawer } from "antd";
import { AiFillFilter } from "react-icons/ai";
import { FcFilledFilter } from "react-icons/fc";
import UserSearchContent from "@views/components/user_search/user_search_content";
import { useTranslation } from "react-i18next";
import UnderVerification from "@views/components/common/under_verification";
import { retrieveItem } from "@helpers/storage";
import { ROUTES } from "@views/routes/my_routes";
import { navigate } from "@helpers/navigator";

const UserSearch = (props) => {
  const { t } = useTranslation();
  const [filter_variables, setFilterVariables] = useState({});
  const is_approved = retrieveItem("is_approved");
  const is_preference_added = retrieveItem("is_preference_added");
  const is_profile_added = retrieveItem("is_profile_added");

  if (!is_profile_added) {
    navigate(ROUTES.USER_MANAGE_PROFILE);
  } else if (!is_preference_added) {
    navigate(ROUTES.USER_MANAGE_PREFERENCE);
  } else if (is_approved === null) {
    return <UnderVerification />;
  }

  return (
    <Box backgroundColor={"#fef5fb"} flex={1}>
      <HStack>
        <Box
          height={"100vh"}
          width={{
            base: 0,
            // xs: 200,
            // sm: 200,
            md: 350,
            lg: 350,
            xl: 350,
            "2xl": 350,
            "3xl": 350,
          }}
        >
          <UserSearchBar setFilterVariables={setFilterVariables} />
        </Box>

        <Box flex={1} height={"100vh"} width={"100%"}>
          {/* <Box height={"30px"} width={"100%"} /> */}
          <Box flex={1} width={"100%"}>
            <UserSearchContent
              setFilterVariables={setFilterVariables}
              filter_variables={filter_variables}
            />
          </Box>
          <Box height={"80px"} width={"100%"} />
        </Box>
      </HStack>
    </Box>
  );
};
export default UserSearch;

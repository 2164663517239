import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Card,
  Hidden,
  Skeleton,
  Divider,
} from "native-base";
import MemberAvatar from "./member_avatar";
import MemberSquareList from "./member_partner_match_lists";
import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from "react-i18next";
import { retrieveItem } from "@helpers/storage";
import { isArray } from "lodash";
import { Spin } from "antd";
import { useDynamicSelector } from "@services/redux";
import { AiFillStar } from "react-icons/ai";
import { BsPersonFill } from "react-icons/bs";
import { getProfileUrl } from "@helpers/get_profile_url";
import { calculateAgeForCompare } from "@helpers/calculate_age";

const MemberPartnerMatchView = (props) => {
  const { preference_matches, _loginUserDetails, member_profile, cover_photo } =
    props;
  const [profilePhoto, setProfilePhoto] = useState("");

  const { loading } = useDynamicSelector("member");

  console.log("preference_matches", preference_matches);
  console.log("_loginUserDetails", _loginUserDetails);

  let count = 0;
  let total_count = preference_matches?.annual_income_from ? 1 : 0;

  const { t } = useTranslation();

  useEffect(() => {
    let user_photo = retrieveItem("profile_photo");
    setProfilePhoto(user_photo);
  }, []);

  const preference_match_key = [
    { key_value_id: "age", key_value: "age" },
    { key_value_id: "marital_status", key_value: "marital_statuses" },
    { key_value_id: "body_type", key_value: "body_types" },
    { key_value_id: "physical_status", key_value: "physical_statuses" },
    { key_value_id: "mother_tongue", key_value: "mother_tongues" },
    { key_value_id: "eating_habit", key_value: "eating_habits" },
    // { key_value_id: "drinking_habit", key_value: "drinking_habits" },
    // { key_value_id: "smoking_habit", key_value: "smoking_habits" },
    { key_value_id: "religion", key_value: "religions" },
    { key_value_id: "caste", key_value: "castes" },
    { key_value_id: "sub_caste", key_value: "sub_castes" },
    { key_value_id: "star", key_value: "stars" },
    // { key_value_id: "raasi", key_value: "raasis" },
    { key_value_id: "country", key_value: "countries" },
    { key_value_id: "state", key_value: "states" },
    { key_value_id: "district", key_value: "districts" },
    // { key_value_id: "education", key_value: "educations" },
    { key_value_id: "employment_type", key_value: "employment_types" },
  ];
  if (
    Number(_loginUserDetails?.annual_income_from) !== -99 &&
    Number(preference_matches?.annual_income_from) <=
      Number(_loginUserDetails?.annual_income_from) &&
    Number(_loginUserDetails?.annual_income_from) <=
      Number(preference_matches?.annual_income_to)
  ) {
    count++;
  }
  preference_match_key.map((x) => {
    let key_value = x.key_value;
    let key_value_id = x.key_value_id;
    if (key_value === "countries") {
      preference_matches?.[key_value]?.filter((p) => {
        return _loginUserDetails?.[key_value_id] === _.snakeCase(p);
      })?.length || preference_matches?.[key_value]?.[0] === "any"
        ? (count++, total_count++)
        : preference_matches?.[key_value]?.length
        ? total_count++
        : "";
    }
    if (key_value === "states") {
      preference_matches?.[key_value]?.filter((p) => {
        return _loginUserDetails?.[key_value_id] === _.snakeCase(p);
      })?.length || preference_matches?.[key_value]?.[0] === "any"
        ? (count++, total_count++)
        : preference_matches?.[key_value]?.length
        ? total_count++
        : "";
    }
    if (key_value === "districts") {
      preference_matches?.[key_value]?.filter((p) => {
        return _loginUserDetails?.[key_value_id] === _.snakeCase(p);
      })?.length
        ? (count++, total_count++)
        : preference_matches?.[key_value]?.length
        ? total_count++
        : "";
    }

    if (
      key_value !== "countries" &&
      key_value !== "states" &&
      key_value !== "districts"
    ) {
      preference_matches?.[key_value]?.filter((p) => {
        return _loginUserDetails?.[key_value_id] === p;
      })?.length || preference_matches?.[key_value]?.[0] === "any"
        ? (count++, total_count++)
        : preference_matches?.[key_value]?.length
        ? total_count++
        : "";
    }
    if (key_value === "age") {
      if (
        Number(preference_matches?.age_from) <
        calculateAgeForCompare(_loginUserDetails?.dob) <
        Number(preference_matches?.age_to)
      ) {
        count++, total_count++;
      }
    }
  });
  const MemberImgResponsive = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          borderRadius: "50%",
          objectFit: "cover",
        }}
        width={80}
        height={80}
      />
    );
  });

  return (
    <Box>
      <VStack alignItems={"center"}>
        <Box>
          <Text fontSize={18} fontWeight={"700"}>
            {t("preference_match")}
          </Text>
        </Box>
        {loading ? (
          <Skeleton.Text lines={1} my={2} width={"60%"} />
        ) : (
          <Box paddingY={2}>
            <Text fontSize={16} color={"#712e5a"} fontWeight={"bold"}>
              {`You Match ${count}/${total_count} of her Preferences`}
            </Text>
          </Box>
        )}
        {loading ? (
          <Card
            width={"90%"}
            bg="white"
            shadow="2"
            borderRadius={5}
            marginTop={5}
            marginBottom={"15px"}
            marginX={5}
            paddingTop={"5px"}
            style={{
              boxShadow: "0 0 10px #dcdcdc",
            }}
          >
            <HStack
              paddingY={5}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <VStack paddingX={5} alignItems={"center"}>
                <Skeleton h={120} width={120} rounded={"full"} />
                <Skeleton.Text my={5} lines={1} />
              </VStack>
              <Player
                style={{ height: "50px", width: "50px" }}
                autoplay
                loop
                src=" https://assets4.lottiefiles.com/packages/lf20_ZKUJ2j.json"
              />
              <VStack paddingX={5} alignItems={"center"}>
                <Skeleton h={120} width={120} rounded={"full"} />
                <Skeleton.Text my={5} lines={1} />
              </VStack>
            </HStack>
            <HStack marginY={5} alignItems={"center"}>
              <Box marginLeft={5} py={2} width={"30px"}>
                <BsPersonFill color="#712e5a" size={18} />
              </Box>
              <Skeleton.Text lines={2} width={"50%"} />
            </HStack>
            <Divider my={2} />
            <HStack marginY={5} alignItems={"center"}>
              <Box marginLeft={5} py={2} width={"30px"}>
                <BsPersonFill color="#712e5a" size={18} />
              </Box>
              <Skeleton.Text lines={2} width={"50%"} />
            </HStack>
            <Divider my={2} />
            <HStack marginY={5} alignItems={"center"}>
              <Box marginLeft={5} py={2} width={"30px"}>
                <BsPersonFill color="#712e5a" size={18} />
              </Box>
              <Skeleton.Text lines={2} width={"50%"} />
            </HStack>
            <Divider my={2} />
            <HStack marginY={5} alignItems={"center"}>
              <Box marginLeft={5} py={2} width={"30px"}>
                <BsPersonFill color="#712e5a" size={18} />
              </Box>
              <Skeleton.Text lines={2} width={"50%"} />
            </HStack>
          </Card>
        ) : (
          <Card
            width={"90%"}
            bg="white"
            shadow="2"
            borderRadius={5}
            marginTop={5}
            marginBottom={"15px"}
            marginX={5}
            paddingTop={"5px"}
            style={{
              boxShadow: "0 0 10px #dcdcdc",
            }}
          >
            <HStack
              paddingY={5}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <VStack paddingX={5} alignItems={"center"}>
                <Hidden till="md">
                  <MemberAvatar
                    src={getProfileUrl(
                      cover_photo?.photo_url,
                      member_profile?.gender
                    )}
                    width={120}
                    height={120}
                  />
                </Hidden>
                <Hidden from="md">
                  <MemberImgResponsive
                    src={getProfileUrl(
                      cover_photo?.photo_url,
                      member_profile?.gender
                    )}
                  />
                </Hidden>
                <Text fontSize={18} style={{ marginTop: "20px" }}>
                  {member_profile?.name}
                </Text>
              </VStack>
              <Player
                style={{ height: "50px", width: "50px" }}
                autoplay
                loop
                src=" https://assets4.lottiefiles.com/packages/lf20_ZKUJ2j.json"
              />
              <VStack paddingX={5} alignItems={"center"}>
                <Hidden till="md">
                  <MemberAvatar
                    src={getProfileUrl(profilePhoto, _loginUserDetails?.gender)}
                    width={120}
                    height={120}
                  />
                </Hidden>
                <Hidden from="md">
                  <MemberImgResponsive
                    src={getProfileUrl(profilePhoto, _loginUserDetails?.gender)}
                  />
                </Hidden>

                <Text fontSize={18} style={{ marginTop: "20px" }}>
                  {_loginUserDetails?.name}
                </Text>
              </VStack>
            </HStack>
            <MemberSquareList
              _loginUserDetails={_loginUserDetails}
              preference_matches={preference_matches}
            />
          </Card>
        )}
      </VStack>
    </Box>
  );
};
export default MemberPartnerMatchView;

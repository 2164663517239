import { gql } from "@apollo/client";

export const get_user_subscription_plan = gql`
  query getSubscriptionPlans($page_number: Float, $page_limt: Float) {
    getSubscriptionPlans(page_number: $page_number, page_limit: $page_limt) {
      items {
        id
        en_label
        ta_label
        period_in_days
        allowed_purchase_count
        actual_price
        discounted_price
        description
        is_recommended
        is_active
        allowed_contact_details_view
        order_id
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const create_user_subscription_plan = gql`
  mutation createUserSubscriptionPlan($subscription_plan_id: String!) {
    createUserSubscriptionPlan(subscription_plan_id: $subscription_plan_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const check_user_subscription_plan = gql`
  mutation checkUserSubscriptionPlan($order_id: String!) {
    checkUserSubscriptionPlan(order_id: $order_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const BodyTypesOptions = [
  {
    value: "any",
    p_o: [],
    en_label: "Any",
    ta_label: "ஏதேனும்",
  },
  {
    value: "slim",
    p_o: [],
    en_label: "Slim",
    ta_label: "மெலிந்த",
  },
  {
    value: "athletic",
    p_o: [],
    en_label: "Athletic",
    ta_label: "விளையாட்டு வீரர்",
  },
  {
    value: "average",
    p_o: [],
    en_label: "Average",
    ta_label: "சராசரி உடல்",
  },
  {
    value: "heavy",
    p_o: [],
    en_label: "Heavy",
    ta_label: "பருமன்",
  },
];

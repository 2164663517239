import { Box, Heading, HStack, Text, VStack } from "native-base";
import React, { Component } from "react";
import "../../index.css";

import Scrollbars from "react-custom-scrollbars-2";

const AboutUs = (props) => {
  return (
    <Scrollbars
      style={{
        backgroundColor: "#fef5fb",
      }}
    >
      <Box alignItems={"center"}>
        <Heading>About Us</Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          Welcome to Standard Matrimony! Our matrimony website and app, where we
          are dedicated to helping you find your life partner. Our platform
          offers a comprehensive and user-friendly interface, making the
          matchmaking process efficient and seamless. Whether you're seeking a
          partner within your community or outside of it, our website and app
          cater to a diverse range of preferences and requirements.
        </Text>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          Our advanced search filters allow you to narrow down your search based
          on specific criteria such as age, religion, caste, and more. We
          understand the significance of astrology in many cultures and provide
          options to select horoscope profiles and perform horoscope matching to
          ensure compatibility between partners.
        </Text>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          Our team of dedicated professionals is committed to providing you with
          a secure, private, and hassle-free experience throughout your
          matchmaking journey. We offer a range of membership plans to suit your
          budget and requirements, ensuring that our services are accessible to
          everyone.
        </Text>
      </Box>
    </Scrollbars>
  );
};

export default AboutUs;

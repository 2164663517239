import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, Center, ScrollView, HStack } from "native-base";
import { getCountry, getState } from "@helpers/get_country_state_city";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
  incomplete_payment_profiles,
  incomplete_payment_profiles_reports_query,
} from "@services/redux";
import { Button, Pagination, Table } from "antd";
import { CalculateAge, CalculateAgeNumber } from "@helpers/calculate_age";
import { navigateBack } from "@helpers/navigator";
import moment from "moment";
import { generateExcelFromJSON } from "@helpers/functions";
import { BiUpArrowAlt } from "react-icons/bi";
import _, { debounce } from "lodash";
import { useRef } from "react";

const AdminPaymentTriedMembers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const top_ref = useRef();

  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [report_data, setReportData] = useState([]);

  const {
    items: reports,
    loading,
    pagination,
  } = useDynamicSelector("incomplete_payments_profiles");
  const {
    items: incomplete_payments_profiles_reports_items,
    loading: incomplete_payments_profiles_reports_loading,
  } = useDynamicSelector("incomplete_payments_profiles_reports");
  useEffect(() => {
    let keys = [{ key: "incomplete_payments_profiles", loading: true }];
    dispatch(
      dynamicRequest(keys, incomplete_payment_profiles, {
        page_number: current,
        page_limit: current_limit,
      })
    );
  }, []);

  const getReports = () => {
    let keys = [{ key: "incomplete_payments_profiles_reports", loading: true }];
    dispatch(
      dynamicRequest(keys, incomplete_payment_profiles_reports_query, {
        page_number: 1,
        page_limit: 1000000,
      })
    );
  };
  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "incomplete_payments_profiles", loading: true }];
    dispatch(
      dynamicRequest(keys, incomplete_payment_profiles, {
        page_number: page_number,
        page_limit: page_limit,
      })
    );
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    if (incomplete_payments_profiles_reports_items?.length > 0) {
      setReportData(incomplete_payments_profiles_reports_items);
    }
  }, [incomplete_payments_profiles_reports_items]);

  const fieldMappings = [
    {
      label: "Name",
      field: "name",
    },
    {
      label: "Profile Number",
      field: "profile_id",
    },

    {
      label: "Mobile",
      field: "mobile",
    },
    {
      label: "Created At",
      field: "created_datetime",
    },
    {
      label: "Tried At",
      field: "tried_date",
    },
    {
      label: "Date of Birth",
      field: "dob",
    },
    {
      label: "Gender",
      field: "gender",
    },
    {
      label: "Franchise",
      field: "franchise_name",
    },
    {
      label: "District",
      field: "district",
    },
    {
      label: "Status",
      field: "incomplete_payment.status",
    },
  ];
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
      fixed: "left",
      width: 200,
    },
    {
      title: "Profile Number",
      dataIndex: "profile_id",
      align: "left",
      key: "profile_id",
      width: 140,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      align: "left",
      key: "gender",
      width: 80,
      render: (record, i) => {
        return (
          <>
            <Text>{record === "m" ? "Male" : "Female"}</Text>
          </>
        );
      },
    },
    {
      title: "Age",
      dataIndex: "dob",
      align: "left",
      key: "dob",
      width: 80,
      render: (record, i) => {
        return (
          <>
            <Text>{CalculateAgeNumber(record)}</Text>
          </>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      align: "left",
      key: "mobile",
      width: 120,
    },
    {
      title: "Location",
      dataIndex: "",
      align: "left",
      key: "location",
      width: 150,
      render: (record, i) => {
        let country = record.country;
        return (
          <>
            <Text>
              {record.country ? <>{getCountry(record.country)},</> : null}
              {record.state ? <>{getState(record.state, country)},</> : null}
              {record.district ? <>{record.district}.</> : null}
            </Text>
          </>
        );
      },
    },
    {
      title: "Franchise Name",
      dataIndex: "franchise_name",
      align: "left",
      key: "name",
      width: 150,
    },
    {
      title: "Created On",
      dataIndex: "",
      align: "left",
      key: "name",
      width: 150,

      render: (record) => {
        return (
          <Text>
            {moment(record?.created_datetime).format("DD/MM/YYYY h:mm a")}
          </Text>
        );
      },
    },
    {
      title: "Tried At",
      dataIndex: "",
      align: "left",
      key: "name",
      width: 150,

      render: (record) => {
        return (
          <Text>{moment(record?.tried_date).format("DD/MM/YYYY h:mm a")}</Text>
        );
      },
    },
    {
      title: "Subscription Plan",
      dataIndex: "",
      align: "left",
      key: "subscription_plan",
      width: 150,

      render: (record) => {
        return (
          <>
            <Text>
              {record?.incomplete_payment?.subscription_plan?.en_label}
            </Text>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "",
      align: "left",
      key: "state",
      width: 100,

      render: (record) => {
        return (
          <>
            <Text>{_.startCase(record?.incomplete_payment?.status)}</Text>
          </>
        );
      },
    },
  ];
  const handlePagePosition = () => {
    const element = top_ref?.current;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);
  const goToTop = () => {
    debouncedHandleTitleClick();
  };
  return (
    <Box ref={top_ref} back width="100%" mt="20px" marginBottom="20px">
      <ScrollView>
        {/* <Box top="0px" zIndex="2" mb="5"> */}
        <Text fontSize="md" fontWeight="bold">
          {`${t("payment_tried_members")} (${
            pagination?.total_count ? pagination?.total_count : 0
          })`}
        </Text>
        <Box my={5} width={"150px"}>
          <Button
            loading={incomplete_payments_profiles_reports_loading}
            colorScheme={"danger.700"}
            onClick={() => {
              generateExcelFromJSON(
                report_data,
                "PaymentTriedMembers.xlsx",
                fieldMappings
              );
            }}
            style={{
              height: "50px",
              label: t("table:back"),
              variant: "outline",
            }}
          >
            {incomplete_payments_profiles_reports_loading
              ? "Wait..."
              : "Download Report"}
          </Button>
        </Box>
        <Box my={5} width={"80px"}>
          <Button
            colorScheme={"danger.700"}
            onClick={() => {
              navigateBack();
            }}
            style={{
              label: t("table:back"),
              variant: "outline",
            }}
          >
            Back
          </Button>
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              columns={columns}
              pagination={false}
              loading={loading}
              dataSource={reports}
              scroll={{
                x: 2000,
              }}
            />
          </Wrapper>
          <HStack alignItems={"center"}>
            <Box width={"70%"}>
              <Pagination
                showSizeChanger={true}
                current={current}
                hideOnSinglePage={false}
                onChange={fetchData}
                total={pagination?.total_count}
                pageSizeOptions={[5, 10, 15, 20, 50, 100, 1000]}
              />
            </Box>
            <Box alignItems={"flex-end"} my={5} width={"30%"}>
              <Button
                colorScheme={"danger.700"}
                onClick={() => {
                  goToTop();
                }}
                style={{
                  label: t("table:back"),
                  variant: "outline",
                }}
              >
                <HStack width={"100%"} alignItems={"center"}>
                  <BiUpArrowAlt size={20} />
                  <Text>{"Go to Top"}</Text>
                </HStack>
              </Button>
            </Box>
          </HStack>
        </VStack>
      </ScrollView>
    </Box>
  );
};

export default AdminPaymentTriedMembers;

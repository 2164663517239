import {
  Box,
  Card,
  Divider,
  Hidden,
  HStack,
  Skeleton,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input } from "antd";
import "../../../index.css";

import { useDispatch } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { useDynamicSelector } from "@services/redux";
import { ReligionsOptions } from "@master_data/common_json/religions";
import { CasteOptions } from "@master_data/common_json/castes";
import { StarsOptions } from "@master_data/common_json/stars";
import { DoshamsOptions } from "@master_data/common_json/doshams";
import { SubCastesOptions } from "@master_data/common_json/sub_castes";

const UserPreferenceFormReligionDetailsOperator = (props) => {
  const { form, getOptions } = props;
  const { t } = useTranslation();
  const [sub_caste_options, setSubCasteOptions] = useState([]);
  const [caste_options, setCasteOptions] = useState([]);
  const selected_castes = Form.useWatch("castes", form);
  const selected_sub_castes = Form.useWatch("sub_castes", form);
  const selected_religions = Form.useWatch("religions", form);

  const { loading: my_preference_loading } =
    useDynamicSelector("my_preference");

  useEffect(() => {
    let grouped_sub_castes = [];

    if (selected_castes) {
      for (let i = 0; i < selected_castes.length; i++) {
        let selected_caste = selected_castes[i];
        let _options = [];
        if (SubCastesOptions) {
          _options = SubCastesOptions?.filter(
            (x) => x.p_o.map((x) => x).indexOf(selected_caste) > -1
          );
          _options = _options.map((obj) => {
            return { label: obj.en_label, value: obj.value };
          });
        }
        let caste = CasteOptions?.find((x) => x.value === selected_caste);
        grouped_sub_castes.push({
          label: caste?.en_label,
          options: _options,
        });
      }
    }
    setSubCasteOptions(grouped_sub_castes);
    let new_selected_sub_castes = [];
    if (selected_sub_castes) {
      for (let i = 0; i < selected_sub_castes.length; i++) {
        let selected_sub_caste = selected_sub_castes[i];
        let _selected_sub_caste = SubCastesOptions?.find(
          (x) => x.value === selected_sub_caste
        );
        if (
          _selected_sub_caste &&
          selected_castes.indexOf(_selected_sub_caste.p_o?.[i]) > -1
        ) {
          new_selected_sub_castes.push(_selected_sub_caste.value);
        }
      }
    }
    form.setFields([
      {
        name: "sub_castes",
        value: new_selected_sub_castes,
      },
    ]);
  }, [selected_castes]);

  useEffect(() => {
    let grouped_castes = [];

    if (selected_religions) {
      for (let i = 0; i < selected_religions.length; i++) {
        let selected_religion = selected_religions[i];
        let _options = [];
        if (CasteOptions) {
          _options = CasteOptions?.filter(
            (x) => x.p_o.map((x) => x).indexOf(selected_religion) > -1
          );
          _options = _options.map((obj) => {
            return { label: obj.en_label, value: obj.value };
          });
        }
        let religion = ReligionsOptions?.find(
          (x) => x.value === selected_religion
        );
        grouped_castes.push({
          label: religion?.en_label,
          options: _options,
        });
      }
    }

    setCasteOptions(grouped_castes);
    let new_selected_castes = [];
    if (selected_castes) {
      for (let i = 0; i < selected_castes.length; i++) {
        let selected_caste = selected_castes[i];
        let _selected_caste = CasteOptions?.find(
          (x) => x.value === selected_caste
        );
        if (
          _selected_caste &&
          selected_religions.indexOf(_selected_caste.p_o?.[i]) > -1
        ) {
          new_selected_castes.push(_selected_caste.value);
        }
      }
    }
    form.setFields([
      {
        name: "castes",
        value: new_selected_castes,
      },
    ]);
  }, [selected_religions]);

  const handleReligionsChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1]?.startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id?.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id?.startsWith("any"));
    }
    form.setFieldsValue({
      religions: filtered_ids,
    });
  };

  const handleCastesChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1]?.startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id?.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id?.startsWith("any"));
    }
    form.setFieldsValue({
      castes: filtered_ids,
    });
  };
  const handleSubCastesChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1]?.startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id?.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id?.startsWith("any"));
    }
    form.setFieldsValue({
      sub_castes: filtered_ids,
    });
  };

  const handleStarChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1]?.startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id?.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id?.startsWith("any"));
    }
    form.setFieldsValue({
      stars: filtered_ids,
    });
  };
  const handleDoshamsChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1] === "no_dosham"
    ) {
      filtered_ids = filtered_ids?.filter((id) => id === "no_dosham");
    } else if (
      values &&
      values.length > 0 &&
      values[values.length - 1] !== "no_dosham"
    ) {
      filtered_ids = filtered_ids?.filter((id) => id !== "no_dosham");
    }
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1]?.startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id?.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id?.startsWith("any"));
    }
    form.setFieldsValue({
      doshams: filtered_ids,
    });
  };
  return (
    <>
      {my_preference_loading ? (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("religion_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <Hidden from="md">
            <Box width={"100%"}>
              <Skeleton.Text lines={5} />
            </Box>
          </Hidden>
          <Hidden till="md">
            <HStack space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
          </Hidden>
        </Card>
      ) : (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={14} bold color={"#712e5a"}>
              {t("religion_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <VForm.Select
            label={t("religion")}
            field={"religions"}
            options={getOptions(ReligionsOptions, "Religion")}
            labelField={"en_label"}
            valueField={"value"}
            isMulti
            onChange={handleReligionsChange}
          />
          {!selected_religions?.includes("any") && (
            <VForm.FormGroupSelect
              label={t("caste")}
              field={"castes"}
              options={caste_options}
              labelField={"label"}
              valueField={"value"}
              isMulti
              onChange={handleCastesChange}
            />
          )}
          {!selected_religions?.includes("any") &&
            !selected_castes?.includes("any") && (
              <VForm.FormGroupSelect
                label={t("sub_caste")}
                field={"sub_castes"}
                showSearch={true}
                options={sub_caste_options}
                labelField={"label"}
                valueField={"value"}
                isMulti
                onChange={handleSubCastesChange}
              />
            )}
          <VForm.Select
            label={t("star")}
            field={"stars"}
            options={getOptions(StarsOptions, "Star")}
            labelField={"en_label"}
            valueField={"value"}
            isMulti
            onChange={handleStarChange}
          />
          {/* <VForm.Select
            label={t("raasi")}
            field={"raasis"}
            labelField={"en_label"}
            valueField={"id"}
            isMulti
          /> */}
          <VForm.Select
            isMulti
            label={t("doshams")}
            field={"doshams"}
            options={getOptions(DoshamsOptions, "Dosham")}
            labelField={"en_label"}
            valueField={"value"}
            onChange={handleDoshamsChange}
          />
        </Card>
      )}
    </>
  );
};
export default UserPreferenceFormReligionDetailsOperator;

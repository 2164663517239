import { gql } from "@apollo/client";

export const pop_up_query = gql`
  mutation createAdPopUp($json: create_ad_pop_up_input) {
    createAdPopUp(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_pop_up_query = gql`
  query {
    get_ad_pop_up: getAdPopUp {
      id
      web_img_url
      day_interval
    }
  }
`;

import React from "react";
import { Box, Text, HStack } from "native-base";
import { DoshamsOptions } from "@master_data/common_json/doshams";
import { getUserDetailsFromValue } from "@helpers/functions";

const MemberDetail = (props) => {
  const { text, icon, items, options } = props;

  return (
    <>
      {text === "multi" ? (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
            }}
          >
            {icon}
          </Box>
          {items?.map((x, i) => {
            return (
              <Text py={1} color={"black"}>
                {`${getUserDetailsFromValue(options, x)}${
                  i === items?.length - 1 ? "" : ", "
                }`}
              </Text>
            );
          })}

          <br />
        </HStack>
      ) : (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
            }}
          >
            {icon}
          </Box>
          <Text py={1} color={"black"}>
            {text}
          </Text>{" "}
          <br />
        </HStack>
      )}
    </>
  );
};
export default MemberDetail;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, Center } from "native-base";
import { getCountry, getState } from "@helpers/get_country_state_city";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
  incomplete_payment_profiles,
} from "@services/redux";
import { Button, Pagination, Table } from "antd";
import { CalculateAge, CalculateAgeNumber } from "@helpers/calculate_age";
import { navigateBack } from "@helpers/navigator";
import moment from "moment";

const AdminPaymentTriedMembers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const {
    items: reports,
    loading,
    pagination,
  } = useDynamicSelector("incomplete_payments_profiles");
  useEffect(() => {
    let keys = [{ key: "incomplete_payments_profiles", loading: true }];
    dispatch(
      dynamicRequest(keys, incomplete_payment_profiles, {
        page_number: current,
        page_limit: current_limit,
      })
    );
  }, []);
  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "incomplete_payments_profiles", loading: true }];
    dispatch(
      dynamicRequest(keys, incomplete_payment_profiles, {
        page_number: page_number,
        page_limit: page_limit,
      })
    );
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
    },
    {
      title: "Profile Number",
      dataIndex: "profile_id",
      align: "left",
      key: "profile_id",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      align: "left",
      key: "gender",
      render: (record, i) => {
        return (
          <>
            <Text>{record === "m" ? "Male" : "Female"}</Text>
          </>
        );
      },
    },
    {
      title: "Received Count",
      dataIndex: "dob",
      align: "left",
      key: "dob",
      render: (record, i) => {
        return (
          <>
            <Text>{CalculateAgeNumber(record)}</Text>
          </>
        );
      },
    },
    {
      title: "Request Count",
      dataIndex: "request count",
      align: "left",
      key: "mobile",
    },
    {
      title: "Accept count",
      dataIndex: "",
      align: "left",
      key: "location",
      render: (record, i) => {
        let country_id = record.country_id;
        return (
          <>
            <Text>
              {record.country_id ? <>{getCountry(record.country_id)},</> : null}
              {record.state_id ? (
                <>{getState(record.state_id, country_id)},</>
              ) : null}
              {record.city_id ? <>{record.city_id}.</> : null}
            </Text>
          </>
        );
      },
    },
    // {
    //   title: "Franchise Name",
    //   dataIndex: "franchise_name",
    //   align: "left",
    //   key: "name",
    // },
    // {
    //   title: "Created On",
    //   dataIndex: "",
    //   align: "left",
    //   key: "name",
    //   render: (record) => {
    //     return (
    //       <Text>
    //         {moment(record?.created_datetime).format("DD/MM/YYYY h:mm a")}
    //       </Text>
    //     );
    //   },
    // },
    // {
    //   title: "Subscription Plan",
    //   dataIndex: "",
    //   align: "left",
    //   key: "subscription_plan",
    //   render: (record) => {
    //     return (
    //       <>
    //         <Text>
    //           {record?.incomplete_payment?.subscription_plan?.en_label}
    //         </Text>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "",
    //   align: "left",
    //   key: "state_id",
    //   render: (record) => {
    //     return (
    //       <>
    //         <Text>{record?.incomplete_payment?.status}</Text>
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <Center>
      <Box width="90%" mt="20px" marginBottom="20px">
        <Box top="0px" zIndex="2" mb="5">
          <Text fontSize="md" fontWeight="bold">
            {`${t("send/received_request")} (${
              pagination?.total_count ? pagination?.total_count : 0
            })`}
          </Text>
        </Box>
        <Box my={5} width={"80px"}>
          <Button
            colorScheme={"danger.700"}
            onClick={() => {
              navigateBack();
            }}
            style={{
              label: t("table:back"),
              variant: "outline",
            }}
          >
            Back
          </Button>
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              columns={columns}
              pagination={false}
              loading={loading}
              dataSource={reports}
            />
          </Wrapper>
          <Pagination
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
            total={pagination?.total_count}
          />
        </VStack>
      </Box>
    </Center>
  );
};

export default AdminPaymentTriedMembers;

import { operator_matches_page_count, page_count } from "@helpers/constants";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  getMatches,
  getMyPreference,
  getOperatorMatches,
  useDynamicSelector,
} from "@services/redux";
import OperatorMatchesContent from "@views/components/operator_web/operator_matches_content";
import OperatorMatchesSideBar from "@views/components/operator_web/operator_home/operator_matches_sidebar";
import HeaderBar from "@views/layouts/admin/header_bar/header_bar";
import HeaderBarUser from "@views/layouts/admin/header_bar/header_bar_user";
import { Box, HStack, ScrollView, Text } from "native-base";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import UserProfileFormOperator from "@views/components/user_manage_profile_in_operator/user_profile_form_operator";
import Scrollbars from "react-custom-scrollbars-2";
import UserPreferenceFormOperator from "@views/components/user_manage_preference_operator/user_preference_form_operator";
import { retrieveItem, storeItem } from "@helpers/storage";
import OperatorMatchesHeader from "@views/components/operator_web/operator_matches_header";
import { ROUTES } from "@views/routes/my_routes";
import { navigate } from "@helpers/navigator";
import OperatorUserPrivacySettings from "./operator_user_privacy_settings";

const OperatorMatches = () => {
  const dispatch = useDispatch();

  const [show_matches_page, setShowMatchesPage] = useState(false);
  const [show_profile_page, setShowProfilePage] = useState(false);
  const [show_preference_page, setShowPreferencePage] = useState(false);
  const [show_privacy_page, setShowPrivacyPage] = useState(false);

  const [custom_filter_variables, setCustomFilterVariables] = useState({});
  let temp = {};
  const initial_filter_variables = useDynamicSelector(
    "initial_filter_variables"
  );
  const secondary_filter_variables = useDynamicSelector(
    "secondary_filter_variables"
  );
  const { status: updatePreferenceStatus } =
    useDynamicSelector("updateMyPreference");

  const { user_preference: my_preference_details } =
    useDynamicSelector("my_preference");
  // const {
  //   items: matches_list,
  //   pagination: all_matches_pagination,
  //   loading: matches_loading,
  //   error,
  // } = useDynamicSelector("operator_matches");
  const handleMatchesPage = (val) => {
    setShowMatchesPage(val);
  };

  const handleProfilePage = (val) => {
    setShowProfilePage(val);
  };
  const handlePreferencePage = (val) => {
    setShowPreferencePage(val);
  };
  const handlePrivacyPage = (val) => {
    setShowPrivacyPage(val);
  };
  const getAllMatches = () => {
    delete custom_filter_variables?.age_from_id;
    delete custom_filter_variables?.age_to_id;
    delete custom_filter_variables?.height_from_id;
    delete custom_filter_variables?.height_to_id;
    delete custom_filter_variables?.annual_income_from_id;
    delete custom_filter_variables?.annual_income_to_id;
    let get_my_matches_key = [{ key: "operator_matches", loading: true }];
    let get_my_matches_query = getOperatorMatches;
    let get_my_matches_variables = {
      filter: {
        ...custom_filter_variables,
        show_only_with_open_photos: false,
      },
      page_number: 1,
      page_limit: operator_matches_page_count,
    };

    dispatch(dynamicSet("secondary_filter_variables", custom_filter_variables));
    dispatch(
      dynamicRequest(
        get_my_matches_key,
        get_my_matches_query,
        get_my_matches_variables
      )
    );
  };

  useEffect(() => {
    if (Object.keys(custom_filter_variables)?.length > 0) {
      getAllMatches();
    }
  }, [custom_filter_variables]);

  const getMyPreferencesList = () => {
    let get_my_preference_key = [{ key: "my_preference", loading: false }];
    let get_my_preference_query = getMyPreference;
    let get_my_preference_variables = {};
    dispatch(
      dynamicRequest(
        get_my_preference_key,
        get_my_preference_query,
        get_my_preference_variables
      )
    );
  };
  useEffect(() => {
    getMyPreferencesList();
  }, []);

  useEffect(() => {
    if (updatePreferenceStatus === "success") {
      getMyPreferencesList();
      dispatch(dynamicClear("updateMyPreference"));
    }
  }, [updatePreferenceStatus]);

  useEffect(() => {
    if (my_preference_details) {
      console.log("my_preference_details", my_preference_details);
      temp = {
        ...my_preference_details,
        age_from: my_preference_details?.age_from,
        age_to: my_preference_details?.age_to,
        height_to: my_preference_details?.height_to,
        height_from: my_preference_details?.height_from,
        annual_income_to: my_preference_details?.annual_income_to,
        annual_income_from: my_preference_details?.annual_income_from,
        marital_statuses: my_preference_details?.marital_statuses.map((x) => x),
        body_types: my_preference_details?.body_types.map((x) => x),
        castes: my_preference_details?.castes.map((x) => x),
        districts: my_preference_details?.districts.map((x) => x),
        countries: my_preference_details?.countries.map((x) => x),
        doshams: my_preference_details?.doshams.map((x) => x),
        drinking_habits: my_preference_details?.drinking_habits.map((x) => x),
        eating_habits: my_preference_details?.eating_habits.map((x) => x),
        educations: my_preference_details?.educations.map((x) => x),
        employment_types: my_preference_details?.employment_types.map((x) => x),
        mother_tongues: my_preference_details?.mother_tongues.map((x) => x),
        physical_statuses: my_preference_details?.physical_statuses.map(
          (x) => x
        ),
        raasis: my_preference_details?.raasis.map((x) => x),
        religions: my_preference_details?.religions.map((x) => x),
        smoking_habits: my_preference_details?.smoking_habits.map((x) => x),
        stars: my_preference_details?.stars.map((x) => x),
        states: my_preference_details?.states.map((x) => x),
        sub_castes: my_preference_details?.sub_castes.map((x) => x),
      };
    }
    // delete temp?.age_from_id;
    // delete temp?.age_to_id;
    // delete temp?.height_from_id;
    // delete temp?.height_to_id;
    // delete temp?.annual_income_from_id;
    // delete temp?.annual_income_to_id;
    // dispatch(dynamicSet("secondary_filter_variables", temp));
    setCustomFilterVariables(temp);
  }, [my_preference_details]);

  useEffect(() => {
    let is_profile_added = retrieveItem("is_profile_added");
    let is_preference_added = retrieveItem("is_preference_added");

    if (is_profile_added && is_preference_added) {
      setShowMatchesPage(true);
      setShowProfilePage(false);
      setShowPreferencePage(false);
    } else if (is_profile_added) {
      setShowMatchesPage(false);
      setShowProfilePage(false);
      setShowPreferencePage(true);
    } else if (is_preference_added) {
      setShowMatchesPage(false);
      setShowProfilePage(true);
      setShowPreferencePage(false);
    } else {
      setShowMatchesPage(false);
      setShowProfilePage(true);
      setShowPreferencePage(false);
    }
  }, []);

  const handleLogout = () => {
    dispatch(dynamicClear("get_user_token_by_admin"));
    dispatch(dynamicSet("open_drawer", { is_drawer_open: false }));
    dispatch(dynamicSet("secondary_filter_variables", {}));
    dispatch(dynamicSet("initial_filter_variables", {}));
    storeItem("shortlisted_profiles", []);
    storeItem("name", "");
    navigate(ROUTES.OPERATOR_HOME);
  };

  useEffect(() => {
    function handleBeforeUnload(event) {
      event.preventDefault();
      event.returnValue = "";
    }
    function handlePopState(event) {
      if (window.confirm("Are you sure you want to logout?")) {
        handleLogout();
      } else {
        window.history.pushState(null, null, window.location.href);
      }
    }
    window.onbeforeunload = handleBeforeUnload;
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = handlePopState;

    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
    };
  }, []);

  return (
    <Box width={"100%"}>
      <HeaderBarUser color="white" projectName={"Standard Matrimony"} />
      <OperatorMatchesHeader
        handleMatchesPage={handleMatchesPage}
        handlePreferencePage={handlePreferencePage}
        handleProfilePage={handleProfilePage}
        handlePrivacyPage={handlePrivacyPage}
      />
      <Box width={"100%"}>
        <Box height={"100vh"} width={"100%"}>
          <Scrollbars flex={1}>
            {show_matches_page && <OperatorMatchesContent />}
            {show_profile_page && (
              <UserProfileFormOperator
                handleMatchesPage={handleMatchesPage}
                handlePreferencePage={handlePreferencePage}
                handlePrivacyPage={handlePrivacyPage}
                handleProfilePage={handleProfilePage}
              />
            )}
            {show_preference_page && (
              <UserPreferenceFormOperator
                handleMatchesPage={handleMatchesPage}
                handlePreferencePage={handlePreferencePage}
                handlePrivacyPage={handlePrivacyPage}
                handleProfilePage={handleProfilePage}
              />
            )}
            {show_privacy_page && (
              <OperatorUserPrivacySettings
                handleMatchesPage={handleMatchesPage}
                handlePreferencePage={handlePreferencePage}
                handlePrivacyPage={handlePrivacyPage}
                handleProfilePage={handleProfilePage}
              />
            )}

            <Box height={20} />
          </Scrollbars>
        </Box>
      </Box>
    </Box>
  );
};

export default OperatorMatches;

import { Box, Heading, HStack, Text, VStack } from "native-base";
import React, { Component } from "react";
import "../../index.css";

import Scrollbars from "react-custom-scrollbars-2";

const Policy = (props) => {
  return (
    <Scrollbars
      style={{
        backgroundColor: "#fef5fb",
      }}
    >
      <Box alignItems={"center"}>
        <Heading>Privacy Policy</Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          We Standard matrimony, the operator of Standard matrimony
          website/mobile application , are committed to protecting the privacy
          of our customers. This privacy policy applies to all information
          collected by us through our Standard matrimony website and mobile
          application. By using our services, you consent to the collection and
          use of your information as described in this policy.
        </Text>
      </Box>
      <Box mx={5} my={5}>
        <Text fontSize={20}>Information We Collect :</Text>
      </Box>
      <Box my={5} mx={5}>
        <Text my={2}>
          1. Personal Information: We collect basic personal information such as
          name, gender, date of birth, and contact details.
        </Text>
        <Text my={2}>
          2. Photos: We collect photographs of our customers for their profile
          on our website and mobile application.
        </Text>
        <Text my={2}>
          3. Aadhaar Information: We collect Aadhaar card information for
          authentic verification and identification purposes ( We collect
          Aadhaar card number only ) .
        </Text>
        <Text my={2}>
          4. Date of Birth: We collect date of birth for the purpose of
          generating horoscopes for our customers.
        </Text>
        <Text my={2}>
          5. Location Details: We collect location details for the purpose of
          providing relevant and localized match suggestions to our customers.
        </Text>
        <Text my={2}>
          6. Payment Information: We collect credit/debit card details and other
          digital payment information for the purpose of processing payments for
          our services.
        </Text>
      </Box>

      <Box mx={5} my={5}>
        <Text fontSize={20}>Use of Information :</Text>
      </Box>

      <Box my={5} mx={5}>
        <Text mb={5}>
          The information collected by us is used for the following purposes:
        </Text>
        <Text my={2}>1. To provide and improve our services</Text>
        <Text my={2}>2. To personalize the user experience</Text>
        <Text my={2}>3. To generate horoscopes3. To generate horoscopes</Text>
        <Text my={2}>
          4. To provide relevant and localized match suggestions
        </Text>
        <Text my={2}>5. To process payments</Text>
      </Box>
      <Box my={5} mx={5}>
        <Text fontSize={20}>Cookies :</Text>
      </Box>

      <Box my={5} mx={5}>
        <Text>
          Our website and mobile application use cookies to enhance your
          experience and personalize the content and advertisements displayed to
          you. Cookies are small text files placed on your device by websites
          you visit. They are widely used to make websites work more efficiently
          and provide information to site owners.
        </Text>
        <Text>
          You can choose to have your computer notify you each time a cookie is
          sent, or you can disable all cookies through your browser settings.
          The process may vary depending on your browser, so consult your
          browser's Help Menu for instructions on modifying your cookie
          settings.
        </Text>
      </Box>

      <Box my={5} mx={5}>
        <Text fontSize={20}>Security:</Text>
      </Box>

      <Box my={5} mx={5}>
        <Text>
          We take appropriate security measures to protect against unauthorized
          access to or unauthorized alteration, disclosure or destruction of
          data. These include internal reviews of our data collection, storage
          and processing practices and security measures, as well as physical
          security measures to guard against unauthorized access to systems
          where we store personal data.
        </Text>
      </Box>

      <Box my={5} mx={5}>
        <Text fontSize={20}>Changes to this Privacy Policy :</Text>
      </Box>

      <Box my={5} mx={5}>
        <Text>
          We may update this privacy policy periodically. Any changes will be
          communicated by posting the new privacy policy on our website. We
          recommend reviewing this privacy policy periodically for any updates.
        </Text>
      </Box>

      {/* <Box my={5} mx={5}>
        <Text fontSize={20}>Admin office : </Text>
      </Box>

      <Box my={2} mx={5}>
        <Text bold fontSize={18}>
          Standard Matrimony ,{" "}
        </Text>
        <Text bold fontSize={18}>
          2nd floor , door No: 774A,{" "}
        </Text>
        <Text bold fontSize={18}>
          Sathy Road, Gandhipuram,{" "}
        </Text>
        <Text bold fontSize={18}>
          Coimbatore-641012.{" "}
        </Text>
        <Text bold fontSize={18}>
          Tamilnadu , India
        </Text>
        <Text bold fontSize={18}>
          contact person : G Mahendran ,{" "}
        </Text>
        <Text bold fontSize={18}>
          E-mail : standardmatrimony@gmail.com ,{" "}
        </Text>
        <Text bold fontSize={18}>
          Phone : +919940717317
        </Text>
        <Text bold fontSize={18}>
          This policy is effective as of 27/02/2023.
        </Text>
      </Box> */}
      <Box mx={5} my={5}>
        <Text fontSize={20}>Sensitive Information :</Text>
      </Box>
      <Box mx={5} my={5}>
        <Text my={2}>
          In addition to the information mentioned above, we may also collect
          sensitive personal information from our customers, such as:
        </Text>

        <Text my={2}>
          1. Religion: We collect information about the customer's religion for
          the purpose of providing relevant match suggestions.
        </Text>
        <Text my={2}>
          2. Community/caste: We collect information about the customer's
          community or caste for the purpose of providing relevant match
          suggestions.
        </Text>
        <Text my={2}>
          3. Income: We collect information about the customer's income for the
          purpose of providing relevant match suggestions.
        </Text>
        <Text my={2}>
          Please note that the collection of sensitive personal information is
          optional and will only be collected with the customer's explicit
          consent. We assure that all the sensitive information collected is
          kept secure and confidential and is used only for the purpose for
          which it was collected.
        </Text>

        <Text my={2}>
          We do not share or disclose sensitive personal information to any
          third party unless required by law or with the customer's explicit
          consent. Customers have the right to withdraw their consent for the
          collection, use, and sharing of sensitive personal information at any
          time by contacting us
        </Text>
        <Box my={5}>
          <Text fontSize={20}>Contact Us:</Text>
        </Box>
        {/* <Box>
            <Text>
              If you have any questions about this privacy policy, please contact
              us :
            </Text>
          </Box> */}
        <Box>
          <Text fontSize={20}>Admin office : </Text>
        </Box>
        <Box>
          <Text bold fontSize={18}>
            Standard Matrimony ,{" "}
          </Text>
          <Text bold fontSize={18}>
            2nd floor , door No: 774A,{" "}
          </Text>
          <Text bold fontSize={18}>
            Sathy Road, Gandhipuram,{" "}
          </Text>
          <Text bold fontSize={18}>
            Coimbatore-641012.{" "}
          </Text>
          <Text bold fontSize={18}>
            Tamilnadu , India
          </Text>
          <Text bold fontSize={18}>
            contact person : G Mahendran ,{" "}
          </Text>
          <Text bold fontSize={18}>
            E-mail : standardmatrimony@gmail.com ,{" "}
          </Text>
          <Text bold fontSize={18}>
            Phone : +914224709829
          </Text>
          <Text bold fontSize={18}>
            This policy is effective as of 05/04/2023.
          </Text>
        </Box>
      </Box>
    </Scrollbars>
  );
};

export default Policy;

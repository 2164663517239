import { Box, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { QueryRequest } from "@services/apollo/api_service";
import {
  dynamicClear,
  dynamicRequest,
  fileQuery,
  useDynamicSelector,
} from "@services/redux";
import { getUUID } from "@helpers/uuid";
import AdImageForWeb from "@views/components/ad_image/ad_image_web";
import AdImageForApp from "@views/components/ad_image/ad_image_mobile";
import axios from "axios";
import {
  get_pop_up_query,
  pop_up_query,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_ad_pop_up";
import { Button, Form, InputNumber } from "antd";
import { toast } from "react-toastify";

let fileUploadresponse;

const AdImage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [url, setUrl] = useState("");
  const [headers, setHeaders] = useState();
  const [fileName, setFileName] = useState();
  const [value, setValue] = useState();
  const [uuid, setUUID] = useState();
  const action = fileUploadresponse?.data?.getUploadUrl;

  const {
    status: create_ad_pop_up_status,
    error: create_ad_pop_up_error,
    loading: create_ad_pop_up_loading,
  } = useDynamicSelector("createAdPopUp");

  const userPhotos = [
    {
      id: "photo1",
      verification_status: "verified",
      photo_url: fileName,
      is_main_photo: true,
    },
  ];

  useEffect(() => {
    let fileLists = [];
    if (userPhotos?.length > 0) {
      for (var i = 0; i < userPhotos?.length; i++) {
        fileLists.push({
          uid: userPhotos?.[i]?.id,
          status: userPhotos?.[i]?.verification_status,
          url: userPhotos?.[i]?.photo_url,
          name: "view",
        });
      }
      setFileList(fileLists);
    }
  }, []);

  const create_ad_pop_up = (data) => {
    let key = [{ key: "createAdPopUp", loading: true }];
    let query = pop_up_query;
    let variables = {
      json: {
        data,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_ad_pop_up = () => {
    let key = [{ key: "getAdPopUp" }];
    let query = get_pop_up_query;
    dispatch(dynamicRequest(key, query));
  };

  useEffect(() => {
    get_ad_pop_up();
  }, []);

  useEffect(() => {
    if (create_ad_pop_up_status === "success") {
      toast.success("Image Uploaded Successfully");
      dispatch(dynamicClear("createAdPopUp"));
    } else if (create_ad_pop_up_error) {
      toast.error("Invalid Submission");
      dispatch(dynamicClear("createAdPopUp"));
    }
  }, [create_ad_pop_up_status, create_ad_pop_up_error]);

  const on_change = (value) => {
    setValue(value);
  };

  const on_finish = (values) => {
    values = {
      ...values,
      mobile_app_img_url: values?.mobile_app_img_url?.[0]?.url,
      web_img_url: values?.web_img_url?.[0]?.url,
    };
    create_ad_pop_up(values);
  };
  const handleChange = (val, value) => {
    console.log("val", val, value);
  };
  return (
    <div>
      <Box>
        <Text bold mt={5}>
          {"AD IMAGE"}
        </Text>
        <Form onFinish={on_finish} onValuesChange={handleChange}>
          <Box style={{ marginRight: "50%" }}>
            <Text>Add Image For Web</Text>
            <AdImageForWeb name={"web_img_url"} />
            <Text>Add Image For App</Text>
            <AdImageForWeb name={"mobile_app_img_url"} />
          </Box>
          <Box width={"25%"}>
            <Text>{"Set the Day Interval:"}</Text>
            <Form.Item name={"day_interval"}>
              <InputNumber
                min={1}
                max={100000}
                onChange={on_change}
                style={{ marginLeft: "90%" }}
              />
            </Form.Item>
          </Box>
          <Box width={"17%"} style={{ marginTop: "9%" }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Box>
        </Form>
      </Box>
    </div>
  );
};
export default AdImage;

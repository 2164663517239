export const SubEducationsOptions = [
  {
    value: "b_sc_physics",
    en_label: "B.Sc. Physics",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_chemistry",
    en_label: "B.Sc. Chemistry",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_mathematics",
    en_label: "B.Sc. Mathematics",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_biology",
    en_label: "B.Sc. Biology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_computer_science",
    en_label: "B.Sc. Computer Science",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_information_technology",
    en_label: "B.Sc. Information Technology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_electronics",
    en_label: "B.Sc. Electronics",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_environmental_science",
    en_label: "B.Sc. Environmental Science",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_biotechnology",
    en_label: "B.Sc. Biotechnology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_microbiology",
    en_label: "B.Sc. Microbiology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_zoology",
    en_label: "B.Sc. Zoology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_botany",
    en_label: "B.Sc. Botany",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_geology",
    en_label: "B.Sc. Geology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_visual_communication",
    en_label: "B.Sc. Visual Communication",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_agriculture",
    en_label: "B.Sc. Agriculture",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_horticulture",
    en_label: "B.Sc. Horticulture",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_nursing",
    en_label: "B.Sc. Nursing",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_nutrition_and_dietetics",
    en_label: "B.Sc. Nutrition And Dietetics",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_physics_with_electronics",
    en_label: "B.Sc. Physics with Electronics",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_statistics",
    en_label: "B.Sc. Statistics",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_home_science",
    en_label: "B.Sc. Home Science",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_psychology",
    en_label: "B.Sc. Psychology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_geology",
    en_label: "B.Sc. Geology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_forestry",
    en_label: "B.Sc. Forestry",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_biochemistry",
    en_label: "B.Sc. Biochemistry",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_animation_and_multimedia",
    en_label: "B.Sc. Animation and multimedia",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_aviation",
    en_label: "B.Sc. Aviation",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_hotel_management",
    en_label: "B.Sc. Hotel Management",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_fashion_design",
    en_label: "B.Sc. Fashion Design",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_interior_design",
    en_label: "B.Sc. Interior Design",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_physical_education",
    en_label: "B.Sc. Physical Education",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_forensic_science",
    en_label: "B.Sc. Forensic Science",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_interior_design",
    en_label: "B.Sc. Interior Design",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_agriculture_biotechnology",
    en_label: "B.Sc. Agriculture Biotechnology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_fisheries_science",
    en_label: "B.Sc. Fisheries Science",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_nautical_science",
    en_label: "B.Sc. Nautical Science",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_astrology",
    en_label: "B.Sc. Astrology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_physician_assistant",
    en_label: "B.Sc.Physician Assistant",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_english_literature",
    en_label: "B.A. English Literature",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_history",
    en_label: "B.A. History",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_political_science",
    en_label: "B.A. Political Science",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_economics",
    en_label: "B.A. Economics",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_sociology",
    en_label: "B.A. Sociology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_psychology",
    en_label: "B.A. Psychology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_geography",
    en_label: "B.A. Geography",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_philosophy",
    en_label: "B.A. Philosophy",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_anthropology",
    en_label: "B.A. Anthropology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_social_work",
    en_label: "B.A. Social Work",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_fine_arts",
    en_label: "B.A. Fine Arts",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_journalism_and_mass_communication",
    en_label: "B.A. Journalism and Mass Communication",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_public_administration",
    en_label: "B.A. Public Administration",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_linguistics",
    en_label: "B.A. Linguistics",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_music",
    en_label: "B.A. Music",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_dance",
    en_label: "B.A. Dance",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_theatre_arts",
    en_label: "B.A. Theatre Arts",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_education",
    en_label: "B.A. Education",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_foreign_language",
    en_label: "B.A. Foreign Language",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_visual_arts",
    en_label: "B.A. Visual Arts",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_women's_studies",
    en_label: "B.A. Women's Studies",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_cultural_studies",
    en_label: "B.A. Cultural Studies",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_rural_development",
    en_label: "B.A. Rural Development",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_heritage_management",
    en_label: "B.A. Heritage Management",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_travel_and_tourism_management",
    en_label: "B.A. Travel And Tourism Management",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_event_management",
    en_label: "B.A. Event Management",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_international_relations",
    en_label: "B.A.International Relations",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_environmental_studies",
    en_label: "B.A. Environmental Studies",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_archaeology",
    en_label: "B.A. Archaeology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_social_science",
    en_label: "B.A. Social Science",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_religious_studies",
    en_label: "B.A. Religious Studies",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_yoga",
    en_label: "B.A. Yoga",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_astrology",
    en_label: "B.A. Astrology",
    tn_label: null,
    p_o: ["b_arch"],
  },

  {
    value: "b_com_general",
    en_label: "B.Com. General",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_accounting_and_finance",
    en_label: "B.Com. Accounting and Finance",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_banking_and_insurance",
    en_label: "B.Com. Banking and Insurance",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_taxation",
    en_label: "B.Com. Taxation",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_corporate_secretaryship",
    en_label: "B.Com. Corporate Secretaryship",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_business_economics",
    en_label: "B.Com. Business Economics",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_computer_applications",
    en_label: "B.Com. Computer Applications",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_e-commerce",
    en_label: "B.Com. e-Commerce",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_international_business",
    en_label: "B.Com. International Business",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_marketing",
    en_label: "B.Com. Marketing",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_entrepreneurship",
    en_label: "B.Com. Entrepreneurship",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_retail_management",
    en_label: "B.Com. Retail Management",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_auditing",
    en_label: "B.Com. Auditing",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_cost_and_management_accounting",
    en_label: "B.Com. Cost and Management Accounting",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_financial_markets",
    en_label: "B.Com. Financial Markets",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_financial_services",
    en_label: "B.Com. Financial Services",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_investment_management",
    en_label: "B.Com. Investment Management",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_human_resource_management",
    en_label: "B.Com. Human Resource Management",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_supply_chain_management",
    en_label: "B.Com. Supply Chain Management",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_tourism_and_travel_management",
    en_label: "B.Com. Tourism and Travel Management",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_hotel_management",
    en_label: "B.Com. Hotel Management",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_event_management",
    en_label: "B.Com. Event Management",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_real_estate_management",
    en_label: "B.Com. Real Estate Management",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_computer_science",
    en_label: "B.Com. Computer Science",
    tn_label: null,
    p_o: ["b_arch"],
  },

  {
    value: "bba",
    en_label: "BBA (Bachelor of Business Administration)",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bms",
    en_label: "BMS",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_finance",
    en_label: "BBA in Finance",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_marketing",
    en_label: "BBA in Marketing",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_human_resource_management",
    en_label: "BBA in Human Resource Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_international_business",
    en_label: "BBA in International Business",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_entrepreneurship",
    en_label: "BBA in Entrepreneurship",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_operations_management",
    en_label: "BBA in Operations Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_supply_chain_management",
    en_label: "BBA in Supply Chain Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_business_analytics",
    en_label: "BBA in Business Analytics",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_retail_management",
    en_label: "BBA in Retail Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_information_technology",
    en_label: "BBA in Information Technology",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_hotel_management",
    en_label: "BBA in Hotel Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_event_management",
    en_label: "BBA in Event Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_aviation_management",
    en_label: "BBA in Aviation Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_tourism_management",
    en_label: "BBA in Tourism Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_real_estate_management",
    en_label: "BBA in Real Estate Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_agribusiness_management",
    en_label: "BBA in Agribusiness Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_sports_management",
    en_label: "BBA in Sports Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_media_and_communication",
    en_label: "BBA in Media and Communication",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_fashion_management",
    en_label: "BBA in Fashion Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_luxury_brand_management",
    en_label: "BBA in Luxury Brand Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_healthcare_management",
    en_label: "BBA in Healthcare Management",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_digital_marketing",
    en_label: "BBA in Digital Marketing",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },
  {
    value: "bba_in_e_Commerce",
    en_label: "BBA in E-Commerce",
    tn_label: null,
    p_o: ["b_sc_it_computer_science"],
  },

  {
    value: "b_el_ed",
    en_label: "B.El.Ed (Bachelor of Elementary Education)",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_a_b_ed",
    en_label: "B.A. B.Ed (Integrated)",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_sc_b_ed",
    en_label: "B.Sc. B.Ed (Integrated)",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_com_b_ed",
    en_label: "B.Com. B.Ed (Integrated)",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_ed_in_special_education",
    en_label: "B.Ed in Special Education",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_ed_in_childhood_education",
    en_label: "B.Ed in Childhood Education",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_ed_in_physical_education",
    en_label: "B.Ed in Physical Education",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_ped",
    en_label: "B Ped",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_lit",
    en_label: "B Lit",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_lit_sc",
    en_label: "B Lib Sc",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_ed_in_vocational_education",
    en_label: "B.Ed in Vocational Education",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_ed_in_educational_technology",
    en_label: "B.Ed in Educational Technology",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_ed_in_educational_leadership",
    en_label: "B.Ed in Educational Leadership",
    tn_label: null,
    p_o: ["b_arch"],
  },
  {
    value: "b_ed_in_guidance_and_counseling",
    en_label: "B.Ed in Guidance and Counseling",
    tn_label: null,
    p_o: ["b_arch"],
  },

  {
    value: "b_phill",
    en_label: "B.Phill",
    tn_label: null,
    p_o: ["b_phil"],
  },
  {
    value: "b_phill_philosophy",
    en_label: "B.Phil in Philosophy",
    tn_label: null,
    p_o: ["b_phil"],
  },
  {
    value: "b_phill_liberal_arts",
    en_label: "B.Phil in Liberal Arts",
    tn_label: null,
    p_o: ["b_phil"],
  },

  {
    value: "b_l",
    en_label: "B.L",
    tn_label: null,
    p_o: ["m_tech"],
  },
  {
    value: "l_l_b",
    en_label: "LL.B",
    tn_label: null,
    p_o: ["m_tech"],
  },
  {
    value: "b_g_l",
    en_label: "BGL",
    tn_label: null,
    p_o: ["m_tech"],
  },
  {
    value: "integerated_l_l_b",
    en_label: "Integrated LL.B",
    tn_label: null,
    p_o: ["m_tech"],
  },

  {
    value: "bsw",
    en_label: "BSW",
    tn_label: null,
    p_o: ["b_s_w"],
  },

  {
    value: "be_ b_tech_civil_engineering",
    en_label: "B.E. / B.Tech Civil Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_mechanical_engineering",
    en_label: "B.E. / B.Tech Mechanical Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_electrical_engineering",
    en_label: "B.E. / B.Tech Electrical Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_computer_science_and_engineering",
    en_label: "B.E. / B.Tech Computer Science and Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_information_technology",
    en_label: "B.E. / B.Tech Information Technology",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_chemical_engineering",
    en_label: "B.E. / B.Tech Chemical Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_aerospace_engineering",
    en_label: "B.E. / B.Tech Aerospace Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_biotechnology",
    en_label: "B.E. / B.Tech Biotechnology",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_environmental_engineering",
    en_label: "B.E. / B.Tech Environmental Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_industrial_engineering",
    en_label: "B.E. / B.Tech Industrial Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_automobile_engineering",
    en_label: "B.E. / B.Tech Automobile Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_instrumentation_engineering",
    en_label: "B.E. / B.Tech Instrumentation Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_robotics_engineering",
    en_label: "B.E. / B.Tech Robotics Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_biomedical_engineering",
    en_label: "B.E. / B.Tech Biomedical Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_aeronautical_engineering",
    en_label: "B.E. / B.Tech Aeronautical Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_software_engineering",
    en_label: "B.E. / B.Tech Software Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_civil_and_environmental_engineering",
    en_label: "B.E. / B.Tech Civil and Environmental Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_materials_engineering",
    en_label: "B.E. / B.Tech Materials Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_mechatronics_engineering",
    en_label: "B.E. / B.Tech Mechatronics Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_textile_engineering",
    en_label: "B.E. / B.Tech Textile Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_production_engineering",
    en_label: "B.E. / B.Tech Production Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_mining_engineering",
    en_label: "B.E. / B.Tech Mining Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_marine_engineering",
    en_label: "B.E. / B.Tech Marine Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_agricultural-engineering",
    en_label: "B.E. / B.Tech Agricultural Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_ceramic_engineering",
    en_label: "B.E. / B.Tech Ceramic Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_petroleum_engineering",
    en_label: "B.E. / B.Tech Petroleum Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_construction_engineering",
    en_label: "B.E. / B.Tech Construction Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_polymer_engineering",
    en_label: "B.E. / B.Tech Polymer Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_telecommunication_engineering",
    en_label: "B.E. / B.Tech Telecommunication Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_power_engineering",
    en_label: "B.E. / B.Tech Power Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_systems_engineering",
    en_label: "B.E. / B.Tech Systems Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_geo_informatics",
    en_label: "B.E. / B.Tech Geo-Informatics",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_energy_engineering",
    en_label: "B.E. / B.Tech Energy Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_infrastructure_engineering",
    en_label: "B.E. / B.Tech Infrastructure Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_food_technology",
    en_label: "B.E. / B.Tech Food Technology",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_water_resources_engineering",
    en_label: "B.E. / B.Tech Water Resources Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_metallurgical_engineering",
    en_label: "B.E. / B.Tech Metallurgical Engineering",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_nano_technology",
    en_label: "B.E. / B.Tech Nano Technology",
    tn_label: null,
    p_o: ["be"],
  },
  {
    value: "be_b_tech_electronics_comm_eng",
    en_label: "B.E. / B.Tech Electronics and Communication Engineering",
    tn_label: null,
    p_o: ["be"],
  },

  {
    value: "b_pharm_",
    en_label: "B.Pharm ",
    tn_label: null,
    p_o: ["m_sc_it_computer_science"],
  },
  {
    value: "b_pharm_in_pharmaceutical_sciences",
    en_label: "B.Pharm in Pharmaceutical Sciences",
    tn_label: null,
    p_o: ["m_sc_it_computer_science"],
  },
  {
    value: "b_pharm_in_pharmacology",
    en_label: "B.Pharm in Pharmacology",
    tn_label: null,
    p_o: ["m_sc_it_computer_science"],
  },
  {
    value: "b_pharm_in_pharmaceutical_chemistry",
    en_label: "B.Pharm in Pharmaceutical Chemistry",
    tn_label: null,
    p_o: ["m_sc_it_computer_science"],
  },
  {
    value: "b_pharm_in_pharmacy_practice",
    en_label: "B.Pharm in Pharmacy Practice",
    tn_label: null,
    p_o: ["m_sc_it_computer_science"],
  },
  {
    value: "b_pharm_in_pharmaceutical_technology",
    en_label: "B.Pharm in Pharmaceutical Technology",
    tn_label: null,
    p_o: ["m_sc_it_computer_science"],
  },
  {
    value: "b_pharm_in_pharmaceutics",
    en_label: "B.Pharm in Pharmaceutics",
    tn_label: null,
    p_o: ["m_sc_it_computer_science"],
  },
  {
    value: "b_pharm_in_industrial_pharmacy",
    en_label: "B.Pharm in Industrial Pharmacy",
    tn_label: null,
    p_o: ["m_sc_it_computer_science"],
  },
  {
    value: "b_pharm_in_clinical_pharmacy",
    en_label: "B.Pharm in Clinical Pharmacy",
    tn_label: null,
    p_o: ["m_sc_it_computer_science"],
  },
  {
    value: "b_pharm_in_herbal_medicine",
    en_label: "B.Pharm in Herbal Medicine",
    tn_label: null,
    p_o: ["m_sc_it_computer_science"],
  },
  {
    value: "b_pharm_in_regulatory_affairs",
    en_label: "B.Pharm in Regulatory Affairs",
    tn_label: null,
    p_o: ["m_sc_it_computer_science"],
  },
  {
    value: "b_pharm_in_pharmaceutical_management",
    en_label: "B.Pharm in Pharmaceutical Management",
    tn_label: null,
    p_o: ["m_sc_it_computer_science"],
  },

  {
    value: "mbbs",
    en_label: "MBBS (Bachelor of Medicine, Bachelor of Surgery)",
    tn_label: null,
    p_o: ["b_s_engineering"],
  },
  {
    value: "bds",
    en_label: "BDS (Bachelor of Dental Surgery)",
    tn_label: null,
    p_o: ["b_s_engineering"],
  },
  {
    value: "bams",
    en_label: "BAMS (Bachelor of Ayurvedic Medicine and Surgery)",
    tn_label: null,
    p_o: ["b_s_engineering"],
  },
  {
    value: "bhms",
    en_label: "BHMS (Bachelor of Homeopathic Medicine and Surgery)",
    tn_label: null,
    p_o: ["b_s_engineering"],
  },
  {
    value: "bums",
    en_label: "BUMS (Bachelor of Unani Medicine and Surgery)",
    tn_label: null,
    p_o: ["b_s_engineering"],
  },
  {
    value: "byns",
    en_label: "BNYS (Bachelor of Naturopathy and Yogic Sciences)",
    tn_label: null,
    p_o: ["b_s_engineering"],
  },
  {
    value: "bvsc_bvs",
    en_label: "BVSC (Bachelor of Veterinary Science )",
    tn_label: null,
    p_o: ["b_s_engineering"],
  },
  {
    value: "m_sc_physics",
    en_label: "M.Sc. Physics",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_chemistry",
    en_label: "M.Sc. Chemistry",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_mathematics",
    en_label: "M.Sc. Mathematics",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_biology",
    en_label: "M.Sc. Biology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_computer_science",
    en_label: "M.Sc. Computer Science",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_information_technology",
    en_label: "M.Sc. Information Technology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_electronics",
    en_label: "M.Sc. Electronics",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_environmental_science",
    en_label: "M.Sc. Environmental Science",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_biotechnology",
    en_label: "M.Sc. Biotechnology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_microbiology",
    en_label: "M.Sc. Microbiology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_zoology",
    en_label: "M.Sc. Zoology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_botany",
    en_label: "M.Sc. Botany",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_geology",
    en_label: "M.Sc. Geology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_visual_communication",
    en_label: "M.Sc. Visual Communication",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_agriculture",
    en_label: "M.Sc. Agriculture",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_horticulture",
    en_label: "M.Sc. Horticulture",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_nursing",
    en_label: "M.Sc. Nursing",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_nutrition_and_dietetics",
    en_label: "M.Sc. Nutrition and Dietetics",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_physics_with_electronics",
    en_label: "M.Sc. Physics with Electronics",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_statistics",
    en_label: "M.Sc. Statistics",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_home_science",
    en_label: "M.Sc. Home Science",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_psychology",
    en_label: "M.Sc. Psychology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_forestry",
    en_label: "M.Sc. Forestry",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_biochemistry",
    en_label: "M.Sc. Biochemistry",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_animation_and_multimedia",
    en_label: "M.Sc. Animation and Multimedia",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_aviation",
    en_label: "M.Sc. Aviation",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_hotel_management",
    en_label: "M.Sc. Hotel Management",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_fashion_design",
    en_label: "M.Sc. Fashion Design",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_interior_design",
    en_label: "M.Sc. Interior Design",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_physical_education",
    en_label: "M.Sc. Physical Education",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_forensic_science",
    en_label: "M.Sc. Forensic Science",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_interior_design",
    en_label: "M.Sc. Interior Design",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_agriculture_biotechnology",
    en_label: "M.Sc. Agriculture Biotechnology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_fisheries_science",
    en_label: "M.Sc. Fisheries Science",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_nautical_science",
    en_label: "M.Sc. Nautical Science",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_sc_astrology",
    en_label: "M.Sc. Astrology",
    tn_label: null,
    p_o: ["bca"],
  },

  {
    value: "m_sc_physician_assistant",
    en_label: "M.Sc. Physician Assistant",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_english_literature",
    en_label: "M.A. English Literature",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_history",
    en_label: "M.A. History",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_political_science",
    en_label: "M.A. Political Science",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_economics",
    en_label: "M.A. Economics",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_sociology",
    en_label: "M.A. Sociology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_psychology",
    en_label: "M.A. Psychology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_geography",
    en_label: "M.A. Geography",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_philosophy",
    en_label: "M.A. Philosophy",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_anthropology",
    en_label: "M.A. Anthropology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_social Work",
    en_label: "M.A. Social Work",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_fine_arts",
    en_label: "M.A. Fine Arts",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_journalism_and_mass_communication",
    en_label: "M.A. Journalism and Mass Communication",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_public_administration",
    en_label: "M.A. Public Administration",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_linguistics",
    en_label: "M.A. Linguistics",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_music",
    en_label: "M.A. Music",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_dance",
    en_label: "M.A. Dance",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_theatre_arts",
    en_label: "M.A. Theatre Arts",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_education",
    en_label: "M.A. Education",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_foreign_languages",
    en_label: "M.A. Foreign Languages",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_visual_arts",
    en_label: "M.A. Visual Arts",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_women's_studies",
    en_label: "M.A. Women's Studies",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_cultural_studies",
    en_label: "M.A. Cultural Studies",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_rural_development",
    en_label: "M.A. Rural Development",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_heritage_management",
    en_label: "M.A. Heritage Management",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_travel_and_tourism_management",
    en_label: "M.A. Travel and Tourism Management",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_event_management",
    en_label: "M.A. Event Management",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_international_relations",
    en_label: "M.A. International Relations",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_environmental_studies",
    en_label: "M.A. Environmental Studies",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_archaeology",
    en_label: "M.A. Archaeology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_social_science",
    en_label: "M.A. Social Science",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_religious_studies",
    en_label: "M.A. Religious Studies",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_yoga",
    en_label: "M.A. Yoga",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_a_astrology",
    en_label: "M.A. Astrology",
    tn_label: null,
    p_o: ["bca"],
  },

  {
    value: "m_com_general",
    en_label: "M.Com. General",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_accounting_and_finance",
    en_label: "M.Com. Accounting and Finance",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_banking_and_insurance",
    en_label: "M.Com. Banking and Insurance",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_taxation",
    en_label: "M.Com. Taxation",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_corporate_secretaryship",
    en_label: "M.Com. Corporate Secretaryship",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_business_economics",
    en_label: "M.Com. Business Economics",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_computer_applications",
    en_label: "M.Com.  Computer Applications",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_e-commerce",
    en_label: "M.Com. e-Commerce",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_international_business",
    en_label: "M.Com. International Business",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_marketing",
    en_label: "M.Com. Marketing",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_entrepreneurship",
    en_label: "M.Com. Entrepreneurship",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_retail_management",
    en_label: "M.Com. Retail Management",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_auditing",
    en_label: "M.Com. Auditing",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_cost_and_management_accounting",
    en_label: "M.Com. Cost and Management Accounting",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_financial_markets",
    en_label: "M.Com. Financial Markets",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_financial_services",
    en_label: "M.Com. Financial Services",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_investment_management",
    en_label: "M.Com. Investment Management",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_human_resource_management",
    en_label: "M.Com. Human Resource Management",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_supply_chain_management",
    en_label: "M.Com. Supply Chain Management",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_tourism_and_travel_management",
    en_label: "M.Com. Tourism and Travel Management",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_hotel_management",
    en_label: "M.Com. Hotel Management",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_event_management",
    en_label: "M.Com. Event Management",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_real_estate_management",
    en_label: "M.Com. Real Estate Management",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_com_computer_science",
    en_label: "M.Com. Computer Science",
    tn_label: null,
    p_o: ["bca"],
  },

  {
    value: "mba_in_marketing",
    en_label: "MBA in Marketing",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_finance",
    en_label: "MBA in Finance",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_human_resource_management",
    en_label: "MBA in Human Resource Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_operations_management",
    en_label: "MBA in Operations Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_international_business",
    en_label: "MBA in International Business",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_information_technology",
    en_label: "MBA in Information Technology",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_entrepreneurship",
    en_label: "MBA in Entrepreneurship",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_healthcare_management",
    en_label: "MBA in Healthcare Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_supply_chain_management",
    en_label: "MBA in Supply Chain Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_business_analytics",
    en_label: "MBA in Business Analytics",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_digital_marketing",
    en_label: "MBA in Digital Marketing",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_luxury_management",
    en_label: "MBA in Luxury Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_rural_management",
    en_label: "MBA in Rural Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_agribusiness_management",
    en_label: "MBA in Agribusiness Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_event_management",
    en_label: "MBA in Event Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_hospitality_management",
    en_label: "MBA in Hospitality Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "mba_in_sports_management",
    en_label: "MBA in Sports Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_management_studies",
    en_label: "Master of Management Studies",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_master_of_retail_management",
    en_label: "Master of Master of Retail Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_hotel_management",
    en_label: "Master of Hotel Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_international_business ",
    en_label: "Master of International Business ",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_finance_and_control",
    en_label: "Master of Finance and Control",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_financial_management",
    en_label: "Master of Financial Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_human_resource_management",
    en_label: "Master of Human Resource Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_marketing_management",
    en_label: "Master of Marketing Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_rural_development_and_management",
    en_label: "Master of Rural Development and Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_entrepreneurship_and_family_business",
    en_label: "Master of Entrepreneurship and Family Business",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_hospital_administration",
    en_label: "Master of Hospital Administration",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_supply_chain_management",
    en_label: "Master of Supply Chain Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_agribusiness_management",
    en_label: "Master of Agribusiness Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_arts_in_management ",
    en_label: "Master of Arts in Management ",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_information_management",
    en_label: "Master of Information Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_communication_management",
    en_label: "Master of Communication Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_event_management",
    en_label: "Master of Event Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_sports_management",
    en_label: "Master of Sports Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_technology management",
    en_label: "Master of Technology Management",
    tn_label: null,
    p_o: ["b_tech"],
  },
  {
    value: "master_of_rural_and_urban_planning",
    en_label: "Master of Rural and Urban Planning",
    tn_label: null,
    p_o: ["b_tech"],
  },

  {
    value: "msw",
    en_label: "MSW",
    tn_label: null,
    p_o: ["m_s_w"],
  },

  {
    value: "m_ed_in_educational_leadership",
    en_label: "M.Ed. In Educational Leadership",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_curriculum_and_instruction",
    en_label: "M.Ed. In Curriculum and Instruction",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_educational_psychology",
    en_label: "M.Ed. In Educational Psychology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_special_education",
    en_label: "M.Ed. In Special Education",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_educational_technology",
    en_label: "M.Ed. In Educational Technology",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_early_childhood_education",
    en_label: "M.Ed. In Early Childhood Education",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_educational_administration",
    en_label: "M.Ed. In Educational Administration",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_guidance_and_counseling",
    en_label: "M.Ed. In Guidance and Counseling",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_teacher_education",
    en_label: "M.Ed. In Teacher Education",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_adult_and_continuing_education",
    en_label: "M.Ed. In Adult and Continuing Education",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_language_education",
    en_label: "M.Ed. In Language Education",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_mathematics_education",
    en_label: "M.Ed. In Mathematics Education",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_science_education",
    en_label: "M.Ed. In Science Education",
    tn_label: null,
    p_o: ["bca"],
  },
  {
    value: "m_ed_in_social_studies_education",
    en_label: "M.Ed. In Social Studies Education",
    tn_label: null,
    p_o: ["bca"],
  },

  {
    value: "m_phill_in_philosophy",
    en_label: "M.Phill in Philosophy",
    tn_label: null,
    p_o: ["m_a"],
  },
  {
    value: "m_phill_in_english_literature",
    en_label: "M.Phill in English Literature",
    tn_label: null,
    p_o: ["m_a"],
  },
  {
    value: "m_phill_in_social_work",
    en_label: "M.Phill in Social Work",
    tn_label: null,
    p_o: ["m_a"],
  },
  {
    value: "m_phill_in_history",
    en_label: "M.Phill in History",
    tn_label: null,
    p_o: ["m_a"],
  },
  {
    value: "m_phill_in_psychology",
    en_label: "M.Phill in Psychology",
    tn_label: null,
    p_o: ["m_a"],
  },
  {
    value: "m_phill_in_economics",
    en_label: "M.Phill in Economics",
    tn_label: null,
    p_o: ["m_a"],
  },
  {
    value: "m_phill_in_education",
    en_label: "M.Phill in Education",
    tn_label: null,
    p_o: ["m_a"],
  },
  {
    value: "m_phill_in_computer_science",
    en_label: "M.Phill in Computer Science",
    tn_label: null,
    p_o: ["m_a"],
  },

  {
    value: "ll_m_in_international_law",
    en_label: "LL.M.in International Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_human_rights_law",
    en_label: "LL.M.in Human Rights Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_environmental_law",
    en_label: "LL.M.in Environmental Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_intellectual_property_law",
    en_label: "LL.M.in Intellectual Property Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_taxation_law",
    en_label: "LL.M.in Taxation Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_corporate_law",
    en_label: "LL.M.in Corporate Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_criminal_law",
    en_label: "LL.M.in Criminal Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_family_law",
    en_label: "LL.M.in Family Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_health_law",
    en_label: "LL.M.in Health Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_immigration_law",
    en_label: "LL.M.in Immigration Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_employment_and_labor_law",
    en_label: "LL.M.in Employment and Labor Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_maritime_and_admiralty_law",
    en_label: "LL.M.in Maritime and Admiralty Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_energy_law",
    en_label: "LL.M.in Energy Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_banking_and_finance_law",
    en_label: "LL.M.in Banking and Finance Law",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "ll_m_in_dispute_resolution_and_arbitration",
    en_label: "LL.M.in Dispute Resolution and Arbitration",
    tn_label: null,
    p_o: ["pgdca"],
  },
  {
    value: "m_l",
    en_label: "M.L",
    tn_label: null,
    p_o: ["pgdca"],
  },

  {
    value: "me_m_tech_in_civil_engineering",
    en_label: "M.E / M.Tech in Civil Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_mechanical_engineering",
    en_label: "M.E / M.Tech in Mechanical Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_electrical_engineering",
    en_label: "M.E / M.Tech in Electrical Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_computer_science_and_engineering",
    en_label: "M.E / M.Tech in Computer Science and Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_information_technology",
    en_label: "M.E / M.Tech in Information Technology",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_chemical_engineering",
    en_label: "M.E / M.Tech in Chemical Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_aerospace_engineering",
    en_label: "M.E / M.Tech in Aerospace Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_biotechnology",
    en_label: "M.E / M.Tech in Biotechnology",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_environmental_engineering",
    en_label: "M.E / M.Tech in Environmental Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_industrial_engineering",
    en_label: "M.E / M.Tech in Industrial Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_automobile_engineering",
    en_label: "M.E / M.Tech in Automobile Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_instrumentation_engineering",
    en_label: "M.E / M.Tech in Instrumentation Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_robotics_engineering",
    en_label: "M.E / M.Tech in Robotics Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_biomedical_engineering",
    en_label: "M.E / M.Tech in Biomedical Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_aeronautical_engineering",
    en_label: "M.E / M.Tech in Aeronautical Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_software_engineering",
    en_label: "M.E / M.Tech in Software Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_civil_and_environmental_engineering",
    en_label: "M.E / M.Tech in Civil and Environmental Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_materials_engineering",
    en_label: "M.E / M.Tech in Materials Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_mechatronics_engineering",
    en_label: "M.E / M.Tech in Mechatronics Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_textile_engineering",
    en_label: "M.E / M.Tech in Textile Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_production_engineering",
    en_label: "M.E / M.Tech in Production Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_mining_engineering",
    en_label: "M.E / M.Tech in Mining Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_marine_engineering",
    en_label: "M.E / M.Tech in Marine Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_agricultural_engineering",
    en_label: "M.E / M.Tech in Agricultural Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_ceramic_engineering",
    en_label: "M.E / M.Tech in Ceramic Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_petroleum_engineering",
    en_label: "M.E / M.Tech in Petroleum Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_construction_engineering",
    en_label: "M.E / M.Tech in Construction Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_polymer_engineering",
    en_label: "M.E / M.Tech in Polymer Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_telecommunication_engineering",
    en_label: "M.E / M.Tech in Telecommunication Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_power_engineering",
    en_label: "M.E / M.Tech in Power Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_systems_engineering",
    en_label: "M.E / M.Tech in Systems Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_geo_informatics",
    en_label: "M.E / M.Tech in Geo-Informatics",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_energy_engineering",
    en_label: "M.E / M.Tech in Energy Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_infrastructure_engineering",
    en_label: "M.E / M.Tech in Infrastructure Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_food_technology",
    en_label: "M.E / M.Tech in Food Technology",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_water_resources_engineering",
    en_label: "M.E / M.Tech in Water Resources Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_metallurgical_engineering",
    en_label: "M.E / M.Tech in Metallurgical Engineering",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_in_nano_technology",
    en_label: "M.E / M.Tech in Nano Technology",
    tn_label: null,
    p_o: ["b_plan"],
  },
  {
    value: "me_m_tech_electronics_comm_eng",
    en_label: "M.E. / M.Tech Electronics and Communication Engineering.",
    tn_label: null,
    p_o: ["b_plan"],
  },

  {
    value: "m_pharm_in_pharmaceutical_sciences",
    en_label: "M.Pharm in Pharmaceutical Sciences",
    tn_label: null,
    p_o: ["m_s_engg"],
  },
  {
    value: "m_pharm_in_pharmacology",
    en_label: "M.Pharm in Pharmacology",
    tn_label: null,
    p_o: ["m_s_engg"],
  },
  {
    value: "m_pharm_in_pharmaceutical_chemistry",
    en_label: "M.Pharm in Pharmaceutical Chemistry",
    tn_label: null,
    p_o: ["m_s_engg"],
  },
  {
    value: "m_pharm_in_pharmacy_practice",
    en_label: "M.Pharm in Pharmacy Practice",
    tn_label: null,
    p_o: ["m_s_engg"],
  },
  {
    value: "m_pharm_in_pharmaceutical_technology",
    en_label: "M.Pharm in Pharmaceutical Technology",
    tn_label: null,
    p_o: ["m_s_engg"],
  },
  {
    value: "m_pharm_in_pharmaceutics",
    en_label: "M.Pharm in Pharmaceutics",
    tn_label: null,
    p_o: ["m_s_engg"],
  },
  {
    value: "m_pharm_in_industrial_pharmacy",
    en_label: "M.Pharm in Industrial Pharmacy",
    tn_label: null,
    p_o: ["m_s_engg"],
  },
  {
    value: "m_pharm_in_clinical_pharmacy",
    en_label: "M.Pharm in Clinical Pharmacy",
    tn_label: null,
    p_o: ["m_s_engg"],
  },
  {
    value: "m_pharm_in_herbal_medicine",
    en_label: "M.Pharm in Herbal Medicine",
    tn_label: null,
    p_o: ["m_s_engg"],
  },
  {
    value: "m_pharm_in_regulatory_affairs",
    en_label: "M.Pharm in Regulatory Affairs",
    tn_label: null,
    p_o: ["m_s_engg"],
  },
  {
    value: "m_pharm_in_pharmaceutical_management",
    en_label: "M.Pharm in Pharmaceutical Management",
    tn_label: null,
    p_o: ["m_s_engg"],
  },

  {
    value: "md_ms",
    en_label: "MD / MS",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_general_medicine",
    en_label: "MD in General Medicine",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_pediatrics",
    en_label: "MD in Pediatrics",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_dermatology_venereology_and_leprosy",
    en_label: "MD in Dermatology, Venereology, and Leprosy",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_psychiatry",
    en_label: "MD in Psychiatry",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_anesthesiology",
    en_label: "MD in Anesthesiology",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_radiodiagnosis",
    en_label: "MD in Radiodiagnosis",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_pathology",
    en_label: "MD in Pathology",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_pharmacology",
    en_label: "MD in Pharmacology",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_microbiology",
    en_label: "MD in Microbiology",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_community_medicine",
    en_label: "MD in Community Medicine",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_forensic_medicine",
    en_label: "MD in Forensic Medicine",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_general_surgery",
    en_label: "MD in General Surgery",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_orthopedics",
    en_label: "MD in Orthopedics",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_ophthalmology",
    en_label: "MD in Ophthalmology",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_ent",
    en_label: "MD in ENT ",
    tn_label: null,
    p_o: ["m_arch"],
  },
  {
    value: "md_in_obstetrics_and_gynecology",
    en_label: "MD in Obstetrics and Gynecology",
    tn_label: null,
    p_o: ["m_arch"],
  },

  {
    value: "mds_in_oral_and_maxillofacial_surgery",
    en_label: "MDS in Oral and Maxillofacial Surgery",
    tn_label: null,
    p_o: ["me"],
  },
  {
    value: "mds_in_orthodontics_and_dentofacial_orthopedics",
    en_label: "MDS in Orthodontics and Dentofacial Orthopedics",
    tn_label: null,
    p_o: ["me"],
  },
  {
    value: "mds_in_periodontology",
    en_label: "MDS in Periodontology",
    tn_label: null,
    p_o: ["me"],
  },
  {
    value: "mds_in_prosthodontics",
    en_label: "MDS in Prosthodontics",
    tn_label: null,
    p_o: ["me"],
  },
  {
    value: "mds_in_conservative_dentistry_and_endodontics",
    en_label: "MDS in Conservative Dentistry and Endodontics",
    tn_label: null,
    p_o: ["me"],
  },
  {
    value: "ms_ayurveda",
    en_label: "Master of Ayurvedic Medicine and Surgery",
    tn_label: null,
    p_o: ["me"],
  },
  {
    value: "mhms",
    en_label: "Master of Homeopathic Medicine and Surgery",
    tn_label: null,
    p_o: ["me"],
  },
  {
    value: "mums",
    en_label: "Master of Unani Medicine and Surgery",
    tn_label: null,
    p_o: ["me"],
  },
  {
    value: "mvsc",
    en_label: "Master of Veterinary Science and Animal Husbandry ( M.V.Sc)",
    tn_label: null,
    p_o: ["me"],
  },

  {
    value: "ias",
    en_label: "IAS",
    tn_label: null,
    p_o: ["b_com"],
  },
  {
    value: "ies",
    en_label: "IES",
    tn_label: null,
    p_o: ["b_com"],
  },
  {
    value: "ifs",
    en_label: "IFS",
    tn_label: null,
    p_o: ["b_com"],
  },
  {
    value: "irs",
    en_label: "IRS",
    tn_label: null,
    p_o: ["b_com"],
  },
  {
    value: "ips",
    en_label: "IPS",
    tn_label: null,
    p_o: ["b_com"],
  },

  {
    value: "chartered_accountancy ",
    en_label: "Chartered Accountancy ",
    tn_label: null,
    p_o: ["b_a"],
  },
  {
    value: "chartered_financial_analyst",
    en_label: "Chartered Financial Analyst",
    tn_label: null,
    p_o: ["b_a"],
  },
  {
    value: "certified_public_accountant",
    en_label: "Certified Public Accountant",
    tn_label: null,
    p_o: ["b_a"],
  },
  {
    value: "icwa_cma",
    en_label: "ICWA / CMA",
    tn_label: null,
    p_o: ["b_a"],
  },
  {
    value: "company_secretary",
    en_label: " Company secretary ",
    tn_label: null,
    p_o: ["b_a"],
  },

  {
    value: "hsc",
    en_label: "HSC",
    tn_label: null,
    p_o: ["b_s_w"],
  },

  {
    value: "sslc",
    en_label: "SSLC",
    tn_label: null,
    p_o: ["b_sc"],
  },
];

export const EducationsOptions = [
  {
    id: "any-18",
    value: "any",
    p_o: [],
    en_label: "Any",
    ta_label: "Any",
  },
  {
    id: "ded45d8d-b099-4dc7-bb28-7db0f0ad000d",
    value: "b_arch",
    p_o: [],
    en_label: "Bachelors in Arts / Science / Commerce / Others",

    ta_label: null,
  },
  {
    id: "24ef5838-53e3-4cb2-8efc-a1723871b271",
    value: "bca",
    p_o: [],
    en_label: "Masters in Arts / Science / Commerce",
    ta_label: null,
  },
  {
    id: "4b3b5dee-9cdf-4c97-947c-368d8be2fc75",
    value: "be",
    p_o: [],
    en_label: "Bachelors in Engineering / Technology",
    ta_label: null,
  },
  {
    id: "d26db6c7-73dc-4fb3-8db7-c85bc0dc680b",
    value: "b_plan",
    p_o: [],
    en_label: "Masters in Engineering / Technology",
    ta_label: null,
  },
  {
    id: "02027c87-cc33-49fe-b10e-aaae2b2b38a4",
    value: "b_sc_it_computer_science",
    p_o: [],
    en_label: "Bachelors in Management",
    ta_label: null,
  },
  {
    id: "469da407-a8a1-43f6-aa6b-e9272b0d2f9e",
    value: "b_tech",
    p_o: [],
    en_label: "Masters in Management",
    ta_label: null,
  },
  {
    id: "98f7e615-6e34-42c0-9630-da676f4c158d",
    value: "b_s_engineering",
    p_o: [],
    en_label: "Bachelor of Medicine",
    ta_label: null,
  },
  {
    id: "48badd6b-f3e2-46e3-96e0-885dec6f9cc8",
    value: "m_arch",
    p_o: [],
    en_label: "MD / MS - Masters in Medicine / General / Surgeon",
    ta_label: null,
  },
  {
    id: "1d167264-b1d9-43b2-a7a1-4aeb102a5e9f",
    value: "mca",
    p_o: [],
    en_label: "Bachelors in Dental",
    ta_label: null,
  },
  {
    id: "5c5a18d9-4f59-4337-8780-206311d8c0e2",
    value: "me",
    p_o: [],
    en_label: "Masters in Dental",
    ta_label: null,
  },
  {
    id: "5fb842a1-9a6a-4cb8-86b5-7a3f0fae8039",
    value: "m_sc_it_computer_science",
    p_o: [],
    en_label: "Bachelors in Nursing / Pharmacy / Physiotherapy",
    ta_label: null,
  },
  {
    id: "a2e7e639-332d-434e-a232-d5437101fc57",
    value: "m_s_engg",
    p_o: [],
    en_label: "Masters in Nursing / Pharmacy / Physiotherapy",
    ta_label: null,
  },
  {
    id: "e17c6a0a-2440-4d20-b86f-666fc2550154",
    value: "m_tech",
    p_o: [],
    en_label: "Bachelors in Legal",
    ta_label: null,
  },
  {
    id: "dfd71b2c-f2f0-4c35-b2fd-a149ba62db65",
    value: "pgdca",
    p_o: [],
    en_label: "Masters in Legal",
    ta_label: null,
  },
  {
    id: "3043e91a-f75e-40df-9c27-4fd39b0cbc3b",
    value: "b_a",
    p_o: [],
    en_label: "Finance in CA / ICWA / CS / CFA / Others",
    ta_label: null,
  },
  {
    id: "689337cc-0695-4008-96b5-c9da28aa7e01",
    value: "b_com",
    p_o: [],
    en_label: "Civil Service IAS / IPS / IRS / IES / IFS",
    ta_label: null,
  },
  {
    id: "b5456a56-906b-4371-88d9-5b966f820d07",
    value: "b_ed",
    p_o: [],
    en_label: "Doctorates PhD / FNB / Others",
    ta_label: null,
  },
  {
    id: "e5cbf995-e621-484a-ad9f-06c91e027ca0",
    value: "bfa",
    p_o: [],
    en_label: "Post Graduate Diploma",
    ta_label: null,
  },
  {
    id: "37e577e3-8975-486c-bc47-05c984e429ea",
    value: "bft",
    p_o: [],
    en_label: "Diploma in Engineering",
    ta_label: null,
  },
  {
    id: "5b1cbe85-4173-4f83-9056-dcee311b210e",
    value: "blis",
    p_o: [],
    en_label: "Diploma in Certification Course / Others",
    ta_label: null,
  },
  {
    id: "424a0a32-947b-4cf5-8541-7b9a3f45677f",
    value: "b_m_m",
    p_o: [],
    en_label: "I T I",
    ta_label: null,
  },
  {
    id: "e928eb0e-7b81-4d45-92d3-02f7a2665c2f",
    value: "b_sc",
    p_o: [],
    en_label: "Primary School / SSLC / Others",
    ta_label: null,
  },
  {
    id: "6c88f2dc-4925-404d-9443-de757ed457c7",
    value: "b_s_w",
    p_o: [],
    en_label: "Higher Secondary / HSC / Others",
    ta_label: null,
  },
  {
    id: "04350c0f-4fd1-469b-bf97-8fd5ebe5e9c3",
    value: "b_phil",
    p_o: [],
    en_label: "B.Phil",
    ta_label: null,
  },
  {
    id: "1ef63b55-3408-45ef-9b59-23de96937d17",
    value: "m_a",
    p_o: [],
    en_label: "M. Phil",
    ta_label: null,
  },
  {
    id: "55affd7c-5a2d-43d6-882c-e48a17bc1282",
    value: "diploma_in_pharmacy",
    p_o: [],
    en_label: "Diploma in Pharmacy / Nursing / Physiotherapy",
    ta_label: null,
  },
  {
    id: "96bd2133-0df3-4936-8067-d412f9efed3e",
    value: "studying_md/ms",
    p_o: [],
    en_label: "Studying MD/MS",
    ta_label: null,
  },
];

import { getUserDetailsFromValue } from "@helpers/functions";
import { Box, HStack, Text } from "native-base";
import React from "react";

const PrintElement = (props) => {
  const { label, value, text, items, options } = props;
  return (
    <Box marginTop={"-2px"}>
      <HStack width={"100%"}>
        <Box width={"45%"}>
          <Text
            fontSize={13}
            bold
            style={{ display: "flex", flexDirection: "row" }}
          >
            {label}
          </Text>
        </Box>

        <Box width={"5%"}>:</Box>

        <Box style={{ width: "55%" }}>
          {text === "multi" ? (
            items?.map((x, i) => {
              return (
                <Text color={"black"}>
                  {`${getUserDetailsFromValue(options, x)}${
                    i === items?.length - 1 ? "" : ", "
                  }`}
                </Text>
              );
            })
          ) : (
            <Text fontSize={13}>{value}</Text>
          )}
        </Box>
      </HStack>
    </Box>
  );
};

export default PrintElement;

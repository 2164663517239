import { CalculateAge, CalculateAgeForMember } from "@helpers/calculate_age";
import colors from "@helpers/colors";
import { getCroppedImageUrl, getProfileUrl } from "@helpers/get_profile_url";
import { navigate } from "@helpers/navigator";
import { retrieveItem } from "@helpers/storage";
import { useDynamicSelector } from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Spin } from "antd";
import { filter } from "lodash";
import moment from "moment";
import {
  Box,
  Hidden,
  HStack,
  Image,
  Pressable,
  Skeleton,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";

const UserRequestMembers = (props) => {
  const {
    data,
    getUserRequests,
    setRequestGroupId,
    onResponsiveClose,
    getAllList,
    getReceivedList,
    getSentList,
  } = props;

  const { request_id } = useParams();

  const { loading: request_groups_loading } =
    useDynamicSelector("request_groups");

  const [current_id, setCurrentId] = useState();
  const [param_id, setParamId] = useState();
  const [hover, setHover] = useState(false);
  const [_user_id, setUserID] = useState("");
  const [login_user_gender, setLoginUserGender] = useState("");
  const [group_members, setGroupMembers] = useState([]);
  const [button_type, setButtonType] = useState("all");

  useEffect(() => {
    let login_user_id = retrieveItem("user_id");
    let login_user_gender = retrieveItem("gender");
    setUserID(login_user_id);
    setLoginUserGender(login_user_gender);
  }, []);

  useEffect(() => {
    setGroupMembers(data);
  }, [data]);

  useEffect(() => {
    if (request_id) {
      setHover(true);
      setParamId(request_id);
    }
  }, [request_id]);

  return (
    <>
      {request_groups_loading ? (
        <Box width={"100%"} alignItems={"center"} justifyContent={"center"}>
          <Skeleton
            style={{
              borderRadius: 10,
            }}
            marginTop={5}
            h={100}
            width={"90%"}
          />
          <Skeleton
            style={{
              borderRadius: 10,
            }}
            marginTop={5}
            h={100}
            width={"90%"}
          />
          <Skeleton
            style={{
              borderRadius: 10,
            }}
            marginTop={5}
            width={"90%"}
            h={100}
          />
          <Skeleton
            style={{
              borderRadius: 10,
            }}
            marginTop={5}
            width={"90%"}
            h={100}
          />
          <Skeleton
            style={{
              borderRadius: 10,
            }}
            marginTop={5}
            width={"90%"}
            h={100}
          />
        </Box>
      ) : (
        <>
          <Box
            height={30}
            backgroundColor={colors.primary}
            borderTopRightRadius={20}
            borderBottomRightRadius={20}
            borderTopLeftRadius={20}
            borderBottomLeftRadius={20}
            my={5}
            width={"90%"}
          >
            <HStack>
              <Pressable
                onPress={() => {
                  getAllList(group_members);
                  setButtonType("all");
                }}
                height={30}
                borderWidth={0.5}
                borderColor={"#fff"}
                borderTopLeftRadius={20}
                borderBottomLeftRadius={20}
                alignItems={"center"}
                justifyContent={"center"}
                width={"33%"}
                backgroundColor={
                  button_type === "all" ? colors.primary : "#dbdad5"
                }
              >
                <Text color={button_type === "all" ? "#fff" : "#111"}>
                  {"All"}
                </Text>
              </Pressable>

              <Pressable
                onPress={() => {
                  getReceivedList(group_members);
                  setButtonType("received");
                }}
                borderWidth={0.5}
                borderColor={"#fff"}
                justifyContent={"center"}
                alignItems={"center"}
                height={30}
                width={"34%"}
                backgroundColor={
                  button_type === "received" ? colors.primary : "#dbdad5"
                }
              >
                <Text color={button_type === "received" ? "#fff" : "#111"}>
                  {"Received"}
                </Text>
              </Pressable>
              <Pressable
                onPress={() => {
                  getSentList(group_members);
                  setButtonType("send");
                }}
                borderWidth={0.5}
                borderColor={"#fff"}
                height={30}
                borderTopRightRadius={20}
                borderBottomRightRadius={20}
                justifyContent={"center"}
                alignItems={"center"}
                width={"33%"}
                backgroundColor={
                  button_type === "send" ? colors.primary : "#dbdad5"
                }
              >
                <Text color={button_type === "send" ? "#fff" : "#111"}>
                  {"Sent"}
                </Text>
              </Pressable>
            </HStack>
          </Box>
          <Hidden till="md">
            <Box height={"100vh"} width={"100%"}>
              {group_members?.map((user, index) => {
                return (
                  <Pressable
                    shadow={2}
                    key={index}
                    cursor={"pointer"}
                    h={100}
                    backgroundColor={"#fff"}
                    bgImage={
                      param_id === user?.[0]?.group_id
                        ? "linear-gradient(234deg,  #fef5fb ,#712e5a)"
                        : "linear-gradient(234deg,  #fff ,#fff)"
                    }
                    marginY={3}
                    borderRadius={10}
                    mx={3}
                    onPress={() => {
                      window.history.replaceState(
                        null,
                        null,
                        `/requests/${user?.[0]?.group_id}`
                      );
                      setRequestGroupId(user?.[0]?.group_id);
                      setHover(true);
                      setParamId(user?.[0]?.group_id);
                      getUserRequests(user?.[0]?.group_id);
                      // onResponsiveClose();
                    }}
                  >
                    <HStack alignItems={"center"}>
                      <Box
                        borderTopLeftRadius={10}
                        borderBottomLeftRadius={10}
                        height={100}
                        // backgroundColor={"#ff0000"}
                        width={"40%"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Image
                          source={getCroppedImageUrl(
                            user?.[0]?.member_cover_photo?.cropped_photo_url,
                            // user?.[0]?.member_profile?.gender
                            login_user_gender === "m" ? "f" : "m"
                          )}
                          width={"60px"}
                          height={"60px"}
                          borderRadius={"30px"}
                        />
                      </Box>
                      <Box justifyContent={"center"} flex={1} height={100}>
                        <Text textAlign={"left"} bold>
                          {user?.[0]?.member_profile?.name}
                        </Text>
                        <Text textAlign={"left"}>
                          {CalculateAgeForMember(
                            user?.[0]?.member_profile?.dob
                          )}
                        </Text>
                      </Box>
                    </HStack>
                    {param_id === user?.[0]?.group_id && (
                      <Box position={"absolute"} right={0}>
                        <AiFillCheckCircle color="#712e5a" size={20} />
                      </Box>
                    )}
                  </Pressable>
                );
              })}
              <Box w={"90%"} backgroundColor={"#712e5a"} h={100}></Box>
            </Box>
          </Hidden>
          <Hidden from="md">
            <Box height={"100vh"} width={"100%"}>
              {group_members?.map((user, index) => {
                return (
                  <Pressable
                    shadow={2}
                    key={index}
                    cursor={"pointer"}
                    h={100}
                    backgroundColor={"#fff"}
                    bgImage={
                      param_id === user?.[0]?.group_id
                        ? "linear-gradient(234deg,  #fef5fb ,#712e5a)"
                        : "linear-gradient(234deg,  #fff ,#fff)"
                    }
                    marginY={3}
                    borderRadius={10}
                    mx={3}
                    onPress={() => {
                      window.history.replaceState(
                        null,
                        null,
                        `/requests/${user?.[0]?.group_id}`
                      );
                      // setRequestGroupId(user?.[0]?.group_id);
                      setHover(true);
                      setParamId(user?.[0]?.group_id);
                      getUserRequests(user?.[0]?.group_id);
                      onResponsiveClose();
                    }}
                  >
                    <HStack alignItems={"center"}>
                      <Box
                        borderTopLeftRadius={10}
                        borderBottomLeftRadius={10}
                        height={100}
                        // backgroundColor={"#ff0000"}
                        width={"40%"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Image
                          source={getCroppedImageUrl(
                            user?.[0]?.member_cover_photo?.cropped_photo_url,
                            user?.[0]?.member_profile?.gender
                          )}
                          width={"60px"}
                          height={"60px"}
                          borderRadius={"30px"}
                        />
                      </Box>
                      <Box justifyContent={"center"} flex={1} height={100}>
                        <Text textAlign={"left"} bold>
                          {user?.[0]?.member_profile?.name}
                        </Text>
                        <Text textAlign={"left"}>
                          {CalculateAge(user?.[0]?.member_profile?.dob)}
                        </Text>
                      </Box>
                    </HStack>
                    {param_id === user?.[0]?.group_id && (
                      <Box position={"absolute"} right={0}>
                        <AiFillCheckCircle color="#712e5a" size={20} />
                      </Box>
                    )}
                  </Pressable>
                );
              })}
              <Box w={"90%"} backgroundColor={"#712e5a"} h={100}></Box>
            </Box>
          </Hidden>
        </>
      )}
    </>
  );
};
export default UserRequestMembers;

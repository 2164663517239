export function getFormattedText(text, should_ellipse = true, max_length = 10) {
  text = text.replace(/\s+/g, ' ').trim();
  if (!should_ellipse) {
    return text;
  }
  let splitted_text = text.split(' ');
  let temp_name = [];
  let total_length_used = 0;
  for (let i = 0; i < splitted_text?.length; i++) {
    let item = splitted_text[i];
    total_length_used = total_length_used + item.length;
    if (total_length_used > max_length) {
      break;
    }
    temp_name.push(item);
  }
  let formatted_text = temp_name.join(' ');

  if (formatted_text?.length > max_length) {
    formatted_text = formatted_text?.substring(0, max_length) + '..';
  }
  return formatted_text;
}

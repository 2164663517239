import { Box, Pressable } from "native-base";
import React, { useState } from "react";
import { FaFilter } from "react-icons/fa";
import MemberFilterDetails from "./member_filter_details";
import MemberFilterForm from "./member_filter_form";
import { useTranslation } from "react-i18next";
import { Button, Drawer } from "antd";

const ManageMemberFilter = () => {
  const [filter_open, setFilterOpen] = useState(false);
  const { t } = useTranslation();
  const onFilterClose = () => {
    console.log("clicked");
    setFilterOpen(false);
  };
  const showFilterDrawer = () => {
    console.log("setFilterOpen", filter_open);
    setFilterOpen(true);
  };

  return (
    <Box alignItems="flex-end">
      <Pressable
        onPress={() => {
          showFilterDrawer();
        }}
      >
        <Box>
          <FaFilter color="#712e5a" size={30} />
        </Box>
      </Pressable>

      <Drawer
        width={400}
        className="ant-drawer-title ant-drawer-close"
        headerStyle={{
          backgroundColor: "#712e5a",
          textDecorationColor: "#fff",
        }}
        bodyStyle={{ backgroundColor: "#fef5fb" }}
        placement="right"
        onClose={onFilterClose}
        open={filter_open}
        title="Filter"
      >
        <MemberFilterDetails onClose={onFilterClose} />
      </Drawer>
    </Box>
  );
};

export default ManageMemberFilter;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  VStack,
  Text,
  HStack,
  Pressable,
  Center,
  Switch,
} from "native-base";
import {
  getCountry,
  getState,
  getDistrict,
} from "@helpers/get_country_state_city";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  useDynamicSelector,
  get_verifications_query,
  dynamicClear,
  get_promo_users_query,
} from "@services/redux";
import {
  Button,
  Checkbox,
  Image,
  Pagination,
  Popconfirm,
  Space,
  Table,
} from "antd";
import { showNotification } from "@helpers/notify";
import { IMAGE_URL } from "@helpers/constants";
import { getImageUrl, getProfileUrl } from "@helpers/get_profile_url";
import { CalculateAge } from "@helpers/calculate_age";
import { useLocation, useParams } from "react-router-dom";
import { navigateBack } from "@helpers/navigator";

const AdminManagePromoCodeUser = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { promo_code_id } = useParams();
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const _ = require("lodash");

  const {
    items: reports,
    loading,
    pagination,
  } = useDynamicSelector("getPromoCodeMembers");

  useEffect(() => {
    let keys = [{ key: "getPromoCodeMembers", loading: true }];
    dispatch(
      dynamicRequest(keys, get_promo_users_query, {
        promo_code_id: promo_code_id,
        page_number: current,
        page_limit: current_limit,
      })
    );
    // setCurrent(value);
  }, []);
  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "getPromoCodeMembers", loading: true }];
    dispatch(
      dynamicRequest(keys, get_promo_users_query, {
        promo_code_id: promo_code_id,

        page_number: page_number,
        page_limit: page_limit,
      })
    );
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
    },
    {
      title: "Profile Number",
      dataIndex: "profile_id",
      align: "left",
      key: "name",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      align: "left",
      key: "gender",
      render: (record, i) => {
        return (
          <>
            <Text>{record === "m" ? "Male" : "Female"}</Text>
          </>
        );
      },
    },
    {
      title: "Age",
      dataIndex: "dob",
      align: "left",
      key: "dob",
      render: (record, i) => {
        return (
          <>
            <Text>{CalculateAge(record)}</Text>
          </>
        );
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      align: "left",
      key: "country",
      render: (record, i) => {
        return (
          <>
            <Text>{getCountry(record)}</Text>
          </>
        );
      },
    },
    {
      title: "State",
      dataIndex: "state",
      align: "left",
      key: "state",
      render: (record, i) => {
        let Country = i.country;
        return (
          <>
            <Text>{getState(record, Country)}</Text>
          </>
        );
      },
    },
    {
      title: "District",
      dataIndex: "",
      align: "left",
      key: "district",
      render: (record, i) => {
        return (
          <>
            <Text>{getDistrict(record?.district)}</Text>
          </>
        );
      },
    },
    {
      title: "Total Discounted Price",
      dataIndex: "subscriptions",
      key: "total_discounted_price",
      align: "right",

      render: (record) => {
        const total = _.sumBy(record, "discounted_price").toFixed(2);
        return <Text>{total > 0 ? total : "-"}</Text>;
      },
    },
  ];

  return (
    <Center>
      <Box width="90%" mt="20px" marginBottom="20px">
        <Box top="0px" zIndex="2" mb="5">
          <Text fontSize="md" fontWeight="bold">
            {`${location?.props_state} - ${t("promo_code_users")} (${
              pagination?.total_count
            })`}
          </Text>
        </Box>
        <Box my={5} width={"80px"}>
          <Button
            colorScheme={"danger.700"}
            onClick={() => {
              navigateBack();
            }}
            style={{
              // height: "50px",
              label: t("table:back"),
              variant: "outline",
            }}
          >
            Back
          </Button>
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={reports}
              pagination={false}
              loading={loading}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </VStack>
      </Box>
    </Center>
  );
};
export default AdminManagePromoCodeUser;

import { Box, Heading, HStack, Text, VStack } from "native-base";
import React, { Component } from "react";
import "../../index.css";

import Scrollbars from "react-custom-scrollbars-2";

const Terms = (props) => {
  return (
    <Scrollbars
      style={{
        backgroundColor: "#fef5fb",
      }}
    >
      <Box alignItems={"center"}>
        <Heading>Introduction :</Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          Welcome to Standard Matrimony! Our matrimony website and app, where we
          are dedicated to helping you find your life partner. Our platform
          offers a comprehensive and user-friendly interface, making the
          matchmaking process efficient and seamless. Whether you're seeking a
          partner within your community or outside of it, our website and app
          cater to a diverse range of preferences and requirements.
        </Text>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          Our advanced search filters allow you to narrow down your search based
          on specific criteria such as age, religion, caste, and more. We
          understand the significance of astrology in many cultures and provide
          options to select horoscope profiles and perform horoscope matching to
          ensure compatibility between partners.
        </Text>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          Our team of dedicated professionals is committed to providing you with
          a secure, private, and hassle-free experience throughout your
          matchmaking journey. We offer a range of membership plans to suit your
          budget and requirements, ensuring that our services are accessible to
          everyone.
        </Text>
      </Box>
      <Box alignItems={"center"}>
        <Heading>Terms and conditions </Heading>
      </Box>
      <Box>
        <Heading>
          The eligibility of a user to use a standard matrimonial Services and
          website and mobile application:{" "}
        </Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          The Service on our standard matrimony website and mobile application
          is intended for use by individuals who are 18 years of age or older.
          By using the Service on our standard matrimony website and mobile
          application, you represent and warrant that you are 18 years of age or
          older and that you have the right, authority, and capacity to enter
          into and abide by these Terms
        </Text>
      </Box>

      <Box>
        <Heading>Eligibility Criteria for Bridegroom:</Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          Age: The minimum age for a bride to get married is 18 years. The
          minimum age for a groom to get married is 21 years. Marriage Status:
          By registering on the Standard matrimony website/ mobile application,
          the bridegroom represents and warrants that he/she is not currently
          married and does not have a living spouse at the time of registration.
          If the bridegroom is found to have a living spouse, the standard
          matrimony website/mobile application reserves the right to immediately
          terminate the bridegroom's account and restrict access to the website.
          In such cases, the standard matrimony website/mobile application may
          also have the right to take legal action against the bridegroom and
          the person creating the profile on behalf of the bridegroom. Mental
          and Physical Health: The bridegroom should be of sound mind and not
          suffer from any mental illness or disorder. The bridegroom should also
          not have any physical disability that would prevent him from
          fulfilling the responsibilities of marriage.
        </Text>
      </Box>

      <Box>
        <Heading>Account Registration: </Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          On the standard matrimony website and mobile application, users are
          permitted to create a profile for their son, daughter, self, relative,
          or friend only. Creating a profile for anyone outside of these
          categories is strictly prohibited. Users must not create fake
          profiles, impersonate someone else, or use the platform for any
          illegal or fraudulent activities. When creating a profile on the
          standard matrimony website and mobile application, users must provide
          accurate and complete information, including name, age, gender,
          occupation, and educational qualifications. Users must also upload a
          recent photograph which is taken within 3 months and provide a brief
          description of themselves. Users of the standard matrimony website and
          mobile application are responsible for maintaining the confidentiality
          of their login credentials and for all activities that occur under
          their account. Users must not share their login credentials with
          anyone else or allow others to use their account. The platform
          reserves the right to verify the accuracy of the information provided
          by users on the standard matrimony website and mobile application and
          may request additional documentation to validate user identities. The
          platform also reserves the right to terminate any account found to
          contain false information. Users of the standard matrimony website and
          mobile application must use the platform for its intended purpose,
          which is to find a suitable partner for marriage. Any other use of the
          platform is prohibited. Violation of any of the terms and conditions
          outlined in the account registration process on the standard matrimony
          website and mobile application may result in the immediate termination
          of the user's account, and the platform may take legal action against
          the user if necessary.
        </Text>
      </Box>
      <Box>
        <Heading>Purpose of creating profile : </Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          The purpose of creating a profile on the standard matrimony website
          and mobile application is strictly for matchmaking and marriage
          purposes only. The website and mobile app are designed for the sole
          purpose of providing a platform for individuals to find a potential
          marriage partner. Using the website or mobile app for any other
          purposes, such as dating or commercial purposes, is strictly
          prohibited and may result in the termination of the account. In
          addition, you are expected to respect the privacy of other users of
          the standard matrimony website and mobile application and not to
          engage in any activities that would disturb their privacy. By creating
          a profile on the website, you agree to use it for its intended purpose
          of matchmaking and marriage only, and to comply with all terms and
          conditions of the standard matrimony website and mobile application,
          including the requirement to respect the privacy of other users.
        </Text>
      </Box>

      <Box>
        <Heading>User conduct:</Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          User conduct on the Standard Matrimony website and mobile application
          is subject to certain rules and regulations that must be followed by
          all users. These rules are in place to ensure the safety and
          well-being of all users and to maintain a respectful and positive
          environment on the platform. Users of the Standard Matrimony website
          and mobile application must not engage in any activities that are
          illegal, fraudulent, or otherwise prohibited by law. This includes,
          but is not limited to, misrepresentation of personal information,
          solicitation of money or personal information, or harassment of other
          users. Users must also not post any content on the platform that is
          offensive, obscene, or discriminatory in nature. This includes, but is
          not limited to, content that is racially or ethnically offensive,
          sexually explicit, or derogatory towards any particular group of
          people. Users of the Standard Matrimony website and mobile application
          must also respect the privacy of other users and must not share their
          personal information without their explicit consent. Users must also
          not use the platform to obtain personal information of other users for
          any purpose other than finding a suitable partner for marriage. Any
          violation of the user conduct rules on the Standard Matrimony website
          and mobile application may result in the immediate termination of the
          user's account and the platform may take legal action against the user
          if necessary.
        </Text>
      </Box>

      <Box>
        <Heading>
          Astrology and Horoscopes on Standard Matrimony website and mobile app
          :{" "}
        </Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          Astrology and Horoscopes on Standard Matrimony website and mobile app:
          Standard Matrimony website and mobile app uses computer-generated
          horoscopes. Therefore, we highly recommend that users recheck the
          compatibility with a reliable astrologer before making any important
          decisions. This will ensure that users are making well-informed
          decisions based on accurate information. It is important to note that
          our platform is only a tool to assist users in finding potential
          matches, and the final decision should be made after consulting with a
          qualified astrologer. The astrological compatibility feature on our
          platform is not intended to replace the advice of a professional
          astrologer, and we advise users to seek the guidance of a qualified
          astrologer before making any important decisions. We are committed to
          providing our users with the best possible experience and want to
          ensure that they are well-informed and make the right decisions.
          Therefore, we advise users to seek the guidance of a reliable
          astrologer before making any important decisions.
        </Text>
      </Box>
      <Box>
        <Heading>Termination of Account:</Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          The service provider, Standard Matrimony, reserves the right to
          terminate a user's account at any time and without prior notice if the
          user is found to be in violation of the terms and conditions set forth
          in this agreement. Violations include but are not limited to:
          providing false information, misusing the service for commercial or
          illegal purposes, harassing other members, or any other actions that
          may be deemed inappropriate by Standard Matrimony. Upon termination of
          an account, the user will no longer have access to the services
          provided by the Standard Matrimony mobile application/website. The
          user will also forfeit any premium features or services that they may
          have purchased. Standard Matrimony is not required to provide a refund
          for any fees or charges paid by the user in the event of termination.
          The user agrees to indemnify and hold harmless the service provider,
          Standard Matrimony, and its employees, agents, and partners from any
          claims, damages, or expenses arising from their use of the service or
          termination of their account. The user also acknowledges that Standard
          Matrimony reserves the right to take legal action against any user who
          violates the terms and conditions set forth in this agreement. By
          using the Standard Matrimony mobile application/website, you agree to
          abide by these terms and conditions, and to use the service in a
          responsible and lawful manner. Failure to do so may result in the
          termination of your Standard Matrimony account and forfeiture of any
          fees or charges paid."
        </Text>
      </Box>

      <Box>
        <Heading>Disclaimer ::</Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          Standard Matrimony provides a platform for individuals seeking
          matrimonial services. We strive to ensure the accuracy and reliability
          of the information provided by our users, however, we cannot guarantee
          the authenticity, accuracy, or completeness of any information
          provided on our website or through our app. We do not verify the
          information provided by our users and are not responsible for any
          inaccuracies or errors in the information provided. We strongly
          recommend that users exercise caution and conduct their own due
          diligence before entering into any matrimonial relationships or
          sharing personal information with others on our platform. Standard
          Matrimony does not guarantee the suitability, reliability, or
          compatibility of any of its users, and we shall not be held liable for
          any damages or losses resulting from the use of our platform. Users of
          Standard Matrimony assume full responsibility for any interactions,
          relationships, or agreements entered into with other users of the
          platform. Standard Matrimony reserves the right to modify or
          discontinue our platform at any time without notice. We shall not be
          held liable for any damages or losses resulting from the modification
          or discontinuation of our platform. This disclaimer of warranties and
          limitations of liability applies to any and all damages or injury,
          including those caused by any failure of performance, error, omission,
          interruption, deletion, defect, delay in operation or transmission,
          computer virus, communication line failure, theft or destruction or
          unauthorized access to, alteration of, or use of record, whether for
          breach of contract, tortuous behavior, negligence, or under any other
          cause of action. By using Standard Matrimony, users agree to indemnify
          and hold harmless Standard Matrimony and its affiliates, directors,
          officers, employees, and agents from any claims, damages, or
          liabilities arising from the use of our platform. Please read our
          Terms of Service and Privacy Policy carefully before using our
          platform. By using Standard Matrimony, users agree to comply with all
          the terms and conditions set forth in these agreements.
        </Text>
      </Box>
      <Box>
        <Heading>User Conduct and Dispute Resolution Policy :</Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          It is important to note that Standard Matrimony, as a service
          provider, may not be directly involved in resolving disputes between
          its members. In the event that a dispute does arise between members,
          Standard Matrimony may recommend that they try to resolve the issue
          amicably through communication and compromise. They may also provide
          resources for seeking legal or professional advice if necessary.
          Ultimately, it is the responsibility of the individual members to take
          appropriate action in the event of a dispute, and Standard Matrimony
          cannot be held liable for any resulting damages or losses.
        </Text>
      </Box>
      <Box>
        <Heading>Membership Plan:</Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          At Standard Matrimony, we offer two membership plans to cater to the
          different needs of our users.
        </Text>
        <Text>
          <b>Free Membership Plan:</b>
          <br />
          Our free membership plan is exclusively available on our mobile app
          for new users who register for the first time. This plan has a
          validity of one month or one week and allows users to view contact
          details of 5, 10, or 15 partner profiles, which are available during
          the availing of the free membership plan. To avail of this plan, users
          must complete their profile with all necessary details, including a
          recent photograph. Additionally, they must share the Standard
          Matrimony app promotional link with their WhatsApp group and provide
          their Aadhar number for authentication verification and it should be
          verified successfully . Please note that this offer may close at any
          time without prior notice.
        </Text>
        <Text>
          <b> 6 Months Membership Plan: </b>
          <br />
          Our 6-month membership plan is a paid option that offers users
          extended benefits. Subscribers to this plan can view contact details
          of 40, 45, or 50 partner profiles (subject to change). If users apply
          a promo code, they may receive discounts on the plan's price. However,
          if they do not apply a promo code, the standard price will apply,
          subject to change. Please be aware that there may be additional
          charges for payment gateway processing, in addition to the actual plan
          cost.
        </Text>
      </Box>
      <Box>
        <Heading>Refund Policy:</Heading>
      </Box>
      <Box mx={5} my={5}>
        <Text>
          At Standard Matrimony, we want our users to be completely satisfied
          with our services. We offer a 100% refund policy for our
          6-month/180-day membership plan, subject to the following conditions:
          <br />
          <Text>
            • The refund policy is applicable for the 6-month/180-day membership
            plan only.
          </Text>{" "}
          <br />
          <Text>
            • Users must complete their profile with all necessary details
            including photos at the beginning of the membership plan and
            maintain it up to the end of the membership plan.
          </Text>{" "}
          <br />
          <Text>
            • To apply for a refund, users must have completed a minimum of 170
            days of membership.
          </Text>{" "}
          <br />
          <Text>
            • Users must use the mobile app or website regularly on a daily
            basis without any breaks.
          </Text>{" "}
          <br />
          <Text>
            • Users must send a minimum of 90 requests (view photo request, view
            phone number request, or express interest) to partner profiles.
          </Text>{" "}
          <br />
          <Text>
            • If no user accepts any of the above requests or if users do not
            receive any requests, and they have followed all the above points,
            they are eligible for a 100% refund.
          </Text>{" "}
          <br />
          <Text>
            If users meet all the above points, they can apply for a refund
            through email at standardmatrimony@gmail.com. We will check their
            eligibility and refund the money within 15 working days.
          </Text>{" "}
          <br />
          <Text>
            Please note that if any user violates our terms of service or
            engages in fraudulent activity, they will not be eligible for a
            refund. Standard Matrimony reserves the right to refuse refunds at
            our discretion.
          </Text>
        </Text>
      </Box>
      <Box my={5}>
        <Text fontSize={20}>Contact Us:</Text>
      </Box>
      <Box mx={5}>
        <Text>
          If you have any questions about this terms and conditions , contact us
          :
        </Text>
      </Box>
      <Box mx={5}>
        <Text fontSize={20}>Admin office : </Text>
      </Box>
      <Box mx={5}>
        <Text bold fontSize={18}>
          Standard Matrimony ,{" "}
        </Text>
        <Text bold fontSize={18}>
          2nd floor , door No: 774A,{" "}
        </Text>
        <Text bold fontSize={18}>
          Sathy Road, Gandhipuram,{" "}
        </Text>
        <Text bold fontSize={18}>
          Coimbatore-641012.{" "}
        </Text>
        <Text bold fontSize={18}>
          Tamilnadu , India
        </Text>
        <Text bold fontSize={18}>
          contact person : G Mahendran ,{" "}
        </Text>
        <Text bold fontSize={18}>
          E-mail : standardmatrimony@gmail.com ,{" "}
        </Text>
        <Text bold fontSize={18}>
          Phone : +914224709829
        </Text>
        <Text bold fontSize={18}>
          This policy is effective as of 05/04/2023.
        </Text>
      </Box>
    </Scrollbars>
  );
};

export default Terms;

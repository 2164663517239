import React from "react";
import { Box, Center, Text, VStack, HStack } from "native-base";

import { CalculateAge, CalculateAgeForMember } from "@helpers/calculate_age";
import { useTranslation } from "react-i18next";
import {
  getAnnualIncome,
  getAnnualIncomeFromValue,
  getUserDetailsFromValue,
} from "@helpers/functions";
import { CasteOptions } from "@master_data/common_json/castes";
import { EducationsOptions } from "@master_data/common_json/educations";
import { AnnualIncomeOptions } from "@master_data/profile_json/annual_income";
import { EmploymentTypesOptions } from "@master_data/common_json/employment_types";
import { StarsOptions } from "@master_data/common_json/stars";
import { ComplexionOptions } from "@master_data/profile_json/complexion";
import { HeightFromOptions } from "@master_data/preference_json/height_from";
import { HeightOptions } from "@master_data/profile_json/height";
import { WeightOptions } from "@master_data/profile_json/weight";
import moment from "moment";
import { RaasiOptions } from "@master_data/profile_json/raasi";
import { DoshamsOptions } from "@master_data/common_json/doshams";
import { FathersOccupationOptions } from "@master_data/profile_json/fathers_occupations";
import { MothersOccupationOptions } from "@master_data/profile_json/mothers_occupation";

const ChartDetails = (props) => {
  const { item, login_user_horoscope, member_root } = props;
  console.log("item", item);
  const { t } = useTranslation();
  return (
    <>
      <Box mt={2}>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("name")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>{item?.name}</Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("profile_id")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>{member_root?.profile_number}</Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("age")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>{CalculateAgeForMember(item?.dob)}</Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("height")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.height
                  ? getUserDetailsFromValue(HeightOptions, item?.height)
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>

        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("weight")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.weight
                  ? getUserDetailsFromValue(WeightOptions, item?.weight)
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("Complexion")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.complexion
                  ? getUserDetailsFromValue(ComplexionOptions, item?.complexion)
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("education")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.education
                  ? getUserDetailsFromValue(EducationsOptions, item?.education)
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("work")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.employment_type
                  ? getUserDetailsFromValue(
                      EmploymentTypesOptions,
                      item?.employment_type
                    )
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("annual_income")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {getAnnualIncomeFromValue(
                  AnnualIncomeOptions,
                  Number(item?.annual_income_to)
                ) || "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("brothers")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.number_of_brothers !== null
                  ? item?.number_of_brothers === 0
                    ? "No brothers"
                    : `${item?.number_of_brothers} brother(s)`
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>

        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("brothers_married")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.number_of_married_brothers !== null
                  ? item?.number_of_married_brothers === 0
                    ? "No brother(s) married"
                    : `${item?.number_of_married_brothers} brother(s) married`
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("sisters")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.number_of_sisters !== null
                  ? item?.number_of_sisters === 0
                    ? "No sisters"
                    : `${item?.number_of_sisters} brother(s)`
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("sisters_married")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.number_of_married_sisters !== null
                  ? item?.number_of_married_sisters === 0
                    ? "No sister(s) married"
                    : `${item?.number_of_married_sisters} sister(s) married`
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("father_name")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.father_name ? item?.father_name : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("father_occupation")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.father_occupation
                  ? getUserDetailsFromValue(
                      FathersOccupationOptions,
                      item?.father_occupation
                    )
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("mother_name")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.mother_name ? item?.mother_name : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("mother_occupation")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.mother_occupation
                  ? getUserDetailsFromValue(
                      MothersOccupationOptions,
                      item?.mother_occupation
                    )
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>

        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("date_of_birth")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {moment(item?.dob).format("DD-MM-YYYY")}
              </Text>
            </Box>
          </HStack>
        </Box>

        {/* <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("caste")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.caste
                  ? getUserDetailsFromValue(CasteOptions, item?.caste)
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box> */}
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("birth_place")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {login_user_horoscope?.place_of_birth
                  ? login_user_horoscope?.place_of_birth
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("birth_time")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {login_user_horoscope?.time_of_birth
                  ? login_user_horoscope?.time_of_birth
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>

        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("star")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.star
                  ? getUserDetailsFromValue(StarsOptions, item?.star)
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("raasi")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.raasi
                  ? getUserDetailsFromValue(RaasiOptions, item?.raasi)
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("Dosham")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.doshams?.map((x, i) => {
                  return `${getUserDetailsFromValue(DoshamsOptions, x)}${
                    i === item?.doshams?.length - 1 ? "" : ", "
                  }`;
                })}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {"Asc"}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>{"லக்கினம்"}</Text>
            </Box>
          </HStack>
        </Box>

        {/* <Box my={0.5}>
          <HStack width={"100%"}>
            <Box width={"45%"}>
              <Text fontSize={18} bold>
                {t("mobile")}
              </Text>
            </Box>
            <Box width={"10%"}>-</Box>
            <Box width={"45%"}>
              <Text fontSize={18}>
                {item?.mobile !== "use_subscription" &&
                item?.mobile !== "need_new_subscription" &&
                item?.mobile !== "need_subscription" &&
                item?.mobile !== "hidden"
                  ? item?.mobile
                  : "-"}
              </Text>
            </Box>
          </HStack>
        </Box> */}
      </Box>
    </>
  );
};

export default ChartDetails;

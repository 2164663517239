export const FamilyStatusOptions = [
  {
    value: "middle_class",
    p_o: [],
    en_label: "Middle Class",
    ta_label: "நடுத்தரம்",
  },
  {
    value: "upper_middle_class",
    p_o: [],
    en_label: "Upper Middle Class",
    ta_label: "மேல் நடுத்தர வர்க்கம்",
  },
  {
    value: "rich",
    p_o: [],
    en_label: "Rich",
    ta_label: "பணக்காரர்",
  },
  {
    value: "affluent",
    p_o: [],
    en_label: "Affluent",
    ta_label: "வசதி படைத்தவர்",
  },
];

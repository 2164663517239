export const WeightOptions = [
  {
    value: 41,
    p_o: [],
    en_label: "41 Kg",
    ta_label: null,
  },
  {
    value: 42,
    p_o: [],
    en_label: "42 Kg",
    ta_label: null,
  },
  {
    value: 43,
    p_o: [],
    en_label: "43 Kg",
    ta_label: null,
  },
  {
    value: 44,
    p_o: [],
    en_label: "44 Kg",
    ta_label: null,
  },
  {
    value: 45,
    p_o: [],
    en_label: "45 Kg",
    ta_label: null,
  },
  {
    value: 46,
    p_o: [],
    en_label: "46 Kg",
    ta_label: null,
  },
  {
    value: 47,
    p_o: [],
    en_label: "47 Kg",
    ta_label: null,
  },
  {
    value: 48,
    p_o: [],
    en_label: "48 Kg",
    ta_label: null,
  },
  {
    value: 49,
    p_o: [],
    en_label: "49 Kg",
    ta_label: null,
  },
  {
    value: 50,
    p_o: [],
    en_label: "50 Kg",
    ta_label: null,
  },
  {
    value: 51,
    p_o: [],
    en_label: "51 Kg",
    ta_label: null,
  },
  {
    value: 52,
    p_o: [],
    en_label: "52 Kg",
    ta_label: null,
  },
  {
    value: 53,
    p_o: [],
    en_label: "53 Kg",
    ta_label: null,
  },
  {
    value: 54,
    p_o: [],
    en_label: "54 Kg",
    ta_label: null,
  },
  {
    value: 55,
    p_o: [],
    en_label: "55 Kg",
    ta_label: null,
  },
  {
    value: 56,
    p_o: [],
    en_label: "56 Kg",
    ta_label: null,
  },
  {
    value: 57,
    p_o: [],
    en_label: "57 Kg",
    ta_label: null,
  },
  {
    value: 58,
    p_o: [],
    en_label: "58 Kg",
    ta_label: null,
  },
  {
    value: 59,
    p_o: [],
    en_label: "59 Kg",
    ta_label: null,
  },
  {
    value: 60,
    p_o: [],
    en_label: "60 Kg",
    ta_label: null,
  },
  {
    value: 61,
    p_o: [],
    en_label: "61 Kg",
    ta_label: null,
  },
  {
    value: 62,
    p_o: [],
    en_label: "62 Kg",
    ta_label: null,
  },
  {
    value: 63,
    p_o: [],
    en_label: "63 Kg",
    ta_label: null,
  },
  {
    value: 64,
    p_o: [],
    en_label: "64 kg",
    ta_label: null,
  },
  {
    value: 65,
    p_o: [],
    en_label: "65 Kg",
    ta_label: null,
  },
  {
    value: 66,
    p_o: [],
    en_label: "66 Kg",
    ta_label: null,
  },
  {
    value: 67,
    p_o: [],
    en_label: "67 Kg",
    ta_label: null,
  },
  {
    value: 68,
    p_o: [],
    en_label: "68 Kg",
    ta_label: null,
  },
  {
    value: 69,
    p_o: [],
    en_label: "69 Kg",
    ta_label: null,
  },
  {
    value: 70,
    p_o: [],
    en_label: "70 Kg",
    ta_label: null,
  },
  {
    value: 71,
    p_o: [],
    en_label: "71 Kg",
    ta_label: null,
  },
  {
    value: 72,
    p_o: [],
    en_label: "72 Kg",
    ta_label: null,
  },
  {
    value: 73,
    p_o: [],
    en_label: "73 Kg",
    ta_label: null,
  },
  {
    value: 74,
    p_o: [],
    en_label: "74 Kg",
    ta_label: null,
  },
  {
    value: 75,
    p_o: [],
    en_label: "75 Kg",
    ta_label: null,
  },
  {
    value: 76,
    p_o: [],
    en_label: "76 Kg",
    ta_label: null,
  },
  {
    value: 77,
    p_o: [],
    en_label: "77 Kg",
    ta_label: null,
  },
  {
    value: 78,
    p_o: [],
    en_label: "78 Kg",
    ta_label: null,
  },
  {
    value: 79,
    p_o: [],
    en_label: "79 Kg",
    ta_label: null,
  },
  {
    value: 80,
    p_o: [],
    en_label: "80 Kg",
    ta_label: null,
  },
  {
    value: 81,
    p_o: [],
    en_label: "81 Kg",
    ta_label: null,
  },
  {
    value: 82,
    p_o: [],
    en_label: "82 Kg",
    ta_label: null,
  },
  {
    value: 83,
    p_o: [],
    en_label: "83 Kg",
    ta_label: null,
  },
  {
    value: 84,
    p_o: [],
    en_label: "84 Kg",
    ta_label: null,
  },
  {
    value: 85,
    p_o: [],
    en_label: "85 Kg",
    ta_label: null,
  },
  {
    value: 86,
    p_o: [],
    en_label: "86",
    ta_label: null,
  },
  {
    value: 87,
    p_o: [],
    en_label: "87",
    ta_label: null,
  },
  {
    value: 88,
    p_o: [],
    en_label: "88",
    ta_label: null,
  },
  {
    value: 89,
    p_o: [],
    en_label: "89",
    ta_label: null,
  },
  {
    value: 91,
    p_o: [],
    en_label: "91",
    ta_label: null,
  },
  {
    value: 90,
    p_o: [],
    en_label: "90",
    ta_label: null,
  },
  {
    value: 92,
    p_o: [],
    en_label: "92",
    ta_label: null,
  },
  {
    value: 93,
    p_o: [],
    en_label: "93",
    ta_label: null,
  },
  {
    value: 94,
    p_o: [],
    en_label: "94",
    ta_label: null,
  },
  {
    value: 95,
    p_o: [],
    en_label: "95",
    ta_label: null,
  },
  {
    value: 96,
    p_o: [],
    en_label: "96",
    ta_label: null,
  },
  {
    value: 97,
    p_o: [],
    en_label: "97",
    ta_label: null,
  },
  {
    value: 98,
    p_o: [],
    en_label: "98",
    ta_label: null,
  },
  {
    value: 99,
    p_o: [],
    en_label: "99",
    ta_label: null,
  },
  {
    value: 100,
    p_o: [],
    en_label: "100",
    ta_label: null,
  },
  {
    value: 101,
    p_o: [],
    en_label: "101",
    ta_label: null,
  },
  {
    value: 102,
    p_o: [],
    en_label: "102",
    ta_label: null,
  },
  {
    value: 103,
    p_o: [],
    en_label: "103",
    ta_label: null,
  },
  {
    value: 104,
    p_o: [],
    en_label: "104",
    ta_label: null,
  },
  {
    value: 105,
    p_o: [],
    en_label: "105",
    ta_label: null,
  },
  {
    value: 106,
    p_o: [],
    en_label: "106",
    ta_label: null,
  },
  {
    value: 107,
    p_o: [],
    en_label: "107",
    ta_label: null,
  },
  {
    value: 108,
    p_o: [],
    en_label: "108",
    ta_label: null,
  },
  {
    value: 109,
    p_o: [],
    en_label: "109",
    ta_label: null,
  },
  {
    value: 110,
    p_o: [],
    en_label: "110",
    ta_label: null,
  },
];

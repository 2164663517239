import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload, message } from "antd";
import { Box } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { QueryRequest } from "@services/apollo/api_service";
import { showNotification } from "@helpers/notify";
import axios from "axios";
import { fileQuery } from "@services/redux";
const AdImageForWeb = (props) => {
  const [fileList, setFileList] = useState([]);
  const [headers, setHeaders] = useState();
  const [get_file, set_file] = useState({});

  let fileUploadresponse;
  const handleBeforeUpload = async ({ name, type }) => {
    let fileupload = {
      type: "advertisement",
      file_name: name,
    };
    fileUploadresponse = await QueryRequest(fileQuery, fileupload);
    set_file(fileUploadresponse);
  };

  const handleFileChanged = (e) => {
    if (e.file.status === "removed") {
      setFileList([]);
      // showNotification({
      //   type: "removed",
      //   message: "Document Removed successfully",
      // });
    } else if (e.file.status === "uploading") {
      setFileList([e.file]);
      // showNotification({
      //   type: "done",
      //   message: "Document uploading successfully",
      // });
    } else if (e.file.status === "done") {
      const newFile = {
        ...e.file,
        url: get_file?.data?.getUploadUrl?.url,
      };
      setFileList([newFile]);
      // showNotification({
      //   type: "Success",
      //   message: "Document uploaded successfully",
      // });
    }
  };
  const handleUpload = async ({ onSuccess, onError, file }) => {
    axios
      .put(get_file?.data?.getUploadUrl?.url, file, {
        headers: { "Content-Type": file?.type },
      })
      .then(async (res) => {
        if (res.status.toString() === "200") {
          file.url = get_file?.data?.getUploadUrl?.url?.split("?")[0];
          onSuccess(null, file);
        } else {
          onError(err, err, file);
        }
      })
      .catch((err) => {
        onError(err, err, file);
      });
  };
  const action = get_file?.data?.getUploadUrl;

  return (
    <Box>
      <Form.Item
        label={"Icon"}
        name={props?.name}
        valuePropName="fileList"
        getValueFromEvent={(e) => e.fileList}
        extra=""
      >
        <Upload
          maxCount={1}
          action={action}
          headers={headers}
          fileList={fileList}
          listType="picture-card"
          customRequest={(e) => handleUpload(e)}
          beforeUpload={(args) => handleBeforeUpload(args)}
          onChange={(e) => handleFileChanged(e)}
        >
          Click to upload
        </Upload>
      </Form.Item>
    </Box>
  );
};

export default AdImageForWeb;

import { gql } from "@apollo/client";
export const incomplete_payment_profiles = gql`
  query getIncompletePaymentMembers(
    $franchise_id: String
    $page_number: Float
    $page_limit: Float
  ) {
    incomplete_payments_profiles: getIncompletePaymentMembers(
      franchise_id: $franchise_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        gender
        dob
        mobile
        profile_id
        country
        state
        district
        created_datetime
        franchise_name
        tried_date
        incomplete_payment {
          id
          status
          initiated_date_time
          subscription_plan {
            en_label
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const incomplete_payment_profiles_reports_query = gql`
  query getIncompletePaymentMembers(
    $franchise_id: String
    $page_number: Float
    $page_limit: Float
  ) {
    incomplete_payments_profiles_reports: getIncompletePaymentMembers(
      franchise_id: $franchise_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        gender
        dob
        mobile
        profile_id
        country
        state
        district
        created_datetime
        tried_date
        franchise_name
        incomplete_payment {
          id
          status
          initiated_date_time
          subscription_plan {
            en_label
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

import React from "react";
import { Box, Center, Text, VStack, HStack, Image } from "native-base";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";
import { useRef } from "react";
import { Button } from "antd";
import { CalculateAge } from "@helpers/calculate_age";
import { useTranslation } from "react-i18next";
import ChartDetails from "./chart_details";
import SvgImages from "./svg_images";

import { useReactToPrint } from "react-to-print";
import { getProfileUrl } from "@helpers/get_profile_url";

const HoroscopeChart = (props) => {
  const { item, login_user_horoscope, cover_photo, member_root } = props;

  console.log("item", item);
  console.log("login_user_horoscope", login_user_horoscope);
  let svg_convert_ref = useRef();
  const { t } = useTranslation();

  const handlePrint = useReactToPrint({
    content: () => svg_convert_ref.current,
  });
  return (
    <>
      <Box>
        {/* <Box
          display={"flex"}
          width={"100vh"}
          height={"150vh"}
          ref={svg_convert_ref}
        > */}
        <div ref={svg_convert_ref} style={{ width: "794px", height: "1123px" }}>
          <Box mx={10} flex={1}>
            <ChartDetails
              item={item}
              login_user_horoscope={login_user_horoscope}
              member_root={member_root}
            />
            {/* <Box position={"absolute"} right={0}>
              <Image
                source={{
                  uri: getProfileUrl(cover_photo?.photo_url, item?.gender),
                }}
                width={40}
                height={40}
                borderRadius={20}
              />
            </Box> */}
          </Box>
          <Box>
            <SvgImages login_user_horoscope={login_user_horoscope} />
          </Box>
        </div>
        {/* </Box> */}
      </Box>
      <Box alignItems={"center"} width={"100%"}>
        <Button
          onClick={() => {
            handlePrint();
            // exportComponentAsJPEG(svg_convert_ref);
          }}
        >
          Print
        </Button>
      </Box>
    </>
  );
};

export default HoroscopeChart;

import React from "react";
import { Box, Center, Text, VStack, HStack } from "native-base";

const MemberAvatar = (props) => {

  const { src, height, width } = props;

  const MemberImg = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          borderRadius: "50%",
          objectFit: "cover",
        }}
        width={height}
        height={width}
      />
    );
  });

  return (
    <Box>
      <MemberImg src={src}/>
    </Box>
  );
};

export default MemberAvatar;

import React from "react";
import { Box, Text, HStack } from "native-base";
import {
  getUserDetailsFromValue,
  getUserDetailsFromValueForTamil,
} from "@helpers/functions";
import { DoshamsOptions } from "@master_data/common_json/doshams";

const MemberDetailOperator = (props) => {
  const { type, text, icon, label, options } = props;

  return (
    <>
      {type === "multi" ? (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            {icon}
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {label}
            </Text>
          </Box>
          {text?.map((x, i) => {
            return (
              <>
                <Text py={1} fontSize={20} color={"black"}>
                  {`${getUserDetailsFromValue(options, x)}${
                    i === text?.length - 1 ? "" : ", "
                  }`}
                </Text>
              </>
            );
          })}

          <br />
        </HStack>
      ) : (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            {icon}
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {label}
            </Text>
          </Box>
          <Box width={"40%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {text}
            </Text>
          </Box>

          <br />
        </HStack>
      )}
    </>
  );
};
export default MemberDetailOperator;

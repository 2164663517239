import {
  Box,
  Card,
  Divider,
  Hidden,
  HStack,
  Skeleton,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input } from "antd";
import "../../../index.css";

import { useDispatch } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { EducationsOptions } from "@master_data/common_json/educations";
import { EmploymentTypesOptions } from "@master_data/common_json/employment_types";
import { AnnualIncomeOptions } from "@master_data/profile_json/annual_income";
import { useDynamicSelector } from "@services/redux";
import { SubOccupationsOptions } from "@master_data/common_json/sub_occupation";
import { SubEducationsOptions } from "@master_data/common_json/sub_education";

const UserProfileFormProfessionalDetails = (props) => {
  const { getOptions, form } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [annual_income_options, setAnnualIncomeOptions] = useState([]);
  const [sub_education_options, setSubEducationsOptions] = useState([]);
  const [sub_employment_type_options, setSubEmploymentTypeOptions] = useState(
    []
  );

  const { loading: my_profile_loading } = useDynamicSelector("my_profile");
  const employment_type_value = Form.useWatch("employment_type", form);
  const sub_employment_type_value = Form.useWatch("sub_employment_type", form);
  const education_type_value = Form.useWatch("educations", form);
  const sub_education_type_value = Form.useWatch("sub_educations", form);
  console.log("education_type_value", education_type_value);
  console.log("sub_education_type_value", sub_education_type_value);

  useEffect(() => {
    let grouped_sub_educations = [];

    if (education_type_value) {
      for (let i = 0; i < education_type_value.length; i++) {
        let selected_education = education_type_value[i];
        let _options = [];
        if (SubEducationsOptions) {
          _options = SubEducationsOptions?.filter(
            (x) => x.p_o.map((x) => x).indexOf(selected_education) > -1
          );
          _options = _options.map((obj) => {
            return { label: obj.en_label, value: obj.value };
          });
        }
        let education = EducationsOptions?.find(
          (x) => x.value === selected_education
        );
        grouped_sub_educations.push({
          label: education?.en_label,
          options: _options,
        });
      }
    }
    setSubEducationsOptions(grouped_sub_educations);
    let new_selected_sub_educations = [];
    if (sub_education_type_value) {
      for (let i = 0; i < sub_education_type_value.length; i++) {
        let selected_sub_education = sub_education_type_value[i];
        let _selected_sub_education = SubEducationsOptions?.find(
          (x) => x.value === selected_sub_education
        );
        if (
          _selected_sub_education &&
          education_type_value.indexOf(_selected_sub_education.p_o?.[i]) > -1
        ) {
          new_selected_sub_educations.push(_selected_sub_education.value);
        }
      }
    }
    form.setFields([
      {
        name: "sub_educations",
        value: new_selected_sub_educations,
      },
    ]);
  }, [education_type_value]);

  // useEffect(() => {
  //   let _options = [];
  //   if (SubEducationsOptions) {
  //     _options = SubEducationsOptions.filter((x) => x?.value !== "any");
  //   }
  //   if (education_type_value && SubEducationsOptions) {
  //     _options = _options?.filter(
  //       (x) => x.p_o?.map((x) => x).indexOf(education_type_value) > -1
  //     );
  //   }
  //   setSubEducationsOptions(_options);
  //   let index = _options.map((x) => x.value).indexOf(sub_education_type_value);
  //   if (index === -1) {
  //     form.setFields([
  //       {
  //         name: "sub_education",
  //         value: null,
  //       },
  //     ]);
  //   }
  // }, [education_type_value, SubEducationsOptions]);

  useEffect(() => {
    let _options = [];
    if (SubOccupationsOptions) {
      _options = SubOccupationsOptions.filter((x) => x?.value !== "any");
    }
    if (employment_type_value && SubOccupationsOptions) {
      _options = _options?.filter(
        (x) => x.p_o?.map((x) => x).indexOf(employment_type_value) > -1
      );
    }
    setSubEmploymentTypeOptions(_options);
    let index = _options.map((x) => x.value).indexOf(sub_employment_type_value);
    if (index === -1) {
      form.setFields([
        {
          name: "sub_employment_type",
          value: null,
        },
      ]);
    }
  }, [employment_type_value, SubOccupationsOptions]);

  useEffect(() => {
    if (employment_type_value === "not_working") {
      let annual_income_options = AnnualIncomeOptions?.filter((x) => {
        return x.r_f_v === -99;
      });
      setAnnualIncomeOptions(annual_income_options);
    } else {
      let annual_income_options_not_null = AnnualIncomeOptions?.filter((x) => {
        return x.r_f_v !== -99;
      });

      setAnnualIncomeOptions(annual_income_options_not_null);
    }
  }, [employment_type_value]);

  const handleEducationChange = (values) => {
    console.log("handleEducationChange", values);
    let filtered_ids = values;

    form.setFieldsValue({
      educations: filtered_ids,
    });
  };
  const handleSubEducationChange = (values) => {
    let filtered_ids = values;

    form.setFieldsValue({
      sub_educations: filtered_ids,
    });
  };

  return (
    <>
      {my_profile_loading ? (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("professional_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <Hidden from="md">
            <Box width={"100%"}>
              <Skeleton.Text lines={5} />
            </Box>
          </Hidden>
          <Hidden till="md">
            <HStack space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
          </Hidden>
        </Card>
      ) : (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={14} bold color={"#712e5a"}>
              {t("professional_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <VForm.Select
            label={t("education")}
            field={"educations"}
            options={getOptions(EducationsOptions, "Education")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            isMulti
            onChange={handleEducationChange}
            rules={[
              {
                required: true,
                message: "Education is required",
              },
            ]}
          />
          <VForm.FormGroupSelect
            label={t("sub_education")}
            field={"sub_educations"}
            options={sub_education_options}
            // options={getOptions(SubEducationsOptions, "SubEducationsOptions")}
            labelField={"label"}
            valueField={"value"}
            showSearch={true}
            form_type={"profile"}
            isMulti
            onChange={handleSubEducationChange}
          />
          <VForm.TextBox
            label={t("institution")}
            field={"institution"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Institution is required",
              },
            ]}
          />
          <VForm.Select
            label={t("employment_type")}
            field={"employment_type"}
            options={getOptions(EmploymentTypesOptions, "Employment Type")}
            labelField={"en_label"}
            valueField={"value"}
            showSearch={true}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Employment Type is required",
              },
            ]}
          />
          {(employment_type_value !== "not_working" ||
            employment_type_value === "farmer") && (
            <VForm.Select
              label={t("occupation")}
              field={"sub_employment_type"}
              options={sub_employment_type_options}
              // options={getOptions(SubOccupationsOptions, "Sub Employment Type")}
              labelField={"en_label"}
              valueField={"value"}
              form_type={"profile"}
              showSearch={true}
            />
          )}
          <VForm.TextBox
            label={t("employer")}
            field={"employer"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Employer is required",
              },
            ]}
          />

          <VForm.Select
            label={t("annual_income")}
            field={"annual_income"}
            // options={getOptions(AnnualIncomeOptions, "Annual Income")}
            options={annual_income_options}
            labelField={"en_label"}
            valueField={"en_label"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Annual Income is required",
              },
            ]}
          />
        </Card>
      )}
    </>
  );
};
export default UserProfileFormProfessionalDetails;

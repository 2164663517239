import React from "react";
import {
  Box,
  Text,
  HStack,
  Divider,
  Skeleton,
  Image as _Image,
} from "native-base";
import { AiFillStar } from "react-icons/ai";
import {
  FaHeart,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaPrayingHands,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  BsCardList,
  BsFillFlagFill,
  BsJournalBookmarkFill,
  BsPersonFill,
} from "react-icons/bs";
import { GiBigDiamondRing, GiBodyHeight } from "react-icons/gi";
import { ImLocation, ImSpoonKnife } from "react-icons/im";
import {
  MdFamilyRestroom,
  MdLanguage,
  MdOutlineWheelchairPickup,
  MdSmokingRooms,
  MdWork,
} from "react-icons/md";
import { BiDrink } from "react-icons/bi";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { HiCurrencyRupee, HiUserGroup } from "react-icons/hi";
import {
  FcBusinessman,
  FcBusinesswoman,
  FcDiploma2,
  FcManager,
} from "react-icons/fc";
import { IoMdSchool } from "react-icons/io";
import BasicDetails from "./user_details/user_basic_details";
import ReligionDetails from "./user_details/user_religion_details";
import ProfessionalDetails from "./user_details/user_professional_details";
import LocationDetails from "./user_details/user_location_details";
import FamilyDetails from "./user_details/user_family_details";
import { Button, Popconfirm } from "antd";
import { navigateBack } from "@helpers/navigator";
import { useDynamicSelector } from "@services/redux";
import { useParams } from "react-router-dom";

const MemberDetailsCardViewAdmin = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const member_for_admin = useDynamicSelector("member_for_admin");
  const { loading: member_loading } = useDynamicSelector("member_for_admin");

  return (
    <>
      {member_loading ? (
        <Box mx={{ base: 5, sm: 2, xs: 2 }}>
          <Box>
            <HStack alignItems={"center"}>
              {/* <Skeleton marginY={5} width={"32"} height={"32"} rounded="full" /> */}
              <Skeleton.Text
                alignItems="center"
                lines={2}
                width={"30%"}
                px={5}
              />
            </HStack>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 5,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              // marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <BsCardList size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("basic_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsPersonFill size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsPersonFill size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <GiBodyHeight size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaHeart size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <GiBigDiamondRing size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaHeart size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdOutlineWheelchairPickup size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdLanguage size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <ImSpoonKnife size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BiDrink size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdSmokingRooms size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaPhoneAlt size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 5,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              // marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <FaPrayingHands size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("religion_information")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsJournalBookmarkFill size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <AiFillStar size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <VscTypeHierarchySub size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaHeart size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcDiploma2 size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 5,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              // marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <FaMapMarkedAlt size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("location_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <ImLocation size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <ImLocation size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsFillFlagFill size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 5,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              // marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <MdWork size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("professional_information")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <IoMdSchool size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdWork size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <HiCurrencyRupee size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 5,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              // marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <MdFamilyRestroom size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("family_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <HiUserGroup size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinessman size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinesswoman size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinesswoman size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcManager size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinesswoman size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcManager size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box my={5} alignItems={"flex-start"}>
            <Button
              loading={false}
              type="default"
              onClick={() => {
                navigateBack();
              }}
            >
              {t("back")}
            </Button>
          </Box>
          {member_for_admin ? (
            <Box mb={5} mx={{ base: 5, sm: 2, xs: 2 }}>
              <HStack alignItems={"center"}>
                <Box marginLeft={2}>
                  <Text fontSize={25} textAlign={"left"} bold color={"#712e5a"}>
                    {member_for_admin?.member_profile?.name}
                  </Text>
                  <Text
                    textAlign={"left"}
                  >{`( ${member_for_admin?.member_root?.profile_number} )`}</Text>
                  <Text textAlign={"left"} fontSize={18}>
                    {member_for_admin?.member_profile?.gender === "m"
                      ? "Male"
                      : "Female"}
                  </Text>
                </Box>
              </HStack>

              <Box mt={3}>
                <BasicDetails member={member_for_admin} />
              </Box>
              <Box mt={3}>
                <ReligionDetails member={member_for_admin} />
              </Box>
              <Box mt={3}>
                <LocationDetails member={member_for_admin} />
              </Box>
              <Box mt={3}>
                <ProfessionalDetails member={member_for_admin} />{" "}
              </Box>
              <Box mt={3}>
                <FamilyDetails member={member_for_admin} />
              </Box>
            </Box>
          ) : (
            <Box alignItems={"center"}>
              <Text>{"No Data"}</Text>
            </Box>
          )}
        </>
      )}
    </>
  );
};
export default MemberDetailsCardViewAdmin;

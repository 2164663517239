import {
  Box,
  Hidden,
  Text,
  Image,
  VStack,
  HStack,
  Pressable,
  Divider,
} from "native-base";
import React, { useEffect, useState, useRef } from "react";
import { Animated, Dimensions } from "react-native";
import * as IMAGE_CONSTANTS from "@helpers/image_constants";
import { ROUTES } from "@views/routes/my_routes";
import Scrollbars from "react-custom-scrollbars-2";
import {
  dynamicClear,
  dynamicRequest,
  getMatches,
  getMember,
  getMyProfile,
  getUserOTP,
  getUserTokenByAdmin,
  get_user_subscription_plan,
  useDynamicSelector,
} from "@services/redux";
import { retrieveItem, storeItem } from "@helpers/storage";
import moment from "moment";
import { FaCrown, FaUserEdit } from "react-icons/fa";
import { navigate } from "@helpers/navigator";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MembersHorizontalList from "@views/components/member/members_horizontal_list";
import { Button, Input, Modal } from "antd";
import toast from "react-hot-toast";
import OperatorOtpModal from "../operator_otp_modal";
import { FiLogIn } from "react-icons/fi";

const OperatorHomeCover = (props) => {
  const { handleRegisterPageVisible, handleLoginPageVisible } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Search } = Input;

  const [profileID, setProfileID] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState();

  const {
    id,
    error,
    name: current_user,
    token,
    session_id,
  } = useDynamicSelector("get_user_token_by_admin");
  const { user_profile } = useDynamicSelector("my_profile");
  // const { status: otp_status } = useDynamicSelector(
  //   "initializeUserSessionByAdmin"
  // );

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const UserImage = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          objectFit: "cover",
        }}
        width={280}
        height={280}
      />
    );
  });

  let member_ref = useRef();

  let navigate_timeout = null;

  // useEffect(() => {
  //   if (otp_status === "success") {
  //     setIsModalOpen(true);
  //     dispatch(dynamicClear("initializeUserSessionByAdmin"));
  //   }
  // }, [otp_status]);

  useEffect(() => {
    member_ref.current = id;
    clearTimeout(navigate_timeout);
    navigate_timeout = setTimeout(() => {
      if (member_ref.current) {
        setSearchLoading(false);
      }
    }, 1000);
  }, [id]);

  useEffect(() => {
    let get_my_profile = [{ key: "my_profile", loading: true }];
    let get_my_profile_query = getMyProfile;
    let get_my_profile_variables = {};

    if (session_id) {
      dispatch(
        dynamicRequest(
          get_my_profile,
          get_my_profile_query,
          get_my_profile_variables
        )
      );
    }
  }, [session_id]);

  // const UserOTP = () => {
  //   let key = [{ key: "initializeUserSessionByAdmin", loading: true }];
  //   let query = getUserOTP;
  //   let variables = { username: userName };
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    } else if (session_id) {
      storeItem("session_id", session_id);
      storeItem("current_user", current_user);
    }
  }, [error, current_user, session_id]);

  return (
    <Box width={"100%"} height={"100%"} backgroundColor={"#712e5a"}>
      <Scrollbars>
        <Box my={5} backgroundColor={"#712e5a"}>
          <VStack>
            <Pressable
              onPress={() => {
                handleLoginPageVisible(true);
                handleRegisterPageVisible(false);
              }}
            >
              <HStack my={2} mx={5} alignItems={"center"}>
                <FiLogIn size={25} color={"#fff"} />
                <Text mx={2} textAlign={"center"} fontSize={20} color={"#fff"}>
                  {"Login"}
                </Text>
              </HStack>
            </Pressable>
            <Divider mx={2} width={"90%"} />
            <Pressable
              onPress={() => {
                handleRegisterPageVisible(true);
                handleLoginPageVisible(false);
              }}
            >
              <HStack my={2} mx={5} alignItems={"center"}>
                <FaUserEdit size={25} color={"#fff"} />
                <Text mx={2} textAlign={"center"} fontSize={20} color={"#fff"}>
                  {"Register"}
                </Text>
              </HStack>
            </Pressable>
          </VStack>
        </Box>
      </Scrollbars>
    </Box>
  );
};
export default OperatorHomeCover;

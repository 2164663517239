import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, HStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
  get_all_verifier_query,
  delete_verifier_mutation,
} from "@services/redux";
import AntdModal from "../ui/antd_modal";
import {
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Tooltip,
  Space,
  Button,
} from "antd";
import { showNotification } from "@helpers/notify";
import ActionButton from "../ui/dialog/action_button";
import VerifierDetails from "./verifier_details";
import { navigateBack } from "@helpers/navigator";

const VerifierList = () => {
  const dispatch = useDispatch();

  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);

  const { status: createStatus, error: createError } =
    useDynamicSelector("create_verifier");
  const { status: updateStatus, error: updateError } =
    useDynamicSelector("update_verifier");
  const { status: deleteStatus, error: deleteError } =
    useDynamicSelector("delete_verifier");
  const {
    items: verifier_items,
    loading,
    pagination: verifiers_pagination,
  } = useDynamicSelector("get_all_verifiers");

  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const getAllVerifierList = () => {
    let keys = [{ key: "get_all_verifiers", loading: true }];
    let variables = {
      json: {
        page_number: current,
        page_limit: 10,
      },
    };
    dispatch(dynamicRequest(keys, get_all_verifier_query, variables));
  };

  useEffect(() => {
    getAllVerifierList();
  }, []);

  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `Verifier added successfully`,
      });
      getAllVerifierList();

      dispatch(dynamicClear("create_verifier"));
    } else if (createError) {
      showNotification({
        type: "error",
        message: createError?.message,
      });
      dispatch(dynamicClear("create_verifier"));
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
        message: `Verifier updated successfully`,
      });
      dispatch(dynamicClear("update_verifier"));

      getAllVerifierList();
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `Verifier deleted successfully`,
      });
      dispatch(dynamicClear("delete_verifier"));

      getAllVerifierList();
    } else if (updateError) {
      showNotification({
        type: "error",
        message: updateError?.message,
      });
      dispatch(dynamicClear("create_verifier"));
    }
  }, [createStatus, updateStatus, deleteStatus, updateError]);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_verifiers", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_verifier_query, {
        json: {
          page_number: page_number,
          page_limit: page_limit,
        },
      })
    );
    setCurrent(page_number);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_verifier", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_verifier_mutation, {
        json: { id: record?.id },
      })
    );
  };

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      align: "left",
      key: "name",
    },

    {
      title: t("mobile"),
      dataIndex: "mobile",
      align: "left",
      key: "mobile",
    },

    {
      title: t("table:username"),
      dataIndex: "username",
      key: "username",
      align: "left",
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <Space>
            <Typography.Link onClick={() => handleEdit(record)}>
              <Space>
                <Tooltip title="Edit" placement="bottom">
                  <EditOutlined />
                </Tooltip>
              </Space>
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <Space>
                <Tooltip title="Delete" placement="bottom">
                  <DeleteOutlined />
                </Tooltip>
              </Space>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_verifier"),
      colorScheme: "danger.700",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box width="100%" mt="20px" marginBottom="20px" height={"100%"}>
        <Box top="0px" zIndex="2">
          <Text fontSize="md" fontWeight="bold">
            {t("verifier_list")}
          </Text>
        </Box>
        <Box my={5} width={"80px"}>
          <Button
            colorScheme={"danger.700"}
            onClick={() => {
              navigateBack();
            }}
            style={{
              height: "40px",
              label: t("table:back"),
              variant: "outline",
            }}
          >
            Back
          </Button>
        </Box>
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            {header_actions.map((headerAction, index) => (
              <Box key={`header-action-${index}`}>
                <ActionButton {...headerAction} />
              </Box>
            ))}
          </HStack>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={verifier_items}
              pagination={false}
              loading={loading}
            />
          </Wrapper>
          <Pagination
            total={verifiers_pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
            pageSizeOptions={[5, 10, 15, 20, 50]}
          />
        </VStack>
      </Box>

      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        header={actionType === "add" ? t("add_verifier") : t("update_verifier")}
        component={
          <VerifierDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
            type={actionType === "add" ? "add" : "update"}
          />
        }
      />
    </>
  );
};
export default VerifierList;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
  get_all_operators_query,
} from "@services/redux";
// import Modal from "@views/components/ui/modal";
import AntdModal from "../ui/antd_modal";
import {
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Tooltip,
  Space,
  Button,
} from "antd";
import { showNotification } from "@helpers/notify";
import lodash from "lodash";
import ActionButton from "../ui/dialog/action_button";
import { TbCurrentLocation } from "react-icons/tb";
import OperatorDetails from "./operator_details";
import { navigateBack } from "@helpers/navigator";
import { useLocation, useParams } from "react-router-dom";

const OperatorList = () => {
  const dispatch = useDispatch();
  const { franchise_id } = useParams();
  const location = useLocation();

  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);

  const { status: createStatus, error: createError } =
    useDynamicSelector("registerOperator");
  const { status: updateStatus, error: updateError } =
    useDynamicSelector("updateOperator");
  const { items: get_operators, loading } = useDynamicSelector("getOperators");

  const handleAdd = () => {
    setActionItem({});

    setActionType("add");
    setModalOpen(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const getAllOperatorsList = () => {
    let keys = [{ key: "getOperators", loading: true }];
    let variables = { franchise_id: franchise_id };
    dispatch(dynamicRequest(keys, get_all_operators_query, variables));
  };

  useEffect(() => {
    getAllOperatorsList();
  }, []);

  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `Operator created successfully`,
      });
      getAllOperatorsList();

      dispatch(dynamicClear("registerOperator"));
    } else if (createError) {
      showNotification({
        type: "error",
        message: createError?.message,
      });
      dispatch(dynamicClear("registerOperator"));
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
        message: `Operator updated successfully`,
      });
      dispatch(dynamicClear("updateOperator"));

      getAllOperatorsList();
    }
  }, [createStatus, updateStatus]);

  const fetchData = (value) => {
    let keys = [{ key: "getOperators", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_operators_query, {
        page_number: value,
        page_limit: 1,
      })
    );
    setCurrent(value);
  };
  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      align: "left",
      key: "name",
    },

    {
      title: t("mobile"),
      dataIndex: "mobile",
      align: "left",
      key: "mobile",
    },

    // {
    //   title: t("table:password"),
    //   dataIndex: "password",
    //   key: "password",
    //   align: "left",
    // },

    // {
    //   title: t("table:franchise"),
    //   dataIndex: "franchise",
    //   key: "franchise",
    //   align: "left",
    //   render: (record) => {
    //     return <Text>{record?.name}</Text>;
    //   },
    // },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span>
            <Typography.Link onClick={() => handleEdit(record)}>
              <Space>
                <Tooltip title="Edit">
                  <EditOutlined />
                </Tooltip>
              </Space>
            </Typography.Link>
            {/* <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <Space>
                <Tooltip title="Delete">
                  <DeleteOutlined />
                </Tooltip>
              </Space>
            </Popconfirm> */}
          </span>
        );
      },
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_operator"),
      colorScheme: "danger.700",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box width="100%" mt="20px" marginBottom="20px">
        <Box top="0px" zIndex="2">
          <Text fontSize="md" fontWeight="bold">
            {`${location.props_state} - ${t("operators_list")}`}
          </Text>
        </Box>
        <Box my={5} width={"80px"}>
          <Button
            colorScheme={"danger.700"}
            onClick={() => {
              navigateBack();
            }}
            style={{
              label: t("table:back"),
              variant: "outline",
            }}
          >
            Back
          </Button>
        </Box>
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            {header_actions.map((headerAction, index) => (
              <Box key={`header-action-${index}`}>
                <ActionButton {...headerAction} />
              </Box>
            ))}
          </HStack>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={get_operators}
              pagination={false}
              loading={loading}
            />
          </Wrapper>
          <Pagination
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </VStack>
      </Box>

      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        destroyOnClose={true}
        header={actionType === "add" ? t("add_operator") : t("update_operator")}
        component={
          <OperatorDetails
            franchise_id={franchise_id}
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
            type={actionType === "add" ? "add" : "update"}
          />
        }
      />
    </>
  );
};
export default OperatorList;

import { gql } from "@apollo/client";

export const create_admin_franchise_mutation = gql`
  mutation create_franchise($data: franchise_input) {
    create_franchise: createFranchise(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const update_admin_franchise_mutation = gql`
  mutation updateFranchise($id: String!, $data: franchise_update_input) {
    update_franchise: updateFranchise(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_franchise_plan_mutation = gql`
  mutation deleteFranchise($id: String!) {
    delete_franchise: deleteFranchise(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const get_franchise_plans_query = gql`
  query getFranchise($id: String!) {
    get_franchise: getFranchise(id: $id) {
      id
      name
      code
      country
      state
      district
      promo_codes {
        id
        name
        code
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const get_all_franchise_plans_query = gql`
  query getAllFranchises($page_number: Float, $page_limit: Float) {
    get_all_franchises: getAllFranchises(
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        code
        pincodes
        country
        state
        district
        mobile
        admin_user {
          username
        }
      }
    }
  }
`;

export const get_all_franchise_members_query = gql`
  query getFranchiseMembers(
    $franchise_id: String
    $gender: String
    $caste: String
    $sub_caste: String
    $religion: String
    $marital_status: String
    $page_number: Float
    $page_limit: Float
    $search_string: String
  ) {
    getFranchiseMembers(
      franchise_id: $franchise_id
      gender: $gender
      caste: $caste
      sub_caste: $sub_caste
      religion: $religion
      marital_status: $marital_status
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        dob
        name
        gender
        country
        state
        city
        district
        created_datetime
        profile_id
        pincode
        subscriptions {
          subscription_plan {
            en_label
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const get_all_franchise_members_for_filter = gql`
  query getAllFranchises {
    filter_franchises: getAllFranchises {
      items {
        id
        name
      }
    }
  }
`;
export const get_franchise_member_pincodes = gql`
  query getFranchise($id: String) {
    filter_franchise_pincodes: getFranchise(id: $id) {
      pincodes
    }
  }
`;

export const StatesOptions = [
  {
    i_c: "02",
    name: "Canillo",
    c_c: "AD",
  },
  {
    i_c: "03",
    name: "Encamp",
    c_c: "AD",
  },
  {
    i_c: "04",
    name: "La Massana",
    c_c: "AD",
  },
  {
    i_c: "05",
    name: "Ordino",
    c_c: "AD",
  },
  {
    i_c: "06",
    name: "Sant Julià de Lòria",
    c_c: "AD",
  },
  {
    i_c: "07",
    name: "Andorra la Vella",
    c_c: "AD",
  },
  {
    i_c: "08",
    name: "Escaldes-Engordany",
    c_c: "AD",
  },
  {
    i_c: "AJ",
    name: "Ajman Emirate",
    c_c: "AE",
  },
  {
    i_c: "AZ",
    name: "Abu Dhabi Emirate",
    c_c: "AE",
  },
  {
    i_c: "DU",
    name: "Dubai",
    c_c: "AE",
  },
  {
    i_c: "FU",
    name: "Fujairah",
    c_c: "AE",
  },
  {
    i_c: "RK",
    name: "Ras al-Khaimah",
    c_c: "AE",
  },
  {
    i_c: "SH",
    name: "Sharjah Emirate",
    c_c: "AE",
  },
  {
    i_c: "UQ",
    name: "Umm al-Quwain",
    c_c: "AE",
  },
  {
    i_c: "BAL",
    name: "Balkh",
    c_c: "AF",
  },
  {
    i_c: "BAM",
    name: "Bamyan",
    c_c: "AF",
  },
  {
    i_c: "BDG",
    name: "Badghis",
    c_c: "AF",
  },
  {
    i_c: "BDS",
    name: "Badakhshan",
    c_c: "AF",
  },
  {
    i_c: "BGL",
    name: "Baghlan",
    c_c: "AF",
  },
  {
    i_c: "DAY",
    name: "Daykundi",
    c_c: "AF",
  },
  {
    i_c: "FRA",
    name: "Farah",
    c_c: "AF",
  },
  {
    i_c: "FYB",
    name: "Faryab",
    c_c: "AF",
  },
  {
    i_c: "GHA",
    name: "Ghazni",
    c_c: "AF",
  },
  {
    i_c: "GHO",
    name: "Ghōr",
    c_c: "AF",
  },
  {
    i_c: "HEL",
    name: "Helmand",
    c_c: "AF",
  },
  {
    i_c: "HER",
    name: "Herat",
    c_c: "AF",
  },
  {
    i_c: "JOW",
    name: "Jowzjan",
    c_c: "AF",
  },
  {
    i_c: "KAB",
    name: "Kabul",
    c_c: "AF",
  },
  {
    i_c: "KAN",
    name: "Kandahar",
    c_c: "AF",
  },
  {
    i_c: "KAP",
    name: "Kapisa",
    c_c: "AF",
  },
  {
    i_c: "KDZ",
    name: "Kunduz Province",
    c_c: "AF",
  },
  {
    i_c: "KHO",
    name: "Khost",
    c_c: "AF",
  },
  {
    i_c: "KNR",
    name: "Kunar",
    c_c: "AF",
  },
  {
    i_c: "LAG",
    name: "Laghman",
    c_c: "AF",
  },
  {
    i_c: "LOG",
    name: "Logar",
    c_c: "AF",
  },
  {
    i_c: "NAN",
    name: "Nangarhar",
    c_c: "AF",
  },
  {
    i_c: "NIM",
    name: "Nimruz",
    c_c: "AF",
  },
  {
    i_c: "NUR",
    name: "Nuristan",
    c_c: "AF",
  },
  {
    i_c: "PAN",
    name: "Panjshir",
    c_c: "AF",
  },
  {
    i_c: "PAR",
    name: "Parwan",
    c_c: "AF",
  },
  {
    i_c: "PIA",
    name: "Paktia",
    c_c: "AF",
  },
  {
    i_c: "PKA",
    name: "Paktika",
    c_c: "AF",
  },
  {
    i_c: "SAM",
    name: "Samangan",
    c_c: "AF",
  },
  {
    i_c: "SAR",
    name: "Sar-e Pol",
    c_c: "AF",
  },
  {
    i_c: "TAK",
    name: "Takhar",
    c_c: "AF",
  },
  {
    i_c: "URU",
    name: "Urozgan",
    c_c: "AF",
  },
  {
    i_c: "ZAB",
    name: "Zabul",
    c_c: "AF",
  },
  {
    i_c: "03",
    name: "Saint George Parish",
    c_c: "AG",
  },
  {
    i_c: "04",
    name: "Saint John Parish",
    c_c: "AG",
  },
  {
    i_c: "05",
    name: "Saint Mary Parish",
    c_c: "AG",
  },
  {
    i_c: "06",
    name: "Saint Paul Parish",
    c_c: "AG",
  },
  {
    i_c: "07",
    name: "Saint Peter Parish",
    c_c: "AG",
  },
  {
    i_c: "08",
    name: "Saint Philip Parish",
    c_c: "AG",
  },
  {
    i_c: "10",
    name: "Barbuda",
    c_c: "AG",
  },
  {
    i_c: "11",
    name: "Redonda",
    c_c: "AG",
  },
  {
    i_c: "01",
    name: "Berat County",
    c_c: "AL",
  },
  {
    i_c: "02",
    name: "Durrës County",
    c_c: "AL",
  },
  {
    i_c: "03",
    name: "Elbasan County",
    c_c: "AL",
  },
  {
    i_c: "04",
    name: "Fier County",
    c_c: "AL",
  },
  {
    i_c: "05",
    name: "Gjirokastër County",
    c_c: "AL",
  },
  {
    i_c: "06",
    name: "Korçë County",
    c_c: "AL",
  },
  {
    i_c: "07",
    name: "Kukës County",
    c_c: "AL",
  },
  {
    i_c: "08",
    name: "Lezhë County",
    c_c: "AL",
  },
  {
    i_c: "09",
    name: "Dibër County",
    c_c: "AL",
  },
  {
    i_c: "10",
    name: "Shkodër County",
    c_c: "AL",
  },
  {
    i_c: "11",
    name: "Tirana County",
    c_c: "AL",
  },
  {
    i_c: "12",
    name: "Vlorë County",
    c_c: "AL",
  },
  {
    i_c: "BR",
    name: "Berat District",
    c_c: "AL",
  },
  {
    i_c: "BU",
    name: "Bulqizë District",
    c_c: "AL",
  },
  {
    i_c: "DI",
    name: "Dibër District",
    c_c: "AL",
  },
  {
    i_c: "DL",
    name: "Delvinë District",
    c_c: "AL",
  },
  {
    i_c: "DR",
    name: "Durrës District",
    c_c: "AL",
  },
  {
    i_c: "DV",
    name: "Devoll District",
    c_c: "AL",
  },
  {
    i_c: "ER",
    name: "Kolonjë District",
    c_c: "AL",
  },
  {
    i_c: "FR",
    name: "Fier District",
    c_c: "AL",
  },
  {
    i_c: "GJ",
    name: "Gjirokastër District",
    c_c: "AL",
  },
  {
    i_c: "GR",
    name: "Gramsh District",
    c_c: "AL",
  },
  {
    i_c: "HA",
    name: "Has District",
    c_c: "AL",
  },
  {
    i_c: "KA",
    name: "Kavajë District",
    c_c: "AL",
  },
  {
    i_c: "KB",
    name: "Kurbin District",
    c_c: "AL",
  },
  {
    i_c: "KC",
    name: "Kuçovë District",
    c_c: "AL",
  },
  {
    i_c: "KO",
    name: "Korçë District",
    c_c: "AL",
  },
  {
    i_c: "KR",
    name: "Krujë District",
    c_c: "AL",
  },
  {
    i_c: "KU",
    name: "Kukës District",
    c_c: "AL",
  },
  {
    i_c: "LB",
    name: "Librazhd District",
    c_c: "AL",
  },
  {
    i_c: "LE",
    name: "Lezhë District",
    c_c: "AL",
  },
  {
    i_c: "LU",
    name: "Lushnjë District",
    c_c: "AL",
  },
  {
    i_c: "MK",
    name: "Mallakastër District",
    c_c: "AL",
  },
  {
    i_c: "MM",
    name: "Malësi e Madhe District",
    c_c: "AL",
  },
  {
    i_c: "MR",
    name: "Mirditë District",
    c_c: "AL",
  },
  {
    i_c: "MT",
    name: "Mat District",
    c_c: "AL",
  },
  {
    i_c: "PG",
    name: "Pogradec District",
    c_c: "AL",
  },
  {
    i_c: "PQ",
    name: "Peqin District",
    c_c: "AL",
  },
  {
    i_c: "PR",
    name: "Përmet District",
    c_c: "AL",
  },
  {
    i_c: "PU",
    name: "Pukë District",
    c_c: "AL",
  },
  {
    i_c: "SH",
    name: "Shkodër District",
    c_c: "AL",
  },
  {
    i_c: "SK",
    name: "Skrapar District",
    c_c: "AL",
  },
  {
    i_c: "SR",
    name: "Sarandë District",
    c_c: "AL",
  },
  {
    i_c: "TE",
    name: "Tepelenë District",
    c_c: "AL",
  },
  {
    i_c: "TP",
    name: "Tropojë District",
    c_c: "AL",
  },
  {
    i_c: "TR",
    name: "Tirana District",
    c_c: "AL",
  },
  {
    i_c: "VL",
    name: "Vlorë District",
    c_c: "AL",
  },
  {
    i_c: "AG",
    name: "Aragatsotn Region",
    c_c: "AM",
  },
  {
    i_c: "AR",
    name: "Ararat Province",
    c_c: "AM",
  },
  {
    i_c: "AV",
    name: "Armavir Region",
    c_c: "AM",
  },
  {
    i_c: "ER",
    name: "Yerevan",
    c_c: "AM",
  },
  {
    i_c: "GR",
    name: "Gegharkunik Province",
    c_c: "AM",
  },
  {
    i_c: "KT",
    name: "Kotayk Region",
    c_c: "AM",
  },
  {
    i_c: "LO",
    name: "Lori Region",
    c_c: "AM",
  },
  {
    i_c: "SH",
    name: "Shirak Region",
    c_c: "AM",
  },
  {
    i_c: "SU",
    name: "Syunik Province",
    c_c: "AM",
  },
  {
    i_c: "TV",
    name: "Tavush Region",
    c_c: "AM",
  },
  {
    i_c: "VD",
    name: "Vayots Dzor Region",
    c_c: "AM",
  },
  {
    i_c: "BGO",
    name: "Bengo Province",
    c_c: "AO",
  },
  {
    i_c: "BGU",
    name: "Benguela Province",
    c_c: "AO",
  },
  {
    i_c: "BIE",
    name: "Bié Province",
    c_c: "AO",
  },
  {
    i_c: "CAB",
    name: "Cabinda Province",
    c_c: "AO",
  },
  {
    i_c: "CCU",
    name: "Cuando Cubango Province",
    c_c: "AO",
  },
  {
    i_c: "CNN",
    name: "Cunene Province",
    c_c: "AO",
  },
  {
    i_c: "CNO",
    name: "Cuanza Norte Province",
    c_c: "AO",
  },
  {
    i_c: "CUS",
    name: "Cuanza Sul",
    c_c: "AO",
  },
  {
    i_c: "HUA",
    name: "Huambo Province",
    c_c: "AO",
  },
  {
    i_c: "HUI",
    name: "Huíla Province",
    c_c: "AO",
  },
  {
    i_c: "LNO",
    name: "Lunda Norte Province",
    c_c: "AO",
  },
  {
    i_c: "LSU",
    name: "Lunda Sul Province",
    c_c: "AO",
  },
  {
    i_c: "LUA",
    name: "Luanda Province",
    c_c: "AO",
  },
  {
    i_c: "MAL",
    name: "Malanje Province",
    c_c: "AO",
  },
  {
    i_c: "MOX",
    name: "Moxico Province",
    c_c: "AO",
  },
  {
    i_c: "UIG",
    name: "Uíge Province",
    c_c: "AO",
  },
  {
    i_c: "ZAI",
    name: "Zaire Province",
    c_c: "AO",
  },
  {
    i_c: "A",
    name: "Salta",
    c_c: "AR",
  },
  {
    i_c: "B",
    name: "Buenos Aires",
    c_c: "AR",
  },
  {
    i_c: "C",
    name: "Ciudad Autónoma de Buenos Aires",
    c_c: "AR",
  },
  {
    i_c: "D",
    name: "San Luis",
    c_c: "AR",
  },
  {
    i_c: "E",
    name: "Entre Ríos",
    c_c: "AR",
  },
  {
    i_c: "F",
    name: "La Rioja",
    c_c: "AR",
  },
  {
    i_c: "G",
    name: "Santiago del Estero",
    c_c: "AR",
  },
  {
    i_c: "H",
    name: "Chaco",
    c_c: "AR",
  },
  {
    i_c: "J",
    name: "San Juan",
    c_c: "AR",
  },
  {
    i_c: "K",
    name: "Catamarca",
    c_c: "AR",
  },
  {
    i_c: "L",
    name: "La Pampa",
    c_c: "AR",
  },
  {
    i_c: "M",
    name: "Mendoza",
    c_c: "AR",
  },
  {
    i_c: "N",
    name: "Misiones",
    c_c: "AR",
  },
  {
    i_c: "P",
    name: "Formosa",
    c_c: "AR",
  },
  {
    i_c: "Q",
    name: "Neuquén",
    c_c: "AR",
  },
  {
    i_c: "R",
    name: "Río Negro",
    c_c: "AR",
  },
  {
    i_c: "S",
    name: "Santa Fe",
    c_c: "AR",
  },
  {
    i_c: "T",
    name: "Tucumán",
    c_c: "AR",
  },
  {
    i_c: "U",
    name: "Chubut",
    c_c: "AR",
  },
  {
    i_c: "V",
    name: "Tierra del Fuego",
    c_c: "AR",
  },
  {
    i_c: "W",
    name: "Corrientes",
    c_c: "AR",
  },
  {
    i_c: "X",
    name: "Córdoba",
    c_c: "AR",
  },
  {
    i_c: "Y",
    name: "Jujuy",
    c_c: "AR",
  },
  {
    i_c: "Z",
    name: "Santa Cruz",
    c_c: "AR",
  },
  {
    i_c: "1",
    name: "Burgenland",
    c_c: "AT",
  },
  {
    i_c: "2",
    name: "Carinthia",
    c_c: "AT",
  },
  {
    i_c: "3",
    name: "Lower Austria",
    c_c: "AT",
  },
  {
    i_c: "4",
    name: "Upper Austria",
    c_c: "AT",
  },
  {
    i_c: "5",
    name: "Salzburg",
    c_c: "AT",
  },
  {
    i_c: "6",
    name: "Styria",
    c_c: "AT",
  },
  {
    i_c: "7",
    name: "Tyrol",
    c_c: "AT",
  },
  {
    i_c: "8",
    name: "Vorarlberg",
    c_c: "AT",
  },
  {
    i_c: "9",
    name: "Vienna",
    c_c: "AT",
  },
  {
    i_c: "ACT",
    name: "Australian Capital Territory",
    c_c: "AU",
  },
  {
    i_c: "NSW",
    name: "New South Wales",
    c_c: "AU",
  },
  {
    i_c: "NT",
    name: "Northern Territory",
    c_c: "AU",
  },
  {
    i_c: "QLD",
    name: "Queensland",
    c_c: "AU",
  },
  {
    i_c: "SA",
    name: "South Australia",
    c_c: "AU",
  },
  {
    i_c: "TAS",
    name: "Tasmania",
    c_c: "AU",
  },
  {
    i_c: "VIC",
    name: "Victoria",
    c_c: "AU",
  },
  {
    i_c: "WA",
    name: "Western Australia",
    c_c: "AU",
  },
  {
    i_c: "ABS",
    name: "Absheron District",
    c_c: "AZ",
  },
  {
    i_c: "AGA",
    name: "Agstafa District",
    c_c: "AZ",
  },
  {
    i_c: "AGC",
    name: "Aghjabadi District",
    c_c: "AZ",
  },
  {
    i_c: "AGM",
    name: "Agdam District",
    c_c: "AZ",
  },
  {
    i_c: "AGS",
    name: "Agdash District",
    c_c: "AZ",
  },
  {
    i_c: "AGU",
    name: "Agsu District",
    c_c: "AZ",
  },
  {
    i_c: "AST",
    name: "Astara District",
    c_c: "AZ",
  },
  {
    i_c: "BA",
    name: "Baku",
    c_c: "AZ",
  },
  {
    i_c: "BAB",
    name: "Babek District",
    c_c: "AZ",
  },
  {
    i_c: "BAL",
    name: "Balakan District",
    c_c: "AZ",
  },
  {
    i_c: "BAR",
    name: "Barda District",
    c_c: "AZ",
  },
  {
    i_c: "BEY",
    name: "Beylagan District",
    c_c: "AZ",
  },
  {
    i_c: "BIL",
    name: "Bilasuvar District",
    c_c: "AZ",
  },
  {
    i_c: "CAB",
    name: "Jabrayil District",
    c_c: "AZ",
  },
  {
    i_c: "CAL",
    name: "Jalilabad District",
    c_c: "AZ",
  },
  {
    i_c: "CUL",
    name: "Julfa District",
    c_c: "AZ",
  },
  {
    i_c: "DAS",
    name: "Dashkasan District",
    c_c: "AZ",
  },
  {
    i_c: "FUZ",
    name: "Fizuli District",
    c_c: "AZ",
  },
  {
    i_c: "GA",
    name: "Ganja",
    c_c: "AZ",
  },
  {
    i_c: "GAD",
    name: "Gədəbəy",
    c_c: "AZ",
  },
  {
    i_c: "GOR",
    name: "Goranboy District",
    c_c: "AZ",
  },
  {
    i_c: "GOY",
    name: "Goychay",
    c_c: "AZ",
  },
  {
    i_c: "GYG",
    name: "Goygol District",
    c_c: "AZ",
  },
  {
    i_c: "HAC",
    name: "Hajigabul District",
    c_c: "AZ",
  },
  {
    i_c: "IMI",
    name: "Imishli District",
    c_c: "AZ",
  },
  {
    i_c: "ISM",
    name: "Ismailli District",
    c_c: "AZ",
  },
  {
    i_c: "KAL",
    name: "Kalbajar District",
    c_c: "AZ",
  },
  {
    i_c: "KAN",
    name: "Kangarli District",
    c_c: "AZ",
  },
  {
    i_c: "KUR",
    name: "Kurdamir District",
    c_c: "AZ",
  },
  {
    i_c: "LA",
    name: "Lankaran District",
    c_c: "AZ",
  },
  {
    i_c: "LAC",
    name: "Lachin District",
    c_c: "AZ",
  },
  {
    i_c: "LAN",
    name: "Lankaran",
    c_c: "AZ",
  },
  {
    i_c: "LER",
    name: "Lerik District",
    c_c: "AZ",
  },
  {
    i_c: "MAS",
    name: "Masally District",
    c_c: "AZ",
  },
  {
    i_c: "MI",
    name: "Mingachevir",
    c_c: "AZ",
  },
  {
    i_c: "NEF",
    name: "Neftchala District",
    c_c: "AZ",
  },
  {
    i_c: "NX",
    name: "Nakhchivan Autonomous Republic",
    c_c: "AZ",
  },
  {
    i_c: "OGU",
    name: "Oghuz District",
    c_c: "AZ",
  },
  {
    i_c: "ORD",
    name: "Ordubad District",
    c_c: "AZ",
  },
  {
    i_c: "QAB",
    name: "Qabala District",
    c_c: "AZ",
  },
  {
    i_c: "QAX",
    name: "Qakh District",
    c_c: "AZ",
  },
  {
    i_c: "QAZ",
    name: "Qazakh District",
    c_c: "AZ",
  },
  {
    i_c: "QBA",
    name: "Quba District",
    c_c: "AZ",
  },
  {
    i_c: "QBI",
    name: "Qubadli District",
    c_c: "AZ",
  },
  {
    i_c: "QOB",
    name: "Gobustan District",
    c_c: "AZ",
  },
  {
    i_c: "QUS",
    name: "Qusar District",
    c_c: "AZ",
  },
  {
    i_c: "SA",
    name: "Shaki",
    c_c: "AZ",
  },
  {
    i_c: "SAB",
    name: "Sabirabad District",
    c_c: "AZ",
  },
  {
    i_c: "SAD",
    name: "Sadarak District",
    c_c: "AZ",
  },
  {
    i_c: "SAH",
    name: "Shahbuz District",
    c_c: "AZ",
  },
  {
    i_c: "SAK",
    name: "Shaki District",
    c_c: "AZ",
  },
  {
    i_c: "SAL",
    name: "Salyan District",
    c_c: "AZ",
  },
  {
    i_c: "SAR",
    name: "Sharur District",
    c_c: "AZ",
  },
  {
    i_c: "SAT",
    name: "Saatly District",
    c_c: "AZ",
  },
  {
    i_c: "SBN",
    name: "Shabran District",
    c_c: "AZ",
  },
  {
    i_c: "SIY",
    name: "Siazan District",
    c_c: "AZ",
  },
  {
    i_c: "SKR",
    name: "Shamkir District",
    c_c: "AZ",
  },
  {
    i_c: "SM",
    name: "Sumqayit",
    c_c: "AZ",
  },
  {
    i_c: "SMI",
    name: "Shamakhi District",
    c_c: "AZ",
  },
  {
    i_c: "SMX",
    name: "Samukh District",
    c_c: "AZ",
  },
  {
    i_c: "SR",
    name: "Shirvan",
    c_c: "AZ",
  },
  {
    i_c: "SUS",
    name: "Shusha District",
    c_c: "AZ",
  },
  {
    i_c: "TAR",
    name: "Tartar District",
    c_c: "AZ",
  },
  {
    i_c: "TOV",
    name: "Tovuz District",
    c_c: "AZ",
  },
  {
    i_c: "UCA",
    name: "Ujar District",
    c_c: "AZ",
  },
  {
    i_c: "XAC",
    name: "Khachmaz District",
    c_c: "AZ",
  },
  {
    i_c: "XCI",
    name: "Khojali District",
    c_c: "AZ",
  },
  {
    i_c: "XIZ",
    name: "Khizi District",
    c_c: "AZ",
  },
  {
    i_c: "XVD",
    name: "Martuni",
    c_c: "AZ",
  },
  {
    i_c: "YAR",
    name: "Yardymli District",
    c_c: "AZ",
  },
  {
    i_c: "YE",
    name: "Yevlakh",
    c_c: "AZ",
  },
  {
    i_c: "YEV",
    name: "Yevlakh District",
    c_c: "AZ",
  },
  {
    i_c: "ZAN",
    name: "Zangilan District",
    c_c: "AZ",
  },
  {
    i_c: "ZAQ",
    name: "Zaqatala District",
    c_c: "AZ",
  },
  {
    i_c: "ZAR",
    name: "Zardab District",
    c_c: "AZ",
  },
  {
    i_c: "01",
    name: "Una-Sana Canton",
    c_c: "BA",
  },
  {
    i_c: "02",
    name: "Posavina Canton",
    c_c: "BA",
  },
  {
    i_c: "03",
    name: "Tuzla Canton",
    c_c: "BA",
  },
  {
    i_c: "04",
    name: "Zenica-Doboj Canton",
    c_c: "BA",
  },
  {
    i_c: "05",
    name: "Bosnian Podrinje Canton",
    c_c: "BA",
  },
  {
    i_c: "06",
    name: "Central Bosnia Canton",
    c_c: "BA",
  },
  {
    i_c: "07",
    name: "Herzegovina-Neretva Canton",
    c_c: "BA",
  },
  {
    i_c: "08",
    name: "West Herzegovina Canton",
    c_c: "BA",
  },
  {
    i_c: "09",
    name: "Sarajevo Canton",
    c_c: "BA",
  },
  {
    i_c: "10",
    name: "Canton 10",
    c_c: "BA",
  },
  {
    i_c: "BIH",
    name: "Federation of Bosnia and Herzegovina",
    c_c: "BA",
  },
  {
    i_c: "BRC",
    name: "Brčko District",
    c_c: "BA",
  },
  {
    i_c: "SRP",
    name: "Republika Srpska",
    c_c: "BA",
  },
  {
    i_c: "01",
    name: "Christ Church",
    c_c: "BB",
  },
  {
    i_c: "02",
    name: "Saint Andrew",
    c_c: "BB",
  },
  {
    i_c: "03",
    name: "Saint George",
    c_c: "BB",
  },
  {
    i_c: "04",
    name: "Saint James",
    c_c: "BB",
  },
  {
    i_c: "05",
    name: "Saint John",
    c_c: "BB",
  },
  {
    i_c: "06",
    name: "Saint Joseph",
    c_c: "BB",
  },
  {
    i_c: "07",
    name: "Saint Lucy",
    c_c: "BB",
  },
  {
    i_c: "08",
    name: "Saint Michael",
    c_c: "BB",
  },
  {
    i_c: "09",
    name: "Saint Peter",
    c_c: "BB",
  },
  {
    i_c: "10",
    name: "Saint Philip",
    c_c: "BB",
  },
  {
    i_c: "11",
    name: "Saint Thomas",
    c_c: "BB",
  },
  {
    i_c: "01",
    name: "Bandarban District",
    c_c: "BD",
  },
  {
    i_c: "02",
    name: "Barguna District",
    c_c: "BD",
  },
  {
    i_c: "03",
    name: "Bogra District",
    c_c: "BD",
  },
  {
    i_c: "04",
    name: "Brahmanbaria District",
    c_c: "BD",
  },
  {
    i_c: "05",
    name: "Bagerhat District",
    c_c: "BD",
  },
  {
    i_c: "06",
    name: "Barisal District",
    c_c: "BD",
  },
  {
    i_c: "07",
    name: "Bhola District",
    c_c: "BD",
  },
  {
    i_c: "08",
    name: "Comilla District",
    c_c: "BD",
  },
  {
    i_c: "09",
    name: "Chandpur District",
    c_c: "BD",
  },
  {
    i_c: "10",
    name: "Chittagong District",
    c_c: "BD",
  },
  {
    i_c: "11",
    name: "Cox's Bazar District",
    c_c: "BD",
  },
  {
    i_c: "12",
    name: "Chuadanga District",
    c_c: "BD",
  },
  {
    i_c: "13",
    name: "Dhaka District",
    c_c: "BD",
  },
  {
    i_c: "14",
    name: "Dinajpur District",
    c_c: "BD",
  },
  {
    i_c: "15",
    name: "Faridpur District",
    c_c: "BD",
  },
  {
    i_c: "16",
    name: "Feni District",
    c_c: "BD",
  },
  {
    i_c: "17",
    name: "Gopalganj District",
    c_c: "BD",
  },
  {
    i_c: "18",
    name: "Gazipur District",
    c_c: "BD",
  },
  {
    i_c: "19",
    name: "Gaibandha District",
    c_c: "BD",
  },
  {
    i_c: "20",
    name: "Habiganj District",
    c_c: "BD",
  },
  {
    i_c: "21",
    name: "Jamalpur District",
    c_c: "BD",
  },
  {
    i_c: "22",
    name: "Jessore District",
    c_c: "BD",
  },
  {
    i_c: "23",
    name: "Jhenaidah District",
    c_c: "BD",
  },
  {
    i_c: "24",
    name: "Joypurhat District",
    c_c: "BD",
  },
  {
    i_c: "25",
    name: "Jhalokati District",
    c_c: "BD",
  },
  {
    i_c: "26",
    name: "Kishoreganj District",
    c_c: "BD",
  },
  {
    i_c: "27",
    name: "Khulna District",
    c_c: "BD",
  },
  {
    i_c: "28",
    name: "Kurigram District",
    c_c: "BD",
  },
  {
    i_c: "29",
    name: "Khagrachari District",
    c_c: "BD",
  },
  {
    i_c: "30",
    name: "Kushtia District",
    c_c: "BD",
  },
  {
    i_c: "31",
    name: "Lakshmipur District",
    c_c: "BD",
  },
  {
    i_c: "32",
    name: "Lalmonirhat District",
    c_c: "BD",
  },
  {
    i_c: "33",
    name: "Bahadia",
    c_c: "BD",
  },
  {
    i_c: "34",
    name: "Mymensingh District",
    c_c: "BD",
  },
  {
    i_c: "35",
    name: "Munshiganj District",
    c_c: "BD",
  },
  {
    i_c: "36",
    name: "Madaripur District",
    c_c: "BD",
  },
  {
    i_c: "38",
    name: "Moulvibazar District",
    c_c: "BD",
  },
  {
    i_c: "39",
    name: "Meherpur District",
    c_c: "BD",
  },
  {
    i_c: "40",
    name: "Narayanganj District",
    c_c: "BD",
  },
  {
    i_c: "41",
    name: "Netrokona District",
    c_c: "BD",
  },
  {
    i_c: "43",
    name: "Narail District",
    c_c: "BD",
  },
  {
    i_c: "44",
    name: "Natore District",
    c_c: "BD",
  },
  {
    i_c: "45",
    name: "Chapai Nawabganj District",
    c_c: "BD",
  },
  {
    i_c: "46",
    name: "Nilphamari District",
    c_c: "BD",
  },
  {
    i_c: "47",
    name: "Noakhali District",
    c_c: "BD",
  },
  {
    i_c: "48",
    name: "Naogaon District",
    c_c: "BD",
  },
  {
    i_c: "49",
    name: "Pabna District",
    c_c: "BD",
  },
  {
    i_c: "50",
    name: "Pirojpur District",
    c_c: "BD",
  },
  {
    i_c: "51",
    name: "Patuakhali District",
    c_c: "BD",
  },
  {
    i_c: "52",
    name: "Panchagarh District",
    c_c: "BD",
  },
  {
    i_c: "53",
    name: "Rajbari District",
    c_c: "BD",
  },
  {
    i_c: "54",
    name: "Rajshahi District",
    c_c: "BD",
  },
  {
    i_c: "55",
    name: "Rangpur District",
    c_c: "BD",
  },
  {
    i_c: "56",
    name: "Rangamati Hill District",
    c_c: "BD",
  },
  {
    i_c: "57",
    name: "Sherpur District",
    c_c: "BD",
  },
  {
    i_c: "58",
    name: "Satkhira District",
    c_c: "BD",
  },
  {
    i_c: "59",
    name: "Sirajganj District",
    c_c: "BD",
  },
  {
    i_c: "60",
    name: "Sylhet District",
    c_c: "BD",
  },
  {
    i_c: "61",
    name: "Sunamganj District",
    c_c: "BD",
  },
  {
    i_c: "62",
    name: "Shariatpur District",
    c_c: "BD",
  },
  {
    i_c: "63",
    name: "Tangail District",
    c_c: "BD",
  },
  {
    i_c: "64",
    name: "Thakurgaon District",
    c_c: "BD",
  },
  {
    i_c: "A",
    name: "Barisal Division",
    c_c: "BD",
  },
  {
    i_c: "B",
    name: "Chittagong Division",
    c_c: "BD",
  },
  {
    i_c: "C",
    name: "Dhaka Division",
    c_c: "BD",
  },
  {
    i_c: "D",
    name: "Khulna Division",
    c_c: "BD",
  },
  {
    i_c: "E",
    name: "Rajshahi Division",
    c_c: "BD",
  },
  {
    i_c: "F",
    name: "Rangpur Division",
    c_c: "BD",
  },
  {
    i_c: "G",
    name: "Sylhet Division",
    c_c: "BD",
  },
  {
    i_c: "H",
    name: "Mymensingh Division",
    c_c: "BD",
  },
  {
    i_c: "BRU",
    name: "Brussels-Capital Region",
    c_c: "BE",
  },
  {
    i_c: "VAN",
    name: "Antwerp",
    c_c: "BE",
  },
  {
    i_c: "VBR",
    name: "Flemish Brabant",
    c_c: "BE",
  },
  {
    i_c: "VLG",
    name: "Flanders",
    c_c: "BE",
  },
  {
    i_c: "VLI",
    name: "Limburg",
    c_c: "BE",
  },
  {
    i_c: "VOV",
    name: "East Flanders",
    c_c: "BE",
  },
  {
    i_c: "VWV",
    name: "West Flanders",
    c_c: "BE",
  },
  {
    i_c: "WAL",
    name: "Wallonia",
    c_c: "BE",
  },
  {
    i_c: "WBR",
    name: "Walloon Brabant",
    c_c: "BE",
  },
  {
    i_c: "WHT",
    name: "Hainaut",
    c_c: "BE",
  },
  {
    i_c: "WLG",
    name: "Liège",
    c_c: "BE",
  },
  {
    i_c: "WLX",
    name: "Luxembourg",
    c_c: "BE",
  },
  {
    i_c: "WNA",
    name: "Namur",
    c_c: "BE",
  },
  {
    i_c: "01",
    name: "Boucle du Mouhoun Region",
    c_c: "BF",
  },
  {
    i_c: "02",
    name: "Cascades Region",
    c_c: "BF",
  },
  {
    i_c: "03",
    name: "Centre",
    c_c: "BF",
  },
  {
    i_c: "04",
    name: "Centre-Est Region",
    c_c: "BF",
  },
  {
    i_c: "05",
    name: "Centre-Nord Region",
    c_c: "BF",
  },
  {
    i_c: "06",
    name: "Centre-Ouest Region",
    c_c: "BF",
  },
  {
    i_c: "07",
    name: "Centre-Sud Region",
    c_c: "BF",
  },
  {
    i_c: "08",
    name: "Est Region",
    c_c: "BF",
  },
  {
    i_c: "09",
    name: "Hauts-Bassins Region",
    c_c: "BF",
  },
  {
    i_c: "10",
    name: "Nord Region, Burkina Faso",
    c_c: "BF",
  },
  {
    i_c: "11",
    name: "Plateau-Central Region",
    c_c: "BF",
  },
  {
    i_c: "12",
    name: "Sahel Region",
    c_c: "BF",
  },
  {
    i_c: "13",
    name: "Sud-Ouest Region",
    c_c: "BF",
  },
  {
    i_c: "BAL",
    name: "Balé Province",
    c_c: "BF",
  },
  {
    i_c: "BAM",
    name: "Bam Province",
    c_c: "BF",
  },
  {
    i_c: "BAN",
    name: "Banwa Province",
    c_c: "BF",
  },
  {
    i_c: "BAZ",
    name: "Bazèga Province",
    c_c: "BF",
  },
  {
    i_c: "BGR",
    name: "Bougouriba Province",
    c_c: "BF",
  },
  {
    i_c: "BLG",
    name: "Boulgou",
    c_c: "BF",
  },
  {
    i_c: "COM",
    name: "Comoé Province",
    c_c: "BF",
  },
  {
    i_c: "GAN",
    name: "Ganzourgou Province",
    c_c: "BF",
  },
  {
    i_c: "GNA",
    name: "Gnagna Province",
    c_c: "BF",
  },
  {
    i_c: "GOU",
    name: "Gourma Province",
    c_c: "BF",
  },
  {
    i_c: "HOU",
    name: "Houet Province",
    c_c: "BF",
  },
  {
    i_c: "IOB",
    name: "Ioba Province",
    c_c: "BF",
  },
  {
    i_c: "KAD",
    name: "Kadiogo Province",
    c_c: "BF",
  },
  {
    i_c: "KEN",
    name: "Kénédougou Province",
    c_c: "BF",
  },
  {
    i_c: "KMD",
    name: "Komondjari Province",
    c_c: "BF",
  },
  {
    i_c: "KMP",
    name: "Kompienga Province",
    c_c: "BF",
  },
  {
    i_c: "KOP",
    name: "Koulpélogo Province",
    c_c: "BF",
  },
  {
    i_c: "KOS",
    name: "Kossi Province",
    c_c: "BF",
  },
  {
    i_c: "KOT",
    name: "Kouritenga Province",
    c_c: "BF",
  },
  {
    i_c: "KOW",
    name: "Kourwéogo Province",
    c_c: "BF",
  },
  {
    i_c: "LER",
    name: "Léraba Province",
    c_c: "BF",
  },
  {
    i_c: "LOR",
    name: "Loroum Province",
    c_c: "BF",
  },
  {
    i_c: "MOU",
    name: "Mouhoun",
    c_c: "BF",
  },
  {
    i_c: "NAM",
    name: "Namentenga Province",
    c_c: "BF",
  },
  {
    i_c: "NAO",
    name: "Nahouri Province",
    c_c: "BF",
  },
  {
    i_c: "NAY",
    name: "Nayala Province",
    c_c: "BF",
  },
  {
    i_c: "NOU",
    name: "Noumbiel Province",
    c_c: "BF",
  },
  {
    i_c: "OUB",
    name: "Oubritenga Province",
    c_c: "BF",
  },
  {
    i_c: "OUD",
    name: "Oudalan Province",
    c_c: "BF",
  },
  {
    i_c: "PAS",
    name: "Passoré Province",
    c_c: "BF",
  },
  {
    i_c: "PON",
    name: "Poni Province",
    c_c: "BF",
  },
  {
    i_c: "SEN",
    name: "Séno Province",
    c_c: "BF",
  },
  {
    i_c: "SIS",
    name: "Sissili Province",
    c_c: "BF",
  },
  {
    i_c: "SMT",
    name: "Sanmatenga Province",
    c_c: "BF",
  },
  {
    i_c: "SNG",
    name: "Sanguié Province",
    c_c: "BF",
  },
  {
    i_c: "SOM",
    name: "Soum Province",
    c_c: "BF",
  },
  {
    i_c: "SOR",
    name: "Sourou Province",
    c_c: "BF",
  },
  {
    i_c: "TAP",
    name: "Tapoa Province",
    c_c: "BF",
  },
  {
    i_c: "TUI",
    name: "Tuy Province",
    c_c: "BF",
  },
  {
    i_c: "YAG",
    name: "Yagha Province",
    c_c: "BF",
  },
  {
    i_c: "YAT",
    name: "Yatenga Province",
    c_c: "BF",
  },
  {
    i_c: "ZIR",
    name: "Ziro Province",
    c_c: "BF",
  },
  {
    i_c: "ZON",
    name: "Zondoma Province",
    c_c: "BF",
  },
  {
    i_c: "ZOU",
    name: "Zoundwéogo Province",
    c_c: "BF",
  },
  {
    i_c: "01",
    name: "Blagoevgrad Province",
    c_c: "BG",
  },
  {
    i_c: "02",
    name: "Burgas Province",
    c_c: "BG",
  },
  {
    i_c: "03",
    name: "Varna Province",
    c_c: "BG",
  },
  {
    i_c: "04",
    name: "Veliko Tarnovo Province",
    c_c: "BG",
  },
  {
    i_c: "05",
    name: "Vidin Province",
    c_c: "BG",
  },
  {
    i_c: "06",
    name: "Vratsa Province",
    c_c: "BG",
  },
  {
    i_c: "07",
    name: "Gabrovo Province",
    c_c: "BG",
  },
  {
    i_c: "08",
    name: "Dobrich Province",
    c_c: "BG",
  },
  {
    i_c: "09",
    name: "Kardzhali Province",
    c_c: "BG",
  },
  {
    i_c: "10",
    name: "Kyustendil Province",
    c_c: "BG",
  },
  {
    i_c: "11",
    name: "Lovech Province",
    c_c: "BG",
  },
  {
    i_c: "12",
    name: "Montana Province",
    c_c: "BG",
  },
  {
    i_c: "13",
    name: "Pazardzhik Province",
    c_c: "BG",
  },
  {
    i_c: "14",
    name: "Pernik Province",
    c_c: "BG",
  },
  {
    i_c: "15",
    name: "Pleven Province",
    c_c: "BG",
  },
  {
    i_c: "16",
    name: "Plovdiv Province",
    c_c: "BG",
  },
  {
    i_c: "17",
    name: "Razgrad Province",
    c_c: "BG",
  },
  {
    i_c: "18",
    name: "Ruse Province",
    c_c: "BG",
  },
  {
    i_c: "19",
    name: "Silistra Province",
    c_c: "BG",
  },
  {
    i_c: "20",
    name: "Sliven Province",
    c_c: "BG",
  },
  {
    i_c: "21",
    name: "Smolyan Province",
    c_c: "BG",
  },
  {
    i_c: "22",
    name: "Sofia City Province",
    c_c: "BG",
  },
  {
    i_c: "23",
    name: "Sofia Province",
    c_c: "BG",
  },
  {
    i_c: "24",
    name: "Stara Zagora Province",
    c_c: "BG",
  },
  {
    i_c: "25",
    name: "Targovishte Province",
    c_c: "BG",
  },
  {
    i_c: "26",
    name: "Haskovo Province",
    c_c: "BG",
  },
  {
    i_c: "27",
    name: "Shumen",
    c_c: "BG",
  },
  {
    i_c: "28",
    name: "Yambol Province",
    c_c: "BG",
  },
  {
    i_c: "13",
    name: "Capital Governorate",
    c_c: "BH",
  },
  {
    i_c: "14",
    name: "Southern Governorate",
    c_c: "BH",
  },
  {
    i_c: "15",
    name: "Muharraq Governorate",
    c_c: "BH",
  },
  {
    i_c: "16",
    name: "Central Governorate",
    c_c: "BH",
  },
  {
    i_c: "17",
    name: "Northern Governorate",
    c_c: "BH",
  },
  {
    i_c: "BB",
    name: "Bubanza Province",
    c_c: "BI",
  },
  {
    i_c: "BL",
    name: "Bujumbura Rural Province",
    c_c: "BI",
  },
  {
    i_c: "BM",
    name: "Bujumbura Mairie Province",
    c_c: "BI",
  },
  {
    i_c: "BR",
    name: "Bururi Province",
    c_c: "BI",
  },
  {
    i_c: "CA",
    name: "Cankuzo Province",
    c_c: "BI",
  },
  {
    i_c: "CI",
    name: "Cibitoke Province",
    c_c: "BI",
  },
  {
    i_c: "GI",
    name: "Gitega Province",
    c_c: "BI",
  },
  {
    i_c: "KI",
    name: "Kirundo Province",
    c_c: "BI",
  },
  {
    i_c: "KR",
    name: "Karuzi Province",
    c_c: "BI",
  },
  {
    i_c: "KY",
    name: "Kayanza Province",
    c_c: "BI",
  },
  {
    i_c: "MA",
    name: "Makamba Province",
    c_c: "BI",
  },
  {
    i_c: "MU",
    name: "Muramvya Province",
    c_c: "BI",
  },
  {
    i_c: "MW",
    name: "Mwaro Province",
    c_c: "BI",
  },
  {
    i_c: "MY",
    name: "Muyinga Province",
    c_c: "BI",
  },
  {
    i_c: "NG",
    name: "Ngozi Province",
    c_c: "BI",
  },
  {
    i_c: "RM",
    name: "Rumonge Province",
    c_c: "BI",
  },
  {
    i_c: "RT",
    name: "Rutana Province",
    c_c: "BI",
  },
  {
    i_c: "RY",
    name: "Ruyigi Province",
    c_c: "BI",
  },
  {
    i_c: "AK",
    name: "Atakora Department",
    c_c: "BJ",
  },
  {
    i_c: "AL",
    name: "Alibori Department",
    c_c: "BJ",
  },
  {
    i_c: "AQ",
    name: "Atlantique Department",
    c_c: "BJ",
  },
  {
    i_c: "BO",
    name: "Borgou Department",
    c_c: "BJ",
  },
  {
    i_c: "CO",
    name: "Collines Department",
    c_c: "BJ",
  },
  {
    i_c: "DO",
    name: "Donga Department",
    c_c: "BJ",
  },
  {
    i_c: "KO",
    name: "Kouffo Department",
    c_c: "BJ",
  },
  {
    i_c: "LI",
    name: "Littoral Department",
    c_c: "BJ",
  },
  {
    i_c: "MO",
    name: "Mono Department",
    c_c: "BJ",
  },
  {
    i_c: "OU",
    name: "Ouémé Department",
    c_c: "BJ",
  },
  {
    i_c: "PL",
    name: "Plateau Department",
    c_c: "BJ",
  },
  {
    i_c: "ZO",
    name: "Zou Department",
    c_c: "BJ",
  },
  {
    i_c: "DEV",
    name: "Devonshire Parish",
    c_c: "BM",
  },
  {
    i_c: "HA",
    name: "Hamilton Parish",
    c_c: "BM",
  },
  {
    i_c: "PAG",
    name: "Paget Parish",
    c_c: "BM",
  },
  {
    i_c: "PEM",
    name: "Pembroke Parish",
    c_c: "BM",
  },
  {
    i_c: "SAN",
    name: "Sandys Parish",
    c_c: "BM",
  },
  {
    i_c: "SGE",
    name: "Saint George's Parish",
    c_c: "BM",
  },
  {
    i_c: "SMI",
    name: "Smith's Parish,",
    c_c: "BM",
  },
  {
    i_c: "SOU",
    name: "Southampton Parish",
    c_c: "BM",
  },
  {
    i_c: "WAR",
    name: "Warwick Parish",
    c_c: "BM",
  },
  {
    i_c: "BE",
    name: "Belait District",
    c_c: "BN",
  },
  {
    i_c: "BM",
    name: "Brunei-Muara District",
    c_c: "BN",
  },
  {
    i_c: "TE",
    name: "Temburong District",
    c_c: "BN",
  },
  {
    i_c: "TU",
    name: "Tutong District",
    c_c: "BN",
  },
  {
    i_c: "B",
    name: "Beni Department",
    c_c: "BO",
  },
  {
    i_c: "C",
    name: "Cochabamba Department",
    c_c: "BO",
  },
  {
    i_c: "H",
    name: "Chuquisaca Department",
    c_c: "BO",
  },
  {
    i_c: "L",
    name: "La Paz Department",
    c_c: "BO",
  },
  {
    i_c: "N",
    name: "Pando Department",
    c_c: "BO",
  },
  {
    i_c: "O",
    name: "Oruro Department",
    c_c: "BO",
  },
  {
    i_c: "P",
    name: "Potosí Department",
    c_c: "BO",
  },
  {
    i_c: "S",
    name: "Santa Cruz Department",
    c_c: "BO",
  },
  {
    i_c: "T",
    name: "Tarija Department",
    c_c: "BO",
  },
  {
    i_c: "AC",
    name: "Acre",
    c_c: "BR",
  },
  {
    i_c: "AL",
    name: "Alagoas",
    c_c: "BR",
  },
  {
    i_c: "AM",
    name: "Amazonas",
    c_c: "BR",
  },
  {
    i_c: "AP",
    name: "Amapá",
    c_c: "BR",
  },
  {
    i_c: "BA",
    name: "Bahia",
    c_c: "BR",
  },
  {
    i_c: "CE",
    name: "Ceará",
    c_c: "BR",
  },
  {
    i_c: "DF",
    name: "Distrito Federal",
    c_c: "BR",
  },
  {
    i_c: "ES",
    name: "Espírito Santo",
    c_c: "BR",
  },
  {
    i_c: "GO",
    name: "Goiás",
    c_c: "BR",
  },
  {
    i_c: "MA",
    name: "Maranhão",
    c_c: "BR",
  },
  {
    i_c: "MG",
    name: "Minas Gerais",
    c_c: "BR",
  },
  {
    i_c: "MS",
    name: "Mato Grosso do Sul",
    c_c: "BR",
  },
  {
    i_c: "MT",
    name: "Mato Grosso",
    c_c: "BR",
  },
  {
    i_c: "PA",
    name: "Pará",
    c_c: "BR",
  },
  {
    i_c: "PB",
    name: "Paraíba",
    c_c: "BR",
  },
  {
    i_c: "PE",
    name: "Pernambuco",
    c_c: "BR",
  },
  {
    i_c: "PI",
    name: "Piauí",
    c_c: "BR",
  },
  {
    i_c: "PR",
    name: "Paraná",
    c_c: "BR",
  },
  {
    i_c: "RJ",
    name: "Rio de Janeiro",
    c_c: "BR",
  },
  {
    i_c: "RN",
    name: "Rio Grande do Norte",
    c_c: "BR",
  },
  {
    i_c: "RO",
    name: "Rondônia",
    c_c: "BR",
  },
  {
    i_c: "RR",
    name: "Roraima",
    c_c: "BR",
  },
  {
    i_c: "RS",
    name: "Rio Grande do Sul",
    c_c: "BR",
  },
  {
    i_c: "SC",
    name: "Santa Catarina",
    c_c: "BR",
  },
  {
    i_c: "SE",
    name: "Sergipe",
    c_c: "BR",
  },
  {
    i_c: "SP",
    name: "São Paulo",
    c_c: "BR",
  },
  {
    i_c: "TO",
    name: "Tocantins",
    c_c: "BR",
  },
  {
    i_c: "AC",
    name: "Acklins and Crooked Islands",
    c_c: "BS",
  },
  {
    i_c: "AK",
    name: "Acklins",
    c_c: "BS",
  },
  {
    i_c: "BI",
    name: "Bimini",
    c_c: "BS",
  },
  {
    i_c: "BP",
    name: "Black Point",
    c_c: "BS",
  },
  {
    i_c: "BY",
    name: "Berry Islands",
    c_c: "BS",
  },
  {
    i_c: "CE",
    name: "Central Eleuthera",
    c_c: "BS",
  },
  {
    i_c: "CI",
    name: "Cat Island",
    c_c: "BS",
  },
  {
    i_c: "CK",
    name: "Crooked Island",
    c_c: "BS",
  },
  {
    i_c: "CO",
    name: "Central Abaco",
    c_c: "BS",
  },
  {
    i_c: "CS",
    name: "Central Andros",
    c_c: "BS",
  },
  {
    i_c: "EG",
    name: "East Grand Bahama",
    c_c: "BS",
  },
  {
    i_c: "EX",
    name: "Exuma",
    c_c: "BS",
  },
  {
    i_c: "FC",
    name: "Fresh Creek",
    c_c: "BS",
  },
  {
    i_c: "FP",
    name: "Freeport",
    c_c: "BS",
  },
  {
    i_c: "GC",
    name: "Grand Cay",
    c_c: "BS",
  },
  {
    i_c: "GH",
    name: "Governor's Harbour",
    c_c: "BS",
  },
  {
    i_c: "GT",
    name: "Green Turtle Cay",
    c_c: "BS",
  },
  {
    i_c: "HI",
    name: "Harbour Island",
    c_c: "BS",
  },
  {
    i_c: "HR",
    name: "High Rock",
    c_c: "BS",
  },
  {
    i_c: "HT",
    name: "Hope Town",
    c_c: "BS",
  },
  {
    i_c: "IN",
    name: "Inagua",
    c_c: "BS",
  },
  {
    i_c: "KB",
    name: "Kemps Bay",
    c_c: "BS",
  },
  {
    i_c: "LI",
    name: "Long Island",
    c_c: "BS",
  },
  {
    i_c: "MC",
    name: "Mangrove Cay",
    c_c: "BS",
  },
  {
    i_c: "MG",
    name: "Mayaguana District",
    c_c: "BS",
  },
  {
    i_c: "MH",
    name: "Marsh Harbour",
    c_c: "BS",
  },
  {
    i_c: "NB",
    name: "Nichollstown and Berry Islands",
    c_c: "BS",
  },
  {
    i_c: "NE",
    name: "North Eleuthera",
    c_c: "BS",
  },
  {
    i_c: "NO",
    name: "North Abaco",
    c_c: "BS",
  },
  {
    i_c: "NP",
    name: "New Providence",
    c_c: "BS",
  },
  {
    i_c: "NS",
    name: "North Andros",
    c_c: "BS",
  },
  {
    i_c: "RC",
    name: "Rum Cay District",
    c_c: "BS",
  },
  {
    i_c: "RI",
    name: "Ragged Island",
    c_c: "BS",
  },
  {
    i_c: "RS",
    name: "Rock Sound",
    c_c: "BS",
  },
  {
    i_c: "SA",
    name: "South Andros",
    c_c: "BS",
  },
  {
    i_c: "SE",
    name: "South Eleuthera",
    c_c: "BS",
  },
  {
    i_c: "SO",
    name: "South Abaco",
    c_c: "BS",
  },
  {
    i_c: "SP",
    name: "Sandy Point",
    c_c: "BS",
  },
  {
    i_c: "SR",
    name: "San Salvador and Rum Cay",
    c_c: "BS",
  },
  {
    i_c: "SS",
    name: "San Salvador Island",
    c_c: "BS",
  },
  {
    i_c: "SW",
    name: "Spanish Wells",
    c_c: "BS",
  },
  {
    i_c: "WG",
    name: "West Grand Bahama",
    c_c: "BS",
  },
  {
    i_c: "11",
    name: "Paro District",
    c_c: "BT",
  },
  {
    i_c: "12",
    name: "Chukha District",
    c_c: "BT",
  },
  {
    i_c: "13",
    name: "Haa District",
    c_c: "BT",
  },
  {
    i_c: "14",
    name: "Samtse District",
    c_c: "BT",
  },
  {
    i_c: "15",
    name: "Thimphu District",
    c_c: "BT",
  },
  {
    i_c: "21",
    name: "Tsirang District",
    c_c: "BT",
  },
  {
    i_c: "22",
    name: "Dagana District",
    c_c: "BT",
  },
  {
    i_c: "23",
    name: "Punakha District",
    c_c: "BT",
  },
  {
    i_c: "24",
    name: "Wangdue Phodrang District",
    c_c: "BT",
  },
  {
    i_c: "31",
    name: "Sarpang District",
    c_c: "BT",
  },
  {
    i_c: "32",
    name: "Trongsa District",
    c_c: "BT",
  },
  {
    i_c: "33",
    name: "Bumthang District",
    c_c: "BT",
  },
  {
    i_c: "34",
    name: "Zhemgang District",
    c_c: "BT",
  },
  {
    i_c: "41",
    name: "Trashigang District",
    c_c: "BT",
  },
  {
    i_c: "42",
    name: "Mongar District",
    c_c: "BT",
  },
  {
    i_c: "43",
    name: "Pemagatshel District",
    c_c: "BT",
  },
  {
    i_c: "44",
    name: "Lhuntse District",
    c_c: "BT",
  },
  {
    i_c: "45",
    name: "Samdrup Jongkhar District",
    c_c: "BT",
  },
  {
    i_c: "GA",
    name: "Gasa District",
    c_c: "BT",
  },
  {
    i_c: "CE",
    name: "Central District",
    c_c: "BW",
  },
  {
    i_c: "GH",
    name: "Ghanzi District",
    c_c: "BW",
  },
  {
    i_c: "KG",
    name: "Kgalagadi District",
    c_c: "BW",
  },
  {
    i_c: "KL",
    name: "Kgatleng District",
    c_c: "BW",
  },
  {
    i_c: "KW",
    name: "Kweneng District",
    c_c: "BW",
  },
  {
    i_c: "NE",
    name: "North-East District",
    c_c: "BW",
  },
  {
    i_c: "NG",
    name: "Ngamiland",
    c_c: "BW",
  },
  {
    i_c: "NW",
    name: "North-West District",
    c_c: "BW",
  },
  {
    i_c: "SE",
    name: "South-East District",
    c_c: "BW",
  },
  {
    i_c: "SO",
    name: "Southern District",
    c_c: "BW",
  },
  {
    i_c: "BR",
    name: "Brest Region",
    c_c: "BY",
  },
  {
    i_c: "HM",
    name: "Minsk",
    c_c: "BY",
  },
  {
    i_c: "HO",
    name: "Gomel Region",
    c_c: "BY",
  },
  {
    i_c: "HR",
    name: "Grodno Region",
    c_c: "BY",
  },
  {
    i_c: "MA",
    name: "Mogilev Region",
    c_c: "BY",
  },
  {
    i_c: "MI",
    name: "Minsk Region",
    c_c: "BY",
  },
  {
    i_c: "VI",
    name: "Vitebsk Region",
    c_c: "BY",
  },
  {
    i_c: "BZ",
    name: "Belize District",
    c_c: "BZ",
  },
  {
    i_c: "CY",
    name: "Cayo District",
    c_c: "BZ",
  },
  {
    i_c: "CZL",
    name: "Corozal District",
    c_c: "BZ",
  },
  {
    i_c: "OW",
    name: "Orange Walk District",
    c_c: "BZ",
  },
  {
    i_c: "SC",
    name: "Stann Creek District",
    c_c: "BZ",
  },
  {
    i_c: "TOL",
    name: "Toledo District",
    c_c: "BZ",
  },
  {
    i_c: "AB",
    name: "Alberta",
    c_c: "CA",
  },
  {
    i_c: "BC",
    name: "British Columbia",
    c_c: "CA",
  },
  {
    i_c: "MB",
    name: "Manitoba",
    c_c: "CA",
  },
  {
    i_c: "NB",
    name: "New Brunswick",
    c_c: "CA",
  },
  {
    i_c: "NL",
    name: "Newfoundland and Labrador",
    c_c: "CA",
  },
  {
    i_c: "NS",
    name: "Nova Scotia",
    c_c: "CA",
  },
  {
    i_c: "NT",
    name: "Northwest Territories",
    c_c: "CA",
  },
  {
    i_c: "NU",
    name: "Nunavut",
    c_c: "CA",
  },
  {
    i_c: "ON",
    name: "Ontario",
    c_c: "CA",
  },
  {
    i_c: "PE",
    name: "Prince Edward Island",
    c_c: "CA",
  },
  {
    i_c: "QC",
    name: "Quebec",
    c_c: "CA",
  },
  {
    i_c: "SK",
    name: "Saskatchewan",
    c_c: "CA",
  },
  {
    i_c: "YT",
    name: "Yukon",
    c_c: "CA",
  },
  {
    i_c: "BC",
    name: "Kongo Central",
    c_c: "CD",
  },
  {
    i_c: "BU",
    name: "Bas-Uélé",
    c_c: "CD",
  },
  {
    i_c: "EQ",
    name: "Équateur",
    c_c: "CD",
  },
  {
    i_c: "HK",
    name: "Haut-Katanga",
    c_c: "CD",
  },
  {
    i_c: "HL",
    name: "Haut-Lomami",
    c_c: "CD",
  },
  {
    i_c: "HU",
    name: "Haut-Uélé",
    c_c: "CD",
  },
  {
    i_c: "IT",
    name: "Ituri",
    c_c: "CD",
  },
  {
    i_c: "KC",
    name: "Kasaï Central",
    c_c: "CD",
  },
  {
    i_c: "KE",
    name: "Kasaï Oriental",
    c_c: "CD",
  },
  {
    i_c: "KG",
    name: "Kwango",
    c_c: "CD",
  },
  {
    i_c: "KL",
    name: "Kwilu",
    c_c: "CD",
  },
  {
    i_c: "KN",
    name: "Kinshasa",
    c_c: "CD",
  },
  {
    i_c: "KS",
    name: "Kasaï",
    c_c: "CD",
  },
  {
    i_c: "LO",
    name: "Lomami",
    c_c: "CD",
  },
  {
    i_c: "LU",
    name: "Lualaba",
    c_c: "CD",
  },
  {
    i_c: "MA",
    name: "Maniema",
    c_c: "CD",
  },
  {
    i_c: "MN",
    name: "Mai-Ndombe",
    c_c: "CD",
  },
  {
    i_c: "MO",
    name: "Mongala",
    c_c: "CD",
  },
  {
    i_c: "NK",
    name: "Nord-Kivu",
    c_c: "CD",
  },
  {
    i_c: "NU",
    name: "Nord-Ubangi",
    c_c: "CD",
  },
  {
    i_c: "SA",
    name: "Sankuru",
    c_c: "CD",
  },
  {
    i_c: "SK",
    name: "Sud-Kivu",
    c_c: "CD",
  },
  {
    i_c: "SU",
    name: "Sud-Ubangi",
    c_c: "CD",
  },
  {
    i_c: "TA",
    name: "Tanganyika",
    c_c: "CD",
  },
  {
    i_c: "TO",
    name: "Tshopo",
    c_c: "CD",
  },
  {
    i_c: "TU",
    name: "Tshuapa",
    c_c: "CD",
  },
  {
    i_c: "AC",
    name: "Ouham Prefecture",
    c_c: "CF",
  },
  {
    i_c: "BB",
    name: "Bamingui-Bangoran Prefecture",
    c_c: "CF",
  },
  {
    i_c: "BGF",
    name: "Bangui",
    c_c: "CF",
  },
  {
    i_c: "BK",
    name: "Basse-Kotto Prefecture",
    c_c: "CF",
  },
  {
    i_c: "HK",
    name: "Haute-Kotto Prefecture",
    c_c: "CF",
  },
  {
    i_c: "HM",
    name: "Haut-Mbomou Prefecture",
    c_c: "CF",
  },
  {
    i_c: "HS",
    name: "Mambéré-Kadéï",
    c_c: "CF",
  },
  {
    i_c: "KB",
    name: "Nana-Grébizi Economic Prefecture",
    c_c: "CF",
  },
  {
    i_c: "KG",
    name: "Kémo Prefecture",
    c_c: "CF",
  },
  {
    i_c: "LB",
    name: "Lobaye Prefecture",
    c_c: "CF",
  },
  {
    i_c: "MB",
    name: "Mbomou Prefecture",
    c_c: "CF",
  },
  {
    i_c: "MP",
    name: "Ombella-M'Poko Prefecture",
    c_c: "CF",
  },
  {
    i_c: "NM",
    name: "Nana-Mambéré Prefecture",
    c_c: "CF",
  },
  {
    i_c: "OP",
    name: "Ouham-Pendé Prefecture",
    c_c: "CF",
  },
  {
    i_c: "SE",
    name: "Sangha-Mbaéré",
    c_c: "CF",
  },
  {
    i_c: "UK",
    name: "Ouaka Prefecture",
    c_c: "CF",
  },
  {
    i_c: "VK",
    name: "Vakaga Prefecture",
    c_c: "CF",
  },
  {
    i_c: "11",
    name: "Bouenza Department",
    c_c: "CG",
  },
  {
    i_c: "12",
    name: "Pool Department",
    c_c: "CG",
  },
  {
    i_c: "13",
    name: "Sangha Department",
    c_c: "CG",
  },
  {
    i_c: "14",
    name: "Plateaux Department",
    c_c: "CG",
  },
  {
    i_c: "15",
    name: "Cuvette-Ouest Department",
    c_c: "CG",
  },
  {
    i_c: "16",
    name: "Pointe-Noire",
    c_c: "CG",
  },
  {
    i_c: "2",
    name: "Lékoumou Department",
    c_c: "CG",
  },
  {
    i_c: "5",
    name: "Kouilou Department",
    c_c: "CG",
  },
  {
    i_c: "7",
    name: "Likouala Department",
    c_c: "CG",
  },
  {
    i_c: "8",
    name: "Cuvette Department",
    c_c: "CG",
  },
  {
    i_c: "9",
    name: "Niari Department",
    c_c: "CG",
  },
  {
    i_c: "BZV",
    name: "Brazzaville",
    c_c: "CG",
  },
  {
    i_c: "AG",
    name: "Aargau",
    c_c: "CH",
  },
  {
    i_c: "AI",
    name: "Appenzell Innerrhoden",
    c_c: "CH",
  },
  {
    i_c: "AR",
    name: "Appenzell Ausserrhoden",
    c_c: "CH",
  },
  {
    i_c: "BE",
    name: "Bern",
    c_c: "CH",
  },
  {
    i_c: "BL",
    name: "Basel-Land",
    c_c: "CH",
  },
  {
    i_c: "BS",
    name: "Basel-Stadt",
    c_c: "CH",
  },
  {
    i_c: "FR",
    name: "Fribourg",
    c_c: "CH",
  },
  {
    i_c: "GE",
    name: "Geneva",
    c_c: "CH",
  },
  {
    i_c: "GL",
    name: "Glarus",
    c_c: "CH",
  },
  {
    i_c: "GR",
    name: "Graubünden",
    c_c: "CH",
  },
  {
    i_c: "JU",
    name: "Jura",
    c_c: "CH",
  },
  {
    i_c: "LU",
    name: "Lucerne",
    c_c: "CH",
  },
  {
    i_c: "NE",
    name: "Neuchâtel",
    c_c: "CH",
  },
  {
    i_c: "NW",
    name: "Nidwalden",
    c_c: "CH",
  },
  {
    i_c: "OW",
    name: "Obwalden",
    c_c: "CH",
  },
  {
    i_c: "SG",
    name: "St. Gallen",
    c_c: "CH",
  },
  {
    i_c: "SH",
    name: "Schaffhausen",
    c_c: "CH",
  },
  {
    i_c: "SO",
    name: "Solothurn",
    c_c: "CH",
  },
  {
    i_c: "SZ",
    name: "Schwyz",
    c_c: "CH",
  },
  {
    i_c: "TG",
    name: "Thurgau",
    c_c: "CH",
  },
  {
    i_c: "TI",
    name: "Ticino",
    c_c: "CH",
  },
  {
    i_c: "UR",
    name: "Uri",
    c_c: "CH",
  },
  {
    i_c: "VD",
    name: "Vaud",
    c_c: "CH",
  },
  {
    i_c: "VS",
    name: "Valais",
    c_c: "CH",
  },
  {
    i_c: "ZG",
    name: "Zug",
    c_c: "CH",
  },
  {
    i_c: "ZH",
    name: "Zürich",
    c_c: "CH",
  },
  {
    i_c: "01",
    name: "Lagunes region",
    c_c: "CI",
  },
  {
    i_c: "02",
    name: "Haut-Sassandra",
    c_c: "CI",
  },
  {
    i_c: "03",
    name: "Savanes Region",
    c_c: "CI",
  },
  {
    i_c: "04",
    name: "Vallée du Bandama Region",
    c_c: "CI",
  },
  {
    i_c: "05",
    name: "Moyen-Comoé",
    c_c: "CI",
  },
  {
    i_c: "06",
    name: "Dix-Huit Montagnes",
    c_c: "CI",
  },
  {
    i_c: "07",
    name: "Lacs Region",
    c_c: "CI",
  },
  {
    i_c: "09",
    name: "Bas-Sassandra Region",
    c_c: "CI",
  },
  {
    i_c: "10",
    name: "Denguélé Region",
    c_c: "CI",
  },
  {
    i_c: "11",
    name: "N'zi-Comoé",
    c_c: "CI",
  },
  {
    i_c: "12",
    name: "Marahoué Region",
    c_c: "CI",
  },
  {
    i_c: "13",
    name: "Sud-Comoé",
    c_c: "CI",
  },
  {
    i_c: "14",
    name: "Worodougou",
    c_c: "CI",
  },
  {
    i_c: "15",
    name: "Sud-Bandama",
    c_c: "CI",
  },
  {
    i_c: "16",
    name: "Agnéby",
    c_c: "CI",
  },
  {
    i_c: "17",
    name: "Bafing Region",
    c_c: "CI",
  },
  {
    i_c: "18",
    name: "Fromager",
    c_c: "CI",
  },
  {
    i_c: "19",
    name: "Moyen-Cavally",
    c_c: "CI",
  },
  {
    i_c: "AB",
    name: "Abidjan",
    c_c: "CI",
  },
  {
    i_c: "BS",
    name: "Bas-Sassandra District",
    c_c: "CI",
  },
  {
    i_c: "CM",
    name: "Comoé District",
    c_c: "CI",
  },
  {
    i_c: "DN",
    name: "Denguélé District",
    c_c: "CI",
  },
  {
    i_c: "GD",
    name: "Gôh-Djiboua District",
    c_c: "CI",
  },
  {
    i_c: "LC",
    name: "Lacs District",
    c_c: "CI",
  },
  {
    i_c: "LG",
    name: "Lagunes District",
    c_c: "CI",
  },
  {
    i_c: "MG",
    name: "Montagnes District",
    c_c: "CI",
  },
  {
    i_c: "SM",
    name: "Sassandra-Marahoué District",
    c_c: "CI",
  },
  {
    i_c: "VB",
    name: "Vallée du Bandama District",
    c_c: "CI",
  },
  {
    i_c: "WR",
    name: "Woroba District",
    c_c: "CI",
  },
  {
    i_c: "YM",
    name: "Yamoussoukro",
    c_c: "CI",
  },
  {
    i_c: "ZZ",
    name: "Zanzan Region",
    c_c: "CI",
  },
  {
    i_c: "AI",
    name: "Aisén del General Carlos Ibañez del Campo",
    c_c: "CL",
  },
  {
    i_c: "AN",
    name: "Antofagasta",
    c_c: "CL",
  },
  {
    i_c: "AP",
    name: "Arica y Parinacota",
    c_c: "CL",
  },
  {
    i_c: "AR",
    name: "La Araucanía",
    c_c: "CL",
  },
  {
    i_c: "AT",
    name: "Atacama",
    c_c: "CL",
  },
  {
    i_c: "BI",
    name: "Biobío",
    c_c: "CL",
  },
  {
    i_c: "CO",
    name: "Coquimbo",
    c_c: "CL",
  },
  {
    i_c: "LI",
    name: "Libertador General Bernardo O'Higgins",
    c_c: "CL",
  },
  {
    i_c: "LL",
    name: "Los Lagos",
    c_c: "CL",
  },
  {
    i_c: "LR",
    name: "Los Ríos",
    c_c: "CL",
  },
  {
    i_c: "MA",
    name: "Magallanes y de la Antártica Chilena",
    c_c: "CL",
  },
  {
    i_c: "ML",
    name: "Maule",
    c_c: "CL",
  },
  {
    i_c: "NB",
    name: "Ñuble",
    c_c: "CL",
  },
  {
    i_c: "RM",
    name: "Región Metropolitana de Santiago",
    c_c: "CL",
  },
  {
    i_c: "TA",
    name: "Tarapacá",
    c_c: "CL",
  },
  {
    i_c: "VS",
    name: "Valparaíso",
    c_c: "CL",
  },
  {
    i_c: "AD",
    name: "Adamawa",
    c_c: "CM",
  },
  {
    i_c: "CE",
    name: "Centre",
    c_c: "CM",
  },
  {
    i_c: "EN",
    name: "Far North",
    c_c: "CM",
  },
  {
    i_c: "ES",
    name: "East",
    c_c: "CM",
  },
  {
    i_c: "LT",
    name: "Littoral",
    c_c: "CM",
  },
  {
    i_c: "NO",
    name: "North",
    c_c: "CM",
  },
  {
    i_c: "NW",
    name: "Northwest",
    c_c: "CM",
  },
  {
    i_c: "OU",
    name: "West",
    c_c: "CM",
  },
  {
    i_c: "SU",
    name: "South",
    c_c: "CM",
  },
  {
    i_c: "SW",
    name: "Southwest",
    c_c: "CM",
  },
  {
    i_c: "AH",
    name: "Anhui",
    c_c: "CN",
  },
  {
    i_c: "BJ",
    name: "Beijing",
    c_c: "CN",
  },
  {
    i_c: "CQ",
    name: "Chongqing",
    c_c: "CN",
  },
  {
    i_c: "FJ",
    name: "Fujian",
    c_c: "CN",
  },
  {
    i_c: "GD",
    name: "Guangdong",
    c_c: "CN",
  },
  {
    i_c: "GS",
    name: "Gansu",
    c_c: "CN",
  },
  {
    i_c: "GX",
    name: "Guangxi Zhuang",
    c_c: "CN",
  },
  {
    i_c: "GZ",
    name: "Guizhou",
    c_c: "CN",
  },
  {
    i_c: "HA",
    name: "Henan",
    c_c: "CN",
  },
  {
    i_c: "HB",
    name: "Hubei",
    c_c: "CN",
  },
  {
    i_c: "HE",
    name: "Hebei",
    c_c: "CN",
  },
  {
    i_c: "HI",
    name: "Hainan",
    c_c: "CN",
  },
  {
    i_c: "HK",
    name: "Hong Kong SAR",
    c_c: "CN",
  },
  {
    i_c: "HL",
    name: "Heilongjiang",
    c_c: "CN",
  },
  {
    i_c: "HN",
    name: "Hunan",
    c_c: "CN",
  },
  {
    i_c: "JL",
    name: "Jilin",
    c_c: "CN",
  },
  {
    i_c: "JS",
    name: "Jiangsu",
    c_c: "CN",
  },
  {
    i_c: "JX",
    name: "Jiangxi",
    c_c: "CN",
  },
  {
    i_c: "LN",
    name: "Liaoning",
    c_c: "CN",
  },
  {
    i_c: "MO",
    name: "Macau SAR",
    c_c: "CN",
  },
  {
    i_c: "NM",
    name: "Inner Mongolia",
    c_c: "CN",
  },
  {
    i_c: "NX",
    name: "Ningxia Huizu",
    c_c: "CN",
  },
  {
    i_c: "QH",
    name: "Qinghai",
    c_c: "CN",
  },
  {
    i_c: "SC",
    name: "Sichuan",
    c_c: "CN",
  },
  {
    i_c: "SD",
    name: "Shandong",
    c_c: "CN",
  },
  {
    i_c: "SH",
    name: "Shanghai",
    c_c: "CN",
  },
  {
    i_c: "SN",
    name: "Shaanxi",
    c_c: "CN",
  },
  {
    i_c: "SX",
    name: "Shanxi",
    c_c: "CN",
  },
  {
    i_c: "TJ",
    name: "Tianjin",
    c_c: "CN",
  },
  {
    i_c: "TW",
    name: "Taiwan",
    c_c: "CN",
  },
  {
    i_c: "XJ",
    name: "Xinjiang",
    c_c: "CN",
  },
  {
    i_c: "XZ",
    name: "Xizang",
    c_c: "CN",
  },
  {
    i_c: "YN",
    name: "Yunnan",
    c_c: "CN",
  },
  {
    i_c: "ZJ",
    name: "Zhejiang",
    c_c: "CN",
  },
  {
    i_c: "AMA",
    name: "Amazonas",
    c_c: "CO",
  },
  {
    i_c: "ANT",
    name: "Antioquia",
    c_c: "CO",
  },
  {
    i_c: "ARA",
    name: "Arauca",
    c_c: "CO",
  },
  {
    i_c: "ATL",
    name: "Atlántico",
    c_c: "CO",
  },
  {
    i_c: "BOL",
    name: "Bolívar",
    c_c: "CO",
  },
  {
    i_c: "BOY",
    name: "Boyacá",
    c_c: "CO",
  },
  {
    i_c: "CAL",
    name: "Caldas",
    c_c: "CO",
  },
  {
    i_c: "CAQ",
    name: "Caquetá",
    c_c: "CO",
  },
  {
    i_c: "CAS",
    name: "Casanare",
    c_c: "CO",
  },
  {
    i_c: "CAU",
    name: "Cauca",
    c_c: "CO",
  },
  {
    i_c: "CES",
    name: "Cesar",
    c_c: "CO",
  },
  {
    i_c: "CHO",
    name: "Chocó",
    c_c: "CO",
  },
  {
    i_c: "COR",
    name: "Córdoba",
    c_c: "CO",
  },
  {
    i_c: "CUN",
    name: "Cundinamarca",
    c_c: "CO",
  },
  {
    i_c: "DC",
    name: "Bogotá D.C.",
    c_c: "CO",
  },
  {
    i_c: "GUA",
    name: "Guainía",
    c_c: "CO",
  },
  {
    i_c: "GUV",
    name: "Guaviare",
    c_c: "CO",
  },
  {
    i_c: "HUI",
    name: "Huila",
    c_c: "CO",
  },
  {
    i_c: "LAG",
    name: "La Guajira",
    c_c: "CO",
  },
  {
    i_c: "MAG",
    name: "Magdalena",
    c_c: "CO",
  },
  {
    i_c: "MET",
    name: "Meta",
    c_c: "CO",
  },
  {
    i_c: "NAR",
    name: "Nariño",
    c_c: "CO",
  },
  {
    i_c: "NSA",
    name: "Norte de Santander",
    c_c: "CO",
  },
  {
    i_c: "PUT",
    name: "Putumayo",
    c_c: "CO",
  },
  {
    i_c: "QUI",
    name: "Quindío",
    c_c: "CO",
  },
  {
    i_c: "RIS",
    name: "Risaralda",
    c_c: "CO",
  },
  {
    i_c: "SAN",
    name: "Santander",
    c_c: "CO",
  },
  {
    i_c: "SAP",
    name: "Archipiélago de San Andrés, Providencia y Santa Catalina",
    c_c: "CO",
  },
  {
    i_c: "SUC",
    name: "Sucre",
    c_c: "CO",
  },
  {
    i_c: "TOL",
    name: "Tolima",
    c_c: "CO",
  },
  {
    i_c: "VAC",
    name: "Valle del Cauca",
    c_c: "CO",
  },
  {
    i_c: "VAU",
    name: "Vaupés",
    c_c: "CO",
  },
  {
    i_c: "VID",
    name: "Vichada",
    c_c: "CO",
  },
  {
    i_c: "A",
    name: "Alajuela Province",
    c_c: "CR",
  },
  {
    i_c: "C",
    name: "Provincia de Cartago",
    c_c: "CR",
  },
  {
    i_c: "G",
    name: "Guanacaste Province",
    c_c: "CR",
  },
  {
    i_c: "H",
    name: "Heredia Province",
    c_c: "CR",
  },
  {
    i_c: "L",
    name: "Limón Province",
    c_c: "CR",
  },
  {
    i_c: "P",
    name: "Puntarenas Province",
    c_c: "CR",
  },
  {
    i_c: "SJ",
    name: "San José Province",
    c_c: "CR",
  },
  {
    i_c: "01",
    name: "Pinar del Río Province",
    c_c: "CU",
  },
  {
    i_c: "03",
    name: "Havana Province",
    c_c: "CU",
  },
  {
    i_c: "04",
    name: "Matanzas Province",
    c_c: "CU",
  },
  {
    i_c: "05",
    name: "Villa Clara Province",
    c_c: "CU",
  },
  {
    i_c: "06",
    name: "Cienfuegos Province",
    c_c: "CU",
  },
  {
    i_c: "07",
    name: "Sancti Spíritus Province",
    c_c: "CU",
  },
  {
    i_c: "08",
    name: "Ciego de Ávila Province",
    c_c: "CU",
  },
  {
    i_c: "09",
    name: "Camagüey Province",
    c_c: "CU",
  },
  {
    i_c: "10",
    name: "Las Tunas Province",
    c_c: "CU",
  },
  {
    i_c: "11",
    name: "Holguín Province",
    c_c: "CU",
  },
  {
    i_c: "12",
    name: "Granma Province",
    c_c: "CU",
  },
  {
    i_c: "13",
    name: "Santiago de Cuba Province",
    c_c: "CU",
  },
  {
    i_c: "14",
    name: "Guantánamo Province",
    c_c: "CU",
  },
  {
    i_c: "15",
    name: "Artemisa Province",
    c_c: "CU",
  },
  {
    i_c: "16",
    name: "Mayabeque Province",
    c_c: "CU",
  },
  {
    i_c: "99",
    name: "Isla de la Juventud",
    c_c: "CU",
  },
  {
    i_c: "B",
    name: "Barlavento Islands",
    c_c: "CV",
  },
  {
    i_c: "BR",
    name: "Brava",
    c_c: "CV",
  },
  {
    i_c: "BV",
    name: "Boa Vista",
    c_c: "CV",
  },
  {
    i_c: "CA",
    name: "Santa Catarina",
    c_c: "CV",
  },
  {
    i_c: "CF",
    name: "Santa Catarina do Fogo",
    c_c: "CV",
  },
  {
    i_c: "CR",
    name: "Santa Cruz",
    c_c: "CV",
  },
  {
    i_c: "MA",
    name: "Maio Municipality",
    c_c: "CV",
  },
  {
    i_c: "MO",
    name: "Mosteiros",
    c_c: "CV",
  },
  {
    i_c: "PA",
    name: "Paul",
    c_c: "CV",
  },
  {
    i_c: "PN",
    name: "Porto Novo",
    c_c: "CV",
  },
  {
    i_c: "PR",
    name: "Praia",
    c_c: "CV",
  },
  {
    i_c: "RB",
    name: "Ribeira Brava Municipality",
    c_c: "CV",
  },
  {
    i_c: "RG",
    name: "Ribeira Grande",
    c_c: "CV",
  },
  {
    i_c: "RS",
    name: "Ribeira Grande de Santiago",
    c_c: "CV",
  },
  {
    i_c: "S",
    name: "Sotavento Islands",
    c_c: "CV",
  },
  {
    i_c: "SD",
    name: "São Domingos",
    c_c: "CV",
  },
  {
    i_c: "SF",
    name: "São Filipe",
    c_c: "CV",
  },
  {
    i_c: "SL",
    name: "Sal",
    c_c: "CV",
  },
  {
    i_c: "SM",
    name: "São Miguel",
    c_c: "CV",
  },
  {
    i_c: "SO",
    name: "São Lourenço dos Órgãos",
    c_c: "CV",
  },
  {
    i_c: "SV",
    name: "São Vicente",
    c_c: "CV",
  },
  {
    i_c: "TA",
    name: "Tarrafal",
    c_c: "CV",
  },
  {
    i_c: "TS",
    name: "Tarrafal de São Nicolau",
    c_c: "CV",
  },
  {
    i_c: "01",
    name: "Nicosia District (Lefkoşa)",
    c_c: "CY",
  },
  {
    i_c: "02",
    name: "Limassol District (Leymasun)",
    c_c: "CY",
  },
  {
    i_c: "03",
    name: "Larnaca District (Larnaka)",
    c_c: "CY",
  },
  {
    i_c: "04",
    name: "Famagusta District (Mağusa)",
    c_c: "CY",
  },
  {
    i_c: "05",
    name: "Paphos District (Pafos)",
    c_c: "CY",
  },
  {
    i_c: "06",
    name: "Kyrenia District (Keryneia)",
    c_c: "CY",
  },
  {
    i_c: "10",
    name: "Praha, Hlavní město",
    c_c: "CZ",
  },
  {
    i_c: "20",
    name: "Středočeský kraj",
    c_c: "CZ",
  },
  {
    i_c: "201",
    name: "Benešov",
    c_c: "CZ",
  },
  {
    i_c: "202",
    name: "Beroun",
    c_c: "CZ",
  },
  {
    i_c: "203",
    name: "Kladno",
    c_c: "CZ",
  },
  {
    i_c: "204",
    name: "Kolín",
    c_c: "CZ",
  },
  {
    i_c: "205",
    name: "Kutná Hora",
    c_c: "CZ",
  },
  {
    i_c: "206",
    name: "Mělník",
    c_c: "CZ",
  },
  {
    i_c: "207",
    name: "Mladá Boleslav",
    c_c: "CZ",
  },
  {
    i_c: "208",
    name: "Nymburk",
    c_c: "CZ",
  },
  {
    i_c: "209",
    name: "Praha-východ",
    c_c: "CZ",
  },
  {
    i_c: "20A",
    name: "Praha-západ",
    c_c: "CZ",
  },
  {
    i_c: "20B",
    name: "Příbram",
    c_c: "CZ",
  },
  {
    i_c: "20C",
    name: "Rakovník",
    c_c: "CZ",
  },
  {
    i_c: "31",
    name: "Jihočeský kraj",
    c_c: "CZ",
  },
  {
    i_c: "311",
    name: "České Budějovice",
    c_c: "CZ",
  },
  {
    i_c: "312",
    name: "Český Krumlov",
    c_c: "CZ",
  },
  {
    i_c: "313",
    name: "Jindřichův Hradec",
    c_c: "CZ",
  },
  {
    i_c: "314",
    name: "Písek",
    c_c: "CZ",
  },
  {
    i_c: "315",
    name: "Prachatice",
    c_c: "CZ",
  },
  {
    i_c: "316",
    name: "Strakonice",
    c_c: "CZ",
  },
  {
    i_c: "317",
    name: "Tábor",
    c_c: "CZ",
  },
  {
    i_c: "32",
    name: "Plzeňský kraj",
    c_c: "CZ",
  },
  {
    i_c: "321",
    name: "Domažlice",
    c_c: "CZ",
  },
  {
    i_c: "322",
    name: "Klatovy",
    c_c: "CZ",
  },
  {
    i_c: "323",
    name: "Plzeň-město",
    c_c: "CZ",
  },
  {
    i_c: "324",
    name: "Plzeň-jih",
    c_c: "CZ",
  },
  {
    i_c: "325",
    name: "Plzeň-sever",
    c_c: "CZ",
  },
  {
    i_c: "326",
    name: "Rokycany",
    c_c: "CZ",
  },
  {
    i_c: "327",
    name: "Tachov",
    c_c: "CZ",
  },
  {
    i_c: "41",
    name: "Karlovarský kraj",
    c_c: "CZ",
  },
  {
    i_c: "411",
    name: "Cheb",
    c_c: "CZ",
  },
  {
    i_c: "412",
    name: "Karlovy Vary",
    c_c: "CZ",
  },
  {
    i_c: "413",
    name: "Sokolov",
    c_c: "CZ",
  },
  {
    i_c: "42",
    name: "Ústecký kraj",
    c_c: "CZ",
  },
  {
    i_c: "421",
    name: "Děčín",
    c_c: "CZ",
  },
  {
    i_c: "422",
    name: "Chomutov",
    c_c: "CZ",
  },
  {
    i_c: "423",
    name: "Litoměřice",
    c_c: "CZ",
  },
  {
    i_c: "424",
    name: "Louny",
    c_c: "CZ",
  },
  {
    i_c: "425",
    name: "Most",
    c_c: "CZ",
  },
  {
    i_c: "426",
    name: "Teplice",
    c_c: "CZ",
  },
  {
    i_c: "427",
    name: "Ústí nad Labem",
    c_c: "CZ",
  },
  {
    i_c: "51",
    name: "Liberecký kraj",
    c_c: "CZ",
  },
  {
    i_c: "511",
    name: "Česká Lípa",
    c_c: "CZ",
  },
  {
    i_c: "512",
    name: "Jablonec nad Nisou",
    c_c: "CZ",
  },
  {
    i_c: "513",
    name: "Liberec",
    c_c: "CZ",
  },
  {
    i_c: "514",
    name: "Semily",
    c_c: "CZ",
  },
  {
    i_c: "52",
    name: "Královéhradecký kraj",
    c_c: "CZ",
  },
  {
    i_c: "521",
    name: "Hradec Králové",
    c_c: "CZ",
  },
  {
    i_c: "522",
    name: "Jičín",
    c_c: "CZ",
  },
  {
    i_c: "523",
    name: "Náchod",
    c_c: "CZ",
  },
  {
    i_c: "524",
    name: "Rychnov nad Kněžnou",
    c_c: "CZ",
  },
  {
    i_c: "525",
    name: "Trutnov",
    c_c: "CZ",
  },
  {
    i_c: "53",
    name: "Pardubický kraj",
    c_c: "CZ",
  },
  {
    i_c: "531",
    name: "Chrudim",
    c_c: "CZ",
  },
  {
    i_c: "532",
    name: "Pardubice",
    c_c: "CZ",
  },
  {
    i_c: "533",
    name: "Svitavy",
    c_c: "CZ",
  },
  {
    i_c: "534",
    name: "Ústí nad Orlicí",
    c_c: "CZ",
  },
  {
    i_c: "63",
    name: "Kraj Vysočina",
    c_c: "CZ",
  },
  {
    i_c: "631",
    name: "Havlíčkův Brod",
    c_c: "CZ",
  },
  {
    i_c: "632",
    name: "Jihlava",
    c_c: "CZ",
  },
  {
    i_c: "633",
    name: "Pelhřimov",
    c_c: "CZ",
  },
  {
    i_c: "634",
    name: "Třebíč",
    c_c: "CZ",
  },
  {
    i_c: "635",
    name: "Žďár nad Sázavou",
    c_c: "CZ",
  },
  {
    i_c: "64",
    name: "Jihomoravský kraj",
    c_c: "CZ",
  },
  {
    i_c: "641",
    name: "Blansko",
    c_c: "CZ",
  },
  {
    i_c: "642",
    name: "Brno-město",
    c_c: "CZ",
  },
  {
    i_c: "643",
    name: "Brno-venkov",
    c_c: "CZ",
  },
  {
    i_c: "644",
    name: "Břeclav",
    c_c: "CZ",
  },
  {
    i_c: "645",
    name: "Hodonín",
    c_c: "CZ",
  },
  {
    i_c: "646",
    name: "Vyškov",
    c_c: "CZ",
  },
  {
    i_c: "647",
    name: "Znojmo",
    c_c: "CZ",
  },
  {
    i_c: "71",
    name: "Olomoucký kraj",
    c_c: "CZ",
  },
  {
    i_c: "711",
    name: "Jeseník",
    c_c: "CZ",
  },
  {
    i_c: "712",
    name: "Olomouc",
    c_c: "CZ",
  },
  {
    i_c: "713",
    name: "Prostějov",
    c_c: "CZ",
  },
  {
    i_c: "714",
    name: "Přerov",
    c_c: "CZ",
  },
  {
    i_c: "715",
    name: "Šumperk",
    c_c: "CZ",
  },
  {
    i_c: "72",
    name: "Zlínský kraj",
    c_c: "CZ",
  },
  {
    i_c: "721",
    name: "Kroměříž",
    c_c: "CZ",
  },
  {
    i_c: "722",
    name: "Uherské Hradiště",
    c_c: "CZ",
  },
  {
    i_c: "723",
    name: "Vsetín",
    c_c: "CZ",
  },
  {
    i_c: "724",
    name: "Zlín",
    c_c: "CZ",
  },
  {
    i_c: "80",
    name: "Moravskoslezský kraj",
    c_c: "CZ",
  },
  {
    i_c: "801",
    name: "Bruntál",
    c_c: "CZ",
  },
  {
    i_c: "802",
    name: "Frýdek-Místek",
    c_c: "CZ",
  },
  {
    i_c: "803",
    name: "Karviná",
    c_c: "CZ",
  },
  {
    i_c: "804",
    name: "Nový Jičín",
    c_c: "CZ",
  },
  {
    i_c: "805",
    name: "Opava",
    c_c: "CZ",
  },
  {
    i_c: "806",
    name: "Ostrava-město",
    c_c: "CZ",
  },
  {
    i_c: "BB",
    name: "Brandenburg",
    c_c: "DE",
  },
  {
    i_c: "BE",
    name: "Berlin",
    c_c: "DE",
  },
  {
    i_c: "BW",
    name: "Baden-Württemberg",
    c_c: "DE",
  },
  {
    i_c: "BY",
    name: "Bavaria",
    c_c: "DE",
  },
  {
    i_c: "HB",
    name: "Bremen",
    c_c: "DE",
  },
  {
    i_c: "HE",
    name: "Hesse",
    c_c: "DE",
  },
  {
    i_c: "HH",
    name: "Hamburg",
    c_c: "DE",
  },
  {
    i_c: "MV",
    name: "Mecklenburg-Vorpommern",
    c_c: "DE",
  },
  {
    i_c: "NI",
    name: "Lower Saxony",
    c_c: "DE",
  },
  {
    i_c: "NW",
    name: "North Rhine-Westphalia",
    c_c: "DE",
  },
  {
    i_c: "RP",
    name: "Rhineland-Palatinate",
    c_c: "DE",
  },
  {
    i_c: "SH",
    name: "Schleswig-Holstein",
    c_c: "DE",
  },
  {
    i_c: "SL",
    name: "Saarland",
    c_c: "DE",
  },
  {
    i_c: "SN",
    name: "Saxony",
    c_c: "DE",
  },
  {
    i_c: "ST",
    name: "Saxony-Anhalt",
    c_c: "DE",
  },
  {
    i_c: "TH",
    name: "Thuringia",
    c_c: "DE",
  },
  {
    i_c: "AR",
    name: "Arta Region",
    c_c: "DJ",
  },
  {
    i_c: "AS",
    name: "Ali Sabieh Region",
    c_c: "DJ",
  },
  {
    i_c: "DI",
    name: "Dikhil Region",
    c_c: "DJ",
  },
  {
    i_c: "DJ",
    name: "Djibouti",
    c_c: "DJ",
  },
  {
    i_c: "OB",
    name: "Obock Region",
    c_c: "DJ",
  },
  {
    i_c: "TA",
    name: "Tadjourah Region",
    c_c: "DJ",
  },
  {
    i_c: "81",
    name: "North Denmark Region",
    c_c: "DK",
  },
  {
    i_c: "82",
    name: "Central Denmark Region",
    c_c: "DK",
  },
  {
    i_c: "83",
    name: "Region of Southern Denmark",
    c_c: "DK",
  },
  {
    i_c: "84",
    name: "Capital Region of Denmark",
    c_c: "DK",
  },
  {
    i_c: "85",
    name: "Region Zealand",
    c_c: "DK",
  },
  {
    i_c: "02",
    name: "Saint Andrew Parish",
    c_c: "DM",
  },
  {
    i_c: "03",
    name: "Saint David Parish",
    c_c: "DM",
  },
  {
    i_c: "04",
    name: "Saint George Parish",
    c_c: "DM",
  },
  {
    i_c: "05",
    name: "Saint John Parish",
    c_c: "DM",
  },
  {
    i_c: "06",
    name: "Saint Joseph Parish",
    c_c: "DM",
  },
  {
    i_c: "07",
    name: "Saint Luke Parish",
    c_c: "DM",
  },
  {
    i_c: "08",
    name: "Saint Mark Parish",
    c_c: "DM",
  },
  {
    i_c: "09",
    name: "Saint Patrick Parish",
    c_c: "DM",
  },
  {
    i_c: "10",
    name: "Saint Paul Parish",
    c_c: "DM",
  },
  {
    i_c: "11",
    name: "Saint Peter Parish",
    c_c: "DM",
  },
  {
    i_c: "01",
    name: "Distrito Nacional",
    c_c: "DO",
  },
  {
    i_c: "02",
    name: "Azua Province",
    c_c: "DO",
  },
  {
    i_c: "03",
    name: "Baoruco Province",
    c_c: "DO",
  },
  {
    i_c: "04",
    name: "Barahona Province",
    c_c: "DO",
  },
  {
    i_c: "05",
    name: "Dajabón Province",
    c_c: "DO",
  },
  {
    i_c: "06",
    name: "Duarte Province",
    c_c: "DO",
  },
  {
    i_c: "08",
    name: "El Seibo Province",
    c_c: "DO",
  },
  {
    i_c: "09",
    name: "Espaillat Province",
    c_c: "DO",
  },
  {
    i_c: "10",
    name: "Independencia",
    c_c: "DO",
  },
  {
    i_c: "11",
    name: "La Altagracia Province",
    c_c: "DO",
  },
  {
    i_c: "12",
    name: "La Romana Province",
    c_c: "DO",
  },
  {
    i_c: "13",
    name: "La Vega Province",
    c_c: "DO",
  },
  {
    i_c: "14",
    name: "María Trinidad Sánchez Province",
    c_c: "DO",
  },
  {
    i_c: "15",
    name: "Monte Cristi Province",
    c_c: "DO",
  },
  {
    i_c: "16",
    name: "Pedernales Province",
    c_c: "DO",
  },
  {
    i_c: "17",
    name: "Peravia Province",
    c_c: "DO",
  },
  {
    i_c: "18",
    name: "Puerto Plata Province",
    c_c: "DO",
  },
  {
    i_c: "19",
    name: "Hermanas Mirabal Province",
    c_c: "DO",
  },
  {
    i_c: "20",
    name: "Samaná Province",
    c_c: "DO",
  },
  {
    i_c: "21",
    name: "San Cristóbal Province",
    c_c: "DO",
  },
  {
    i_c: "22",
    name: "San Juan Province",
    c_c: "DO",
  },
  {
    i_c: "23",
    name: "San Pedro de Macorís",
    c_c: "DO",
  },
  {
    i_c: "24",
    name: "Sánchez Ramírez Province",
    c_c: "DO",
  },
  {
    i_c: "25",
    name: "Santiago Province",
    c_c: "DO",
  },
  {
    i_c: "26",
    name: "Santiago Rodríguez Province",
    c_c: "DO",
  },
  {
    i_c: "27",
    name: "Valverde Province",
    c_c: "DO",
  },
  {
    i_c: "28",
    name: "Monseñor Nouel Province",
    c_c: "DO",
  },
  {
    i_c: "29",
    name: "Monte Plata Province",
    c_c: "DO",
  },
  {
    i_c: "30",
    name: "Hato Mayor Province",
    c_c: "DO",
  },
  {
    i_c: "31",
    name: "San José de Ocoa Province",
    c_c: "DO",
  },
  {
    i_c: "32",
    name: "Santo Domingo Province",
    c_c: "DO",
  },
  {
    i_c: "01",
    name: "Adrar",
    c_c: "DZ",
  },
  {
    i_c: "02",
    name: "Chlef",
    c_c: "DZ",
  },
  {
    i_c: "03",
    name: "Laghouat",
    c_c: "DZ",
  },
  {
    i_c: "04",
    name: "Oum El Bouaghi",
    c_c: "DZ",
  },
  {
    i_c: "05",
    name: "Batna",
    c_c: "DZ",
  },
  {
    i_c: "06",
    name: "Béjaïa",
    c_c: "DZ",
  },
  {
    i_c: "07",
    name: "Biskra",
    c_c: "DZ",
  },
  {
    i_c: "08",
    name: "Béchar",
    c_c: "DZ",
  },
  {
    i_c: "09",
    name: "Blida",
    c_c: "DZ",
  },
  {
    i_c: "10",
    name: "Bouïra",
    c_c: "DZ",
  },
  {
    i_c: "11",
    name: "Tamanghasset",
    c_c: "DZ",
  },
  {
    i_c: "12",
    name: "Tébessa",
    c_c: "DZ",
  },
  {
    i_c: "13",
    name: "Tlemcen",
    c_c: "DZ",
  },
  {
    i_c: "14",
    name: "Tiaret",
    c_c: "DZ",
  },
  {
    i_c: "15",
    name: "Tizi Ouzou",
    c_c: "DZ",
  },
  {
    i_c: "16",
    name: "Algiers",
    c_c: "DZ",
  },
  {
    i_c: "17",
    name: "Djelfa",
    c_c: "DZ",
  },
  {
    i_c: "18",
    name: "Jijel",
    c_c: "DZ",
  },
  {
    i_c: "19",
    name: "Sétif",
    c_c: "DZ",
  },
  {
    i_c: "20",
    name: "Saïda",
    c_c: "DZ",
  },
  {
    i_c: "21",
    name: "Skikda",
    c_c: "DZ",
  },
  {
    i_c: "22",
    name: "Sidi Bel Abbès",
    c_c: "DZ",
  },
  {
    i_c: "23",
    name: "Annaba",
    c_c: "DZ",
  },
  {
    i_c: "24",
    name: "Guelma",
    c_c: "DZ",
  },
  {
    i_c: "25",
    name: "Constantine",
    c_c: "DZ",
  },
  {
    i_c: "26",
    name: "Médéa",
    c_c: "DZ",
  },
  {
    i_c: "27",
    name: "Mostaganem",
    c_c: "DZ",
  },
  {
    i_c: "28",
    name: "M'Sila",
    c_c: "DZ",
  },
  {
    i_c: "29",
    name: "Mascara",
    c_c: "DZ",
  },
  {
    i_c: "30",
    name: "Ouargla",
    c_c: "DZ",
  },
  {
    i_c: "31",
    name: "Oran",
    c_c: "DZ",
  },
  {
    i_c: "32",
    name: "El Bayadh",
    c_c: "DZ",
  },
  {
    i_c: "33",
    name: "Illizi",
    c_c: "DZ",
  },
  {
    i_c: "34",
    name: "Bordj Bou Arréridj",
    c_c: "DZ",
  },
  {
    i_c: "35",
    name: "Boumerdès",
    c_c: "DZ",
  },
  {
    i_c: "36",
    name: "El Tarf",
    c_c: "DZ",
  },
  {
    i_c: "37",
    name: "Tindouf",
    c_c: "DZ",
  },
  {
    i_c: "38",
    name: "Tissemsilt",
    c_c: "DZ",
  },
  {
    i_c: "39",
    name: "El Oued",
    c_c: "DZ",
  },
  {
    i_c: "40",
    name: "Khenchela",
    c_c: "DZ",
  },
  {
    i_c: "41",
    name: "Souk Ahras",
    c_c: "DZ",
  },
  {
    i_c: "42",
    name: "Tipasa",
    c_c: "DZ",
  },
  {
    i_c: "43",
    name: "Mila",
    c_c: "DZ",
  },
  {
    i_c: "44",
    name: "Aïn Defla",
    c_c: "DZ",
  },
  {
    i_c: "45",
    name: "Naama",
    c_c: "DZ",
  },
  {
    i_c: "46",
    name: "Aïn Témouchent",
    c_c: "DZ",
  },
  {
    i_c: "47",
    name: "Ghardaïa",
    c_c: "DZ",
  },
  {
    i_c: "48",
    name: "Relizane",
    c_c: "DZ",
  },
  {
    i_c: "49",
    name: "El M'ghair",
    c_c: "DZ",
  },
  {
    i_c: "50",
    name: "El Menia",
    c_c: "DZ",
  },
  {
    i_c: "51",
    name: "Ouled Djellal",
    c_c: "DZ",
  },
  {
    i_c: "52",
    name: "Bordj Baji Mokhtar",
    c_c: "DZ",
  },
  {
    i_c: "53",
    name: "Béni Abbès",
    c_c: "DZ",
  },
  {
    i_c: "54",
    name: "Timimoun",
    c_c: "DZ",
  },
  {
    i_c: "55",
    name: "Touggourt",
    c_c: "DZ",
  },
  {
    i_c: "56",
    name: "Djanet",
    c_c: "DZ",
  },
  {
    i_c: "57",
    name: "In Salah",
    c_c: "DZ",
  },
  {
    i_c: "58",
    name: "In Guezzam",
    c_c: "DZ",
  },
  {
    i_c: "A",
    name: "Azuay",
    c_c: "EC",
  },
  {
    i_c: "B",
    name: "Bolívar",
    c_c: "EC",
  },
  {
    i_c: "C",
    name: "Carchi",
    c_c: "EC",
  },
  {
    i_c: "D",
    name: "Orellana",
    c_c: "EC",
  },
  {
    i_c: "E",
    name: "Esmeraldas",
    c_c: "EC",
  },
  {
    i_c: "F",
    name: "Cañar",
    c_c: "EC",
  },
  {
    i_c: "G",
    name: "Guayas",
    c_c: "EC",
  },
  {
    i_c: "H",
    name: "Chimborazo",
    c_c: "EC",
  },
  {
    i_c: "I",
    name: "Imbabura",
    c_c: "EC",
  },
  {
    i_c: "L",
    name: "Loja",
    c_c: "EC",
  },
  {
    i_c: "M",
    name: "Manabí",
    c_c: "EC",
  },
  {
    i_c: "N",
    name: "Napo",
    c_c: "EC",
  },
  {
    i_c: "O",
    name: "El Oro",
    c_c: "EC",
  },
  {
    i_c: "P",
    name: "Pichincha",
    c_c: "EC",
  },
  {
    i_c: "R",
    name: "Los Ríos",
    c_c: "EC",
  },
  {
    i_c: "S",
    name: "Morona-Santiago",
    c_c: "EC",
  },
  {
    i_c: "SD",
    name: "Santo Domingo de los Tsáchilas",
    c_c: "EC",
  },
  {
    i_c: "SE",
    name: "Santa Elena",
    c_c: "EC",
  },
  {
    i_c: "T",
    name: "Tungurahua",
    c_c: "EC",
  },
  {
    i_c: "U",
    name: "Sucumbíos",
    c_c: "EC",
  },
  {
    i_c: "W",
    name: "Galápagos",
    c_c: "EC",
  },
  {
    i_c: "X",
    name: "Cotopaxi",
    c_c: "EC",
  },
  {
    i_c: "Y",
    name: "Pastaza",
    c_c: "EC",
  },
  {
    i_c: "Z",
    name: "Zamora Chinchipe",
    c_c: "EC",
  },
  {
    i_c: "37",
    name: "Harju County",
    c_c: "EE",
  },
  {
    i_c: "39",
    name: "Hiiu County",
    c_c: "EE",
  },
  {
    i_c: "44",
    name: "Ida-Viru County",
    c_c: "EE",
  },
  {
    i_c: "49",
    name: "Jõgeva County",
    c_c: "EE",
  },
  {
    i_c: "51",
    name: "Järva County",
    c_c: "EE",
  },
  {
    i_c: "57",
    name: "Lääne County",
    c_c: "EE",
  },
  {
    i_c: "59",
    name: "Lääne-Viru County",
    c_c: "EE",
  },
  {
    i_c: "65",
    name: "Põlva County",
    c_c: "EE",
  },
  {
    i_c: "67",
    name: "Pärnu County",
    c_c: "EE",
  },
  {
    i_c: "70",
    name: "Rapla County",
    c_c: "EE",
  },
  {
    i_c: "74",
    name: "Saare County",
    c_c: "EE",
  },
  {
    i_c: "78",
    name: "Tartu County",
    c_c: "EE",
  },
  {
    i_c: "82",
    name: "Valga County",
    c_c: "EE",
  },
  {
    i_c: "84",
    name: "Viljandi County",
    c_c: "EE",
  },
  {
    i_c: "86",
    name: "Võru County",
    c_c: "EE",
  },
  {
    i_c: "ALX",
    name: "Alexandria",
    c_c: "EG",
  },
  {
    i_c: "ASN",
    name: "Aswan",
    c_c: "EG",
  },
  {
    i_c: "AST",
    name: "Asyut",
    c_c: "EG",
  },
  {
    i_c: "BA",
    name: "Red Sea",
    c_c: "EG",
  },
  {
    i_c: "BH",
    name: "Beheira",
    c_c: "EG",
  },
  {
    i_c: "BNS",
    name: "Beni Suef",
    c_c: "EG",
  },
  {
    i_c: "C",
    name: "Cairo",
    c_c: "EG",
  },
  {
    i_c: "DK",
    name: "Dakahlia",
    c_c: "EG",
  },
  {
    i_c: "DT",
    name: "Damietta",
    c_c: "EG",
  },
  {
    i_c: "FYM",
    name: "Faiyum",
    c_c: "EG",
  },
  {
    i_c: "GH",
    name: "Gharbia",
    c_c: "EG",
  },
  {
    i_c: "GZ",
    name: "Giza",
    c_c: "EG",
  },
  {
    i_c: "IS",
    name: "Ismailia",
    c_c: "EG",
  },
  {
    i_c: "JS",
    name: "South Sinai",
    c_c: "EG",
  },
  {
    i_c: "KB",
    name: "Qalyubia",
    c_c: "EG",
  },
  {
    i_c: "KFS",
    name: "Kafr el-Sheikh",
    c_c: "EG",
  },
  {
    i_c: "KN",
    name: "Qena",
    c_c: "EG",
  },
  {
    i_c: "LX",
    name: "Luxor",
    c_c: "EG",
  },
  {
    i_c: "MN",
    name: "Minya",
    c_c: "EG",
  },
  {
    i_c: "MNF",
    name: "Monufia",
    c_c: "EG",
  },
  {
    i_c: "MT",
    name: "Matrouh",
    c_c: "EG",
  },
  {
    i_c: "PTS",
    name: "Port Said",
    c_c: "EG",
  },
  {
    i_c: "SHG",
    name: "Sohag",
    c_c: "EG",
  },
  {
    i_c: "SHR",
    name: "Sharqia",
    c_c: "EG",
  },
  {
    i_c: "SIN",
    name: "North Sinai",
    c_c: "EG",
  },
  {
    i_c: "SUZ",
    name: "Suez",
    c_c: "EG",
  },
  {
    i_c: "WAD",
    name: "New Valley",
    c_c: "EG",
  },
  {
    i_c: "AN",
    name: "Anseba Region",
    c_c: "ER",
  },
  {
    i_c: "DK",
    name: "Southern Red Sea Region",
    c_c: "ER",
  },
  {
    i_c: "DU",
    name: "Debub Region",
    c_c: "ER",
  },
  {
    i_c: "GB",
    name: "Gash-Barka Region",
    c_c: "ER",
  },
  {
    i_c: "MA",
    name: "Maekel Region",
    c_c: "ER",
  },
  {
    i_c: "SK",
    name: "Northern Red Sea Region",
    c_c: "ER",
  },
  {
    i_c: "AN",
    name: "Andalusia",
    c_c: "ES",
  },
  {
    i_c: "AR",
    name: "Aragon",
    c_c: "ES",
  },
  {
    i_c: "AS",
    name: "Asturias",
    c_c: "ES",
  },
  {
    i_c: "AV",
    name: "Ávila",
    c_c: "ES",
  },
  {
    i_c: "BU",
    name: "Burgos Province",
    c_c: "ES",
  },
  {
    i_c: "CB",
    name: "Cantabria",
    c_c: "ES",
  },
  {
    i_c: "CE",
    name: "Ceuta",
    c_c: "ES",
  },
  {
    i_c: "CL",
    name: "Castile and León",
    c_c: "ES",
  },
  {
    i_c: "CM",
    name: "Castilla La Mancha",
    c_c: "ES",
  },
  {
    i_c: "CN",
    name: "Canary Islands",
    c_c: "ES",
  },
  {
    i_c: "CT",
    name: "Catalonia",
    c_c: "ES",
  },
  {
    i_c: "EX",
    name: "Extremadura",
    c_c: "ES",
  },
  {
    i_c: "GA",
    name: "Galicia",
    c_c: "ES",
  },
  {
    i_c: "LE",
    name: "Léon",
    c_c: "ES",
  },
  {
    i_c: "MC",
    name: "Murcia",
    c_c: "ES",
  },
  {
    i_c: "MD",
    name: "Madrid",
    c_c: "ES",
  },
  {
    i_c: "ML",
    name: "Melilla",
    c_c: "ES",
  },
  {
    i_c: "NC",
    name: "Navarra",
    c_c: "ES",
  },
  {
    i_c: "P",
    name: "Palencia Province",
    c_c: "ES",
  },
  {
    i_c: "PM",
    name: "Balearic Islands",
    c_c: "ES",
  },
  {
    i_c: "PV",
    name: "Basque Country",
    c_c: "ES",
  },
  {
    i_c: "RI",
    name: "La Rioja",
    c_c: "ES",
  },
  {
    i_c: "SA",
    name: "Salamanca Province",
    c_c: "ES",
  },
  {
    i_c: "SG",
    name: "Segovia Province",
    c_c: "ES",
  },
  {
    i_c: "SO",
    name: "Soria Province",
    c_c: "ES",
  },
  {
    i_c: "VA",
    name: "Valladolid Province",
    c_c: "ES",
  },
  {
    i_c: "VC",
    name: "Valencia",
    c_c: "ES",
  },
  {
    i_c: "ZA",
    name: "Zamora Province",
    c_c: "ES",
  },
  {
    i_c: "AA",
    name: "Addis Ababa",
    c_c: "ET",
  },
  {
    i_c: "AF",
    name: "Afar Region",
    c_c: "ET",
  },
  {
    i_c: "AM",
    name: "Amhara Region",
    c_c: "ET",
  },
  {
    i_c: "BE",
    name: "Benishangul-Gumuz Region",
    c_c: "ET",
  },
  {
    i_c: "DD",
    name: "Dire Dawa",
    c_c: "ET",
  },
  {
    i_c: "GA",
    name: "Gambela Region",
    c_c: "ET",
  },
  {
    i_c: "HA",
    name: "Harari Region",
    c_c: "ET",
  },
  {
    i_c: "OR",
    name: "Oromia Region",
    c_c: "ET",
  },
  {
    i_c: "SN",
    name: "Southern Nations, Nationalities, and Peoples' Region",
    c_c: "ET",
  },
  {
    i_c: "SO",
    name: "Somali Region",
    c_c: "ET",
  },
  {
    i_c: "TI",
    name: "Tigray Region",
    c_c: "ET",
  },
  {
    i_c: "01",
    name: "Åland Islands",
    c_c: "FI",
  },
  {
    i_c: "02",
    name: "South Karelia",
    c_c: "FI",
  },
  {
    i_c: "03",
    name: "Southern Ostrobothnia",
    c_c: "FI",
  },
  {
    i_c: "04",
    name: "Southern Savonia",
    c_c: "FI",
  },
  {
    i_c: "05",
    name: "Kainuu",
    c_c: "FI",
  },
  {
    i_c: "06",
    name: "Tavastia Proper",
    c_c: "FI",
  },
  {
    i_c: "07",
    name: "Central Ostrobothnia",
    c_c: "FI",
  },
  {
    i_c: "08",
    name: "Central Finland",
    c_c: "FI",
  },
  {
    i_c: "09",
    name: "Kymenlaakso",
    c_c: "FI",
  },
  {
    i_c: "11",
    name: "Pirkanmaa",
    c_c: "FI",
  },
  {
    i_c: "12",
    name: "Ostrobothnia",
    c_c: "FI",
  },
  {
    i_c: "13",
    name: "North Karelia",
    c_c: "FI",
  },
  {
    i_c: "14",
    name: "Northern Ostrobothnia",
    c_c: "FI",
  },
  {
    i_c: "15",
    name: "Northern Savonia",
    c_c: "FI",
  },
  {
    i_c: "16",
    name: "Päijänne Tavastia",
    c_c: "FI",
  },
  {
    i_c: "17",
    name: "Satakunta",
    c_c: "FI",
  },
  {
    i_c: "18",
    name: "Uusimaa",
    c_c: "FI",
  },
  {
    i_c: "19",
    name: "Finland Proper",
    c_c: "FI",
  },
  {
    i_c: "IS",
    name: "Eastern Finland Province",
    c_c: "FI",
  },
  {
    i_c: "LL",
    name: "Lapland",
    c_c: "FI",
  },
  {
    i_c: "OL",
    name: "Oulu Province",
    c_c: "FI",
  },
  {
    i_c: "01",
    name: "Ba",
    c_c: "FJ",
  },
  {
    i_c: "02",
    name: "Bua",
    c_c: "FJ",
  },
  {
    i_c: "03",
    name: "Cakaudrove",
    c_c: "FJ",
  },
  {
    i_c: "04",
    name: "Kadavu",
    c_c: "FJ",
  },
  {
    i_c: "05",
    name: "Lau",
    c_c: "FJ",
  },
  {
    i_c: "06",
    name: "Lomaiviti",
    c_c: "FJ",
  },
  {
    i_c: "07",
    name: "Macuata",
    c_c: "FJ",
  },
  {
    i_c: "08",
    name: "Nadroga-Navosa",
    c_c: "FJ",
  },
  {
    i_c: "09",
    name: "Naitasiri",
    c_c: "FJ",
  },
  {
    i_c: "10",
    name: "Namosi",
    c_c: "FJ",
  },
  {
    i_c: "11",
    name: "Ra",
    c_c: "FJ",
  },
  {
    i_c: "12",
    name: "Rewa",
    c_c: "FJ",
  },
  {
    i_c: "13",
    name: "Serua",
    c_c: "FJ",
  },
  {
    i_c: "14",
    name: "Tailevu",
    c_c: "FJ",
  },
  {
    i_c: "C",
    name: "Central Division",
    c_c: "FJ",
  },
  {
    i_c: "E",
    name: "Eastern Division",
    c_c: "FJ",
  },
  {
    i_c: "N",
    name: "Northern Division",
    c_c: "FJ",
  },
  {
    i_c: "R",
    name: "Rotuma",
    c_c: "FJ",
  },
  {
    i_c: "W",
    name: "Western Division",
    c_c: "FJ",
  },
  {
    i_c: "KSA",
    name: "Kosrae State",
    c_c: "FM",
  },
  {
    i_c: "PNI",
    name: "Pohnpei State",
    c_c: "FM",
  },
  {
    i_c: "TRK",
    name: "Chuuk State",
    c_c: "FM",
  },
  {
    i_c: "YAP",
    name: "Yap State",
    c_c: "FM",
  },
  {
    i_c: "01",
    name: "Ain",
    c_c: "FR",
  },
  {
    i_c: "02",
    name: "Aisne",
    c_c: "FR",
  },
  {
    i_c: "03",
    name: "Allier",
    c_c: "FR",
  },
  {
    i_c: "04",
    name: "Alpes-de-Haute-Provence",
    c_c: "FR",
  },
  {
    i_c: "05",
    name: "Hautes-Alpes",
    c_c: "FR",
  },
  {
    i_c: "06",
    name: "Alpes-Maritimes",
    c_c: "FR",
  },
  {
    i_c: "07",
    name: "Ardèche",
    c_c: "FR",
  },
  {
    i_c: "08",
    name: "Ardennes",
    c_c: "FR",
  },
  {
    i_c: "09",
    name: "Ariège",
    c_c: "FR",
  },
  {
    i_c: "10",
    name: "Aube",
    c_c: "FR",
  },
  {
    i_c: "11",
    name: "Aude",
    c_c: "FR",
  },
  {
    i_c: "12",
    name: "Aveyron",
    c_c: "FR",
  },
  {
    i_c: "13",
    name: "Bouches-du-Rhône",
    c_c: "FR",
  },
  {
    i_c: "14",
    name: "Calvados",
    c_c: "FR",
  },
  {
    i_c: "15",
    name: "Cantal",
    c_c: "FR",
  },
  {
    i_c: "16",
    name: "Charente",
    c_c: "FR",
  },
  {
    i_c: "17",
    name: "Charente-Maritime",
    c_c: "FR",
  },
  {
    i_c: "18",
    name: "Cher",
    c_c: "FR",
  },
  {
    i_c: "19",
    name: "Corrèze",
    c_c: "FR",
  },
  {
    i_c: "20R",
    name: "Corse",
    c_c: "FR",
  },
  {
    i_c: "21",
    name: "Côte-d'Or",
    c_c: "FR",
  },
  {
    i_c: "22",
    name: "Côtes-d'Armor",
    c_c: "FR",
  },
  {
    i_c: "23",
    name: "Creuse",
    c_c: "FR",
  },
  {
    i_c: "24",
    name: "Dordogne",
    c_c: "FR",
  },
  {
    i_c: "25",
    name: "Doubs",
    c_c: "FR",
  },
  {
    i_c: "26",
    name: "Drôme",
    c_c: "FR",
  },
  {
    i_c: "27",
    name: "Eure",
    c_c: "FR",
  },
  {
    i_c: "28",
    name: "Eure-et-Loir",
    c_c: "FR",
  },
  {
    i_c: "29",
    name: "Finistère",
    c_c: "FR",
  },
  {
    i_c: "2A",
    name: "Corse-du-Sud",
    c_c: "FR",
  },
  {
    i_c: "2B",
    name: "Haute-Corse",
    c_c: "FR",
  },
  {
    i_c: "30",
    name: "Gard",
    c_c: "FR",
  },
  {
    i_c: "31",
    name: "Haute-Garonne",
    c_c: "FR",
  },
  {
    i_c: "32",
    name: "Gers",
    c_c: "FR",
  },
  {
    i_c: "33",
    name: "Gironde",
    c_c: "FR",
  },
  {
    i_c: "34",
    name: "Hérault",
    c_c: "FR",
  },
  {
    i_c: "35",
    name: "Ille-et-Vilaine",
    c_c: "FR",
  },
  {
    i_c: "36",
    name: "Indre",
    c_c: "FR",
  },
  {
    i_c: "37",
    name: "Indre-et-Loire",
    c_c: "FR",
  },
  {
    i_c: "38",
    name: "Isère",
    c_c: "FR",
  },
  {
    i_c: "39",
    name: "Jura",
    c_c: "FR",
  },
  {
    i_c: "40",
    name: "Landes",
    c_c: "FR",
  },
  {
    i_c: "41",
    name: "Loir-et-Cher",
    c_c: "FR",
  },
  {
    i_c: "42",
    name: "Loire",
    c_c: "FR",
  },
  {
    i_c: "43",
    name: "Haute-Loire",
    c_c: "FR",
  },
  {
    i_c: "44",
    name: "Loire-Atlantique",
    c_c: "FR",
  },
  {
    i_c: "45",
    name: "Loiret",
    c_c: "FR",
  },
  {
    i_c: "46",
    name: "Lot",
    c_c: "FR",
  },
  {
    i_c: "47",
    name: "Lot-et-Garonne",
    c_c: "FR",
  },
  {
    i_c: "48",
    name: "Lozère",
    c_c: "FR",
  },
  {
    i_c: "49",
    name: "Maine-et-Loire",
    c_c: "FR",
  },
  {
    i_c: "50",
    name: "Manche",
    c_c: "FR",
  },
  {
    i_c: "51",
    name: "Marne",
    c_c: "FR",
  },
  {
    i_c: "52",
    name: "Haute-Marne",
    c_c: "FR",
  },
  {
    i_c: "53",
    name: "Mayenne",
    c_c: "FR",
  },
  {
    i_c: "54",
    name: "Meurthe-et-Moselle",
    c_c: "FR",
  },
  {
    i_c: "55",
    name: "Meuse",
    c_c: "FR",
  },
  {
    i_c: "56",
    name: "Morbihan",
    c_c: "FR",
  },
  {
    i_c: "57",
    name: "Moselle",
    c_c: "FR",
  },
  {
    i_c: "58",
    name: "Nièvre",
    c_c: "FR",
  },
  {
    i_c: "59",
    name: "Nord",
    c_c: "FR",
  },
  {
    i_c: "60",
    name: "Oise",
    c_c: "FR",
  },
  {
    i_c: "61",
    name: "Orne",
    c_c: "FR",
  },
  {
    i_c: "62",
    name: "Pas-de-Calais",
    c_c: "FR",
  },
  {
    i_c: "63",
    name: "Puy-de-Dôme",
    c_c: "FR",
  },
  {
    i_c: "64",
    name: "Pyrénées-Atlantiques",
    c_c: "FR",
  },
  {
    i_c: "65",
    name: "Hautes-Pyrénées",
    c_c: "FR",
  },
  {
    i_c: "66",
    name: "Pyrénées-Orientales",
    c_c: "FR",
  },
  {
    i_c: "67",
    name: "Bas-Rhin",
    c_c: "FR",
  },
  {
    i_c: "68",
    name: "Haut-Rhin",
    c_c: "FR",
  },
  {
    i_c: "69",
    name: "Rhône",
    c_c: "FR",
  },
  {
    i_c: "69M",
    name: "Métropole de Lyon",
    c_c: "FR",
  },
  {
    i_c: "6AE",
    name: "Alsace",
    c_c: "FR",
  },
  {
    i_c: "70",
    name: "Haute-Saône",
    c_c: "FR",
  },
  {
    i_c: "71",
    name: "Saône-et-Loire",
    c_c: "FR",
  },
  {
    i_c: "72",
    name: "Sarthe",
    c_c: "FR",
  },
  {
    i_c: "73",
    name: "Savoie",
    c_c: "FR",
  },
  {
    i_c: "74",
    name: "Haute-Savoie",
    c_c: "FR",
  },
  {
    i_c: "75C",
    name: "Paris",
    c_c: "FR",
  },
  {
    i_c: "76",
    name: "Seine-Maritime",
    c_c: "FR",
  },
  {
    i_c: "77",
    name: "Seine-et-Marne",
    c_c: "FR",
  },
  {
    i_c: "78",
    name: "Yvelines",
    c_c: "FR",
  },
  {
    i_c: "79",
    name: "Deux-Sèvres",
    c_c: "FR",
  },
  {
    i_c: "80",
    name: "Somme",
    c_c: "FR",
  },
  {
    i_c: "81",
    name: "Tarn",
    c_c: "FR",
  },
  {
    i_c: "82",
    name: "Tarn-et-Garonne",
    c_c: "FR",
  },
  {
    i_c: "83",
    name: "Var",
    c_c: "FR",
  },
  {
    i_c: "84",
    name: "Vaucluse",
    c_c: "FR",
  },
  {
    i_c: "85",
    name: "Vendée",
    c_c: "FR",
  },
  {
    i_c: "86",
    name: "Vienne",
    c_c: "FR",
  },
  {
    i_c: "87",
    name: "Haute-Vienne",
    c_c: "FR",
  },
  {
    i_c: "88",
    name: "Vosges",
    c_c: "FR",
  },
  {
    i_c: "89",
    name: "Yonne",
    c_c: "FR",
  },
  {
    i_c: "90",
    name: "Territoire de Belfort",
    c_c: "FR",
  },
  {
    i_c: "91",
    name: "Essonne",
    c_c: "FR",
  },
  {
    i_c: "92",
    name: "Hauts-de-Seine",
    c_c: "FR",
  },
  {
    i_c: "93",
    name: "Seine-Saint-Denis",
    c_c: "FR",
  },
  {
    i_c: "94",
    name: "Val-de-Marne",
    c_c: "FR",
  },
  {
    i_c: "95",
    name: "Val-d'Oise",
    c_c: "FR",
  },
  {
    i_c: "971",
    name: "Guadeloupe",
    c_c: "FR",
  },
  {
    i_c: "972",
    name: "Martinique",
    c_c: "FR",
  },
  {
    i_c: "973",
    name: "French Guiana",
    c_c: "FR",
  },
  {
    i_c: "974",
    name: "La Réunion",
    c_c: "FR",
  },
  {
    i_c: "976",
    name: "Mayotte",
    c_c: "FR",
  },
  {
    i_c: "ARA",
    name: "Auvergne-Rhône-Alpes",
    c_c: "FR",
  },
  {
    i_c: "BFC",
    name: "Bourgogne-Franche-Comté",
    c_c: "FR",
  },
  {
    i_c: "BL",
    name: "Saint-Barthélemy",
    c_c: "FR",
  },
  {
    i_c: "BRE",
    name: "Bretagne",
    c_c: "FR",
  },
  {
    i_c: "CP",
    name: "Clipperton",
    c_c: "FR",
  },
  {
    i_c: "CVL",
    name: "Centre-Val de Loire",
    c_c: "FR",
  },
  {
    i_c: "GES",
    name: "Grand-Est",
    c_c: "FR",
  },
  {
    i_c: "HDF",
    name: "Hauts-de-France",
    c_c: "FR",
  },
  {
    i_c: "IDF",
    name: "Île-de-France",
    c_c: "FR",
  },
  {
    i_c: "MF",
    name: "Saint-Martin",
    c_c: "FR",
  },
  {
    i_c: "NAQ",
    name: "Nouvelle-Aquitaine",
    c_c: "FR",
  },
  {
    i_c: "NOR",
    name: "Normandie",
    c_c: "FR",
  },
  {
    i_c: "OCC",
    name: "Occitanie",
    c_c: "FR",
  },
  {
    i_c: "PAC",
    name: "Provence-Alpes-Côte-d’Azur",
    c_c: "FR",
  },
  {
    i_c: "PDL",
    name: "Pays-de-la-Loire",
    c_c: "FR",
  },
  {
    i_c: "PF",
    name: "French Polynesia",
    c_c: "FR",
  },
  {
    i_c: "PM",
    name: "Saint Pierre and Miquelon",
    c_c: "FR",
  },
  {
    i_c: "TF",
    name: "French Southern and Antarctic Lands",
    c_c: "FR",
  },
  {
    i_c: "WF",
    name: "Wallis and Futuna",
    c_c: "FR",
  },
  {
    i_c: "1",
    name: "Estuaire Province",
    c_c: "GA",
  },
  {
    i_c: "2",
    name: "Haut-Ogooué Province",
    c_c: "GA",
  },
  {
    i_c: "3",
    name: "Moyen-Ogooué Province",
    c_c: "GA",
  },
  {
    i_c: "4",
    name: "Ngounié Province",
    c_c: "GA",
  },
  {
    i_c: "5",
    name: "Nyanga Province",
    c_c: "GA",
  },
  {
    i_c: "6",
    name: "Ogooué-Ivindo Province",
    c_c: "GA",
  },
  {
    i_c: "7",
    name: "Ogooué-Lolo Province",
    c_c: "GA",
  },
  {
    i_c: "8",
    name: "Ogooué-Maritime Province",
    c_c: "GA",
  },
  {
    i_c: "9",
    name: "Woleu-Ntem Province",
    c_c: "GA",
  },
  {
    i_c: "ABC",
    name: "Armagh, Banbridge and Craigavon",
    c_c: "GB",
  },
  {
    i_c: "ABD",
    name: "Aberdeenshire",
    c_c: "GB",
  },
  {
    i_c: "ABE",
    name: "Aberdeen",
    c_c: "GB",
  },
  {
    i_c: "AGB",
    name: "Argyll and Bute",
    c_c: "GB",
  },
  {
    i_c: "AND",
    name: "Ards and North Down",
    c_c: "GB",
  },
  {
    i_c: "ANN",
    name: "Antrim and Newtownabbey",
    c_c: "GB",
  },
  {
    i_c: "ANS",
    name: "Angus",
    c_c: "GB",
  },
  {
    i_c: "ANT",
    name: "Antrim",
    c_c: "GB",
  },
  {
    i_c: "ARD",
    name: "Ards",
    c_c: "GB",
  },
  {
    i_c: "ARM",
    name: "Armagh City and District Council",
    c_c: "GB",
  },
  {
    i_c: "BAS",
    name: "Bath and North East Somerset",
    c_c: "GB",
  },
  {
    i_c: "BBD",
    name: "Blackburn with Darwen",
    c_c: "GB",
  },
  {
    i_c: "BDF",
    name: "Bedford",
    c_c: "GB",
  },
  {
    i_c: "BDG",
    name: "London Borough of Barking and Dagenham",
    c_c: "GB",
  },
  {
    i_c: "BEN",
    name: "London Borough of Brent",
    c_c: "GB",
  },
  {
    i_c: "BEX",
    name: "London Borough of Bexley",
    c_c: "GB",
  },
  {
    i_c: "BFS",
    name: "Belfast district",
    c_c: "GB",
  },
  {
    i_c: "BGE",
    name: "Bridgend County Borough",
    c_c: "GB",
  },
  {
    i_c: "BGW",
    name: "Blaenau Gwent County Borough",
    c_c: "GB",
  },
  {
    i_c: "BIR",
    name: "Birmingham",
    c_c: "GB",
  },
  {
    i_c: "BKM",
    name: "Buckinghamshire",
    c_c: "GB",
  },
  {
    i_c: "BLA",
    name: "Ballymena Borough",
    c_c: "GB",
  },
  {
    i_c: "BLY",
    name: "Ballymoney",
    c_c: "GB",
  },
  {
    i_c: "BMH",
    name: "Bournemouth",
    c_c: "GB",
  },
  {
    i_c: "BNB",
    name: "Banbridge",
    c_c: "GB",
  },
  {
    i_c: "BNE",
    name: "London Borough of Barnet",
    c_c: "GB",
  },
  {
    i_c: "BNH",
    name: "Brighton and Hove",
    c_c: "GB",
  },
  {
    i_c: "BNS",
    name: "Barnsley",
    c_c: "GB",
  },
  {
    i_c: "BOL",
    name: "Bolton",
    c_c: "GB",
  },
  {
    i_c: "BPL",
    name: "Blackpool",
    c_c: "GB",
  },
  {
    i_c: "BRC",
    name: "Bracknell Forest",
    c_c: "GB",
  },
  {
    i_c: "BRD",
    name: "Bradford",
    c_c: "GB",
  },
  {
    i_c: "BRY",
    name: "London Borough of Bromley",
    c_c: "GB",
  },
  {
    i_c: "BST",
    name: "City of Bristol",
    c_c: "GB",
  },
  {
    i_c: "BUR",
    name: "Bury",
    c_c: "GB",
  },
  {
    i_c: "CAM",
    name: "Cambridgeshire",
    c_c: "GB",
  },
  {
    i_c: "CAY",
    name: "Caerphilly County Borough",
    c_c: "GB",
  },
  {
    i_c: "CBF",
    name: "Central Bedfordshire",
    c_c: "GB",
  },
  {
    i_c: "CCG",
    name: "Causeway Coast and Glens",
    c_c: "GB",
  },
  {
    i_c: "CGN",
    name: "Ceredigion",
    c_c: "GB",
  },
  {
    i_c: "CGV",
    name: "Craigavon Borough Council",
    c_c: "GB",
  },
  {
    i_c: "CHE",
    name: "Cheshire East",
    c_c: "GB",
  },
  {
    i_c: "CHW",
    name: "Cheshire West and Chester",
    c_c: "GB",
  },
  {
    i_c: "CKF",
    name: "Carrickfergus Borough Council",
    c_c: "GB",
  },
  {
    i_c: "CKT",
    name: "Cookstown District Council",
    c_c: "GB",
  },
  {
    i_c: "CLD",
    name: "Calderdale",
    c_c: "GB",
  },
  {
    i_c: "CLK",
    name: "Clackmannanshire",
    c_c: "GB",
  },
  {
    i_c: "CLR",
    name: "Coleraine Borough Council",
    c_c: "GB",
  },
  {
    i_c: "CMA",
    name: "Cumbria",
    c_c: "GB",
  },
  {
    i_c: "CMD",
    name: "London Borough of Camden",
    c_c: "GB",
  },
  {
    i_c: "CMN",
    name: "Carmarthenshire",
    c_c: "GB",
  },
  {
    i_c: "CON",
    name: "Cornwall",
    c_c: "GB",
  },
  {
    i_c: "COV",
    name: "Coventry",
    c_c: "GB",
  },
  {
    i_c: "CRF",
    name: "City and County of Cardiff",
    c_c: "GB",
  },
  {
    i_c: "CRY",
    name: "London Borough of Croydon",
    c_c: "GB",
  },
  {
    i_c: "CSR",
    name: "Castlereagh",
    c_c: "GB",
  },
  {
    i_c: "CWY",
    name: "Conwy County Borough",
    c_c: "GB",
  },
  {
    i_c: "DAL",
    name: "Darlington",
    c_c: "GB",
  },
  {
    i_c: "DBY",
    name: "Derbyshire",
    c_c: "GB",
  },
  {
    i_c: "DEN",
    name: "Denbighshire",
    c_c: "GB",
  },
  {
    i_c: "DER",
    name: "City of Derby",
    c_c: "GB",
  },
  {
    i_c: "DEV",
    name: "Devon",
    c_c: "GB",
  },
  {
    i_c: "DGN",
    name: "Dungannon and South Tyrone Borough Council",
    c_c: "GB",
  },
  {
    i_c: "DGY",
    name: "Dumfries and Galloway",
    c_c: "GB",
  },
  {
    i_c: "DNC",
    name: "Doncaster",
    c_c: "GB",
  },
  {
    i_c: "DND",
    name: "Dundee",
    c_c: "GB",
  },
  {
    i_c: "DOR",
    name: "Dorset",
    c_c: "GB",
  },
  {
    i_c: "DOW",
    name: "Down District Council",
    c_c: "GB",
  },
  {
    i_c: "DRS",
    name: "Derry City and Strabane",
    c_c: "GB",
  },
  {
    i_c: "DRY",
    name: "Derry City Council",
    c_c: "GB",
  },
  {
    i_c: "DUD",
    name: "Dudley",
    c_c: "GB",
  },
  {
    i_c: "DUR",
    name: "County Durham",
    c_c: "GB",
  },
  {
    i_c: "EAL",
    name: "London Borough of Ealing",
    c_c: "GB",
  },
  {
    i_c: "EAY",
    name: "East Ayrshire",
    c_c: "GB",
  },
  {
    i_c: "EDH",
    name: "Edinburgh",
    c_c: "GB",
  },
  {
    i_c: "EDU",
    name: "East Dunbartonshire",
    c_c: "GB",
  },
  {
    i_c: "ELN",
    name: "East Lothian",
    c_c: "GB",
  },
  {
    i_c: "ELS",
    name: "Outer Hebrides",
    c_c: "GB",
  },
  {
    i_c: "ENF",
    name: "London Borough of Enfield",
    c_c: "GB",
  },
  {
    i_c: "ENG",
    name: "England",
    c_c: "GB",
  },
  {
    i_c: "ERW",
    name: "East Renfrewshire",
    c_c: "GB",
  },
  {
    i_c: "ERY",
    name: "East Riding of Yorkshire",
    c_c: "GB",
  },
  {
    i_c: "ESS",
    name: "Essex",
    c_c: "GB",
  },
  {
    i_c: "ESX",
    name: "East Sussex",
    c_c: "GB",
  },
  {
    i_c: "FAL",
    name: "Falkirk",
    c_c: "GB",
  },
  {
    i_c: "FER",
    name: "Fermanagh District Council",
    c_c: "GB",
  },
  {
    i_c: "FIF",
    name: "Fife",
    c_c: "GB",
  },
  {
    i_c: "FLN",
    name: "Flintshire",
    c_c: "GB",
  },
  {
    i_c: "FMO",
    name: "Fermanagh and Omagh",
    c_c: "GB",
  },
  {
    i_c: "GAT",
    name: "Gateshead",
    c_c: "GB",
  },
  {
    i_c: "GLG",
    name: "Glasgow",
    c_c: "GB",
  },
  {
    i_c: "GLS",
    name: "Gloucestershire",
    c_c: "GB",
  },
  {
    i_c: "GRE",
    name: "Royal Borough of Greenwich",
    c_c: "GB",
  },
  {
    i_c: "GWN",
    name: "Gwynedd",
    c_c: "GB",
  },
  {
    i_c: "HAL",
    name: "Halton",
    c_c: "GB",
  },
  {
    i_c: "HAM",
    name: "Hampshire",
    c_c: "GB",
  },
  {
    i_c: "HAV",
    name: "London Borough of Havering",
    c_c: "GB",
  },
  {
    i_c: "HCK",
    name: "London Borough of Hackney",
    c_c: "GB",
  },
  {
    i_c: "HEF",
    name: "Herefordshire",
    c_c: "GB",
  },
  {
    i_c: "HIL",
    name: "London Borough of Hillingdon",
    c_c: "GB",
  },
  {
    i_c: "HLD",
    name: "Highland",
    c_c: "GB",
  },
  {
    i_c: "HMF",
    name: "London Borough of Hammersmith and Fulham",
    c_c: "GB",
  },
  {
    i_c: "HNS",
    name: "London Borough of Hounslow",
    c_c: "GB",
  },
  {
    i_c: "HPL",
    name: "Hartlepool",
    c_c: "GB",
  },
  {
    i_c: "HRT",
    name: "Hertfordshire",
    c_c: "GB",
  },
  {
    i_c: "HRW",
    name: "London Borough of Harrow",
    c_c: "GB",
  },
  {
    i_c: "HRY",
    name: "London Borough of Haringey",
    c_c: "GB",
  },
  {
    i_c: "IOS",
    name: "Isles of Scilly",
    c_c: "GB",
  },
  {
    i_c: "IOW",
    name: "Isle of Wight",
    c_c: "GB",
  },
  {
    i_c: "ISL",
    name: "London Borough of Islington",
    c_c: "GB",
  },
  {
    i_c: "IVC",
    name: "Inverclyde",
    c_c: "GB",
  },
  {
    i_c: "KEC",
    name: "Royal Borough of Kensington and Chelsea",
    c_c: "GB",
  },
  {
    i_c: "KEN",
    name: "Kent",
    c_c: "GB",
  },
  {
    i_c: "KHL",
    name: "City of Kingston upon Hull",
    c_c: "GB",
  },
  {
    i_c: "KIR",
    name: "Kirklees",
    c_c: "GB",
  },
  {
    i_c: "KTT",
    name: "Royal Borough of Kingston upon Thames",
    c_c: "GB",
  },
  {
    i_c: "KWL",
    name: "Knowsley",
    c_c: "GB",
  },
  {
    i_c: "LAN",
    name: "Lancashire",
    c_c: "GB",
  },
  {
    i_c: "LBC",
    name: "Lisburn and Castlereagh",
    c_c: "GB",
  },
  {
    i_c: "LBH",
    name: "London Borough of Lambeth",
    c_c: "GB",
  },
  {
    i_c: "LCE",
    name: "City of Leicester",
    c_c: "GB",
  },
  {
    i_c: "LDS",
    name: "Leeds",
    c_c: "GB",
  },
  {
    i_c: "LEC",
    name: "Leicestershire",
    c_c: "GB",
  },
  {
    i_c: "LEW",
    name: "London Borough of Lewisham",
    c_c: "GB",
  },
  {
    i_c: "LIN",
    name: "Lincolnshire",
    c_c: "GB",
  },
  {
    i_c: "LIV",
    name: "Liverpool",
    c_c: "GB",
  },
  {
    i_c: "LMV",
    name: "Limavady Borough Council",
    c_c: "GB",
  },
  {
    i_c: "LND",
    name: "City of London",
    c_c: "GB",
  },
  {
    i_c: "LRN",
    name: "Larne Borough Council",
    c_c: "GB",
  },
  {
    i_c: "LSB",
    name: "Lisburn City Council",
    c_c: "GB",
  },
  {
    i_c: "MAN",
    name: "Manchester",
    c_c: "GB",
  },
  {
    i_c: "MDB",
    name: "Middlesbrough",
    c_c: "GB",
  },
  {
    i_c: "MDW",
    name: "Medway",
    c_c: "GB",
  },
  {
    i_c: "MEA",
    name: "Mid and East Antrim",
    c_c: "GB",
  },
  {
    i_c: "MFT",
    name: "Magherafelt District Council",
    c_c: "GB",
  },
  {
    i_c: "MIK",
    name: "Milton Keynes",
    c_c: "GB",
  },
  {
    i_c: "MLN",
    name: "Midlothian",
    c_c: "GB",
  },
  {
    i_c: "MON",
    name: "Monmouthshire",
    c_c: "GB",
  },
  {
    i_c: "MRT",
    name: "London Borough of Merton",
    c_c: "GB",
  },
  {
    i_c: "MRY",
    name: "Moray",
    c_c: "GB",
  },
  {
    i_c: "MTY",
    name: "Merthyr Tydfil County Borough",
    c_c: "GB",
  },
  {
    i_c: "MUL",
    name: "Mid Ulster",
    c_c: "GB",
  },
  {
    i_c: "MYL",
    name: "Moyle District Council",
    c_c: "GB",
  },
  {
    i_c: "NAY",
    name: "North Ayrshire",
    c_c: "GB",
  },
  {
    i_c: "NBL",
    name: "Northumberland",
    c_c: "GB",
  },
  {
    i_c: "NDN",
    name: "North Down Borough Council",
    c_c: "GB",
  },
  {
    i_c: "NEL",
    name: "North East Lincolnshire",
    c_c: "GB",
  },
  {
    i_c: "NET",
    name: "Newcastle upon Tyne",
    c_c: "GB",
  },
  {
    i_c: "NFK",
    name: "Norfolk",
    c_c: "GB",
  },
  {
    i_c: "NGM",
    name: "City of Nottingham",
    c_c: "GB",
  },
  {
    i_c: "NIR",
    name: "Northern Ireland",
    c_c: "GB",
  },
  {
    i_c: "NLK",
    name: "North Lanarkshire",
    c_c: "GB",
  },
  {
    i_c: "NLN",
    name: "North Lincolnshire",
    c_c: "GB",
  },
  {
    i_c: "NMD",
    name: "Newry, Mourne and Down",
    c_c: "GB",
  },
  {
    i_c: "NSM",
    name: "North Somerset",
    c_c: "GB",
  },
  {
    i_c: "NTA",
    name: "Newtownabbey Borough Council",
    c_c: "GB",
  },
  {
    i_c: "NTH",
    name: "Northamptonshire",
    c_c: "GB",
  },
  {
    i_c: "NTL",
    name: "Neath Port Talbot County Borough",
    c_c: "GB",
  },
  {
    i_c: "NTT",
    name: "Nottinghamshire",
    c_c: "GB",
  },
  {
    i_c: "NTY",
    name: "North Tyneside",
    c_c: "GB",
  },
  {
    i_c: "NWM",
    name: "London Borough of Newham",
    c_c: "GB",
  },
  {
    i_c: "NWP",
    name: "Newport",
    c_c: "GB",
  },
  {
    i_c: "NYK",
    name: "North Yorkshire",
    c_c: "GB",
  },
  {
    i_c: "NYM",
    name: "Newry and Mourne District Council",
    c_c: "GB",
  },
  {
    i_c: "OLD",
    name: "Oldham",
    c_c: "GB",
  },
  {
    i_c: "OMH",
    name: "Omagh District Council",
    c_c: "GB",
  },
  {
    i_c: "ORK",
    name: "Orkney Islands",
    c_c: "GB",
  },
  {
    i_c: "OXF",
    name: "Oxfordshire",
    c_c: "GB",
  },
  {
    i_c: "PEM",
    name: "Pembrokeshire",
    c_c: "GB",
  },
  {
    i_c: "PKN",
    name: "Perth and Kinross",
    c_c: "GB",
  },
  {
    i_c: "PLY",
    name: "City of Plymouth",
    c_c: "GB",
  },
  {
    i_c: "POL",
    name: "Poole",
    c_c: "GB",
  },
  {
    i_c: "POR",
    name: "City of Portsmouth",
    c_c: "GB",
  },
  {
    i_c: "POW",
    name: "Powys",
    c_c: "GB",
  },
  {
    i_c: "PTE",
    name: "City of Peterborough",
    c_c: "GB",
  },
  {
    i_c: "RCC",
    name: "Redcar and Cleveland",
    c_c: "GB",
  },
  {
    i_c: "RCH",
    name: "Rochdale",
    c_c: "GB",
  },
  {
    i_c: "RCT",
    name: "Rhondda Cynon Taf",
    c_c: "GB",
  },
  {
    i_c: "RDB",
    name: "London Borough of Redbridge",
    c_c: "GB",
  },
  {
    i_c: "RDG",
    name: "Reading",
    c_c: "GB",
  },
  {
    i_c: "RFW",
    name: "Renfrewshire",
    c_c: "GB",
  },
  {
    i_c: "RIC",
    name: "London Borough of Richmond upon Thames",
    c_c: "GB",
  },
  {
    i_c: "ROT",
    name: "Rotherham",
    c_c: "GB",
  },
  {
    i_c: "RUT",
    name: "Rutland",
    c_c: "GB",
  },
  {
    i_c: "SAW",
    name: "Sandwell",
    c_c: "GB",
  },
  {
    i_c: "SAY",
    name: "South Ayrshire",
    c_c: "GB",
  },
  {
    i_c: "SCB",
    name: "Scottish Borders",
    c_c: "GB",
  },
  {
    i_c: "SCT",
    name: "Scotland",
    c_c: "GB",
  },
  {
    i_c: "SFK",
    name: "Suffolk",
    c_c: "GB",
  },
  {
    i_c: "SFT",
    name: "Sefton",
    c_c: "GB",
  },
  {
    i_c: "SGC",
    name: "South Gloucestershire",
    c_c: "GB",
  },
  {
    i_c: "SH-AC",
    name: "Ascension Island",
    c_c: "GB",
  },
  {
    i_c: "SH-HL",
    name: "Saint Helena",
    c_c: "GB",
  },
  {
    i_c: "SHF",
    name: "Sheffield",
    c_c: "GB",
  },
  {
    i_c: "SHN",
    name: "St Helens",
    c_c: "GB",
  },
  {
    i_c: "SHR",
    name: "Shropshire",
    c_c: "GB",
  },
  {
    i_c: "SKP",
    name: "Stockport",
    c_c: "GB",
  },
  {
    i_c: "SLF",
    name: "Salford",
    c_c: "GB",
  },
  {
    i_c: "SLG",
    name: "Slough",
    c_c: "GB",
  },
  {
    i_c: "SLK",
    name: "South Lanarkshire",
    c_c: "GB",
  },
  {
    i_c: "SND",
    name: "City of Sunderland",
    c_c: "GB",
  },
  {
    i_c: "SOL",
    name: "Solihull",
    c_c: "GB",
  },
  {
    i_c: "SOM",
    name: "Somerset",
    c_c: "GB",
  },
  {
    i_c: "SOS",
    name: "Southend-on-Sea",
    c_c: "GB",
  },
  {
    i_c: "SRY",
    name: "Surrey",
    c_c: "GB",
  },
  {
    i_c: "STB",
    name: "Strabane District Council",
    c_c: "GB",
  },
  {
    i_c: "STE",
    name: "City of Stoke-on-Trent",
    c_c: "GB",
  },
  {
    i_c: "STG",
    name: "Stirling",
    c_c: "GB",
  },
  {
    i_c: "STH",
    name: "City of Southampton",
    c_c: "GB",
  },
  {
    i_c: "STN",
    name: "London Borough of Sutton",
    c_c: "GB",
  },
  {
    i_c: "STS",
    name: "Staffordshire",
    c_c: "GB",
  },
  {
    i_c: "STT",
    name: "Stockton-on-Tees",
    c_c: "GB",
  },
  {
    i_c: "STY",
    name: "South Tyneside",
    c_c: "GB",
  },
  {
    i_c: "SWA",
    name: "City and County of Swansea",
    c_c: "GB",
  },
  {
    i_c: "SWD",
    name: "Swindon",
    c_c: "GB",
  },
  {
    i_c: "SWK",
    name: "London Borough of Southwark",
    c_c: "GB",
  },
  {
    i_c: "TAM",
    name: "Tameside",
    c_c: "GB",
  },
  {
    i_c: "TFW",
    name: "Telford and Wrekin",
    c_c: "GB",
  },
  {
    i_c: "THR",
    name: "Thurrock",
    c_c: "GB",
  },
  {
    i_c: "TOB",
    name: "Torbay",
    c_c: "GB",
  },
  {
    i_c: "TOF",
    name: "Torfaen",
    c_c: "GB",
  },
  {
    i_c: "TRF",
    name: "Trafford",
    c_c: "GB",
  },
  {
    i_c: "TWH",
    name: "London Borough of Tower Hamlets",
    c_c: "GB",
  },
  {
    i_c: "UKM",
    name: "United Kingdom",
    c_c: "GB",
  },
  {
    i_c: "VGL",
    name: "Vale of Glamorgan",
    c_c: "GB",
  },
  {
    i_c: "WAR",
    name: "Warwickshire",
    c_c: "GB",
  },
  {
    i_c: "WBK",
    name: "West Berkshire",
    c_c: "GB",
  },
  {
    i_c: "WDU",
    name: "West Dunbartonshire",
    c_c: "GB",
  },
  {
    i_c: "WFT",
    name: "London Borough of Waltham Forest",
    c_c: "GB",
  },
  {
    i_c: "WGN",
    name: "Metropolitan Borough of Wigan",
    c_c: "GB",
  },
  {
    i_c: "WIL",
    name: "Wiltshire",
    c_c: "GB",
  },
  {
    i_c: "WKF",
    name: "Wakefield",
    c_c: "GB",
  },
  {
    i_c: "WLL",
    name: "Walsall",
    c_c: "GB",
  },
  {
    i_c: "WLN",
    name: "West Lothian",
    c_c: "GB",
  },
  {
    i_c: "WLS",
    name: "Wales",
    c_c: "GB",
  },
  {
    i_c: "WLV",
    name: "City of Wolverhampton",
    c_c: "GB",
  },
  {
    i_c: "WND",
    name: "London Borough of Wandsworth",
    c_c: "GB",
  },
  {
    i_c: "WNM",
    name: "Windsor and Maidenhead",
    c_c: "GB",
  },
  {
    i_c: "WOK",
    name: "Wokingham",
    c_c: "GB",
  },
  {
    i_c: "WOR",
    name: "Worcestershire",
    c_c: "GB",
  },
  {
    i_c: "WRL",
    name: "Wirral",
    c_c: "GB",
  },
  {
    i_c: "WRT",
    name: "Warrington",
    c_c: "GB",
  },
  {
    i_c: "WRX",
    name: "Wrexham County Borough",
    c_c: "GB",
  },
  {
    i_c: "WSM",
    name: "City of Westminster",
    c_c: "GB",
  },
  {
    i_c: "WSX",
    name: "West Sussex",
    c_c: "GB",
  },
  {
    i_c: "YOR",
    name: "City of York",
    c_c: "GB",
  },
  {
    i_c: "ZET",
    name: "Shetland Islands",
    c_c: "GB",
  },
  {
    i_c: "01",
    name: "Saint Andrew Parish",
    c_c: "GD",
  },
  {
    i_c: "02",
    name: "Saint David Parish",
    c_c: "GD",
  },
  {
    i_c: "03",
    name: "Saint George Parish",
    c_c: "GD",
  },
  {
    i_c: "04",
    name: "Saint John Parish",
    c_c: "GD",
  },
  {
    i_c: "05",
    name: "Saint Mark Parish",
    c_c: "GD",
  },
  {
    i_c: "06",
    name: "Saint Patrick Parish",
    c_c: "GD",
  },
  {
    i_c: "10",
    name: "Carriacou and Petite Martinique",
    c_c: "GD",
  },
  {
    i_c: "29",
    name: "Khelvachauri Municipality",
    c_c: "GE",
  },
  {
    i_c: "50",
    name: "Senaki Municipality",
    c_c: "GE",
  },
  {
    i_c: "AB",
    name: "Autonomous Republic of Abkhazia",
    c_c: "GE",
  },
  {
    i_c: "AJ",
    name: "Adjara",
    c_c: "GE",
  },
  {
    i_c: "GU",
    name: "Guria",
    c_c: "GE",
  },
  {
    i_c: "IM",
    name: "Imereti",
    c_c: "GE",
  },
  {
    i_c: "KA",
    name: "Kakheti",
    c_c: "GE",
  },
  {
    i_c: "KK",
    name: "Kvemo Kartli",
    c_c: "GE",
  },
  {
    i_c: "MM",
    name: "Mtskheta-Mtianeti",
    c_c: "GE",
  },
  {
    i_c: "RL",
    name: "Racha-Lechkhumi and Kvemo Svaneti",
    c_c: "GE",
  },
  {
    i_c: "SJ",
    name: "Samtskhe-Javakheti",
    c_c: "GE",
  },
  {
    i_c: "SK",
    name: "Shida Kartli",
    c_c: "GE",
  },
  {
    i_c: "SZ",
    name: "Samegrelo-Zemo Svaneti",
    c_c: "GE",
  },
  {
    i_c: "TB",
    name: "Tbilisi",
    c_c: "GE",
  },
  {
    i_c: "AA",
    name: "Greater Accra",
    c_c: "GH",
  },
  {
    i_c: "AF",
    name: "Ahafo",
    c_c: "GH",
  },
  {
    i_c: "AH",
    name: "Ashanti",
    c_c: "GH",
  },
  {
    i_c: "BE",
    name: "Bono East",
    c_c: "GH",
  },
  {
    i_c: "BO",
    name: "Bono",
    c_c: "GH",
  },
  {
    i_c: "CP",
    name: "Central",
    c_c: "GH",
  },
  {
    i_c: "EP",
    name: "Eastern",
    c_c: "GH",
  },
  {
    i_c: "NE",
    name: "North East",
    c_c: "GH",
  },
  {
    i_c: "NP",
    name: "Northern",
    c_c: "GH",
  },
  {
    i_c: "OT",
    name: "Oti",
    c_c: "GH",
  },
  {
    i_c: "SV",
    name: "Savannah",
    c_c: "GH",
  },
  {
    i_c: "TV",
    name: "Volta",
    c_c: "GH",
  },
  {
    i_c: "UE",
    name: "Upper East",
    c_c: "GH",
  },
  {
    i_c: "UW",
    name: "Upper West",
    c_c: "GH",
  },
  {
    i_c: "WN",
    name: "Western North",
    c_c: "GH",
  },
  {
    i_c: "WP",
    name: "Western",
    c_c: "GH",
  },
  {
    i_c: "B",
    name: "Banjul",
    c_c: "GM",
  },
  {
    i_c: "L",
    name: "Lower River Division",
    c_c: "GM",
  },
  {
    i_c: "M",
    name: "Central River Division",
    c_c: "GM",
  },
  {
    i_c: "N",
    name: "North Bank Division",
    c_c: "GM",
  },
  {
    i_c: "U",
    name: "Upper River Division",
    c_c: "GM",
  },
  {
    i_c: "W",
    name: "West Coast Division",
    c_c: "GM",
  },
  {
    i_c: "B",
    name: "Boké Region",
    c_c: "GN",
  },
  {
    i_c: "BE",
    name: "Beyla Prefecture",
    c_c: "GN",
  },
  {
    i_c: "BF",
    name: "Boffa Prefecture",
    c_c: "GN",
  },
  {
    i_c: "BK",
    name: "Boké Prefecture",
    c_c: "GN",
  },
  {
    i_c: "C",
    name: "Conakry",
    c_c: "GN",
  },
  {
    i_c: "CO",
    name: "Coyah Prefecture",
    c_c: "GN",
  },
  {
    i_c: "D",
    name: "Kindia Region",
    c_c: "GN",
  },
  {
    i_c: "DB",
    name: "Dabola Prefecture",
    c_c: "GN",
  },
  {
    i_c: "DI",
    name: "Dinguiraye Prefecture",
    c_c: "GN",
  },
  {
    i_c: "DL",
    name: "Dalaba Prefecture",
    c_c: "GN",
  },
  {
    i_c: "DU",
    name: "Dubréka Prefecture",
    c_c: "GN",
  },
  {
    i_c: "FA",
    name: "Faranah Prefecture",
    c_c: "GN",
  },
  {
    i_c: "FO",
    name: "Forécariah Prefecture",
    c_c: "GN",
  },
  {
    i_c: "FR",
    name: "Fria Prefecture",
    c_c: "GN",
  },
  {
    i_c: "GA",
    name: "Gaoual Prefecture",
    c_c: "GN",
  },
  {
    i_c: "GU",
    name: "Guéckédou Prefecture",
    c_c: "GN",
  },
  {
    i_c: "K",
    name: "Kankan Region",
    c_c: "GN",
  },
  {
    i_c: "KA",
    name: "Kankan Prefecture",
    c_c: "GN",
  },
  {
    i_c: "KB",
    name: "Koubia Prefecture",
    c_c: "GN",
  },
  {
    i_c: "KD",
    name: "Kindia Prefecture",
    c_c: "GN",
  },
  {
    i_c: "KE",
    name: "Kérouané Prefecture",
    c_c: "GN",
  },
  {
    i_c: "KN",
    name: "Koundara Prefecture",
    c_c: "GN",
  },
  {
    i_c: "KO",
    name: "Kouroussa Prefecture",
    c_c: "GN",
  },
  {
    i_c: "KS",
    name: "Kissidougou Prefecture",
    c_c: "GN",
  },
  {
    i_c: "L",
    name: "Labé Region",
    c_c: "GN",
  },
  {
    i_c: "LA",
    name: "Labé Prefecture",
    c_c: "GN",
  },
  {
    i_c: "LE",
    name: "Lélouma Prefecture",
    c_c: "GN",
  },
  {
    i_c: "LO",
    name: "Lola Prefecture",
    c_c: "GN",
  },
  {
    i_c: "M",
    name: "Mamou Region",
    c_c: "GN",
  },
  {
    i_c: "MC",
    name: "Macenta Prefecture",
    c_c: "GN",
  },
  {
    i_c: "MD",
    name: "Mandiana Prefecture",
    c_c: "GN",
  },
  {
    i_c: "ML",
    name: "Mali Prefecture",
    c_c: "GN",
  },
  {
    i_c: "MM",
    name: "Mamou Prefecture",
    c_c: "GN",
  },
  {
    i_c: "N",
    name: "Nzérékoré Region",
    c_c: "GN",
  },
  {
    i_c: "NZ",
    name: "Nzérékoré Prefecture",
    c_c: "GN",
  },
  {
    i_c: "PI",
    name: "Pita Prefecture",
    c_c: "GN",
  },
  {
    i_c: "SI",
    name: "Siguiri Prefecture",
    c_c: "GN",
  },
  {
    i_c: "TE",
    name: "Télimélé Prefecture",
    c_c: "GN",
  },
  {
    i_c: "TO",
    name: "Tougué Prefecture",
    c_c: "GN",
  },
  {
    i_c: "YO",
    name: "Yomou Prefecture",
    c_c: "GN",
  },
  {
    i_c: "AN",
    name: "Annobón Province",
    c_c: "GQ",
  },
  {
    i_c: "BN",
    name: "Bioko Norte Province",
    c_c: "GQ",
  },
  {
    i_c: "BS",
    name: "Bioko Sur Province",
    c_c: "GQ",
  },
  {
    i_c: "C",
    name: "Río Muni",
    c_c: "GQ",
  },
  {
    i_c: "CS",
    name: "Centro Sur Province",
    c_c: "GQ",
  },
  {
    i_c: "I",
    name: "Insular Region",
    c_c: "GQ",
  },
  {
    i_c: "KN",
    name: "Kié-Ntem Province",
    c_c: "GQ",
  },
  {
    i_c: "LI",
    name: "Litoral Province",
    c_c: "GQ",
  },
  {
    i_c: "WN",
    name: "Wele-Nzas Province",
    c_c: "GQ",
  },
  {
    i_c: "01",
    name: "Aetolia-Acarnania Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "03",
    name: "Boeotia Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "04",
    name: "Euboea",
    c_c: "GR",
  },
  {
    i_c: "06",
    name: "Phthiotis Prefecture",
    c_c: "GR",
  },
  {
    i_c: "11",
    name: "Argolis Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "12",
    name: "Arcadia Prefecture",
    c_c: "GR",
  },
  {
    i_c: "13",
    name: "Achaea Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "15",
    name: "Corinthia Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "16",
    name: "Laconia",
    c_c: "GR",
  },
  {
    i_c: "22",
    name: "Corfu Prefecture",
    c_c: "GR",
  },
  {
    i_c: "23",
    name: "Kefalonia Prefecture",
    c_c: "GR",
  },
  {
    i_c: "24",
    name: "Lefkada Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "33",
    name: "Ioannina Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "34",
    name: "Preveza Prefecture",
    c_c: "GR",
  },
  {
    i_c: "41",
    name: "Karditsa Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "42",
    name: "Larissa Prefecture",
    c_c: "GR",
  },
  {
    i_c: "51",
    name: "Grevena Prefecture",
    c_c: "GR",
  },
  {
    i_c: "52",
    name: "Drama Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "53",
    name: "Imathia Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "54",
    name: "Thessaloniki Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "56",
    name: "Kastoria Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "57",
    name: "Kilkis Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "58",
    name: "Kozani Prefecture",
    c_c: "GR",
  },
  {
    i_c: "59",
    name: "Pella Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "62",
    name: "Serres Prefecture",
    c_c: "GR",
  },
  {
    i_c: "94",
    name: "Chania Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "A",
    name: "East Macedonia and Thrace",
    c_c: "GR",
  },
  {
    i_c: "A2",
    name: "East Attica Regional Unit",
    c_c: "GR",
  },
  {
    i_c: "B",
    name: "Central Macedonia",
    c_c: "GR",
  },
  {
    i_c: "C",
    name: "West Macedonia Region",
    c_c: "GR",
  },
  {
    i_c: "D",
    name: "Epirus Region",
    c_c: "GR",
  },
  {
    i_c: "F",
    name: "Ionian Islands Region",
    c_c: "GR",
  },
  {
    i_c: "G",
    name: "West Greece Region",
    c_c: "GR",
  },
  {
    i_c: "H",
    name: "Central Greece Region",
    c_c: "GR",
  },
  {
    i_c: "I",
    name: "Attica Region",
    c_c: "GR",
  },
  {
    i_c: "J",
    name: "Peloponnese Region",
    c_c: "GR",
  },
  {
    i_c: "L",
    name: "South Aegean",
    c_c: "GR",
  },
  {
    i_c: "M",
    name: "Crete Region",
    c_c: "GR",
  },
  {
    i_c: "AV",
    name: "Alta Verapaz Department",
    c_c: "GT",
  },
  {
    i_c: "BV",
    name: "Baja Verapaz Department",
    c_c: "GT",
  },
  {
    i_c: "CM",
    name: "Chimaltenango Department",
    c_c: "GT",
  },
  {
    i_c: "CQ",
    name: "Chiquimula Department",
    c_c: "GT",
  },
  {
    i_c: "ES",
    name: "Escuintla Department",
    c_c: "GT",
  },
  {
    i_c: "GU",
    name: "Guatemala Department",
    c_c: "GT",
  },
  {
    i_c: "HU",
    name: "Huehuetenango Department",
    c_c: "GT",
  },
  {
    i_c: "IZ",
    name: "Izabal Department",
    c_c: "GT",
  },
  {
    i_c: "JA",
    name: "Jalapa Department",
    c_c: "GT",
  },
  {
    i_c: "JU",
    name: "Jutiapa Department",
    c_c: "GT",
  },
  {
    i_c: "PE",
    name: "Petén Department",
    c_c: "GT",
  },
  {
    i_c: "PR",
    name: "El Progreso Department",
    c_c: "GT",
  },
  {
    i_c: "QC",
    name: "Quiché Department",
    c_c: "GT",
  },
  {
    i_c: "QZ",
    name: "Quetzaltenango Department",
    c_c: "GT",
  },
  {
    i_c: "RE",
    name: "Retalhuleu Department",
    c_c: "GT",
  },
  {
    i_c: "SA",
    name: "Sacatepéquez Department",
    c_c: "GT",
  },
  {
    i_c: "SM",
    name: "San Marcos Department",
    c_c: "GT",
  },
  {
    i_c: "SO",
    name: "Sololá Department",
    c_c: "GT",
  },
  {
    i_c: "SR",
    name: "Santa Rosa Department",
    c_c: "GT",
  },
  {
    i_c: "SU",
    name: "Suchitepéquez Department",
    c_c: "GT",
  },
  {
    i_c: "TO",
    name: "Totonicapán Department",
    c_c: "GT",
  },
  {
    i_c: "BA",
    name: "Bafatá",
    c_c: "GW",
  },
  {
    i_c: "BL",
    name: "Bolama Region",
    c_c: "GW",
  },
  {
    i_c: "BM",
    name: "Biombo Region",
    c_c: "GW",
  },
  {
    i_c: "CA",
    name: "Cacheu Region",
    c_c: "GW",
  },
  {
    i_c: "GA",
    name: "Gabú Region",
    c_c: "GW",
  },
  {
    i_c: "L",
    name: "Leste Province",
    c_c: "GW",
  },
  {
    i_c: "N",
    name: "Norte Province",
    c_c: "GW",
  },
  {
    i_c: "OI",
    name: "Oio Region",
    c_c: "GW",
  },
  {
    i_c: "QU",
    name: "Quinara Region",
    c_c: "GW",
  },
  {
    i_c: "S",
    name: "Sul Province",
    c_c: "GW",
  },
  {
    i_c: "TO",
    name: "Tombali Region",
    c_c: "GW",
  },
  {
    i_c: "BA",
    name: "Barima-Waini",
    c_c: "GY",
  },
  {
    i_c: "CU",
    name: "Cuyuni-Mazaruni",
    c_c: "GY",
  },
  {
    i_c: "DE",
    name: "Demerara-Mahaica",
    c_c: "GY",
  },
  {
    i_c: "EB",
    name: "East Berbice-Corentyne",
    c_c: "GY",
  },
  {
    i_c: "ES",
    name: "Essequibo Islands-West Demerara",
    c_c: "GY",
  },
  {
    i_c: "MA",
    name: "Mahaica-Berbice",
    c_c: "GY",
  },
  {
    i_c: "PM",
    name: "Pomeroon-Supenaam",
    c_c: "GY",
  },
  {
    i_c: "PT",
    name: "Potaro-Siparuni",
    c_c: "GY",
  },
  {
    i_c: "UD",
    name: "Upper Demerara-Berbice",
    c_c: "GY",
  },
  {
    i_c: "UT",
    name: "Upper Takutu-Upper Essequibo",
    c_c: "GY",
  },
  {
    i_c: "HCW",
    name: "Central and Western District",
    c_c: "HK",
  },
  {
    i_c: "HEA",
    name: "Eastern",
    c_c: "HK",
  },
  {
    i_c: "HSO",
    name: "Southern",
    c_c: "HK",
  },
  {
    i_c: "HWC",
    name: "Wan Chai",
    c_c: "HK",
  },
  {
    i_c: "KKC",
    name: "Kowloon City",
    c_c: "HK",
  },
  {
    i_c: "KKT",
    name: "Kwun Tong",
    c_c: "HK",
  },
  {
    i_c: "KSS",
    name: "Sham Shui Po",
    c_c: "HK",
  },
  {
    i_c: "KWT",
    name: "Wong Tai Sin",
    c_c: "HK",
  },
  {
    i_c: "KYT",
    name: "Yau Tsim Mong",
    c_c: "HK",
  },
  {
    i_c: "NIS",
    name: "Islands District",
    c_c: "HK",
  },
  {
    i_c: "NKT",
    name: "Kwai Tsing",
    c_c: "HK",
  },
  {
    i_c: "NNO",
    name: "North",
    c_c: "HK",
  },
  {
    i_c: "NSK",
    name: "Sai Kung District",
    c_c: "HK",
  },
  {
    i_c: "NST",
    name: "Sha Tin",
    c_c: "HK",
  },
  {
    i_c: "NTM",
    name: "Tuen Mun",
    c_c: "HK",
  },
  {
    i_c: "NTW",
    name: "Tsuen Wan District",
    c_c: "HK",
  },
  {
    i_c: "NYL",
    name: "Yuen Long District",
    c_c: "HK",
  },
  {
    i_c: "AT",
    name: "Atlántida Department",
    c_c: "HN",
  },
  {
    i_c: "CH",
    name: "Choluteca Department",
    c_c: "HN",
  },
  {
    i_c: "CL",
    name: "Colón Department",
    c_c: "HN",
  },
  {
    i_c: "CM",
    name: "Comayagua Department",
    c_c: "HN",
  },
  {
    i_c: "CP",
    name: "Copán Department",
    c_c: "HN",
  },
  {
    i_c: "CR",
    name: "Cortés Department",
    c_c: "HN",
  },
  {
    i_c: "EP",
    name: "El Paraíso Department",
    c_c: "HN",
  },
  {
    i_c: "FM",
    name: "Francisco Morazán Department",
    c_c: "HN",
  },
  {
    i_c: "GD",
    name: "Gracias a Dios Department",
    c_c: "HN",
  },
  {
    i_c: "IB",
    name: "Bay Islands Department",
    c_c: "HN",
  },
  {
    i_c: "IN",
    name: "Intibucá Department",
    c_c: "HN",
  },
  {
    i_c: "LE",
    name: "Lempira Department",
    c_c: "HN",
  },
  {
    i_c: "LP",
    name: "La Paz Department",
    c_c: "HN",
  },
  {
    i_c: "OC",
    name: "Ocotepeque Department",
    c_c: "HN",
  },
  {
    i_c: "OL",
    name: "Olancho Department",
    c_c: "HN",
  },
  {
    i_c: "SB",
    name: "Santa Bárbara Department",
    c_c: "HN",
  },
  {
    i_c: "VA",
    name: "Valle Department",
    c_c: "HN",
  },
  {
    i_c: "YO",
    name: "Yoro Department",
    c_c: "HN",
  },
  {
    i_c: "01",
    name: "Zagreb County",
    c_c: "HR",
  },
  {
    i_c: "02",
    name: "Krapina-Zagorje County",
    c_c: "HR",
  },
  {
    i_c: "03",
    name: "Sisak-Moslavina County",
    c_c: "HR",
  },
  {
    i_c: "05",
    name: "Karlovac County",
    c_c: "HR",
  },
  {
    i_c: "06",
    name: "Koprivnica-Križevci County",
    c_c: "HR",
  },
  {
    i_c: "07",
    name: "Bjelovar-Bilogora County",
    c_c: "HR",
  },
  {
    i_c: "08",
    name: "Primorje-Gorski Kotar County",
    c_c: "HR",
  },
  {
    i_c: "09",
    name: "Lika-Senj County",
    c_c: "HR",
  },
  {
    i_c: "10",
    name: "Virovitica-Podravina County",
    c_c: "HR",
  },
  {
    i_c: "11",
    name: "Požega-Slavonia County",
    c_c: "HR",
  },
  {
    i_c: "12",
    name: "Brod-Posavina County",
    c_c: "HR",
  },
  {
    i_c: "13",
    name: "Zadar County",
    c_c: "HR",
  },
  {
    i_c: "14",
    name: "Osijek-Baranja County",
    c_c: "HR",
  },
  {
    i_c: "15",
    name: "Šibenik-Knin County",
    c_c: "HR",
  },
  {
    i_c: "16",
    name: "Vukovar-Syrmia County",
    c_c: "HR",
  },
  {
    i_c: "17",
    name: "Split-Dalmatia County",
    c_c: "HR",
  },
  {
    i_c: "18",
    name: "Istria County",
    c_c: "HR",
  },
  {
    i_c: "19",
    name: "Dubrovnik-Neretva County",
    c_c: "HR",
  },
  {
    i_c: "20",
    name: "Međimurje County",
    c_c: "HR",
  },
  {
    i_c: "21",
    name: "Zagreb",
    c_c: "HR",
  },
  {
    i_c: "AR",
    name: "Artibonite",
    c_c: "HT",
  },
  {
    i_c: "CE",
    name: "Centre",
    c_c: "HT",
  },
  {
    i_c: "GA",
    name: "Grand'Anse",
    c_c: "HT",
  },
  {
    i_c: "ND",
    name: "Nord",
    c_c: "HT",
  },
  {
    i_c: "NE",
    name: "Nord-Est",
    c_c: "HT",
  },
  {
    i_c: "NI",
    name: "Nippes",
    c_c: "HT",
  },
  {
    i_c: "NO",
    name: "Nord-Ouest",
    c_c: "HT",
  },
  {
    i_c: "OU",
    name: "Ouest",
    c_c: "HT",
  },
  {
    i_c: "SD",
    name: "Sud",
    c_c: "HT",
  },
  {
    i_c: "SE",
    name: "Sud-Est",
    c_c: "HT",
  },
  {
    i_c: "BA",
    name: "Baranya County",
    c_c: "HU",
  },
  {
    i_c: "BC",
    name: "Békéscsaba",
    c_c: "HU",
  },
  {
    i_c: "BE",
    name: "Békés County",
    c_c: "HU",
  },
  {
    i_c: "BK",
    name: "Bács-Kiskun County",
    c_c: "HU",
  },
  {
    i_c: "BU",
    name: "Budapest",
    c_c: "HU",
  },
  {
    i_c: "BZ",
    name: "Borsod-Abaúj-Zemplén County",
    c_c: "HU",
  },
  {
    i_c: "CS",
    name: "Csongrád County",
    c_c: "HU",
  },
  {
    i_c: "DE",
    name: "Debrecen",
    c_c: "HU",
  },
  {
    i_c: "DU",
    name: "Dunaújváros",
    c_c: "HU",
  },
  {
    i_c: "EG",
    name: "Eger",
    c_c: "HU",
  },
  {
    i_c: "ER",
    name: "Érd",
    c_c: "HU",
  },
  {
    i_c: "FE",
    name: "Fejér County",
    c_c: "HU",
  },
  {
    i_c: "GS",
    name: "Győr-Moson-Sopron County",
    c_c: "HU",
  },
  {
    i_c: "GY",
    name: "Győr",
    c_c: "HU",
  },
  {
    i_c: "HB",
    name: "Hajdú-Bihar County",
    c_c: "HU",
  },
  {
    i_c: "HE",
    name: "Heves County",
    c_c: "HU",
  },
  {
    i_c: "HV",
    name: "Hódmezővásárhely",
    c_c: "HU",
  },
  {
    i_c: "JN",
    name: "Jász-Nagykun-Szolnok County",
    c_c: "HU",
  },
  {
    i_c: "KM",
    name: "Kecskemét",
    c_c: "HU",
  },
  {
    i_c: "KV",
    name: "Kaposvár",
    c_c: "HU",
  },
  {
    i_c: "MI",
    name: "Miskolc",
    c_c: "HU",
  },
  {
    i_c: "NK",
    name: "Nagykanizsa",
    c_c: "HU",
  },
  {
    i_c: "NO",
    name: "Nógrád County",
    c_c: "HU",
  },
  {
    i_c: "NY",
    name: "Nyíregyháza",
    c_c: "HU",
  },
  {
    i_c: "PE",
    name: "Pest County",
    c_c: "HU",
  },
  {
    i_c: "PS",
    name: "Pécs",
    c_c: "HU",
  },
  {
    i_c: "SD",
    name: "Szeged",
    c_c: "HU",
  },
  {
    i_c: "SF",
    name: "Székesfehérvár",
    c_c: "HU",
  },
  {
    i_c: "SH",
    name: "Szombathely",
    c_c: "HU",
  },
  {
    i_c: "SK",
    name: "Szolnok",
    c_c: "HU",
  },
  {
    i_c: "SN",
    name: "Sopron",
    c_c: "HU",
  },
  {
    i_c: "SO",
    name: "Somogy County",
    c_c: "HU",
  },
  {
    i_c: "SS",
    name: "Szekszárd",
    c_c: "HU",
  },
  {
    i_c: "ST",
    name: "Salgótarján",
    c_c: "HU",
  },
  {
    i_c: "SZ",
    name: "Szabolcs-Szatmár-Bereg County",
    c_c: "HU",
  },
  {
    i_c: "TB",
    name: "Tatabánya",
    c_c: "HU",
  },
  {
    i_c: "TO",
    name: "Tolna County",
    c_c: "HU",
  },
  {
    i_c: "VA",
    name: "Vas County",
    c_c: "HU",
  },
  {
    i_c: "VE",
    name: "Veszprém County",
    c_c: "HU",
  },
  {
    i_c: "VM",
    name: "Veszprém",
    c_c: "HU",
  },
  {
    i_c: "ZA",
    name: "Zala County",
    c_c: "HU",
  },
  {
    i_c: "ZE",
    name: "Zalaegerszeg",
    c_c: "HU",
  },
  {
    i_c: "AC",
    name: "Aceh",
    c_c: "ID",
  },
  {
    i_c: "BA",
    name: "Bali",
    c_c: "ID",
  },
  {
    i_c: "BB",
    name: "Kepulauan Bangka Belitung",
    c_c: "ID",
  },
  {
    i_c: "BE",
    name: "Bengkulu",
    c_c: "ID",
  },
  {
    i_c: "BT",
    name: "Banten",
    c_c: "ID",
  },
  {
    i_c: "GO",
    name: "Gorontalo",
    c_c: "ID",
  },
  {
    i_c: "JA",
    name: "Jambi",
    c_c: "ID",
  },
  {
    i_c: "JB",
    name: "Jawa Barat",
    c_c: "ID",
  },
  {
    i_c: "JI",
    name: "Jawa Timur",
    c_c: "ID",
  },
  {
    i_c: "JK",
    name: "DKI Jakarta",
    c_c: "ID",
  },
  {
    i_c: "JT",
    name: "Jawa Tengah",
    c_c: "ID",
  },
  {
    i_c: "KA",
    name: "Kalimantan Barat",
    c_c: "ID",
  },
  {
    i_c: "KI",
    name: "Kalimantan Timur",
    c_c: "ID",
  },
  {
    i_c: "KR",
    name: "Kepulauan Riau",
    c_c: "ID",
  },
  {
    i_c: "KS",
    name: "Kalimantan Selatan",
    c_c: "ID",
  },
  {
    i_c: "KT",
    name: "Kalimantan Tengah",
    c_c: "ID",
  },
  {
    i_c: "KU",
    name: "Kalimantan Utara",
    c_c: "ID",
  },
  {
    i_c: "LA",
    name: "Lampung",
    c_c: "ID",
  },
  {
    i_c: "MA",
    name: "Maluku",
    c_c: "ID",
  },
  {
    i_c: "MU",
    name: "Maluku Utara",
    c_c: "ID",
  },
  {
    i_c: "NB",
    name: "Nusa Tenggara Barat",
    c_c: "ID",
  },
  {
    i_c: "NT",
    name: "Nusa Tenggara Timur",
    c_c: "ID",
  },
  {
    i_c: "PA",
    name: "Papua",
    c_c: "ID",
  },
  {
    i_c: "PB",
    name: "Papua Barat",
    c_c: "ID",
  },
  {
    i_c: "RI",
    name: "Riau",
    c_c: "ID",
  },
  {
    i_c: "SA",
    name: "Sulawesi Utara",
    c_c: "ID",
  },
  {
    i_c: "SB",
    name: "Sumatera Barat",
    c_c: "ID",
  },
  {
    i_c: "SG",
    name: "Sulawesi Tenggara",
    c_c: "ID",
  },
  {
    i_c: "SN",
    name: "Sulawesi Selatan",
    c_c: "ID",
  },
  {
    i_c: "SR",
    name: "Sulawesi Barat",
    c_c: "ID",
  },
  {
    i_c: "SS",
    name: "Sumatera Selatan",
    c_c: "ID",
  },
  {
    i_c: "ST",
    name: "Sulawesi Tengah",
    c_c: "ID",
  },
  {
    i_c: "SU",
    name: "Sumatera Utara",
    c_c: "ID",
  },
  {
    i_c: "YO",
    name: "DI Yogyakarta",
    c_c: "ID",
  },
  {
    i_c: "C",
    name: "Connacht",
    c_c: "IE",
  },
  {
    i_c: "CE",
    name: "Clare",
    c_c: "IE",
  },
  {
    i_c: "CN",
    name: "Cavan",
    c_c: "IE",
  },
  {
    i_c: "CO",
    name: "Cork",
    c_c: "IE",
  },
  {
    i_c: "CW",
    name: "Carlow",
    c_c: "IE",
  },
  {
    i_c: "D",
    name: "Dublin",
    c_c: "IE",
  },
  {
    i_c: "DL",
    name: "Donegal",
    c_c: "IE",
  },
  {
    i_c: "G",
    name: "Galway",
    c_c: "IE",
  },
  {
    i_c: "KE",
    name: "Kildare",
    c_c: "IE",
  },
  {
    i_c: "KK",
    name: "Kilkenny",
    c_c: "IE",
  },
  {
    i_c: "KY",
    name: "Kerry",
    c_c: "IE",
  },
  {
    i_c: "L",
    name: "Leinster",
    c_c: "IE",
  },
  {
    i_c: "LD",
    name: "Longford",
    c_c: "IE",
  },
  {
    i_c: "LH",
    name: "Louth",
    c_c: "IE",
  },
  {
    i_c: "LK",
    name: "Limerick",
    c_c: "IE",
  },
  {
    i_c: "LS",
    name: "Laois",
    c_c: "IE",
  },
  {
    i_c: "M",
    name: "Munster",
    c_c: "IE",
  },
  {
    i_c: "MH",
    name: "Meath",
    c_c: "IE",
  },
  {
    i_c: "MN",
    name: "Monaghan",
    c_c: "IE",
  },
  {
    i_c: "MO",
    name: "Mayo",
    c_c: "IE",
  },
  {
    i_c: "OY",
    name: "Offaly",
    c_c: "IE",
  },
  {
    i_c: "RN",
    name: "Roscommon",
    c_c: "IE",
  },
  {
    i_c: "SO",
    name: "Sligo",
    c_c: "IE",
  },
  {
    i_c: "TA",
    name: "Tipperary",
    c_c: "IE",
  },
  {
    i_c: "U",
    name: "Ulster",
    c_c: "IE",
  },
  {
    i_c: "WD",
    name: "Waterford",
    c_c: "IE",
  },
  {
    i_c: "WH",
    name: "Westmeath",
    c_c: "IE",
  },
  {
    i_c: "WW",
    name: "Wicklow",
    c_c: "IE",
  },
  {
    i_c: "WX",
    name: "Wexford",
    c_c: "IE",
  },
  {
    i_c: "D",
    name: "Southern District",
    c_c: "IL",
  },
  {
    i_c: "HA",
    name: "Haifa District",
    c_c: "IL",
  },
  {
    i_c: "JM",
    name: "Jerusalem District",
    c_c: "IL",
  },
  {
    i_c: "M",
    name: "Central District",
    c_c: "IL",
  },
  {
    i_c: "TA",
    name: "Tel Aviv District",
    c_c: "IL",
  },
  {
    i_c: "Z",
    name: "Northern District",
    c_c: "IL",
  },
  {
    i_c: "AN",
    name: "Andaman and Nicobar Islands",
    c_c: "IN",
    invoice_code: 35,
  },
  {
    i_c: "AP",
    name: "Andhra Pradesh",
    c_c: "IN",
    invoice_code: 28,
  },
  {
    i_c: "AR",
    name: "Arunachal Pradesh",
    c_c: "IN",
    invoice_code: 12,
  },
  {
    i_c: "AS",
    name: "Assam",
    c_c: "IN",
    invoice_code: 18,
  },
  {
    i_c: "BR",
    name: "Bihar",
    c_c: "IN",
    invoice_code: 10,
  },
  {
    i_c: "CH",
    name: "Chandigarh",
    c_c: "IN",
    invoice_code: 4,
  },
  {
    i_c: "CT",
    name: "Chhattisgarh",
    c_c: "IN",
    invoice_code: 22,
  },
  {
    i_c: "DH",
    name: "Dadra and Nagar Haveli and Daman and Diu",
    c_c: "IN",
    invoice_code: 26,
  },
  {
    i_c: "DL",
    name: "Delhi",
    c_c: "IN",
    invoice_code: 7,
  },
  {
    i_c: "GA",
    name: "Goa",
    c_c: "IN",
    invoice_code: 30,
  },
  {
    i_c: "GJ",
    name: "Gujarat",
    c_c: "IN",
    invoice_code: 24,
  },
  {
    i_c: "HP",
    name: "Himachal Pradesh",
    c_c: "IN",
    invoice_code: 2,
  },
  {
    i_c: "HR",
    name: "Haryana",
    c_c: "IN",
    invoice_code: 6,
  },
  {
    i_c: "JH",
    name: "Jharkhand",
    c_c: "IN",
    invoice_code: 20,
  },
  {
    i_c: "JK",
    name: "Jammu and Kashmir",
    c_c: "IN",
    invoice_code: 1,
  },
  {
    i_c: "KA",
    name: "Karnataka",
    c_c: "IN",
    invoice_code: 29,
  },
  {
    i_c: "KL",
    name: "Kerala",
    c_c: "IN",
    invoice_code: 32,
  },
  {
    i_c: "LA",
    name: "Ladakh",
    c_c: "IN",
    invoice_code: 0,
  },
  {
    i_c: "LD",
    name: "Lakshadweep",
    c_c: "IN",
    invoice_code: 31,
  },
  {
    i_c: "MH",
    name: "Maharashtra",
    c_c: "IN",
    invoice_code: 27,
  },
  {
    i_c: "ML",
    name: "Meghalaya",
    c_c: "IN",
    invoice_code: 17,
  },
  {
    i_c: "MN",
    name: "Manipur",
    c_c: "IN",
    invoice_code: 14,
  },
  {
    i_c: "MP",
    name: "Madhya Pradesh",
    c_c: "IN",
    invoice_code: 23,
  },
  {
    i_c: "MZ",
    name: "Mizoram",
    c_c: "IN",
    invoice_code: 15,
  },
  {
    i_c: "NL",
    name: "Nagaland",
    c_c: "IN",
    invoice_code: 13,
  },
  {
    i_c: "OR",
    name: "Odisha",
    c_c: "IN",
    invoice_code: 21,
  },
  {
    i_c: "PB",
    name: "Punjab",
    c_c: "IN",
    invoice_code: 3,
  },
  {
    i_c: "PY",
    name: "Puducherry",
    c_c: "IN",
    invoice_code: 34,
  },
  {
    i_c: "RJ",
    name: "Rajasthan",
    c_c: "IN",
    invoice_code: 8,
  },
  {
    i_c: "SK",
    name: "Sikkim",
    c_c: "IN",
    invoice_code: 11,
  },
  {
    i_c: "TG",
    name: "Telangana",
    c_c: "IN",
    invoice_code: 36,
  },
  {
    i_c: "TN",
    name: "Tamil Nadu",
    c_c: "IN",
    invoice_code: 33,
  },
  {
    i_c: "TR",
    name: "Tripura",
    c_c: "IN",
    invoice_code: 16,
  },
  {
    i_c: "UP",
    name: "Uttar Pradesh",
    c_c: "IN",
    invoice_code: 9,
  },
  {
    i_c: "UT",
    name: "Uttarakhand",
    c_c: "IN",
    invoice_code: 5,
  },
  {
    i_c: "WB",
    name: "West Bengal",
    c_c: "IN",
    invoice_code: 19,
  },
  {
    i_c: "AN",
    name: "Al Anbar Governorate",
    c_c: "IQ",
  },
  {
    i_c: "AR",
    name: "Erbil Governorate",
    c_c: "IQ",
  },
  {
    i_c: "BA",
    name: "Basra Governorate",
    c_c: "IQ",
  },
  {
    i_c: "BB",
    name: "Babylon Governorate",
    c_c: "IQ",
  },
  {
    i_c: "BG",
    name: "Baghdad Governorate",
    c_c: "IQ",
  },
  {
    i_c: "DA",
    name: "Dohuk Governorate",
    c_c: "IQ",
  },
  {
    i_c: "DI",
    name: "Diyala Governorate",
    c_c: "IQ",
  },
  {
    i_c: "DQ",
    name: "Dhi Qar Governorate",
    c_c: "IQ",
  },
  {
    i_c: "KA",
    name: "Karbala Governorate",
    c_c: "IQ",
  },
  {
    i_c: "KI",
    name: "Kirkuk Governorate",
    c_c: "IQ",
  },
  {
    i_c: "MA",
    name: "Maysan Governorate",
    c_c: "IQ",
  },
  {
    i_c: "MU",
    name: "Al Muthanna Governorate",
    c_c: "IQ",
  },
  {
    i_c: "NA",
    name: "Najaf Governorate",
    c_c: "IQ",
  },
  {
    i_c: "NI",
    name: "Nineveh Governorate",
    c_c: "IQ",
  },
  {
    i_c: "QA",
    name: "Al-Qādisiyyah Governorate",
    c_c: "IQ",
  },
  {
    i_c: "SD",
    name: "Saladin Governorate",
    c_c: "IQ",
  },
  {
    i_c: "SU",
    name: "Sulaymaniyah Governorate",
    c_c: "IQ",
  },
  {
    i_c: "WA",
    name: "Wasit Governorate",
    c_c: "IQ",
  },
  {
    i_c: "00",
    name: "Markazi",
    c_c: "IR",
  },
  {
    i_c: "01",
    name: "Gilan",
    c_c: "IR",
  },
  {
    i_c: "02",
    name: "Mazandaran",
    c_c: "IR",
  },
  {
    i_c: "03",
    name: "East Azerbaijan",
    c_c: "IR",
  },
  {
    i_c: "04",
    name: "West Azarbaijan",
    c_c: "IR",
  },
  {
    i_c: "05",
    name: "Kermanshah",
    c_c: "IR",
  },
  {
    i_c: "06",
    name: "Khuzestan",
    c_c: "IR",
  },
  {
    i_c: "07",
    name: "Fars",
    c_c: "IR",
  },
  {
    i_c: "08",
    name: "Kerman",
    c_c: "IR",
  },
  {
    i_c: "09",
    name: "Razavi Khorasan",
    c_c: "IR",
  },
  {
    i_c: "10",
    name: "Isfahan",
    c_c: "IR",
  },
  {
    i_c: "11",
    name: "Sistan and Baluchestan",
    c_c: "IR",
  },
  {
    i_c: "12",
    name: "Kurdistan",
    c_c: "IR",
  },
  {
    i_c: "13",
    name: "Hamadan",
    c_c: "IR",
  },
  {
    i_c: "14",
    name: "Chaharmahal and Bakhtiari",
    c_c: "IR",
  },
  {
    i_c: "15",
    name: "Lorestan",
    c_c: "IR",
  },
  {
    i_c: "16",
    name: "Ilam",
    c_c: "IR",
  },
  {
    i_c: "17",
    name: "Kohgiluyeh and Boyer-Ahmad",
    c_c: "IR",
  },
  {
    i_c: "18",
    name: "Bushehr",
    c_c: "IR",
  },
  {
    i_c: "19",
    name: "Zanjan",
    c_c: "IR",
  },
  {
    i_c: "20",
    name: "Semnan",
    c_c: "IR",
  },
  {
    i_c: "21",
    name: "Yazd",
    c_c: "IR",
  },
  {
    i_c: "22",
    name: "Hormozgan",
    c_c: "IR",
  },
  {
    i_c: "23",
    name: "Tehran",
    c_c: "IR",
  },
  {
    i_c: "24",
    name: "Ardabil",
    c_c: "IR",
  },
  {
    i_c: "25",
    name: "Qom",
    c_c: "IR",
  },
  {
    i_c: "26",
    name: "Qazvin",
    c_c: "IR",
  },
  {
    i_c: "27",
    name: "Golestan",
    c_c: "IR",
  },
  {
    i_c: "28",
    name: "North Khorasan",
    c_c: "IR",
  },
  {
    i_c: "29",
    name: "South Khorasan",
    c_c: "IR",
  },
  {
    i_c: "30",
    name: "Alborz",
    c_c: "IR",
  },
  {
    i_c: "1",
    name: "Capital Region",
    c_c: "IS",
  },
  {
    i_c: "2",
    name: "Southern Peninsula Region",
    c_c: "IS",
  },
  {
    i_c: "3",
    name: "Western Region",
    c_c: "IS",
  },
  {
    i_c: "4",
    name: "Westfjords",
    c_c: "IS",
  },
  {
    i_c: "5",
    name: "Northwestern Region",
    c_c: "IS",
  },
  {
    i_c: "6",
    name: "Northeastern Region",
    c_c: "IS",
  },
  {
    i_c: "7",
    name: "Eastern Region",
    c_c: "IS",
  },
  {
    i_c: "8",
    name: "Southern Region",
    c_c: "IS",
  },
  {
    i_c: "21",
    name: "Piedmont",
    c_c: "IT",
  },
  {
    i_c: "23",
    name: "Aosta Valley",
    c_c: "IT",
  },
  {
    i_c: "25",
    name: "Lombardy",
    c_c: "IT",
  },
  {
    i_c: "32",
    name: "Trentino-South Tyrol",
    c_c: "IT",
  },
  {
    i_c: "34",
    name: "Veneto",
    c_c: "IT",
  },
  {
    i_c: "36",
    name: "Friuli–Venezia Giulia",
    c_c: "IT",
  },
  {
    i_c: "42",
    name: "Liguria",
    c_c: "IT",
  },
  {
    i_c: "45",
    name: "Emilia-Romagna",
    c_c: "IT",
  },
  {
    i_c: "52",
    name: "Tuscany",
    c_c: "IT",
  },
  {
    i_c: "55",
    name: "Umbria",
    c_c: "IT",
  },
  {
    i_c: "57",
    name: "Marche",
    c_c: "IT",
  },
  {
    i_c: "62",
    name: "Lazio",
    c_c: "IT",
  },
  {
    i_c: "65",
    name: "Abruzzo",
    c_c: "IT",
  },
  {
    i_c: "67",
    name: "Molise",
    c_c: "IT",
  },
  {
    i_c: "72",
    name: "Campania",
    c_c: "IT",
  },
  {
    i_c: "75",
    name: "Apulia",
    c_c: "IT",
  },
  {
    i_c: "77",
    name: "Basilicata",
    c_c: "IT",
  },
  {
    i_c: "78",
    name: "Calabria",
    c_c: "IT",
  },
  {
    i_c: "82",
    name: "Sicily",
    c_c: "IT",
  },
  {
    i_c: "88",
    name: "Sardinia",
    c_c: "IT",
  },
  {
    i_c: "AG",
    name: "Libero consorzio comunale di Agrigento",
    c_c: "IT",
  },
  {
    i_c: "AL",
    name: "Province of Alessandria",
    c_c: "IT",
  },
  {
    i_c: "AN",
    name: "Province of Ancona",
    c_c: "IT",
  },
  {
    i_c: "AP",
    name: "Province of Ascoli Piceno",
    c_c: "IT",
  },
  {
    i_c: "AQ",
    name: "Province of L'Aquila",
    c_c: "IT",
  },
  {
    i_c: "AT",
    name: "Province of Asti",
    c_c: "IT",
  },
  {
    i_c: "AV",
    name: "Province of Avellino",
    c_c: "IT",
  },
  {
    i_c: "BA",
    name: "Metropolitan City of Bari",
    c_c: "IT",
  },
  {
    i_c: "BG",
    name: "Province of Bergamo",
    c_c: "IT",
  },
  {
    i_c: "BI",
    name: "Province of Biella",
    c_c: "IT",
  },
  {
    i_c: "BL",
    name: "Province of Belluno",
    c_c: "IT",
  },
  {
    i_c: "BN",
    name: "Benevento Province",
    c_c: "IT",
  },
  {
    i_c: "BO",
    name: "Metropolitan City of Bologna",
    c_c: "IT",
  },
  {
    i_c: "BR",
    name: "Province of Brindisi",
    c_c: "IT",
  },
  {
    i_c: "BS",
    name: "Province of Brescia",
    c_c: "IT",
  },
  {
    i_c: "BT",
    name: "Province of Barletta-Andria-Trani",
    c_c: "IT",
  },
  {
    i_c: "BZ",
    name: "South Tyrol",
    c_c: "IT",
  },
  {
    i_c: "CA",
    name: "Metropolitan City of Cagliari",
    c_c: "IT",
  },
  {
    i_c: "CB",
    name: "Province of Campobasso",
    c_c: "IT",
  },
  {
    i_c: "CE",
    name: "Province of Caserta",
    c_c: "IT",
  },
  {
    i_c: "CH",
    name: "Province of Chieti",
    c_c: "IT",
  },
  {
    i_c: "CI",
    name: "Province of Carbonia-Iglesias",
    c_c: "IT",
  },
  {
    i_c: "CL",
    name: "Libero consorzio comunale di Caltanissetta",
    c_c: "IT",
  },
  {
    i_c: "CN",
    name: "Province of Cuneo",
    c_c: "IT",
  },
  {
    i_c: "CO",
    name: "Province of Como",
    c_c: "IT",
  },
  {
    i_c: "CR",
    name: "Province of Cremona",
    c_c: "IT",
  },
  {
    i_c: "CS",
    name: "Province of Cosenza",
    c_c: "IT",
  },
  {
    i_c: "CT",
    name: "Metropolitan City of Catania",
    c_c: "IT",
  },
  {
    i_c: "CZ",
    name: "Province of Catanzaro",
    c_c: "IT",
  },
  {
    i_c: "EN",
    name: "Libero consorzio comunale di Enna",
    c_c: "IT",
  },
  {
    i_c: "FC",
    name: "Province of Forlì-Cesena",
    c_c: "IT",
  },
  {
    i_c: "FE",
    name: "Province of Ferrara",
    c_c: "IT",
  },
  {
    i_c: "FG",
    name: "Province of Foggia",
    c_c: "IT",
  },
  {
    i_c: "FI",
    name: "Metropolitan City of Florence",
    c_c: "IT",
  },
  {
    i_c: "FM",
    name: "Province of Fermo",
    c_c: "IT",
  },
  {
    i_c: "FR",
    name: "Province of Frosinone",
    c_c: "IT",
  },
  {
    i_c: "GE",
    name: "Metropolitan City of Genoa",
    c_c: "IT",
  },
  {
    i_c: "GO",
    name: "Province of Gorizia",
    c_c: "IT",
  },
  {
    i_c: "GR",
    name: "Province of Grosseto",
    c_c: "IT",
  },
  {
    i_c: "IM",
    name: "Province of Imperia",
    c_c: "IT",
  },
  {
    i_c: "IS",
    name: "Province of Isernia",
    c_c: "IT",
  },
  {
    i_c: "KR",
    name: "Province of Crotone",
    c_c: "IT",
  },
  {
    i_c: "LC",
    name: "Province of Lecco",
    c_c: "IT",
  },
  {
    i_c: "LE",
    name: "Province of Lecce",
    c_c: "IT",
  },
  {
    i_c: "LI",
    name: "Province of Livorno",
    c_c: "IT",
  },
  {
    i_c: "LO",
    name: "Province of Lodi",
    c_c: "IT",
  },
  {
    i_c: "LT",
    name: "Province of Latina",
    c_c: "IT",
  },
  {
    i_c: "LU",
    name: "Province of Lucca",
    c_c: "IT",
  },
  {
    i_c: "MB",
    name: "Province of Monza and Brianza",
    c_c: "IT",
  },
  {
    i_c: "MC",
    name: "Province of Macerata",
    c_c: "IT",
  },
  {
    i_c: "ME",
    name: "Metropolitan City of Messina",
    c_c: "IT",
  },
  {
    i_c: "MI",
    name: "Metropolitan City of Milan",
    c_c: "IT",
  },
  {
    i_c: "MN",
    name: "Province of Mantua",
    c_c: "IT",
  },
  {
    i_c: "MO",
    name: "Province of Modena",
    c_c: "IT",
  },
  {
    i_c: "MS",
    name: "Province of Massa and Carrara",
    c_c: "IT",
  },
  {
    i_c: "MT",
    name: "Province of Matera",
    c_c: "IT",
  },
  {
    i_c: "NA",
    name: "Metropolitan City of Naples",
    c_c: "IT",
  },
  {
    i_c: "NO",
    name: "Province of Novara",
    c_c: "IT",
  },
  {
    i_c: "NU",
    name: "Province of Nuoro",
    c_c: "IT",
  },
  {
    i_c: "OG",
    name: "Province of Ogliastra",
    c_c: "IT",
  },
  {
    i_c: "OR",
    name: "Province of Oristano",
    c_c: "IT",
  },
  {
    i_c: "OT",
    name: "Province of Olbia-Tempio",
    c_c: "IT",
  },
  {
    i_c: "PA",
    name: "Metropolitan City of Palermo",
    c_c: "IT",
  },
  {
    i_c: "PC",
    name: "Province of Piacenza",
    c_c: "IT",
  },
  {
    i_c: "PD",
    name: "Province of Padua",
    c_c: "IT",
  },
  {
    i_c: "PE",
    name: "Province of Pescara",
    c_c: "IT",
  },
  {
    i_c: "PG",
    name: "Province of Perugia",
    c_c: "IT",
  },
  {
    i_c: "PI",
    name: "Province of Pisa",
    c_c: "IT",
  },
  {
    i_c: "PN",
    name: "Province of Pordenone",
    c_c: "IT",
  },
  {
    i_c: "PO",
    name: "Province of Prato",
    c_c: "IT",
  },
  {
    i_c: "PR",
    name: "Province of Parma",
    c_c: "IT",
  },
  {
    i_c: "PT",
    name: "Province of Pistoia",
    c_c: "IT",
  },
  {
    i_c: "PU",
    name: "Pesaro and Urbino Province",
    c_c: "IT",
  },
  {
    i_c: "PV",
    name: "Province of Pavia",
    c_c: "IT",
  },
  {
    i_c: "PZ",
    name: "Province of Potenza",
    c_c: "IT",
  },
  {
    i_c: "RA",
    name: "Province of Ravenna",
    c_c: "IT",
  },
  {
    i_c: "RC",
    name: "Metropolitan City of Reggio Calabria",
    c_c: "IT",
  },
  {
    i_c: "RE",
    name: "Province of Reggio Emilia",
    c_c: "IT",
  },
  {
    i_c: "RG",
    name: "Libero consorzio comunale di Ragusa",
    c_c: "IT",
  },
  {
    i_c: "RI",
    name: "Province of Rieti",
    c_c: "IT",
  },
  {
    i_c: "RM",
    name: "Metropolitan City of Rome",
    c_c: "IT",
  },
  {
    i_c: "RN",
    name: "Province of Rimini",
    c_c: "IT",
  },
  {
    i_c: "RO",
    name: "Province of Rovigo",
    c_c: "IT",
  },
  {
    i_c: "SA",
    name: "Province of Salerno",
    c_c: "IT",
  },
  {
    i_c: "SI",
    name: "Province of Siena",
    c_c: "IT",
  },
  {
    i_c: "SO",
    name: "Province of Sondrio",
    c_c: "IT",
  },
  {
    i_c: "SP",
    name: "Province of La Spezia",
    c_c: "IT",
  },
  {
    i_c: "SR",
    name: "Libero consorzio comunale di Siracusa",
    c_c: "IT",
  },
  {
    i_c: "SS",
    name: "Province of Sassari",
    c_c: "IT",
  },
  {
    i_c: "SV",
    name: "Province of Savona",
    c_c: "IT",
  },
  {
    i_c: "TA",
    name: "Province of Taranto",
    c_c: "IT",
  },
  {
    i_c: "TE",
    name: "Province of Teramo",
    c_c: "IT",
  },
  {
    i_c: "TN",
    name: "Trentino",
    c_c: "IT",
  },
  {
    i_c: "TO",
    name: "Metropolitan City of Turin",
    c_c: "IT",
  },
  {
    i_c: "TP",
    name: "Libero consorzio comunale di Trapani",
    c_c: "IT",
  },
  {
    i_c: "TR",
    name: "Province of Terni",
    c_c: "IT",
  },
  {
    i_c: "TS",
    name: "Province of Trieste",
    c_c: "IT",
  },
  {
    i_c: "TV",
    name: "Province of Treviso",
    c_c: "IT",
  },
  {
    i_c: "UD",
    name: "Province of Udine",
    c_c: "IT",
  },
  {
    i_c: "VA",
    name: "Province of Varese",
    c_c: "IT",
  },
  {
    i_c: "VB",
    name: "Province of Verbano-Cusio-Ossola",
    c_c: "IT",
  },
  {
    i_c: "VC",
    name: "Province of Vercelli",
    c_c: "IT",
  },
  {
    i_c: "VE",
    name: "Metropolitan City of Venice",
    c_c: "IT",
  },
  {
    i_c: "VI",
    name: "Province of Vicenza",
    c_c: "IT",
  },
  {
    i_c: "VR",
    name: "Province of Verona",
    c_c: "IT",
  },
  {
    i_c: "VS",
    name: "Province of Medio Campidano",
    c_c: "IT",
  },
  {
    i_c: "VT",
    name: "Province of Viterbo",
    c_c: "IT",
  },
  {
    i_c: "VV",
    name: "Province of Vibo Valentia",
    c_c: "IT",
  },
  {
    i_c: "01",
    name: "Kingston Parish",
    c_c: "JM",
  },
  {
    i_c: "02",
    name: "Saint Andrew",
    c_c: "JM",
  },
  {
    i_c: "03",
    name: "Saint Thomas Parish",
    c_c: "JM",
  },
  {
    i_c: "04",
    name: "Portland Parish",
    c_c: "JM",
  },
  {
    i_c: "05",
    name: "Saint Mary Parish",
    c_c: "JM",
  },
  {
    i_c: "06",
    name: "Saint Ann Parish",
    c_c: "JM",
  },
  {
    i_c: "07",
    name: "Trelawny Parish",
    c_c: "JM",
  },
  {
    i_c: "08",
    name: "Saint James Parish",
    c_c: "JM",
  },
  {
    i_c: "09",
    name: "Hanover Parish",
    c_c: "JM",
  },
  {
    i_c: "10",
    name: "Westmoreland Parish",
    c_c: "JM",
  },
  {
    i_c: "11",
    name: "Saint Elizabeth Parish",
    c_c: "JM",
  },
  {
    i_c: "12",
    name: "Manchester Parish",
    c_c: "JM",
  },
  {
    i_c: "13",
    name: "Clarendon Parish",
    c_c: "JM",
  },
  {
    i_c: "14",
    name: "Saint Catherine Parish",
    c_c: "JM",
  },
  {
    i_c: "AJ",
    name: "Ajloun Governorate",
    c_c: "JO",
  },
  {
    i_c: "AM",
    name: "Amman Governorate",
    c_c: "JO",
  },
  {
    i_c: "AQ",
    name: "Aqaba Governorate",
    c_c: "JO",
  },
  {
    i_c: "AT",
    name: "Tafilah Governorate",
    c_c: "JO",
  },
  {
    i_c: "AZ",
    name: "Zarqa Governorate",
    c_c: "JO",
  },
  {
    i_c: "BA",
    name: "Balqa Governorate",
    c_c: "JO",
  },
  {
    i_c: "IR",
    name: "Irbid Governorate",
    c_c: "JO",
  },
  {
    i_c: "JA",
    name: "Jerash Governorate",
    c_c: "JO",
  },
  {
    i_c: "KA",
    name: "Karak Governorate",
    c_c: "JO",
  },
  {
    i_c: "MA",
    name: "Mafraq Governorate",
    c_c: "JO",
  },
  {
    i_c: "MD",
    name: "Madaba Governorate",
    c_c: "JO",
  },
  {
    i_c: "MN",
    name: "Ma'an Governorate",
    c_c: "JO",
  },
  {
    i_c: "01",
    name: "Hokkaidō Prefecture",
    c_c: "JP",
  },
  {
    i_c: "02",
    name: "Aomori Prefecture",
    c_c: "JP",
  },
  {
    i_c: "03",
    name: "Iwate Prefecture",
    c_c: "JP",
  },
  {
    i_c: "04",
    name: "Miyagi Prefecture",
    c_c: "JP",
  },
  {
    i_c: "05",
    name: "Akita Prefecture",
    c_c: "JP",
  },
  {
    i_c: "06",
    name: "Yamagata Prefecture",
    c_c: "JP",
  },
  {
    i_c: "07",
    name: "Fukushima Prefecture",
    c_c: "JP",
  },
  {
    i_c: "08",
    name: "Ibaraki Prefecture",
    c_c: "JP",
  },
  {
    i_c: "09",
    name: "Tochigi Prefecture",
    c_c: "JP",
  },
  {
    i_c: "10",
    name: "Gunma Prefecture",
    c_c: "JP",
  },
  {
    i_c: "11",
    name: "Saitama Prefecture",
    c_c: "JP",
  },
  {
    i_c: "12",
    name: "Chiba Prefecture",
    c_c: "JP",
  },
  {
    i_c: "13",
    name: "Tokyo",
    c_c: "JP",
  },
  {
    i_c: "14",
    name: "Kanagawa Prefecture",
    c_c: "JP",
  },
  {
    i_c: "15",
    name: "Niigata Prefecture",
    c_c: "JP",
  },
  {
    i_c: "16",
    name: "Toyama Prefecture",
    c_c: "JP",
  },
  {
    i_c: "17",
    name: "Ishikawa Prefecture",
    c_c: "JP",
  },
  {
    i_c: "18",
    name: "Fukui Prefecture",
    c_c: "JP",
  },
  {
    i_c: "19",
    name: "Yamanashi Prefecture",
    c_c: "JP",
  },
  {
    i_c: "20",
    name: "Nagano Prefecture",
    c_c: "JP",
  },
  {
    i_c: "21",
    name: "Gifu Prefecture",
    c_c: "JP",
  },
  {
    i_c: "22",
    name: "Shizuoka Prefecture",
    c_c: "JP",
  },
  {
    i_c: "23",
    name: "Aichi Prefecture",
    c_c: "JP",
  },
  {
    i_c: "24",
    name: "Mie Prefecture",
    c_c: "JP",
  },
  {
    i_c: "25",
    name: "Shiga Prefecture",
    c_c: "JP",
  },
  {
    i_c: "26",
    name: "Kyōto Prefecture",
    c_c: "JP",
  },
  {
    i_c: "27",
    name: "Ōsaka Prefecture",
    c_c: "JP",
  },
  {
    i_c: "28",
    name: "Hyōgo Prefecture",
    c_c: "JP",
  },
  {
    i_c: "29",
    name: "Nara Prefecture",
    c_c: "JP",
  },
  {
    i_c: "30",
    name: "Wakayama Prefecture",
    c_c: "JP",
  },
  {
    i_c: "31",
    name: "Tottori Prefecture",
    c_c: "JP",
  },
  {
    i_c: "32",
    name: "Shimane Prefecture",
    c_c: "JP",
  },
  {
    i_c: "33",
    name: "Okayama Prefecture",
    c_c: "JP",
  },
  {
    i_c: "34",
    name: "Hiroshima Prefecture",
    c_c: "JP",
  },
  {
    i_c: "35",
    name: "Yamaguchi Prefecture",
    c_c: "JP",
  },
  {
    i_c: "36",
    name: "Tokushima Prefecture",
    c_c: "JP",
  },
  {
    i_c: "37",
    name: "Kagawa Prefecture",
    c_c: "JP",
  },
  {
    i_c: "38",
    name: "Ehime Prefecture",
    c_c: "JP",
  },
  {
    i_c: "39",
    name: "Kōchi Prefecture",
    c_c: "JP",
  },
  {
    i_c: "40",
    name: "Fukuoka Prefecture",
    c_c: "JP",
  },
  {
    i_c: "41",
    name: "Saga Prefecture",
    c_c: "JP",
  },
  {
    i_c: "42",
    name: "Nagasaki Prefecture",
    c_c: "JP",
  },
  {
    i_c: "43",
    name: "Kumamoto Prefecture",
    c_c: "JP",
  },
  {
    i_c: "44",
    name: "Ōita Prefecture",
    c_c: "JP",
  },
  {
    i_c: "45",
    name: "Miyazaki Prefecture",
    c_c: "JP",
  },
  {
    i_c: "46",
    name: "Kagoshima Prefecture",
    c_c: "JP",
  },
  {
    i_c: "47",
    name: "Okinawa Prefecture",
    c_c: "JP",
  },
  {
    i_c: "01",
    name: "Baringo",
    c_c: "KE",
  },
  {
    i_c: "02",
    name: "Bomet",
    c_c: "KE",
  },
  {
    i_c: "03",
    name: "Bungoma",
    c_c: "KE",
  },
  {
    i_c: "04",
    name: "Busia",
    c_c: "KE",
  },
  {
    i_c: "05",
    name: "Elgeyo-Marakwet",
    c_c: "KE",
  },
  {
    i_c: "06",
    name: "Embu",
    c_c: "KE",
  },
  {
    i_c: "07",
    name: "Garissa",
    c_c: "KE",
  },
  {
    i_c: "08",
    name: "Homa Bay",
    c_c: "KE",
  },
  {
    i_c: "09",
    name: "Isiolo",
    c_c: "KE",
  },
  {
    i_c: "10",
    name: "Kajiado",
    c_c: "KE",
  },
  {
    i_c: "11",
    name: "Kakamega",
    c_c: "KE",
  },
  {
    i_c: "12",
    name: "Kericho",
    c_c: "KE",
  },
  {
    i_c: "13",
    name: "Kiambu",
    c_c: "KE",
  },
  {
    i_c: "14",
    name: "Kilifi",
    c_c: "KE",
  },
  {
    i_c: "15",
    name: "Kirinyaga",
    c_c: "KE",
  },
  {
    i_c: "16",
    name: "Kisii",
    c_c: "KE",
  },
  {
    i_c: "17",
    name: "Kisumu",
    c_c: "KE",
  },
  {
    i_c: "18",
    name: "Kitui",
    c_c: "KE",
  },
  {
    i_c: "19",
    name: "Kwale",
    c_c: "KE",
  },
  {
    i_c: "20",
    name: "Laikipia",
    c_c: "KE",
  },
  {
    i_c: "21",
    name: "Lamu",
    c_c: "KE",
  },
  {
    i_c: "22",
    name: "Machakos",
    c_c: "KE",
  },
  {
    i_c: "23",
    name: "Makueni",
    c_c: "KE",
  },
  {
    i_c: "24",
    name: "Mandera",
    c_c: "KE",
  },
  {
    i_c: "25",
    name: "Marsabit",
    c_c: "KE",
  },
  {
    i_c: "26",
    name: "Meru",
    c_c: "KE",
  },
  {
    i_c: "27",
    name: "Migori",
    c_c: "KE",
  },
  {
    i_c: "28",
    name: "Mombasa",
    c_c: "KE",
  },
  {
    i_c: "29",
    name: "Murang'a",
    c_c: "KE",
  },
  {
    i_c: "30",
    name: "Nairobi City",
    c_c: "KE",
  },
  {
    i_c: "31",
    name: "Nakuru",
    c_c: "KE",
  },
  {
    i_c: "32",
    name: "Nandi",
    c_c: "KE",
  },
  {
    i_c: "33",
    name: "Narok",
    c_c: "KE",
  },
  {
    i_c: "34",
    name: "Nyamira",
    c_c: "KE",
  },
  {
    i_c: "35",
    name: "Nyandarua",
    c_c: "KE",
  },
  {
    i_c: "36",
    name: "Nyeri",
    c_c: "KE",
  },
  {
    i_c: "37",
    name: "Samburu",
    c_c: "KE",
  },
  {
    i_c: "38",
    name: "Siaya",
    c_c: "KE",
  },
  {
    i_c: "39",
    name: "Taita–Taveta",
    c_c: "KE",
  },
  {
    i_c: "40",
    name: "Tana River",
    c_c: "KE",
  },
  {
    i_c: "41",
    name: "Tharaka-Nithi",
    c_c: "KE",
  },
  {
    i_c: "42",
    name: "Trans Nzoia",
    c_c: "KE",
  },
  {
    i_c: "43",
    name: "Turkana",
    c_c: "KE",
  },
  {
    i_c: "44",
    name: "Uasin Gishu",
    c_c: "KE",
  },
  {
    i_c: "45",
    name: "Vihiga",
    c_c: "KE",
  },
  {
    i_c: "46",
    name: "Wajir",
    c_c: "KE",
  },
  {
    i_c: "47",
    name: "West Pokot",
    c_c: "KE",
  },
  {
    i_c: "B",
    name: "Batken Region",
    c_c: "KG",
  },
  {
    i_c: "C",
    name: "Chuy Region",
    c_c: "KG",
  },
  {
    i_c: "GB",
    name: "Bishkek",
    c_c: "KG",
  },
  {
    i_c: "GO",
    name: "Osh",
    c_c: "KG",
  },
  {
    i_c: "J",
    name: "Jalal-Abad Region",
    c_c: "KG",
  },
  {
    i_c: "N",
    name: "Naryn Region",
    c_c: "KG",
  },
  {
    i_c: "O",
    name: "Osh Region",
    c_c: "KG",
  },
  {
    i_c: "T",
    name: "Talas Region",
    c_c: "KG",
  },
  {
    i_c: "Y",
    name: "Issyk-Kul Region",
    c_c: "KG",
  },
  {
    i_c: "1",
    name: "Banteay Meanchey",
    c_c: "KH",
  },
  {
    i_c: "10",
    name: "Kratie",
    c_c: "KH",
  },
  {
    i_c: "11",
    name: "Mondulkiri",
    c_c: "KH",
  },
  {
    i_c: "12",
    name: "Phnom Penh",
    c_c: "KH",
  },
  {
    i_c: "13",
    name: "Preah Vihear",
    c_c: "KH",
  },
  {
    i_c: "14",
    name: "Prey Veng",
    c_c: "KH",
  },
  {
    i_c: "15",
    name: "Pursat",
    c_c: "KH",
  },
  {
    i_c: "16",
    name: "Ratanakiri",
    c_c: "KH",
  },
  {
    i_c: "17",
    name: "Siem Reap",
    c_c: "KH",
  },
  {
    i_c: "18",
    name: "Sihanoukville",
    c_c: "KH",
  },
  {
    i_c: "19",
    name: "Stung Treng",
    c_c: "KH",
  },
  {
    i_c: "2",
    name: "Battambang",
    c_c: "KH",
  },
  {
    i_c: "20",
    name: "Svay Rieng",
    c_c: "KH",
  },
  {
    i_c: "21",
    name: "Takeo",
    c_c: "KH",
  },
  {
    i_c: "22",
    name: "Oddar Meanchey",
    c_c: "KH",
  },
  {
    i_c: "23",
    name: "Kep",
    c_c: "KH",
  },
  {
    i_c: "24",
    name: "Pailin",
    c_c: "KH",
  },
  {
    i_c: "3",
    name: "Kampong Cham",
    c_c: "KH",
  },
  {
    i_c: "4",
    name: "Kampong Chhnang",
    c_c: "KH",
  },
  {
    i_c: "5",
    name: "Kampong Speu",
    c_c: "KH",
  },
  {
    i_c: "6",
    name: "Kampong Thom",
    c_c: "KH",
  },
  {
    i_c: "7",
    name: "Kampot",
    c_c: "KH",
  },
  {
    i_c: "8",
    name: "Kandal",
    c_c: "KH",
  },
  {
    i_c: "9",
    name: "Koh Kong",
    c_c: "KH",
  },
  {
    i_c: "NTP",
    name: "Tai Po District",
    c_c: "KH",
  },
  {
    i_c: "G",
    name: "Gilbert Islands",
    c_c: "KI",
  },
  {
    i_c: "L",
    name: "Line Islands",
    c_c: "KI",
  },
  {
    i_c: "P",
    name: "Phoenix Islands",
    c_c: "KI",
  },
  {
    i_c: "A",
    name: "Anjouan",
    c_c: "KM",
  },
  {
    i_c: "G",
    name: "Grande Comore",
    c_c: "KM",
  },
  {
    i_c: "M",
    name: "Mohéli",
    c_c: "KM",
  },
  {
    i_c: "01",
    name: "Christ Church Nichola Town Parish",
    c_c: "KN",
  },
  {
    i_c: "02",
    name: "Saint Anne Sandy Point Parish",
    c_c: "KN",
  },
  {
    i_c: "04",
    name: "Saint George Gingerland Parish",
    c_c: "KN",
  },
  {
    i_c: "05",
    name: "Saint James Windward Parish",
    c_c: "KN",
  },
  {
    i_c: "06",
    name: "Saint John Capisterre Parish",
    c_c: "KN",
  },
  {
    i_c: "07",
    name: "Saint John Figtree Parish",
    c_c: "KN",
  },
  {
    i_c: "08",
    name: "Saint Mary Cayon Parish",
    c_c: "KN",
  },
  {
    i_c: "09",
    name: "Saint Paul Capisterre Parish",
    c_c: "KN",
  },
  {
    i_c: "10",
    name: "Saint Paul Charlestown Parish",
    c_c: "KN",
  },
  {
    i_c: "11",
    name: "Saint Peter Basseterre Parish",
    c_c: "KN",
  },
  {
    i_c: "12",
    name: "Saint Thomas Lowland Parish",
    c_c: "KN",
  },
  {
    i_c: "13",
    name: "Saint Thomas Middle Island Parish",
    c_c: "KN",
  },
  {
    i_c: "15",
    name: "Trinity Palmetto Point Parish",
    c_c: "KN",
  },
  {
    i_c: "K",
    name: "Saint Kitts",
    c_c: "KN",
  },
  {
    i_c: "N",
    name: "Nevis",
    c_c: "KN",
  },
  {
    i_c: "01",
    name: "Pyongyang",
    c_c: "KP",
  },
  {
    i_c: "02",
    name: "South Pyongan Province",
    c_c: "KP",
  },
  {
    i_c: "03",
    name: "North Pyongan Province",
    c_c: "KP",
  },
  {
    i_c: "04",
    name: "Chagang Province",
    c_c: "KP",
  },
  {
    i_c: "05",
    name: "South Hwanghae Province",
    c_c: "KP",
  },
  {
    i_c: "06",
    name: "North Hwanghae Province",
    c_c: "KP",
  },
  {
    i_c: "07",
    name: "Kangwon Province",
    c_c: "KP",
  },
  {
    i_c: "08",
    name: "South Hamgyong Province",
    c_c: "KP",
  },
  {
    i_c: "09",
    name: "North Hamgyong Province",
    c_c: "KP",
  },
  {
    i_c: "10",
    name: "Ryanggang Province",
    c_c: "KP",
  },
  {
    i_c: "13",
    name: "Rason",
    c_c: "KP",
  },
  {
    i_c: "11",
    name: "Seoul",
    c_c: "KR",
  },
  {
    i_c: "26",
    name: "Busan",
    c_c: "KR",
  },
  {
    i_c: "27",
    name: "Daegu",
    c_c: "KR",
  },
  {
    i_c: "28",
    name: "Incheon",
    c_c: "KR",
  },
  {
    i_c: "29",
    name: "Gwangju",
    c_c: "KR",
  },
  {
    i_c: "30",
    name: "Daejeon",
    c_c: "KR",
  },
  {
    i_c: "31",
    name: "Ulsan",
    c_c: "KR",
  },
  {
    i_c: "41",
    name: "Gyeonggi Province",
    c_c: "KR",
  },
  {
    i_c: "42",
    name: "Gangwon Province",
    c_c: "KR",
  },
  {
    i_c: "43",
    name: "North Chungcheong Province",
    c_c: "KR",
  },
  {
    i_c: "44",
    name: "South Chungcheong Province",
    c_c: "KR",
  },
  {
    i_c: "45",
    name: "North Jeolla Province",
    c_c: "KR",
  },
  {
    i_c: "46",
    name: "South Jeolla Province",
    c_c: "KR",
  },
  {
    i_c: "47",
    name: "North Gyeongsang Province",
    c_c: "KR",
  },
  {
    i_c: "48",
    name: "South Gyeongsang Province",
    c_c: "KR",
  },
  {
    i_c: "49",
    name: "Jeju",
    c_c: "KR",
  },
  {
    i_c: "50",
    name: "Sejong City",
    c_c: "KR",
  },
  {
    i_c: "AH",
    name: "Al Ahmadi Governorate",
    c_c: "KW",
  },
  {
    i_c: "FA",
    name: "Al Farwaniyah Governorate",
    c_c: "KW",
  },
  {
    i_c: "HA",
    name: "Hawalli Governorate",
    c_c: "KW",
  },
  {
    i_c: "JA",
    name: "Al Jahra Governorate",
    c_c: "KW",
  },
  {
    i_c: "KU",
    name: "Capital Governorate",
    c_c: "KW",
  },
  {
    i_c: "MU",
    name: "Mubarak Al-Kabeer Governorate",
    c_c: "KW",
  },
  {
    i_c: "AKM",
    name: "Akmola Region",
    c_c: "KZ",
  },
  {
    i_c: "AKT",
    name: "Aktobe Region",
    c_c: "KZ",
  },
  {
    i_c: "ALA",
    name: "Almaty",
    c_c: "KZ",
  },
  {
    i_c: "ALM",
    name: "Almaty Region",
    c_c: "KZ",
  },
  {
    i_c: "AST",
    name: "Nur-Sultan",
    c_c: "KZ",
  },
  {
    i_c: "ATY",
    name: "Atyrau Region",
    c_c: "KZ",
  },
  {
    i_c: "BAY",
    name: "Baikonur",
    c_c: "KZ",
  },
  {
    i_c: "KAR",
    name: "Karaganda Region",
    c_c: "KZ",
  },
  {
    i_c: "KUS",
    name: "Kostanay Region",
    c_c: "KZ",
  },
  {
    i_c: "KZY",
    name: "Kyzylorda Region",
    c_c: "KZ",
  },
  {
    i_c: "MAN",
    name: "Mangystau Region",
    c_c: "KZ",
  },
  {
    i_c: "PAV",
    name: "Pavlodar Region",
    c_c: "KZ",
  },
  {
    i_c: "SEV",
    name: "North Kazakhstan Region",
    c_c: "KZ",
  },
  {
    i_c: "VOS",
    name: "East Kazakhstan Region",
    c_c: "KZ",
  },
  {
    i_c: "YUZ",
    name: "Turkestan Region",
    c_c: "KZ",
  },
  {
    i_c: "ZAP",
    name: "West Kazakhstan Province",
    c_c: "KZ",
  },
  {
    i_c: "ZHA",
    name: "Jambyl Region",
    c_c: "KZ",
  },
  {
    i_c: "AT",
    name: "Attapeu Province",
    c_c: "LA",
  },
  {
    i_c: "BK",
    name: "Bokeo Province",
    c_c: "LA",
  },
  {
    i_c: "BL",
    name: "Bolikhamsai Province",
    c_c: "LA",
  },
  {
    i_c: "CH",
    name: "Champasak Province",
    c_c: "LA",
  },
  {
    i_c: "HO",
    name: "Houaphanh Province",
    c_c: "LA",
  },
  {
    i_c: "KH",
    name: "Khammouane Province",
    c_c: "LA",
  },
  {
    i_c: "LM",
    name: "Luang Namtha Province",
    c_c: "LA",
  },
  {
    i_c: "LP",
    name: "Luang Prabang Province",
    c_c: "LA",
  },
  {
    i_c: "OU",
    name: "Oudomxay Province",
    c_c: "LA",
  },
  {
    i_c: "PH",
    name: "Phongsaly Province",
    c_c: "LA",
  },
  {
    i_c: "SL",
    name: "Salavan Province",
    c_c: "LA",
  },
  {
    i_c: "SV",
    name: "Savannakhet Province",
    c_c: "LA",
  },
  {
    i_c: "VI",
    name: "Vientiane Province",
    c_c: "LA",
  },
  {
    i_c: "VT",
    name: "Vientiane Prefecture",
    c_c: "LA",
  },
  {
    i_c: "XA",
    name: "Sainyabuli Province",
    c_c: "LA",
  },
  {
    i_c: "XE",
    name: "Sekong Province",
    c_c: "LA",
  },
  {
    i_c: "XI",
    name: "Xiangkhouang Province",
    c_c: "LA",
  },
  {
    i_c: "XN",
    name: "Xaisomboun",
    c_c: "LA",
  },
  {
    i_c: "XS",
    name: "Xaisomboun Province",
    c_c: "LA",
  },
  {
    i_c: "AK",
    name: "Akkar Governorate",
    c_c: "LB",
  },
  {
    i_c: "AS",
    name: "North Governorate",
    c_c: "LB",
  },
  {
    i_c: "BA",
    name: "Beirut Governorate",
    c_c: "LB",
  },
  {
    i_c: "BH",
    name: "Baalbek-Hermel Governorate",
    c_c: "LB",
  },
  {
    i_c: "BI",
    name: "Beqaa Governorate",
    c_c: "LB",
  },
  {
    i_c: "JA",
    name: "South Governorate",
    c_c: "LB",
  },
  {
    i_c: "JL",
    name: "Mount Lebanon Governorate",
    c_c: "LB",
  },
  {
    i_c: "NA",
    name: "Nabatieh Governorate",
    c_c: "LB",
  },
  {
    i_c: "01",
    name: "Anse la Raye Quarter",
    c_c: "LC",
  },
  {
    i_c: "02",
    name: "Castries Quarter",
    c_c: "LC",
  },
  {
    i_c: "03",
    name: "Choiseul Quarter",
    c_c: "LC",
  },
  {
    i_c: "04",
    name: "Dauphin Quarter",
    c_c: "LC",
  },
  {
    i_c: "05",
    name: "Dennery Quarter",
    c_c: "LC",
  },
  {
    i_c: "06",
    name: "Gros Islet Quarter",
    c_c: "LC",
  },
  {
    i_c: "07",
    name: "Laborie Quarter",
    c_c: "LC",
  },
  {
    i_c: "08",
    name: "Micoud Quarter",
    c_c: "LC",
  },
  {
    i_c: "09",
    name: "Praslin Quarter",
    c_c: "LC",
  },
  {
    i_c: "10",
    name: "Soufrière Quarter",
    c_c: "LC",
  },
  {
    i_c: "11",
    name: "Vieux Fort Quarter",
    c_c: "LC",
  },
  {
    i_c: "12",
    name: "Canaries",
    c_c: "LC",
  },
  {
    i_c: "01",
    name: "Balzers",
    c_c: "LI",
  },
  {
    i_c: "02",
    name: "Eschen",
    c_c: "LI",
  },
  {
    i_c: "03",
    name: "Gamprin",
    c_c: "LI",
  },
  {
    i_c: "04",
    name: "Mauren",
    c_c: "LI",
  },
  {
    i_c: "05",
    name: "Planken",
    c_c: "LI",
  },
  {
    i_c: "06",
    name: "Ruggell",
    c_c: "LI",
  },
  {
    i_c: "07",
    name: "Schaan",
    c_c: "LI",
  },
  {
    i_c: "08",
    name: "Schellenberg",
    c_c: "LI",
  },
  {
    i_c: "09",
    name: "Triesen",
    c_c: "LI",
  },
  {
    i_c: "10",
    name: "Triesenberg",
    c_c: "LI",
  },
  {
    i_c: "11",
    name: "Vaduz",
    c_c: "LI",
  },
  {
    i_c: "1",
    name: "Western Province",
    c_c: "LK",
  },
  {
    i_c: "11",
    name: "Colombo District",
    c_c: "LK",
  },
  {
    i_c: "12",
    name: "Gampaha District",
    c_c: "LK",
  },
  {
    i_c: "13",
    name: "Kalutara District",
    c_c: "LK",
  },
  {
    i_c: "2",
    name: "Central Province",
    c_c: "LK",
  },
  {
    i_c: "21",
    name: "Kandy District",
    c_c: "LK",
  },
  {
    i_c: "22",
    name: "Matale District",
    c_c: "LK",
  },
  {
    i_c: "23",
    name: "Nuwara Eliya District",
    c_c: "LK",
  },
  {
    i_c: "3",
    name: "Southern Province",
    c_c: "LK",
  },
  {
    i_c: "31",
    name: "Galle District",
    c_c: "LK",
  },
  {
    i_c: "32",
    name: "Matara District",
    c_c: "LK",
  },
  {
    i_c: "33",
    name: "Hambantota District",
    c_c: "LK",
  },
  {
    i_c: "4",
    name: "Northern Province",
    c_c: "LK",
  },
  {
    i_c: "41",
    name: "Jaffna District",
    c_c: "LK",
  },
  {
    i_c: "42",
    name: "Kilinochchi District",
    c_c: "LK",
  },
  {
    i_c: "43",
    name: "Mannar District",
    c_c: "LK",
  },
  {
    i_c: "44",
    name: "Vavuniya District",
    c_c: "LK",
  },
  {
    i_c: "45",
    name: "Mullaitivu District",
    c_c: "LK",
  },
  {
    i_c: "5",
    name: "Eastern Province",
    c_c: "LK",
  },
  {
    i_c: "51",
    name: "Batticaloa District",
    c_c: "LK",
  },
  {
    i_c: "52",
    name: "Ampara District",
    c_c: "LK",
  },
  {
    i_c: "53",
    name: "Trincomalee District",
    c_c: "LK",
  },
  {
    i_c: "6",
    name: "North Western Province",
    c_c: "LK",
  },
  {
    i_c: "62",
    name: "Puttalam District",
    c_c: "LK",
  },
  {
    i_c: "7",
    name: "North Central Province",
    c_c: "LK",
  },
  {
    i_c: "71",
    name: "Anuradhapura District",
    c_c: "LK",
  },
  {
    i_c: "72",
    name: "Polonnaruwa District",
    c_c: "LK",
  },
  {
    i_c: "8",
    name: "Uva Province",
    c_c: "LK",
  },
  {
    i_c: "81",
    name: "Badulla District",
    c_c: "LK",
  },
  {
    i_c: "82",
    name: "Monaragala District",
    c_c: "LK",
  },
  {
    i_c: "9",
    name: "Sabaragamuwa Province",
    c_c: "LK",
  },
  {
    i_c: "91",
    name: "Ratnapura district",
    c_c: "LK",
  },
  {
    i_c: "92",
    name: "Kegalle District",
    c_c: "LK",
  },
  {
    i_c: "BG",
    name: "Bong County",
    c_c: "LR",
  },
  {
    i_c: "BM",
    name: "Bomi County",
    c_c: "LR",
  },
  {
    i_c: "CM",
    name: "Grand Cape Mount County",
    c_c: "LR",
  },
  {
    i_c: "GB",
    name: "Grand Bassa County",
    c_c: "LR",
  },
  {
    i_c: "GG",
    name: "Grand Gedeh County",
    c_c: "LR",
  },
  {
    i_c: "GK",
    name: "Grand Kru County",
    c_c: "LR",
  },
  {
    i_c: "GP",
    name: "Gbarpolu County",
    c_c: "LR",
  },
  {
    i_c: "LO",
    name: "Lofa County",
    c_c: "LR",
  },
  {
    i_c: "MG",
    name: "Margibi County",
    c_c: "LR",
  },
  {
    i_c: "MO",
    name: "Montserrado County",
    c_c: "LR",
  },
  {
    i_c: "MY",
    name: "Maryland County",
    c_c: "LR",
  },
  {
    i_c: "NI",
    name: "Nimba",
    c_c: "LR",
  },
  {
    i_c: "RG",
    name: "River Gee County",
    c_c: "LR",
  },
  {
    i_c: "RI",
    name: "River Cess County",
    c_c: "LR",
  },
  {
    i_c: "SI",
    name: "Sinoe County",
    c_c: "LR",
  },
  {
    i_c: "A",
    name: "Maseru District",
    c_c: "LS",
  },
  {
    i_c: "B",
    name: "Butha-Buthe District",
    c_c: "LS",
  },
  {
    i_c: "C",
    name: "Leribe District",
    c_c: "LS",
  },
  {
    i_c: "D",
    name: "Berea District",
    c_c: "LS",
  },
  {
    i_c: "E",
    name: "Mafeteng District",
    c_c: "LS",
  },
  {
    i_c: "F",
    name: "Mohale's Hoek District",
    c_c: "LS",
  },
  {
    i_c: "G",
    name: "Quthing District",
    c_c: "LS",
  },
  {
    i_c: "H",
    name: "Qacha's Nek District",
    c_c: "LS",
  },
  {
    i_c: "J",
    name: "Mokhotlong District",
    c_c: "LS",
  },
  {
    i_c: "K",
    name: "Thaba-Tseka District",
    c_c: "LS",
  },
  {
    i_c: "01",
    name: "Akmenė District Municipality",
    c_c: "LT",
  },
  {
    i_c: "02",
    name: "Alytus City Municipality",
    c_c: "LT",
  },
  {
    i_c: "03",
    name: "Alytus District Municipality",
    c_c: "LT",
  },
  {
    i_c: "05",
    name: "Birštonas Municipality",
    c_c: "LT",
  },
  {
    i_c: "06",
    name: "Biržai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "07",
    name: "Druskininkai municipality",
    c_c: "LT",
  },
  {
    i_c: "08",
    name: "Elektrėnai municipality",
    c_c: "LT",
  },
  {
    i_c: "09",
    name: "Ignalina District Municipality",
    c_c: "LT",
  },
  {
    i_c: "10",
    name: "Jonava District Municipality",
    c_c: "LT",
  },
  {
    i_c: "11",
    name: "Joniškis District Municipality",
    c_c: "LT",
  },
  {
    i_c: "12",
    name: "Jurbarkas District Municipality",
    c_c: "LT",
  },
  {
    i_c: "13",
    name: "Kaišiadorys District Municipality",
    c_c: "LT",
  },
  {
    i_c: "14",
    name: "Kalvarija municipality",
    c_c: "LT",
  },
  {
    i_c: "15",
    name: "Kaunas City Municipality",
    c_c: "LT",
  },
  {
    i_c: "16",
    name: "Kaunas District Municipality",
    c_c: "LT",
  },
  {
    i_c: "17",
    name: "Kazlų Rūda municipality",
    c_c: "LT",
  },
  {
    i_c: "18",
    name: "Kėdainiai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "19",
    name: "Kelmė District Municipality",
    c_c: "LT",
  },
  {
    i_c: "20",
    name: "Klaipeda City Municipality",
    c_c: "LT",
  },
  {
    i_c: "21",
    name: "Klaipėda District Municipality",
    c_c: "LT",
  },
  {
    i_c: "22",
    name: "Kretinga District Municipality",
    c_c: "LT",
  },
  {
    i_c: "23",
    name: "Kupiškis District Municipality",
    c_c: "LT",
  },
  {
    i_c: "24",
    name: "Lazdijai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "25",
    name: "Marijampolė Municipality",
    c_c: "LT",
  },
  {
    i_c: "26",
    name: "Mažeikiai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "27",
    name: "Molėtai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "28",
    name: "Neringa Municipality",
    c_c: "LT",
  },
  {
    i_c: "29",
    name: "Pagėgiai municipality",
    c_c: "LT",
  },
  {
    i_c: "30",
    name: "Pakruojis District Municipality",
    c_c: "LT",
  },
  {
    i_c: "31",
    name: "Palanga City Municipality",
    c_c: "LT",
  },
  {
    i_c: "32",
    name: "Panevėžys City Municipality",
    c_c: "LT",
  },
  {
    i_c: "33",
    name: "Panevėžys District Municipality",
    c_c: "LT",
  },
  {
    i_c: "34",
    name: "Pasvalys District Municipality",
    c_c: "LT",
  },
  {
    i_c: "35",
    name: "Plungė District Municipality",
    c_c: "LT",
  },
  {
    i_c: "36",
    name: "Prienai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "37",
    name: "Radviliškis District Municipality",
    c_c: "LT",
  },
  {
    i_c: "38",
    name: "Raseiniai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "39",
    name: "Rietavas municipality",
    c_c: "LT",
  },
  {
    i_c: "40",
    name: "Rokiškis District Municipality",
    c_c: "LT",
  },
  {
    i_c: "41",
    name: "Šakiai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "42",
    name: "Šalčininkai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "43",
    name: "Šiauliai City Municipality",
    c_c: "LT",
  },
  {
    i_c: "44",
    name: "Šiauliai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "45",
    name: "Šilalė District Municipality",
    c_c: "LT",
  },
  {
    i_c: "46",
    name: "Šilutė District Municipality",
    c_c: "LT",
  },
  {
    i_c: "47",
    name: "Širvintos District Municipality",
    c_c: "LT",
  },
  {
    i_c: "48",
    name: "Skuodas District Municipality",
    c_c: "LT",
  },
  {
    i_c: "49",
    name: "Švenčionys District Municipality",
    c_c: "LT",
  },
  {
    i_c: "50",
    name: "Tauragė District Municipality",
    c_c: "LT",
  },
  {
    i_c: "51",
    name: "Telšiai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "52",
    name: "Trakai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "53",
    name: "Ukmergė District Municipality",
    c_c: "LT",
  },
  {
    i_c: "54",
    name: "Utena District Municipality",
    c_c: "LT",
  },
  {
    i_c: "55",
    name: "Varėna District Municipality",
    c_c: "LT",
  },
  {
    i_c: "56",
    name: "Vilkaviškis District Municipality",
    c_c: "LT",
  },
  {
    i_c: "57",
    name: "Vilnius City Municipality",
    c_c: "LT",
  },
  {
    i_c: "58",
    name: "Vilnius District Municipality",
    c_c: "LT",
  },
  {
    i_c: "59",
    name: "Visaginas Municipality",
    c_c: "LT",
  },
  {
    i_c: "60",
    name: "Zarasai District Municipality",
    c_c: "LT",
  },
  {
    i_c: "AL",
    name: "Alytus County",
    c_c: "LT",
  },
  {
    i_c: "KL",
    name: "Klaipėda County",
    c_c: "LT",
  },
  {
    i_c: "KU",
    name: "Kaunas County",
    c_c: "LT",
  },
  {
    i_c: "MR",
    name: "Marijampolė County",
    c_c: "LT",
  },
  {
    i_c: "PN",
    name: "Panevėžys County",
    c_c: "LT",
  },
  {
    i_c: "SA",
    name: "Šiauliai County",
    c_c: "LT",
  },
  {
    i_c: "TA",
    name: "Tauragė County",
    c_c: "LT",
  },
  {
    i_c: "TE",
    name: "Telšiai County",
    c_c: "LT",
  },
  {
    i_c: "UT",
    name: "Utena County",
    c_c: "LT",
  },
  {
    i_c: "VL",
    name: "Vilnius County",
    c_c: "LT",
  },
  {
    i_c: "CA",
    name: "Canton of Capellen",
    c_c: "LU",
  },
  {
    i_c: "CL",
    name: "Canton of Clervaux",
    c_c: "LU",
  },
  {
    i_c: "D",
    name: "Diekirch District",
    c_c: "LU",
  },
  {
    i_c: "DI",
    name: "Canton of Diekirch",
    c_c: "LU",
  },
  {
    i_c: "EC",
    name: "Canton of Echternach",
    c_c: "LU",
  },
  {
    i_c: "ES",
    name: "Canton of Esch-sur-Alzette",
    c_c: "LU",
  },
  {
    i_c: "G",
    name: "Grevenmacher District",
    c_c: "LU",
  },
  {
    i_c: "GR",
    name: "Canton of Grevenmacher",
    c_c: "LU",
  },
  {
    i_c: "L",
    name: "Luxembourg District",
    c_c: "LU",
  },
  {
    i_c: "LU",
    name: "Canton of Luxembourg",
    c_c: "LU",
  },
  {
    i_c: "ME",
    name: "Canton of Mersch",
    c_c: "LU",
  },
  {
    i_c: "RD",
    name: "Canton of Redange",
    c_c: "LU",
  },
  {
    i_c: "RM",
    name: "Canton of Remich",
    c_c: "LU",
  },
  {
    i_c: "VD",
    name: "Canton of Vianden",
    c_c: "LU",
  },
  {
    i_c: "WI",
    name: "Canton of Wiltz",
    c_c: "LU",
  },
  {
    i_c: "001",
    name: "Aglona Municipality",
    c_c: "LV",
  },
  {
    i_c: "002",
    name: "Aizkraukle Municipality",
    c_c: "LV",
  },
  {
    i_c: "003",
    name: "Aizpute Municipality",
    c_c: "LV",
  },
  {
    i_c: "004",
    name: "Aknīste Municipality",
    c_c: "LV",
  },
  {
    i_c: "005",
    name: "Aloja Municipality",
    c_c: "LV",
  },
  {
    i_c: "006",
    name: "Alsunga Municipality",
    c_c: "LV",
  },
  {
    i_c: "007",
    name: "Alūksne Municipality",
    c_c: "LV",
  },
  {
    i_c: "008",
    name: "Amata Municipality",
    c_c: "LV",
  },
  {
    i_c: "009",
    name: "Ape Municipality",
    c_c: "LV",
  },
  {
    i_c: "010",
    name: "Auce Municipality",
    c_c: "LV",
  },
  {
    i_c: "012",
    name: "Babīte Municipality",
    c_c: "LV",
  },
  {
    i_c: "013",
    name: "Baldone Municipality",
    c_c: "LV",
  },
  {
    i_c: "014",
    name: "Baltinava Municipality",
    c_c: "LV",
  },
  {
    i_c: "015",
    name: "Balvi Municipality",
    c_c: "LV",
  },
  {
    i_c: "016",
    name: "Bauska Municipality",
    c_c: "LV",
  },
  {
    i_c: "017",
    name: "Beverīna Municipality",
    c_c: "LV",
  },
  {
    i_c: "018",
    name: "Brocēni Municipality",
    c_c: "LV",
  },
  {
    i_c: "019",
    name: "Burtnieki Municipality",
    c_c: "LV",
  },
  {
    i_c: "020",
    name: "Carnikava Municipality",
    c_c: "LV",
  },
  {
    i_c: "021",
    name: "Cesvaine Municipality",
    c_c: "LV",
  },
  {
    i_c: "022",
    name: "Cēsis Municipality",
    c_c: "LV",
  },
  {
    i_c: "023",
    name: "Cibla Municipality",
    c_c: "LV",
  },
  {
    i_c: "024",
    name: "Dagda Municipality",
    c_c: "LV",
  },
  {
    i_c: "025",
    name: "Daugavpils Municipality",
    c_c: "LV",
  },
  {
    i_c: "026",
    name: "Dobele Municipality",
    c_c: "LV",
  },
  {
    i_c: "027",
    name: "Dundaga Municipality",
    c_c: "LV",
  },
  {
    i_c: "028",
    name: "Durbe Municipality",
    c_c: "LV",
  },
  {
    i_c: "029",
    name: "Engure Municipality",
    c_c: "LV",
  },
  {
    i_c: "030",
    name: "Ērgļi Municipality",
    c_c: "LV",
  },
  {
    i_c: "031",
    name: "Garkalne Municipality",
    c_c: "LV",
  },
  {
    i_c: "032",
    name: "Grobiņa Municipality",
    c_c: "LV",
  },
  {
    i_c: "033",
    name: "Gulbene Municipality",
    c_c: "LV",
  },
  {
    i_c: "034",
    name: "Iecava Municipality",
    c_c: "LV",
  },
  {
    i_c: "035",
    name: "Ikšķile Municipality",
    c_c: "LV",
  },
  {
    i_c: "036",
    name: "Ilūkste Municipality",
    c_c: "LV",
  },
  {
    i_c: "037",
    name: "Inčukalns Municipality",
    c_c: "LV",
  },
  {
    i_c: "038",
    name: "Jaunjelgava Municipality",
    c_c: "LV",
  },
  {
    i_c: "039",
    name: "Jaunpiebalga Municipality",
    c_c: "LV",
  },
  {
    i_c: "040",
    name: "Jaunpils Municipality",
    c_c: "LV",
  },
  {
    i_c: "041",
    name: "Jelgava Municipality",
    c_c: "LV",
  },
  {
    i_c: "042",
    name: "Jēkabpils Municipality",
    c_c: "LV",
  },
  {
    i_c: "043",
    name: "Kandava Municipality",
    c_c: "LV",
  },
  {
    i_c: "044",
    name: "Kārsava Municipality",
    c_c: "LV",
  },
  {
    i_c: "045",
    name: "Kocēni Municipality",
    c_c: "LV",
  },
  {
    i_c: "046",
    name: "Koknese Municipality",
    c_c: "LV",
  },
  {
    i_c: "047",
    name: "Krāslava Municipality",
    c_c: "LV",
  },
  {
    i_c: "048",
    name: "Krimulda Municipality",
    c_c: "LV",
  },
  {
    i_c: "049",
    name: "Krustpils Municipality",
    c_c: "LV",
  },
  {
    i_c: "050",
    name: "Kuldīga Municipality",
    c_c: "LV",
  },
  {
    i_c: "051",
    name: "Ķegums Municipality",
    c_c: "LV",
  },
  {
    i_c: "052",
    name: "Ķekava Municipality",
    c_c: "LV",
  },
  {
    i_c: "053",
    name: "Lielvārde Municipality",
    c_c: "LV",
  },
  {
    i_c: "054",
    name: "Limbaži Municipality",
    c_c: "LV",
  },
  {
    i_c: "055",
    name: "Līgatne Municipality",
    c_c: "LV",
  },
  {
    i_c: "056",
    name: "Līvāni Municipality",
    c_c: "LV",
  },
  {
    i_c: "057",
    name: "Lubāna Municipality",
    c_c: "LV",
  },
  {
    i_c: "058",
    name: "Ludza Municipality",
    c_c: "LV",
  },
  {
    i_c: "059",
    name: "Madona Municipality",
    c_c: "LV",
  },
  {
    i_c: "060",
    name: "Mazsalaca Municipality",
    c_c: "LV",
  },
  {
    i_c: "061",
    name: "Mālpils Municipality",
    c_c: "LV",
  },
  {
    i_c: "062",
    name: "Mārupe Municipality",
    c_c: "LV",
  },
  {
    i_c: "063",
    name: "Mērsrags Municipality",
    c_c: "LV",
  },
  {
    i_c: "064",
    name: "Naukšēni Municipality",
    c_c: "LV",
  },
  {
    i_c: "065",
    name: "Nereta Municipality",
    c_c: "LV",
  },
  {
    i_c: "066",
    name: "Nīca Municipality",
    c_c: "LV",
  },
  {
    i_c: "067",
    name: "Ogre Municipality",
    c_c: "LV",
  },
  {
    i_c: "068",
    name: "Olaine Municipality",
    c_c: "LV",
  },
  {
    i_c: "069",
    name: "Ozolnieki Municipality",
    c_c: "LV",
  },
  {
    i_c: "070",
    name: "Pārgauja Municipality",
    c_c: "LV",
  },
  {
    i_c: "071",
    name: "Pāvilosta Municipality",
    c_c: "LV",
  },
  {
    i_c: "072",
    name: "Pļaviņas Municipality",
    c_c: "LV",
  },
  {
    i_c: "073",
    name: "Preiļi Municipality",
    c_c: "LV",
  },
  {
    i_c: "074",
    name: "Priekule Municipality",
    c_c: "LV",
  },
  {
    i_c: "075",
    name: "Priekuļi Municipality",
    c_c: "LV",
  },
  {
    i_c: "076",
    name: "Rauna Municipality",
    c_c: "LV",
  },
  {
    i_c: "077",
    name: "Rēzekne Municipality",
    c_c: "LV",
  },
  {
    i_c: "078",
    name: "Riebiņi Municipality",
    c_c: "LV",
  },
  {
    i_c: "079",
    name: "Roja Municipality",
    c_c: "LV",
  },
  {
    i_c: "080",
    name: "Ropaži Municipality",
    c_c: "LV",
  },
  {
    i_c: "081",
    name: "Rucava Municipality",
    c_c: "LV",
  },
  {
    i_c: "082",
    name: "Rugāji Municipality",
    c_c: "LV",
  },
  {
    i_c: "083",
    name: "Rundāle Municipality",
    c_c: "LV",
  },
  {
    i_c: "084",
    name: "Rūjiena Municipality",
    c_c: "LV",
  },
  {
    i_c: "085",
    name: "Sala Municipality",
    c_c: "LV",
  },
  {
    i_c: "086",
    name: "Salacgrīva Municipality",
    c_c: "LV",
  },
  {
    i_c: "087",
    name: "Salaspils Municipality",
    c_c: "LV",
  },
  {
    i_c: "088",
    name: "Saldus Municipality",
    c_c: "LV",
  },
  {
    i_c: "089",
    name: "Saulkrasti Municipality",
    c_c: "LV",
  },
  {
    i_c: "090",
    name: "Sēja Municipality",
    c_c: "LV",
  },
  {
    i_c: "091",
    name: "Sigulda Municipality",
    c_c: "LV",
  },
  {
    i_c: "092",
    name: "Skrīveri Municipality",
    c_c: "LV",
  },
  {
    i_c: "093",
    name: "Skrunda Municipality",
    c_c: "LV",
  },
  {
    i_c: "094",
    name: "Smiltene Municipality",
    c_c: "LV",
  },
  {
    i_c: "095",
    name: "Stopiņi Municipality",
    c_c: "LV",
  },
  {
    i_c: "096",
    name: "Strenči Municipality",
    c_c: "LV",
  },
  {
    i_c: "097",
    name: "Talsi Municipality",
    c_c: "LV",
  },
  {
    i_c: "098",
    name: "Tērvete Municipality",
    c_c: "LV",
  },
  {
    i_c: "099",
    name: "Tukums Municipality",
    c_c: "LV",
  },
  {
    i_c: "100",
    name: "Vaiņode Municipality",
    c_c: "LV",
  },
  {
    i_c: "101",
    name: "Valka Municipality",
    c_c: "LV",
  },
  {
    i_c: "102",
    name: "Varakļāni Municipality",
    c_c: "LV",
  },
  {
    i_c: "103",
    name: "Vārkava Municipality",
    c_c: "LV",
  },
  {
    i_c: "104",
    name: "Vecpiebalga Municipality",
    c_c: "LV",
  },
  {
    i_c: "105",
    name: "Vecumnieki Municipality",
    c_c: "LV",
  },
  {
    i_c: "106",
    name: "Ventspils Municipality",
    c_c: "LV",
  },
  {
    i_c: "107",
    name: "Viesīte Municipality",
    c_c: "LV",
  },
  {
    i_c: "108",
    name: "Viļaka Municipality",
    c_c: "LV",
  },
  {
    i_c: "109",
    name: "Viļāni Municipality",
    c_c: "LV",
  },
  {
    i_c: "110",
    name: "Zilupe Municipality",
    c_c: "LV",
  },
  {
    i_c: "DGV",
    name: "Daugavpils",
    c_c: "LV",
  },
  {
    i_c: "JEL",
    name: "Jelgava",
    c_c: "LV",
  },
  {
    i_c: "JKB",
    name: "Jēkabpils",
    c_c: "LV",
  },
  {
    i_c: "JUR",
    name: "Jūrmala",
    c_c: "LV",
  },
  {
    i_c: "LPX",
    name: "Liepāja",
    c_c: "LV",
  },
  {
    i_c: "REZ",
    name: "Rēzekne",
    c_c: "LV",
  },
  {
    i_c: "RIX",
    name: "Riga",
    c_c: "LV",
  },
  {
    i_c: "VEN",
    name: "Ventspils",
    c_c: "LV",
  },
  {
    i_c: "VMR",
    name: "Valmiera",
    c_c: "LV",
  },
  {
    i_c: "BA",
    name: "Benghazi",
    c_c: "LY",
  },
  {
    i_c: "DR",
    name: "Derna District",
    c_c: "LY",
  },
  {
    i_c: "GT",
    name: "Ghat District",
    c_c: "LY",
  },
  {
    i_c: "JA",
    name: "Jabal al Akhdar",
    c_c: "LY",
  },
  {
    i_c: "JG",
    name: "Jabal al Gharbi District",
    c_c: "LY",
  },
  {
    i_c: "JI",
    name: "Jafara",
    c_c: "LY",
  },
  {
    i_c: "JU",
    name: "Jufra",
    c_c: "LY",
  },
  {
    i_c: "KF",
    name: "Kufra District",
    c_c: "LY",
  },
  {
    i_c: "MB",
    name: "Murqub",
    c_c: "LY",
  },
  {
    i_c: "MI",
    name: "Misrata District",
    c_c: "LY",
  },
  {
    i_c: "MJ",
    name: "Marj District",
    c_c: "LY",
  },
  {
    i_c: "MQ",
    name: "Murzuq District",
    c_c: "LY",
  },
  {
    i_c: "NL",
    name: "Nalut District",
    c_c: "LY",
  },
  {
    i_c: "NQ",
    name: "Nuqat al Khams",
    c_c: "LY",
  },
  {
    i_c: "SB",
    name: "Sabha District",
    c_c: "LY",
  },
  {
    i_c: "SR",
    name: "Sirte District",
    c_c: "LY",
  },
  {
    i_c: "TB",
    name: "Tripoli District",
    c_c: "LY",
  },
  {
    i_c: "WA",
    name: "Al Wahat District",
    c_c: "LY",
  },
  {
    i_c: "WD",
    name: "Wadi al Hayaa District",
    c_c: "LY",
  },
  {
    i_c: "WS",
    name: "Wadi al Shatii District",
    c_c: "LY",
  },
  {
    i_c: "ZA",
    name: "Zawiya District",
    c_c: "LY",
  },
  {
    i_c: "01",
    name: "Tanger-Tétouan-Al Hoceïma",
    c_c: "MA",
  },
  {
    i_c: "02",
    name: "L'Oriental",
    c_c: "MA",
  },
  {
    i_c: "03",
    name: "Fès-Meknès",
    c_c: "MA",
  },
  {
    i_c: "04",
    name: "Rabat-Salé-Kénitra",
    c_c: "MA",
  },
  {
    i_c: "05",
    name: "Béni Mellal-Khénifra",
    c_c: "MA",
  },
  {
    i_c: "06",
    name: "Casablanca-Settat",
    c_c: "MA",
  },
  {
    i_c: "07",
    name: "Marrakesh-Safi",
    c_c: "MA",
  },
  {
    i_c: "08",
    name: "Drâa-Tafilalet",
    c_c: "MA",
  },
  {
    i_c: "09",
    name: "Souss-Massa",
    c_c: "MA",
  },
  {
    i_c: "10",
    name: "Guelmim-Oued Noun (EH-partial)",
    c_c: "MA",
  },
  {
    i_c: "11",
    name: "Laâyoune-Sakia El Hamra (EH-partial)",
    c_c: "MA",
  },
  {
    i_c: "12",
    name: "Dakhla-Oued Ed-Dahab (EH)",
    c_c: "MA",
  },
  {
    i_c: "AGD",
    name: "Agadir-Ida-Ou-Tanane",
    c_c: "MA",
  },
  {
    i_c: "AOU",
    name: "Aousserd (EH)",
    c_c: "MA",
  },
  {
    i_c: "ASZ",
    name: "Assa-Zag (EH-partial)",
    c_c: "MA",
  },
  {
    i_c: "AZI",
    name: "Azilal",
    c_c: "MA",
  },
  {
    i_c: "BEM",
    name: "Béni Mellal",
    c_c: "MA",
  },
  {
    i_c: "BER",
    name: "Berkane",
    c_c: "MA",
  },
  {
    i_c: "BES",
    name: "Benslimane",
    c_c: "MA",
  },
  {
    i_c: "BOD",
    name: "Boujdour (EH)",
    c_c: "MA",
  },
  {
    i_c: "BOM",
    name: "Boulemane",
    c_c: "MA",
  },
  {
    i_c: "BRR",
    name: "Berrechid",
    c_c: "MA",
  },
  {
    i_c: "CAS",
    name: "Casablanca",
    c_c: "MA",
  },
  {
    i_c: "CHE",
    name: "Chefchaouen",
    c_c: "MA",
  },
  {
    i_c: "CHI",
    name: "Chichaoua",
    c_c: "MA",
  },
  {
    i_c: "CHT",
    name: "Chtouka-Ait Baha",
    c_c: "MA",
  },
  {
    i_c: "DRI",
    name: "Driouch",
    c_c: "MA",
  },
  {
    i_c: "ERR",
    name: "Errachidia",
    c_c: "MA",
  },
  {
    i_c: "ESI",
    name: "Essaouira",
    c_c: "MA",
  },
  {
    i_c: "ESM",
    name: "Es-Semara (EH-partial)",
    c_c: "MA",
  },
  {
    i_c: "FAH",
    name: "Fahs-Anjra",
    c_c: "MA",
  },
  {
    i_c: "FES",
    name: "Fès",
    c_c: "MA",
  },
  {
    i_c: "FIG",
    name: "Figuig",
    c_c: "MA",
  },
  {
    i_c: "FQH",
    name: "Fquih Ben Salah",
    c_c: "MA",
  },
  {
    i_c: "GUE",
    name: "Guelmim",
    c_c: "MA",
  },
  {
    i_c: "GUF",
    name: "Guercif",
    c_c: "MA",
  },
  {
    i_c: "HAJ",
    name: "El Hajeb",
    c_c: "MA",
  },
  {
    i_c: "HAO",
    name: "Al Haouz",
    c_c: "MA",
  },
  {
    i_c: "HOC",
    name: "Al Hoceïma",
    c_c: "MA",
  },
  {
    i_c: "IFR",
    name: "Ifrane",
    c_c: "MA",
  },
  {
    i_c: "INE",
    name: "Inezgane-Ait Melloul",
    c_c: "MA",
  },
  {
    i_c: "JDI",
    name: "El Jadida",
    c_c: "MA",
  },
  {
    i_c: "JRA",
    name: "Jerada",
    c_c: "MA",
  },
  {
    i_c: "KEN",
    name: "Kénitra",
    c_c: "MA",
  },
  {
    i_c: "KES",
    name: "El Kelâa des Sraghna",
    c_c: "MA",
  },
  {
    i_c: "KHE",
    name: "Khémisset",
    c_c: "MA",
  },
  {
    i_c: "KHN",
    name: "Khénifra",
    c_c: "MA",
  },
  {
    i_c: "KHO",
    name: "Khouribga",
    c_c: "MA",
  },
  {
    i_c: "LAA",
    name: "Laâyoune (EH)",
    c_c: "MA",
  },
  {
    i_c: "LAR",
    name: "Larache",
    c_c: "MA",
  },
  {
    i_c: "MAR",
    name: "Marrakech",
    c_c: "MA",
  },
  {
    i_c: "MDF",
    name: "M’diq-Fnideq",
    c_c: "MA",
  },
  {
    i_c: "MED",
    name: "Médiouna",
    c_c: "MA",
  },
  {
    i_c: "MEK",
    name: "Meknès",
    c_c: "MA",
  },
  {
    i_c: "MID",
    name: "Midelt",
    c_c: "MA",
  },
  {
    i_c: "MOH",
    name: "Mohammadia",
    c_c: "MA",
  },
  {
    i_c: "MOU",
    name: "Moulay Yacoub",
    c_c: "MA",
  },
  {
    i_c: "NAD",
    name: "Nador",
    c_c: "MA",
  },
  {
    i_c: "NOU",
    name: "Nouaceur",
    c_c: "MA",
  },
  {
    i_c: "OUA",
    name: "Ouarzazate",
    c_c: "MA",
  },
  {
    i_c: "OUD",
    name: "Oued Ed-Dahab (EH)",
    c_c: "MA",
  },
  {
    i_c: "OUJ",
    name: "Oujda-Angad",
    c_c: "MA",
  },
  {
    i_c: "OUZ",
    name: "Ouezzane",
    c_c: "MA",
  },
  {
    i_c: "RAB",
    name: "Rabat",
    c_c: "MA",
  },
  {
    i_c: "REH",
    name: "Rehamna",
    c_c: "MA",
  },
  {
    i_c: "SAF",
    name: "Safi",
    c_c: "MA",
  },
  {
    i_c: "SAL",
    name: "Salé",
    c_c: "MA",
  },
  {
    i_c: "SEF",
    name: "Sefrou",
    c_c: "MA",
  },
  {
    i_c: "SET",
    name: "Settat",
    c_c: "MA",
  },
  {
    i_c: "SIB",
    name: "Sidi Bennour",
    c_c: "MA",
  },
  {
    i_c: "SIF",
    name: "Sidi Ifni",
    c_c: "MA",
  },
  {
    i_c: "SIK",
    name: "Sidi Kacem",
    c_c: "MA",
  },
  {
    i_c: "SIL",
    name: "Sidi Slimane",
    c_c: "MA",
  },
  {
    i_c: "SKH",
    name: "Skhirate-Témara",
    c_c: "MA",
  },
  {
    i_c: "TAF",
    name: "Tarfaya (EH-partial)",
    c_c: "MA",
  },
  {
    i_c: "TAI",
    name: "Taourirt",
    c_c: "MA",
  },
  {
    i_c: "TAO",
    name: "Taounate",
    c_c: "MA",
  },
  {
    i_c: "TAR",
    name: "Taroudannt",
    c_c: "MA",
  },
  {
    i_c: "TAT",
    name: "Tata",
    c_c: "MA",
  },
  {
    i_c: "TAZ",
    name: "Taza",
    c_c: "MA",
  },
  {
    i_c: "TET",
    name: "Tétouan",
    c_c: "MA",
  },
  {
    i_c: "TIN",
    name: "Tinghir",
    c_c: "MA",
  },
  {
    i_c: "TIZ",
    name: "Tiznit",
    c_c: "MA",
  },
  {
    i_c: "TNG",
    name: "Tanger-Assilah",
    c_c: "MA",
  },
  {
    i_c: "TNT",
    name: "Tan-Tan (EH-partial)",
    c_c: "MA",
  },
  {
    i_c: "YUS",
    name: "Youssoufia",
    c_c: "MA",
  },
  {
    i_c: "ZAG",
    name: "Zagora",
    c_c: "MA",
  },
  {
    i_c: "CL",
    name: "La Colle",
    c_c: "MC",
  },
  {
    i_c: "CO",
    name: "La Condamine",
    c_c: "MC",
  },
  {
    i_c: "MG",
    name: "Moneghetti",
    c_c: "MC",
  },
  {
    i_c: "AN",
    name: "Anenii Noi District",
    c_c: "MD",
  },
  {
    i_c: "BA",
    name: "Bălți Municipality",
    c_c: "MD",
  },
  {
    i_c: "BD",
    name: "Bender Municipality",
    c_c: "MD",
  },
  {
    i_c: "BR",
    name: "Briceni District",
    c_c: "MD",
  },
  {
    i_c: "BS",
    name: "Basarabeasca District",
    c_c: "MD",
  },
  {
    i_c: "CA",
    name: "Cahul District",
    c_c: "MD",
  },
  {
    i_c: "CL",
    name: "Călărași District",
    c_c: "MD",
  },
  {
    i_c: "CM",
    name: "Cimișlia District",
    c_c: "MD",
  },
  {
    i_c: "CR",
    name: "Criuleni District",
    c_c: "MD",
  },
  {
    i_c: "CS",
    name: "Căușeni District",
    c_c: "MD",
  },
  {
    i_c: "CT",
    name: "Cantemir District",
    c_c: "MD",
  },
  {
    i_c: "CU",
    name: "Chișinău Municipality",
    c_c: "MD",
  },
  {
    i_c: "DO",
    name: "Dondușeni District",
    c_c: "MD",
  },
  {
    i_c: "DR",
    name: "Drochia District",
    c_c: "MD",
  },
  {
    i_c: "DU",
    name: "Dubăsari District",
    c_c: "MD",
  },
  {
    i_c: "ED",
    name: "Edineț District",
    c_c: "MD",
  },
  {
    i_c: "FA",
    name: "Fălești District",
    c_c: "MD",
  },
  {
    i_c: "FL",
    name: "Florești District",
    c_c: "MD",
  },
  {
    i_c: "GA",
    name: "Gagauzia",
    c_c: "MD",
  },
  {
    i_c: "GL",
    name: "Glodeni District",
    c_c: "MD",
  },
  {
    i_c: "HI",
    name: "Hîncești District",
    c_c: "MD",
  },
  {
    i_c: "IA",
    name: "Ialoveni District",
    c_c: "MD",
  },
  {
    i_c: "NI",
    name: "Nisporeni District",
    c_c: "MD",
  },
  {
    i_c: "OC",
    name: "Ocnița District",
    c_c: "MD",
  },
  {
    i_c: "OR",
    name: "Orhei District",
    c_c: "MD",
  },
  {
    i_c: "RE",
    name: "Rezina District",
    c_c: "MD",
  },
  {
    i_c: "RI",
    name: "Rîșcani District",
    c_c: "MD",
  },
  {
    i_c: "SD",
    name: "Șoldănești District",
    c_c: "MD",
  },
  {
    i_c: "SI",
    name: "Sîngerei District",
    c_c: "MD",
  },
  {
    i_c: "SN",
    name: "Transnistria autonomous territorial unit",
    c_c: "MD",
  },
  {
    i_c: "SO",
    name: "Soroca District",
    c_c: "MD",
  },
  {
    i_c: "ST",
    name: "Strășeni District",
    c_c: "MD",
  },
  {
    i_c: "SV",
    name: "Ștefan Vodă District",
    c_c: "MD",
  },
  {
    i_c: "TA",
    name: "Taraclia District",
    c_c: "MD",
  },
  {
    i_c: "TE",
    name: "Telenești District",
    c_c: "MD",
  },
  {
    i_c: "UN",
    name: "Ungheni District",
    c_c: "MD",
  },
  {
    i_c: "01",
    name: "Andrijevica Municipality",
    c_c: "ME",
  },
  {
    i_c: "02",
    name: "Bar Municipality",
    c_c: "ME",
  },
  {
    i_c: "03",
    name: "Berane Municipality",
    c_c: "ME",
  },
  {
    i_c: "04",
    name: "Bijelo Polje Municipality",
    c_c: "ME",
  },
  {
    i_c: "05",
    name: "Budva Municipality",
    c_c: "ME",
  },
  {
    i_c: "06",
    name: "Old Royal Capital Cetinje",
    c_c: "ME",
  },
  {
    i_c: "07",
    name: "Danilovgrad Municipality",
    c_c: "ME",
  },
  {
    i_c: "09",
    name: "Kolašin Municipality",
    c_c: "ME",
  },
  {
    i_c: "10",
    name: "Kotor Municipality",
    c_c: "ME",
  },
  {
    i_c: "11",
    name: "Mojkovac Municipality",
    c_c: "ME",
  },
  {
    i_c: "12",
    name: "Nikšić Municipality",
    c_c: "ME",
  },
  {
    i_c: "13",
    name: "Plav Municipality",
    c_c: "ME",
  },
  {
    i_c: "14",
    name: "Pljevlja Municipality",
    c_c: "ME",
  },
  {
    i_c: "15",
    name: "Plužine Municipality",
    c_c: "ME",
  },
  {
    i_c: "16",
    name: "Podgorica Municipality",
    c_c: "ME",
  },
  {
    i_c: "17",
    name: "Rožaje Municipality",
    c_c: "ME",
  },
  {
    i_c: "18",
    name: "Šavnik Municipality",
    c_c: "ME",
  },
  {
    i_c: "19",
    name: "Tivat Municipality",
    c_c: "ME",
  },
  {
    i_c: "20",
    name: "Ulcinj Municipality",
    c_c: "ME",
  },
  {
    i_c: "21",
    name: "Žabljak Municipality",
    c_c: "ME",
  },
  {
    i_c: "22",
    name: "Gusinje Municipality",
    c_c: "ME",
  },
  {
    i_c: "23",
    name: "Petnjica Municipality",
    c_c: "ME",
  },
  {
    i_c: "A",
    name: "Toamasina Province",
    c_c: "MG",
  },
  {
    i_c: "D",
    name: "Antsiranana Province",
    c_c: "MG",
  },
  {
    i_c: "F",
    name: "Fianarantsoa Province",
    c_c: "MG",
  },
  {
    i_c: "M",
    name: "Mahajanga Province",
    c_c: "MG",
  },
  {
    i_c: "T",
    name: "Antananarivo Province",
    c_c: "MG",
  },
  {
    i_c: "U",
    name: "Toliara Province",
    c_c: "MG",
  },
  {
    i_c: "L",
    name: "Ralik Chain",
    c_c: "MH",
  },
  {
    i_c: "T",
    name: "Ratak Chain",
    c_c: "MH",
  },
  {
    i_c: "01",
    name: "Aerodrom Municipality",
    c_c: "MK",
  },
  {
    i_c: "02",
    name: "Aračinovo Municipality",
    c_c: "MK",
  },
  {
    i_c: "03",
    name: "Berovo Municipality",
    c_c: "MK",
  },
  {
    i_c: "04",
    name: "Bitola Municipality",
    c_c: "MK",
  },
  {
    i_c: "05",
    name: "Bogdanci Municipality",
    c_c: "MK",
  },
  {
    i_c: "06",
    name: "Bogovinje Municipality",
    c_c: "MK",
  },
  {
    i_c: "07",
    name: "Bosilovo Municipality",
    c_c: "MK",
  },
  {
    i_c: "08",
    name: "Brvenica Municipality",
    c_c: "MK",
  },
  {
    i_c: "09",
    name: "Butel Municipality",
    c_c: "MK",
  },
  {
    i_c: "10",
    name: "Valandovo Municipality",
    c_c: "MK",
  },
  {
    i_c: "11",
    name: "Vasilevo Municipality",
    c_c: "MK",
  },
  {
    i_c: "12",
    name: "Vevčani Municipality",
    c_c: "MK",
  },
  {
    i_c: "13",
    name: "Veles Municipality",
    c_c: "MK",
  },
  {
    i_c: "14",
    name: "Vinica Municipality",
    c_c: "MK",
  },
  {
    i_c: "15",
    name: "Vraneštica Municipality",
    c_c: "MK",
  },
  {
    i_c: "16",
    name: "Vrapčište Municipality",
    c_c: "MK",
  },
  {
    i_c: "17",
    name: "Gazi Baba Municipality",
    c_c: "MK",
  },
  {
    i_c: "18",
    name: "Gevgelija Municipality",
    c_c: "MK",
  },
  {
    i_c: "19",
    name: "Gostivar Municipality",
    c_c: "MK",
  },
  {
    i_c: "20",
    name: "Gradsko Municipality",
    c_c: "MK",
  },
  {
    i_c: "22",
    name: "Debarca Municipality",
    c_c: "MK",
  },
  {
    i_c: "23",
    name: "Delčevo Municipality",
    c_c: "MK",
  },
  {
    i_c: "24",
    name: "Demir Kapija Municipality",
    c_c: "MK",
  },
  {
    i_c: "25",
    name: "Demir Hisar Municipality",
    c_c: "MK",
  },
  {
    i_c: "26",
    name: "Dojran Municipality",
    c_c: "MK",
  },
  {
    i_c: "27",
    name: "Dolneni Municipality",
    c_c: "MK",
  },
  {
    i_c: "28",
    name: "Drugovo Municipality",
    c_c: "MK",
  },
  {
    i_c: "29",
    name: "Gjorče Petrov Municipality",
    c_c: "MK",
  },
  {
    i_c: "30",
    name: "Želino Municipality",
    c_c: "MK",
  },
  {
    i_c: "31",
    name: "Zajas Municipality",
    c_c: "MK",
  },
  {
    i_c: "32",
    name: "Zelenikovo Municipality",
    c_c: "MK",
  },
  {
    i_c: "33",
    name: "Zrnovci Municipality",
    c_c: "MK",
  },
  {
    i_c: "34",
    name: "Ilinden Municipality",
    c_c: "MK",
  },
  {
    i_c: "35",
    name: "Jegunovce Municipality",
    c_c: "MK",
  },
  {
    i_c: "36",
    name: "Kavadarci Municipality",
    c_c: "MK",
  },
  {
    i_c: "37",
    name: "Karbinci",
    c_c: "MK",
  },
  {
    i_c: "38",
    name: "Karpoš Municipality",
    c_c: "MK",
  },
  {
    i_c: "39",
    name: "Kisela Voda Municipality",
    c_c: "MK",
  },
  {
    i_c: "40",
    name: "Kičevo Municipality",
    c_c: "MK",
  },
  {
    i_c: "41",
    name: "Konče Municipality",
    c_c: "MK",
  },
  {
    i_c: "42",
    name: "Kočani Municipality",
    c_c: "MK",
  },
  {
    i_c: "43",
    name: "Kratovo Municipality",
    c_c: "MK",
  },
  {
    i_c: "44",
    name: "Kriva Palanka Municipality",
    c_c: "MK",
  },
  {
    i_c: "45",
    name: "Krivogaštani Municipality",
    c_c: "MK",
  },
  {
    i_c: "46",
    name: "Kruševo Municipality",
    c_c: "MK",
  },
  {
    i_c: "47",
    name: "Kumanovo Municipality",
    c_c: "MK",
  },
  {
    i_c: "48",
    name: "Lipkovo Municipality",
    c_c: "MK",
  },
  {
    i_c: "49",
    name: "Lozovo Municipality",
    c_c: "MK",
  },
  {
    i_c: "50",
    name: "Mavrovo and Rostuša Municipality",
    c_c: "MK",
  },
  {
    i_c: "51",
    name: "Makedonska Kamenica Municipality",
    c_c: "MK",
  },
  {
    i_c: "52",
    name: "Makedonski Brod Municipality",
    c_c: "MK",
  },
  {
    i_c: "53",
    name: "Mogila Municipality",
    c_c: "MK",
  },
  {
    i_c: "54",
    name: "Negotino Municipality",
    c_c: "MK",
  },
  {
    i_c: "55",
    name: "Novaci Municipality",
    c_c: "MK",
  },
  {
    i_c: "56",
    name: "Novo Selo Municipality",
    c_c: "MK",
  },
  {
    i_c: "57",
    name: "Oslomej Municipality",
    c_c: "MK",
  },
  {
    i_c: "58",
    name: "Ohrid Municipality",
    c_c: "MK",
  },
  {
    i_c: "59",
    name: "Petrovec Municipality",
    c_c: "MK",
  },
  {
    i_c: "60",
    name: "Pehčevo Municipality",
    c_c: "MK",
  },
  {
    i_c: "61",
    name: "Plasnica Municipality",
    c_c: "MK",
  },
  {
    i_c: "62",
    name: "Prilep Municipality",
    c_c: "MK",
  },
  {
    i_c: "63",
    name: "Probištip Municipality",
    c_c: "MK",
  },
  {
    i_c: "64",
    name: "Radoviš Municipality",
    c_c: "MK",
  },
  {
    i_c: "65",
    name: "Rankovce Municipality",
    c_c: "MK",
  },
  {
    i_c: "66",
    name: "Resen Municipality",
    c_c: "MK",
  },
  {
    i_c: "67",
    name: "Rosoman Municipality",
    c_c: "MK",
  },
  {
    i_c: "68",
    name: "Saraj Municipality",
    c_c: "MK",
  },
  {
    i_c: "69",
    name: "Sveti Nikole Municipality",
    c_c: "MK",
  },
  {
    i_c: "70",
    name: "Sopište Municipality",
    c_c: "MK",
  },
  {
    i_c: "71",
    name: "Staro Nagoričane Municipality",
    c_c: "MK",
  },
  {
    i_c: "72",
    name: "Struga Municipality",
    c_c: "MK",
  },
  {
    i_c: "73",
    name: "Strumica Municipality",
    c_c: "MK",
  },
  {
    i_c: "74",
    name: "Studeničani Municipality",
    c_c: "MK",
  },
  {
    i_c: "75",
    name: "Tearce Municipality",
    c_c: "MK",
  },
  {
    i_c: "76",
    name: "Tetovo Municipality",
    c_c: "MK",
  },
  {
    i_c: "77",
    name: "Centar Municipality",
    c_c: "MK",
  },
  {
    i_c: "78",
    name: "Centar Župa Municipality",
    c_c: "MK",
  },
  {
    i_c: "79",
    name: "Čair Municipality",
    c_c: "MK",
  },
  {
    i_c: "80",
    name: "Čaška Municipality",
    c_c: "MK",
  },
  {
    i_c: "81",
    name: "Češinovo-Obleševo Municipality",
    c_c: "MK",
  },
  {
    i_c: "82",
    name: "Čučer-Sandevo Municipality",
    c_c: "MK",
  },
  {
    i_c: "83",
    name: "Štip Municipality",
    c_c: "MK",
  },
  {
    i_c: "84",
    name: "Šuto Orizari Municipality",
    c_c: "MK",
  },
  {
    i_c: "85",
    name: "Greater Skopje",
    c_c: "MK",
  },
  {
    i_c: "1",
    name: "Kayes Region",
    c_c: "ML",
  },
  {
    i_c: "10",
    name: "Taoudénit Region",
    c_c: "ML",
  },
  {
    i_c: "2",
    name: "Koulikoro Region",
    c_c: "ML",
  },
  {
    i_c: "3",
    name: "Sikasso Region",
    c_c: "ML",
  },
  {
    i_c: "4",
    name: "Ségou Region",
    c_c: "ML",
  },
  {
    i_c: "5",
    name: "Mopti Region",
    c_c: "ML",
  },
  {
    i_c: "6",
    name: "Tombouctou Region",
    c_c: "ML",
  },
  {
    i_c: "7",
    name: "Gao Region",
    c_c: "ML",
  },
  {
    i_c: "8",
    name: "Kidal Region",
    c_c: "ML",
  },
  {
    i_c: "9",
    name: "Ménaka Region",
    c_c: "ML",
  },
  {
    i_c: "BKO",
    name: "Bamako",
    c_c: "ML",
  },
  {
    i_c: "01",
    name: "Sagaing Region",
    c_c: "MM",
  },
  {
    i_c: "02",
    name: "Bago",
    c_c: "MM",
  },
  {
    i_c: "03",
    name: "Magway Region",
    c_c: "MM",
  },
  {
    i_c: "04",
    name: "Mandalay Region",
    c_c: "MM",
  },
  {
    i_c: "05",
    name: "Tanintharyi Region",
    c_c: "MM",
  },
  {
    i_c: "06",
    name: "Yangon Region",
    c_c: "MM",
  },
  {
    i_c: "07",
    name: "Ayeyarwady Region",
    c_c: "MM",
  },
  {
    i_c: "11",
    name: "Kachin State",
    c_c: "MM",
  },
  {
    i_c: "12",
    name: "Kayah State",
    c_c: "MM",
  },
  {
    i_c: "13",
    name: "Kayin State",
    c_c: "MM",
  },
  {
    i_c: "14",
    name: "Chin State",
    c_c: "MM",
  },
  {
    i_c: "15",
    name: "Mon State",
    c_c: "MM",
  },
  {
    i_c: "16",
    name: "Rakhine State",
    c_c: "MM",
  },
  {
    i_c: "17",
    name: "Shan State",
    c_c: "MM",
  },
  {
    i_c: "18",
    name: "Naypyidaw Union Territory",
    c_c: "MM",
  },
  {
    i_c: "035",
    name: "Orkhon Province",
    c_c: "MN",
  },
  {
    i_c: "037",
    name: "Darkhan-Uul Province",
    c_c: "MN",
  },
  {
    i_c: "039",
    name: "Khentii Province",
    c_c: "MN",
  },
  {
    i_c: "041",
    name: "Khövsgöl Province",
    c_c: "MN",
  },
  {
    i_c: "043",
    name: "Khovd Province",
    c_c: "MN",
  },
  {
    i_c: "046",
    name: "Uvs Province",
    c_c: "MN",
  },
  {
    i_c: "047",
    name: "Töv Province",
    c_c: "MN",
  },
  {
    i_c: "049",
    name: "Selenge Province",
    c_c: "MN",
  },
  {
    i_c: "051",
    name: "Sükhbaatar Province",
    c_c: "MN",
  },
  {
    i_c: "053",
    name: "Ömnögovi Province",
    c_c: "MN",
  },
  {
    i_c: "055",
    name: "Övörkhangai Province",
    c_c: "MN",
  },
  {
    i_c: "057",
    name: "Zavkhan Province",
    c_c: "MN",
  },
  {
    i_c: "059",
    name: "Dundgovi Province",
    c_c: "MN",
  },
  {
    i_c: "061",
    name: "Dornod Province",
    c_c: "MN",
  },
  {
    i_c: "063",
    name: "Dornogovi Province",
    c_c: "MN",
  },
  {
    i_c: "064",
    name: "Govisümber Province",
    c_c: "MN",
  },
  {
    i_c: "065",
    name: "Govi-Altai Province",
    c_c: "MN",
  },
  {
    i_c: "067",
    name: "Bulgan Province",
    c_c: "MN",
  },
  {
    i_c: "069",
    name: "Bayankhongor Province",
    c_c: "MN",
  },
  {
    i_c: "071",
    name: "Bayan-Ölgii Province",
    c_c: "MN",
  },
  {
    i_c: "073",
    name: "Arkhangai Province",
    c_c: "MN",
  },
  {
    i_c: "01",
    name: "Hodh Ech Chargui Region",
    c_c: "MR",
  },
  {
    i_c: "02",
    name: "Hodh El Gharbi Region",
    c_c: "MR",
  },
  {
    i_c: "03",
    name: "Assaba Region",
    c_c: "MR",
  },
  {
    i_c: "04",
    name: "Gorgol Region",
    c_c: "MR",
  },
  {
    i_c: "05",
    name: "Brakna Region",
    c_c: "MR",
  },
  {
    i_c: "06",
    name: "Trarza Region",
    c_c: "MR",
  },
  {
    i_c: "07",
    name: "Adrar Region",
    c_c: "MR",
  },
  {
    i_c: "08",
    name: "Dakhlet Nouadhibou",
    c_c: "MR",
  },
  {
    i_c: "09",
    name: "Tagant Region",
    c_c: "MR",
  },
  {
    i_c: "10",
    name: "Guidimaka Region",
    c_c: "MR",
  },
  {
    i_c: "11",
    name: "Tiris Zemmour Region",
    c_c: "MR",
  },
  {
    i_c: "12",
    name: "Inchiri Region",
    c_c: "MR",
  },
  {
    i_c: "13",
    name: "Nouakchott-Ouest Region",
    c_c: "MR",
  },
  {
    i_c: "14",
    name: "Nouakchott-Nord Region",
    c_c: "MR",
  },
  {
    i_c: "15",
    name: "Nouakchott-Sud Region",
    c_c: "MR",
  },
  {
    i_c: "01",
    name: "Attard",
    c_c: "MT",
  },
  {
    i_c: "02",
    name: "Balzan",
    c_c: "MT",
  },
  {
    i_c: "03",
    name: "Birgu",
    c_c: "MT",
  },
  {
    i_c: "04",
    name: "Birkirkara",
    c_c: "MT",
  },
  {
    i_c: "05",
    name: "Birżebbuġa",
    c_c: "MT",
  },
  {
    i_c: "06",
    name: "Cospicua",
    c_c: "MT",
  },
  {
    i_c: "07",
    name: "Dingli",
    c_c: "MT",
  },
  {
    i_c: "08",
    name: "Fgura",
    c_c: "MT",
  },
  {
    i_c: "09",
    name: "Floriana",
    c_c: "MT",
  },
  {
    i_c: "10",
    name: "Fontana",
    c_c: "MT",
  },
  {
    i_c: "11",
    name: "Gudja",
    c_c: "MT",
  },
  {
    i_c: "12",
    name: "Gżira",
    c_c: "MT",
  },
  {
    i_c: "13",
    name: "Għajnsielem",
    c_c: "MT",
  },
  {
    i_c: "14",
    name: "Għarb",
    c_c: "MT",
  },
  {
    i_c: "15",
    name: "Għargħur",
    c_c: "MT",
  },
  {
    i_c: "16",
    name: "Għasri",
    c_c: "MT",
  },
  {
    i_c: "17",
    name: "Għaxaq",
    c_c: "MT",
  },
  {
    i_c: "18",
    name: "Ħamrun",
    c_c: "MT",
  },
  {
    i_c: "19",
    name: "Iklin",
    c_c: "MT",
  },
  {
    i_c: "20",
    name: "Senglea",
    c_c: "MT",
  },
  {
    i_c: "21",
    name: "Kalkara",
    c_c: "MT",
  },
  {
    i_c: "22",
    name: "Kerċem",
    c_c: "MT",
  },
  {
    i_c: "23",
    name: "Kirkop",
    c_c: "MT",
  },
  {
    i_c: "24",
    name: "Lija",
    c_c: "MT",
  },
  {
    i_c: "25",
    name: "Luqa",
    c_c: "MT",
  },
  {
    i_c: "26",
    name: "Marsa",
    c_c: "MT",
  },
  {
    i_c: "27",
    name: "Marsaskala",
    c_c: "MT",
  },
  {
    i_c: "28",
    name: "Marsaxlokk",
    c_c: "MT",
  },
  {
    i_c: "29",
    name: "Mdina",
    c_c: "MT",
  },
  {
    i_c: "30",
    name: "Mellieħa",
    c_c: "MT",
  },
  {
    i_c: "31",
    name: "Mġarr",
    c_c: "MT",
  },
  {
    i_c: "32",
    name: "Mosta",
    c_c: "MT",
  },
  {
    i_c: "33",
    name: "Mqabba",
    c_c: "MT",
  },
  {
    i_c: "34",
    name: "Msida",
    c_c: "MT",
  },
  {
    i_c: "35",
    name: "Mtarfa",
    c_c: "MT",
  },
  {
    i_c: "36",
    name: "Munxar",
    c_c: "MT",
  },
  {
    i_c: "37",
    name: "Nadur",
    c_c: "MT",
  },
  {
    i_c: "38",
    name: "Naxxar",
    c_c: "MT",
  },
  {
    i_c: "39",
    name: "Paola",
    c_c: "MT",
  },
  {
    i_c: "40",
    name: "Pembroke",
    c_c: "MT",
  },
  {
    i_c: "41",
    name: "Pietà",
    c_c: "MT",
  },
  {
    i_c: "42",
    name: "Qala",
    c_c: "MT",
  },
  {
    i_c: "43",
    name: "Qormi",
    c_c: "MT",
  },
  {
    i_c: "44",
    name: "Qrendi",
    c_c: "MT",
  },
  {
    i_c: "45",
    name: "Victoria",
    c_c: "MT",
  },
  {
    i_c: "46",
    name: "Rabat",
    c_c: "MT",
  },
  {
    i_c: "48",
    name: "St. Julian's",
    c_c: "MT",
  },
  {
    i_c: "49",
    name: "San Ġwann",
    c_c: "MT",
  },
  {
    i_c: "50",
    name: "Saint Lawrence",
    c_c: "MT",
  },
  {
    i_c: "51",
    name: "St. Paul's Bay",
    c_c: "MT",
  },
  {
    i_c: "52",
    name: "Sannat",
    c_c: "MT",
  },
  {
    i_c: "53",
    name: "Santa Luċija",
    c_c: "MT",
  },
  {
    i_c: "54",
    name: "Santa Venera",
    c_c: "MT",
  },
  {
    i_c: "55",
    name: "Siġġiewi",
    c_c: "MT",
  },
  {
    i_c: "56",
    name: "Sliema",
    c_c: "MT",
  },
  {
    i_c: "57",
    name: "Swieqi",
    c_c: "MT",
  },
  {
    i_c: "58",
    name: "Ta' Xbiex",
    c_c: "MT",
  },
  {
    i_c: "59",
    name: "Tarxien",
    c_c: "MT",
  },
  {
    i_c: "60",
    name: "Valletta",
    c_c: "MT",
  },
  {
    i_c: "61",
    name: "Xagħra",
    c_c: "MT",
  },
  {
    i_c: "62",
    name: "Xewkija",
    c_c: "MT",
  },
  {
    i_c: "63",
    name: "Xgħajra",
    c_c: "MT",
  },
  {
    i_c: "64",
    name: "Żabbar",
    c_c: "MT",
  },
  {
    i_c: "65",
    name: "Żebbuġ Gozo",
    c_c: "MT",
  },
  {
    i_c: "66",
    name: "Żebbuġ Malta",
    c_c: "MT",
  },
  {
    i_c: "67",
    name: "Żejtun",
    c_c: "MT",
  },
  {
    i_c: "68",
    name: "Żurrieq",
    c_c: "MT",
  },
  {
    i_c: "AG",
    name: "Agaléga",
    c_c: "MU",
  },
  {
    i_c: "BL",
    name: "Rivière Noire District",
    c_c: "MU",
  },
  {
    i_c: "BR",
    name: "Beau Bassin-Rose Hill",
    c_c: "MU",
  },
  {
    i_c: "CC",
    name: "Cargados Carajos",
    c_c: "MU",
  },
  {
    i_c: "CU",
    name: "Curepipe",
    c_c: "MU",
  },
  {
    i_c: "FL",
    name: "Flacq District",
    c_c: "MU",
  },
  {
    i_c: "GP",
    name: "Grand Port District",
    c_c: "MU",
  },
  {
    i_c: "MO",
    name: "Moka District",
    c_c: "MU",
  },
  {
    i_c: "PA",
    name: "Pamplemousses District",
    c_c: "MU",
  },
  {
    i_c: "PL",
    name: "Port Louis District",
    c_c: "MU",
  },
  {
    i_c: "PU",
    name: "Port Louis",
    c_c: "MU",
  },
  {
    i_c: "PW",
    name: "Plaines Wilhems District",
    c_c: "MU",
  },
  {
    i_c: "QB",
    name: "Quatre Bornes",
    c_c: "MU",
  },
  {
    i_c: "RO",
    name: "Rodrigues",
    c_c: "MU",
  },
  {
    i_c: "RR",
    name: "Rivière du Rempart District",
    c_c: "MU",
  },
  {
    i_c: "SA",
    name: "Savanne District",
    c_c: "MU",
  },
  {
    i_c: "VP",
    name: "Vacoas-Phoenix",
    c_c: "MU",
  },
  {
    i_c: "00",
    name: "Alif Dhaal Atoll",
    c_c: "MV",
  },
  {
    i_c: "01",
    name: "Addu Atoll",
    c_c: "MV",
  },
  {
    i_c: "02",
    name: "Alif Alif Atoll",
    c_c: "MV",
  },
  {
    i_c: "03",
    name: "Lhaviyani Atoll",
    c_c: "MV",
  },
  {
    i_c: "04",
    name: "Vaavu Atoll",
    c_c: "MV",
  },
  {
    i_c: "05",
    name: "Laamu Atoll",
    c_c: "MV",
  },
  {
    i_c: "07",
    name: "Haa Alif Atoll",
    c_c: "MV",
  },
  {
    i_c: "08",
    name: "Thaa Atoll",
    c_c: "MV",
  },
  {
    i_c: "12",
    name: "Meemu Atoll",
    c_c: "MV",
  },
  {
    i_c: "13",
    name: "Raa Atoll",
    c_c: "MV",
  },
  {
    i_c: "14",
    name: "Faafu Atoll",
    c_c: "MV",
  },
  {
    i_c: "17",
    name: "Dhaalu Atoll",
    c_c: "MV",
  },
  {
    i_c: "23",
    name: "Haa Dhaalu Atoll",
    c_c: "MV",
  },
  {
    i_c: "24",
    name: "Shaviyani Atoll",
    c_c: "MV",
  },
  {
    i_c: "25",
    name: "Noonu Atoll",
    c_c: "MV",
  },
  {
    i_c: "26",
    name: "Kaafu Atoll",
    c_c: "MV",
  },
  {
    i_c: "27",
    name: "Gaafu Alif Atoll",
    c_c: "MV",
  },
  {
    i_c: "28",
    name: "Gaafu Dhaalu Atoll",
    c_c: "MV",
  },
  {
    i_c: "29",
    name: "Gnaviyani Atoll",
    c_c: "MV",
  },
  {
    i_c: "CE",
    name: "Central Province",
    c_c: "MV",
  },
  {
    i_c: "MLE",
    name: "Malé",
    c_c: "MV",
  },
  {
    i_c: "NC",
    name: "North Central Province",
    c_c: "MV",
  },
  {
    i_c: "NO",
    name: "North Province",
    c_c: "MV",
  },
  {
    i_c: "SC",
    name: "South Central Province",
    c_c: "MV",
  },
  {
    i_c: "SU",
    name: "South Province",
    c_c: "MV",
  },
  {
    i_c: "US",
    name: "Upper South Province",
    c_c: "MV",
  },
  {
    i_c: "BA",
    name: "Balaka District",
    c_c: "MW",
  },
  {
    i_c: "BL",
    name: "Blantyre District",
    c_c: "MW",
  },
  {
    i_c: "C",
    name: "Central Region",
    c_c: "MW",
  },
  {
    i_c: "CK",
    name: "Chikwawa District",
    c_c: "MW",
  },
  {
    i_c: "CR",
    name: "Chiradzulu District",
    c_c: "MW",
  },
  {
    i_c: "CT",
    name: "Chitipa district",
    c_c: "MW",
  },
  {
    i_c: "DE",
    name: "Dedza District",
    c_c: "MW",
  },
  {
    i_c: "DO",
    name: "Dowa District",
    c_c: "MW",
  },
  {
    i_c: "KR",
    name: "Karonga District",
    c_c: "MW",
  },
  {
    i_c: "KS",
    name: "Kasungu District",
    c_c: "MW",
  },
  {
    i_c: "LI",
    name: "Lilongwe District",
    c_c: "MW",
  },
  {
    i_c: "LK",
    name: "Likoma District",
    c_c: "MW",
  },
  {
    i_c: "MC",
    name: "Mchinji District",
    c_c: "MW",
  },
  {
    i_c: "MG",
    name: "Mangochi District",
    c_c: "MW",
  },
  {
    i_c: "MH",
    name: "Machinga District",
    c_c: "MW",
  },
  {
    i_c: "MU",
    name: "Mulanje District",
    c_c: "MW",
  },
  {
    i_c: "MW",
    name: "Mwanza District",
    c_c: "MW",
  },
  {
    i_c: "MZ",
    name: "Mzimba District",
    c_c: "MW",
  },
  {
    i_c: "N",
    name: "Northern Region",
    c_c: "MW",
  },
  {
    i_c: "NB",
    name: "Nkhata Bay District",
    c_c: "MW",
  },
  {
    i_c: "NI",
    name: "Ntchisi District",
    c_c: "MW",
  },
  {
    i_c: "NK",
    name: "Nkhotakota District",
    c_c: "MW",
  },
  {
    i_c: "NS",
    name: "Nsanje District",
    c_c: "MW",
  },
  {
    i_c: "NU",
    name: "Ntcheu District",
    c_c: "MW",
  },
  {
    i_c: "PH",
    name: "Phalombe District",
    c_c: "MW",
  },
  {
    i_c: "RU",
    name: "Rumphi District",
    c_c: "MW",
  },
  {
    i_c: "S",
    name: "Southern Region",
    c_c: "MW",
  },
  {
    i_c: "SA",
    name: "Salima District",
    c_c: "MW",
  },
  {
    i_c: "TH",
    name: "Thyolo District",
    c_c: "MW",
  },
  {
    i_c: "ZO",
    name: "Zomba District",
    c_c: "MW",
  },
  {
    i_c: "AGU",
    name: "Aguascalientes",
    c_c: "MX",
  },
  {
    i_c: "BCN",
    name: "Baja California",
    c_c: "MX",
  },
  {
    i_c: "BCS",
    name: "Baja California Sur",
    c_c: "MX",
  },
  {
    i_c: "CAM",
    name: "Campeche",
    c_c: "MX",
  },
  {
    i_c: "CDMX",
    name: "Ciudad de México",
    c_c: "MX",
  },
  {
    i_c: "CHH",
    name: "Chihuahua",
    c_c: "MX",
  },
  {
    i_c: "CHP",
    name: "Chiapas",
    c_c: "MX",
  },
  {
    i_c: "COA",
    name: "Coahuila de Zaragoza",
    c_c: "MX",
  },
  {
    i_c: "COL",
    name: "Colima",
    c_c: "MX",
  },
  {
    i_c: "DUR",
    name: "Durango",
    c_c: "MX",
  },
  {
    i_c: "GRO",
    name: "Guerrero",
    c_c: "MX",
  },
  {
    i_c: "GUA",
    name: "Guanajuato",
    c_c: "MX",
  },
  {
    i_c: "HID",
    name: "Hidalgo",
    c_c: "MX",
  },
  {
    i_c: "JAL",
    name: "Jalisco",
    c_c: "MX",
  },
  {
    i_c: "MEX",
    name: "Estado de México",
    c_c: "MX",
  },
  {
    i_c: "MIC",
    name: "Michoacán de Ocampo",
    c_c: "MX",
  },
  {
    i_c: "MOR",
    name: "Morelos",
    c_c: "MX",
  },
  {
    i_c: "NAY",
    name: "Nayarit",
    c_c: "MX",
  },
  {
    i_c: "NLE",
    name: "Nuevo León",
    c_c: "MX",
  },
  {
    i_c: "OAX",
    name: "Oaxaca",
    c_c: "MX",
  },
  {
    i_c: "PUE",
    name: "Puebla",
    c_c: "MX",
  },
  {
    i_c: "QUE",
    name: "Querétaro",
    c_c: "MX",
  },
  {
    i_c: "ROO",
    name: "Quintana Roo",
    c_c: "MX",
  },
  {
    i_c: "SIN",
    name: "Sinaloa",
    c_c: "MX",
  },
  {
    i_c: "SLP",
    name: "San Luis Potosí",
    c_c: "MX",
  },
  {
    i_c: "SON",
    name: "Sonora",
    c_c: "MX",
  },
  {
    i_c: "TAB",
    name: "Tabasco",
    c_c: "MX",
  },
  {
    i_c: "TAM",
    name: "Tamaulipas",
    c_c: "MX",
  },
  {
    i_c: "TLA",
    name: "Tlaxcala",
    c_c: "MX",
  },
  {
    i_c: "VER",
    name: "Veracruz de Ignacio de la Llave",
    c_c: "MX",
  },
  {
    i_c: "YUC",
    name: "Yucatán",
    c_c: "MX",
  },
  {
    i_c: "ZAC",
    name: "Zacatecas",
    c_c: "MX",
  },
  {
    i_c: "01",
    name: "Johor",
    c_c: "MY",
  },
  {
    i_c: "02",
    name: "Kedah",
    c_c: "MY",
  },
  {
    i_c: "03",
    name: "Kelantan",
    c_c: "MY",
  },
  {
    i_c: "04",
    name: "Malacca",
    c_c: "MY",
  },
  {
    i_c: "05",
    name: "Negeri Sembilan",
    c_c: "MY",
  },
  {
    i_c: "06",
    name: "Pahang",
    c_c: "MY",
  },
  {
    i_c: "07",
    name: "Penang",
    c_c: "MY",
  },
  {
    i_c: "08",
    name: "Perak",
    c_c: "MY",
  },
  {
    i_c: "09",
    name: "Perlis",
    c_c: "MY",
  },
  {
    i_c: "10",
    name: "Selangor",
    c_c: "MY",
  },
  {
    i_c: "11",
    name: "Terengganu",
    c_c: "MY",
  },
  {
    i_c: "12",
    name: "Sabah",
    c_c: "MY",
  },
  {
    i_c: "13",
    name: "Sarawak",
    c_c: "MY",
  },
  {
    i_c: "14",
    name: "Kuala Lumpur",
    c_c: "MY",
  },
  {
    i_c: "15",
    name: "Labuan",
    c_c: "MY",
  },
  {
    i_c: "16",
    name: "Putrajaya",
    c_c: "MY",
  },
  {
    i_c: "A",
    name: "Niassa Province",
    c_c: "MZ",
  },
  {
    i_c: "B",
    name: "Manica Province",
    c_c: "MZ",
  },
  {
    i_c: "G",
    name: "Gaza Province",
    c_c: "MZ",
  },
  {
    i_c: "I",
    name: "Inhambane Province",
    c_c: "MZ",
  },
  {
    i_c: "L",
    name: "Maputo Province",
    c_c: "MZ",
  },
  {
    i_c: "MPM",
    name: "Maputo",
    c_c: "MZ",
  },
  {
    i_c: "N",
    name: "Nampula Province",
    c_c: "MZ",
  },
  {
    i_c: "P",
    name: "Cabo Delgado Province",
    c_c: "MZ",
  },
  {
    i_c: "Q",
    name: "Zambezia Province",
    c_c: "MZ",
  },
  {
    i_c: "S",
    name: "Sofala Province",
    c_c: "MZ",
  },
  {
    i_c: "T",
    name: "Tete Province",
    c_c: "MZ",
  },
  {
    i_c: "CA",
    name: "Zambezi Region",
    c_c: "NA",
  },
  {
    i_c: "ER",
    name: "Erongo Region",
    c_c: "NA",
  },
  {
    i_c: "HA",
    name: "Hardap Region",
    c_c: "NA",
  },
  {
    i_c: "KA",
    name: "Karas Region",
    c_c: "NA",
  },
  {
    i_c: "KE",
    name: "Kavango East Region",
    c_c: "NA",
  },
  {
    i_c: "KH",
    name: "Khomas Region",
    c_c: "NA",
  },
  {
    i_c: "KU",
    name: "Kunene Region",
    c_c: "NA",
  },
  {
    i_c: "KW",
    name: "Kavango West Region",
    c_c: "NA",
  },
  {
    i_c: "OD",
    name: "Otjozondjupa Region",
    c_c: "NA",
  },
  {
    i_c: "OH",
    name: "Omaheke Region",
    c_c: "NA",
  },
  {
    i_c: "ON",
    name: "Oshana Region",
    c_c: "NA",
  },
  {
    i_c: "OS",
    name: "Omusati Region",
    c_c: "NA",
  },
  {
    i_c: "OT",
    name: "Oshikoto Region",
    c_c: "NA",
  },
  {
    i_c: "OW",
    name: "Ohangwena Region",
    c_c: "NA",
  },
  {
    i_c: "1",
    name: "Agadez Region",
    c_c: "NE",
  },
  {
    i_c: "2",
    name: "Diffa Region",
    c_c: "NE",
  },
  {
    i_c: "3",
    name: "Dosso Region",
    c_c: "NE",
  },
  {
    i_c: "4",
    name: "Maradi Region",
    c_c: "NE",
  },
  {
    i_c: "5",
    name: "Tahoua Region",
    c_c: "NE",
  },
  {
    i_c: "6",
    name: "Tillabéri Region",
    c_c: "NE",
  },
  {
    i_c: "7",
    name: "Zinder Region",
    c_c: "NE",
  },
  {
    i_c: "AB",
    name: "Abia",
    c_c: "NG",
  },
  {
    i_c: "AD",
    name: "Adamawa",
    c_c: "NG",
  },
  {
    i_c: "AK",
    name: "Akwa Ibom",
    c_c: "NG",
  },
  {
    i_c: "AN",
    name: "Anambra",
    c_c: "NG",
  },
  {
    i_c: "BA",
    name: "Bauchi",
    c_c: "NG",
  },
  {
    i_c: "BE",
    name: "Benue",
    c_c: "NG",
  },
  {
    i_c: "BO",
    name: "Borno",
    c_c: "NG",
  },
  {
    i_c: "BY",
    name: "Bayelsa",
    c_c: "NG",
  },
  {
    i_c: "CR",
    name: "Cross River",
    c_c: "NG",
  },
  {
    i_c: "DE",
    name: "Delta",
    c_c: "NG",
  },
  {
    i_c: "EB",
    name: "Ebonyi",
    c_c: "NG",
  },
  {
    i_c: "ED",
    name: "Edo",
    c_c: "NG",
  },
  {
    i_c: "EK",
    name: "Ekiti",
    c_c: "NG",
  },
  {
    i_c: "EN",
    name: "Enugu",
    c_c: "NG",
  },
  {
    i_c: "FC",
    name: "Abuja Federal Capital Territory",
    c_c: "NG",
  },
  {
    i_c: "GO",
    name: "Gombe",
    c_c: "NG",
  },
  {
    i_c: "IM",
    name: "Imo",
    c_c: "NG",
  },
  {
    i_c: "JI",
    name: "Jigawa",
    c_c: "NG",
  },
  {
    i_c: "KD",
    name: "Kaduna",
    c_c: "NG",
  },
  {
    i_c: "KE",
    name: "Kebbi",
    c_c: "NG",
  },
  {
    i_c: "KN",
    name: "Kano",
    c_c: "NG",
  },
  {
    i_c: "KO",
    name: "Kogi",
    c_c: "NG",
  },
  {
    i_c: "KT",
    name: "Katsina",
    c_c: "NG",
  },
  {
    i_c: "KW",
    name: "Kwara",
    c_c: "NG",
  },
  {
    i_c: "LA",
    name: "Lagos",
    c_c: "NG",
  },
  {
    i_c: "NA",
    name: "Nasarawa",
    c_c: "NG",
  },
  {
    i_c: "NI",
    name: "Niger",
    c_c: "NG",
  },
  {
    i_c: "OG",
    name: "Ogun",
    c_c: "NG",
  },
  {
    i_c: "ON",
    name: "Ondo",
    c_c: "NG",
  },
  {
    i_c: "OS",
    name: "Osun",
    c_c: "NG",
  },
  {
    i_c: "OY",
    name: "Oyo",
    c_c: "NG",
  },
  {
    i_c: "PL",
    name: "Plateau",
    c_c: "NG",
  },
  {
    i_c: "RI",
    name: "Rivers",
    c_c: "NG",
  },
  {
    i_c: "SO",
    name: "Sokoto",
    c_c: "NG",
  },
  {
    i_c: "TA",
    name: "Taraba",
    c_c: "NG",
  },
  {
    i_c: "YO",
    name: "Yobe",
    c_c: "NG",
  },
  {
    i_c: "ZA",
    name: "Zamfara",
    c_c: "NG",
  },
  {
    i_c: "AN",
    name: "North Caribbean Coast",
    c_c: "NI",
  },
  {
    i_c: "AS",
    name: "South Caribbean Coast",
    c_c: "NI",
  },
  {
    i_c: "BO",
    name: "Boaco",
    c_c: "NI",
  },
  {
    i_c: "CA",
    name: "Carazo",
    c_c: "NI",
  },
  {
    i_c: "CI",
    name: "Chinandega",
    c_c: "NI",
  },
  {
    i_c: "CO",
    name: "Chontales",
    c_c: "NI",
  },
  {
    i_c: "ES",
    name: "Estelí",
    c_c: "NI",
  },
  {
    i_c: "GR",
    name: "Granada",
    c_c: "NI",
  },
  {
    i_c: "JI",
    name: "Jinotega",
    c_c: "NI",
  },
  {
    i_c: "LE",
    name: "León",
    c_c: "NI",
  },
  {
    i_c: "MD",
    name: "Madriz",
    c_c: "NI",
  },
  {
    i_c: "MN",
    name: "Managua",
    c_c: "NI",
  },
  {
    i_c: "MS",
    name: "Masaya",
    c_c: "NI",
  },
  {
    i_c: "MT",
    name: "Matagalpa",
    c_c: "NI",
  },
  {
    i_c: "NS",
    name: "Nueva Segovia",
    c_c: "NI",
  },
  {
    i_c: "RI",
    name: "Rivas",
    c_c: "NI",
  },
  {
    i_c: "SJ",
    name: "Río San Juan",
    c_c: "NI",
  },
  {
    i_c: "BQ1",
    name: "Bonaire",
    c_c: "NL",
  },
  {
    i_c: "BQ2",
    name: "Saba",
    c_c: "NL",
  },
  {
    i_c: "BQ3",
    name: "Sint Eustatius",
    c_c: "NL",
  },
  {
    i_c: "DR",
    name: "Drenthe",
    c_c: "NL",
  },
  {
    i_c: "FL",
    name: "Flevoland",
    c_c: "NL",
  },
  {
    i_c: "FR",
    name: "Friesland",
    c_c: "NL",
  },
  {
    i_c: "GE",
    name: "Gelderland",
    c_c: "NL",
  },
  {
    i_c: "GR",
    name: "Groningen",
    c_c: "NL",
  },
  {
    i_c: "LI",
    name: "Limburg",
    c_c: "NL",
  },
  {
    i_c: "NB",
    name: "North Brabant",
    c_c: "NL",
  },
  {
    i_c: "NH",
    name: "North Holland",
    c_c: "NL",
  },
  {
    i_c: "OV",
    name: "Overijssel",
    c_c: "NL",
  },
  {
    i_c: "UT",
    name: "Utrecht",
    c_c: "NL",
  },
  {
    i_c: "ZE",
    name: "Zeeland",
    c_c: "NL",
  },
  {
    i_c: "ZH",
    name: "South Holland",
    c_c: "NL",
  },
  {
    i_c: "01",
    name: "Østfold",
    c_c: "NO",
  },
  {
    i_c: "02",
    name: "Akershus",
    c_c: "NO",
  },
  {
    i_c: "03",
    name: "Oslo",
    c_c: "NO",
  },
  {
    i_c: "04",
    name: "Hedmark",
    c_c: "NO",
  },
  {
    i_c: "05",
    name: "Oppland",
    c_c: "NO",
  },
  {
    i_c: "06",
    name: "Buskerud",
    c_c: "NO",
  },
  {
    i_c: "07",
    name: "Vestfold",
    c_c: "NO",
  },
  {
    i_c: "08",
    name: "Telemark",
    c_c: "NO",
  },
  {
    i_c: "10",
    name: "Vest-Agder",
    c_c: "NO",
  },
  {
    i_c: "11",
    name: "Rogaland",
    c_c: "NO",
  },
  {
    i_c: "12",
    name: "Hordaland",
    c_c: "NO",
  },
  {
    i_c: "14",
    name: "Sogn og Fjordane",
    c_c: "NO",
  },
  {
    i_c: "15",
    name: "Møre og Romsdal",
    c_c: "NO",
  },
  {
    i_c: "16",
    name: "Sør-Trøndelag",
    c_c: "NO",
  },
  {
    i_c: "17",
    name: "Nord-Trøndelag",
    c_c: "NO",
  },
  {
    i_c: "18",
    name: "Nordland",
    c_c: "NO",
  },
  {
    i_c: "19",
    name: "Troms",
    c_c: "NO",
  },
  {
    i_c: "20",
    name: "Finnmark",
    c_c: "NO",
  },
  {
    i_c: "21",
    name: "Svalbard",
    c_c: "NO",
  },
  {
    i_c: "22",
    name: "Jan Mayen",
    c_c: "NO",
  },
  {
    i_c: "50",
    name: "Trøndelag",
    c_c: "NO",
  },
  {
    i_c: "1",
    name: "Central Region",
    c_c: "NP",
  },
  {
    i_c: "2",
    name: "Mid-Western Region",
    c_c: "NP",
  },
  {
    i_c: "3",
    name: "Western Region",
    c_c: "NP",
  },
  {
    i_c: "4",
    name: "Eastern Development Region",
    c_c: "NP",
  },
  {
    i_c: "5",
    name: "Far-Western Development Region",
    c_c: "NP",
  },
  {
    i_c: "BA",
    name: "Bagmati Zone",
    c_c: "NP",
  },
  {
    i_c: "BH",
    name: "Bheri Zone",
    c_c: "NP",
  },
  {
    i_c: "DH",
    name: "Dhaulagiri Zone",
    c_c: "NP",
  },
  {
    i_c: "GA",
    name: "Gandaki Zone",
    c_c: "NP",
  },
  {
    i_c: "JA",
    name: "Janakpur Zone",
    c_c: "NP",
  },
  {
    i_c: "KA",
    name: "Karnali Zone",
    c_c: "NP",
  },
  {
    i_c: "KO",
    name: "Kosi Zone",
    c_c: "NP",
  },
  {
    i_c: "LU",
    name: "Lumbini Zone",
    c_c: "NP",
  },
  {
    i_c: "MA",
    name: "Mahakali Zone",
    c_c: "NP",
  },
  {
    i_c: "ME",
    name: "Mechi Zone",
    c_c: "NP",
  },
  {
    i_c: "NA",
    name: "Narayani Zone",
    c_c: "NP",
  },
  {
    i_c: "RA",
    name: "Rapti Zone",
    c_c: "NP",
  },
  {
    i_c: "SA",
    name: "Sagarmatha Zone",
    c_c: "NP",
  },
  {
    i_c: "SE",
    name: "Seti Zone",
    c_c: "NP",
  },
  {
    i_c: "01",
    name: "Aiwo District",
    c_c: "NR",
  },
  {
    i_c: "02",
    name: "Anabar District",
    c_c: "NR",
  },
  {
    i_c: "03",
    name: "Anetan District",
    c_c: "NR",
  },
  {
    i_c: "04",
    name: "Anibare District",
    c_c: "NR",
  },
  {
    i_c: "05",
    name: "Baiti District",
    c_c: "NR",
  },
  {
    i_c: "06",
    name: "Boe District",
    c_c: "NR",
  },
  {
    i_c: "07",
    name: "Buada District",
    c_c: "NR",
  },
  {
    i_c: "08",
    name: "Denigomodu District",
    c_c: "NR",
  },
  {
    i_c: "09",
    name: "Ewa District",
    c_c: "NR",
  },
  {
    i_c: "10",
    name: "Ijuw District",
    c_c: "NR",
  },
  {
    i_c: "11",
    name: "Meneng District",
    c_c: "NR",
  },
  {
    i_c: "12",
    name: "Nibok District",
    c_c: "NR",
  },
  {
    i_c: "13",
    name: "Uaboe District",
    c_c: "NR",
  },
  {
    i_c: "14",
    name: "Yaren District",
    c_c: "NR",
  },
  {
    i_c: "AUK",
    name: "Auckland Region",
    c_c: "NZ",
  },
  {
    i_c: "BOP",
    name: "Bay of Plenty Region",
    c_c: "NZ",
  },
  {
    i_c: "CAN",
    name: "Canterbury Region",
    c_c: "NZ",
  },
  {
    i_c: "CIT",
    name: "Chatham Islands",
    c_c: "NZ",
  },
  {
    i_c: "GIS",
    name: "Gisborne District",
    c_c: "NZ",
  },
  {
    i_c: "HKB",
    name: "Hawke's Bay Region",
    c_c: "NZ",
  },
  {
    i_c: "MBH",
    name: "Marlborough Region",
    c_c: "NZ",
  },
  {
    i_c: "MWT",
    name: "Manawatu-Wanganui Region",
    c_c: "NZ",
  },
  {
    i_c: "NSN",
    name: "Nelson Region",
    c_c: "NZ",
  },
  {
    i_c: "NTL",
    name: "Northland Region",
    c_c: "NZ",
  },
  {
    i_c: "OTA",
    name: "Otago Region",
    c_c: "NZ",
  },
  {
    i_c: "STL",
    name: "Southland Region",
    c_c: "NZ",
  },
  {
    i_c: "TAS",
    name: "Tasman District",
    c_c: "NZ",
  },
  {
    i_c: "TKI",
    name: "Taranaki Region",
    c_c: "NZ",
  },
  {
    i_c: "WGN",
    name: "Wellington Region",
    c_c: "NZ",
  },
  {
    i_c: "WKO",
    name: "Waikato Region",
    c_c: "NZ",
  },
  {
    i_c: "WTC",
    name: "West Coast Region",
    c_c: "NZ",
  },
  {
    i_c: "BA",
    name: "Al Batinah Region",
    c_c: "OM",
  },
  {
    i_c: "BJ",
    name: "Al Batinah South Governorate",
    c_c: "OM",
  },
  {
    i_c: "BS",
    name: "Al Batinah North Governorate",
    c_c: "OM",
  },
  {
    i_c: "BU",
    name: "Al Buraimi Governorate",
    c_c: "OM",
  },
  {
    i_c: "DA",
    name: "Ad Dakhiliyah Governorate",
    c_c: "OM",
  },
  {
    i_c: "MA",
    name: "Muscat Governorate",
    c_c: "OM",
  },
  {
    i_c: "MU",
    name: "Musandam Governorate",
    c_c: "OM",
  },
  {
    i_c: "SH",
    name: "Ash Sharqiyah Region",
    c_c: "OM",
  },
  {
    i_c: "SJ",
    name: "Ash Sharqiyah South Governorate",
    c_c: "OM",
  },
  {
    i_c: "SS",
    name: "Ash Sharqiyah North Governorate",
    c_c: "OM",
  },
  {
    i_c: "WU",
    name: "Al Wusta Governorate",
    c_c: "OM",
  },
  {
    i_c: "ZA",
    name: "Ad Dhahirah Governorate",
    c_c: "OM",
  },
  {
    i_c: "ZU",
    name: "Dhofar Governorate",
    c_c: "OM",
  },
  {
    i_c: "1",
    name: "Bocas del Toro Province",
    c_c: "PA",
  },
  {
    i_c: "10",
    name: "Panamá Oeste Province",
    c_c: "PA",
  },
  {
    i_c: "2",
    name: "Coclé Province",
    c_c: "PA",
  },
  {
    i_c: "3",
    name: "Colón Province",
    c_c: "PA",
  },
  {
    i_c: "4",
    name: "Chiriquí Province",
    c_c: "PA",
  },
  {
    i_c: "5",
    name: "Darién Province",
    c_c: "PA",
  },
  {
    i_c: "6",
    name: "Herrera Province",
    c_c: "PA",
  },
  {
    i_c: "7",
    name: "Los Santos Province",
    c_c: "PA",
  },
  {
    i_c: "8",
    name: "Panamá Province",
    c_c: "PA",
  },
  {
    i_c: "9",
    name: "Veraguas Province",
    c_c: "PA",
  },
  {
    i_c: "EM",
    name: "Emberá-Wounaan Comarca",
    c_c: "PA",
  },
  {
    i_c: "KY",
    name: "Guna Yala",
    c_c: "PA",
  },
  {
    i_c: "NB",
    name: "Ngöbe-Buglé Comarca",
    c_c: "PA",
  },
  {
    i_c: "AMA",
    name: "Amazonas",
    c_c: "PE",
  },
  {
    i_c: "ANC",
    name: "Áncash",
    c_c: "PE",
  },
  {
    i_c: "APU",
    name: "Apurímac",
    c_c: "PE",
  },
  {
    i_c: "ARE",
    name: "Arequipa",
    c_c: "PE",
  },
  {
    i_c: "AYA",
    name: "Ayacucho",
    c_c: "PE",
  },
  {
    i_c: "CAJ",
    name: "Cajamarca",
    c_c: "PE",
  },
  {
    i_c: "CAL",
    name: "Callao",
    c_c: "PE",
  },
  {
    i_c: "CUS",
    name: "Cusco",
    c_c: "PE",
  },
  {
    i_c: "HUC",
    name: "Huanuco",
    c_c: "PE",
  },
  {
    i_c: "HUV",
    name: "Huancavelica",
    c_c: "PE",
  },
  {
    i_c: "ICA",
    name: "Ica",
    c_c: "PE",
  },
  {
    i_c: "JUN",
    name: "Junín",
    c_c: "PE",
  },
  {
    i_c: "LAL",
    name: "La Libertad",
    c_c: "PE",
  },
  {
    i_c: "LAM",
    name: "Lambayeque",
    c_c: "PE",
  },
  {
    i_c: "LIM",
    name: "Lima",
    c_c: "PE",
  },
  {
    i_c: "LOR",
    name: "Loreto",
    c_c: "PE",
  },
  {
    i_c: "MDD",
    name: "Madre de Dios",
    c_c: "PE",
  },
  {
    i_c: "MOQ",
    name: "Moquegua",
    c_c: "PE",
  },
  {
    i_c: "PAS",
    name: "Pasco",
    c_c: "PE",
  },
  {
    i_c: "PIU",
    name: "Piura",
    c_c: "PE",
  },
  {
    i_c: "PUN",
    name: "Puno",
    c_c: "PE",
  },
  {
    i_c: "SAM",
    name: "San Martín",
    c_c: "PE",
  },
  {
    i_c: "TAC",
    name: "Tacna",
    c_c: "PE",
  },
  {
    i_c: "TUM",
    name: "Tumbes",
    c_c: "PE",
  },
  {
    i_c: "UCA",
    name: "Ucayali",
    c_c: "PE",
  },
  {
    i_c: "CPK",
    name: "Chimbu Province",
    c_c: "PG",
  },
  {
    i_c: "CPM",
    name: "Central Province",
    c_c: "PG",
  },
  {
    i_c: "EBR",
    name: "East New Britain",
    c_c: "PG",
  },
  {
    i_c: "EHG",
    name: "Eastern Highlands Province",
    c_c: "PG",
  },
  {
    i_c: "EPW",
    name: "Enga Province",
    c_c: "PG",
  },
  {
    i_c: "GPK",
    name: "Gulf",
    c_c: "PG",
  },
  {
    i_c: "HLA",
    name: "Hela",
    c_c: "PG",
  },
  {
    i_c: "JWK",
    name: "Jiwaka Province",
    c_c: "PG",
  },
  {
    i_c: "MBA",
    name: "Milne Bay Province",
    c_c: "PG",
  },
  {
    i_c: "MPL",
    name: "Morobe Province",
    c_c: "PG",
  },
  {
    i_c: "MPM",
    name: "Madang Province",
    c_c: "PG",
  },
  {
    i_c: "MRL",
    name: "Manus Province",
    c_c: "PG",
  },
  {
    i_c: "NCD",
    name: "Port Moresby",
    c_c: "PG",
  },
  {
    i_c: "NIK",
    name: "New Ireland Province",
    c_c: "PG",
  },
  {
    i_c: "NPP",
    name: "Oro Province",
    c_c: "PG",
  },
  {
    i_c: "NSB",
    name: "Bougainville",
    c_c: "PG",
  },
  {
    i_c: "SAN",
    name: "Sandaun Province",
    c_c: "PG",
  },
  {
    i_c: "SHM",
    name: "Southern Highlands Province",
    c_c: "PG",
  },
  {
    i_c: "WBK",
    name: "West New Britain Province",
    c_c: "PG",
  },
  {
    i_c: "WHM",
    name: "Western Highlands Province",
    c_c: "PG",
  },
  {
    i_c: "WPD",
    name: "Western Province",
    c_c: "PG",
  },
  {
    i_c: "01",
    name: "Ilocos Region",
    c_c: "PH",
  },
  {
    i_c: "02",
    name: "Cagayan Valley",
    c_c: "PH",
  },
  {
    i_c: "03",
    name: "Central Luzon",
    c_c: "PH",
  },
  {
    i_c: "05",
    name: "Bicol Region",
    c_c: "PH",
  },
  {
    i_c: "06",
    name: "Western Visayas",
    c_c: "PH",
  },
  {
    i_c: "07",
    name: "Central Visayas",
    c_c: "PH",
  },
  {
    i_c: "08",
    name: "Eastern Visayas",
    c_c: "PH",
  },
  {
    i_c: "09",
    name: "Zamboanga Peninsula",
    c_c: "PH",
  },
  {
    i_c: "10",
    name: "Northern Mindanao",
    c_c: "PH",
  },
  {
    i_c: "11",
    name: "Davao Region",
    c_c: "PH",
  },
  {
    i_c: "12",
    name: "Soccsksargen",
    c_c: "PH",
  },
  {
    i_c: "13",
    name: "Caraga",
    c_c: "PH",
  },
  {
    i_c: "14",
    name: "Autonomous Region in Muslim Mindanao",
    c_c: "PH",
  },
  {
    i_c: "15",
    name: "Cordillera Administrative Region",
    c_c: "PH",
  },
  {
    i_c: "40",
    name: "Calabarzon",
    c_c: "PH",
  },
  {
    i_c: "41",
    name: "Mimaropa",
    c_c: "PH",
  },
  {
    i_c: "ABR",
    name: "Abra",
    c_c: "PH",
  },
  {
    i_c: "AGN",
    name: "Agusan del Norte",
    c_c: "PH",
  },
  {
    i_c: "AGS",
    name: "Agusan del Sur",
    c_c: "PH",
  },
  {
    i_c: "AKL",
    name: "Aklan",
    c_c: "PH",
  },
  {
    i_c: "ALB",
    name: "Albay",
    c_c: "PH",
  },
  {
    i_c: "ANT",
    name: "Antique",
    c_c: "PH",
  },
  {
    i_c: "APA",
    name: "Apayao",
    c_c: "PH",
  },
  {
    i_c: "AUR",
    name: "Aurora",
    c_c: "PH",
  },
  {
    i_c: "BAN",
    name: "Bataan",
    c_c: "PH",
  },
  {
    i_c: "BAS",
    name: "Basilan",
    c_c: "PH",
  },
  {
    i_c: "BEN",
    name: "Benguet",
    c_c: "PH",
  },
  {
    i_c: "BIL",
    name: "Biliran",
    c_c: "PH",
  },
  {
    i_c: "BOH",
    name: "Bohol",
    c_c: "PH",
  },
  {
    i_c: "BTG",
    name: "Batangas",
    c_c: "PH",
  },
  {
    i_c: "BTN",
    name: "Batanes",
    c_c: "PH",
  },
  {
    i_c: "BUK",
    name: "Bukidnon",
    c_c: "PH",
  },
  {
    i_c: "BUL",
    name: "Bulacan",
    c_c: "PH",
  },
  {
    i_c: "CAG",
    name: "Cagayan",
    c_c: "PH",
  },
  {
    i_c: "CAM",
    name: "Camiguin",
    c_c: "PH",
  },
  {
    i_c: "CAN",
    name: "Camarines Norte",
    c_c: "PH",
  },
  {
    i_c: "CAP",
    name: "Capiz",
    c_c: "PH",
  },
  {
    i_c: "CAS",
    name: "Camarines Sur",
    c_c: "PH",
  },
  {
    i_c: "CAT",
    name: "Catanduanes",
    c_c: "PH",
  },
  {
    i_c: "CAV",
    name: "Cavite",
    c_c: "PH",
  },
  {
    i_c: "CEB",
    name: "Cebu",
    c_c: "PH",
  },
  {
    i_c: "COM",
    name: "Compostela Valley",
    c_c: "PH",
  },
  {
    i_c: "DAO",
    name: "Davao Oriental",
    c_c: "PH",
  },
  {
    i_c: "DAS",
    name: "Davao del Sur",
    c_c: "PH",
  },
  {
    i_c: "DAV",
    name: "Davao del Norte",
    c_c: "PH",
  },
  {
    i_c: "DIN",
    name: "Dinagat Islands",
    c_c: "PH",
  },
  {
    i_c: "DVO",
    name: "Davao Occidental",
    c_c: "PH",
  },
  {
    i_c: "EAS",
    name: "Eastern Samar",
    c_c: "PH",
  },
  {
    i_c: "GUI",
    name: "Guimaras",
    c_c: "PH",
  },
  {
    i_c: "IFU",
    name: "Ifugao",
    c_c: "PH",
  },
  {
    i_c: "ILI",
    name: "Iloilo",
    c_c: "PH",
  },
  {
    i_c: "ILN",
    name: "Ilocos Norte",
    c_c: "PH",
  },
  {
    i_c: "ILS",
    name: "Ilocos Sur",
    c_c: "PH",
  },
  {
    i_c: "ISA",
    name: "Isabela",
    c_c: "PH",
  },
  {
    i_c: "KAL",
    name: "Kalinga",
    c_c: "PH",
  },
  {
    i_c: "LAG",
    name: "Laguna",
    c_c: "PH",
  },
  {
    i_c: "LAN",
    name: "Lanao del Norte",
    c_c: "PH",
  },
  {
    i_c: "LAS",
    name: "Lanao del Sur",
    c_c: "PH",
  },
  {
    i_c: "LEY",
    name: "Leyte",
    c_c: "PH",
  },
  {
    i_c: "LUN",
    name: "La Union",
    c_c: "PH",
  },
  {
    i_c: "MAD",
    name: "Marinduque",
    c_c: "PH",
  },
  {
    i_c: "MAG",
    name: "Maguindanao",
    c_c: "PH",
  },
  {
    i_c: "MAS",
    name: "Masbate",
    c_c: "PH",
  },
  {
    i_c: "MDC",
    name: "Occidental Mindoro",
    c_c: "PH",
  },
  {
    i_c: "MDR",
    name: "Oriental Mindoro",
    c_c: "PH",
  },
  {
    i_c: "MOU",
    name: "Mountain Province",
    c_c: "PH",
  },
  {
    i_c: "MSC",
    name: "Misamis Occidental",
    c_c: "PH",
  },
  {
    i_c: "MSR",
    name: "Misamis Oriental",
    c_c: "PH",
  },
  {
    i_c: "NCO",
    name: "Cotabato",
    c_c: "PH",
  },
  {
    i_c: "NCR",
    name: "Metro Manila",
    c_c: "PH",
  },
  {
    i_c: "NEC",
    name: "Negros Occidental",
    c_c: "PH",
  },
  {
    i_c: "NER",
    name: "Negros Oriental",
    c_c: "PH",
  },
  {
    i_c: "NSA",
    name: "Northern Samar",
    c_c: "PH",
  },
  {
    i_c: "NUE",
    name: "Nueva Ecija",
    c_c: "PH",
  },
  {
    i_c: "NUV",
    name: "Nueva Vizcaya",
    c_c: "PH",
  },
  {
    i_c: "PAM",
    name: "Pampanga",
    c_c: "PH",
  },
  {
    i_c: "PAN",
    name: "Pangasinan",
    c_c: "PH",
  },
  {
    i_c: "PLW",
    name: "Palawan",
    c_c: "PH",
  },
  {
    i_c: "QUE",
    name: "Quezon",
    c_c: "PH",
  },
  {
    i_c: "QUI",
    name: "Quirino",
    c_c: "PH",
  },
  {
    i_c: "RIZ",
    name: "Rizal",
    c_c: "PH",
  },
  {
    i_c: "ROM",
    name: "Romblon",
    c_c: "PH",
  },
  {
    i_c: "SAR",
    name: "Sarangani",
    c_c: "PH",
  },
  {
    i_c: "SCO",
    name: "South Cotabato",
    c_c: "PH",
  },
  {
    i_c: "SIG",
    name: "Siquijor",
    c_c: "PH",
  },
  {
    i_c: "SLE",
    name: "Southern Leyte",
    c_c: "PH",
  },
  {
    i_c: "SLU",
    name: "Sulu",
    c_c: "PH",
  },
  {
    i_c: "SOR",
    name: "Sorsogon",
    c_c: "PH",
  },
  {
    i_c: "SUK",
    name: "Sultan Kudarat",
    c_c: "PH",
  },
  {
    i_c: "SUN",
    name: "Surigao del Norte",
    c_c: "PH",
  },
  {
    i_c: "SUR",
    name: "Surigao del Sur",
    c_c: "PH",
  },
  {
    i_c: "TAR",
    name: "Tarlac",
    c_c: "PH",
  },
  {
    i_c: "TAW",
    name: "Tawi-Tawi",
    c_c: "PH",
  },
  {
    i_c: "ZAN",
    name: "Zamboanga del Norte",
    c_c: "PH",
  },
  {
    i_c: "ZAS",
    name: "Zamboanga del Sur",
    c_c: "PH",
  },
  {
    i_c: "ZMB",
    name: "Zambales",
    c_c: "PH",
  },
  {
    i_c: "ZSI",
    name: "Zamboanga Sibugay",
    c_c: "PH",
  },
  {
    i_c: "BA",
    name: "Balochistan",
    c_c: "PK",
  },
  {
    i_c: "GB",
    name: "Gilgit-Baltistan",
    c_c: "PK",
  },
  {
    i_c: "IS",
    name: "Islamabad Capital Territory",
    c_c: "PK",
  },
  {
    i_c: "JK",
    name: "Azad Kashmir",
    c_c: "PK",
  },
  {
    i_c: "KP",
    name: "Khyber Pakhtunkhwa",
    c_c: "PK",
  },
  {
    i_c: "PB",
    name: "Punjab",
    c_c: "PK",
  },
  {
    i_c: "SD",
    name: "Sindh",
    c_c: "PK",
  },
  {
    i_c: "TA",
    name: "Federally Administered Tribal Areas",
    c_c: "PK",
  },
  {
    i_c: "DS",
    name: "Lower Silesian Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "KP",
    name: "Kuyavian-Pomeranian Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "LB",
    name: "Lubusz Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "LD",
    name: "Łódź Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "LU",
    name: "Lublin Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "MA",
    name: "Lesser Poland Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "MZ",
    name: "Masovian Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "OP",
    name: "Opole Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "PD",
    name: "Podlaskie Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "PK",
    name: "Podkarpackie Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "PM",
    name: "Pomeranian Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "SK",
    name: "Świętokrzyskie Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "SL",
    name: "Silesian Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "WN",
    name: "Warmian-Masurian Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "WP",
    name: "Greater Poland Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "ZP",
    name: "West Pomeranian Voivodeship",
    c_c: "PL",
  },
  {
    i_c: "01",
    name: "Aveiro",
    c_c: "PT",
  },
  {
    i_c: "02",
    name: "Beja",
    c_c: "PT",
  },
  {
    i_c: "03",
    name: "Braga",
    c_c: "PT",
  },
  {
    i_c: "04",
    name: "Bragança",
    c_c: "PT",
  },
  {
    i_c: "05",
    name: "Castelo Branco",
    c_c: "PT",
  },
  {
    i_c: "06",
    name: "Coimbra",
    c_c: "PT",
  },
  {
    i_c: "07",
    name: "Évora",
    c_c: "PT",
  },
  {
    i_c: "08",
    name: "Faro",
    c_c: "PT",
  },
  {
    i_c: "09",
    name: "Guarda",
    c_c: "PT",
  },
  {
    i_c: "10",
    name: "Leiria",
    c_c: "PT",
  },
  {
    i_c: "11",
    name: "Lisbon",
    c_c: "PT",
  },
  {
    i_c: "12",
    name: "Portalegre",
    c_c: "PT",
  },
  {
    i_c: "13",
    name: "Porto",
    c_c: "PT",
  },
  {
    i_c: "14",
    name: "Santarém",
    c_c: "PT",
  },
  {
    i_c: "15",
    name: "Setúbal",
    c_c: "PT",
  },
  {
    i_c: "16",
    name: "Viana do Castelo",
    c_c: "PT",
  },
  {
    i_c: "17",
    name: "Vila Real",
    c_c: "PT",
  },
  {
    i_c: "18",
    name: "Viseu",
    c_c: "PT",
  },
  {
    i_c: "20",
    name: "Açores",
    c_c: "PT",
  },
  {
    i_c: "30",
    name: "Madeira",
    c_c: "PT",
  },
  {
    i_c: "002",
    name: "Aimeliik",
    c_c: "PW",
  },
  {
    i_c: "004",
    name: "Airai",
    c_c: "PW",
  },
  {
    i_c: "010",
    name: "Angaur",
    c_c: "PW",
  },
  {
    i_c: "050",
    name: "Hatohobei",
    c_c: "PW",
  },
  {
    i_c: "100",
    name: "Kayangel",
    c_c: "PW",
  },
  {
    i_c: "150",
    name: "Koror",
    c_c: "PW",
  },
  {
    i_c: "212",
    name: "Melekeok",
    c_c: "PW",
  },
  {
    i_c: "214",
    name: "Ngaraard",
    c_c: "PW",
  },
  {
    i_c: "218",
    name: "Ngarchelong",
    c_c: "PW",
  },
  {
    i_c: "222",
    name: "Ngardmau",
    c_c: "PW",
  },
  {
    i_c: "224",
    name: "Ngatpang",
    c_c: "PW",
  },
  {
    i_c: "226",
    name: "Ngchesar",
    c_c: "PW",
  },
  {
    i_c: "227",
    name: "Ngeremlengui",
    c_c: "PW",
  },
  {
    i_c: "228",
    name: "Ngiwal",
    c_c: "PW",
  },
  {
    i_c: "350",
    name: "Peleliu",
    c_c: "PW",
  },
  {
    i_c: "370",
    name: "Sonsorol",
    c_c: "PW",
  },
  {
    i_c: "1",
    name: "Concepción Department",
    c_c: "PY",
  },
  {
    i_c: "10",
    name: "Alto Paraná Department",
    c_c: "PY",
  },
  {
    i_c: "11",
    name: "Central Department",
    c_c: "PY",
  },
  {
    i_c: "12",
    name: "Ñeembucú Department",
    c_c: "PY",
  },
  {
    i_c: "13",
    name: "Amambay Department",
    c_c: "PY",
  },
  {
    i_c: "14",
    name: "Canindeyú",
    c_c: "PY",
  },
  {
    i_c: "15",
    name: "Presidente Hayes Department",
    c_c: "PY",
  },
  {
    i_c: "16",
    name: "Alto Paraguay Department",
    c_c: "PY",
  },
  {
    i_c: "19",
    name: "Boquerón Department",
    c_c: "PY",
  },
  {
    i_c: "2",
    name: "San Pedro Department",
    c_c: "PY",
  },
  {
    i_c: "3",
    name: "Cordillera Department",
    c_c: "PY",
  },
  {
    i_c: "4",
    name: "Guairá Department",
    c_c: "PY",
  },
  {
    i_c: "5",
    name: "Caaguazú",
    c_c: "PY",
  },
  {
    i_c: "6",
    name: "Caazapá",
    c_c: "PY",
  },
  {
    i_c: "7",
    name: "Itapúa",
    c_c: "PY",
  },
  {
    i_c: "8",
    name: "Misiones Department",
    c_c: "PY",
  },
  {
    i_c: "9",
    name: "Paraguarí Department",
    c_c: "PY",
  },
  {
    i_c: "DA",
    name: "Doha",
    c_c: "QA",
  },
  {
    i_c: "KH",
    name: "Al Khor",
    c_c: "QA",
  },
  {
    i_c: "MS",
    name: "Madinat ash Shamal",
    c_c: "QA",
  },
  {
    i_c: "RA",
    name: "Al Rayyan Municipality",
    c_c: "QA",
  },
  {
    i_c: "SH",
    name: "Al-Shahaniya",
    c_c: "QA",
  },
  {
    i_c: "US",
    name: "Umm Salal Municipality",
    c_c: "QA",
  },
  {
    i_c: "WA",
    name: "Al Wakrah",
    c_c: "QA",
  },
  {
    i_c: "ZA",
    name: "Al Daayen",
    c_c: "QA",
  },
  {
    i_c: "AB",
    name: "Alba",
    c_c: "RO",
  },
  {
    i_c: "AG",
    name: "Arges",
    c_c: "RO",
  },
  {
    i_c: "AR",
    name: "Arad County",
    c_c: "RO",
  },
  {
    i_c: "B",
    name: "Bucharest",
    c_c: "RO",
  },
  {
    i_c: "BC",
    name: "Bacău County",
    c_c: "RO",
  },
  {
    i_c: "BH",
    name: "Bihor County",
    c_c: "RO",
  },
  {
    i_c: "BN",
    name: "Bistrița-Năsăud County",
    c_c: "RO",
  },
  {
    i_c: "BR",
    name: "Braila",
    c_c: "RO",
  },
  {
    i_c: "BT",
    name: "Botoșani County",
    c_c: "RO",
  },
  {
    i_c: "BV",
    name: "Brașov County",
    c_c: "RO",
  },
  {
    i_c: "BZ",
    name: "Buzău County",
    c_c: "RO",
  },
  {
    i_c: "CJ",
    name: "Cluj County",
    c_c: "RO",
  },
  {
    i_c: "CL",
    name: "Călărași County",
    c_c: "RO",
  },
  {
    i_c: "CS",
    name: "Caraș-Severin County",
    c_c: "RO",
  },
  {
    i_c: "CT",
    name: "Constanța County",
    c_c: "RO",
  },
  {
    i_c: "CV",
    name: "Covasna County",
    c_c: "RO",
  },
  {
    i_c: "DB",
    name: "Dâmbovița County",
    c_c: "RO",
  },
  {
    i_c: "DJ",
    name: "Dolj County",
    c_c: "RO",
  },
  {
    i_c: "GJ",
    name: "Gorj County",
    c_c: "RO",
  },
  {
    i_c: "GL",
    name: "Galați County",
    c_c: "RO",
  },
  {
    i_c: "GR",
    name: "Giurgiu County",
    c_c: "RO",
  },
  {
    i_c: "HD",
    name: "Hunedoara County",
    c_c: "RO",
  },
  {
    i_c: "HR",
    name: "Harghita County",
    c_c: "RO",
  },
  {
    i_c: "IF",
    name: "Ilfov County",
    c_c: "RO",
  },
  {
    i_c: "IL",
    name: "Ialomița County",
    c_c: "RO",
  },
  {
    i_c: "IS",
    name: "Iași County",
    c_c: "RO",
  },
  {
    i_c: "MH",
    name: "Mehedinți County",
    c_c: "RO",
  },
  {
    i_c: "MM",
    name: "Maramureș County",
    c_c: "RO",
  },
  {
    i_c: "MS",
    name: "Mureș County",
    c_c: "RO",
  },
  {
    i_c: "NT",
    name: "Neamț County",
    c_c: "RO",
  },
  {
    i_c: "OT",
    name: "Olt County",
    c_c: "RO",
  },
  {
    i_c: "PH",
    name: "Prahova County",
    c_c: "RO",
  },
  {
    i_c: "SB",
    name: "Sibiu County",
    c_c: "RO",
  },
  {
    i_c: "SJ",
    name: "Sălaj County",
    c_c: "RO",
  },
  {
    i_c: "SM",
    name: "Satu Mare County",
    c_c: "RO",
  },
  {
    i_c: "SV",
    name: "Suceava County",
    c_c: "RO",
  },
  {
    i_c: "TL",
    name: "Tulcea County",
    c_c: "RO",
  },
  {
    i_c: "TM",
    name: "Timiș County",
    c_c: "RO",
  },
  {
    i_c: "TR",
    name: "Teleorman County",
    c_c: "RO",
  },
  {
    i_c: "VL",
    name: "Vâlcea County",
    c_c: "RO",
  },
  {
    i_c: "VN",
    name: "Vrancea County",
    c_c: "RO",
  },
  {
    i_c: "VS",
    name: "Vaslui County",
    c_c: "RO",
  },
  {
    i_c: "00",
    name: "Belgrade",
    c_c: "RS",
  },
  {
    i_c: "01",
    name: "North Bačka District",
    c_c: "RS",
  },
  {
    i_c: "02",
    name: "Central Banat District",
    c_c: "RS",
  },
  {
    i_c: "03",
    name: "North Banat District",
    c_c: "RS",
  },
  {
    i_c: "04",
    name: "South Banat District",
    c_c: "RS",
  },
  {
    i_c: "05",
    name: "West Bačka District",
    c_c: "RS",
  },
  {
    i_c: "06",
    name: "South Bačka District",
    c_c: "RS",
  },
  {
    i_c: "07",
    name: "Srem District",
    c_c: "RS",
  },
  {
    i_c: "08",
    name: "Mačva District",
    c_c: "RS",
  },
  {
    i_c: "09",
    name: "Kolubara District",
    c_c: "RS",
  },
  {
    i_c: "10",
    name: "Podunavlje District",
    c_c: "RS",
  },
  {
    i_c: "11",
    name: "Braničevo District",
    c_c: "RS",
  },
  {
    i_c: "12",
    name: "Šumadija District",
    c_c: "RS",
  },
  {
    i_c: "13",
    name: "Pomoravlje District",
    c_c: "RS",
  },
  {
    i_c: "14",
    name: "Bor District",
    c_c: "RS",
  },
  {
    i_c: "15",
    name: "Zaječar District",
    c_c: "RS",
  },
  {
    i_c: "16",
    name: "Zlatibor District",
    c_c: "RS",
  },
  {
    i_c: "17",
    name: "Moravica District",
    c_c: "RS",
  },
  {
    i_c: "18",
    name: "Raška District",
    c_c: "RS",
  },
  {
    i_c: "19",
    name: "Rasina District",
    c_c: "RS",
  },
  {
    i_c: "20",
    name: "Nišava District",
    c_c: "RS",
  },
  {
    i_c: "21",
    name: "Toplica District",
    c_c: "RS",
  },
  {
    i_c: "22",
    name: "Pirot District",
    c_c: "RS",
  },
  {
    i_c: "23",
    name: "Jablanica District",
    c_c: "RS",
  },
  {
    i_c: "24",
    name: "Pčinja District",
    c_c: "RS",
  },
  {
    i_c: "VO",
    name: "Vojvodina",
    c_c: "RS",
  },
  {
    i_c: "AD",
    name: "Republic of Adygea",
    c_c: "RU",
  },
  {
    i_c: "AL",
    name: "Altai Republic",
    c_c: "RU",
  },
  {
    i_c: "ALT",
    name: "Altai Krai",
    c_c: "RU",
  },
  {
    i_c: "AMU",
    name: "Amur Oblast",
    c_c: "RU",
  },
  {
    i_c: "ARK",
    name: "Arkhangelsk",
    c_c: "RU",
  },
  {
    i_c: "AST",
    name: "Astrakhan Oblast",
    c_c: "RU",
  },
  {
    i_c: "BA",
    name: "Republic of Bashkortostan",
    c_c: "RU",
  },
  {
    i_c: "BEL",
    name: "Belgorod Oblast",
    c_c: "RU",
  },
  {
    i_c: "BRY",
    name: "Bryansk Oblast",
    c_c: "RU",
  },
  {
    i_c: "BU",
    name: "Republic of Buryatia",
    c_c: "RU",
  },
  {
    i_c: "CE",
    name: "Chechen Republic",
    c_c: "RU",
  },
  {
    i_c: "CHE",
    name: "Chelyabinsk Oblast",
    c_c: "RU",
  },
  {
    i_c: "CHU",
    name: "Chukotka Autonomous Okrug",
    c_c: "RU",
  },
  {
    i_c: "CU",
    name: "Chuvash Republic",
    c_c: "RU",
  },
  {
    i_c: "DA",
    name: "Republic of Dagestan",
    c_c: "RU",
  },
  {
    i_c: "IN",
    name: "Republic of Ingushetia",
    c_c: "RU",
  },
  {
    i_c: "IRK",
    name: "Irkutsk",
    c_c: "RU",
  },
  {
    i_c: "IVA",
    name: "Ivanovo Oblast",
    c_c: "RU",
  },
  {
    i_c: "KAM",
    name: "Kamchatka Krai",
    c_c: "RU",
  },
  {
    i_c: "KB",
    name: "Kabardino-Balkar Republic",
    c_c: "RU",
  },
  {
    i_c: "KC",
    name: "Karachay-Cherkess Republic",
    c_c: "RU",
  },
  {
    i_c: "KDA",
    name: "Krasnodar Krai",
    c_c: "RU",
  },
  {
    i_c: "KEM",
    name: "Kemerovo Oblast",
    c_c: "RU",
  },
  {
    i_c: "KGD",
    name: "Kaliningrad",
    c_c: "RU",
  },
  {
    i_c: "KGN",
    name: "Kurgan Oblast",
    c_c: "RU",
  },
  {
    i_c: "KHA",
    name: "Khabarovsk Krai",
    c_c: "RU",
  },
  {
    i_c: "KHM",
    name: "Khanty-Mansi Autonomous Okrug",
    c_c: "RU",
  },
  {
    i_c: "KIR",
    name: "Kirov Oblast",
    c_c: "RU",
  },
  {
    i_c: "KK",
    name: "Republic of Khakassia",
    c_c: "RU",
  },
  {
    i_c: "KL",
    name: "Republic of Kalmykia",
    c_c: "RU",
  },
  {
    i_c: "KLU",
    name: "Kaluga Oblast",
    c_c: "RU",
  },
  {
    i_c: "KO",
    name: "Komi Republic",
    c_c: "RU",
  },
  {
    i_c: "KOS",
    name: "Kostroma Oblast",
    c_c: "RU",
  },
  {
    i_c: "KR",
    name: "Republic of Karelia",
    c_c: "RU",
  },
  {
    i_c: "KRS",
    name: "Kursk Oblast",
    c_c: "RU",
  },
  {
    i_c: "KYA",
    name: "Krasnoyarsk Krai",
    c_c: "RU",
  },
  {
    i_c: "LEN",
    name: "Leningrad Oblast",
    c_c: "RU",
  },
  {
    i_c: "LIP",
    name: "Lipetsk Oblast",
    c_c: "RU",
  },
  {
    i_c: "MAG",
    name: "Magadan Oblast",
    c_c: "RU",
  },
  {
    i_c: "ME",
    name: "Mari El Republic",
    c_c: "RU",
  },
  {
    i_c: "MO",
    name: "Republic of Mordovia",
    c_c: "RU",
  },
  {
    i_c: "MOS",
    name: "Moscow Oblast",
    c_c: "RU",
  },
  {
    i_c: "MOW",
    name: "Moscow",
    c_c: "RU",
  },
  {
    i_c: "MUR",
    name: "Murmansk Oblast",
    c_c: "RU",
  },
  {
    i_c: "NEN",
    name: "Nenets Autonomous Okrug",
    c_c: "RU",
  },
  {
    i_c: "NGR",
    name: "Novgorod Oblast",
    c_c: "RU",
  },
  {
    i_c: "NIZ",
    name: "Nizhny Novgorod Oblast",
    c_c: "RU",
  },
  {
    i_c: "NVS",
    name: "Novosibirsk",
    c_c: "RU",
  },
  {
    i_c: "OMS",
    name: "Omsk Oblast",
    c_c: "RU",
  },
  {
    i_c: "ORE",
    name: "Orenburg Oblast",
    c_c: "RU",
  },
  {
    i_c: "ORL",
    name: "Oryol Oblast",
    c_c: "RU",
  },
  {
    i_c: "PER",
    name: "Perm Krai",
    c_c: "RU",
  },
  {
    i_c: "PNZ",
    name: "Penza Oblast",
    c_c: "RU",
  },
  {
    i_c: "PRI",
    name: "Primorsky Krai",
    c_c: "RU",
  },
  {
    i_c: "PSK",
    name: "Pskov Oblast",
    c_c: "RU",
  },
  {
    i_c: "ROS",
    name: "Rostov Oblast",
    c_c: "RU",
  },
  {
    i_c: "RYA",
    name: "Ryazan Oblast",
    c_c: "RU",
  },
  {
    i_c: "SA",
    name: "Sakha Republic",
    c_c: "RU",
  },
  {
    i_c: "SAK",
    name: "Sakhalin",
    c_c: "RU",
  },
  {
    i_c: "SAM",
    name: "Samara Oblast",
    c_c: "RU",
  },
  {
    i_c: "SAR",
    name: "Saratov Oblast",
    c_c: "RU",
  },
  {
    i_c: "SE",
    name: "Republic of North Ossetia-Alania",
    c_c: "RU",
  },
  {
    i_c: "SMO",
    name: "Smolensk Oblast",
    c_c: "RU",
  },
  {
    i_c: "SPE",
    name: "Saint Petersburg",
    c_c: "RU",
  },
  {
    i_c: "STA",
    name: "Stavropol Krai",
    c_c: "RU",
  },
  {
    i_c: "SVE",
    name: "Sverdlovsk",
    c_c: "RU",
  },
  {
    i_c: "TA",
    name: "Republic of Tatarstan",
    c_c: "RU",
  },
  {
    i_c: "TAM",
    name: "Tambov Oblast",
    c_c: "RU",
  },
  {
    i_c: "TOM",
    name: "Tomsk Oblast",
    c_c: "RU",
  },
  {
    i_c: "TUL",
    name: "Tula Oblast",
    c_c: "RU",
  },
  {
    i_c: "TVE",
    name: "Tver Oblast",
    c_c: "RU",
  },
  {
    i_c: "TY",
    name: "Tuva Republic",
    c_c: "RU",
  },
  {
    i_c: "TYU",
    name: "Tyumen Oblast",
    c_c: "RU",
  },
  {
    i_c: "UA-40",
    name: "Sevastopol",
    c_c: "RU",
  },
  {
    i_c: "UD",
    name: "Udmurt Republic",
    c_c: "RU",
  },
  {
    i_c: "ULY",
    name: "Ulyanovsk Oblast",
    c_c: "RU",
  },
  {
    i_c: "VGG",
    name: "Volgograd Oblast",
    c_c: "RU",
  },
  {
    i_c: "VLA",
    name: "Vladimir Oblast",
    c_c: "RU",
  },
  {
    i_c: "VLG",
    name: "Vologda Oblast",
    c_c: "RU",
  },
  {
    i_c: "VOR",
    name: "Voronezh Oblast",
    c_c: "RU",
  },
  {
    i_c: "YAN",
    name: "Yamalo-Nenets Autonomous Okrug",
    c_c: "RU",
  },
  {
    i_c: "YAR",
    name: "Yaroslavl Oblast",
    c_c: "RU",
  },
  {
    i_c: "YEV",
    name: "Jewish Autonomous Oblast",
    c_c: "RU",
  },
  {
    i_c: "ZAB",
    name: "Zabaykalsky Krai",
    c_c: "RU",
  },
  {
    i_c: "01",
    name: "Kigali district",
    c_c: "RW",
  },
  {
    i_c: "02",
    name: "Eastern Province",
    c_c: "RW",
  },
  {
    i_c: "03",
    name: "Northern Province",
    c_c: "RW",
  },
  {
    i_c: "04",
    name: "Western Province",
    c_c: "RW",
  },
  {
    i_c: "05",
    name: "Southern Province",
    c_c: "RW",
  },
  {
    i_c: "01",
    name: "Riyadh",
    c_c: "SA",
  },
  {
    i_c: "02",
    name: "Makkah",
    c_c: "SA",
  },
  {
    i_c: "03",
    name: "Al Madinah",
    c_c: "SA",
  },
  {
    i_c: "04",
    name: "Eastern Province",
    c_c: "SA",
  },
  {
    i_c: "05",
    name: "Al-Qassim",
    c_c: "SA",
  },
  {
    i_c: "06",
    name: "Ha'il",
    c_c: "SA",
  },
  {
    i_c: "07",
    name: "Tabuk",
    c_c: "SA",
  },
  {
    i_c: "08",
    name: "Northern Borders",
    c_c: "SA",
  },
  {
    i_c: "09",
    name: "Jizan",
    c_c: "SA",
  },
  {
    i_c: "10",
    name: "Najran",
    c_c: "SA",
  },
  {
    i_c: "11",
    name: "Al Bahah",
    c_c: "SA",
  },
  {
    i_c: "12",
    name: "Al Jawf",
    c_c: "SA",
  },
  {
    i_c: "14",
    name: "'Asir",
    c_c: "SA",
  },
  {
    i_c: "CE",
    name: "Central Province",
    c_c: "SB",
  },
  {
    i_c: "CH",
    name: "Choiseul Province",
    c_c: "SB",
  },
  {
    i_c: "CT",
    name: "Honiara",
    c_c: "SB",
  },
  {
    i_c: "GU",
    name: "Guadalcanal Province",
    c_c: "SB",
  },
  {
    i_c: "IS",
    name: "Isabel Province",
    c_c: "SB",
  },
  {
    i_c: "MK",
    name: "Makira-Ulawa Province",
    c_c: "SB",
  },
  {
    i_c: "ML",
    name: "Malaita Province",
    c_c: "SB",
  },
  {
    i_c: "RB",
    name: "Rennell and Bellona Province",
    c_c: "SB",
  },
  {
    i_c: "TE",
    name: "Temotu Province",
    c_c: "SB",
  },
  {
    i_c: "WE",
    name: "Western Province",
    c_c: "SB",
  },
  {
    i_c: "01",
    name: "Anse-aux-Pins",
    c_c: "SC",
  },
  {
    i_c: "02",
    name: "Anse Boileau",
    c_c: "SC",
  },
  {
    i_c: "04",
    name: "Au Cap",
    c_c: "SC",
  },
  {
    i_c: "05",
    name: "Anse Royale",
    c_c: "SC",
  },
  {
    i_c: "06",
    name: "Baie Lazare",
    c_c: "SC",
  },
  {
    i_c: "07",
    name: "Baie Sainte Anne",
    c_c: "SC",
  },
  {
    i_c: "08",
    name: "Beau Vallon",
    c_c: "SC",
  },
  {
    i_c: "09",
    name: "Bel Air",
    c_c: "SC",
  },
  {
    i_c: "10",
    name: "Bel Ombre",
    c_c: "SC",
  },
  {
    i_c: "11",
    name: "Cascade",
    c_c: "SC",
  },
  {
    i_c: "12",
    name: "Glacis",
    c_c: "SC",
  },
  {
    i_c: "13",
    name: "Grand'Anse Mahé",
    c_c: "SC",
  },
  {
    i_c: "14",
    name: "Grand'Anse Praslin",
    c_c: "SC",
  },
  {
    i_c: "15",
    name: "La Digue",
    c_c: "SC",
  },
  {
    i_c: "16",
    name: "La Rivière Anglaise",
    c_c: "SC",
  },
  {
    i_c: "17",
    name: "Mont Buxton",
    c_c: "SC",
  },
  {
    i_c: "18",
    name: "Mont Fleuri",
    c_c: "SC",
  },
  {
    i_c: "19",
    name: "Plaisance",
    c_c: "SC",
  },
  {
    i_c: "20",
    name: "Pointe La Rue",
    c_c: "SC",
  },
  {
    i_c: "21",
    name: "Port Glaud",
    c_c: "SC",
  },
  {
    i_c: "22",
    name: "Saint Louis",
    c_c: "SC",
  },
  {
    i_c: "23",
    name: "Takamaka",
    c_c: "SC",
  },
  {
    i_c: "24",
    name: "Les Mamelles",
    c_c: "SC",
  },
  {
    i_c: "25",
    name: "Roche Caiman",
    c_c: "SC",
  },
  {
    i_c: "DC",
    name: "Central Darfur",
    c_c: "SD",
  },
  {
    i_c: "DE",
    name: "East Darfur",
    c_c: "SD",
  },
  {
    i_c: "DN",
    name: "North Darfur",
    c_c: "SD",
  },
  {
    i_c: "DS",
    name: "South Darfur",
    c_c: "SD",
  },
  {
    i_c: "DW",
    name: "West Darfur",
    c_c: "SD",
  },
  {
    i_c: "GD",
    name: "Al Qadarif",
    c_c: "SD",
  },
  {
    i_c: "GK",
    name: "West Kordofan",
    c_c: "SD",
  },
  {
    i_c: "GZ",
    name: "Al Jazirah",
    c_c: "SD",
  },
  {
    i_c: "KA",
    name: "Kassala",
    c_c: "SD",
  },
  {
    i_c: "KH",
    name: "Khartoum",
    c_c: "SD",
  },
  {
    i_c: "KN",
    name: "North Kordofan",
    c_c: "SD",
  },
  {
    i_c: "KS",
    name: "South Kordofan",
    c_c: "SD",
  },
  {
    i_c: "NB",
    name: "Blue Nile",
    c_c: "SD",
  },
  {
    i_c: "NO",
    name: "Northern",
    c_c: "SD",
  },
  {
    i_c: "NR",
    name: "River Nile",
    c_c: "SD",
  },
  {
    i_c: "NW",
    name: "White Nile",
    c_c: "SD",
  },
  {
    i_c: "RS",
    name: "Red Sea",
    c_c: "SD",
  },
  {
    i_c: "SI",
    name: "Sennar",
    c_c: "SD",
  },
  {
    i_c: "AB",
    name: "Stockholm County",
    c_c: "SE",
  },
  {
    i_c: "AC",
    name: "Västerbotten County",
    c_c: "SE",
  },
  {
    i_c: "BD",
    name: "Norrbotten County",
    c_c: "SE",
  },
  {
    i_c: "C",
    name: "Uppsala County",
    c_c: "SE",
  },
  {
    i_c: "D",
    name: "Södermanland County",
    c_c: "SE",
  },
  {
    i_c: "E",
    name: "Östergötland County",
    c_c: "SE",
  },
  {
    i_c: "F",
    name: "Jönköping County",
    c_c: "SE",
  },
  {
    i_c: "G",
    name: "Kronoberg County",
    c_c: "SE",
  },
  {
    i_c: "H",
    name: "Kalmar County",
    c_c: "SE",
  },
  {
    i_c: "I",
    name: "Gotland County",
    c_c: "SE",
  },
  {
    i_c: "K",
    name: "Blekinge",
    c_c: "SE",
  },
  {
    i_c: "M",
    name: "Skåne County",
    c_c: "SE",
  },
  {
    i_c: "N",
    name: "Halland County",
    c_c: "SE",
  },
  {
    i_c: "O",
    name: "Västra Götaland County",
    c_c: "SE",
  },
  {
    i_c: "S",
    name: "Värmland County",
    c_c: "SE",
  },
  {
    i_c: "T",
    name: "Örebro County",
    c_c: "SE",
  },
  {
    i_c: "U",
    name: "Västmanland County",
    c_c: "SE",
  },
  {
    i_c: "W",
    name: "Dalarna County",
    c_c: "SE",
  },
  {
    i_c: "X",
    name: "Gävleborg County",
    c_c: "SE",
  },
  {
    i_c: "Y",
    name: "Västernorrland County",
    c_c: "SE",
  },
  {
    i_c: "01",
    name: "Central Singapore Community Development Council",
    c_c: "SG",
  },
  {
    i_c: "02",
    name: "North East Community Development Council",
    c_c: "SG",
  },
  {
    i_c: "03",
    name: "North West Community Development Council",
    c_c: "SG",
  },
  {
    i_c: "04",
    name: "South East Community Development Council",
    c_c: "SG",
  },
  {
    i_c: "05",
    name: "South West Community Development Council",
    c_c: "SG",
  },
  {
    i_c: "001",
    name: "Ajdovščina Municipality",
    c_c: "SI",
  },
  {
    i_c: "002",
    name: "Beltinci Municipality",
    c_c: "SI",
  },
  {
    i_c: "003",
    name: "Bled Municipality",
    c_c: "SI",
  },
  {
    i_c: "004",
    name: "Bohinj Municipality",
    c_c: "SI",
  },
  {
    i_c: "005",
    name: "Borovnica Municipality",
    c_c: "SI",
  },
  {
    i_c: "006",
    name: "Bovec Municipality",
    c_c: "SI",
  },
  {
    i_c: "007",
    name: "Brda Municipality",
    c_c: "SI",
  },
  {
    i_c: "008",
    name: "Brezovica Municipality",
    c_c: "SI",
  },
  {
    i_c: "009",
    name: "Brežice Municipality",
    c_c: "SI",
  },
  {
    i_c: "010",
    name: "Tišina Municipality",
    c_c: "SI",
  },
  {
    i_c: "011",
    name: "City Municipality of Celje",
    c_c: "SI",
  },
  {
    i_c: "012",
    name: "Cerklje na Gorenjskem Municipality",
    c_c: "SI",
  },
  {
    i_c: "013",
    name: "Cerknica Municipality",
    c_c: "SI",
  },
  {
    i_c: "014",
    name: "Cerkno Municipality",
    c_c: "SI",
  },
  {
    i_c: "015",
    name: "Črenšovci Municipality",
    c_c: "SI",
  },
  {
    i_c: "016",
    name: "Črna na Koroškem Municipality",
    c_c: "SI",
  },
  {
    i_c: "017",
    name: "Črnomelj Municipality",
    c_c: "SI",
  },
  {
    i_c: "018",
    name: "Destrnik Municipality",
    c_c: "SI",
  },
  {
    i_c: "019",
    name: "Divača Municipality",
    c_c: "SI",
  },
  {
    i_c: "020",
    name: "Dobrepolje Municipality",
    c_c: "SI",
  },
  {
    i_c: "021",
    name: "Dobrova–Polhov Gradec Municipality",
    c_c: "SI",
  },
  {
    i_c: "022",
    name: "Dol pri Ljubljani Municipality",
    c_c: "SI",
  },
  {
    i_c: "023",
    name: "Domžale Municipality",
    c_c: "SI",
  },
  {
    i_c: "024",
    name: "Dornava Municipality",
    c_c: "SI",
  },
  {
    i_c: "025",
    name: "Dravograd Municipality",
    c_c: "SI",
  },
  {
    i_c: "026",
    name: "Duplek Municipality",
    c_c: "SI",
  },
  {
    i_c: "027",
    name: "Gorenja Vas–Poljane Municipality",
    c_c: "SI",
  },
  {
    i_c: "028",
    name: "Gorišnica Municipality",
    c_c: "SI",
  },
  {
    i_c: "029",
    name: "Gornja Radgona Municipality",
    c_c: "SI",
  },
  {
    i_c: "030",
    name: "Gornji Grad Municipality",
    c_c: "SI",
  },
  {
    i_c: "031",
    name: "Gornji Petrovci Municipality",
    c_c: "SI",
  },
  {
    i_c: "032",
    name: "Grosuplje Municipality",
    c_c: "SI",
  },
  {
    i_c: "033",
    name: "Šalovci Municipality",
    c_c: "SI",
  },
  {
    i_c: "034",
    name: "Hrastnik Municipality",
    c_c: "SI",
  },
  {
    i_c: "035",
    name: "Hrpelje–Kozina Municipality",
    c_c: "SI",
  },
  {
    i_c: "036",
    name: "Idrija Municipality",
    c_c: "SI",
  },
  {
    i_c: "037",
    name: "Ig Municipality",
    c_c: "SI",
  },
  {
    i_c: "038",
    name: "Municipality of Ilirska Bistrica",
    c_c: "SI",
  },
  {
    i_c: "039",
    name: "Ivančna Gorica Municipality",
    c_c: "SI",
  },
  {
    i_c: "040",
    name: "Izola Municipality",
    c_c: "SI",
  },
  {
    i_c: "041",
    name: "Jesenice Municipality",
    c_c: "SI",
  },
  {
    i_c: "042",
    name: "Juršinci Municipality",
    c_c: "SI",
  },
  {
    i_c: "043",
    name: "Kamnik Municipality",
    c_c: "SI",
  },
  {
    i_c: "044",
    name: "Kanal ob Soči Municipality",
    c_c: "SI",
  },
  {
    i_c: "045",
    name: "Kidričevo Municipality",
    c_c: "SI",
  },
  {
    i_c: "046",
    name: "Kobarid Municipality",
    c_c: "SI",
  },
  {
    i_c: "047",
    name: "Kobilje Municipality",
    c_c: "SI",
  },
  {
    i_c: "048",
    name: "Kočevje Municipality",
    c_c: "SI",
  },
  {
    i_c: "049",
    name: "Komen Municipality",
    c_c: "SI",
  },
  {
    i_c: "050",
    name: "Koper City Municipality",
    c_c: "SI",
  },
  {
    i_c: "051",
    name: "Kozje Municipality",
    c_c: "SI",
  },
  {
    i_c: "052",
    name: "Kranj City Municipality",
    c_c: "SI",
  },
  {
    i_c: "053",
    name: "Kranjska Gora Municipality",
    c_c: "SI",
  },
  {
    i_c: "054",
    name: "Municipality of Krško",
    c_c: "SI",
  },
  {
    i_c: "055",
    name: "Kungota",
    c_c: "SI",
  },
  {
    i_c: "056",
    name: "Kuzma Municipality",
    c_c: "SI",
  },
  {
    i_c: "057",
    name: "Laško Municipality",
    c_c: "SI",
  },
  {
    i_c: "058",
    name: "Lenart Municipality",
    c_c: "SI",
  },
  {
    i_c: "059",
    name: "Lendava Municipality",
    c_c: "SI",
  },
  {
    i_c: "060",
    name: "Litija Municipality",
    c_c: "SI",
  },
  {
    i_c: "061",
    name: "Ljubljana City Municipality",
    c_c: "SI",
  },
  {
    i_c: "062",
    name: "Ljubno Municipality",
    c_c: "SI",
  },
  {
    i_c: "063",
    name: "Ljutomer Municipality",
    c_c: "SI",
  },
  {
    i_c: "064",
    name: "Logatec Municipality",
    c_c: "SI",
  },
  {
    i_c: "065",
    name: "Loška Dolina Municipality",
    c_c: "SI",
  },
  {
    i_c: "066",
    name: "Loški Potok Municipality",
    c_c: "SI",
  },
  {
    i_c: "067",
    name: "Luče Municipality",
    c_c: "SI",
  },
  {
    i_c: "068",
    name: "Lukovica Municipality",
    c_c: "SI",
  },
  {
    i_c: "069",
    name: "Majšperk Municipality",
    c_c: "SI",
  },
  {
    i_c: "070",
    name: "Maribor City Municipality",
    c_c: "SI",
  },
  {
    i_c: "071",
    name: "Medvode Municipality",
    c_c: "SI",
  },
  {
    i_c: "072",
    name: "Mengeš Municipality",
    c_c: "SI",
  },
  {
    i_c: "073",
    name: "Metlika Municipality",
    c_c: "SI",
  },
  {
    i_c: "074",
    name: "Mežica Municipality",
    c_c: "SI",
  },
  {
    i_c: "075",
    name: "Miren–Kostanjevica Municipality",
    c_c: "SI",
  },
  {
    i_c: "076",
    name: "Mislinja Municipality",
    c_c: "SI",
  },
  {
    i_c: "077",
    name: "Moravče Municipality",
    c_c: "SI",
  },
  {
    i_c: "078",
    name: "Moravske Toplice Municipality",
    c_c: "SI",
  },
  {
    i_c: "079",
    name: "Mozirje Municipality",
    c_c: "SI",
  },
  {
    i_c: "080",
    name: "Murska Sobota City Municipality",
    c_c: "SI",
  },
  {
    i_c: "081",
    name: "Muta Municipality",
    c_c: "SI",
  },
  {
    i_c: "082",
    name: "Naklo Municipality",
    c_c: "SI",
  },
  {
    i_c: "083",
    name: "Nazarje Municipality",
    c_c: "SI",
  },
  {
    i_c: "084",
    name: "Nova Gorica City Municipality",
    c_c: "SI",
  },
  {
    i_c: "085",
    name: "City Municipality of Novo Mesto",
    c_c: "SI",
  },
  {
    i_c: "086",
    name: "Odranci Municipality",
    c_c: "SI",
  },
  {
    i_c: "087",
    name: "Ormož Municipality",
    c_c: "SI",
  },
  {
    i_c: "088",
    name: "Osilnica Municipality",
    c_c: "SI",
  },
  {
    i_c: "089",
    name: "Pesnica Municipality",
    c_c: "SI",
  },
  {
    i_c: "090",
    name: "Piran Municipality",
    c_c: "SI",
  },
  {
    i_c: "091",
    name: "Pivka Municipality",
    c_c: "SI",
  },
  {
    i_c: "092",
    name: "Podčetrtek Municipality",
    c_c: "SI",
  },
  {
    i_c: "093",
    name: "Podvelka Municipality",
    c_c: "SI",
  },
  {
    i_c: "094",
    name: "Postojna Municipality",
    c_c: "SI",
  },
  {
    i_c: "095",
    name: "Preddvor Municipality",
    c_c: "SI",
  },
  {
    i_c: "096",
    name: "Ptuj City Municipality",
    c_c: "SI",
  },
  {
    i_c: "097",
    name: "Puconci Municipality",
    c_c: "SI",
  },
  {
    i_c: "098",
    name: "Rače–Fram Municipality",
    c_c: "SI",
  },
  {
    i_c: "099",
    name: "Radeče Municipality",
    c_c: "SI",
  },
  {
    i_c: "100",
    name: "Radenci Municipality",
    c_c: "SI",
  },
  {
    i_c: "101",
    name: "Radlje ob Dravi Municipality",
    c_c: "SI",
  },
  {
    i_c: "102",
    name: "Radovljica Municipality",
    c_c: "SI",
  },
  {
    i_c: "103",
    name: "Ravne na Koroškem Municipality",
    c_c: "SI",
  },
  {
    i_c: "104",
    name: "Ribnica Municipality",
    c_c: "SI",
  },
  {
    i_c: "105",
    name: "Rogašovci Municipality",
    c_c: "SI",
  },
  {
    i_c: "106",
    name: "Rogaška Slatina Municipality",
    c_c: "SI",
  },
  {
    i_c: "107",
    name: "Rogatec Municipality",
    c_c: "SI",
  },
  {
    i_c: "108",
    name: "Ruše Municipality",
    c_c: "SI",
  },
  {
    i_c: "109",
    name: "Semič Municipality",
    c_c: "SI",
  },
  {
    i_c: "110",
    name: "Sevnica Municipality",
    c_c: "SI",
  },
  {
    i_c: "111",
    name: "Sežana Municipality",
    c_c: "SI",
  },
  {
    i_c: "112",
    name: "Slovenj Gradec City Municipality",
    c_c: "SI",
  },
  {
    i_c: "113",
    name: "Slovenska Bistrica Municipality",
    c_c: "SI",
  },
  {
    i_c: "114",
    name: "Slovenske Konjice Municipality",
    c_c: "SI",
  },
  {
    i_c: "115",
    name: "Starše Municipality",
    c_c: "SI",
  },
  {
    i_c: "116",
    name: "Sveti Jurij ob Ščavnici Municipality",
    c_c: "SI",
  },
  {
    i_c: "117",
    name: "Šenčur Municipality",
    c_c: "SI",
  },
  {
    i_c: "118",
    name: "Šentilj Municipality",
    c_c: "SI",
  },
  {
    i_c: "119",
    name: "Šentjernej Municipality",
    c_c: "SI",
  },
  {
    i_c: "120",
    name: "Šentjur Municipality",
    c_c: "SI",
  },
  {
    i_c: "121",
    name: "Škocjan Municipality",
    c_c: "SI",
  },
  {
    i_c: "122",
    name: "Škofja Loka Municipality",
    c_c: "SI",
  },
  {
    i_c: "123",
    name: "Municipality of Škofljica",
    c_c: "SI",
  },
  {
    i_c: "124",
    name: "Šmarje pri Jelšah Municipality",
    c_c: "SI",
  },
  {
    i_c: "125",
    name: "Šmartno ob Paki Municipality",
    c_c: "SI",
  },
  {
    i_c: "126",
    name: "Šoštanj Municipality",
    c_c: "SI",
  },
  {
    i_c: "127",
    name: "Štore Municipality",
    c_c: "SI",
  },
  {
    i_c: "128",
    name: "Tolmin Municipality",
    c_c: "SI",
  },
  {
    i_c: "129",
    name: "Trbovlje Municipality",
    c_c: "SI",
  },
  {
    i_c: "130",
    name: "Trebnje Municipality",
    c_c: "SI",
  },
  {
    i_c: "131",
    name: "Tržič Municipality",
    c_c: "SI",
  },
  {
    i_c: "132",
    name: "Turnišče Municipality",
    c_c: "SI",
  },
  {
    i_c: "134",
    name: "Velike Lašče Municipality",
    c_c: "SI",
  },
  {
    i_c: "135",
    name: "Videm Municipality",
    c_c: "SI",
  },
  {
    i_c: "136",
    name: "Vipava Municipality",
    c_c: "SI",
  },
  {
    i_c: "137",
    name: "Vitanje Municipality",
    c_c: "SI",
  },
  {
    i_c: "138",
    name: "Vodice Municipality",
    c_c: "SI",
  },
  {
    i_c: "139",
    name: "Vojnik Municipality",
    c_c: "SI",
  },
  {
    i_c: "140",
    name: "Vrhnika Municipality",
    c_c: "SI",
  },
  {
    i_c: "141",
    name: "Vuzenica Municipality",
    c_c: "SI",
  },
  {
    i_c: "142",
    name: "Zagorje ob Savi Municipality",
    c_c: "SI",
  },
  {
    i_c: "143",
    name: "Zavrč Municipality",
    c_c: "SI",
  },
  {
    i_c: "144",
    name: "Zreče Municipality",
    c_c: "SI",
  },
  {
    i_c: "146",
    name: "Železniki Municipality",
    c_c: "SI",
  },
  {
    i_c: "147",
    name: "Žiri Municipality",
    c_c: "SI",
  },
  {
    i_c: "148",
    name: "Benedikt Municipality",
    c_c: "SI",
  },
  {
    i_c: "149",
    name: "Bistrica ob Sotli Municipality",
    c_c: "SI",
  },
  {
    i_c: "150",
    name: "Bloke Municipality",
    c_c: "SI",
  },
  {
    i_c: "151",
    name: "Braslovče Municipality",
    c_c: "SI",
  },
  {
    i_c: "152",
    name: "Cankova Municipality",
    c_c: "SI",
  },
  {
    i_c: "153",
    name: "Cerkvenjak Municipality",
    c_c: "SI",
  },
  {
    i_c: "154",
    name: "Dobje Municipality",
    c_c: "SI",
  },
  {
    i_c: "155",
    name: "Dobrna Municipality",
    c_c: "SI",
  },
  {
    i_c: "156",
    name: "Dobrovnik Municipality",
    c_c: "SI",
  },
  {
    i_c: "157",
    name: "Dolenjske Toplice Municipality",
    c_c: "SI",
  },
  {
    i_c: "158",
    name: "Grad Municipality",
    c_c: "SI",
  },
  {
    i_c: "159",
    name: "Hajdina Municipality",
    c_c: "SI",
  },
  {
    i_c: "160",
    name: "Hoče–Slivnica Municipality",
    c_c: "SI",
  },
  {
    i_c: "161",
    name: "Hodoš Municipality",
    c_c: "SI",
  },
  {
    i_c: "162",
    name: "Horjul Municipality",
    c_c: "SI",
  },
  {
    i_c: "163",
    name: "Jezersko Municipality",
    c_c: "SI",
  },
  {
    i_c: "164",
    name: "Komenda Municipality",
    c_c: "SI",
  },
  {
    i_c: "165",
    name: "Kostel Municipality",
    c_c: "SI",
  },
  {
    i_c: "166",
    name: "Križevci Municipality",
    c_c: "SI",
  },
  {
    i_c: "167",
    name: "Lovrenc na Pohorju Municipality",
    c_c: "SI",
  },
  {
    i_c: "168",
    name: "Markovci Municipality",
    c_c: "SI",
  },
  {
    i_c: "169",
    name: "Miklavž na Dravskem Polju Municipality",
    c_c: "SI",
  },
  {
    i_c: "170",
    name: "Mirna Peč Municipality",
    c_c: "SI",
  },
  {
    i_c: "171",
    name: "Oplotnica",
    c_c: "SI",
  },
  {
    i_c: "172",
    name: "Podlehnik Municipality",
    c_c: "SI",
  },
  {
    i_c: "173",
    name: "Polzela Municipality",
    c_c: "SI",
  },
  {
    i_c: "174",
    name: "Prebold Municipality",
    c_c: "SI",
  },
  {
    i_c: "175",
    name: "Prevalje Municipality",
    c_c: "SI",
  },
  {
    i_c: "176",
    name: "Razkrižje Municipality",
    c_c: "SI",
  },
  {
    i_c: "177",
    name: "Ribnica na Pohorju Municipality",
    c_c: "SI",
  },
  {
    i_c: "178",
    name: "Selnica ob Dravi Municipality",
    c_c: "SI",
  },
  {
    i_c: "179",
    name: "Sodražica Municipality",
    c_c: "SI",
  },
  {
    i_c: "180",
    name: "Solčava Municipality",
    c_c: "SI",
  },
  {
    i_c: "181",
    name: "Sveta Ana Municipality",
    c_c: "SI",
  },
  {
    i_c: "182",
    name: "Sveti Andraž v Slovenskih Goricah Municipality",
    c_c: "SI",
  },
  {
    i_c: "183",
    name: "Šempeter–Vrtojba Municipality",
    c_c: "SI",
  },
  {
    i_c: "184",
    name: "Tabor Municipality",
    c_c: "SI",
  },
  {
    i_c: "185",
    name: "Trnovska Vas Municipality",
    c_c: "SI",
  },
  {
    i_c: "186",
    name: "Trzin Municipality",
    c_c: "SI",
  },
  {
    i_c: "187",
    name: "Velika Polana Municipality",
    c_c: "SI",
  },
  {
    i_c: "188",
    name: "Veržej Municipality",
    c_c: "SI",
  },
  {
    i_c: "189",
    name: "Vransko Municipality",
    c_c: "SI",
  },
  {
    i_c: "190",
    name: "Žalec Municipality",
    c_c: "SI",
  },
  {
    i_c: "191",
    name: "Žetale Municipality",
    c_c: "SI",
  },
  {
    i_c: "192",
    name: "Žirovnica Municipality",
    c_c: "SI",
  },
  {
    i_c: "193",
    name: "Žužemberk Municipality",
    c_c: "SI",
  },
  {
    i_c: "194",
    name: "Šmartno pri Litiji Municipality",
    c_c: "SI",
  },
  {
    i_c: "195",
    name: "Municipality of Apače",
    c_c: "SI",
  },
  {
    i_c: "196",
    name: "Municipality of Cirkulane",
    c_c: "SI",
  },
  {
    i_c: "197",
    name: "Kostanjevica na Krki Municipality",
    c_c: "SI",
  },
  {
    i_c: "198",
    name: "Makole Municipality",
    c_c: "SI",
  },
  {
    i_c: "199",
    name: "Mokronog–Trebelno Municipality",
    c_c: "SI",
  },
  {
    i_c: "200",
    name: "Poljčane Municipality",
    c_c: "SI",
  },
  {
    i_c: "201",
    name: "Renče–Vogrsko Municipality",
    c_c: "SI",
  },
  {
    i_c: "202",
    name: "Središče ob Dravi",
    c_c: "SI",
  },
  {
    i_c: "203",
    name: "Straža Municipality",
    c_c: "SI",
  },
  {
    i_c: "204",
    name: "Sveta Trojica v Slovenskih Goricah Municipality",
    c_c: "SI",
  },
  {
    i_c: "205",
    name: "Sveti Tomaž Municipality",
    c_c: "SI",
  },
  {
    i_c: "206",
    name: "Šmarješke Toplice Municipality",
    c_c: "SI",
  },
  {
    i_c: "207",
    name: "Gorje Municipality",
    c_c: "SI",
  },
  {
    i_c: "208",
    name: "Log–Dragomer Municipality",
    c_c: "SI",
  },
  {
    i_c: "209",
    name: "Rečica ob Savinji Municipality",
    c_c: "SI",
  },
  {
    i_c: "210",
    name: "Sveti Jurij v Slovenskih Goricah Municipality",
    c_c: "SI",
  },
  {
    i_c: "211",
    name: "Šentrupert Municipality",
    c_c: "SI",
  },
  {
    i_c: "212",
    name: "Mirna Municipality",
    c_c: "SI",
  },
  {
    i_c: "213",
    name: "Ankaran Municipality",
    c_c: "SI",
  },
  {
    i_c: "BC",
    name: "Banská Bystrica Region",
    c_c: "SK",
  },
  {
    i_c: "BL",
    name: "Bratislava Region",
    c_c: "SK",
  },
  {
    i_c: "KI",
    name: "Košice Region",
    c_c: "SK",
  },
  {
    i_c: "NI",
    name: "Nitra Region",
    c_c: "SK",
  },
  {
    i_c: "PV",
    name: "Prešov Region",
    c_c: "SK",
  },
  {
    i_c: "TA",
    name: "Trnava Region",
    c_c: "SK",
  },
  {
    i_c: "TC",
    name: "Trenčín Region",
    c_c: "SK",
  },
  {
    i_c: "ZI",
    name: "Žilina Region",
    c_c: "SK",
  },
  {
    i_c: "E",
    name: "Eastern Province",
    c_c: "SL",
  },
  {
    i_c: "N",
    name: "Northern Province",
    c_c: "SL",
  },
  {
    i_c: "S",
    name: "Southern Province",
    c_c: "SL",
  },
  {
    i_c: "W",
    name: "Western Area",
    c_c: "SL",
  },
  {
    i_c: "01",
    name: "Acquaviva",
    c_c: "SM",
  },
  {
    i_c: "02",
    name: "Chiesanuova",
    c_c: "SM",
  },
  {
    i_c: "03",
    name: "Domagnano",
    c_c: "SM",
  },
  {
    i_c: "04",
    name: "Faetano",
    c_c: "SM",
  },
  {
    i_c: "05",
    name: "Fiorentino",
    c_c: "SM",
  },
  {
    i_c: "06",
    name: "Borgo Maggiore",
    c_c: "SM",
  },
  {
    i_c: "07",
    name: "San Marino",
    c_c: "SM",
  },
  {
    i_c: "08",
    name: "Montegiardino",
    c_c: "SM",
  },
  {
    i_c: "09",
    name: "Serravalle",
    c_c: "SM",
  },
  {
    i_c: "DB",
    name: "Diourbel Region",
    c_c: "SN",
  },
  {
    i_c: "DK",
    name: "Dakar",
    c_c: "SN",
  },
  {
    i_c: "FK",
    name: "Fatick",
    c_c: "SN",
  },
  {
    i_c: "KA",
    name: "Kaffrine",
    c_c: "SN",
  },
  {
    i_c: "KD",
    name: "Kolda",
    c_c: "SN",
  },
  {
    i_c: "KE",
    name: "Kédougou",
    c_c: "SN",
  },
  {
    i_c: "KL",
    name: "Kaolack",
    c_c: "SN",
  },
  {
    i_c: "LG",
    name: "Louga",
    c_c: "SN",
  },
  {
    i_c: "MT",
    name: "Matam",
    c_c: "SN",
  },
  {
    i_c: "SE",
    name: "Sédhiou",
    c_c: "SN",
  },
  {
    i_c: "SL",
    name: "Saint-Louis",
    c_c: "SN",
  },
  {
    i_c: "TC",
    name: "Tambacounda Region",
    c_c: "SN",
  },
  {
    i_c: "TH",
    name: "Thiès Region",
    c_c: "SN",
  },
  {
    i_c: "ZG",
    name: "Ziguinchor",
    c_c: "SN",
  },
  {
    i_c: "AW",
    name: "Awdal Region",
    c_c: "SO",
  },
  {
    i_c: "BK",
    name: "Bakool",
    c_c: "SO",
  },
  {
    i_c: "BN",
    name: "Banaadir",
    c_c: "SO",
  },
  {
    i_c: "BR",
    name: "Bari",
    c_c: "SO",
  },
  {
    i_c: "BY",
    name: "Bay",
    c_c: "SO",
  },
  {
    i_c: "GA",
    name: "Galguduud",
    c_c: "SO",
  },
  {
    i_c: "GE",
    name: "Gedo",
    c_c: "SO",
  },
  {
    i_c: "HI",
    name: "Hiran",
    c_c: "SO",
  },
  {
    i_c: "JD",
    name: "Middle Juba",
    c_c: "SO",
  },
  {
    i_c: "JH",
    name: "Lower Juba",
    c_c: "SO",
  },
  {
    i_c: "MU",
    name: "Mudug",
    c_c: "SO",
  },
  {
    i_c: "NU",
    name: "Nugal",
    c_c: "SO",
  },
  {
    i_c: "SA",
    name: "Sanaag Region",
    c_c: "SO",
  },
  {
    i_c: "SD",
    name: "Middle Shebelle",
    c_c: "SO",
  },
  {
    i_c: "SH",
    name: "Lower Shebelle",
    c_c: "SO",
  },
  {
    i_c: "TO",
    name: "Togdheer Region",
    c_c: "SO",
  },
  {
    i_c: "BR",
    name: "Brokopondo District",
    c_c: "SR",
  },
  {
    i_c: "CM",
    name: "Commewijne District",
    c_c: "SR",
  },
  {
    i_c: "CR",
    name: "Coronie District",
    c_c: "SR",
  },
  {
    i_c: "MA",
    name: "Marowijne District",
    c_c: "SR",
  },
  {
    i_c: "NI",
    name: "Nickerie District",
    c_c: "SR",
  },
  {
    i_c: "PM",
    name: "Paramaribo District",
    c_c: "SR",
  },
  {
    i_c: "PR",
    name: "Para District",
    c_c: "SR",
  },
  {
    i_c: "SA",
    name: "Saramacca District",
    c_c: "SR",
  },
  {
    i_c: "SI",
    name: "Sipaliwini District",
    c_c: "SR",
  },
  {
    i_c: "WA",
    name: "Wanica District",
    c_c: "SR",
  },
  {
    i_c: "BN",
    name: "Northern Bahr el Ghazal",
    c_c: "SS",
  },
  {
    i_c: "BW",
    name: "Western Bahr el Ghazal",
    c_c: "SS",
  },
  {
    i_c: "EC",
    name: "Central Equatoria",
    c_c: "SS",
  },
  {
    i_c: "EE",
    name: "Eastern Equatoria",
    c_c: "SS",
  },
  {
    i_c: "EW",
    name: "Western Equatoria",
    c_c: "SS",
  },
  {
    i_c: "JG",
    name: "Jonglei State",
    c_c: "SS",
  },
  {
    i_c: "LK",
    name: "Lakes",
    c_c: "SS",
  },
  {
    i_c: "NU",
    name: "Upper Nile",
    c_c: "SS",
  },
  {
    i_c: "UY",
    name: "Unity",
    c_c: "SS",
  },
  {
    i_c: "WR",
    name: "Warrap",
    c_c: "SS",
  },
  {
    i_c: "P",
    name: "Príncipe Province",
    c_c: "ST",
  },
  {
    i_c: "S",
    name: "São Tomé Province",
    c_c: "ST",
  },
  {
    i_c: "AH",
    name: "Ahuachapán Department",
    c_c: "SV",
  },
  {
    i_c: "CA",
    name: "Cabañas Department",
    c_c: "SV",
  },
  {
    i_c: "CH",
    name: "Chalatenango Department",
    c_c: "SV",
  },
  {
    i_c: "CU",
    name: "Cuscatlán Department",
    c_c: "SV",
  },
  {
    i_c: "LI",
    name: "La Libertad Department",
    c_c: "SV",
  },
  {
    i_c: "MO",
    name: "Morazán Department",
    c_c: "SV",
  },
  {
    i_c: "PA",
    name: "La Paz Department",
    c_c: "SV",
  },
  {
    i_c: "SA",
    name: "Santa Ana Department",
    c_c: "SV",
  },
  {
    i_c: "SM",
    name: "San Miguel Department",
    c_c: "SV",
  },
  {
    i_c: "SO",
    name: "Sonsonate Department",
    c_c: "SV",
  },
  {
    i_c: "SS",
    name: "San Salvador Department",
    c_c: "SV",
  },
  {
    i_c: "SV",
    name: "San Vicente Department",
    c_c: "SV",
  },
  {
    i_c: "UN",
    name: "La Unión Department",
    c_c: "SV",
  },
  {
    i_c: "US",
    name: "Usulután Department",
    c_c: "SV",
  },
  {
    i_c: "DI",
    name: "Damascus Governorate",
    c_c: "SY",
  },
  {
    i_c: "DR",
    name: "Daraa Governorate",
    c_c: "SY",
  },
  {
    i_c: "DY",
    name: "Deir ez-Zor Governorate",
    c_c: "SY",
  },
  {
    i_c: "HA",
    name: "Al-Hasakah Governorate",
    c_c: "SY",
  },
  {
    i_c: "HI",
    name: "Homs Governorate",
    c_c: "SY",
  },
  {
    i_c: "HL",
    name: "Aleppo Governorate",
    c_c: "SY",
  },
  {
    i_c: "HM",
    name: "Hama Governorate",
    c_c: "SY",
  },
  {
    i_c: "ID",
    name: "Idlib Governorate",
    c_c: "SY",
  },
  {
    i_c: "LA",
    name: "Latakia Governorate",
    c_c: "SY",
  },
  {
    i_c: "QU",
    name: "Quneitra Governorate",
    c_c: "SY",
  },
  {
    i_c: "RA",
    name: "Al-Raqqah Governorate",
    c_c: "SY",
  },
  {
    i_c: "RD",
    name: "Rif Dimashq Governorate",
    c_c: "SY",
  },
  {
    i_c: "SU",
    name: "As-Suwayda Governorate",
    c_c: "SY",
  },
  {
    i_c: "TA",
    name: "Tartus Governorate",
    c_c: "SY",
  },
  {
    i_c: "HH",
    name: "Hhohho District",
    c_c: "SZ",
  },
  {
    i_c: "LU",
    name: "Lubombo District",
    c_c: "SZ",
  },
  {
    i_c: "MA",
    name: "Manzini District",
    c_c: "SZ",
  },
  {
    i_c: "SH",
    name: "Shiselweni District",
    c_c: "SZ",
  },
  {
    i_c: "BA",
    name: "Batha Region",
    c_c: "TD",
  },
  {
    i_c: "BG",
    name: "Bahr el Gazel",
    c_c: "TD",
  },
  {
    i_c: "BO",
    name: "Borkou",
    c_c: "TD",
  },
  {
    i_c: "EE",
    name: "Ennedi-Est",
    c_c: "TD",
  },
  {
    i_c: "EN",
    name: "Ennedi Region",
    c_c: "TD",
  },
  {
    i_c: "EO",
    name: "Ennedi-Ouest",
    c_c: "TD",
  },
  {
    i_c: "GR",
    name: "Guéra Region",
    c_c: "TD",
  },
  {
    i_c: "HL",
    name: "Hadjer-Lamis",
    c_c: "TD",
  },
  {
    i_c: "KA",
    name: "Kanem Region",
    c_c: "TD",
  },
  {
    i_c: "LC",
    name: "Lac Region",
    c_c: "TD",
  },
  {
    i_c: "LO",
    name: "Logone Occidental Region",
    c_c: "TD",
  },
  {
    i_c: "LR",
    name: "Logone Oriental Region",
    c_c: "TD",
  },
  {
    i_c: "MA",
    name: "Mandoul Region",
    c_c: "TD",
  },
  {
    i_c: "MC",
    name: "Moyen-Chari Region",
    c_c: "TD",
  },
  {
    i_c: "ME",
    name: "Mayo-Kebbi Est Region",
    c_c: "TD",
  },
  {
    i_c: "MO",
    name: "Mayo-Kebbi Ouest Region",
    c_c: "TD",
  },
  {
    i_c: "ND",
    name: "N'Djamena",
    c_c: "TD",
  },
  {
    i_c: "OD",
    name: "Ouaddaï Region",
    c_c: "TD",
  },
  {
    i_c: "SA",
    name: "Salamat Region",
    c_c: "TD",
  },
  {
    i_c: "SI",
    name: "Sila Region",
    c_c: "TD",
  },
  {
    i_c: "TA",
    name: "Tandjilé Region",
    c_c: "TD",
  },
  {
    i_c: "TI",
    name: "Tibesti Region",
    c_c: "TD",
  },
  {
    i_c: "WF",
    name: "Wadi Fira Region",
    c_c: "TD",
  },
  {
    i_c: "C",
    name: "Centrale Region",
    c_c: "TG",
  },
  {
    i_c: "K",
    name: "Kara Region",
    c_c: "TG",
  },
  {
    i_c: "M",
    name: "Maritime",
    c_c: "TG",
  },
  {
    i_c: "P",
    name: "Plateaux Region",
    c_c: "TG",
  },
  {
    i_c: "S",
    name: "Savanes Region",
    c_c: "TG",
  },
  {
    i_c: "10",
    name: "Bangkok",
    c_c: "TH",
  },
  {
    i_c: "11",
    name: "Samut Prakan",
    c_c: "TH",
  },
  {
    i_c: "12",
    name: "Nonthaburi",
    c_c: "TH",
  },
  {
    i_c: "13",
    name: "Pathum Thani",
    c_c: "TH",
  },
  {
    i_c: "14",
    name: "Phra Nakhon Si Ayutthaya",
    c_c: "TH",
  },
  {
    i_c: "15",
    name: "Ang Thong",
    c_c: "TH",
  },
  {
    i_c: "16",
    name: "Lop Buri",
    c_c: "TH",
  },
  {
    i_c: "17",
    name: "Sing Buri",
    c_c: "TH",
  },
  {
    i_c: "18",
    name: "Chai Nat",
    c_c: "TH",
  },
  {
    i_c: "19",
    name: "Saraburi",
    c_c: "TH",
  },
  {
    i_c: "20",
    name: "Chon Buri",
    c_c: "TH",
  },
  {
    i_c: "21",
    name: "Rayong",
    c_c: "TH",
  },
  {
    i_c: "22",
    name: "Chanthaburi",
    c_c: "TH",
  },
  {
    i_c: "23",
    name: "Trat",
    c_c: "TH",
  },
  {
    i_c: "24",
    name: "Chachoengsao",
    c_c: "TH",
  },
  {
    i_c: "25",
    name: "Prachin Buri",
    c_c: "TH",
  },
  {
    i_c: "26",
    name: "Nakhon Nayok",
    c_c: "TH",
  },
  {
    i_c: "27",
    name: "Sa Kaeo",
    c_c: "TH",
  },
  {
    i_c: "30",
    name: "Nakhon Ratchasima",
    c_c: "TH",
  },
  {
    i_c: "31",
    name: "Buri Ram",
    c_c: "TH",
  },
  {
    i_c: "32",
    name: "Surin",
    c_c: "TH",
  },
  {
    i_c: "33",
    name: "Si Sa Ket",
    c_c: "TH",
  },
  {
    i_c: "34",
    name: "Ubon Ratchathani",
    c_c: "TH",
  },
  {
    i_c: "35",
    name: "Yasothon",
    c_c: "TH",
  },
  {
    i_c: "36",
    name: "Chaiyaphum",
    c_c: "TH",
  },
  {
    i_c: "37",
    name: "Amnat Charoen",
    c_c: "TH",
  },
  {
    i_c: "38",
    name: "Bueng Kan",
    c_c: "TH",
  },
  {
    i_c: "39",
    name: "Nong Bua Lam Phu",
    c_c: "TH",
  },
  {
    i_c: "40",
    name: "Khon Kaen",
    c_c: "TH",
  },
  {
    i_c: "41",
    name: "Udon Thani",
    c_c: "TH",
  },
  {
    i_c: "42",
    name: "Loei",
    c_c: "TH",
  },
  {
    i_c: "43",
    name: "Nong Khai",
    c_c: "TH",
  },
  {
    i_c: "44",
    name: "Maha Sarakham",
    c_c: "TH",
  },
  {
    i_c: "45",
    name: "Roi Et",
    c_c: "TH",
  },
  {
    i_c: "46",
    name: "Kalasin",
    c_c: "TH",
  },
  {
    i_c: "47",
    name: "Sakon Nakhon",
    c_c: "TH",
  },
  {
    i_c: "48",
    name: "Nakhon Phanom",
    c_c: "TH",
  },
  {
    i_c: "49",
    name: "Mukdahan",
    c_c: "TH",
  },
  {
    i_c: "50",
    name: "Chiang Mai",
    c_c: "TH",
  },
  {
    i_c: "51",
    name: "Lamphun",
    c_c: "TH",
  },
  {
    i_c: "52",
    name: "Lampang",
    c_c: "TH",
  },
  {
    i_c: "53",
    name: "Uttaradit",
    c_c: "TH",
  },
  {
    i_c: "54",
    name: "Phrae",
    c_c: "TH",
  },
  {
    i_c: "55",
    name: "Nan",
    c_c: "TH",
  },
  {
    i_c: "56",
    name: "Phayao",
    c_c: "TH",
  },
  {
    i_c: "57",
    name: "Chiang Rai",
    c_c: "TH",
  },
  {
    i_c: "58",
    name: "Mae Hong Son",
    c_c: "TH",
  },
  {
    i_c: "60",
    name: "Nakhon Sawan",
    c_c: "TH",
  },
  {
    i_c: "61",
    name: "Uthai Thani",
    c_c: "TH",
  },
  {
    i_c: "62",
    name: "Kamphaeng Phet",
    c_c: "TH",
  },
  {
    i_c: "63",
    name: "Tak",
    c_c: "TH",
  },
  {
    i_c: "64",
    name: "Sukhothai",
    c_c: "TH",
  },
  {
    i_c: "65",
    name: "Phitsanulok",
    c_c: "TH",
  },
  {
    i_c: "66",
    name: "Phichit",
    c_c: "TH",
  },
  {
    i_c: "67",
    name: "Phetchabun",
    c_c: "TH",
  },
  {
    i_c: "70",
    name: "Ratchaburi",
    c_c: "TH",
  },
  {
    i_c: "71",
    name: "Kanchanaburi",
    c_c: "TH",
  },
  {
    i_c: "72",
    name: "Suphan Buri",
    c_c: "TH",
  },
  {
    i_c: "73",
    name: "Nakhon Pathom",
    c_c: "TH",
  },
  {
    i_c: "74",
    name: "Samut Sakhon",
    c_c: "TH",
  },
  {
    i_c: "75",
    name: "Samut Songkhram",
    c_c: "TH",
  },
  {
    i_c: "76",
    name: "Phetchaburi",
    c_c: "TH",
  },
  {
    i_c: "77",
    name: "Prachuap Khiri Khan",
    c_c: "TH",
  },
  {
    i_c: "80",
    name: "Nakhon Si Thammarat",
    c_c: "TH",
  },
  {
    i_c: "81",
    name: "Krabi",
    c_c: "TH",
  },
  {
    i_c: "82",
    name: "Phangnga",
    c_c: "TH",
  },
  {
    i_c: "83",
    name: "Phuket",
    c_c: "TH",
  },
  {
    i_c: "84",
    name: "Surat Thani",
    c_c: "TH",
  },
  {
    i_c: "85",
    name: "Ranong",
    c_c: "TH",
  },
  {
    i_c: "86",
    name: "Chumphon",
    c_c: "TH",
  },
  {
    i_c: "90",
    name: "Songkhla",
    c_c: "TH",
  },
  {
    i_c: "91",
    name: "Satun",
    c_c: "TH",
  },
  {
    i_c: "92",
    name: "Trang",
    c_c: "TH",
  },
  {
    i_c: "93",
    name: "Phatthalung",
    c_c: "TH",
  },
  {
    i_c: "94",
    name: "Pattani",
    c_c: "TH",
  },
  {
    i_c: "95",
    name: "Yala",
    c_c: "TH",
  },
  {
    i_c: "96",
    name: "Narathiwat",
    c_c: "TH",
  },
  {
    i_c: "S",
    name: "Pattaya",
    c_c: "TH",
  },
  {
    i_c: "GB",
    name: "Gorno-Badakhshan Autonomous Province",
    c_c: "TJ",
  },
  {
    i_c: "KT",
    name: "Khatlon Province",
    c_c: "TJ",
  },
  {
    i_c: "RA",
    name: "districts of Republican Subordination",
    c_c: "TJ",
  },
  {
    i_c: "SU",
    name: "Sughd Province",
    c_c: "TJ",
  },
  {
    i_c: "AL",
    name: "Aileu municipality",
    c_c: "TL",
  },
  {
    i_c: "AN",
    name: "Ainaro Municipality",
    c_c: "TL",
  },
  {
    i_c: "BA",
    name: "Baucau Municipality",
    c_c: "TL",
  },
  {
    i_c: "BO",
    name: "Bobonaro Municipality",
    c_c: "TL",
  },
  {
    i_c: "CO",
    name: "Cova Lima Municipality",
    c_c: "TL",
  },
  {
    i_c: "DI",
    name: "Dili municipality",
    c_c: "TL",
  },
  {
    i_c: "ER",
    name: "Ermera District",
    c_c: "TL",
  },
  {
    i_c: "LA",
    name: "Lautém Municipality",
    c_c: "TL",
  },
  {
    i_c: "LI",
    name: "Liquiçá Municipality",
    c_c: "TL",
  },
  {
    i_c: "MF",
    name: "Manufahi Municipality",
    c_c: "TL",
  },
  {
    i_c: "MT",
    name: "Manatuto District",
    c_c: "TL",
  },
  {
    i_c: "VI",
    name: "Viqueque Municipality",
    c_c: "TL",
  },
  {
    i_c: "A",
    name: "Ahal Region",
    c_c: "TM",
  },
  {
    i_c: "B",
    name: "Balkan Region",
    c_c: "TM",
  },
  {
    i_c: "D",
    name: "Daşoguz Region",
    c_c: "TM",
  },
  {
    i_c: "L",
    name: "Lebap Region",
    c_c: "TM",
  },
  {
    i_c: "M",
    name: "Mary Region",
    c_c: "TM",
  },
  {
    i_c: "S",
    name: "Ashgabat",
    c_c: "TM",
  },
  {
    i_c: "11",
    name: "Tunis Governorate",
    c_c: "TN",
  },
  {
    i_c: "12",
    name: "Ariana Governorate",
    c_c: "TN",
  },
  {
    i_c: "13",
    name: "Ben Arous Governorate",
    c_c: "TN",
  },
  {
    i_c: "14",
    name: "Manouba Governorate",
    c_c: "TN",
  },
  {
    i_c: "22",
    name: "Zaghouan Governorate",
    c_c: "TN",
  },
  {
    i_c: "23",
    name: "Bizerte Governorate",
    c_c: "TN",
  },
  {
    i_c: "31",
    name: "Kassrine",
    c_c: "TN",
  },
  {
    i_c: "32",
    name: "Jendouba Governorate",
    c_c: "TN",
  },
  {
    i_c: "33",
    name: "Kef Governorate",
    c_c: "TN",
  },
  {
    i_c: "34",
    name: "Siliana Governorate",
    c_c: "TN",
  },
  {
    i_c: "41",
    name: "Kairouan Governorate",
    c_c: "TN",
  },
  {
    i_c: "42",
    name: "Kasserine Governorate",
    c_c: "TN",
  },
  {
    i_c: "43",
    name: "Sidi Bouzid Governorate",
    c_c: "TN",
  },
  {
    i_c: "51",
    name: "Sousse Governorate",
    c_c: "TN",
  },
  {
    i_c: "52",
    name: "Monastir Governorate",
    c_c: "TN",
  },
  {
    i_c: "53",
    name: "Mahdia Governorate",
    c_c: "TN",
  },
  {
    i_c: "61",
    name: "Sfax Governorate",
    c_c: "TN",
  },
  {
    i_c: "71",
    name: "Gafsa Governorate",
    c_c: "TN",
  },
  {
    i_c: "72",
    name: "Tozeur Governorate",
    c_c: "TN",
  },
  {
    i_c: "73",
    name: "Kebili Governorate",
    c_c: "TN",
  },
  {
    i_c: "81",
    name: "Gabès Governorate",
    c_c: "TN",
  },
  {
    i_c: "82",
    name: "Medenine Governorate",
    c_c: "TN",
  },
  {
    i_c: "83",
    name: "Tataouine Governorate",
    c_c: "TN",
  },
  {
    i_c: "01",
    name: "ʻEua",
    c_c: "TO",
  },
  {
    i_c: "02",
    name: "Haʻapai",
    c_c: "TO",
  },
  {
    i_c: "03",
    name: "Niuas",
    c_c: "TO",
  },
  {
    i_c: "04",
    name: "Tongatapu",
    c_c: "TO",
  },
  {
    i_c: "05",
    name: "Vavaʻu",
    c_c: "TO",
  },
  {
    i_c: "01",
    name: "Adana",
    c_c: "TR",
  },
  {
    i_c: "02",
    name: "Adıyaman",
    c_c: "TR",
  },
  {
    i_c: "03",
    name: "Afyonkarahisar",
    c_c: "TR",
  },
  {
    i_c: "04",
    name: "Ağrı",
    c_c: "TR",
  },
  {
    i_c: "05",
    name: "Amasya",
    c_c: "TR",
  },
  {
    i_c: "06",
    name: "Ankara",
    c_c: "TR",
  },
  {
    i_c: "07",
    name: "Antalya",
    c_c: "TR",
  },
  {
    i_c: "08",
    name: "Artvin",
    c_c: "TR",
  },
  {
    i_c: "09",
    name: "Aydın",
    c_c: "TR",
  },
  {
    i_c: "10",
    name: "Balıkesir",
    c_c: "TR",
  },
  {
    i_c: "11",
    name: "Bilecik",
    c_c: "TR",
  },
  {
    i_c: "12",
    name: "Bingöl",
    c_c: "TR",
  },
  {
    i_c: "13",
    name: "Bitlis",
    c_c: "TR",
  },
  {
    i_c: "14",
    name: "Bolu",
    c_c: "TR",
  },
  {
    i_c: "15",
    name: "Burdur",
    c_c: "TR",
  },
  {
    i_c: "16",
    name: "Bursa",
    c_c: "TR",
  },
  {
    i_c: "17",
    name: "Çanakkale",
    c_c: "TR",
  },
  {
    i_c: "18",
    name: "Çankırı",
    c_c: "TR",
  },
  {
    i_c: "19",
    name: "Çorum",
    c_c: "TR",
  },
  {
    i_c: "20",
    name: "Denizli",
    c_c: "TR",
  },
  {
    i_c: "21",
    name: "Diyarbakır",
    c_c: "TR",
  },
  {
    i_c: "22",
    name: "Edirne",
    c_c: "TR",
  },
  {
    i_c: "23",
    name: "Elazığ",
    c_c: "TR",
  },
  {
    i_c: "24",
    name: "Erzincan",
    c_c: "TR",
  },
  {
    i_c: "25",
    name: "Erzurum",
    c_c: "TR",
  },
  {
    i_c: "26",
    name: "Eskişehir",
    c_c: "TR",
  },
  {
    i_c: "27",
    name: "Gaziantep",
    c_c: "TR",
  },
  {
    i_c: "28",
    name: "Giresun",
    c_c: "TR",
  },
  {
    i_c: "29",
    name: "Gümüşhane",
    c_c: "TR",
  },
  {
    i_c: "30",
    name: "Hakkâri",
    c_c: "TR",
  },
  {
    i_c: "31",
    name: "Hatay",
    c_c: "TR",
  },
  {
    i_c: "32",
    name: "Isparta",
    c_c: "TR",
  },
  {
    i_c: "33",
    name: "Mersin",
    c_c: "TR",
  },
  {
    i_c: "34",
    name: "Istanbul",
    c_c: "TR",
  },
  {
    i_c: "35",
    name: "İzmir",
    c_c: "TR",
  },
  {
    i_c: "36",
    name: "Kars",
    c_c: "TR",
  },
  {
    i_c: "37",
    name: "Kastamonu",
    c_c: "TR",
  },
  {
    i_c: "38",
    name: "Kayseri",
    c_c: "TR",
  },
  {
    i_c: "39",
    name: "Kırklareli",
    c_c: "TR",
  },
  {
    i_c: "40",
    name: "Kırşehir",
    c_c: "TR",
  },
  {
    i_c: "41",
    name: "Kocaeli",
    c_c: "TR",
  },
  {
    i_c: "42",
    name: "Konya",
    c_c: "TR",
  },
  {
    i_c: "43",
    name: "Kütahya",
    c_c: "TR",
  },
  {
    i_c: "44",
    name: "Malatya",
    c_c: "TR",
  },
  {
    i_c: "45",
    name: "Manisa",
    c_c: "TR",
  },
  {
    i_c: "46",
    name: "Kahramanmaraş",
    c_c: "TR",
  },
  {
    i_c: "47",
    name: "Mardin",
    c_c: "TR",
  },
  {
    i_c: "48",
    name: "Muğla",
    c_c: "TR",
  },
  {
    i_c: "49",
    name: "Muş",
    c_c: "TR",
  },
  {
    i_c: "50",
    name: "Nevşehir",
    c_c: "TR",
  },
  {
    i_c: "51",
    name: "Niğde",
    c_c: "TR",
  },
  {
    i_c: "52",
    name: "Ordu",
    c_c: "TR",
  },
  {
    i_c: "53",
    name: "Rize",
    c_c: "TR",
  },
  {
    i_c: "54",
    name: "Sakarya",
    c_c: "TR",
  },
  {
    i_c: "55",
    name: "Samsun",
    c_c: "TR",
  },
  {
    i_c: "56",
    name: "Siirt",
    c_c: "TR",
  },
  {
    i_c: "57",
    name: "Sinop",
    c_c: "TR",
  },
  {
    i_c: "58",
    name: "Sivas",
    c_c: "TR",
  },
  {
    i_c: "59",
    name: "Tekirdağ",
    c_c: "TR",
  },
  {
    i_c: "60",
    name: "Tokat",
    c_c: "TR",
  },
  {
    i_c: "61",
    name: "Trabzon",
    c_c: "TR",
  },
  {
    i_c: "62",
    name: "Tunceli",
    c_c: "TR",
  },
  {
    i_c: "63",
    name: "Şanlıurfa",
    c_c: "TR",
  },
  {
    i_c: "64",
    name: "Uşak",
    c_c: "TR",
  },
  {
    i_c: "65",
    name: "Van",
    c_c: "TR",
  },
  {
    i_c: "66",
    name: "Yozgat",
    c_c: "TR",
  },
  {
    i_c: "67",
    name: "Zonguldak",
    c_c: "TR",
  },
  {
    i_c: "68",
    name: "Aksaray",
    c_c: "TR",
  },
  {
    i_c: "69",
    name: "Bayburt",
    c_c: "TR",
  },
  {
    i_c: "70",
    name: "Karaman",
    c_c: "TR",
  },
  {
    i_c: "71",
    name: "Kırıkkale",
    c_c: "TR",
  },
  {
    i_c: "72",
    name: "Batman",
    c_c: "TR",
  },
  {
    i_c: "73",
    name: "Şırnak",
    c_c: "TR",
  },
  {
    i_c: "74",
    name: "Bartın",
    c_c: "TR",
  },
  {
    i_c: "75",
    name: "Ardahan",
    c_c: "TR",
  },
  {
    i_c: "76",
    name: "Iğdır",
    c_c: "TR",
  },
  {
    i_c: "77",
    name: "Yalova",
    c_c: "TR",
  },
  {
    i_c: "78",
    name: "Karabük",
    c_c: "TR",
  },
  {
    i_c: "79",
    name: "Kilis",
    c_c: "TR",
  },
  {
    i_c: "80",
    name: "Osmaniye",
    c_c: "TR",
  },
  {
    i_c: "81",
    name: "Düzce",
    c_c: "TR",
  },
  {
    i_c: "ARI",
    name: "Arima",
    c_c: "TT",
  },
  {
    i_c: "CHA",
    name: "Chaguanas",
    c_c: "TT",
  },
  {
    i_c: "CTT",
    name: "Couva-Tabaquite-Talparo Regional Corporation",
    c_c: "TT",
  },
  {
    i_c: "DMN",
    name: "Diego Martin Regional Corporation",
    c_c: "TT",
  },
  {
    i_c: "ETO",
    name: "Eastern Tobago",
    c_c: "TT",
  },
  {
    i_c: "MRC",
    name: "Rio Claro-Mayaro Regional Corporation",
    c_c: "TT",
  },
  {
    i_c: "PED",
    name: "Penal-Debe Regional Corporation",
    c_c: "TT",
  },
  {
    i_c: "POS",
    name: "Port of Spain",
    c_c: "TT",
  },
  {
    i_c: "PRT",
    name: "Princes Town Regional Corporation",
    c_c: "TT",
  },
  {
    i_c: "PTF",
    name: "Point Fortin",
    c_c: "TT",
  },
  {
    i_c: "SFO",
    name: "San Fernando",
    c_c: "TT",
  },
  {
    i_c: "SGE",
    name: "Sangre Grande Regional Corporation",
    c_c: "TT",
  },
  {
    i_c: "SIP",
    name: "Siparia Regional Corporation",
    c_c: "TT",
  },
  {
    i_c: "SJL",
    name: "San Juan-Laventille Regional Corporation",
    c_c: "TT",
  },
  {
    i_c: "TUP",
    name: "Tunapuna-Piarco Regional Corporation",
    c_c: "TT",
  },
  {
    i_c: "WTO",
    name: "Western Tobago",
    c_c: "TT",
  },
  {
    i_c: "FUN",
    name: "Funafuti",
    c_c: "TV",
  },
  {
    i_c: "NIT",
    name: "Niutao Island Council",
    c_c: "TV",
  },
  {
    i_c: "NKF",
    name: "Nukufetau",
    c_c: "TV",
  },
  {
    i_c: "NKL",
    name: "Nukulaelae",
    c_c: "TV",
  },
  {
    i_c: "NMA",
    name: "Nanumea",
    c_c: "TV",
  },
  {
    i_c: "NMG",
    name: "Nanumanga",
    c_c: "TV",
  },
  {
    i_c: "NUI",
    name: "Nui",
    c_c: "TV",
  },
  {
    i_c: "VAI",
    name: "Vaitupu",
    c_c: "TV",
  },
  {
    i_c: "CHA",
    name: "Changhua",
    c_c: "TW",
  },
  {
    i_c: "CYI",
    name: "Chiayi",
    c_c: "TW",
  },
  {
    i_c: "CYQ",
    name: "Chiayi",
    c_c: "TW",
  },
  {
    i_c: "HSQ",
    name: "Hsinchu",
    c_c: "TW",
  },
  {
    i_c: "HSZ",
    name: "Hsinchu",
    c_c: "TW",
  },
  {
    i_c: "HUA",
    name: "Hualien",
    c_c: "TW",
  },
  {
    i_c: "ILA",
    name: "Yilan",
    c_c: "TW",
  },
  {
    i_c: "KEE",
    name: "Keelung",
    c_c: "TW",
  },
  {
    i_c: "KHH",
    name: "Kaohsiung",
    c_c: "TW",
  },
  {
    i_c: "KIN",
    name: "Kinmen",
    c_c: "TW",
  },
  {
    i_c: "LIE",
    name: "Lienchiang",
    c_c: "TW",
  },
  {
    i_c: "MIA",
    name: "Miaoli",
    c_c: "TW",
  },
  {
    i_c: "NAN",
    name: "Nantou",
    c_c: "TW",
  },
  {
    i_c: "NWT",
    name: "New Taipei",
    c_c: "TW",
  },
  {
    i_c: "PEN",
    name: "Penghu",
    c_c: "TW",
  },
  {
    i_c: "PIF",
    name: "Pingtung",
    c_c: "TW",
  },
  {
    i_c: "TAO",
    name: "Taoyuan",
    c_c: "TW",
  },
  {
    i_c: "TNN",
    name: "Tainan",
    c_c: "TW",
  },
  {
    i_c: "TPE",
    name: "Taipei",
    c_c: "TW",
  },
  {
    i_c: "TTT",
    name: "Taitung",
    c_c: "TW",
  },
  {
    i_c: "TXG",
    name: "Taichung",
    c_c: "TW",
  },
  {
    i_c: "YUN",
    name: "Yunlin",
    c_c: "TW",
  },
  {
    i_c: "01",
    name: "Arusha",
    c_c: "TZ",
  },
  {
    i_c: "02",
    name: "Dar es Salaam",
    c_c: "TZ",
  },
  {
    i_c: "03",
    name: "Dodoma",
    c_c: "TZ",
  },
  {
    i_c: "04",
    name: "Iringa",
    c_c: "TZ",
  },
  {
    i_c: "05",
    name: "Kagera",
    c_c: "TZ",
  },
  {
    i_c: "06",
    name: "Pemba North",
    c_c: "TZ",
  },
  {
    i_c: "07",
    name: "Zanzibar North",
    c_c: "TZ",
  },
  {
    i_c: "08",
    name: "Kigoma",
    c_c: "TZ",
  },
  {
    i_c: "09",
    name: "Kilimanjaro",
    c_c: "TZ",
  },
  {
    i_c: "10",
    name: "Pemba South",
    c_c: "TZ",
  },
  {
    i_c: "11",
    name: "Zanzibar South",
    c_c: "TZ",
  },
  {
    i_c: "12",
    name: "Lindi",
    c_c: "TZ",
  },
  {
    i_c: "13",
    name: "Mara",
    c_c: "TZ",
  },
  {
    i_c: "14",
    name: "Mbeya",
    c_c: "TZ",
  },
  {
    i_c: "15",
    name: "Zanzibar West",
    c_c: "TZ",
  },
  {
    i_c: "16",
    name: "Morogoro",
    c_c: "TZ",
  },
  {
    i_c: "17",
    name: "Mtwara",
    c_c: "TZ",
  },
  {
    i_c: "18",
    name: "Mwanza",
    c_c: "TZ",
  },
  {
    i_c: "19",
    name: "Pwani",
    c_c: "TZ",
  },
  {
    i_c: "20",
    name: "Rukwa",
    c_c: "TZ",
  },
  {
    i_c: "21",
    name: "Ruvuma",
    c_c: "TZ",
  },
  {
    i_c: "22",
    name: "Shinyanga",
    c_c: "TZ",
  },
  {
    i_c: "23",
    name: "Singida",
    c_c: "TZ",
  },
  {
    i_c: "24",
    name: "Tabora",
    c_c: "TZ",
  },
  {
    i_c: "25",
    name: "Tanga",
    c_c: "TZ",
  },
  {
    i_c: "26",
    name: "Manyara",
    c_c: "TZ",
  },
  {
    i_c: "27",
    name: "Geita",
    c_c: "TZ",
  },
  {
    i_c: "28",
    name: "Katavi",
    c_c: "TZ",
  },
  {
    i_c: "29",
    name: "Njombe",
    c_c: "TZ",
  },
  {
    i_c: "30",
    name: "Simiyu",
    c_c: "TZ",
  },
  {
    i_c: "31",
    name: "Songwe",
    c_c: "TZ",
  },
  {
    i_c: "05",
    name: "Vinnytska oblast",
    c_c: "UA",
  },
  {
    i_c: "07",
    name: "Volynska oblast",
    c_c: "UA",
  },
  {
    i_c: "09",
    name: "Luhanska oblast",
    c_c: "UA",
  },
  {
    i_c: "12",
    name: "Dnipropetrovska oblast",
    c_c: "UA",
  },
  {
    i_c: "14",
    name: "Donetska oblast",
    c_c: "UA",
  },
  {
    i_c: "18",
    name: "Zhytomyrska oblast",
    c_c: "UA",
  },
  {
    i_c: "21",
    name: "Zakarpatska Oblast",
    c_c: "UA",
  },
  {
    i_c: "23",
    name: "Zaporizka oblast",
    c_c: "UA",
  },
  {
    i_c: "26",
    name: "Ivano-Frankivska oblast",
    c_c: "UA",
  },
  {
    i_c: "30",
    name: "Kyiv",
    c_c: "UA",
  },
  {
    i_c: "32",
    name: "Kyivska oblast",
    c_c: "UA",
  },
  {
    i_c: "35",
    name: "Kirovohradska oblast",
    c_c: "UA",
  },
  {
    i_c: "43",
    name: "Autonomous Republic of Crimea",
    c_c: "UA",
  },
  {
    i_c: "46",
    name: "Lvivska oblast",
    c_c: "UA",
  },
  {
    i_c: "48",
    name: "Mykolaivska oblast",
    c_c: "UA",
  },
  {
    i_c: "51",
    name: "Odeska oblast",
    c_c: "UA",
  },
  {
    i_c: "53",
    name: "Poltavska oblast",
    c_c: "UA",
  },
  {
    i_c: "56",
    name: "Rivnenska oblast",
    c_c: "UA",
  },
  {
    i_c: "59",
    name: "Sumska oblast",
    c_c: "UA",
  },
  {
    i_c: "61",
    name: "Ternopilska oblast",
    c_c: "UA",
  },
  {
    i_c: "63",
    name: "Kharkivska oblast",
    c_c: "UA",
  },
  {
    i_c: "65",
    name: "Khersonska oblast",
    c_c: "UA",
  },
  {
    i_c: "68",
    name: "Khmelnytska oblast",
    c_c: "UA",
  },
  {
    i_c: "71",
    name: "Cherkaska oblast",
    c_c: "UA",
  },
  {
    i_c: "74",
    name: "Chernihivska oblast",
    c_c: "UA",
  },
  {
    i_c: "77",
    name: "Chernivetska oblast",
    c_c: "UA",
  },
  {
    i_c: "101",
    name: "Kalangala District",
    c_c: "UG",
  },
  {
    i_c: "102",
    name: "Kampala District",
    c_c: "UG",
  },
  {
    i_c: "103",
    name: "Kiboga District",
    c_c: "UG",
  },
  {
    i_c: "104",
    name: "Luwero District",
    c_c: "UG",
  },
  {
    i_c: "105",
    name: "Masaka District",
    c_c: "UG",
  },
  {
    i_c: "106",
    name: "Mpigi District",
    c_c: "UG",
  },
  {
    i_c: "107",
    name: "Mubende District",
    c_c: "UG",
  },
  {
    i_c: "108",
    name: "Mukono District",
    c_c: "UG",
  },
  {
    i_c: "109",
    name: "Nakasongola District",
    c_c: "UG",
  },
  {
    i_c: "110",
    name: "Rakai District",
    c_c: "UG",
  },
  {
    i_c: "111",
    name: "Sembabule District",
    c_c: "UG",
  },
  {
    i_c: "112",
    name: "Kayunga District",
    c_c: "UG",
  },
  {
    i_c: "113",
    name: "Wakiso District",
    c_c: "UG",
  },
  {
    i_c: "114",
    name: "Lyantonde District",
    c_c: "UG",
  },
  {
    i_c: "115",
    name: "Mityana District",
    c_c: "UG",
  },
  {
    i_c: "116",
    name: "Nakaseke District",
    c_c: "UG",
  },
  {
    i_c: "117",
    name: "Buikwe District",
    c_c: "UG",
  },
  {
    i_c: "118",
    name: "Bukomansimbi District",
    c_c: "UG",
  },
  {
    i_c: "119",
    name: "Butambala District",
    c_c: "UG",
  },
  {
    i_c: "120",
    name: "Buvuma District",
    c_c: "UG",
  },
  {
    i_c: "121",
    name: "Gomba District",
    c_c: "UG",
  },
  {
    i_c: "122",
    name: "Kalungu District",
    c_c: "UG",
  },
  {
    i_c: "123",
    name: "Kyankwanzi District",
    c_c: "UG",
  },
  {
    i_c: "124",
    name: "Lwengo District",
    c_c: "UG",
  },
  {
    i_c: "125",
    name: "Kyotera District",
    c_c: "UG",
  },
  {
    i_c: "201",
    name: "Bugiri District",
    c_c: "UG",
  },
  {
    i_c: "202",
    name: "Busia District",
    c_c: "UG",
  },
  {
    i_c: "203",
    name: "Iganga District",
    c_c: "UG",
  },
  {
    i_c: "204",
    name: "Jinja District",
    c_c: "UG",
  },
  {
    i_c: "205",
    name: "Kamuli District",
    c_c: "UG",
  },
  {
    i_c: "206",
    name: "Kapchorwa District",
    c_c: "UG",
  },
  {
    i_c: "207",
    name: "Katakwi District",
    c_c: "UG",
  },
  {
    i_c: "208",
    name: "Kumi District",
    c_c: "UG",
  },
  {
    i_c: "209",
    name: "Mbale District",
    c_c: "UG",
  },
  {
    i_c: "210",
    name: "Pallisa District",
    c_c: "UG",
  },
  {
    i_c: "211",
    name: "Soroti District",
    c_c: "UG",
  },
  {
    i_c: "212",
    name: "Tororo District",
    c_c: "UG",
  },
  {
    i_c: "213",
    name: "Kaberamaido District",
    c_c: "UG",
  },
  {
    i_c: "214",
    name: "Mayuge District",
    c_c: "UG",
  },
  {
    i_c: "215",
    name: "Sironko District",
    c_c: "UG",
  },
  {
    i_c: "216",
    name: "Amuria District",
    c_c: "UG",
  },
  {
    i_c: "217",
    name: "Budaka District",
    c_c: "UG",
  },
  {
    i_c: "218",
    name: "Bududa District",
    c_c: "UG",
  },
  {
    i_c: "219",
    name: "Bukedea District",
    c_c: "UG",
  },
  {
    i_c: "220",
    name: "Bukwo District",
    c_c: "UG",
  },
  {
    i_c: "221",
    name: "Butaleja District",
    c_c: "UG",
  },
  {
    i_c: "222",
    name: "Kaliro District",
    c_c: "UG",
  },
  {
    i_c: "223",
    name: "Manafwa District",
    c_c: "UG",
  },
  {
    i_c: "224",
    name: "Namutumba District",
    c_c: "UG",
  },
  {
    i_c: "225",
    name: "Bulambuli District",
    c_c: "UG",
  },
  {
    i_c: "226",
    name: "Buyende District",
    c_c: "UG",
  },
  {
    i_c: "227",
    name: "Kibuku District",
    c_c: "UG",
  },
  {
    i_c: "228",
    name: "Kween District",
    c_c: "UG",
  },
  {
    i_c: "229",
    name: "Luuka District",
    c_c: "UG",
  },
  {
    i_c: "230",
    name: "Namayingo District",
    c_c: "UG",
  },
  {
    i_c: "231",
    name: "Ngora District",
    c_c: "UG",
  },
  {
    i_c: "232",
    name: "Serere District",
    c_c: "UG",
  },
  {
    i_c: "233",
    name: "Butebo District",
    c_c: "UG",
  },
  {
    i_c: "234",
    name: "Namisindwa District",
    c_c: "UG",
  },
  {
    i_c: "301",
    name: "Adjumani District",
    c_c: "UG",
  },
  {
    i_c: "302",
    name: "Apac District",
    c_c: "UG",
  },
  {
    i_c: "303",
    name: "Arua District",
    c_c: "UG",
  },
  {
    i_c: "304",
    name: "Gulu District",
    c_c: "UG",
  },
  {
    i_c: "305",
    name: "Kitgum District",
    c_c: "UG",
  },
  {
    i_c: "306",
    name: "Kotido District",
    c_c: "UG",
  },
  {
    i_c: "307",
    name: "Lira District",
    c_c: "UG",
  },
  {
    i_c: "308",
    name: "Moroto District",
    c_c: "UG",
  },
  {
    i_c: "309",
    name: "Moyo District",
    c_c: "UG",
  },
  {
    i_c: "310",
    name: "Nebbi District",
    c_c: "UG",
  },
  {
    i_c: "311",
    name: "Nakapiripirit District",
    c_c: "UG",
  },
  {
    i_c: "312",
    name: "Pader District",
    c_c: "UG",
  },
  {
    i_c: "313",
    name: "Yumbe District",
    c_c: "UG",
  },
  {
    i_c: "314",
    name: "Abim District",
    c_c: "UG",
  },
  {
    i_c: "315",
    name: "Amolatar District",
    c_c: "UG",
  },
  {
    i_c: "316",
    name: "Amuru District",
    c_c: "UG",
  },
  {
    i_c: "317",
    name: "Dokolo District",
    c_c: "UG",
  },
  {
    i_c: "318",
    name: "Kaabong District",
    c_c: "UG",
  },
  {
    i_c: "319",
    name: "Koboko District",
    c_c: "UG",
  },
  {
    i_c: "320",
    name: "Maracha District",
    c_c: "UG",
  },
  {
    i_c: "321",
    name: "Oyam District",
    c_c: "UG",
  },
  {
    i_c: "322",
    name: "Agago District",
    c_c: "UG",
  },
  {
    i_c: "323",
    name: "Alebtong District",
    c_c: "UG",
  },
  {
    i_c: "324",
    name: "Amudat District",
    c_c: "UG",
  },
  {
    i_c: "325",
    name: "Kole District",
    c_c: "UG",
  },
  {
    i_c: "326",
    name: "Lamwo District",
    c_c: "UG",
  },
  {
    i_c: "327",
    name: "Napak District",
    c_c: "UG",
  },
  {
    i_c: "328",
    name: "Nwoya District",
    c_c: "UG",
  },
  {
    i_c: "329",
    name: "Otuke District",
    c_c: "UG",
  },
  {
    i_c: "330",
    name: "Zombo District",
    c_c: "UG",
  },
  {
    i_c: "331",
    name: "Omoro District",
    c_c: "UG",
  },
  {
    i_c: "332",
    name: "Pakwach District",
    c_c: "UG",
  },
  {
    i_c: "401",
    name: "Bundibugyo District",
    c_c: "UG",
  },
  {
    i_c: "402",
    name: "Bushenyi District",
    c_c: "UG",
  },
  {
    i_c: "404",
    name: "Kabale District",
    c_c: "UG",
  },
  {
    i_c: "405",
    name: "Kabarole District",
    c_c: "UG",
  },
  {
    i_c: "406",
    name: "Kasese District",
    c_c: "UG",
  },
  {
    i_c: "407",
    name: "Kibaale District",
    c_c: "UG",
  },
  {
    i_c: "408",
    name: "Kisoro District",
    c_c: "UG",
  },
  {
    i_c: "409",
    name: "Masindi District",
    c_c: "UG",
  },
  {
    i_c: "410",
    name: "Mbarara District",
    c_c: "UG",
  },
  {
    i_c: "411",
    name: "Ntungamo District",
    c_c: "UG",
  },
  {
    i_c: "412",
    name: "Rukungiri District",
    c_c: "UG",
  },
  {
    i_c: "413",
    name: "Kamwenge District",
    c_c: "UG",
  },
  {
    i_c: "414",
    name: "Kanungu District",
    c_c: "UG",
  },
  {
    i_c: "415",
    name: "Kyenjojo District",
    c_c: "UG",
  },
  {
    i_c: "416",
    name: "Buliisa District",
    c_c: "UG",
  },
  {
    i_c: "417",
    name: "Ibanda District",
    c_c: "UG",
  },
  {
    i_c: "418",
    name: "Isingiro District",
    c_c: "UG",
  },
  {
    i_c: "419",
    name: "Kiruhura District",
    c_c: "UG",
  },
  {
    i_c: "420",
    name: "Buhweju District",
    c_c: "UG",
  },
  {
    i_c: "421",
    name: "Kiryandongo District",
    c_c: "UG",
  },
  {
    i_c: "422",
    name: "Kyegegwa District",
    c_c: "UG",
  },
  {
    i_c: "423",
    name: "Mitooma District",
    c_c: "UG",
  },
  {
    i_c: "424",
    name: "Ntoroko District",
    c_c: "UG",
  },
  {
    i_c: "425",
    name: "Rubirizi District",
    c_c: "UG",
  },
  {
    i_c: "426",
    name: "Sheema District",
    c_c: "UG",
  },
  {
    i_c: "427",
    name: "Kagadi District",
    c_c: "UG",
  },
  {
    i_c: "428",
    name: "Kakumiro District",
    c_c: "UG",
  },
  {
    i_c: "429",
    name: "Rubanda District",
    c_c: "UG",
  },
  {
    i_c: "430",
    name: "Bunyangabu District",
    c_c: "UG",
  },
  {
    i_c: "431",
    name: "Rukiga District",
    c_c: "UG",
  },
  {
    i_c: "C",
    name: "Central Region",
    c_c: "UG",
  },
  {
    i_c: "E",
    name: "Eastern Region",
    c_c: "UG",
  },
  {
    i_c: "N",
    name: "Northern Region",
    c_c: "UG",
  },
  {
    i_c: "W",
    name: "Western Region",
    c_c: "UG",
  },
  {
    i_c: "AK",
    name: "Alaska",
    c_c: "US",
  },
  {
    i_c: "AL",
    name: "Alabama",
    c_c: "US",
  },
  {
    i_c: "AR",
    name: "Arkansas",
    c_c: "US",
  },
  {
    i_c: "AS",
    name: "American Samoa",
    c_c: "US",
  },
  {
    i_c: "AZ",
    name: "Arizona",
    c_c: "US",
  },
  {
    i_c: "CA",
    name: "California",
    c_c: "US",
  },
  {
    i_c: "CO",
    name: "Colorado",
    c_c: "US",
  },
  {
    i_c: "CT",
    name: "Connecticut",
    c_c: "US",
  },
  {
    i_c: "DC",
    name: "District of Columbia",
    c_c: "US",
  },
  {
    i_c: "DE",
    name: "Delaware",
    c_c: "US",
  },
  {
    i_c: "FL",
    name: "Florida",
    c_c: "US",
  },
  {
    i_c: "GA",
    name: "Georgia",
    c_c: "US",
  },
  {
    i_c: "GU",
    name: "Guam",
    c_c: "US",
  },
  {
    i_c: "HI",
    name: "Hawaii",
    c_c: "US",
  },
  {
    i_c: "IA",
    name: "Iowa",
    c_c: "US",
  },
  {
    i_c: "ID",
    name: "Idaho",
    c_c: "US",
  },
  {
    i_c: "IL",
    name: "Illinois",
    c_c: "US",
  },
  {
    i_c: "IN",
    name: "Indiana",
    c_c: "US",
  },
  {
    i_c: "KS",
    name: "Kansas",
    c_c: "US",
  },
  {
    i_c: "KY",
    name: "Kentucky",
    c_c: "US",
  },
  {
    i_c: "LA",
    name: "Louisiana",
    c_c: "US",
  },
  {
    i_c: "MA",
    name: "Massachusetts",
    c_c: "US",
  },
  {
    i_c: "MD",
    name: "Maryland",
    c_c: "US",
  },
  {
    i_c: "ME",
    name: "Maine",
    c_c: "US",
  },
  {
    i_c: "MI",
    name: "Michigan",
    c_c: "US",
  },
  {
    i_c: "MN",
    name: "Minnesota",
    c_c: "US",
  },
  {
    i_c: "MO",
    name: "Missouri",
    c_c: "US",
  },
  {
    i_c: "MP",
    name: "Northern Mariana Islands",
    c_c: "US",
  },
  {
    i_c: "MS",
    name: "Mississippi",
    c_c: "US",
  },
  {
    i_c: "MT",
    name: "Montana",
    c_c: "US",
  },
  {
    i_c: "NC",
    name: "North Carolina",
    c_c: "US",
  },
  {
    i_c: "ND",
    name: "North Dakota",
    c_c: "US",
  },
  {
    i_c: "NE",
    name: "Nebraska",
    c_c: "US",
  },
  {
    i_c: "NH",
    name: "New Hampshire",
    c_c: "US",
  },
  {
    i_c: "NJ",
    name: "New Jersey",
    c_c: "US",
  },
  {
    i_c: "NM",
    name: "New Mexico",
    c_c: "US",
  },
  {
    i_c: "NV",
    name: "Nevada",
    c_c: "US",
  },
  {
    i_c: "NY",
    name: "New York",
    c_c: "US",
  },
  {
    i_c: "OH",
    name: "Ohio",
    c_c: "US",
  },
  {
    i_c: "OK",
    name: "Oklahoma",
    c_c: "US",
  },
  {
    i_c: "OR",
    name: "Oregon",
    c_c: "US",
  },
  {
    i_c: "PA",
    name: "Pennsylvania",
    c_c: "US",
  },
  {
    i_c: "PR",
    name: "Puerto Rico",
    c_c: "US",
  },
  {
    i_c: "RI",
    name: "Rhode Island",
    c_c: "US",
  },
  {
    i_c: "SC",
    name: "South Carolina",
    c_c: "US",
  },
  {
    i_c: "SD",
    name: "South Dakota",
    c_c: "US",
  },
  {
    i_c: "TN",
    name: "Tennessee",
    c_c: "US",
  },
  {
    i_c: "TX",
    name: "Texas",
    c_c: "US",
  },
  {
    i_c: "UM",
    name: "United States Minor Outlying Islands",
    c_c: "US",
  },
  {
    i_c: "UM-67",
    name: "Johnston Atoll",
    c_c: "US",
  },
  {
    i_c: "UM-71",
    name: "Midway Atoll",
    c_c: "US",
  },
  {
    i_c: "UM-76",
    name: "Navassa Island",
    c_c: "US",
  },
  {
    i_c: "UM-79",
    name: "Wake Island",
    c_c: "US",
  },
  {
    i_c: "UM-81",
    name: "Baker Island",
    c_c: "US",
  },
  {
    i_c: "UM-84",
    name: "Howland Island",
    c_c: "US",
  },
  {
    i_c: "UM-86",
    name: "Jarvis Island",
    c_c: "US",
  },
  {
    i_c: "UM-89",
    name: "Kingman Reef",
    c_c: "US",
  },
  {
    i_c: "UM-95",
    name: "Palmyra Atoll",
    c_c: "US",
  },
  {
    i_c: "UT",
    name: "Utah",
    c_c: "US",
  },
  {
    i_c: "VA",
    name: "Virginia",
    c_c: "US",
  },
  {
    i_c: "VI",
    name: "United States Virgin Islands",
    c_c: "US",
  },
  {
    i_c: "VT",
    name: "Vermont",
    c_c: "US",
  },
  {
    i_c: "WA",
    name: "Washington",
    c_c: "US",
  },
  {
    i_c: "WI",
    name: "Wisconsin",
    c_c: "US",
  },
  {
    i_c: "WV",
    name: "West Virginia",
    c_c: "US",
  },
  {
    i_c: "WY",
    name: "Wyoming",
    c_c: "US",
  },
  {
    i_c: "AR",
    name: "Artigas Department",
    c_c: "UY",
  },
  {
    i_c: "CA",
    name: "Canelones Department",
    c_c: "UY",
  },
  {
    i_c: "CL",
    name: "Cerro Largo Department",
    c_c: "UY",
  },
  {
    i_c: "CO",
    name: "Colonia Department",
    c_c: "UY",
  },
  {
    i_c: "DU",
    name: "Durazno Department",
    c_c: "UY",
  },
  {
    i_c: "FD",
    name: "Florida Department",
    c_c: "UY",
  },
  {
    i_c: "FS",
    name: "Flores Department",
    c_c: "UY",
  },
  {
    i_c: "LA",
    name: "Lavalleja Department",
    c_c: "UY",
  },
  {
    i_c: "MA",
    name: "Maldonado Department",
    c_c: "UY",
  },
  {
    i_c: "MO",
    name: "Montevideo Department",
    c_c: "UY",
  },
  {
    i_c: "PA",
    name: "Paysandú Department",
    c_c: "UY",
  },
  {
    i_c: "RN",
    name: "Río Negro Department",
    c_c: "UY",
  },
  {
    i_c: "RO",
    name: "Rocha Department",
    c_c: "UY",
  },
  {
    i_c: "RV",
    name: "Rivera Department",
    c_c: "UY",
  },
  {
    i_c: "SA",
    name: "Salto Department",
    c_c: "UY",
  },
  {
    i_c: "SJ",
    name: "San José Department",
    c_c: "UY",
  },
  {
    i_c: "SO",
    name: "Soriano Department",
    c_c: "UY",
  },
  {
    i_c: "TA",
    name: "Tacuarembó Department",
    c_c: "UY",
  },
  {
    i_c: "TT",
    name: "Treinta y Tres Department",
    c_c: "UY",
  },
  {
    i_c: "AN",
    name: "Andijan Region",
    c_c: "UZ",
  },
  {
    i_c: "BU",
    name: "Bukhara Region",
    c_c: "UZ",
  },
  {
    i_c: "FA",
    name: "Fergana Region",
    c_c: "UZ",
  },
  {
    i_c: "JI",
    name: "Jizzakh Region",
    c_c: "UZ",
  },
  {
    i_c: "NG",
    name: "Namangan Region",
    c_c: "UZ",
  },
  {
    i_c: "NW",
    name: "Navoiy Region",
    c_c: "UZ",
  },
  {
    i_c: "QA",
    name: "Qashqadaryo Region",
    c_c: "UZ",
  },
  {
    i_c: "QR",
    name: "Karakalpakstan",
    c_c: "UZ",
  },
  {
    i_c: "SA",
    name: "Samarqand Region",
    c_c: "UZ",
  },
  {
    i_c: "SI",
    name: "Sirdaryo Region",
    c_c: "UZ",
  },
  {
    i_c: "SU",
    name: "Surxondaryo Region",
    c_c: "UZ",
  },
  {
    i_c: "TK",
    name: "Tashkent",
    c_c: "UZ",
  },
  {
    i_c: "TO",
    name: "Tashkent Region",
    c_c: "UZ",
  },
  {
    i_c: "XO",
    name: "Xorazm Region",
    c_c: "UZ",
  },
  {
    i_c: "01",
    name: "Charlotte Parish",
    c_c: "VC",
  },
  {
    i_c: "02",
    name: "Saint Andrew Parish",
    c_c: "VC",
  },
  {
    i_c: "03",
    name: "Saint David Parish",
    c_c: "VC",
  },
  {
    i_c: "04",
    name: "Saint George Parish",
    c_c: "VC",
  },
  {
    i_c: "05",
    name: "Saint Patrick Parish",
    c_c: "VC",
  },
  {
    i_c: "06",
    name: "Grenadines Parish",
    c_c: "VC",
  },
  {
    i_c: "A",
    name: "Distrito Capital",
    c_c: "VE",
  },
  {
    i_c: "B",
    name: "Anzoátegui",
    c_c: "VE",
  },
  {
    i_c: "C",
    name: "Apure",
    c_c: "VE",
  },
  {
    i_c: "D",
    name: "Aragua",
    c_c: "VE",
  },
  {
    i_c: "E",
    name: "Barinas",
    c_c: "VE",
  },
  {
    i_c: "F",
    name: "Bolívar",
    c_c: "VE",
  },
  {
    i_c: "G",
    name: "Carabobo",
    c_c: "VE",
  },
  {
    i_c: "H",
    name: "Cojedes",
    c_c: "VE",
  },
  {
    i_c: "I",
    name: "Falcón",
    c_c: "VE",
  },
  {
    i_c: "J",
    name: "Guárico",
    c_c: "VE",
  },
  {
    i_c: "K",
    name: "Lara",
    c_c: "VE",
  },
  {
    i_c: "L",
    name: "Mérida",
    c_c: "VE",
  },
  {
    i_c: "M",
    name: "Miranda",
    c_c: "VE",
  },
  {
    i_c: "N",
    name: "Monagas",
    c_c: "VE",
  },
  {
    i_c: "O",
    name: "Nueva Esparta",
    c_c: "VE",
  },
  {
    i_c: "P",
    name: "Portuguesa",
    c_c: "VE",
  },
  {
    i_c: "R",
    name: "Sucre",
    c_c: "VE",
  },
  {
    i_c: "S",
    name: "Táchira",
    c_c: "VE",
  },
  {
    i_c: "T",
    name: "Trujillo",
    c_c: "VE",
  },
  {
    i_c: "U",
    name: "Yaracuy",
    c_c: "VE",
  },
  {
    i_c: "V",
    name: "Zulia",
    c_c: "VE",
  },
  {
    i_c: "W",
    name: "Federal Dependencies of Venezuela",
    c_c: "VE",
  },
  {
    i_c: "X",
    name: "La Guaira",
    c_c: "VE",
  },
  {
    i_c: "Y",
    name: "Delta Amacuro",
    c_c: "VE",
  },
  {
    i_c: "Z",
    name: "Amazonas",
    c_c: "VE",
  },
  {
    i_c: "01",
    name: "Lai Châu",
    c_c: "VN",
  },
  {
    i_c: "02",
    name: "Lào Cai",
    c_c: "VN",
  },
  {
    i_c: "03",
    name: "Hà Giang",
    c_c: "VN",
  },
  {
    i_c: "04",
    name: "Cao Bằng",
    c_c: "VN",
  },
  {
    i_c: "05",
    name: "Sơn La",
    c_c: "VN",
  },
  {
    i_c: "06",
    name: "Yên Bái",
    c_c: "VN",
  },
  {
    i_c: "07",
    name: "Tuyên Quang",
    c_c: "VN",
  },
  {
    i_c: "09",
    name: "Lạng Sơn",
    c_c: "VN",
  },
  {
    i_c: "13",
    name: "Quảng Ninh",
    c_c: "VN",
  },
  {
    i_c: "14",
    name: "Hòa Bình",
    c_c: "VN",
  },
  {
    i_c: "18",
    name: "Ninh Bình",
    c_c: "VN",
  },
  {
    i_c: "20",
    name: "Thái Bình",
    c_c: "VN",
  },
  {
    i_c: "21",
    name: "Thanh Hóa",
    c_c: "VN",
  },
  {
    i_c: "22",
    name: "Nghệ An",
    c_c: "VN",
  },
  {
    i_c: "23",
    name: "Hà Tĩnh",
    c_c: "VN",
  },
  {
    i_c: "24",
    name: "Quảng Bình",
    c_c: "VN",
  },
  {
    i_c: "25",
    name: "Quảng Trị",
    c_c: "VN",
  },
  {
    i_c: "26",
    name: "Thừa Thiên-Huế",
    c_c: "VN",
  },
  {
    i_c: "27",
    name: "Quảng Nam",
    c_c: "VN",
  },
  {
    i_c: "28",
    name: "Kon Tum",
    c_c: "VN",
  },
  {
    i_c: "29",
    name: "Quảng Ngãi",
    c_c: "VN",
  },
  {
    i_c: "30",
    name: "Gia Lai",
    c_c: "VN",
  },
  {
    i_c: "31",
    name: "Bình Định",
    c_c: "VN",
  },
  {
    i_c: "32",
    name: "Phú Yên",
    c_c: "VN",
  },
  {
    i_c: "33",
    name: "Đắk Lắk",
    c_c: "VN",
  },
  {
    i_c: "34",
    name: "Khánh Hòa",
    c_c: "VN",
  },
  {
    i_c: "35",
    name: "Lâm Đồng",
    c_c: "VN",
  },
  {
    i_c: "36",
    name: "Ninh Thuận",
    c_c: "VN",
  },
  {
    i_c: "37",
    name: "Tây Ninh",
    c_c: "VN",
  },
  {
    i_c: "39",
    name: "Đồng Nai",
    c_c: "VN",
  },
  {
    i_c: "40",
    name: "Bình Thuận",
    c_c: "VN",
  },
  {
    i_c: "41",
    name: "Long An",
    c_c: "VN",
  },
  {
    i_c: "43",
    name: "Bà Rịa-Vũng Tàu",
    c_c: "VN",
  },
  {
    i_c: "44",
    name: "An Giang",
    c_c: "VN",
  },
  {
    i_c: "45",
    name: "Đồng Tháp",
    c_c: "VN",
  },
  {
    i_c: "46",
    name: "Tiền Giang",
    c_c: "VN",
  },
  {
    i_c: "47",
    name: "Kiên Giang",
    c_c: "VN",
  },
  {
    i_c: "49",
    name: "Vĩnh Long",
    c_c: "VN",
  },
  {
    i_c: "50",
    name: "Bến Tre",
    c_c: "VN",
  },
  {
    i_c: "51",
    name: "Trà Vinh",
    c_c: "VN",
  },
  {
    i_c: "52",
    name: "Sóc Trăng",
    c_c: "VN",
  },
  {
    i_c: "53",
    name: "Bắc Kạn",
    c_c: "VN",
  },
  {
    i_c: "54",
    name: "Bắc Giang",
    c_c: "VN",
  },
  {
    i_c: "55",
    name: "Bạc Liêu",
    c_c: "VN",
  },
  {
    i_c: "56",
    name: "Bắc Ninh",
    c_c: "VN",
  },
  {
    i_c: "57",
    name: "Bình Dương",
    c_c: "VN",
  },
  {
    i_c: "58",
    name: "Bình Phước",
    c_c: "VN",
  },
  {
    i_c: "59",
    name: "Cà Mau",
    c_c: "VN",
  },
  {
    i_c: "61",
    name: "Hải Dương",
    c_c: "VN",
  },
  {
    i_c: "63",
    name: "Hà Nam",
    c_c: "VN",
  },
  {
    i_c: "66",
    name: "Hưng Yên",
    c_c: "VN",
  },
  {
    i_c: "67",
    name: "Nam Định",
    c_c: "VN",
  },
  {
    i_c: "68",
    name: "Phú Thọ",
    c_c: "VN",
  },
  {
    i_c: "69",
    name: "Thái Nguyên",
    c_c: "VN",
  },
  {
    i_c: "70",
    name: "Vĩnh Phúc",
    c_c: "VN",
  },
  {
    i_c: "71",
    name: "Điện Biên",
    c_c: "VN",
  },
  {
    i_c: "72",
    name: "Đắk Nông",
    c_c: "VN",
  },
  {
    i_c: "73",
    name: "Hậu Giang",
    c_c: "VN",
  },
  {
    i_c: "CT",
    name: "Cần Thơ",
    c_c: "VN",
  },
  {
    i_c: "DN",
    name: "Đà Nẵng",
    c_c: "VN",
  },
  {
    i_c: "HN",
    name: "Hà Nội",
    c_c: "VN",
  },
  {
    i_c: "HP",
    name: "Hải Phòng",
    c_c: "VN",
  },
  {
    i_c: "SG",
    name: "Hồ Chí Minh",
    c_c: "VN",
  },
  {
    i_c: "MAP",
    name: "Malampa",
    c_c: "VU",
  },
  {
    i_c: "PAM",
    name: "Penama",
    c_c: "VU",
  },
  {
    i_c: "SAM",
    name: "Sanma",
    c_c: "VU",
  },
  {
    i_c: "SEE",
    name: "Shefa",
    c_c: "VU",
  },
  {
    i_c: "TAE",
    name: "Tafea",
    c_c: "VU",
  },
  {
    i_c: "TOB",
    name: "Torba",
    c_c: "VU",
  },
  {
    i_c: "AA",
    name: "A'ana",
    c_c: "WS",
  },
  {
    i_c: "AL",
    name: "Aiga-i-le-Tai",
    c_c: "WS",
  },
  {
    i_c: "AT",
    name: "Atua",
    c_c: "WS",
  },
  {
    i_c: "FA",
    name: "Fa'asaleleaga",
    c_c: "WS",
  },
  {
    i_c: "GE",
    name: "Gaga'emauga",
    c_c: "WS",
  },
  {
    i_c: "GI",
    name: "Gaga'ifomauga",
    c_c: "WS",
  },
  {
    i_c: "PA",
    name: "Palauli",
    c_c: "WS",
  },
  {
    i_c: "SA",
    name: "Satupa'itea",
    c_c: "WS",
  },
  {
    i_c: "TU",
    name: "Tuamasaga",
    c_c: "WS",
  },
  {
    i_c: "VF",
    name: "Va'a-o-Fonoti",
    c_c: "WS",
  },
  {
    i_c: "VS",
    name: "Vaisigano",
    c_c: "WS",
  },
  {
    i_c: "XDG",
    name: "Đakovica District (Gjakove)",
    c_c: "XK",
  },
  {
    i_c: "XGJ",
    name: "Gjilan District",
    c_c: "XK",
  },
  {
    i_c: "XKM",
    name: "Kosovska Mitrovica District",
    c_c: "XK",
  },
  {
    i_c: "XPE",
    name: "Peć District",
    c_c: "XK",
  },
  {
    i_c: "XPI",
    name: "Pristina (Priştine)",
    c_c: "XK",
  },
  {
    i_c: "XPR",
    name: "Prizren District",
    c_c: "XK",
  },
  {
    i_c: "XUF",
    name: "Uroševac District (Ferizaj)",
    c_c: "XK",
  },
  {
    i_c: "AB",
    name: "Abyan Governorate",
    c_c: "YE",
  },
  {
    i_c: "AD",
    name: "'Adan Governorate",
    c_c: "YE",
  },
  {
    i_c: "AM",
    name: "'Amran Governorate",
    c_c: "YE",
  },
  {
    i_c: "BA",
    name: "Al Bayda' Governorate",
    c_c: "YE",
  },
  {
    i_c: "DH",
    name: "Dhamar Governorate",
    c_c: "YE",
  },
  {
    i_c: "HD",
    name: "Hadhramaut Governorate",
    c_c: "YE",
  },
  {
    i_c: "HJ",
    name: "Hajjah Governorate",
    c_c: "YE",
  },
  {
    i_c: "HU",
    name: "Al Hudaydah Governorate",
    c_c: "YE",
  },
  {
    i_c: "IB",
    name: "Ibb Governorate",
    c_c: "YE",
  },
  {
    i_c: "JA",
    name: "Al Jawf Governorate",
    c_c: "YE",
  },
  {
    i_c: "LA",
    name: "Lahij Governorate",
    c_c: "YE",
  },
  {
    i_c: "MA",
    name: "Ma'rib Governorate",
    c_c: "YE",
  },
  {
    i_c: "MR",
    name: "Al Mahrah Governorate",
    c_c: "YE",
  },
  {
    i_c: "MW",
    name: "Al Mahwit Governorate",
    c_c: "YE",
  },
  {
    i_c: "RA",
    name: "Raymah Governorate",
    c_c: "YE",
  },
  {
    i_c: "SA",
    name: "Sana'a",
    c_c: "YE",
  },
  {
    i_c: "SD",
    name: "Saada Governorate",
    c_c: "YE",
  },
  {
    i_c: "SH",
    name: "Shabwah Governorate",
    c_c: "YE",
  },
  {
    i_c: "SN",
    name: "Sana'a Governorate",
    c_c: "YE",
  },
  {
    i_c: "SU",
    name: "Socotra Governorate",
    c_c: "YE",
  },
  {
    i_c: "TA",
    name: "Ta'izz Governorate",
    c_c: "YE",
  },
  {
    i_c: "EC",
    name: "Eastern Cape",
    c_c: "ZA",
  },
  {
    i_c: "FS",
    name: "Free State",
    c_c: "ZA",
  },
  {
    i_c: "GP",
    name: "Gauteng",
    c_c: "ZA",
  },
  {
    i_c: "KZN",
    name: "KwaZulu-Natal",
    c_c: "ZA",
  },
  {
    i_c: "LP",
    name: "Limpopo",
    c_c: "ZA",
  },
  {
    i_c: "MP",
    name: "Mpumalanga",
    c_c: "ZA",
  },
  {
    i_c: "NC",
    name: "Northern Cape",
    c_c: "ZA",
  },
  {
    i_c: "NW",
    name: "North West",
    c_c: "ZA",
  },
  {
    i_c: "WC",
    name: "Western Cape",
    c_c: "ZA",
  },
  {
    i_c: "01",
    name: "Western Province",
    c_c: "ZM",
  },
  {
    i_c: "02",
    name: "Central Province",
    c_c: "ZM",
  },
  {
    i_c: "03",
    name: "Eastern Province",
    c_c: "ZM",
  },
  {
    i_c: "04",
    name: "Luapula Province",
    c_c: "ZM",
  },
  {
    i_c: "05",
    name: "Northern Province",
    c_c: "ZM",
  },
  {
    i_c: "06",
    name: "Northwestern Province",
    c_c: "ZM",
  },
  {
    i_c: "07",
    name: "Southern Province",
    c_c: "ZM",
  },
  {
    i_c: "08",
    name: "Copperbelt Province",
    c_c: "ZM",
  },
  {
    i_c: "09",
    name: "Lusaka Province",
    c_c: "ZM",
  },
  {
    i_c: "10",
    name: "Muchinga Province",
    c_c: "ZM",
  },
  {
    i_c: "BU",
    name: "Bulawayo Province",
    c_c: "ZW",
  },
  {
    i_c: "HA",
    name: "Harare Province",
    c_c: "ZW",
  },
  {
    i_c: "MA",
    name: "Manicaland",
    c_c: "ZW",
  },
  {
    i_c: "MC",
    name: "Mashonaland Central Province",
    c_c: "ZW",
  },
  {
    i_c: "ME",
    name: "Mashonaland East Province",
    c_c: "ZW",
  },
  {
    i_c: "MI",
    name: "Midlands Province",
    c_c: "ZW",
  },
  {
    i_c: "MN",
    name: "Matabeleland North Province",
    c_c: "ZW",
  },
  {
    i_c: "MS",
    name: "Matabeleland South Province",
    c_c: "ZW",
  },
  {
    i_c: "MV",
    name: "Masvingo Province",
    c_c: "ZW",
  },
  {
    i_c: "MW",
    name: "Mashonaland West Province",
    c_c: "ZW",
  },
];

import React from "react";
import {
  Box,
  Text,
  HStack,
  Divider,
  Pressable,
  Skeleton,
  Image as _Image,
} from "native-base";
import { AiFillStar } from "react-icons/ai";
import {
  FaHeart,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaPrayingHands,
} from "react-icons/fa";
import MemberDetail from "../member/member_desc";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  BsCardList,
  BsFillFlagFill,
  BsJournalBookmarkFill,
  BsPersonFill,
} from "react-icons/bs";
import { GiBigDiamondRing, GiBodyHeight } from "react-icons/gi";

import { ImLocation, ImSpoonKnife } from "react-icons/im";
import {
  MdFamilyRestroom,
  MdLanguage,
  MdOutlineWheelchairPickup,
  MdSmokingRooms,
  MdWork,
} from "react-icons/md";
import { BiDrink } from "react-icons/bi";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { HiCurrencyRupee, HiUserGroup } from "react-icons/hi";
import {
  FcBusinessman,
  FcBusinesswoman,
  FcDiploma2,
  FcManager,
} from "react-icons/fc";
import { IoMdSchool } from "react-icons/io";

import { useState } from "react";

import { getProfileUrl } from "@helpers/get_profile_url";

import BasicDetails from "./user_details/user_basic_details";
import ReligionDetails from "./user_details/user_religion_details";
import ProfessionalDetails from "./user_details/user_professional_details";
import LocationDetails from "./user_details/user_location_details";
import FamilyDetails from "./user_details/user_family_details";
import { Button, Input, Modal, Popconfirm } from "antd";
import { navigate, navigateBack } from "@helpers/navigator";
import {
  approve_profile_query,
  dynamicClear,
  dynamicRequest,
  getMemberForAdmin,
  reject_profile_query,
  useDynamicSelector,
} from "@services/redux";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import AntdModal from "../ui/antd_modal/antd_modal";
import ManageMembersDetails from "../manage_members/manage_members_details";
import { showNotification } from "@helpers/notify";

const MemberDetailsCardView = (props) => {
  const { matches_list } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user_id } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [reject_reason, setRejectReason] = useState("");
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  const member_for_admin = useDynamicSelector("member_for_admin");
  const { loading: member_loading } = useDynamicSelector("member_for_admin");

  const {
    status: approve_profile_status,
    loading: approve_profile_loading,
    error: approve_profile_error,
  } = useDynamicSelector("approveProfile");

  const {
    status: reject_profile_status,
    loading: reject_profile_loading,
    error: reject_profile_error,
  } = useDynamicSelector("rejectProfile");

  const { status: updateStatus, error: updateError } =
    useDynamicSelector("updateMember");

  const handleRejectModalCancel = () => {
    setIsRejectModalOpen(false);
  };

  const showRejectModal = () => {
    setIsRejectModalOpen(true);
  };
  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const rejectProfile = () => {
    let key = [{ key: "rejectProfile", loading: true }];
    let query = reject_profile_query;
    let variables = { user_id: user_id, reject_reason };
    dispatch(dynamicRequest(key, query, variables, "M"));
  };
  const verifyProfile = () => {
    let key = [{ key: "approveProfile", loading: true }];
    let query = approve_profile_query;
    let variables = { user_id: user_id };
    dispatch(dynamicRequest(key, query, variables, "M"));
  };

  useEffect(() => {
    if (approve_profile_status === "success") {
      toast.success(t("profile_approved"));
      dispatch(dynamicClear("approveProfile"));
      navigateBack();
    } else if (approve_profile_error) {
      toast.error(approve_profile_error?.message);
      dispatch(dynamicClear("approveProfile"));
    } else if (reject_profile_status === "success") {
      toast.success(t("profile_rejected"));
      dispatch(dynamicClear("rejectProfile"));
      navigateBack();
    } else if (reject_profile_error) {
      toast.error(reject_profile_error?.message);
      dispatch(dynamicClear("rejectProfile"));
    } else if (updateStatus === "success") {
      handleModalClose();
      getMemberById();
      toast.success(t("member_updated"));

      dispatch(dynamicClear("updateMember"));
    } else if (updateError) {
      toast.error(updateError?.message);

      dispatch(dynamicClear("updateMember"));
    }
  }, [
    approve_profile_status,
    approve_profile_error,
    reject_profile_status,
    reject_profile_error,
    updateStatus,
  ]);
  const getMemberById = () => {
    let key = [{ key: "member_for_admin", loading: true }];
    let query = getMemberForAdmin;
    let variables = { id: user_id };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <>
      {member_loading ? (
        <Box mx={{ base: 5, sm: 2, xs: 2 }}>
          <Box>
            <HStack alignItems={"center"}>
              {/* <Skeleton marginY={5} width={"32"} height={"32"} rounded="full" /> */}
              <Skeleton.Text
                alignItems="center"
                lines={2}
                width={"30%"}
                px={5}
              />
            </HStack>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 5,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              // marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <BsCardList size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("basic_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsPersonFill size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsPersonFill size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <GiBodyHeight size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaHeart size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <GiBigDiamondRing size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaHeart size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdOutlineWheelchairPickup size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdLanguage size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <ImSpoonKnife size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BiDrink size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdSmokingRooms size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaPhoneAlt size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 5,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              // marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <FaPrayingHands size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("religion_information")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsJournalBookmarkFill size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <AiFillStar size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <VscTypeHierarchySub size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FaHeart size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcDiploma2 size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 5,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              // marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <FaMapMarkedAlt size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("location_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <ImLocation size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <ImLocation size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <BsFillFlagFill size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 5,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              // marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <MdWork size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("professional_information")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <IoMdSchool size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <MdWork size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <HiCurrencyRupee size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 8,
              boxShadow: "0 0 10px #dcdcdc",
              elevation: 5,
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              borderBottomWidth: 3,
              borderBottomColor: "#712e5a",
              // marginVertical: 10,
              marginHorizontal: 5,
              paddingHorizontal: 5,
            }}
          >
            <HStack alignItems={"center"}>
              <Box
                style={{
                  paddingHorizontal: 5,
                }}
              >
                <MdFamilyRestroom size={30} color={"#712e5a"} />
              </Box>
              <Box>
                <Text fontSize={25} fontWeight={"bold"}>
                  {t("family_details")}
                </Text>
              </Box>
            </HStack>
            <Divider my={2} />
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <HiUserGroup size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinessman size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinesswoman size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinesswoman size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcManager size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcBusinesswoman size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
            <Box marginLeft={2} mt={2}>
              <HStack alignItems={"center"} width={"100%"}>
                <Box mx={2}>
                  <FcManager size={18} color={"#ab6c95"} />
                </Box>
                <Skeleton.Text width={"20%"} lines={1} />
              </HStack>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box my={5} alignItems={"flex-start"}>
            <Button
              loading={false}
              type="default"
              onClick={() => {
                navigateBack();
              }}
            >
              {t("back")}
            </Button>
          </Box>
          {member_for_admin ? (
            <Box mb={5} mx={{ base: 5, sm: 2, xs: 2 }}>
              <HStack alignItems={"center"}>
                <Box marginLeft={2}>
                  <Text fontSize={25} textAlign={"left"} bold color={"#712e5a"}>
                    {member_for_admin?.member_profile?.name}
                  </Text>
                  <Text
                    textAlign={"left"}
                  >{`( ${member_for_admin?.member_root?.profile_number} )`}</Text>
                  <Text textAlign={"left"} fontSize={18}>
                    {member_for_admin?.member_profile?.gender === "m"
                      ? "Male"
                      : "Female"}
                  </Text>
                </Box>
              </HStack>
              <Box mt={3}>
                <BasicDetails member={member_for_admin} />
              </Box>
              <Box mt={3}>
                <ReligionDetails member={member_for_admin} />
              </Box>
              <Box mt={3}>
                <LocationDetails member={member_for_admin} />
              </Box>
              <Box mt={3}>
                <ProfessionalDetails member={member_for_admin} />{" "}
              </Box>
              <Box mt={3}>
                <FamilyDetails member={member_for_admin} />
              </Box>
              <Box my={5} width={"100%"} alignItems={"center"}>
                <HStack space={3} alignItems={"center"}>
                  <Box>
                    <Button
                      loading={false}
                      type="default"
                      onClick={() => {
                        handleEdit(member_for_admin);
                      }}
                    >
                      {t("edit")}
                    </Button>
                  </Box>

                  <Box>
                    <Button
                      // loading={reject_profile_loading}
                      type="default"
                      onClick={() => {
                        showRejectModal();
                      }}
                    >
                      {t("reject")}
                    </Button>
                  </Box>
                  <Popconfirm
                    title={t("sure_to_verify")}
                    onConfirm={() => {
                      verifyProfile();
                    }}
                  >
                    <Box>
                      <Button
                        loading={approve_profile_loading}
                        type="primary"
                        onClick={() => {}}
                      >
                        {t("verify")}
                      </Button>
                    </Box>
                  </Popconfirm>
                </HStack>
                <Box position={"absolute"} right={0}>
                  <Button
                    type="default"
                    onClick={() => {
                      navigateBack();
                    }}
                  >
                    {t("back")}
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box alignItems={"center"}>
              <Text>{"No Data"}</Text>
            </Box>
          )}
        </>
      )}
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        header={t("update_member")}
        component={
          <ManageMembersDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
            type={actionType === "add" ? "add" : "update"}
          />
        }
      />
      <Modal
        title="Reject Profile"
        open={isRejectModalOpen}
        onOk={() => {
          rejectProfile();
        }}
        onCancel={handleRejectModalCancel}
        confirmLoading={reject_profile_loading}
      >
        <Box pt={"10px"} marginBottom={"10px"}>
          <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
            {t("reason_for_rejection")}
          </Text>
        </Box>

        <Box width={"470px"} my={5}>
          <Input
            size="2xl"
            height={"50px"}
            placeholder="Reason"
            onChange={(e) => {
              setRejectReason(e.target.value);
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
export default MemberDetailsCardView;

import {
  Box,
  Hidden,
  Text,
  Image,
  VStack,
  HStack,
  Pressable,
  Skeleton,
  Heading,
  Center,
} from "native-base";
import React, { useEffect, useState, useRef } from "react";
import MembersHorizontalList from "../member/members_horizontal_list";
import { Animated, Dimensions } from "react-native";
import * as IMAGE_CONSTANTS from "@helpers/image_constants";
import { ROUTES } from "@views/routes/my_routes";
import UserSubscriptionDetail from "../user_subscription/user_subscription_detail";
import Scrollbars from "react-custom-scrollbars-2";
import {
  dynamicRequest,
  getMyPlan,
  get_user_subscription_plan,
  useDynamicSelector,
} from "@services/redux";
import { retrieveItem } from "@helpers/storage";
import moment from "moment";
import { FaCrown, FaEdit, FaUserFriends } from "react-icons/fa";
import { navigate } from "@helpers/navigator";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Modal, Spin } from "antd";
import { getCroppedImageUrl, getProfileUrl } from "@helpers/get_profile_url";
import colors from "@helpers/colors";
import { getFormattedText } from "@helpers/text_ellipsis";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;
const UserCover = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    matches_list,
    visited_me,
    members_visited_me_loading,
    all_matches_loading,
    all_matches_pagination,
  } = props;
  const [userName, setUserName] = useState("");
  const [profileID, setProfileID] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user_plan, setUserPlan] = useState([]);
  const [member_gender, setGender] = useState();
  const [image_list, setImageList] = useState([]);

  const { my_subscription: subscribed_plan, loading: plan_loading } =
    useDynamicSelector("my_plan");

  const { user_photos: userPhotos } = useDynamicSelector("my_photo");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (userPhotos) {
      let arr = userPhotos?.find((x) => x.is_main_photo === true);
      setImageList(arr);
    }
  }, [userPhotos]);
  useEffect(() => {
    let login_user = retrieveItem("name");
    let login_profile_id = retrieveItem("profile_id");
    let login_user_photo = retrieveItem("profile_photo");
    let gender = retrieveItem("gender");
    setUserName(login_user);
    setProfileID(login_profile_id);
    setProfilePhoto(login_user_photo);
    setGender(gender);
  }, []);

  useEffect(() => {
    let get_plan_key = [{ key: "my_plan", loading: true }];
    let get_plan_query = getMyPlan;
    let get_plan_variables = null;
    dispatch(dynamicRequest(get_plan_key, get_plan_query, get_plan_variables));
  }, []);

  useEffect(() => {
    if (subscribed_plan) {
      setUserPlan(subscribed_plan);
    }
  }, [subscribed_plan]);

  const UserImage = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          objectFit: "cover",
        }}
        width={280}
        height={280}
      />
    );
  });

  const Image2 = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          objectFit: "cover",
          borderRadius: "50%",
        }}
        width={80}
        height={80}
      />
    );
  });

  return (
    <Box width={"100%"} height={"100%"} backgroundColor={"#fff"}>
      <Scrollbars>
        <Box
          my={{
            base: 5,
            md: 5,
            lg: 5,
            xl: 5,
            "2xl": 5,
            "3xl": 5,
          }}
          alignItems={"center"}
          backgroundColor={"#fff"}
        >
          <Hidden till="md">
            {!member_gender ? (
              <Skeleton h={280} width={280} />
            ) : (
              <Pressable
                onPress={() => {
                  navigate(ROUTES.USER_SETTINGS);
                }}
              >
                <UserImage
                  src={getCroppedImageUrl(
                    image_list?.cropped_photo_url,
                    member_gender
                  )}
                />
              </Pressable>
            )}
            <Box my={5} alignItems={"center"}>
              {!userName && !profileID ? (
                <Box width={"50%"}>
                  <Skeleton.Text my={5} lines={1} />
                  <Skeleton.Text lines={1} />
                </Box>
              ) : (
                <>
                  <Text color={"#712e5a"} bold>
                    {getFormattedText(userName, false, 50)}
                  </Text>
                  <Text color={"#712e5a"} bold>
                    {`( ${profileID} )`}
                  </Text>
                </>
              )}
            </Box>
          </Hidden>

          <Hidden from="md">
            <HStack width={"100%"} alignItems={"center"}>
              <Box marginLeft={5} width={"30%"}>
                {!member_gender ? (
                  <Skeleton h={20} width={20} rounded="full" />
                ) : (
                  <Image2
                    src={getProfileUrl(image_list?.photo_url, member_gender)}
                  />
                )}
              </Box>

              {plan_loading ? (
                <>
                  <Box width={"50%"}>
                    <Skeleton.Text my={5} lines={1} />
                    <Skeleton.Text lines={1} />
                  </Box>
                </>
              ) : (
                <>
                  <Box flex={1}>
                    <Text color={"#712e5a"} bold>
                      {userName}
                    </Text>
                    <Text color={"#712e5a"} bold>
                      {`( ${profileID} )`}
                    </Text>
                  </Box>
                  <Box mx={2} alignItems={"center"}>
                    <Pressable
                      alignItems={"center"}
                      width={"100%"}
                      onPress={() => {
                        if (subscribed_plan) {
                          showModal();
                        } else {
                          navigate(ROUTES.USER_SUBSCRIPTION);
                        }
                      }}
                      style={{
                        shadowOffset: { width: 0, height: 1 },
                        shadowColor: "#ffffff",
                        elevation: 15,
                        shadowOpacity: 0.62,
                        shadowRadius: 2.22,
                      }}
                      backgroundColor={"#6cb0f0"}
                      marginTop={"10px"}
                      marginRight={"5px"}
                      borderColor="#ffffff"
                      borderWidth={0.8}
                      rounded={5}
                      elevation={5}
                      paddingY={2}
                    >
                      <HStack alignItems={"center"}>
                        <FaCrown size={20} color="#ffffff" />

                        <Text textAlign={"center"} mx={2} color="#fff">
                          {subscribed_plan?.en_label
                            ? subscribed_plan?.en_label
                            : "Buy Plan"}
                        </Text>
                      </HStack>
                    </Pressable>
                  </Box>
                </>
              )}
            </HStack>
          </Hidden>
          <Hidden till="md">
            <Box alignItems={"center"}>
              {plan_loading ? (
                <Box width={"50%"}>
                  <Skeleton.Text my={5} lines={1} />
                  <Skeleton.Text lines={1} />
                </Box>
              ) : (
                <Pressable
                  alignItems={"center"}
                  width={"280px"}
                  onPress={() => {
                    if (subscribed_plan) {
                      showModal();
                    } else navigate(ROUTES.USER_SUBSCRIPTION);
                  }}
                  style={{
                    shadowOffset: { width: 0, height: 1 },
                    shadowColor: "#ffffff",
                    elevation: 15,
                    shadowOpacity: 0.62,
                    shadowRadius: 2.22,
                  }}
                  backgroundColor={"#6cb0f0"}
                  marginTop={"10px"}
                  marginRight={"5px"}
                  borderColor="#ffffff"
                  borderWidth={0.8}
                  rounded={5}
                  elevation={5}
                  paddingY={2}
                >
                  <HStack alignItems={"center"}>
                    <FaCrown size={20} color="#ffffff" />

                    <Text mx={2} color="#fff">
                      {subscribed_plan?.en_label
                        ? subscribed_plan?.en_label
                        : "Buy Plan"}
                    </Text>
                  </HStack>
                </Pressable>
              )}
            </Box>
          </Hidden>
          <Hidden till="md">
            <Pressable
              onPress={() => {
                navigate(ROUTES.USER_MANAGE_PROFILE);
              }}
            >
              <Box mt={2}>
                <HStack alignItems={"center"}>
                  <FaEdit size={25} color={"#712e5a"} />
                  <Text fontSize={12} px={5}>
                    {t("edit_profile")}
                  </Text>
                </HStack>
              </Box>
              <Pressable
                onPress={() => {
                  navigate(ROUTES.USER_MANAGE_PREFERENCE);
                }}
              >
                <Box mt={2}>
                  <HStack alignItems={"center"}>
                    <FaUserFriends size={25} color={"#712e5a"} />
                    <Text fontSize={12} px={5}>
                      {t("edit_preference")}
                    </Text>
                  </HStack>
                </Box>
              </Pressable>

              <Box mt={2}></Box>
            </Pressable>
          </Hidden>

          {/* <UserSubscriptionDetail /> */}
        </Box>

        <Hidden from="md">
          <Box
            my={5}
            mx={5}
            bgColor={"#753968"}
            alignItems={"center"}
            width={"90%"}
            borderRadius={10}
          >
            <Image
              width={"100%"}
              height={40}
              borderColor={"#753968"}
              resizeMode={"contain"}
              // borderRadius={'400px'}
              source={IMAGE_CONSTANTS.BANNER_ICON}
              alt="SM"
            />
          </Box>
          <Box backgroundColor={"#fef5fb"}>
            <MembersHorizontalList
              loading={all_matches_loading}
              title={t("all_matches")}
              // total_count={all_matches_count}
              total_count={
                all_matches_pagination?.total_count
                  ? all_matches_pagination?.total_count
                  : 0
              }
              members={matches_list}
              has_more={matches_list?.length > 10 ? true : false}
              more_path={ROUTES.USER_MATCHES}
            />
          </Box>
          <Box height={10} backgroundColor={"#fef5fb"}></Box>

          <Box backgroundColor={"#fef5fb"}>
            <MembersHorizontalList
              loading={members_visited_me_loading}
              title={t("viewed_you")}
              total_count={visited_me?.length}
              members={visited_me}
              has_more={visited_me?.length > 10 ? true : false}
              more_path={ROUTES.USER_MATCHES}
            />
          </Box>
          <Box height={100} backgroundColor={"#fef5fb"}></Box>
        </Hidden>
      </Scrollbars>
      <Modal
        title="Current Plan"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <VStack>
          <Text fontSize={14} color={"#111"}>
            {`Active Plan - ${user_plan?.en_label}`}
          </Text>
          <Text fontSize={14}>
            {`${user_plan?.viewed_contact_details_count} / ${user_plan?.allowed_contact_details_view} Contacts viewed`}
          </Text>
          <HStack alignItems={"center"}>
            <FaCrown size={20} />

            <Text fontSize={14} mx={2}>{`Valid till ${moment
              .unix(user_plan?.subscription_till / 1000)
              .format("Do MMMM YYYY")}`}</Text>
          </HStack>
        </VStack>
        {/* <VStack space={3}>
          <Text bold>{`Valid Till - ${moment
            .unix(user_plan?.subscription_till / 1000)
            .format("DD MMM YYYY")}`}</Text>
          <Text>{`Allowed contact details view - ${user_plan?.allowed_contact_details_view}`}</Text>
          <Text>{`Viewed contact details count - ${user_plan?.viewed_contact_details_count}`}</Text>
        </VStack> */}
      </Modal>
    </Box>
  );
};
export default UserCover;

import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";

import {
  dynamicRequest,
  create_verifier_mutation,
  update_verifier_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { HStack, useBreakpointValue } from "native-base";
import "../../../index.css";
import {
  create_telecaller_mutation,
  update_telecaller_mutation,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_telecaller";

const TeleCallerDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, type } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { loading: createLoading } = useDynamicSelector("create_telecaller");
  const { loading: updateLoading } = useDynamicSelector("update_telecaller");

  useEffect(() => {
    if (type === "add") {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, type]);

  const handleSubmit = (values) => {
    values.mobile = String(values.mobile);
    if (initialValues.id) {
      let keys = [{ key: "update_telecaller", loading: true }];
      dispatch(
        dynamicRequest(
          keys,
          update_telecaller_mutation,
          {
            json: {
              id: initialValues?.id,
              data: values,
            },
          },
          "M"
        )
      );
    } else {
      let variables = {
        json: { data: values },
      };
      let keys = [{ key: "create_telecaller", loading: true }];
      dispatch(
        dynamicRequest(keys, create_telecaller_mutation, variables, "M")
      );
    }
  };
  const columns = useBreakpointValue({
    base: 1,
    md: 2,
  });

  return (
    <Form
      form={form}
      name={"operator_list"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.Grid columns={columns} spacingY={10} spacingX={10}>
        <VForm.TextBox
          label={t("name")}
          field={"name"}
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        />
        <VForm.Number
          label={t("mobile")}
          field={"mobile"}
          rules={[
            {
              required: true,
              message: "Mobile is required",
            },
          ]}
        />
        <VForm.TextBox
          label={t("username")}
          field={"username"}
          rules={[
            {
              required: initialValues?.id ? false : true,
              message: "Username is required",
            },
          ]}
          disabled={initialValues?.id ? true : false}
        />

        <VForm.Password
          label={t("password")}
          field={"password"}
          rules={[
            {
              required: initialValues?.id ? false : true,
              message: "Password is required",
            },
          ]}
        />
      </VForm.Grid>
      <HStack space="3" justifyContent={"flex-end"}>
        <Button onClick={props.close}>{t("close")}</Button>
        <VForm.Button
          isLoading={type === "add" ? createLoading : updateLoading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
      </HStack>
    </Form>
  );
};
export default TeleCallerDetails;

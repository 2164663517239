export const EatingHabitsOptions = [
  {
    value: "any",
    p_o: [],
    en_label: "Any",
    ta_label: "ஏதேனும்",
  },
  {
    value: "vegetarian",
    p_o: [],
    en_label: "Vegetarian",
    ta_label: "சைவம்",
  },
  {
    value: "non_vegetarian",
    p_o: [],
    en_label: "Non Vegetarian",
    ta_label: "அசைவம்",
  },

  {
    value: "eggetarian",
    p_o: [],
    en_label: "Eggetarian",
    ta_label: "முட்டை உணவு",
  },
];

import React, { useEffect, useState } from "react";
import {
  Box,
  Pressable,
  Text,
  VStack,
  HStack,
  Menu,
  Divider,
  Hidden,
  Tooltip,
  Spacer,
  Skeleton,
} from "native-base";
import { Dimensions } from "react-native";

import moment from "moment";
import MemberAvatar from "./member_avatar";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Space, Spin } from "antd";
import {
  blockMember,
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  getMember,
  ignoreMember,
  send_request_mutation,
  shortlistMember,
  unshortlistMember,
  useDynamicSelector,
} from "@services/redux";
import {
  CalculateAge,
  CalculateAgeForMember,
  LastSeen,
} from "@helpers/calculate_age";
import { useDispatch } from "react-redux";
import {
  getCity,
  getCountry,
  getDistrict,
  getState,
} from "@helpers/get_country_state_city";

import { RiSendPlaneFill } from "react-icons/ri";
import { TbMoodSad } from "react-icons/tb";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { FaHandshake, FaUserSlash } from "react-icons/fa";

import {
  AiFillStar,
  AiOutlineClockCircle,
  AiOutlineHeart,
  AiOutlineStar,
} from "react-icons/ai";
import { getCroppedImageUrl, getProfileUrl } from "@helpers/get_profile_url";
import { getFormattedText } from "@helpers/text_ellipsis";
import _ from "lodash";
import { getUserDetailsFromValue } from "@helpers/functions";
import { ReligionsOptions } from "@master_data/common_json/religions";
import { CasteOptions } from "@master_data/common_json/castes";

const MemberRectangleView = (props) => {
  const { member, index } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);
  const [currentID, setCurrentId] = useState();
  const shortlist_loading = useDynamicSelector(
    `shortlist-${member?.id}-loading`
  );

  const request_loading = useDynamicSelector(`request-${member?.id}-loading`);
  const { status: send_request_status, id: request_id } =
    useDynamicSelector("send_request");

  const matches = useDynamicSelector("matches");
  const members_visited_me = useDynamicSelector("members_visited_me");
  const my_visits_of_members = useDynamicSelector("my_visits_of_members");
  const my_shortlists = useDynamicSelector("my_shortlists");
  const short_listed_me = useDynamicSelector("short_listed_me");
  const getMembersByFilter = useDynamicSelector("getMembersByFilter");

  const { status: shortlistMemberStatus, id } =
    useDynamicSelector("shortlistMember");

  const { status: unshortlistMemberStatus, id: un_shortlist_id } =
    useDynamicSelector("unshortlistMember");

  useEffect(() => {
    if (
      (id === member?.id && shortlistMemberStatus === "success") ||
      (un_shortlist_id === member?.id && unshortlistMemberStatus === "success")
    ) {
      dispatch(dynamicSet(`shortlist-${member?.id}-loading`, false));
      let _matches = JSON.parse(JSON.stringify(matches));
      let _member = _matches?.items?.find((x) => x.id === member?.id);
      if (_member) {
        _member.is_shortlisted = shortlistMemberStatus === "success";
        dispatch(dynamicSet(`matches`, _matches));
      }

      let _members_visited_me = JSON.parse(JSON.stringify(members_visited_me));
      let _member_visited_me = _members_visited_me?.members_visited_me?.find(
        (x) => x.id === member?.id
      );
      if (_member_visited_me) {
        _member_visited_me.is_shortlisted = shortlistMemberStatus === "success";
        dispatch(dynamicSet(`members_visited_me`, _members_visited_me));
      }

      let _my_visits_of_members = JSON.parse(
        JSON.stringify(my_visits_of_members)
      );
      let _my_visit_of_members =
        _my_visits_of_members?.my_visits_of_members?.find(
          (x) => x.id === member?.id
        );
      if (_my_visit_of_members) {
        _my_visit_of_members.is_shortlisted =
          shortlistMemberStatus === "success";
        dispatch(dynamicSet(`my_visits_of_members`, _my_visits_of_members));
      }

      let _my_shortlists = JSON.parse(JSON.stringify(my_shortlists));
      let _my_shortlist = _my_shortlists?.my_shortlisted_members?.find(
        (x) => x.id === member?.id
      );

      if (_my_shortlist) {
        _my_shortlist.is_shortlisted = shortlistMemberStatus === "success";
        dispatch(dynamicSet(`my_shortlists`, _my_shortlists));
      }

      let _short_listed_me = JSON.parse(JSON.stringify(short_listed_me));
      let _member_short_listed_me = _short_listed_me?.shortlisted_me?.find(
        (x) => x.id === member?.id
      );

      if (_member_short_listed_me) {
        _member_short_listed_me.is_shortlisted =
          shortlistMemberStatus === "success";
        dispatch(dynamicSet(`short_listed_me`, _short_listed_me));
      }

      let _get_members_by_filter = JSON.parse(
        JSON.stringify(getMembersByFilter)
      );
      let _get_members_filter = _get_members_by_filter?.items?.find(
        (x) => x.id === member?.id
      );

      if (_get_members_filter) {
        _get_members_filter.is_shortlisted =
          shortlistMemberStatus === "success";
        dispatch(dynamicSet(`getMembersByFilter`, _get_members_by_filter));
      }

      dispatch(dynamicClear(`shortlist-${member?.id}-loading`));
    }
  }, [shortlistMemberStatus, unshortlistMemberStatus]);

  useEffect(() => {
    if (request_id === member?.id && send_request_status === "success") {
      dispatch(dynamicSet(`request-${member?.id}-loading`, false));

      let _matches = JSON.parse(JSON.stringify(matches));
      let _member = _matches?.items?.find((x) => x.id === member?.id);
      if (_member) {
        if (_member?.member_interest_status?.status === "no_comm") {
          _member.member_interest_status.status = "reminder_sent";
        } else if (
          _member.member_interest_status.status === "your_interest_pending"
        ) {
          _member.member_interest_status.status = "reminder_sent";
        }
        dispatch(dynamicSet(`matches`, _matches));
      }

      let _members_visited_me = JSON.parse(JSON.stringify(members_visited_me));
      let _member_visited_me = _members_visited_me?.members_visited_me?.find(
        (x) => x.id === member?.id
      );
      if (_member_visited_me) {
        if (_member_visited_me.member_interest_status.status === "no_comm") {
          _member_visited_me.member_interest_status.status = "reminder_sent";
        } else if (
          _member_visited_me.member_interest_status.status ===
          "your_interest_pending"
        ) {
          _member_visited_me.member_interest_status.status = "reminder_sent";
        }
        dispatch(dynamicSet(`members_visited_me`, _members_visited_me));
      }

      let _my_visits_of_members = JSON.parse(
        JSON.stringify(my_visits_of_members)
      );
      let _my_visit_of_members =
        _my_visits_of_members?.my_visits_of_members?.find(
          (x) => x.id === member?.id
        );

      if (_my_visit_of_members) {
        if (_my_visit_of_members.member_interest_status.status === "no_comm") {
          _my_visit_of_members.member_interest_status.status = "reminder_sent";
        } else if (
          _my_visit_of_members.member_interest_status.status ===
          "your_interest_pending"
        ) {
          _my_visit_of_members.member_interest_status.status = "reminder_sent";
        }
        dispatch(dynamicSet(`my_visits_of_members`, _my_visits_of_members));
      }

      let _my_shortlists = JSON.parse(JSON.stringify(my_shortlists));
      let _my_shortlist = _my_shortlists?.my_shortlisted_members?.find(
        (x) => x.id === member?.id
      );
      if (_my_shortlist) {
        if (_my_shortlist.member_interest_status.status === "no_comm") {
          _my_shortlist.member_interest_status.status = "reminder_sent";
        } else if (
          _my_shortlist.member_interest_status.status ===
          "your_interest_pending"
        ) {
          _my_shortlist.member_interest_status.status = "reminder_sent";
        }
        dispatch(dynamicSet(`my_shortlists`, _my_shortlists));
      }

      let _short_listed_me = JSON.parse(JSON.stringify(short_listed_me));
      let _member_short_listed_me = _short_listed_me?.shortlisted_me?.find(
        (x) => x.id === member?.id
      );

      if (_member_short_listed_me) {
        if (
          _member_short_listed_me.member_interest_status.status === "no_comm"
        ) {
          _member_short_listed_me.member_interest_status.status =
            "reminder_sent";
        } else if (
          _member_short_listed_me.member_interest_status.status ===
          "your_interest_pending"
        ) {
          _member_short_listed_me.member_interest_status.status =
            "reminder_sent";
        }
        dispatch(dynamicSet(`short_listed_me`, _short_listed_me));
      }

      let _get_members_by_filter = JSON.parse(
        JSON.stringify(getMembersByFilter)
      );
      let _filter_member = _get_members_by_filter?.items?.find(
        (x) => x.id === member?.id
      );

      if (_filter_member) {
        if (_filter_member.member_interest_status.status === "no_comm") {
          _filter_member.member_interest_status.status = "reminder_sent";
        } else if (
          _filter_member.member_interest_status.status ===
          "your_interest_pending"
        ) {
          _filter_member.member_interest_status.status = "reminder_sent";
        }
        dispatch(dynamicSet(`getMembersByFilter`, _get_members_by_filter));
      }

      dispatch(dynamicClear(`request-${member?.id}-loading`));
    }
  }, [send_request_status]);

  const blockMemberMutate = (member) => {
    let block_member_key = [{ key: "blockMember", loading: true }];
    let block_member_query = blockMember;
    let block_member_variables = { id: member?.id };
    dispatch(
      dynamicRequest(
        block_member_key,
        block_member_query,
        block_member_variables,
        "M"
      )
    );
  };

  const ignoreMemberMutate = (member) => {
    let ignore_member_key = [{ key: "ignoreMember", loading: true }];
    let ignore_member_query = ignoreMember;
    let ignore_member_variables = { id: member?.id };
    dispatch(
      dynamicRequest(
        ignore_member_key,
        ignore_member_query,
        ignore_member_variables,
        "M"
      )
    );
  };
  const shortlistMemberMutate = (member) => {
    let shortlist_member_key = [{ key: "shortlistMember", loading: true }];
    let shortlist_member_query = shortlistMember;
    let shortlist_member_variables = { id: member?.id };
    dispatch(dynamicSet(`shortlist-${member?.id}-loading`, true));
    dispatch(
      dynamicRequest(
        shortlist_member_key,
        shortlist_member_query,
        shortlist_member_variables,
        "M"
      )
    );
  };
  const unShortlistMemberMutate = (member) => {
    let shortlist_member_key = [{ key: "unshortlistMember", loading: true }];
    let shortlist_member_query = unshortlistMember;
    let shortlist_member_variables = { id: member?.id };
    dispatch(dynamicSet(`shortlist-${member?.id}-loading`, true));
    dispatch(
      dynamicRequest(
        shortlist_member_key,
        shortlist_member_query,
        shortlist_member_variables,
        "M"
      )
    );
  };

  const sendRequest = (member, type) => {
    let send_photo_request_key = [{ key: "send_request", loading: true }];
    let send_photo_request_query = send_request_mutation;
    let send_photo_request_variables = {
      member_id: member?.id,
      type: type,
    };
    dispatch(dynamicSet(`request-${member?.id}-loading`, true));

    dispatch(
      dynamicRequest(
        send_photo_request_key,
        send_photo_request_query,
        send_photo_request_variables
      )
    );
  };

  return (
    <Box
      key={index}
      w={{
        base: 350,
        // xs: 290,
        // sm: 380,
        md: 600,
        lg: 600,
        xl: 800,
        "2xl": 800,
        "3xl": 800,
      }}
      h={180}
      bg="white"
      shadow="2"
      rounded={"5px"}
      marginBottom={"15px"}
      marginX={5}
      paddingTop={"5px"}
      onMouseEnter={() => {
        setHover(true);
        setCurrentId(member?.id);
      }}
      onMouseLeave={() => {
        setHover(false);
        setCurrentId(-1);
      }}
    >
      <Pressable
        height={"70%"}
        onPress={() => {
          navigate(`/member/${member?.id}`);
        }}
        flexDirection="row"
        alignItems={"center"}
      >
        <Box paddingLeft={5} paddingY={2} w={120}>
          <MemberAvatar
            src={getCroppedImageUrl(
              member?.member_cover_photo?.cropped_photo_url,
              member?.member_profile?.gender
            )}
            width={80}
            height={80}
          />
        </Box>

        <Box flexDirection="row" flex={1}>
          <Box w={"100%"}>
            <VStack>
              <Text fontSize={16} fontWeight={"700"}>
                {getFormattedText(member?.member_profile?.name, false, 50)}
              </Text>
              <Text color="#64748B" fontSize={12} fontWeight={"400"}>
                {CalculateAgeForMember(member?.member_profile?.dob)}
              </Text>
              {member?.member_profile?.religion &&
                member?.member_profile?.caste && (
                  <Text>{`${getUserDetailsFromValue(
                    ReligionsOptions,
                    member?.member_profile?.religion
                  )}, ${getUserDetailsFromValue(
                    CasteOptions,
                    member?.member_profile?.caste
                  )}`}</Text>
                )}
              {member?.member_profile?.district &&
                member?.member_profile?.state &&
                member?.member_profile?.country && (
                  <Text>
                    {`Lives in ${getDistrict(
                      _.snakeCase(member?.member_profile?.district)
                    )}, ${getState(
                      _.snakeCase(member?.member_profile?.state),
                      _.snakeCase(member?.member_profile?.country)
                    )}, ${getCountry(member?.member_profile?.country)}`}
                  </Text>
                )}
            </VStack>
          </Box>
        </Box>
      </Pressable>
      <Box
        style={{
          position: "absolute",
          right: 2,
          top: 10,
        }}
      >
        <Menu
          style={{
            border: "1px solid #d7d7d7",
            boxShadow: "-2px 2px #7a7a7a42",
          }}
          shouldOverlapWithTrigger={true}
          placement={"left"}
          trigger={(triggerProps) => {
            return (
              <Pressable
                // alignSelf="center"
                // alignItems="center"
                variant="solid"
                {...triggerProps}
              >
                <Hidden till="md">
                  {hover && currentID === member?.id && (
                    <BsThreeDotsVertical color="grey" size={20} />
                  )}
                </Hidden>
                <Hidden from="md">
                  <BsThreeDotsVertical color="grey" size={20} />
                </Hidden>
              </Pressable>
            );
          }}
        >
          <Menu.Item>
            <Pressable
              PressableColor="#ff0000"
              PressableOpacity={0.87}
              PressableDuration={1000}
              onPress={() => {
                blockMemberMutate(member);
              }}
              // alignItems="center"
            >
              <HStack space={2}>
                <FaUserSlash size={20} color={"#ff0000"} />
                <Text color={"#ff0000"} fontSize={14}>
                  {t("block")}
                </Text>
              </HStack>
            </Pressable>
          </Menu.Item>

          <Menu.Item>
            <Pressable
              PressableColor="#0d7c80"
              PressableOpacity={0.87}
              PressableDuration={1000}
              onPress={() => {
                ignoreMemberMutate(member);
              }}
              // alignItems="center"
            >
              <HStack space={2}>
                <MdCancel size={20} color={"#0d7c80"} />

                <Text color={"#0d7c80"} justifyContent="center" fontSize={14}>
                  {t("ignore")}
                </Text>
              </HStack>
            </Pressable>
          </Menu.Item>
        </Menu>
      </Box>
      <Divider my={2} />

      <Box
        height={"20%"}
        flexDirection="row"
        justifyContent="space-around"
        width={"100%"}
      >
        <Pressable
          width={"33%"}
          cursor={"pointer"}
          onPress={() => {
            member?.member_interest_status?.status === "no_comm"
              ? sendRequest(member, "interest")
              : member?.member_interest_status?.status === "connected"
              ? ""
              : member?.member_interest_status?.status === "you_rejected"
              ? ""
              : member?.member_interest_status?.status === "member_rejected"
              ? ""
              : member?.member_interest_status?.status === "reminder_sent"
              ? ""
              : member?.member_interest_status?.status ===
                "your_interest_pending"
              ? sendRequest(member, "interest")
              : member?.member_interest_status?.status ===
                  "member_interest_pending" && "";
          }}
          flexDirection="row"
          padding={"2px"}
          justifyContent="center"
          alignItems="center"
        >
          <Hidden from="md">
            <Box justifyContent="center">
              {member?.member_interest_status?.status === "no_comm" ? (
                <HStack alignItems={"center"}>
                  {request_loading === true ? (
                    <Spin size="md" />
                  ) : (
                    <AiOutlineHeart size={15} color={"#712e5a"} />
                  )}

                  <Text paddingLeft={1} fontSize={12}>
                    {t("send_interest")}
                  </Text>
                </HStack>
              ) : member?.member_interest_status?.status === "connected" ? (
                <HStack alignItems={"center"}>
                  <FaHandshake size={20} color={"#e0bc96"} />
                  <Text paddingLeft={1} fontSize={12}>
                    {t("connected")}
                  </Text>
                </HStack>
              ) : member?.member_interest_status?.status === "you_rejected" ? (
                <Text paddingLeft={1} fontSize={12}>
                  {t("accept_request")}
                </Text>
              ) : member?.member_interest_status?.status ===
                "member_rejected" ? (
                <HStack alignItems={"center"}>
                  <TbMoodSad size={15} color={"#712e5a"} />
                  <Text paddingLeft={1} fontSize={12}>
                    {t("interest_rejected")}
                  </Text>
                </HStack>
              ) : member?.member_interest_status?.status === "reminder_sent" ? (
                <HStack alignItems={"center"}>
                  <AiOutlineClockCircle size={15} color={"#e07e1b"} />
                  <Text paddingLeft={1} fontSize={10}>
                    {t("awaiting_response")}
                  </Text>
                </HStack>
              ) : member?.member_interest_status?.status ===
                "your_interest_pending" ? (
                <HStack alignItems={"center"}>
                  {request_loading === true ? (
                    <Spin size="md" />
                  ) : (
                    <RiSendPlaneFill size={15} color={"#2a85db"} />
                  )}
                  <Text paddingLeft={1} fontSize={12}>
                    {t("send_reminder")}
                  </Text>
                </HStack>
              ) : (
                member?.member_interest_status?.status ===
                  "member_interest_pending" && (
                  <HStack alignItems={"center"}>
                    <AiOutlineHeart size={15} color={"#712e5a"} />
                    <Text paddingLeft={1} fontSize={12}>
                      {t("accept_interest")}
                    </Text>
                  </HStack>
                )
              )}
            </Box>
          </Hidden>
          <Hidden till="md">
            <Box justifyContent="center">
              {member?.member_interest_status?.status === "no_comm" ? (
                <HStack alignItems={"center"}>
                  {request_loading === true ? (
                    <Spin size="md" />
                  ) : (
                    <AiOutlineHeart size={20} color={"#712e5a"} />
                  )}
                  <Text paddingLeft={1} fontSize={16}>
                    {t("send_interest")}
                  </Text>
                </HStack>
              ) : member?.member_interest_status?.status === "connected" ? (
                <HStack alignItems={"center"}>
                  <FaHandshake size={25} color={"#e0bc96"} />
                  <Text paddingLeft={1} fontSize={16}>
                    {t("connected")}
                  </Text>
                </HStack>
              ) : member?.member_interest_status?.status === "you_rejected" ? (
                <Text paddingLeft={1} fontSize={16}>
                  {t("accept_request")}
                </Text>
              ) : member?.member_interest_status?.status ===
                "member_rejected" ? (
                <HStack alignItems={"center"}>
                  <TbMoodSad size={20} color={"#712e5a"} />
                  <Text paddingLeft={1} fontSize={16}>
                    {t("interest_rejected")}
                  </Text>
                </HStack>
              ) : member?.member_interest_status?.status === "reminder_sent" ? (
                <HStack alignItems={"center"}>
                  <AiOutlineClockCircle size={18} color={"#e07e1b"} />
                  <Text paddingLeft={1} fontSize={14}>
                    {t("awaiting_response")}
                  </Text>
                </HStack>
              ) : member?.member_interest_status?.status ===
                "your_interest_pending" ? (
                <HStack alignItems={"center"}>
                  {request_loading === true ? (
                    <Spin size="md" />
                  ) : (
                    <RiSendPlaneFill size={20} color={"#2a85db"} />
                  )}
                  <Text paddingLeft={1} fontSize={16}>
                    {t("send_reminder")}
                  </Text>
                </HStack>
              ) : (
                member?.member_interest_status?.status ===
                  "member_interest_pending" && (
                  <HStack alignItems={"center"}>
                    <AiOutlineHeart size={20} color={"#712e5a"} />
                    <Text paddingLeft={1} fontSize={14}>
                      {t("accept_interest")}
                    </Text>
                  </HStack>
                )
              )}
            </Box>
          </Hidden>
        </Pressable>

        <Divider orientation="vertical" />

        <Pressable
          width={"34%"}
          cursor={"pointer "}
          flexDirection="row"
          padding={"2px"}
          justifyContent="center"
          alignItems="center"
        >
          <Hidden from="md">
            <AiOutlineClockCircle size={15} color={"green"} />
            <Box justifyContent="center">
              <Text paddingLeft={2} fontSize={12}>
                {LastSeen(member?.member_root?.last_login_datetime)}
              </Text>
            </Box>
          </Hidden>
          <Hidden till="md">
            <AiOutlineClockCircle size={20} color={"green"} />
            <Box justifyContent="center">
              <Text paddingLeft={2} fontSize={16}>
                {LastSeen(member?.member_root?.last_login_datetime)}
              </Text>
            </Box>
          </Hidden>
        </Pressable>
        <Divider orientation="vertical" />
        <Pressable
          width={"33%"}
          cursor={"pointer "}
          flexDirection="row"
          padding={"2px"}
          justifyContent="center"
          alignItems="center"
          onPress={() => {
            shortlist_loading === true
              ? ""
              : member?.is_shortlisted
              ? unShortlistMemberMutate(member)
              : shortlistMemberMutate(member);
          }}
        >
          <Hidden from="md">
            {shortlist_loading === true ? (
              <Spin size="sm" />
            ) : member?.is_shortlisted ? (
              <AiFillStar size={15} color={"#FF1493"} />
            ) : (
              <AiOutlineStar size={15} color={"#FF1493"} />
            )}

            <Box justifyContent="center">
              {member?.is_shortlisted ? (
                <Text paddingLeft={"5px"} fontSize={12}>
                  {t("shortlisted")}
                </Text>
              ) : (
                <Text paddingLeft={"5px"} fontSize={12}>
                  {t("shortlist")}
                </Text>
              )}
            </Box>
          </Hidden>
          <Hidden till="md">
            {shortlist_loading === true ? (
              <Spin size="sm" />
            ) : member?.is_shortlisted ? (
              <AiFillStar size={20} color={"#FF1493"} />
            ) : (
              <AiOutlineStar size={20} color={"#FF1493"} />
            )}

            <Box justifyContent="center">
              {member?.is_shortlisted ? (
                <Text paddingLeft={"5px"} fontSize={16}>
                  {t("shortlisted")}
                </Text>
              ) : (
                <Text paddingLeft={"5px"} fontSize={16}>
                  {t("shortlist")}
                </Text>
              )}
            </Box>
          </Hidden>
        </Pressable>
        <Spacer />
      </Box>
      <Box height={80} />
    </Box>
  );
};
export default MemberRectangleView;

export const DrinkingHabitsOptions = [
  {
    value: "any",
    p_o: [],
    en_label: "Any",
    ta_label: "ஏதேனும்",
  },
  {
    value: "no",
    p_o: [],
    en_label: "No",
    ta_label: "இல்லை",
  },
  {
    value: "drinks_socially ",
    p_o: [],
    en_label: "Drinks Socially",
    ta_label: "எப்போதாவது",
  },
  {
    value: "yes",
    p_o: [],
    en_label: "Yes",
    ta_label: "ஆம்",
  },
];

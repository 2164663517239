import { Document, Page } from "react-pdf";
import Invoice from "@views/components/invoice/invoice";
import { Box, Button, Center, HStack, Text, VStack } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { navigateBack } from "@helpers/navigator";
import { Spin } from "antd";
import {
  dynamicRequest,
  get_invoice_details,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
const InvoicePage = () => {
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(true);
  const [response_id, setResponseId] = useState("");
  const myRef = useRef();
  const {
    customer_name,
    customer_mobile,
    customer_district,
    customer_state,
    customer_profile_id,
    customer_country,
    plan_name,
    plan_rate,
    plan_discounted_rate,
    order_datetime,
    invoice_number,
  } = useDynamicSelector("get_invoice_details");
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      myRef.current.click();
      setLoading(false);
    }, [5000]);
  }, []);

  const getInvoiceDetail = (id) => {
    let keys = [{ key: "get_invoice_details", loading: true }];
    dispatch(
      dynamicRequest(keys, get_invoice_details, {
        order_id: id,
      })
    );
  };
  useEffect(() => {
    if (pathname) {
      const parts = pathname.split("/invoice/");

      const orderId = parts[1]; // The value after "/invoice"
      setResponseId(orderId);
      if (orderId) {
        getInvoiceDetail(orderId);
      }
    }
  }, [pathname]);

  return (
    <div>
      <Center height={"500px"}>
        {loading == true ? (
          <>
            <VStack>
              <Text bold>Downloading Invoice.. Please wait..</Text>
            </VStack>
          </>
        ) : (
          ""
        )}
      </Center>
      <div
        style={{
          visibility: "hidden",
        }}
      >
        <PDFDownloadLink
          fileName="standard_matrimony_invoice_KIXp4QuQ.pdf"
          document={
            <Invoice
              order_id={response_id}
              customer_name={customer_name}
              invoice_number={invoice_number}
              customer_mobile={customer_mobile}
              customer_district={customer_district}
              customer_state={customer_state}
              customer_profile_id={customer_profile_id}
              customer_country={customer_country}
              plan_name={plan_name}
              plan_rate={plan_rate}
              plan_discounted_rate={plan_discounted_rate}
              order_datetime={order_datetime}
            />
          }
        >
          <Button ref={myRef}></Button>
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default InvoicePage;

import {
  Box,
  Center,
  Divider,
  HStack,
  Pressable,
  Spinner,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import { Dimensions } from "react-native";
import { AiFillCheckCircle } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

import "./styles.css";

// import required modules
import { EffectCards } from "swiper";
import { FaCrown } from "react-icons/fa";
import { Popconfirm } from "antd";
import {
  check_user_subscription_plan,
  create_user_subscription_plan,
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import colors from "@helpers/colors";
import { navigate } from "@helpers/navigator";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@views/routes/my_routes";

const SubscriptionPlanList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    id: payment_url,
    status,
    loading,
    error,
  } = useDynamicSelector("createUserSubscriptionPlan");
  const [all_plans, setAllPlans] = useState();
  const [active_id, setActiveId] = useState("");
  const [paymentForm, setPaymentForm] = useState("");

  const { items: all_plans_list } = useDynamicSelector("getSubscriptionPlans");
  const { status: check_plan_status, loading: check_plan_loading } =
    useDynamicSelector("checkUserSubscriptionPlan");

  const planColors = [
    { ind: 0, color: colors.lightRed },
    { ind: 1, color: colors.green2 },
    { ind: 2, color: colors.skyBlue },
    { ind: 3, color: colors.orange },
    { ind: 4, color: colors.secondary },
  ];

  const randomColor = (index) => {
    let color_item = planColors.find((x) => x.ind === index);

    return color_item;
  };
  const buyPlan = (plan_id) => {
    let key = [{ key: "createUserSubscriptionPlan", loading: true }];
    let query = create_user_subscription_plan;
    let variables = { subscription_plan_id: plan_id };
    dispatch(dynamicRequest(key, query, variables, "M"));
  };

  const checkPlanStatus = (order_id) => {
    let key = [{ key: "checkUserSubscriptionPlan", loading: true }];
    let query = check_user_subscription_plan;
    let variables = { order_id: order_id };
    dispatch(dynamicRequest(key, query, variables, "M"));
  };
  useEffect(() => {
    if (all_plans_list) {
      let _filtered_plans = all_plans_list?.filter((x) => {
        return x.is_active === true;
      });

      _filtered_plans = _filtered_plans?.filter((x) => {
        return x.allowed_purchase_count !== 1;
      });

      let list = _filtered_plans?.map((a, index) => {
        let obj = JSON.parse(JSON.stringify(a));
        obj.color = randomColor(index).color;
        return obj;
      });
      setAllPlans(list);
    }
  }, [all_plans_list]);

  useEffect(() => {
    if (status === "success") {
      // dispatch(dynamicClear("createUserSubscriptionPlan"));
      // setPaymentForm(payment_url);
      window.location.href = payment_url;

      // setTimeout(() => {
      //   const paymentFormElement = document.getElementById("nonseamless");
      //   if (paymentFormElement) {
      //     paymentFormElement.submit();
      //   }
      // }, 1000);
      // navigate(`/confirm-payment/${orderId}`);
      // history.push({
      //   pathname: `${ROUTES.CONFIRM_PAYMENT}`,
      //   state: { orderId: id },
      // });

      // navigation.navigate('confirmPayment', {orderId: id, item: item});
    } else if (error) {
      toast.error(error?.message);
      dispatch(dynamicClear("createUserSubscriptionPlan"));
    }
    if (check_plan_status === "success") {
      dispatch(dynamicClear("checkUserSubscriptionPlan"));
      navigate(ROUTES.USER_SETTINGS);
      toast.error(t("payment_success"));
    } else if (check_plan_status === "failure") {
      dispatch(dynamicClear("checkUserSubscriptionPlan"));
      toast.error(t("payment_failure"));

      navigate(ROUTES.USER_SETTINGS);
    } else if (check_plan_status === "pending") {
      dispatch(dynamicClear("checkUserSubscriptionPlan"));
      toast.error(t("payment_pending"));

      navigate(ROUTES.USER_SETTINGS);
    }
  }, [status, check_plan_status, error]);

  return (
    <Box alignItems={"center"} height={"100vh"}>
      <Box my={5} alignItems={"center"}>
        <Swiper
          effect={"cards"}
          grabCursor={true}
          modules={[EffectCards]}
          className="mySwiper"
        >
          {all_plans?.map((item, index) => {
            return (
              <SwiperSlide>
                <Box
                  w={300}
                  h={500}
                  // marginY={"5px"}
                  borderRadius={20}
                  backgroundColor={"#fff"}
                  shadow={8}
                >
                  <Box
                    backgroundColor={item.color}
                    borderTopLeftRadius={20}
                    borderTopRightRadius={20}
                    height={10}
                  />
                  <Box backgroundColor={"#fff"}>
                    <Text
                      fontFamily={"Montserrat-SemiBold"}
                      textAlign={"center"}
                      padding={2}
                      fontSize={20}
                      color={item.color}
                    >
                      {item?.en_label}
                    </Text>
                  </Box>
                  <Box backgroundColor={item.color} height={10} />
                  <Box
                    backgroundColor={item.color}
                    height={10}
                    width={300}
                    borderBottomLeftRadius={100}
                    borderBottomRightRadius={100}
                  />
                  <Box
                    height={20}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {item?.discounted_price > 0 ? (
                      <>
                        <Text
                          style={{ textDecorationLine: "line-through" }}
                          fontFamily={"Montserrat-SemiBold"}
                          color={"#111"}
                          fontSize={20}
                          fontWeight="200"
                        >
                          {`₹ ${item.actual_price}`}
                        </Text>
                        <Text
                          fontFamily={"Montserrat-SemiBold"}
                          color={item.color}
                          fontSize={35}
                          fontWeight="200"
                        >
                          {`₹ ${item.discounted_price}`}
                        </Text>
                      </>
                    ) : (
                      <Text
                        fontFamily={"Montserrat-SemiBold"}
                        color={item.color}
                        fontSize={35}
                        fontWeight="200"
                      >
                        {item?.allowed_purchase_count !== 1
                          ? `₹ ${item.actual_price}`
                          : "Free"}
                      </Text>
                    )}
                  </Box>
                  <Box flex={1} padding={2}>
                    {item.description?.map((a, index) => {
                      return (
                        <Box key={index} flexDirection={"row"}>
                          <AiFillCheckCircle size={20} color={item.color} />

                          <Text
                            color={item.color}
                            paddingLeft={"5px"}
                            paddingBottom={"5px"}
                            fontSize={16}
                          >
                            {a}
                          </Text>
                        </Box>
                      );
                    })}
                  </Box>
                  <Pressable
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginBottom: 20,
                      height: 20,
                    }}
                    onPress={() => {
                      // if (item?.order_id) {
                      //   checkPlanStatus(item?.order_id);
                      //   setActiveId(item.id);
                      // } else {
                      buyPlan(item.id);
                      setActiveId(item.id);
                      // }
                    }}
                  >
                    <Box
                      style={{
                        shadowOffset: { width: 0, height: 1 },
                        shadowColor: item.color,
                        elevation: 15,
                        shadowOpacity: 0.62,
                        shadowRadius: 1,
                      }}
                      alignItems="center"
                      justifyContent="center"
                      rounded={50}
                      backgroundColor={item.color}
                      borderWidth={0.5}
                      flexDirection="row"
                      width={"50%"}
                      height={30}
                      marginTop={4}
                    >
                      <Box>
                        {loading && active_id === item.id ? (
                          <Center flex={1}>
                            <Spinner color={"#fff"} />
                          </Center>
                        ) : (
                          <Text
                            fontFamily={"Montserrat-SemiBold"}
                            color={"#fff"}
                            fontSize={18}
                            fontWeight="200"
                          >
                            {`BUY NOW`}
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </Pressable>
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
      <Box>
        <div dangerouslySetInnerHTML={{ __html: paymentForm }} />
      </Box>
    </Box>
  );
};
export default SubscriptionPlanList;

import { navigate, navigateWithProps } from "@helpers/navigator";
import {
  dynamicClear,
  dynamicRequest,
  getMember,
  useDynamicSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Input } from "antd";
import { Box, Text, ScrollView, Hidden, HStack, Divider } from "native-base";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator } from "react-native-web";
import { useDispatch } from "react-redux";
import UserSearchForm from "../user_search_form/user_search_form";
import { toast } from "react-hot-toast";

// import ScrollView from "@views/components/ui/scroll_view/scroll_view";

const UserSearchBar = (props) => {
  const { Search } = Input;
  const { setFilterVariables, onSearchClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchLoading, setSearchLoading] = useState(false);

  const { id, error } = useDynamicSelector("member");
  let member_ref = useRef();

  let navigate_timeout = null;
  useEffect(() => {
    member_ref.current = id;
    clearTimeout(navigate_timeout);
    navigate_timeout = setTimeout(() => {
      if (member_ref.current) {
        setSearchLoading(false);
        navigateWithProps(`/member/${member_ref.current}`, member_ref.current);
      }
    }, 1000);
  }, [id]);

  const getMemberSearchById = (value) => {
    let get_search_member_key = [{ key: "member", loading: true }];
    let get_search_member_query = getMember;
    let get_search_member_variables = { id: value };
    dispatch(
      dynamicRequest(
        get_search_member_key,
        get_search_member_query,
        get_search_member_variables
      )
    );
  };

  const search = async (value) => {
    if (value.length > 5) {
      setSearchLoading(true);
      await dispatch(getMemberSearchById(value));
    }
  };

  useEffect(() => {
    if (error) {
      setSearchLoading(false);
      dispatch(dynamicClear("member"));

      toast.error(error.message.toUpperCase());
    }
  }, [error]);
  return (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      backgroundColor={"#712e5a"}
    >
      <Box marginTop={5} width={"90%"}>
        <Search
          placeholder="Search by ID"
          onSearch={(val) => {
            search(val);
          }}
        />
        {searchLoading && (
          <Box
            position={"absolute"}
            zIndex={999}
            right={10}
            top={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <ActivityIndicator size="small" color={"#712e5a"} />
          </Box>
        )}
      </Box>

      <Box my={5} alignItems={"center"} width={"90%"}>
        <Divider borderBottomWidth={0.5} borderBottomColor={"#cdbdc7"} />
      </Box>

      <ScrollView showsVerticalScrollIndicator={false} width={"100%"}>
        <UserSearchForm
          onSearchClose={onSearchClose}
          setFilterVariables={setFilterVariables}
        />
        <Box w={"90%"} backgroundColor={"#712e5a"} h={50}></Box>
      </ScrollView>
      <Hidden till="md">
        <Box w={"90%"} backgroundColor={"#712e5a"} h={10} />
      </Hidden>
    </Box>
  );
};
export default UserSearchBar;

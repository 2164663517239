export const NationalityOptions = [
  {
    nationality: "Afghan",
    num_code: "4",
  },
  {
    nationality: "Åland Island",
    num_code: "248",
  },
  {
    nationality: "Albanian",
    num_code: "8",
  },
  {
    nationality: "Algerian",
    num_code: "12",
  },
  {
    nationality: "American Samoan",
    num_code: "16",
  },
  {
    nationality: "Andorran",
    num_code: "20",
  },
  {
    nationality: "Angolan",
    num_code: "24",
  },
  {
    nationality: "Anguillan",
    num_code: "660",
  },
  {
    nationality: "Antarctic",
    num_code: "10",
  },
  {
    nationality: "Antiguan or Barbudan",
    num_code: "28",
  },
  {
    nationality: "Argentine",
    num_code: "32",
  },
  {
    nationality: "Armenian",
    num_code: "51",
  },
  {
    nationality: "Aruban",
    num_code: "533",
  },
  {
    nationality: "Australian",
    num_code: "36",
  },
  {
    nationality: "Austrian",
    num_code: "40",
  },
  {
    nationality: "Azerbaijani, Azeri",
    num_code: "31",
  },
  {
    nationality: "Bahamian",
    num_code: "44",
  },
  {
    nationality: "Bahraini",
    num_code: "48",
  },
  {
    nationality: "Bangladeshi",
    num_code: "50",
  },
  {
    nationality: "Barbadian",
    num_code: "52",
  },
  {
    nationality: "Belarusian",
    num_code: "112",
  },
  {
    nationality: "Belgian",
    num_code: "56",
  },
  {
    nationality: "Belizean",
    num_code: "84",
  },
  {
    nationality: "Beninese, Beninois",
    num_code: "204",
  },
  {
    nationality: "Bermudian, Bermudan",
    num_code: "60",
  },
  {
    nationality: "Bhutanese",
    num_code: "64",
  },
  {
    nationality: "Bolivian",
    num_code: "68",
  },
  {
    nationality: "Bonaire",
    num_code: "535",
  },
  {
    nationality: "Bosnian or Herzegovinian",
    num_code: "70",
  },
  {
    nationality: "Motswana, Botswanan",
    num_code: "72",
  },
  {
    nationality: "Bouvet Island",
    num_code: "74",
  },
  {
    nationality: "Brazilian",
    num_code: "76",
  },
  {
    nationality: "BIOT",
    num_code: "86",
  },
  {
    nationality: "Bruneian",
    num_code: "96",
  },
  {
    nationality: "Bulgarian",
    num_code: "100",
  },
  {
    nationality: "Burkinabé",
    num_code: "854",
  },
  {
    nationality: "Burundian",
    num_code: "108",
  },
  {
    nationality: "Cabo Verdean",
    num_code: "132",
  },
  {
    nationality: "Cambodian",
    num_code: "116",
  },
  {
    nationality: "Cameroonian",
    num_code: "120",
  },
  {
    nationality: "Canadian",
    num_code: "124",
  },
  {
    nationality: "Caymanian",
    num_code: "136",
  },
  {
    nationality: "Central African",
    num_code: "140",
  },
  {
    nationality: "Chadian",
    num_code: "148",
  },
  {
    nationality: "Chilean",
    num_code: "152",
  },
  {
    nationality: "Chinese",
    num_code: "156",
  },
  {
    nationality: "Christmas Island",
    num_code: "162",
  },
  {
    nationality: "Cocos Island",
    num_code: "166",
  },
  {
    nationality: "Colombian",
    num_code: "170",
  },
  {
    nationality: "Comoran, Comorian",
    num_code: "174",
  },
  {
    nationality: "Congolese",
    num_code: "178",
  },
  {
    nationality: "Congolese",
    num_code: "180",
  },
  {
    nationality: "Cook Island",
    num_code: "184",
  },
  {
    nationality: "Costa Rican",
    num_code: "188",
  },
  {
    nationality: "Ivorian",
    num_code: "384",
  },
  {
    nationality: "Croatian",
    num_code: "191",
  },
  {
    nationality: "Cuban",
    num_code: "192",
  },
  {
    nationality: "Curaçaoan",
    num_code: "531",
  },
  {
    nationality: "Cypriot",
    num_code: "196",
  },
  {
    nationality: "Czech",
    num_code: "203",
  },
  {
    nationality: "Danish",
    num_code: "208",
  },
  {
    nationality: "Djiboutian",
    num_code: "262",
  },
  {
    nationality: "Dominican",
    num_code: "212",
  },
  {
    nationality: "Dominican",
    num_code: "214",
  },
  {
    nationality: "Ecuadorian",
    num_code: "218",
  },
  {
    nationality: "Egyptian",
    num_code: "818",
  },
  {
    nationality: "Salvadoran",
    num_code: "222",
  },
  {
    nationality: "Equatorial Guinean, Equatoguinean",
    num_code: "226",
  },
  {
    nationality: "Eritrean",
    num_code: "232",
  },
  {
    nationality: "Estonian",
    num_code: "233",
  },
  {
    nationality: "Ethiopian",
    num_code: "231",
  },
  {
    nationality: "Falkland Island",
    num_code: "238",
  },
  {
    nationality: "Faroese",
    num_code: "234",
  },
  {
    nationality: "Fijian",
    num_code: "242",
  },
  {
    nationality: "Finnish",
    num_code: "246",
  },
  {
    nationality: "French",
    num_code: "250",
  },
  {
    nationality: "French Guianese",
    num_code: "254",
  },
  {
    nationality: "French Polynesian",
    num_code: "258",
  },
  {
    nationality: "French Southern Territories",
    num_code: "260",
  },
  {
    nationality: "Gabonese",
    num_code: "266",
  },
  {
    nationality: "Gambian",
    num_code: "270",
  },
  {
    nationality: "Georgian",
    num_code: "268",
  },
  {
    nationality: "German",
    num_code: "276",
  },
  {
    nationality: "Ghanaian",
    num_code: "288",
  },
  {
    nationality: "Gibraltar",
    num_code: "292",
  },
  {
    nationality: "Greek, Hellenic",
    num_code: "300",
  },
  {
    nationality: "Greenlandic",
    num_code: "304",
  },
  {
    nationality: "Grenadian",
    num_code: "308",
  },
  {
    nationality: "Guadeloupe",
    num_code: "312",
  },
  {
    nationality: "Guamanian, Guambat",
    num_code: "316",
  },
  {
    nationality: "Guatemalan",
    num_code: "320",
  },
  {
    nationality: "Channel Island",
    num_code: "831",
  },
  {
    nationality: "Guinean",
    num_code: "324",
  },
  {
    nationality: "Bissau-Guinean",
    num_code: "624",
  },
  {
    nationality: "Guyanese",
    num_code: "328",
  },
  {
    nationality: "Haitian",
    num_code: "332",
  },
  {
    nationality: "Heard Island or McDonald Islands",
    num_code: "334",
  },
  {
    nationality: "Vatican",
    num_code: "336",
  },
  {
    nationality: "Honduran",
    num_code: "340",
  },
  {
    nationality: "Hong Kong, Hong Kongese",
    num_code: "344",
  },
  {
    nationality: "Hungarian, Magyar",
    num_code: "348",
  },
  {
    nationality: "Icelandic",
    num_code: "352",
  },
  {
    nationality: "Indian",
    num_code: "356",
  },
  {
    nationality: "Indonesian",
    num_code: "360",
  },
  {
    nationality: "Iranian, Persian",
    num_code: "364",
  },
  {
    nationality: "Iraqi",
    num_code: "368",
  },
  {
    nationality: "Irish",
    num_code: "372",
  },
  {
    nationality: "Manx",
    num_code: "833",
  },
  {
    nationality: "Israeli",
    num_code: "376",
  },
  {
    nationality: "Italian",
    num_code: "380",
  },
  {
    nationality: "Jamaican",
    num_code: "388",
  },
  {
    nationality: "Japanese",
    num_code: "392",
  },
  {
    nationality: "Channel Island",
    num_code: "832",
  },
  {
    nationality: "Jordanian",
    num_code: "400",
  },
  {
    nationality: "Kazakhstani, Kazakh",
    num_code: "398",
  },
  {
    nationality: "Kenyan",
    num_code: "404",
  },
  {
    nationality: "I-Kiribati",
    num_code: "296",
  },
  {
    nationality: "North Korean",
    num_code: "408",
  },
  {
    nationality: "South Korean",
    num_code: "410",
  },
  {
    nationality: "Kuwaiti",
    num_code: "414",
  },
  {
    nationality: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
    num_code: "417",
  },
  {
    nationality: "Lao, Laotian",
    num_code: "418",
  },
  {
    nationality: "Latvian",
    num_code: "428",
  },
  {
    nationality: "Lebanese",
    num_code: "422",
  },
  {
    nationality: "Basotho",
    num_code: "426",
  },
  {
    nationality: "Liberian",
    num_code: "430",
  },
  {
    nationality: "Libyan",
    num_code: "434",
  },
  {
    nationality: "Liechtenstein",
    num_code: "438",
  },
  {
    nationality: "Lithuanian",
    num_code: "440",
  },
  {
    nationality: "Luxembourg, Luxembourgish",
    num_code: "442",
  },
  {
    nationality: "Macanese, Chinese",
    num_code: "446",
  },
  {
    nationality: "Macedonian",
    num_code: "807",
  },
  {
    nationality: "Malagasy",
    num_code: "450",
  },
  {
    nationality: "Malawian",
    num_code: "454",
  },
  {
    nationality: "Malaysian",
    num_code: "458",
  },
  {
    nationality: "Maldivian",
    num_code: "462",
  },
  {
    nationality: "Malian, Malinese",
    num_code: "466",
  },
  {
    nationality: "Maltese",
    num_code: "470",
  },
  {
    nationality: "Marshallese",
    num_code: "584",
  },
  {
    nationality: "Martiniquais, Martinican",
    num_code: "474",
  },
  {
    nationality: "Mauritanian",
    num_code: "478",
  },
  {
    nationality: "Mauritian",
    num_code: "480",
  },
  {
    nationality: "Mahoran",
    num_code: "175",
  },
  {
    nationality: "Mexican",
    num_code: "484",
  },
  {
    nationality: "Micronesian",
    num_code: "583",
  },
  {
    nationality: "Moldovan",
    num_code: "498",
  },
  {
    nationality: "Monégasque, Monacan",
    num_code: "492",
  },
  {
    nationality: "Mongolian",
    num_code: "496",
  },
  {
    nationality: "Montenegrin",
    num_code: "499",
  },
  {
    nationality: "Montserratian",
    num_code: "500",
  },
  {
    nationality: "Moroccan",
    num_code: "504",
  },
  {
    nationality: "Mozambican",
    num_code: "508",
  },
  {
    nationality: "Burmese",
    num_code: "104",
  },
  {
    nationality: "Namibian",
    num_code: "516",
  },
  {
    nationality: "Nauruan",
    num_code: "520",
  },
  {
    nationality: "Nepali, Nepalese",
    num_code: "524",
  },
  {
    nationality: "Dutch, Netherlandic",
    num_code: "528",
  },
  {
    nationality: "New Caledonian",
    num_code: "540",
  },
  {
    nationality: "New Zealand, NZ",
    num_code: "554",
  },
  {
    nationality: "Nicaraguan",
    num_code: "558",
  },
  {
    nationality: "Nigerien",
    num_code: "562",
  },
  {
    nationality: "Nigerian",
    num_code: "566",
  },
  {
    nationality: "Niuean",
    num_code: "570",
  },
  {
    nationality: "Norfolk Island",
    num_code: "574",
  },
  {
    nationality: "Northern Marianan",
    num_code: "580",
  },
  {
    nationality: "Norwegian",
    num_code: "578",
  },
  {
    nationality: "Omani",
    num_code: "512",
  },
  {
    nationality: "Pakistani",
    num_code: "586",
  },
  {
    nationality: "Palauan",
    num_code: "585",
  },
  {
    nationality: "Palestinian",
    num_code: "275",
  },
  {
    nationality: "Panamanian",
    num_code: "591",
  },
  {
    nationality: "Papua New Guinean, Papuan",
    num_code: "598",
  },
  {
    nationality: "Paraguayan",
    num_code: "600",
  },
  {
    nationality: "Peruvian",
    num_code: "604",
  },
  {
    nationality: "Philippine, Filipino",
    num_code: "608",
  },
  {
    nationality: "Pitcairn Island",
    num_code: "612",
  },
  {
    nationality: "Polish",
    num_code: "616",
  },
  {
    nationality: "Portuguese",
    num_code: "620",
  },
  {
    nationality: "Puerto Rican",
    num_code: "630",
  },
  {
    nationality: "Qatari",
    num_code: "634",
  },
  {
    nationality: "Réunionese, Réunionnais",
    num_code: "638",
  },
  {
    nationality: "Romanian",
    num_code: "642",
  },
  {
    nationality: "Russian",
    num_code: "643",
  },
  {
    nationality: "Rwandan",
    num_code: "646",
  },
  {
    nationality: "Barthélemois",
    num_code: "652",
  },
  {
    nationality: "Saint Helenian",
    num_code: "654",
  },
  {
    nationality: "Kittitian or Nevisian",
    num_code: "659",
  },
  {
    nationality: "Saint Lucian",
    num_code: "662",
  },
  {
    nationality: "Saint-Martinoise",
    num_code: "663",
  },
  {
    nationality: "Saint-Pierrais or Miquelonnais",
    num_code: "666",
  },
  {
    nationality: "Saint Vincentian, Vincentian",
    num_code: "670",
  },
  {
    nationality: "Samoan",
    num_code: "882",
  },
  {
    nationality: "Sammarinese",
    num_code: "674",
  },
  {
    nationality: "São Toméan",
    num_code: "678",
  },
  {
    nationality: "Saudi, Saudi Arabian",
    num_code: "682",
  },
  {
    nationality: "Senegalese",
    num_code: "686",
  },
  {
    nationality: "Serbian",
    num_code: "688",
  },
  {
    nationality: "Seychellois",
    num_code: "690",
  },
  {
    nationality: "Sierra Leonean",
    num_code: "694",
  },
  {
    nationality: "Singaporean",
    num_code: "702",
  },
  {
    nationality: "Sint Maarten",
    num_code: "534",
  },
  {
    nationality: "Slovak",
    num_code: "703",
  },
  {
    nationality: "Slovenian, Slovene",
    num_code: "705",
  },
  {
    nationality: "Solomon Island",
    num_code: "90",
  },
  {
    nationality: "Somali, Somalian",
    num_code: "706",
  },
  {
    nationality: "South African",
    num_code: "710",
  },
  {
    nationality: "South Georgia or South Sandwich Islands",
    num_code: "239",
  },
  {
    nationality: "South Sudanese",
    num_code: "728",
  },
  {
    nationality: "Spanish",
    num_code: "724",
  },
  {
    nationality: "Sri Lankan",
    num_code: "144",
  },
  {
    nationality: "Sudanese",
    num_code: "729",
  },
  {
    nationality: "Surinamese",
    num_code: "740",
  },
  {
    nationality: "Svalbard",
    num_code: "744",
  },
  {
    nationality: "Swazi",
    num_code: "748",
  },
  {
    nationality: "Swedish",
    num_code: "752",
  },
  {
    nationality: "Swiss",
    num_code: "756",
  },
  {
    nationality: "Syrian",
    num_code: "760",
  },
  {
    nationality: "Chinese, Taiwanese",
    num_code: "158",
  },
  {
    nationality: "Tajikistani",
    num_code: "762",
  },
  {
    nationality: "Tanzanian",
    num_code: "834",
  },
  {
    nationality: "Thai",
    num_code: "764",
  },
  {
    nationality: "Timorese",
    num_code: "626",
  },
  {
    nationality: "Togolese",
    num_code: "768",
  },
  {
    nationality: "Tokelauan",
    num_code: "772",
  },
  {
    nationality: "Tongan",
    num_code: "776",
  },
  {
    nationality: "Trinidadian or Tobagonian",
    num_code: "780",
  },
  {
    nationality: "Tunisian",
    num_code: "788",
  },
  {
    nationality: "Turkish",
    num_code: "792",
  },
  {
    nationality: "Turkmen",
    num_code: "795",
  },
  {
    nationality: "Turks and Caicos Island",
    num_code: "796",
  },
  {
    nationality: "Tuvaluan",
    num_code: "798",
  },
  {
    nationality: "Ugandan",
    num_code: "800",
  },
  {
    nationality: "Ukrainian",
    num_code: "804",
  },
  {
    nationality: "Emirati, Emirian, Emiri",
    num_code: "784",
  },
  {
    nationality: "British, UK",
    num_code: "826",
  },
  {
    nationality: "American",
    num_code: "581",
  },
  {
    nationality: "American",
    num_code: "840",
  },
  {
    nationality: "Uruguayan",
    num_code: "858",
  },
  {
    nationality: "Uzbekistani, Uzbek",
    num_code: "860",
  },
  {
    nationality: "Ni-Vanuatu, Vanuatuan",
    num_code: "548",
  },
  {
    nationality: "Venezuelan",
    num_code: "862",
  },
  {
    nationality: "Vietnamese",
    num_code: "704",
  },
  {
    nationality: "British Virgin Island",
    num_code: "92",
  },
  {
    nationality: "U.S. Virgin Island",
    num_code: "850",
  },
  {
    nationality: "Wallis and Futuna, Wallisian or Futunan",
    num_code: "876",
  },
  {
    nationality: "Sahrawi, Sahrawian, Sahraouian",
    num_code: "732",
  },
  {
    nationality: "Yemeni",
    num_code: "887",
  },
  {
    nationality: "Zambian",
    num_code: "894",
  },
  {
    nationality: "Zimbabwean",
    num_code: "716",
  },
];

import React from "react";
import {
  Box,
  Text,
  HStack,
  Divider,
  Pressable,
  Skeleton,
  Image as _Image,
} from "native-base";
import { AiFillStar } from "react-icons/ai";
import {
  FaHeart,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaPrayingHands,
} from "react-icons/fa";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Popconfirm } from "antd";
import toast from "react-hot-toast";
import { CalculateAge } from "@helpers/calculate_age";
import {
  BsCardList,
  BsFillFlagFill,
  BsJournalBookmarkFill,
  BsPersonFill,
} from "react-icons/bs";
import MemberDetailCardAdmin from "@views/components/member/member_detail_card";
import { MdWork } from "react-icons/md";
import { IoMdSchool } from "react-icons/io";
import { HiCurrencyRupee } from "react-icons/hi";
import {
  getAnnualIncome,
  getAnnualIncomeOptions,
  getUserDetailsFromValue,
} from "@helpers/functions";
import { EducationsOptions } from "@master_data/common_json/educations";
import { EmploymentTypesOptions } from "@master_data/common_json/employment_types";
import { AnnualIncomeOptions } from "@master_data/profile_json/annual_income";

const ProfessionalDetails = (props) => {
  const { member } = props;
  const { t } = useTranslation();

  return (
    <Box
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 8,
        boxShadow: "0 0 10px #dcdcdc",
        elevation: 5,
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        borderBottomWidth: 3,
        borderBottomColor: "#712e5a",
        // marginVertical: 10,
        marginHorizontal: 5,
        paddingHorizontal: 5,
      }}
    >
      <HStack alignItems={"center"}>
        <Box
          style={{
            paddingHorizontal: 5,
          }}
        >
          <MdWork size={30} color={"#712e5a"} />
        </Box>
        <Box>
          <Text fontSize={25} fontWeight={"bold"}>
            {t("professional_information")}
          </Text>
        </Box>
      </HStack>
      <Divider my={2} />
      <MemberDetailCardAdmin
        element={t("education")}
        icon={<IoMdSchool size={18} color={"#ab6c95"} />}
        // text={getUserDetailsFromValue(
        //   EducationsOptions,
        //   member?.member_profile?.education
        // )}
        list={member?.member_profile?.educations}
        type={"multi"}
        options={EducationsOptions}
      />
      <MemberDetailCardAdmin
        element={t("institution")}
        icon={<IoMdSchool size={18} color={"#ab6c95"} />}
        text={member?.member_profile?.institution}
      />
      <MemberDetailCardAdmin
        element={t("employer")}
        icon={<IoMdSchool size={18} color={"#ab6c95"} />}
        text={member?.member_profile?.employer}
      />
      <MemberDetailCardAdmin
        element={t("employment_type")}
        icon={<MdWork size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          EmploymentTypesOptions,
          member?.member_profile?.employment_type
        )}
      />
      {console.log(
        "annual_income_from",
        member?.member_profile?.annual_income_from
      )}
      <MemberDetailCardAdmin
        element={t("annual_income")}
        icon={<HiCurrencyRupee size={18} color={"#ab6c95"} />}
        text={getAnnualIncome(
          AnnualIncomeOptions,
          Number(member?.member_profile?.annual_income_from)
        )}
      />
    </Box>
  );
};
export default ProfessionalDetails;

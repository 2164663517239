import MemberCover from "@views/components/member/member_cover";
import UserCover from "@views/components/user_home/user_cover";
import {
  Box,
  Hidden,
  HStack,
  Pressable,
  Skeleton,
  Text,
  VStack,
} from "native-base";
import ScrollView from "@views/components/ui/scroll_view/scroll_view";

import React, { useEffect, useState } from "react";
import UserMemberDetailCard from "./user_member_detail_card";

import MembersHorizontalList from "@views/components/member/members_horizontal_list";
import { ROUTES } from "@views/routes/my_routes";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  getMember,
  getMyProfile,
  useDynamicSelector,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useParams, useLocation } from "react-router-dom";
import { navigateBack } from "@helpers/navigator";
import MembersHorizontalListSimilarMatches from "@views/components/member/members_horizontal_list_similar";
import { BackTop, Button } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useRef } from "react";
import { BiUpArrowAlt } from "react-icons/bi";

const UserMemberDetail = (props) => {
  const top_ref = useRef();
  const { t } = useTranslation();
  const { member_id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);

  let searched_member_id = location.props_state;

  const [memberDetails, setMemberDetails] = useState({});
  const [memberPreference, setMemberPreference] = useState({});
  const [loginUserDetails, setLoginUserDetails] = useState({});
  const [photoDetails, setPhotoDetails] = useState([]);
  const [cover_photo, setCoverPhoto] = useState();
  const [login_user_photo, setLoginUserPhoto] = useState([]);
  const [similar_matches_list, setSimilarMatchesList] = useState([]);
  const [login_user_horoscope, setLoginUserHoroscope] = useState([]);
  const [member_requests_list, setMemberRequestsList] = useState([]);
  const [login_user_current_plan, setLoginUserPlan] = useState([]);

  const {
    id: _member_id,
    member_profile,
    member_photos,
    member_preference,
    loading: member_loading,
    is_shortlisted,
    member_interest_status,
    member_cover_photo,
    member_horoscope,
    member_requests_from_me,
    member_root,
    error,
  } = useDynamicSelector("member");
  const { items: similar_members_list, loading: similar_members_loading } =
    useDynamicSelector("similar_matches");
  const {
    user_profile: my_profile_details,
    user_photos: login_user_photos,
    my_subscription: login_user_plan,
  } = useDynamicSelector("my_profile");

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);
  const goToTop = () => {
    debouncedHandleTitleClick();
  };

  useEffect(() => {
    return () => dispatch(dynamicClear("member"));
  }, [member_id]);

  useEffect(() => {
    if (!searched_member_id) {
      let get_member_key = [{ key: "member", loading: true }];
      let get_member_query = getMember;
      let get_member_variables = { id: member_id };
      dispatch(
        dynamicRequest(get_member_key, get_member_query, get_member_variables)
      );
    }
  }, [searched_member_id, member_id]);

  useEffect(() => {
    if (_member_id) {
      let get_my_user_key = [{ key: "my_profile", loading: true }];
      let get_my_user_query = getMyProfile;
      let get_my_user_variables = {};
      dispatch(
        dynamicRequest(
          get_my_user_key,
          get_my_user_query,
          get_my_user_variables
        )
      );
    }
  }, [_member_id]);

  useEffect(() => {
    if (member_profile) {
      setMemberDetails(member_profile);
      setPhotoDetails(member_photos);
      setCoverPhoto(member_cover_photo);
      setMemberPreference(member_preference);
      setLoginUserDetails(my_profile_details);
      setLoginUserPhoto(login_user_photos);
      setLoginUserHoroscope(member_horoscope);
      setMemberRequestsList(member_requests_from_me);
      setLoginUserPlan(login_user_plan);
    }
  }, [
    member_profile,
    member_photos,
    member_preference,
    my_profile_details,
    login_user_photos,
    member_cover_photo,
    member_horoscope,
    member_requests_from_me,
    login_user_plan,
  ]);

  useEffect(() => {
    if (similar_members_list) {
      setSimilarMatchesList(similar_members_list);
    }
  }, [similar_members_list]);

  return (
    <Box flex={1}>
      <>
        {error?.message === "invalid member" && (
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            flex={1}
            backgroundColor={"white"}
          >
            <Text fontSize={"3xl"} color={"#712e5a"}>
              {"Member profile is hidden"}
            </Text>
          </Box>
        )}
        {!error && (
          <>
            <Box
              shadow={1}
              backgroundColor={"#fef5fb"}
              height={30}
              alignItems={"center"}
              justifyContent={"center"}
              width={"100vw"}
            >
              <Pressable
                onPress={() => {
                  navigateBack();
                }}
                cursor={"pointer"}
                position={"absolute"}
                left={5}
              >
                <AiOutlineArrowLeft size={20} color={"#111"} />
              </Pressable>
            </Box>
            <HStack>
              <Box
                height={"100vh"}
                width={{
                  base: 0,
                  md: 300,
                  lg: 300,
                  xl: 300,
                  "2xl": 300,
                  "3xl": 300,
                }}
              >
                <Hidden till="md">
                  <MemberCover
                    member_root={member_root}
                    member_requests_list={member_requests_list}
                    cover_photo={cover_photo}
                    is_shortlisted={is_shortlisted}
                    member_interest_status={member_interest_status}
                    _member_id={_member_id}
                    member_profile={member_profile}
                    _photoDetails={photoDetails}
                    login_user_horoscope={login_user_horoscope}
                  />
                </Hidden>
              </Box>

              <ScrollView showVerticalBar flex={1}>
                <Box ref={top_ref} backgroundColor={"#fef5fb"}>
                  <UserMemberDetailCard
                    member_root={member_root}
                    login_user_current_plan={login_user_current_plan}
                    member_loading={member_loading}
                    cover_photo={cover_photo}
                    _member_id={_member_id}
                    member_profile={member_profile}
                    login_user_horoscope={login_user_horoscope}
                    _loginUserDetails={loginUserDetails}
                    preference_matches={memberPreference}
                    _photoDetails={photoDetails}
                    memberCardDetails={memberDetails}
                    login_user_photo={login_user_photo}
                    is_shortlisted={is_shortlisted}
                    member_interest_status={member_interest_status}
                    member_requests_list={member_requests_list}
                  />
                </Box>
                {similar_matches_list?.length > 0 && (
                  <Box backgroundColor={"#fef5fb"}>
                    <MembersHorizontalListSimilarMatches
                      loading={similar_members_loading}
                      title={t("similar_matches")}
                      total_count={
                        similar_matches_list ? similar_matches_list?.length : 0
                      }
                      members={similar_matches_list}
                      has_more={false}
                      more_path={ROUTES.USER_MATCHES}
                    />
                  </Box>
                )}
                {/* <Box backgroundColor={"#fef5fb"} alignItems={"center"}>
                  <Button
                    colorScheme={"danger.700"}
                    onClick={() => {
                      goToTop();
                    }}
                    style={{
                      label: t("table:back"),
                      variant: "outline",
                    }}
                  >
                    <HStack width={"100%"} alignItems={"center"}>
                      <BiUpArrowAlt size={20} />
                      <Text>{"Go to Top"}</Text>
                    </HStack>
                  </Button>
                </Box> */}
                <Box backgroundColor={"#fef5fb"} height={40}></Box>
              </ScrollView>
            </HStack>
          </>
        )}
      </>
    </Box>
  );
};
export default UserMemberDetail;

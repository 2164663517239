import React, { useEffect, useState } from "react";
import {
  Box,
  Pressable,
  Text,
  VStack,
  HStack,
  Menu,
  Divider,
  Hidden,
  Tooltip,
  Spacer,
} from "native-base";
import { Dimensions } from "react-native";
import moment from "moment";
import MemberAvatar from "./member_avatar";
import { navigate } from "@helpers/navigator";
import { useTranslation } from "react-i18next";
import { Button, Space } from "antd";
import {
  blockMember,
  dynamicClear,
  dynamicRequest,
  getMyBlockedProfiles,
  ignoreMember,
  shortlistMember,
  unblockMember,
  unignoreMember,
  useDynamicSelector,
} from "@services/redux";
import toast from "react-hot-toast";
import { CalculateAge } from "@helpers/calculate_age";
import { useDispatch } from "react-redux";
import { getProfileUrl } from "@helpers/get_profile_url";

const windowWidth = Dimensions.get("window").width;
const MemberBlock = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { member, index, title } = props;

  const { status: unblockMemberStatus } = useDynamicSelector("unblockMember");

  const unblockMemberMutate = (member_id) => {
    let un_block_member_key = [{ key: "unblockMember", loading: true }];
    let un_block_member_query = unblockMember;
    let un_block_member_variables = { id: member_id };
    dispatch(
      dynamicRequest(
        un_block_member_key,
        un_block_member_query,
        un_block_member_variables,
        "M"
      )
    );
  };
  const getBlockedMembersList = () => {
    let get_my_blocked_user_key = [
      { key: "my_blocked_profile", loading: true },
    ];
    let get_my_blocked_user_query = getMyBlockedProfiles;
    let get_my_blocked_user_variables = null;
    dispatch(
      dynamicRequest(
        get_my_blocked_user_key,
        get_my_blocked_user_query,
        get_my_blocked_user_variables
      )
    );
  };
  useEffect(() => {
    if (unblockMemberStatus === "success") {
      toast.success(t("profile_unblocked"));
      getBlockedMembersList();
      dispatch(dynamicClear("unblockMember"));
    }
  }, [unblockMemberStatus]);
  return (
    <Box
      key={index}
      w={{ base: 300, md: 450, lg: 500, xl: 500, "2xl": 500, "3xl": 500 }}
      h={100}
      bg="white"
      shadow="2"
      rounded={"5px"}
      marginBottom={"15px"}
      justifyContent={"center"}
    >
      <Pressable flexDirection="row" alignItems={"center"}>
        <Box
          cursor={"pointer"}
          onClick={() => {
            navigate(`member/${member?.id}`);
          }}
          alignItems={"center"}
          justifyContent={"center"}
          width={{ base: "120px", xs: "100px", sm: "100px" }}
        >
          <MemberAvatar
            src={getProfileUrl(
              member?.member_cover_photo?.cropped_photo_url,
              member?.member_profile?.gender
            )}
            width={80}
            height={80}
          />
        </Box>

        <Box flexDirection="row" flex={1}>
          <Box w={"100%"}>
            <VStack>
              <Text fontSize={16} fontWeight={"700"}>
                {member?.member_profile?.name}
              </Text>
              <Text color="#64748B" fontSize={12} fontWeight={"400"}>
                {CalculateAge(member?.member_profile?.dob)}
              </Text>
            </VStack>
          </Box>
        </Box>
        <Box
          alignItems={"center"}
          mx={5}
          width={{ base: "100px", xs: "80px", sm: "80px" }}
        >
          <Button
            type="primary"
            onClick={() => {
              unblockMemberMutate(member?.id);
            }}
          >
            {title}
          </Button>
        </Box>
      </Pressable>
    </Box>
  );
};
export default MemberBlock;

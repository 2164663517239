import { Form } from "antd";
import { Box, Button, Drawer, HStack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MemberFilterForm from "./member_filter_form";
import {
  dynamicRequest,
  get_all_franchise_members_for_filter,
  get_all_members_query,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const MemberFilterDetails = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [initial_values, setInitialValues] = useState([]);

  const getFranchiseList = () => {
    let keys = [{ key: "filter_franchises", loading: true }];
    let variables = null;
    dispatch(
      dynamicRequest(keys, get_all_franchise_members_for_filter, variables, "M")
    );
  };

  const getAllMembersList = (values) => {
    let keys = [{ key: "manage_members_list", loading: true }];
    let variables = {
      sort_by: "created",
      gender: values?.gender,
      caste: values?.caste,
      franchise_id: values?.franchise_id,
      pincode: values?.pincode,
      page_number: 1,
      page_limit: 10,
    };
    dispatch(dynamicRequest(keys, get_all_members_query, variables));
  };
  const onFinish = (values) => {
    getAllMembersList(values);
  };

  useEffect(() => {
    getFranchiseList();
  }, []);

  return (
    <Box p={5}>
      <Form
        onFinish={onFinish}
        form={form}
        className="user_form"
        layout={"vertical"}
      >
        <MemberFilterForm onFilterClose={onClose} form={form} />
      </Form>
    </Box>
  );
};

export default MemberFilterDetails;

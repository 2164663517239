import React, { useEffect, useState } from "react";
import MemberSquareView from "./member_square_view";
import {
  Box,
  Center,
  Text,
  FlatList,
  Spinner,
  Skeleton,
  VStack,
  HStack,
  Pressable,
  ScrollView,
  Heading,
  Avatar,
  Spacer,
  Hidden,
  Image,
} from "native-base";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useHorizontalScroll } from "../../../helpers/useSideScroll";
import ViewAll from "@assets/icons/viewall.png";
import { Animated, Dimensions } from "react-native";
import { navigate } from "@helpers/navigator";
import { useTranslation } from "react-i18next";
import { page_count } from "@helpers/constants";

const MembersHorizontalList = (props) => {
  const { t } = useTranslation();
  const { members, has_more, more_path, title, total_count, loading } = props;
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (total_count) {
      setTotal(total_count);
    }
  }, [total_count]);

  useEffect(() => {
    if (members) {
      if (members.length > page_count) {
        let _members = JSON.parse(JSON.stringify(members));
        setItems(members.slice(0, 7));
      } else setItems(members);
    }
  }, [members]);

  const ViewAllImg = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          borderRadius: "50%",
          objectFit: "cover",
        }}
        width={50}
        height={50}
      />
    );
  });

  const ListView = () => {
    return (
      <>
        {items.map((member, index) => {
          return (
            <MemberSquareView loading={loading} key={index} member={member} />
          );
        })}
        {has_more && (
          <Pressable
            onPress={() => {
              navigate(more_path);
            }}
          >
            <Center w={180} h={180} marginLeft={"-35px"}>
              <Box
                alignItems={"center"}
                justifyContent={"center"}
                w={"55%"}
                h={"95%"}
                bg="white"
                rounded={"5px"}
                padding={5}
                shadow={2}
              >
                <Box justifyContent="center">
                  <ViewAllImg src={ViewAll} />
                </Box>
                <Box alignItems="center" justifyContent="center" h={45}>
                  <Box alignItems="center" justifyContent="center">
                    <Text fontSize={14} fontWeight={"700"} mt={2}>
                      {t("view_all")}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Center>
          </Pressable>
        )}
      </>
    );
  };

  const ListDesktopView = () => {
    const scrollRef = useHorizontalScroll();
    return (
      <Box ref={scrollRef} style={{ overflowX: "hidden" }} flexDirection="row">
        <ListView />
      </Box>
    );
  };

  const ListMobileView = () => {
    return (
      <div className="horizontal_container">
        <HStack>
          <ListView />
        </HStack>
      </div>
    );
  };

  return (
    <Box paddingX={5} backgroundColor={"#fef5fb"}>
      {total > 0 ? (
        <Heading fontSize="xl" p="4" pb="3">
          {`${title} (${total})`}
        </Heading>
      ) : (
        <Heading fontSize="xl" p="4" pb="3">
          {`${title}`}
        </Heading>
      )}
      {total > 0 ? (
        <>
          <Hidden till="md">
            <ListDesktopView />
          </Hidden>
          <Hidden from="md">
            <ListMobileView />
          </Hidden>
        </>
      ) : (
        <Box
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={10}
          height={"72"}
        >
          <Image
            source={{
              uri: "https://blr1.vultrobjects.com/assets/no_data.gif",
            }}
            width={"72"}
            height={"72"}
          />
          <Box
            // width={40}
            // height={40}
            alignItems={"center"}
            position={"absolute"}
            bottom={10}
          >
            {title === "All Matches" && (
              <Text
                style={{
                  textAlign: "center",
                }}
                color={"#712e5a"}
              >
                {t("all_matches_no_data")}
              </Text>
            )}
            {title === "Viewed You" && (
              <Text
                style={{
                  textAlign: "center",
                }}
                color={"#712e5a"}
              >
                {t("visited_me_no_profile")}
              </Text>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default MembersHorizontalList;

import React, { useEffect, useState } from "react";
import MemberHarizontalView from "../components/member/members_horizontal_list";
import MemberCardView from "../components/member/member_card_view";
import MemberPartnerMatchView from "../components/member/member_partner_match_views";

import { Box, Center, FlatList, Hidden } from "native-base";
import { ROUTES } from "@views/routes/my_routes";
import MemberCover from "@views/components/member/member_cover";

const UserMemberDetailCard = (props) => {
  const {
    member_loading,
    login_user_current_plan,
    _member_id,
    memberCardDetails,
    member_profile,
    _photoDetails,
    preference_matches,
    _loginUserDetails,
    login_user_photo,
    is_shortlisted,
    member_interest_status,
    cover_photo,
    login_user_horoscope,
    member_requests_list,
    member_root,
  } = props;

  return (
    <>
      <Hidden from="md">
        <MemberCover
          cover_photo={cover_photo}
          member_requests_list={member_requests_list}
          is_shortlisted={is_shortlisted}
          member_interest_status={member_interest_status}
          _member_id={_member_id}
          member_profile={member_profile}
          _photoDetails={_photoDetails}
          login_user_horoscope={login_user_horoscope}
          member_root={member_root}
        />
      </Hidden>

      <MemberCardView
        cover_photo={cover_photo}
        login_user_current_plan={login_user_current_plan}
        member_loading={member_loading}
        login_user_horoscope={login_user_horoscope}
        _member_id={_member_id}
        item={memberCardDetails}
        member_requests_list={member_requests_list}
        member_root={member_root}
      />

      <MemberPartnerMatchView
        member_profile={member_profile}
        login_user_photo={login_user_photo}
        _loginUserDetails={_loginUserDetails}
        _photoDetails={_photoDetails}
        preference_matches={preference_matches}
        cover_photo={cover_photo}
      />
    </>
  );
};
export default UserMemberDetailCard;

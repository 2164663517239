import { navigateBack } from "@helpers/navigator";
import {
  dynamicRequest,
  getMyBlockedProfiles,
  getMyIgnoredProfiles,
  getMyProfile,
  useDynamicSelector,
} from "@services/redux";
import MemberIgnore from "@views/components/member/member_ignore";
import MemberBlockIgnore from "@views/components/member/member_ignore";
import { Spin } from "antd";
import { Box, FlatList, Image, Pressable, Text } from "native-base";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch } from "react-redux";
const UserIgnoredMembers = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ignored_members, setIgnoredMembers] = useState([]);

  const all_data = [
    {
      id: "bd7acbea-c1b1-46c2-aed5-3ad53abb28ba",
      fullName: "Aafreen Khan",
      timeStamp: "11/11/97",
      recentText: "Good Day!",
      avatarUrl:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
    {
      id: "3ac68afc-c605-48d3-a4f8-fbd91aa97f63",
      fullName: "Sujitha Mathur",
      timeStamp: "01/05/92",
      recentText: "Cheer up, there!",
      avatarUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyEaZqT3fHeNrPGcnjLLX1v_W4mvBlgpwxnA&usqp=CAU",
    },
    {
      id: "58694a0f-3da1-471f-bd96-145571e29d72",
      fullName: "Anci Barroco",
      timeStamp: "07/05/89",
      recentText: "Good Day!",
      avatarUrl: "https://miro.medium.com/max/1400/0*0fClPmIScV5pTLoE.jpg",
    },
    {
      id: "b17acbea-c1b1-46c2-aed5-3ad53abb28ba",
      fullName: "Good Khan",
      timeStamp: "11/11/97",
      recentText: "Good Day!",
      avatarUrl:
        "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
    {
      id: "31c68afc-c605-48d3-a4f8-fbd91aa97f63",
      fullName: "Cheer Mathur",
      timeStamp: "01/05/92",
      recentText: "Cheer up, there!",
      avatarUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyEaZqT3fHeNrPGcnjLLX1v_W4mvBlgpwxnA&usqp=CAU",
    },
    {
      id: "51694a0f-3da1-471f-bd96-145571e29d72",
      fullName: "Day Barroco",
      timeStamp: "07/05/89",
      recentText: "Good Day!",
      avatarUrl: "https://miro.medium.com/max/1400/0*0fClPmIScV5pTLoE.jpg",
    },
    {
      id: "bd3acbea-c1b1-46c2-aed5-3ad53abb28ba",
      fullName: "Aafreen Khan",
      timeStamp: "11/11/97",
      recentText: "Good Day!",
      avatarUrl:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
    {
      id: "3a368afc-c605-48d3-a4f8-fbd91aa97f63",
      fullName: "Sujitha Mathur",
      timeStamp: "01/05/92",
      recentText: "Cheer up, there!",
      avatarUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyEaZqT3fHeNrPGcnjLLX1v_W4mvBlgpwxnA&usqp=CAU",
    },
    {
      id: "58494a0f-3da1-471f-bd96-145571e29d72",
      fullName: "Anci Barroco",
      timeStamp: "07/05/89",
      recentText: "Good Day!",
      avatarUrl: "https://miro.medium.com/max/1400/0*0fClPmIScV5pTLoE.jpg",
    },
    {
      id: "bd5acbea-c1b1-46c2-aed5-3ad53abb28ba",
      fullName: "Aafreen Khan",
      timeStamp: "11/11/97",
      recentText: "Good Day!",
      avatarUrl:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
    {
      id: "3a568afc-c605-48d3-a4f8-fbd91aa97f63",
      fullName: "Sujitha Mathur",
      timeStamp: "01/05/92",
      recentText: "Cheer up, there!",
      avatarUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyEaZqT3fHeNrPGcnjLLX1v_W4mvBlgpwxnA&usqp=CAU",
    },
    {
      id: "58644a0f-3da1-471f-bd96-145571e29d72",
      fullName: "Anci Barroco",
      timeStamp: "07/05/89",
      recentText: "Good Day!",
      avatarUrl: "https://miro.medium.com/max/1400/0*0fClPmIScV5pTLoE.jpg",
    },
    {
      id: "bd73cbea-c1b1-46c2-aed5-3ad53abb28ba",
      fullName: "Aafreen Khan",
      timeStamp: "11/11/97",
      recentText: "Good Day!",
      avatarUrl:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
    {
      id: "3ac61afc-c605-48d3-a4f8-fbd91aa97f63",
      fullName: "Sujitha Mathur",
      timeStamp: "01/05/92",
      recentText: "Cheer up, there!",
      avatarUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyEaZqT3fHeNrPGcnjLLX1v_W4mvBlgpwxnA&usqp=CAU",
    },
    {
      id: "5869ga0f-3da1-471f-bd96-145571e29d72",
      fullName: "Anci Barroco",
      timeStamp: "07/05/89",
      recentText: "Good Day!",
      avatarUrl: "https://miro.medium.com/max/1400/0*0fClPmIScV5pTLoE.jpg",
    },
  ];
  const { my_ignored_members, loading: getIgnoreLoading } =
    useDynamicSelector("ignored_members");

  const getIgnoredMemberList = () => {
    let get_my_blocked_user_key = [{ key: "ignored_members", loading: true }];
    let get_my_blocked_user_query = getMyIgnoredProfiles;
    let get_my_blocked_user_variables = null;
    dispatch(
      dynamicRequest(
        get_my_blocked_user_key,
        get_my_blocked_user_query,
        get_my_blocked_user_variables
      )
    );
  };

  useEffect(() => {
    getIgnoredMemberList();
  }, []);

  useEffect(() => {
    if (my_ignored_members) {
      setIgnoredMembers(my_ignored_members);
    }
  }, [my_ignored_members]);

  return (
    <Box flex={1} backgroundColor={"#fef5fb"} height={"100vh"} width={"100%"}>
      <Box
        shadow={1}
        height={30}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100vw"}
      >
        <Pressable
          onPress={() => {
            navigateBack();
          }}
          cursor={"pointer"}
          position={"absolute"}
          left={5}
        >
          <AiOutlineArrowLeft size={20} color={"#111"} />
        </Pressable>
        <Text color={"#712e5a"} fontSize={16} bold>
          {t("ignored_list")}
        </Text>
      </Box>
      <Scrollbars>
        <Box my={5} alignItems={"center"}>
          <FlatList
            data={ignored_members}
            renderItem={({ item, index }) => (
              <MemberIgnore
                title={t("un_ignore")}
                member={item}
                index={index}
              />
            )}
            keyExtractor={(item) => item.id}
            //   ListFooterComponent={renderLoader}
            //   onEndReached={loadMoreItem}
            onEndReachedThreshold={0}
            // scrollEnabled={false}
            showsHorizontalScrollIndicator={false}
            ListEmptyComponent={
              <Box
                width={"100%"}
                height={"100vh"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Image
                  source={{
                    uri: "https://blr1.vultrobjects.com/assets/no_data.gif",
                  }}
                  width={250}
                  height={250}
                />
                <Box
                  width={250}
                  height={250}
                  alignItems={"center"}
                  position={"absolute"}
                  bottom={10}
                >
                  <Text color={"#712e5a"}>{t("no_ignored_profiles")}</Text>
                </Box>
              </Box>
            }
          />
        </Box>
      </Scrollbars>
    </Box>
  );
};
export default UserIgnoredMembers;

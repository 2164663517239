import {
  Box,
  Hidden,
  Text,
  VStack,
  HStack,
  Pressable,
  Divider,
  Image as _Image,
  Skeleton,
} from "native-base";
import React, { useEffect, useState, useRef } from "react";
import { Animated, Dimensions } from "react-native";

import { Image, Popconfirm, Spin } from "antd";
import {
  AiFillStar,
  AiOutlineClockCircle,
  AiOutlineHeart,
  AiOutlineStar,
} from "react-icons/ai";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  getMember,
  getMyProfile,
  send_request_mutation,
  send_request_to_add_photo,
  shortlistMember,
  unshortlistMember,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { FiThumbsUp } from "react-icons/fi";
import { FaHandshake } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { getMemberCoverPhotoResponse } from "@helpers/member_cover_photo_response_function";
import { RiSendPlaneFill } from "react-icons/ri";
import { getCroppedImageUrl, getProfileUrl } from "@helpers/get_profile_url";
import { navigate } from "@helpers/navigator";

const MemberCover = (props) => {
  const {
    _photoDetails,
    member_profile,
    _member_id,
    member_interest_status,
    cover_photo,
    member_requests_list,
    is_shortlisted,
    member_root,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { member_id } = useParams();
  const [visible, setVisible] = useState(false);
  const [photo_response, setPhotoResponse] = useState([]);

  const { loading } = useDynamicSelector("member");
  const member_details = useDynamicSelector("member");

  const shortlist_loading = useDynamicSelector(
    `shortlist-${member_id}-loading`
  );

  const request_loading = useDynamicSelector(`request-${member_id}-loading`);

  const { status: send_request_status, id: request_id } =
    useDynamicSelector("send_request");
  const {
    status: send_request_to_add_photo_status,
    error: send_request_to_add_photo_error,
  } = useDynamicSelector("send_request_add_photo");

  const { id, status: shortlistMemberStatus } =
    useDynamicSelector("shortlistMember");

  const { id: un_shortlist_id, status: unshortlistMemberStatus } =
    useDynamicSelector("unshortlistMember");

  const shortlistMemberMutate = (_member_id) => {
    let shortlist_member_key = [{ key: "shortlistMember", loading: true }];
    let shortlist_member_query = shortlistMember;
    let shortlist_member_variables = { id: _member_id };
    dispatch(dynamicSet(`shortlist-${member_id}-loading`, true));
    dispatch(
      dynamicRequest(
        shortlist_member_key,
        shortlist_member_query,
        shortlist_member_variables,
        "M"
      )
    );
  };
  const unShortlistMemberMutate = (_member_id) => {
    let shortlist_member_key = [{ key: "unshortlistMember", loading: true }];
    let shortlist_member_query = unshortlistMember;
    let shortlist_member_variables = { id: _member_id };
    dispatch(dynamicSet(`shortlist-${member_id}-loading`, true));

    dispatch(
      dynamicRequest(
        shortlist_member_key,
        shortlist_member_query,
        shortlist_member_variables,
        "M"
      )
    );
  };

  const sendRequest = (_member_id, type) => {
    let send_photo_request_key = [{ key: "send_request", loading: true }];
    let send_photo_request_query = send_request_mutation;
    let send_photo_request_variables = {
      member_id: _member_id,
      type: type,
    };

    dispatch(dynamicSet(`request-${member_id}-loading`, true));

    dispatch(
      dynamicRequest(
        send_photo_request_key,
        send_photo_request_query,
        send_photo_request_variables
      )
    );
  };

  const sendRequestToAddPhoto = (_member_id, type) => {
    let key = [{ key: "send_request_add_photo", loading: true }];
    let query = send_request_to_add_photo;
    let variables = {
      member_id: _member_id,
      type: type,
    };

    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    if (
      (id === member_id && shortlistMemberStatus === "success") ||
      (un_shortlist_id === member_id && unshortlistMemberStatus === "success")
    ) {
      dispatch(dynamicSet(`shortlist-${member_id}-loading`, false));
      let _member_details = JSON.parse(JSON.stringify(member_details));
      if (_member_details) {
        _member_details.is_shortlisted = shortlistMemberStatus === "success";
        dispatch(dynamicSet(`member`, _member_details));
      }
      dispatch(dynamicClear(`shortlist-${member_id}-loading`));
    }
  }, [shortlistMemberStatus, unshortlistMemberStatus]);

  useEffect(() => {
    if (request_id === member_id && send_request_status === "success") {
      dispatch(dynamicSet(`request-${member_id}-loading`, false));
      let _member_details = JSON.parse(JSON.stringify(member_details));
      if (_member_details) {
        if (_member_details?.member_interest_status?.status === "no_comm") {
          _member_details.member_interest_status.status = "reminder_sent";
        } else if (
          _member_details.member_interest_status.status ===
          "your_interest_pending"
        ) {
          _member_details.member_interest_status.status = "reminder_sent";
        }
        dispatch(dynamicSet(`member`, _member_details));
      }

      dispatch(dynamicClear(`request-${member_id}-loading`));
    }
  }, [send_request_status]);

  useEffect(() => {
    if (send_request_to_add_photo_status === "success") {
      toast.success(t("request_sent"));
      dispatch(dynamicClear("send_request_add_photo"));
    } else if (send_request_to_add_photo_error) {
      toast.error(send_request_to_add_photo_error?.message);
      dispatch(dynamicClear("send_request_add_photo"));
    }
  }, [send_request_to_add_photo_status, send_request_to_add_photo_error]);

  useEffect(() => {
    if (member_requests_list) {
      let request_photo = member_requests_list?.filter(
        (x) => x.type === "photo"
      );

      setPhotoResponse(request_photo);
    }
  }, [member_requests_list]);
  return (
    <>
      {loading ? (
        <Box flex={1} width={"100%"} height={"100%"} backgroundColor={"#fff"}>
          <Box my={2} width={"100%"}>
            <HStack mx={2}>
              <Pressable flex={1} alignItems={"center"}>
                <Skeleton height={300} width={300}></Skeleton>

                <Skeleton.Text
                  alignItems={"center"}
                  my={2}
                  width={"30%"}
                  lines={1}
                />
                <Divider my={2} />
              </Pressable>
            </HStack>
          </Box>
        </Box>
      ) : (
        <Box flex={1} width={"100%"} height={"100%"} backgroundColor={"#fff"}>
          <Box my={2} width={"100%"}>
            <HStack mx={2}>
              <Pressable flex={1} alignItems={"center"}>
                {cover_photo?.photo_view === "hidden" && (
                  <Popconfirm
                    title="Send request to view photo?"
                    onConfirm={() => {
                      sendRequest(_member_id, "photo");
                    }}
                  >
                    <Box height={300} width={300}>
                      <_Image
                        // className="ant-image-mask-info"
                        onClick={() => {
                          // setVisible(true);
                        }}
                        width={300}
                        height={300}
                        style={{
                          objectFit: "cover",
                          zIndex: -999,
                        }}
                        src={getProfileUrl(
                          cover_photo?.photo_url,
                          member_profile?.gender
                        )}
                        preview={{ visible: false }}
                      />
                      <Box
                        height={300}
                        width={300}
                        position={"absolute"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Box
                          borderRadius={5}
                          px={5}
                          py={2}
                          backgroundColor={"#712e5a"}
                        >
                          <Text color={"#fff"}>
                            {getMemberCoverPhotoResponse(photo_response)}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Popconfirm>
                )}
                {!cover_photo?.photo_view && (
                  <Popconfirm
                    title="Send request to add photo?"
                    onConfirm={() => {
                      sendRequestToAddPhoto(_member_id, "add_photo");
                    }}
                  >
                    <_Image
                      width={300}
                      height={300}
                      style={{
                        objectFit: "cover",
                        zIndex: -999,
                      }}
                      src={
                        member_profile?.gender === "f"
                          ? "https://assets.blr1.vultrobjects.com/female-avatar.png"
                          : "https://assets.blr1.vultrobjects.com/male-avatar.png"
                      }
                      preview={{ visible: false }}
                    />
                  </Popconfirm>
                )}
                {cover_photo?.photo_view === "allowed" && (
                  <_Image
                    // className="ant-image-mask-info"
                    onClick={() => {
                      setVisible(true);
                    }}
                    width={300}
                    height={300}
                    style={{
                      objectFit: "cover",
                      zIndex: -999,
                    }}
                    src={getCroppedImageUrl(
                      cover_photo?.cropped_photo_url,
                      member_profile?.gender
                    )}
                    // src={getProfileUrl(
                    //   cover_photo?.photo_url,
                    //   member_profile?.gender
                    // )}
                    preview={{ visible: false }}
                  />
                )}

                <Box alignItems={"center"}>
                  <Text bold color={"#712e5a"}>
                    {member_profile?.name}
                  </Text>
                </Box>

                <Box alignItems={"center"}>
                  <Text bold color={"#712e5a"}>
                    {`(${member_root?.profile_number})`}
                  </Text>
                </Box>
                <Divider my={2} />
              </Pressable>

              <div style={{ display: "none" }}>
                <Image.PreviewGroup
                  preview={{
                    visible,
                    onVisibleChange: (vis) => setVisible(vis),
                  }}
                >
                  {_photoDetails?.map((x, index) => {
                    return (
                      <Image
                        key={index}
                        onClick={() => {}}
                        // width={300}
                        // height={300}
                        style={{
                          objectFit: "cover",
                        }}
                        src={getProfileUrl(x.photo_url, member_profile?.gender)}
                        preview={{
                          src: getProfileUrl(
                            x.photo_url,
                            member_profile?.gender
                          ),
                        }}
                      />
                    );
                  })}
                </Image.PreviewGroup>
              </div>
            </HStack>
            <Box height={"20%"} mx={2}>
              <HStack width={"100%"} alignItems={"center"}>
                <Pressable
                  width={"50%"}
                  justifyContent={"center"}
                  cursor={"pointer"}
                  onPress={() => {
                    member_interest_status?.status === "no_comm"
                      ? sendRequest(_member_id, "interest")
                      : member_interest_status?.status === "connected"
                      ? ""
                      : member_interest_status?.status === "you_rejected"
                      ? ""
                      : member_interest_status?.status === "member_rejected"
                      ? ""
                      : member_interest_status?.status === "reminder_sent"
                      ? ""
                      : member_interest_status?.status ===
                        "your_interest_pending"
                      ? sendRequest(_member_id, "interest")
                      : member_interest_status?.status ===
                          "member_interest_pending" && navigate("/requests");
                  }}
                  flexDirection="row"
                  padding={"2px"}
                  alignItems="center"
                >
                  <Box justifyContent="center">
                    {member_interest_status?.status === "no_comm" ? (
                      <HStack>
                        {request_loading === true ? (
                          <Spin size="md" />
                        ) : (
                          <AiOutlineHeart size={20} color={"#712e5a"} />
                        )}

                        <Text paddingLeft={1} fontSize={14}>
                          {t("send_interest")}
                        </Text>
                      </HStack>
                    ) : member_interest_status?.status === "connected" ? (
                      <HStack alignItems={"center"}>
                        <FaHandshake size={18} color={"#e0bc96"} />
                        <Text paddingLeft={1} fontSize={14}>
                          {t("connected")}
                        </Text>
                      </HStack>
                    ) : member_interest_status?.status === "you_rejected" ? (
                      <Text paddingLeft={1} fontSize={12}>
                        {t("accept_request")}
                      </Text>
                    ) : member_interest_status?.status === "member_rejected" ? (
                      <Text paddingLeft={1} fontSize={12}>
                        {t("interest_rejected")}
                      </Text>
                    ) : member_interest_status?.status === "reminder_sent" ? (
                      <HStack alignItems={"center"}>
                        <AiOutlineClockCircle size={18} />
                        <Text paddingLeft={1} fontSize={14}>
                          {t("awaiting_response")}
                        </Text>
                      </HStack>
                    ) : member_interest_status?.status ===
                      "your_interest_pending" ? (
                      <HStack alignItems={"center"}>
                        {request_loading === true ? (
                          <Spin size="md" />
                        ) : (
                          <RiSendPlaneFill size={18} color={"#712e5a"} />
                        )}
                        <Text paddingLeft={1} fontSize={12}>
                          {t("send_reminder")}
                        </Text>
                      </HStack>
                    ) : (
                      member_interest_status?.status ===
                        "member_interest_pending" && (
                        <HStack alignItems={"center"}>
                          <AiOutlineHeart size={15} color={"#712e5a"} />
                          <Text paddingLeft={1} fontSize={12}>
                            {t("accept_interest")}
                          </Text>
                        </HStack>
                      )
                    )}
                  </Box>
                </Pressable>
                <Divider mx={1} orientation="vertical" />

                <Pressable
                  width={"50%"}
                  cursor={"pointer"}
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Pressable
                    onPress={() => {
                      shortlist_loading === true
                        ? ""
                        : member_details?.is_shortlisted
                        ? unShortlistMemberMutate(_member_id)
                        : shortlistMemberMutate(_member_id);
                    }}
                  >
                    <HStack alignItems={"center"}>
                      {shortlist_loading === true ? (
                        <Spin size={10} />
                      ) : member_details?.is_shortlisted ? (
                        <AiFillStar size={20} color={"#FF1493"} />
                      ) : (
                        <AiOutlineStar size={20} color={"#FF1493"} />
                      )}

                      <Box justifyContent="center">
                        {member_details?.is_shortlisted ? (
                          <Text paddingLeft={"5px"} fontSize={14}>
                            {t("shortlisted")}
                          </Text>
                        ) : (
                          <Text paddingLeft={"5px"} fontSize={14}>
                            {t("shortlist")}
                          </Text>
                        )}
                      </Box>
                    </HStack>
                  </Pressable>
                </Pressable>
              </HStack>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default MemberCover;

import {
  Box,
  Card,
  Divider,
  Hidden,
  HStack,
  Skeleton,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input } from "antd";
import "../../../index.css";

import { useDispatch } from "react-redux";
import { CasteOptions } from "@master_data/common_json/castes";
import { SubCastesOptions } from "@master_data/common_json/sub_castes";
import { MaritalStatusOptions } from "@master_data/common_json/marital_status";
import { ReligionsOptions } from "@master_data/common_json/religions";

const FranchiseFilterFormReligionDetails = (props) => {
  const { getOptions, form, initial_values } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [caste_options, setCasteOptions] = useState([]);
  const [sub_caste_options, setSubCasteOptions] = useState([]);
  const caste = Form.useWatch("caste", form);
  const sub_caste = Form.useWatch("sub_caste", form);
  const religion = Form.useWatch("religion", form);

  useEffect(() => {
    let _options = [];
    if (CasteOptions) {
      _options = CasteOptions;
    }
    if (religion && CasteOptions) {
      _options = _options?.filter(
        (x) => x.p_o.map((x) => x).indexOf(religion) > -1
      );
    }
    // _options = _options?.filter((x) => x?.number_value !== -1);
    setCasteOptions(_options);
    let index = _options.map((x) => x.value).indexOf(caste);
    if (index === -1) {
      form.setFields([
        {
          name: "caste",
          value: null,
        },
      ]);
    }
  }, [religion, CasteOptions]);

  useEffect(() => {
    let _options = [];
    if (SubCastesOptions) {
      _options = SubCastesOptions;
    }
    if (caste && SubCastesOptions) {
      _options = _options?.filter(
        (x) => x.p_o.map((x) => x).indexOf(caste) > -1
      );
    }
    // _options = _options?.filter((x) => x?.number_value !== -1);

    // _options = [{ id: null, en_label: "Dont Know" }, ..._options];
    setSubCasteOptions(_options);
    let index = _options.map((x) => x.value).indexOf(sub_caste);
    if (index === -1) {
      form.setFields([
        {
          name: "sub_caste",
          value: null,
        },
      ]);
    }
  }, [caste, SubCastesOptions]);
  return (
    <>
      {/* {options_loading ? (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("religion_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <Hidden from="md">
            <Box width={"100%"}>
              <Skeleton.Text lines={5} />
            </Box>
          </Hidden>
          <Hidden till="md">
            <HStack space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
          </Hidden>
        </Card>
      ) : ( */}
      <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
        <VForm.Select
          label={t("gender")}
          field={"gender"}
          options={[
            {
              label: "Male",
              id: "m",
            },
            {
              label: "Female",
              id: "f",
            },
          ]}
          labelField={"label"}
          valueField={"id"}
          form_type={"profile"}
          allowClear
        />
        <VForm.Select
          label={t("marital_status")}
          field={"marital_status"}
          options={getOptions(MaritalStatusOptions, "Marital Status")}
          labelField={"en_label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
        />
        <VForm.Select
          label={t("religion")}
          field={"religion"}
          options={getOptions(ReligionsOptions, "Religion")}
          labelField={"en_label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
        />
        <VForm.Select
          label={t("caste")}
          field={"caste"}
          options={caste_options}
          labelField={"en_label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
        />
        <VForm.Select
          label={t("sub_caste")}
          field={"sub_caste"}
          options={sub_caste_options}
          labelField={"en_label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
        />
      </Card>
      {/* )} */}
    </>
  );
};
export default FranchiseFilterFormReligionDetails;

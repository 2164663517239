import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button, Spin, TimePicker } from "antd";
import { useDispatch } from "react-redux";

import {
  dynamicRequest,
  create_verifier_mutation,
  update_verifier_mutation,
  useDynamicSelector,
  update_member_mutation,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { Box, Center, HStack, Text, useBreakpointValue } from "native-base";
import "../../../index.css";
import moment from "moment";
import { CasteOptions } from "@master_data/common_json/castes";
import { SubCastesOptions } from "@master_data/common_json/sub_castes";
import { ReligionsOptions } from "@master_data/common_json/religions";
import { EducationsOptions } from "@master_data/common_json/educations";
import { AnnualIncomeOptions } from "@master_data/profile_json/annual_income";
import {
  getAnnualIncomeOptions,
  getDistrictsList,
  getStatesList,
} from "@helpers/functions";
import { CountriesOptions } from "@master_data/common_json/countries";
import { StatesOptions } from "@master_data/common_json/states";
import { CreatedForOptions } from "@master_data/profile_json/created_for";
import { DistrictsOptions } from "@master_data/common_json/districts";

const ManageMembersDetailsAdmin = (props) => {
  const { t } = useTranslation();
  const { initialValues, type } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const format = "HH:mm";

  const [countries_loading, setCountriesLoading] = useState(false);
  const [states_loading, setStatesLoading] = useState(false);
  const [districts_loading, setDistrictsLoading] = useState(false);
  const [caste_options, setCasteOptions] = useState([]);
  const [sub_caste_options, setSubCasteOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [districts_for_birth, setDistrictsForBirth] = useState([]);
  const [time, setTime] = useState("00:00");
  const [district_value, setDistrictValue] = useState("");

  const caste = Form.useWatch("caste", form);
  const sub_caste = Form.useWatch("sub_caste", form);
  const religion = Form.useWatch("religion", form);
  const country = Form.useWatch("country", form);
  const state = Form.useWatch("state", form);

  const { loading: updateLoading } = useDynamicSelector("updateMember");
  const { loading: members_list_loading } = useDynamicSelector(
    "manage_members_list"
  );

  const getOptions = (all_options, element_name, parent_key) => {
    let _options = all_options?.filter((x) => x.value !== "any");

    if (parent_key) {
      let parent_element_option = eval(parent_key);
      _options = _options?.filter(
        (x) => x.p_o.indexOf(parent_element_option) > -1
      );
    }

    return _options;
  };
  useEffect(() => {
    let _options = [];
    if (CasteOptions) {
      _options = CasteOptions;
    }
    if (religion && CasteOptions) {
      _options = _options?.filter(
        (x) => x.p_o.map((x) => x).indexOf(religion) > -1
      );
    }
    _options = _options?.filter((x) => x?.number_value !== -1);
    setCasteOptions(_options);
    let index = _options.map((x) => x.value).indexOf(caste);

    if (index === -1) {
      form.setFields([
        {
          name: "caste",
          value: null,
        },
      ]);
    }
  }, [religion, CasteOptions]);

  useEffect(() => {
    let _options = [];
    if (SubCastesOptions) {
      _options = SubCastesOptions;
    }
    if (caste && SubCastesOptions) {
      _options = _options?.filter(
        (x) => x.p_o.map((x) => x).indexOf(caste) > -1
      );
    }

    _options = _options?.filter((x) => x?.number_value !== -1);
    setSubCasteOptions(_options);
    let index = _options.map((x) => x.value).indexOf(sub_caste);
    if (index === -1) {
      form.setFields([
        {
          name: "sub_caste",
          value: null,
        },
      ]);
    }
  }, [caste, SubCastesOptions]);

  useEffect(() => {
    let _districts = [];
    if (DistrictsOptions) {
      try {
        _districts = DistrictsOptions?.map((x) => ({
          label: x.name,
          value: x.name,
        }));
        _districts.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
      } catch (e) {}
    }
    setDistrictsForBirth(_districts);
  }, [DistrictsOptions]);

  useEffect(() => {
    let temp_district = DistrictsOptions?.find(
      (x) => x.name === initialValues?.place_of_birth
    );
    if (temp_district?.name) {
      setDistrictValue(temp_district?.name);
    } else {
      setDistrictValue("");
    }
    form.setFieldsValue({
      ...initialValues,
      name: initialValues?.name,
      gender: initialValues?.gender,
      religion: initialValues?.religion,
      caste: initialValues?.caste,
      created_for: initialValues?.created_for,

      sub_caste: initialValues?.sub_caste,
      education: initialValues?.education,
      country: initialValues?.country,
      state: initialValues?.state,
      district: initialValues?.district,
      place_of_birth: district_value || "",
      annual_income: getAnnualIncomeOptions(initialValues),

      dob: moment(initialValues?.dob),
    });
    setTime(initialValues?.time_of_birth);
  }, [initialValues, district_value, type]);
  const getAnnualIncomeRange = (value) => {
    let range = [];
    let income_data = AnnualIncomeOptions?.find((x) => x.en_label === value);
    range.push({
      r_f_v: income_data?.r_f_v,
      r_t_v: income_data?.r_t_v,
    });
    return range;
  };
  const handleSubmit = (values) => {
    values = {
      ...values,
      annual_income_from: getAnnualIncomeRange(values?.annual_income)?.[0]
        ?.r_f_v,
      annual_income_to: getAnnualIncomeRange(values?.annual_income)?.[0]?.r_t_v,
      time_of_birth: time,
    };
    delete values.annual_income;
    if (initialValues.id) {
      let keys = [{ key: "updateMember", loading: true }];
      dispatch(
        dynamicRequest(
          keys,
          update_member_mutation,
          {
            id: initialValues?.id,
            data: values,
          },
          "M"
        )
      );
    }
  };
  const columns = useBreakpointValue({
    base: 1,
    md: 2,
  });

  useEffect(() => {
    setCountriesLoading(true);
    let _countries = [];
    try {
      _countries = CountriesOptions;
    } catch (e) {}
    _countries = _countries?.map((x) => ({
      name: x.name,
      value: _.snakeCase(x.i_c),
    }));
    setCountries(_countries);
    setCountriesLoading(false);
  }, []);

  useEffect(() => {
    setStatesLoading(true);
    let _states = [];
    if (country) {
      try {
        _states = getStatesList(country);
        _states.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      } catch (e) {}
    }
    setStates(_states);
    setStatesLoading(false);
  }, [country]);

  useEffect(() => {
    setDistrictsLoading(true);
    let _districts = [];
    if (country && state) {
      try {
        _districts = getDistrictsList(country, state);
        _districts.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      } catch (e) {}
    }
    setDistricts(_districts);
    setDistrictsLoading(false);
  }, [state]);

  const handleStateChange = (val) => {
    if (val) {
      form.setFieldsValue({
        ...initialValues,
        district: null,
      });
    }
  };
  return (
    <Form
      form={form}
      name={"manage_members"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.Grid columns={columns} spacingY={10} spacingX={10}>
        <VForm.TextBox
          label={t("name")}
          field={"name"}
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        />

        <VForm.Date
          label={t("dob")}
          field={"dob"}
          show_today={false}
          rules={[
            {
              required: true,
              message: "Date of Birth is required",
            },
          ]}
        />
        {/* <VForm.Time
          label={t("birth_time")}
          field={"time_of_birth"}
          rules={[
            {
              required: true,
              message: "Birth Time is required",
            },
          ]}
        /> */}
        <Box paddingTop={2} paddingBottom={2}>
          <Text color={"#712e5a"} fontWeight={"600"} fontSize={15}>
            Time of Birth
          </Text>
          <TimePicker
            defaultValue={moment(time, format)}
            format={format}
            onChange={(value, dateString) => {
              setTime(dateString);
            }}
          />
        </Box>
        <VForm.Select
          label={t("place_of_birth_value", {
            name: district_value ? "" : initialValues?.place_of_birth,
          })}
          field={"place_of_birth"}
          options={districts_for_birth}
          labelField={"label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
          showSearch
        />

        <VForm.Select
          label={t("gender")}
          field={"gender"}
          options={[
            {
              name: "Female",
              id: "f",
            },
            {
              name: "Male",
              id: "m",
            },
          ]}
          rules={[
            {
              required: initialValues?.id ? false : true,
              message: "Gender is required",
            },
          ]}
        />
        <VForm.Select
          label={t("created_for")}
          field={"created_for"}
          options={getOptions(CreatedForOptions, "Created For")}
          labelField={"en_label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
        />
        <VForm.Select
          label={t("religion")}
          field={"religion"}
          options={getOptions(ReligionsOptions, "Religion")}
          labelField={"en_label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
        />
        <VForm.Select
          label={t("caste")}
          field={"caste"}
          options={caste_options}
          labelField={"en_label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
        />
        <VForm.Select
          label={t("sub_caste")}
          field={"sub_caste"}
          options={sub_caste_options}
          labelField={"en_label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
        />
        <VForm.Select
          label={t("education")}
          field={"educations"}
          options={getOptions(EducationsOptions, "Education")}
          labelField={"en_label"}
          valueField={"value"}
          form_type={"profile"}
          allowClear
          isMulti
        />
        <VForm.Select
          label={t("annual_income")}
          field={"annual_income"}
          options={getOptions(AnnualIncomeOptions, "Annual Income")}
          labelField={"en_label"}
          valueField={"en_label"}
          form_type={"profile"}
          allowClear
        />
        <VForm.Select
          label={t("country")}
          field={"country"}
          loading={countries_loading}
          showSearch={true}
          options={countries}
          labelField={"name"}
          valueField={"value"}
          form_type={"profile"}
          rules={[
            {
              required: true,
              message: "Country is required",
            },
          ]}
        />
        <VForm.Select
          label={t("state")}
          field={"state"}
          loading={states_loading}
          showSearch={true}
          onChange={handleStateChange}
          options={states}
          labelField={"name"}
          valueField={"i_c"}
          form_type={"profile"}
          rules={[
            {
              required: true,
              message: "State is required",
            },
          ]}
        />
        <VForm.Select
          label={t("district")}
          field={"district"}
          loading={districts_loading}
          showSearch={true}
          options={districts}
          labelField={"name"}
          valueField={"value"}
          form_type={"profile"}
          rules={[
            {
              required: true,
              message: "District is required",
            },
          ]}
        />
      </VForm.Grid>
      <HStack space="3" justifyContent={"flex-end"}>
        <Button onClick={props.close}>{t("close")}</Button>
        <VForm.Button isLoading={updateLoading} disabled={false}>
          {t("Submit")}
        </VForm.Button>
      </HStack>
    </Form>
  );
};
export default ManageMembersDetailsAdmin;

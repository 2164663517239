import { retrieveItem } from "@helpers/storage";
import { useDynamicSelector } from "@services/redux";
import { Input } from "antd";
import { Box, Text, ScrollView, Hidden } from "native-base";
import React, { useEffect, useState } from "react";
import UserRequestMembers from "./user_request_members";

// import ScrollView from "@views/components/ui/scroll_view/scroll_view";

const UserRequestGroup = (props) => {
  const { getUserRequests, onResponsiveClose, setRequestGroupId } = props;
  const { Search } = Input;

  const [response, setResponse] = useState();
  const [filter_response, setFilterResponse] = useState([]);
  const [requested_groups, setRequestedGroups] = useState();

  const [group_members, setGroupMembers] = useState([]);
  const [_user_id, setUserID] = useState("");

  const { items: request_groups } = useDynamicSelector("request_groups");
  let filter_parent_result;

  useEffect(() => {
    let login_user_id = retrieveItem("user_id");
    setUserID(login_user_id);
  }, []);

  const getReceivedList = (_data) => {
    if (_data) {
      let received_list = filter_response.filter((x) => {
        return x.has_received === true;
      });
      received_list = received_list.map((res, _ind) => {
        return res.member
          ?.filter((y) => {
            return y?.id !== _user_id;
          })
          .map((obj) => {
            return { ...obj, group_id: res.id };
          });
      });
      setResponse(received_list);
    }
  };

  const getSentList = (_data) => {
    if (_data) {
      let sent_list = filter_response.filter((x) => {
        return x.has_sent === true;
      });

      sent_list = sent_list.map((res, _ind) => {
        return res.member
          ?.filter((y) => {
            return y?.id !== _user_id;
          })
          .map((obj) => {
            return { ...obj, group_id: res.id };
          });
      });
      setResponse(sent_list);
    }
  };

  const getAllList = (_data) => {
    if (_data) {
      filter_parent_result = filter_response.map((res, _ind) => {
        return res.member
          ?.filter((y) => {
            return y?.id !== _user_id;
          })
          .map((obj) => {
            return { ...obj, group_id: res.id };
          });
      });
      setResponse(filter_parent_result);
    }
  };
  useEffect(() => {
    if (request_groups) {
      filter_parent_result = request_groups.map((res, _ind) => {
        return res.member
          ?.filter((y) => {
            return y?.id !== _user_id;
          })
          .map((obj, _index) => {
            return { ...obj, group_id: res.id };
          });
      });
      setGroupMembers(filter_parent_result);
      setResponse(filter_parent_result);
      let _filter_response = request_groups?.map((x, _ind) => {
        return x;
      });
      setFilterResponse(_filter_response);
    }
  }, [request_groups]);

  const search = async (value) => {
    if (value.length) {
      let search_response = await group_members?.filter((_member) =>
        _member?.[0]?.member_profile?.name
          .toLowerCase()
          .includes(value.toLowerCase())
      );
      setResponse(search_response);
    } else if (value.length === 0) {
      setResponse(group_members);
    }
  };

  useEffect(() => {
    if (request_groups) {
      setRequestedGroups(request_groups);
    }
  }, [request_groups]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      backgroundColor={"#712e5a"}
    >
      <Box my={5} width={"90%"}>
        <Search
          allowClear
          onChange={(e) => {
            search(e.target.value);
          }}
          placeholder="search"
          onSearch={(val) => {
            search(val);
          }}
        />
      </Box>

      <ScrollView showsVerticalScrollIndicator={false} width={"100%"}>
        <Box alignItems={"center"} width={"100%"}>
          <UserRequestMembers
            setRequestGroupId={setRequestGroupId}
            getUserRequests={getUserRequests}
            data={response}
            onResponsiveClose={onResponsiveClose}
            getAllList={getAllList}
            getReceivedList={getReceivedList}
            getSentList={getSentList}
          />
        </Box>

        {/* <Box w={"90%"} backgroundColor={"#712e5a"} h={100}></Box> */}
      </ScrollView>
    </Box>
  );
};
export default UserRequestGroup;

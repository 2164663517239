import { navigateBack } from "@helpers/navigator";
import {
  dynamicRequest,
  get_user_subscription_plan,
  useDynamicSelector,
} from "@services/redux";
import SubscriptionPlanList from "@views/components/user_subscription/subscription_plan_list";
import SubscriptionPlanListWebView from "@views/components/user_subscription/subscription_plan_web_view";
import { Box, Center, Hidden, Pressable, Spinner, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch } from "react-redux";
const UserSubscription = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { loading: plan_list_loading } = useDynamicSelector(
    "getSubscriptionPlans"
  );

  useEffect(() => {
    let get_plan_key = [{ key: "getSubscriptionPlans", loading: true }];
    let get_plan_query = get_user_subscription_plan;
    let get_plan_variables = null;
    dispatch(dynamicRequest(get_plan_key, get_plan_query, get_plan_variables));
  }, []);

  return (
    <>
      {plan_list_loading ? (
        <Center bgImage={"linear-gradient(234deg , #F0ECFC, #B6739F)"} flex={1}>
          <Spinner size={"lg"} color={"#712e5a"} />
        </Center>
      ) : (
        <Box
          alignItems={"center"}
          justifyContent={"center"}
          bgImage={"linear-gradient(234deg , #F0ECFC, #B6739F)"}
          flex={1}
        >
          <Box
            shadow={1}
            height={30}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100vw"}
          >
            <Pressable
              onPress={() => {
                navigateBack();
              }}
              cursor={"pointer"}
              position={"absolute"}
              left={5}
            >
              <Box
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"#712e5a"}
                width="50px"
                height="25px"
                borderRadius={5}
                left={0}
                shadow={1}
              >
                <Text fontSize={12} color={"#fff"}>
                  {"Back"}
                </Text>
              </Box>
              {/* <AiOutlineArrowLeft size={20} color={"#111"} /> */}
            </Pressable>
            <Text color={"#712e5a"} fontSize={16} bold>
              {t("subscription_plans").toUpperCase()}
            </Text>
          </Box>
          <Hidden from="lg">
            <Box>
              <SubscriptionPlanList />
            </Box>
          </Hidden>
          <Hidden till="lg">
            <Box>
              <SubscriptionPlanListWebView />
            </Box>
          </Hidden>
        </Box>
      )}
    </>
  );
};
export default UserSubscription;

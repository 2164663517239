import { gql } from "@apollo/client";
export const get_all_promo_Code_query = gql`
  query getAllPromoCodes($page_number: Float, $page_limit: Float) {
    get_all_promo_codes: getAllPromoCodes(
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        code
        franchise_id
        discount_percentage
        franchise {
          id
          name
        }
      }
    }
  }
`;
export const create_promo_code_franchises_mutation = gql`
  mutation createPromoCode($data: promo_code_input) {
    create_promo_code: createPromoCode(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const get_promo_code_franchises_query = gql`
  query getPromoCodeByFranchiseId($franchise_id: String!) {
    get_promo_code_by_franchises: getPromoCodeByFranchiseId(
      franchise_id: $franchise_id
    ) {
      items {
        id
        name
        code
        discount_percentage
      }
    }
  }
`;
export const update_promo_code_franchise_mutation = gql`
  mutation updatePromoCode($id: String!, $data: promo_code_input) {
    update_promo_code: updatePromoCode(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_promo_code_franchise_mutation = gql`
  mutation deletePromoCode($id: String!) {
    delete_promo_code: deletePromoCode(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, HStack } from "native-base";

import { useParams } from "react-router-dom";
import {
  dynamicClear,
  dynamicRequest,
  getMember,
  getMemberForAdmin,
  getMyPrivacySettings,
  get_matches_count_query,
  get_user_name,
  useDynamicSelector,
} from "@services/redux";
import UserPhotos from "@views/components/user_manage_profile/user_photos";
import MemberDetailsCardViewTeleCaller from "@views/components/admin_verification/member_details_card_view_in_tele_caller";
import { Button } from "antd";
import { navigateBack } from "@helpers/navigator";
import { useTranslation } from "react-i18next";
import UserPrivacySettingsTeleCaller from "./user_privacy_settings_telecaller";
import { retrieveItem, storeItem } from "@helpers/storage";

const UserPhotosPrivacyUpdate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const role = retrieveItem("role");

  const { session_id } = useDynamicSelector("getUserSession");
  const { user_profile } = useDynamicSelector("get_name");
  const { total_count } = useDynamicSelector("getMatchesCount");

  const get_user_name_query = () => {
    let key = [{ key: "get_name", loading: true }];
    let query = get_user_name;
    let variables = null;
    dispatch(dynamicRequest(key, query, variables));
  };
  const get_matches_count = () => {
    let key = [{ key: "getMatchesCount", loading: true }];
    let query = get_matches_count_query;
    let variables = null;
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_my_privacy_settings = () => {
    let get_privacy_key = [{ key: "my_privacy_settings", loading: true }];
    let get_privacy_query = getMyPrivacySettings;
    dispatch(dynamicRequest(get_privacy_key, get_privacy_query));
  };

  useEffect(() => {
    if (session_id) {
      storeItem("user_session_id", session_id);
      // dispatch(dynamicClear("getUserSession"));

      get_user_name_query();
      get_matches_count();
      get_my_privacy_settings();
    }
  }, [session_id]);

  return (
    <>
      <Box mx={5} my={5} alignItems={"flex-start"}>
        <Button
          loading={false}
          type="default"
          onClick={() => {
            navigateBack();
          }}
        >
          {t("back")}
        </Button>
      </Box>
      <Box width="100%">
        {user_profile?.name && (
          <Box mx={5}>
            <Text fontSize={30} color={"#712e5a"}>
              {user_profile?.name}
            </Text>
            {total_count && (
              <Text
                fontSize={25}
                color={"#712e5a"}
              >{`Total Matches ( ${total_count} )`}</Text>
            )}
          </Box>
        )}
        <Box mx={5} alignItems={"center"}>
          <UserPhotos custom_width={"100%"} />
        </Box>

        {role === "Verifier" && <UserPrivacySettingsTeleCaller />}
      </Box>
    </>
  );
};
export default UserPhotosPrivacyUpdate;

import {
  Box,
  Card,
  Divider,
  Hidden,
  HStack,
  Image,
  Menu,
  Pressable,
  Text,
  Tooltip,
  VStack,
} from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import style from "./style";

import { useTranslation } from "react-i18next";
import "../../../index.css";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";

import { useDispatch } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { gql } from "@apollo/client";
import { Popconfirm, Spin, Upload } from "antd";
import {
  AiFillCrown,
  AiFillDelete,
  AiOutlineCrown,
  AiOutlineFileImage,
  AiOutlinePicture,
} from "react-icons/ai";
import { QueryRequest } from "@services/apollo/api_service";
import toast from "react-hot-toast";
import {
  deleteMyPhoto,
  dynamicClear,
  dynamicRequest,
  fileQuery,
  getMyPhoto,
  updateMyPhoto,
  useDynamicSelector,
} from "@services/redux";
import { getUUID } from "@helpers/uuid";
import { useRef } from "react";
import { BsFileImage, BsThreeDotsVertical } from "react-icons/bs";
import { MdAssistantPhoto } from "react-icons/md";
import { IMAGE_URL } from "@helpers/constants";
import colors from "@helpers/colors";
let fileUploadresponse;

const UserPhotosOperator = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [fileList, setFileList] = useState([]);
  const [headers, setHeaders] = useState();
  const [uuid, setUUID] = useState();
  const [hover, setHover] = useState(false);
  const [currentID, setCurrentId] = useState();
  const action = fileUploadresponse?.data?.getUploadUrl;

  const { status: photoStatus, loading: update_photo_loading } =
    useDynamicSelector("updateMyPhoto");
  const { status: removePhotoStatus, loading: delete_photo_loading } =
    useDynamicSelector("deleteMyPhoto");
  const { user_photos: userPhotos } = useDynamicSelector("my_photo");
  const { status: get_file_upload_status, url: get_response_url } =
    useDynamicSelector("getUploadUrl");

  const UserCustomPhotos = React.memo(function Image({ src, style }) {
    return <img src={src} style={style} width={100} height={100} />;
  });
  useEffect(() => {
    if (removePhotoStatus === "success") {
      toast.success(t("photo_removed_successfully"));
      getMyPhotos();

      dispatch(dynamicClear("deleteMyPhoto"));
    }
    if (photoStatus === "success") {
      toast.success(t("photo_updated_successfully"));
      getMyPhotos();
      dispatch(dynamicClear("updateMyPhoto"));
    }
  }, [removePhotoStatus, photoStatus]);

  const getMyPhotos = () => {
    let get_my_photo_key = [{ key: "my_photo", loading: true }];
    let get_my_photo_query = getMyPhoto;
    let get_my_photo_variables = {};
    dispatch(
      dynamicRequest(
        get_my_photo_key,
        get_my_photo_query,
        get_my_photo_variables
      )
    );
  };
  useEffect(() => {
    getMyPhotos();
  }, []);

  useEffect(() => {
    let fileLists = [];
    if (userPhotos) {
      for (var i = 0; i < userPhotos?.length; i++) {
        fileLists.push({
          uid: userPhotos?.[i]?.id,
          verification_status: userPhotos?.[i]?.verification_status,
          url: userPhotos?.[i]?.photo_url,
          is_main_photo: userPhotos?.[i]?.is_main_photo,
        });
      }

      setFileList([...fileLists]);
    }
  }, [userPhotos]);

  const profilePhotoUpload = (file) => {
    let file_name = file.url.substring(file.url.lastIndexOf("/") + 1);
    let data = {
      photo_url: file_name,
      id: file.uid,
    };
    let upload_photo_key = [{ key: "updateMyPhoto", loading: true }];
    let upload_photo_query = updateMyPhoto;
    let upload_photo_variables = { data: data };
    dispatch(
      dynamicRequest(
        upload_photo_key,
        upload_photo_query,
        upload_photo_variables,
        "M"
      )
    );
  };

  const profilePhotoDelete = (file) => {
    let delete_photo_key = [{ key: "deleteMyPhoto", loading: true }];
    let delete_photo_query = deleteMyPhoto;
    let delete_photo_variables = { id: file.uid };
    dispatch(
      dynamicRequest(
        delete_photo_key,
        delete_photo_query,
        delete_photo_variables,
        "M"
      )
    );
  };

  const setMainPhoto = (photo_file) => {
    let data = {
      id: photo_file.uid,
      // photo_url: photo_file.url,
      is_main_photo: true,
    };
    let upload_photo_key = [{ key: "updateMyPhoto", loading: true }];
    let upload_photo_query = updateMyPhoto;
    let upload_photo_variables = { data: data };
    dispatch(
      dynamicRequest(
        upload_photo_key,
        upload_photo_query,
        upload_photo_variables,
        "M"
      )
    );
  };

  const DragableUploadListItem = ({ originNode, file, fileList }) => {
    const userPhotos = (
      <Box>
        <HStack space={2}>
          <Pressable
            onMouseEnter={() => {
              setHover(true);
              setCurrentId(file.uid);
            }}
            onMouseLeave={() => {
              setHover(false);
              setCurrentId(-1);
            }}
          >
            <UserCustomPhotos
              style={
                file?.verification_status === "pending"
                  ? style.hover
                  : style.normal
              }
              src={file.url}
            />
            {hover &&
              currentID === file.uid &&
              file?.verification_status === "approved" && (
                <>
                  <Box position={"absolute"} top={1} left={1}>
                    <Tooltip
                      label={
                        file.is_main_photo === true
                          ? "Main Photo"
                          : "Set Main Photo"
                      }
                      bg="#712e5a"
                      _text={{
                        color: "#fff",
                      }}
                    >
                      <Box>
                        <Popconfirm
                          title={t("set_main_photo")}
                          onConfirm={() => {
                            setMainPhoto(file);
                          }}
                        >
                          <AiFillCrown
                            size={20}
                            color={
                              file.is_main_photo === true ? "#712e5a" : "#111"
                            }
                          />
                        </Popconfirm>
                      </Box>
                    </Tooltip>
                  </Box>
                  <Box position={"absolute"} top={1} right={1}>
                    <Tooltip
                      label={"Delete Photo"}
                      bg="#712e5a"
                      _text={{
                        color: "#fff",
                      }}
                    >
                      <Box>
                        <Popconfirm
                          title={t("delete_photo")}
                          onConfirm={() => {
                            profilePhotoDelete(file);
                          }}
                        >
                          <AiFillDelete size={20} />
                        </Popconfirm>
                      </Box>
                    </Tooltip>
                  </Box>
                </>
              )}

            {hover && currentID === file.uid && (
              <Box position={"absolute"} top={1} right={1}>
                <Tooltip
                  label={"Delete Photo"}
                  bg="#712e5a"
                  _text={{
                    color: "#fff",
                  }}
                >
                  <Box>
                    <Popconfirm
                      title={t("delete_photo")}
                      onConfirm={() => {
                        profilePhotoDelete(file);
                      }}
                    >
                      <AiFillDelete size={20} />
                    </Popconfirm>
                  </Box>
                </Tooltip>
              </Box>
            )}

            {(file?.verification_status === "pending" ||
              file?.verification_status === "rejected") && (
              <Box
                width={"100%"}
                alignItems={"center"}
                position={"absolute"}
                bottom={0}
                backgroundColor={"#712e5a"}
                opacity={0.8}
              >
                <Text textAlign={"center"} color={"#fff"}>
                  {file?.verification_status === "pending"
                    ? t("verification_pending")
                    : t("verification_rejected")}
                </Text>
              </Box>
            )}
          </Pressable>
        </HStack>
      </Box>
    );
    // const errorNode = (
    //   <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
    // );
    return <Box>{userPhotos}</Box>;
  };
  const uploadButton = (
    <div
      onClick={() => {
        setUUID(getUUID());
      }}
    >
      <img
        width={"100px"}
        style={{
          objectFit: "cover",
        }}
        src={
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAgVBMVEXw8PABFicAAAD39/cACyD6+fpJUVgAABiLjpHS0tP8/PzOzs8AABXs7e0ADyIAAAacnqAtOUMAABEcKjamp6pYX2UdJTCtsLN8gIQAABvk5eYAAA3Bw8UpND4gLTkyPUZqbnOWmZyGiYx6foMXHywYHyxrcHW4u73a3N1ASFEAECD3KgboAAACYElEQVR4nO3c63KaQBiAYWAxKBVijEbRYAxJc+j9X2DBnhbcTpFhT/Z9fjIfie+M4mGBIAAAAAAAAAAAAAAAAAAAAAAAAAAAAABwxcTuiy6ZsB138hjpM7Md10jLONSlWE9s59XS+1xbYXzjTGFyO77CpcL5KsjGFkwLhwqXq/EPemKaUGgIhUNRaA6FQ1FozjUWirTlUBdu0lHIPRYLxba8kzzVn5Dzw90YyqP0VLBXKKoob2m+BOTj2B//FNkrnNzo+z6YP6SuFMbz8dV/Nr93pTBeL8Z3LBwqTKapGFu6WjpVqOH9j0JjKByKQnMoHIpCcy4rFGnvST8LxXNZ9R31slBsojh67jnrZ+FLUX9K7/coPS3cUijNUmgMhTIKW7MUGkOhjMLWLIXGUCijsDXrcWH678HAk8LztYgfhd3NyoftQ6GoXh86XptVwe7Gr2/KvT0ofI/OFnVD5QJxtFDs7kGhWOx7LvUmWz8Lgyy53Xc0C+J5d+M++lDs7UNhkG261nGYf753t+58fR2eH0z/cixV7+tFYdf/8o5P4c9ZCo2hUEZha5ZCYyiUUdiapdAYCmUUtmY7hRM/CqtlWBwvKfz4faX67MXWNaQXnTG0+xYpf7JQaArDXLpS3dp1wBee19b7rgGnwg4vCntTFc6Vv6zqprUwb99SodyN/m960Hr25aG9xmHnthimzi+1h8KhKDSHwqHcKiwqDfcyWc0dKgwLDXdMSkKXCjVxpHBdaCuMSxcKg5mGZ+gvj7bjTkQ200W5OAwAAAAAAAAAAAAAAAAAAAAAAAAAAADganwH5zd6LobMFeIAAAAASUVORK5CYII="
        }
      />
      {/* <div> */}
      {/* <PlusOutlined />
      </div>
      <div
        style={{
          marginTop: 8,
        }}
      > */}
      {/* Upload */}
      {/* </div> */}
    </div>
  );

  const handleUpload = async ({ onSuccess, onError, file }) => {
    file.uid = file?.uid?.includes("rc") ? uuid : file.uid;
    axios
      .put(fileUploadresponse?.data?.getUploadUrl?.url, file, {
        headers: { "Content-Type": file?.type },
      })
      .then((res) => {
        if (res.status.toString() === "200") {
          file.url = fileUploadresponse?.data?.getUploadUrl?.url.split("?")[0];

          onSuccess(null, file);
          profilePhotoUpload(file);
        } else {
          onError(err, err, file);
        }
      })
      .catch((err) => {
        onError(err, err, file);
      });
  };
  const handleBeforeUpload = async ({ name, type }) => {
    let filenameuuid = uuid;
    let ext = name.split(".")[1];

    if (
      type === "image/png" ||
      type === "image/jpg" ||
      type === "image/jpeg" ||
      type === "image/bmp"
    ) {
      let fileName = `${filenameuuid}.${ext}`;
      let fileupload = {
        type: "photo",
        // uid: uuid,
        fileName: fileName,
      };

      fileUploadresponse = await QueryRequest(fileQuery, fileupload);
    } else {
      toast.error("Please choose a valid image file in JPG, PNG, JPEG, BMP");
    }
  };
  const handleFileChanged = ({ file }) => {
    file.uid = file?.uid?.includes("rc") ? uuid : file.uid;
    if (file.status === "removed") {
      // profilePhotoDelete(file);

      let list = [];
      let file_index = fileList.findIndex((x) => x.uid === file.uid);
      fileList.splice(file_index, 1);

      // setFileList([...fileList]);
    } else if (file.status === "uploading") {
      // setFileList([...fileList, file]);
    } else if (file.status === "done") {
      // debugger;
      const newFile = {
        ...file,
        uid: uuid,
        url: fileUploadresponse?.data?.getUploadUrl?.url.split("?")[0],
      };

      let list = fileList?.map((x) => (x.uid === newFile.uid ? newFile : x));
      // setFileList(list);
      // toast.success("Photo uploaded successfully");
    }
  };

  return (
    <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
      <Box my={5} mx={5} width={"100%"}>
        {update_photo_loading || delete_photo_loading ? (
          <Spin tip="Loading">
            <Upload
              accept="image/png, image/jpeg"
              // className="file-upload ant-upload-list-picture-card-container"

              style={{ position: "absolute", top: 0 }}
              onPreview={(e) => {
                // setMainPhoto(e);
              }}
              locale={{ previewFile: "Set Main Photo" }}
              showPreviewIcon={false}
              maxCount={1}
              listType="picture-card"
              action={action}
              headers={headers}
              fileList={fileList}
              showUploadList={{
                previewIcon: <AiOutlineCrown color="#fff" size={20} />,
                showDownloadIcon: false,
                showPreviewIcon: true,
                style: {
                  background: "#ffffff",
                },
              }}
              customRequest={(e) => handleUpload(e)}
              beforeUpload={(args) => handleBeforeUpload(args)}
              onChange={(e) => handleFileChanged(e)}
              itemRender={(originNode, file, fileList) => (
                <DragableUploadListItem
                  originNode={originNode}
                  file={file}
                  fileList={fileList}
                />
              )}
            >
              {fileList.length >= 5 ? null : uploadButton}
            </Upload>
          </Spin>
        ) : (
          <Upload
            accept="image/png, image/jpeg, image/jpg, image/bmp"
            // className="file-upload ant-upload-list-picture-card-container"
            // data={{ file: { uid: getUUID() } }}
            // data={{uid}}
            // id={getUUID()}
            style={{ position: "absolute", top: 0 }}
            onPreview={(e) => {
              setMainPhoto(e);
            }}
            locale={{ previewFile: "Set Main Photo" }}
            showPreviewIcon={false}
            maxCount={1}
            listType="picture-card"
            action={action}
            headers={headers}
            fileList={fileList}
            showUploadList={{
              previewIcon: <AiOutlineCrown color="#fff" size={20} />,
              showDownloadIcon: false,
              showPreviewIcon: true,
              style: {
                background: "#ffffff",
              },
            }}
            customRequest={(e) => handleUpload(e)}
            beforeUpload={(args) => handleBeforeUpload(args)}
            onChange={(e) => handleFileChanged(e)}
            itemRender={(originNode, file, fileList) => (
              <DragableUploadListItem
                originNode={originNode}
                file={file}
                fileList={fileList}
              />
            )}
          >
            {fileList.length >= 5 ? null : uploadButton}
          </Upload>
        )}
      </Box>
      <HStack alignItems={"center"}>
        <VStack width={"100%"}>
          <HStack>
            <Text color={colors.red}>*</Text>

            <Text color={colors.green2} bold mx={1}>
              {t("photo_upload_instructions")}
            </Text>
          </HStack>
          <Text mx={2}>{t("photo_requirement")}</Text>
          <HStack mt={2}>
            <Text color={colors.red}>*</Text>
            <Text color={colors.darkRed} bold mx={1}>
              {t("not_allowed_header")}
            </Text>
          </HStack>
          <Text mx={2}>{t("not_allowed_photos")}</Text>
        </VStack>
      </HStack>
    </Card>
  );
};
export default UserPhotosOperator;

export const RaasiOptions = [
  {
    value: "any",
    en_label: "Any",
    ta_label: "ஏதேனும்",
    p_o: [],
  },
  {
    value: "dont_know",
    en_label: "Dont know",
    ta_label: "தெரியாது",
    p_o: [
      "poosam",
      "aayilyam",
      "pooradam",
      "poorattathi",
      "bharanai",
      "revathi",
      "rohini",
      "thiruvathirai",
      "punarpoosam",
      "uthiram",
      "magam",
      "sadhayam",
      "moolam",
      "visakam",
      "hastham",
      "thiruvonam",
      "swathi",
      "ashwini",
      "avitam",
      "krittigai",
      "mirigasirisham",
      "anusham",
      "chithirai",
      "uthradam",
      "pooram",
      "dont_know",
    ],
  },
  {
    value: "mesham",
    en_label: "Mesham (Aries)",
    ta_label: "மேஷம்",
    p_o: ["bharanai", "ashwini", "krittigai", "dont_know"],
  },
  {
    value: "rishabam",
    en_label: "Rishabam (Taurus)",
    ta_label: "ரிஷபம்",
    p_o: ["rohini", "krittigai", "mirigasirisham", "dont_know"],
  },
  {
    value: "mithunam",
    en_label: "Mithunam (Gemini)",
    ta_label: "மிதுனம்",
    p_o: ["thiruvathirai", "punarpoosam", "mirigasirisham", "dont_know"],
  },
  {
    value: "kadagam",
    en_label: "Kadagam (Cancer)",
    ta_label: "கடகம்",
    p_o: ["poosam", "aayilyam", "punarpoosam", "dont_know"],
  },
  {
    value: "simmam",
    en_label: "Simmam (Leo)",
    ta_label: "சிம்மம்",
    p_o: ["uthiram", "magam", "pooram", "dont_know"],
  },

  {
    value: "kanni",
    en_label: "kanni (Virgo)",
    ta_label: "கன்னி",
    p_o: ["uthiram", "hastham", "chithirai", "dont_know"],
  },
  {
    value: "thulam",
    en_label: "Thulam (Libra)",
    ta_label: "துலாம்",
    p_o: ["visakam", "swathi", "chithirai", "dont_know"],
  },
  {
    value: "viruchigam",
    en_label: "Viruchigam (Scorpio)",
    ta_label: "விருச்சிகம்",
    p_o: ["visakam", "kettai", "anusham", "dont_know"],
  },
  {
    value: "dhanusu",
    en_label: "Dhanusu (Sagittarius)",
    ta_label: "தனுசு",
    p_o: ["pooradam", "moolam", "uthradam", "dont_know"],
  },
  {
    value: "magaram",
    en_label: "Magaram (Capricorn)",
    ta_label: "மகரம்",
    p_o: ["thiruvonam", "avitam", "uthradam", "dont_know"],
  },
  {
    value: "kumbam",
    en_label: "Kumbam (Aquarius)",
    ta_label: "கும்பம்",
    p_o: ["poorattathi", "sadhayam", "avitam", "dont_know"],
  },
  {
    value: "meenam",
    en_label: "Meenam (Pisces )",
    ta_label: "மீனம்",
    p_o: ["poorattathi", "revathi", "uthirattathi", "dont_know"],
  },
];

import { Box, Card, Divider, Text } from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input, Spin } from "antd";
import "../../../index.css";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  getMyPreference,
  getOperatorMatches,
  updateMyPreference,
  updateUserProfile,
  useDynamicSelector,
  userNewToken,
} from "@services/redux";
import { useDispatch } from "react-redux";

import UserPreferenceFormBasicDetails from "./user_preference_basic_details_operator";
import UserPreferenceFormReligionDetails from "./user_preference_religion_details_operator";
import UserPreferenceFormLocationDetails from "./user_preference_location_details_operator";
import UserPreferenceFormProfessionalDetails from "./user_preference_professional_details_operator";
import toast from "react-hot-toast";
import { retrieveItem, storeItem } from "@helpers/storage";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { operator_matches_page_count } from "@helpers/constants";

const UserPreferenceFormOperator = (props) => {
  const {
    handleMatchesPage,
    handlePreferencePage,
    handleProfilePage,
    handlePrivacyPage,
  } = props;

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [initial_values, setInitialValues] = useState({});

  const { user_preference: my_preference_details } =
    useDynamicSelector("my_preference");
  const { status: updatePreferenceStatus, loading: update_preference_loading } =
    useDynamicSelector("updateMyPreference");

  const { token: new_token } = useDynamicSelector("user_new_token");
  const secondary_filter_variables = useDynamicSelector(
    "secondary_filter_variables"
  );

  const getAllMatches = () => {
    let get_my_matches_key = [{ key: "operator_matches", loading: true }];
    let get_my_matches_query = getOperatorMatches;
    let get_my_matches_variables = {
      filter: secondary_filter_variables,
      page_number: 1,
      page_limit: operator_matches_page_count,
    };

    dispatch(
      dynamicRequest(
        get_my_matches_key,
        get_my_matches_query,
        get_my_matches_variables
      )
    );
  };
  const getUserNewToken = () => {
    let key = [{ key: "user_new_token", loading: true }];
    let query = userNewToken;
    let variables = null;
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleSubmit = async (values) => {
    let create_preference_key = [{ key: "updateMyPreference", loading: true }];
    let create_preference_query = updateMyPreference;
    let create_preference_variables = { data: values };
    dispatch(
      dynamicRequest(
        create_preference_key,
        create_preference_query,
        create_preference_variables,
        "M"
      )
    );
  };
  useEffect(() => {
    if (updatePreferenceStatus === "success") {
      dispatch(dynamicSet("open_drawer", { is_drawer_open: true }));
      // getAllMatches();
      getUserNewToken();
      toast.success(t("preference_updated_successfully"));
      handleMatchesPage(true);
      handlePreferencePage(false);
      handleProfilePage(false);
      handlePrivacyPage(false);

      dispatch(dynamicClear("updateMyPreference"));
    }
  }, [updatePreferenceStatus]);

  useEffect(() => {
    if (new_token) {
      storeItem("token", new_token);
    }
  }, [new_token]);

  const onValueChange = (_values) => {};

  const getOptions = (all_options, element_name, parent_key) => {
    let _options = all_options;

    if (parent_key) {
      let parent_element_option = eval(parent_key);
      _options = _options?.filter(
        (x) => x.p_o.indexOf(parent_element_option) > -1
      );
    }
    if (!_options) {
      _options = [];
    }
    // if (
    //   element_name == "Age From" ||
    //   element_name == "Age To" ||
    //   element_name == "Height From" ||
    //   element_name == "Height To" ||
    //   element_name == "Annual Income From" ||
    //   element_name == "Annual Income To"
    // ) {
    //   _options = [{ en_label: "Any", value: null }, ..._options];
    // }
    return _options;
  };
  useEffect(() => {
    let get_my_preference_key = [{ key: "my_preference", loading: true }];
    let get_my_preference_query = getMyPreference;
    let get_my_preference_variables = {};
    dispatch(
      dynamicRequest(
        get_my_preference_key,
        get_my_preference_query,
        get_my_preference_variables
      )
    );
  }, []);
  useEffect(() => {
    if (my_preference_details) {
      let _my_preference_details = JSON.parse(
        JSON.stringify(my_preference_details)
      );
      Object.keys(_my_preference_details).forEach(function (key) {
        if (Array.isArray(_my_preference_details[key])) {
          let _key_values = _my_preference_details[key].map((x) => {
            if (x.id) return x.id;
            else return x;
          });
          _my_preference_details[key] = _key_values;
        }
      });
      setInitialValues(_my_preference_details);
    }
  }, [my_preference_details]);

  useEffect(() => {
    let _initial_values = JSON.parse(JSON.stringify(initial_values));
    if (_initial_values.countries?.length === 0) {
      _initial_values.countries = ["any"];
    }
    if (
      _initial_values.countries?.length > 0 &&
      _initial_values.states?.length === 0
    ) {
      _initial_values.states = ["any"];
    }
    if (
      _initial_values.countries?.length > 0 &&
      _initial_values.states?.length > 0 &&
      _initial_values.districts?.length === 0
    ) {
      _initial_values.districts = ["any"];
    }
    form.setFieldsValue(_initial_values);
  }, [initial_values]);
  return (
    <Box>
      <Form
        form={form}
        name={"preference_form"}
        className="user_form"
        layout={"horizontal"}
        onFinish={handleSubmit}
        initialValues={initial_values}
        onValuesChange={onValueChange}
      >
        <Box alignItems={"center"}>
          <Card
            backgroundColor={"#fff"}
            maxWidth={"700px"}
            width={"90%"}
            alignItems={"center"}
            my={5}
          >
            <Box>
              <Text fontSize={16} color={"#712e5a"} bold>
                {t("my_preferences")}
              </Text>
            </Box>
            <UserPreferenceFormBasicDetails
              getOptions={getOptions}
              form={form}
            />
            <UserPreferenceFormReligionDetails
              getOptions={getOptions}
              form={form}
            />
            <UserPreferenceFormLocationDetails
              getOptions={getOptions}
              form={form}
            />
            <UserPreferenceFormProfessionalDetails
              getOptions={getOptions}
              form={form}
            />

            <Box alignItems={"center"}>
              <Button
                loading={update_preference_loading}
                type="primary"
                htmlType="submit"
              >
                {"Submit"}
              </Button>
            </Box>
          </Card>
        </Box>
      </Form>
    </Box>
  );
};
export default UserPreferenceFormOperator;

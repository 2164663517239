import { navigateBack } from "@helpers/navigator";
import {
  dynamicRequest,
  getMyBlockedProfiles,
  getMyProfile,
  useDynamicSelector,
} from "@services/redux";
import MemberBlock from "@views/components/member/member_block";
import MemberBlockIgnore from "@views/components/member/member_ignore";
import { Box, FlatList, Image, Pressable, Text } from "native-base";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch } from "react-redux";
const UserBlockedMembers = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [blocked_members, setBlockedMembers] = useState([]);

  const { my_blocked_members, loading: getBlockLoading } =
    useDynamicSelector("my_blocked_profile");

  const getBlockedMembersList = () => {
    let get_my_blocked_user_key = [
      { key: "my_blocked_profile", loading: true },
    ];
    let get_my_blocked_user_query = getMyBlockedProfiles;
    let get_my_blocked_user_variables = null;
    dispatch(
      dynamicRequest(
        get_my_blocked_user_key,
        get_my_blocked_user_query,
        get_my_blocked_user_variables
      )
    );
  };
  useEffect(() => {
    getBlockedMembersList();
  }, []);

  useEffect(() => {
    if (my_blocked_members) {
      setBlockedMembers(my_blocked_members);
    }
  }, [my_blocked_members]);

  return (
    <Box flex={1} backgroundColor={"#fef5fb"} height={"100vh"} width={"100%"}>
      <Box
        shadow={1}
        height={30}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100vw"}
      >
        <Pressable
          onPress={() => {
            navigateBack();
          }}
          cursor={"pointer"}
          position={"absolute"}
          left={5}
        >
          <AiOutlineArrowLeft size={20} color={"#111"} />
        </Pressable>
        <Text color={"#712e5a"} fontSize={16} bold>
          {t("blocked_list")}
        </Text>
      </Box>
      <Scrollbars>
        <Box my={5} alignItems={"center"}>
          <FlatList
            data={blocked_members}
            renderItem={({ item, index }) => (
              <MemberBlock title={t("un_block")} member={item} index={index} />
            )}
            keyExtractor={(item) => item.id}
            onEndReachedThreshold={0}
            showsHorizontalScrollIndicator={false}
            ListEmptyComponent={
              <Box
                width={"100%"}
                height={"100vh"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Image
                  source={{
                    uri: "https://blr1.vultrobjects.com/assets/no_data.gif",
                  }}
                  width={250}
                  height={250}
                />
                <Box
                  width={250}
                  height={250}
                  alignItems={"center"}
                  position={"absolute"}
                  bottom={10}
                >
                  <Text color={"#712e5a"}>{t("no_blocked_profiles")}</Text>
                </Box>
              </Box>
            }
          />
        </Box>
      </Scrollbars>
    </Box>
  );
};
export default UserBlockedMembers;

import { gql } from "@apollo/client";

export const complete_payment_query = gql`
  query getCompletedPaymentMembers($page_number: Float, $page_limit: Float) {
    getCompletedPaymentMembers(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        profile_id
        name
        gender
        dob
        mobile
        country
        state
        district
        basic_price
        gst_price
        total_price
        franchise_name
        pincode
        complete_before
        payment_type
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const complete_payment_report_query = gql`
  query getCompletedPaymentMembers($page_number: Float, $page_limit: Float) {
    complete_payment_report: getCompletedPaymentMembers(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        profile_id
        name
        gender
        dob
        mobile
        country
        state
        district
        basic_price
        gst_price
        total_price
        franchise_name
        pincode
        complete_before
        payment_type
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const getNotifications = gql`
  query getMyUser {
    notifications: getMyUser {
      my_notifications {
        id
        type
        datetime
        is_read
        user_id
        user {
          id
          member_profile {
            id
            name
            gender
          }
          member_cover_photo {
            photo_url
            photo_view
            cropped_photo_url
          }
        }
        member_id
        member {
          id
          member_profile {
            id
            name
            gender
          }
          member_cover_photo {
            photo_url
            photo_view
            cropped_photo_url
          }
        }
        request_group_id
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const markNotificationsQuery = gql`
  mutation markNotificationsAsRead($notification_ids: [String]) {
    markNotificationsAsRead(notification_ids: $notification_ids) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

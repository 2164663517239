import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  Menu,
  VStack,
  Pressable,
  Text,
  Image,
  Center,
} from "native-base";
import { navigate } from "@helpers/navigator";
import logo from "@assets/icons/transparent_sm.png";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  useDynamicSelector,
  getMyProfile,
  get_subscription_plans_query,
  activatePlanByOperator,
  getMyPlan,
  activatePlanByOTP,
} from "@services/redux";
import { getCroppedImageUrl, getProfileUrl } from "@helpers/get_profile_url";
import { useDispatch } from "react-redux";
import { FaCrown, FaEdit, FaUserFriends } from "react-icons/fa";
import { ROUTES } from "@views/routes/my_routes";
import moment from "moment";
import { Button, Input, Modal, Select } from "antd";
import { retrieveItem, storeItem } from "@helpers/storage";
// import Select from "react-select";
import { toast } from "react-hot-toast";

const OperatorMatchesHeader = (props) => {
  const dispatch = useDispatch();
  const [profilePhoto, setProfilePhoto] = useState("");
  const [all_plans, setAllPlans] = useState([]);
  const [image_list, setImageList] = useState([]);

  const [plan_value, setPlanValue] = useState(null);
  const [plan_amount, setPlanAmount] = useState("");
  const [otp, setOTP] = useState("");
  let _user_id;
  const { user_profile, user_photos, my_root } =
    useDynamicSelector("my_profile");
  const my_profile_photo = useDynamicSelector("my_profile_photo");

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currencyDisplay: "symbol",
    useGrouping: true,
  });
  const { items: plans_list } = useDynamicSelector("subscription_plans");
  const { status: requested_otp_status, loading: requested_otp_loading } =
    useDynamicSelector("requestOTPByOperator");
  const {
    status: activate_status,
    loading: activate_loading,
    error: activate_error,
  } = useDynamicSelector("createUserSubscriptionPlanByOperator");
  const { user_photos: userPhotos } = useDynamicSelector("my_photo");

  const {
    handleMatchesPage,
    handlePreferencePage,
    handleProfilePage,
    handlePrivacyPage,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const { my_subscription: subscribed_plan, loading: plan_loading } =
    useDynamicSelector("my_plan");

  const handleLogout = () => {
    dispatch(dynamicClear("get_user_token_by_admin"));
    dispatch(dynamicSet("open_drawer", { is_drawer_open: false }));
    dispatch(dynamicSet("secondary_filter_variables", {}));
    dispatch(dynamicSet("initial_filter_variables", {}));

    // clearLocalStorage();
    storeItem("shortlisted_profiles", []);
    storeItem("name", "");
    navigate(ROUTES.OPERATOR_HOME);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showSelectModal = () => {
    setIsSelectModalOpen(true);
  };
  const handleSelectCancel = () => {
    setOTP("");
    setPlanValue(null);
    setPlanAmount("");
    setIsSelectModalOpen(false);
  };

  const myPlan = () => {
    let get_plan_key = [{ key: "my_plan", loading: true }];
    let get_plan_query = getMyPlan;
    let get_plan_variables = null;
    dispatch(dynamicRequest(get_plan_key, get_plan_query, get_plan_variables));
  };
  const handleActivatePlan = () => {
    if (!plan_value) {
      toast.error("Please choose plan");
    } else if (!otp) {
      toast.error("Please enter otp");
    }
    if (otp && plan_amount && plan_value) {
      activatePlan();
    }
  };

  useEffect(() => {
    let get_my_profile = [{ key: "my_profile", loading: true }];
    let get_my_profile_query = getMyProfile;
    let get_my_profile_variables = {};

    dispatch(
      dynamicRequest(
        get_my_profile,
        get_my_profile_query,
        get_my_profile_variables
      )
    );
  }, []);

  const activatePlan = () => {
    let key = [{ key: "createUserSubscriptionPlanByOperator", loading: true }];
    let query = activatePlanByOperator;
    let variables = {
      data: {
        subscription_plan_id: plan_value,
        otp: otp,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllPlansList = () => {
    let key = [{ key: "subscription_plans", loading: true }];
    let query = get_subscription_plans_query;
    let variables = null;

    dispatch(dynamicRequest(key, query, variables));
  };

  const requestOTPByOperatorQuery = () => {
    let key = [{ key: "requestOTPByOperator", loading: true }];
    let query = activatePlanByOTP;
    let variables = null;

    dispatch(dynamicRequest(key, query, variables));
  };
  const UserImage = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          borderRadius: 100,
          objectFit: "cover",
        }}
        width={50}
        height={50}
      />
    );
  });

  useEffect(() => {
    _user_id = retrieveItem("user_id");
    getAllPlansList();
  }, []);

  useEffect(() => {
    let temp_arr = [];
    let _plans = [];
    if (plans_list) {
      let _filtered_plans = plans_list?.filter((x) => {
        return x.is_active === true;
      });
      _plans = _filtered_plans?.filter((x) => x.allowed_purchase_count !== 1);
      for (var i = 0; i < _plans?.length; i++) {
        temp_arr.push({
          label: _plans?.[i]?.en_label,
          value: _plans?.[i]?.id,
          amount: _plans?.[i]?.actual_price,
        });
      }
      setAllPlans(temp_arr);
    }
  }, [plans_list]);

  useEffect(() => {
    let rate;
    let disc_rate;
    if (plan_value) {
      disc_rate = plans_list?.find(
        (x) => x.id === plan_value
      )?.discounted_price;

      if (disc_rate === 0) {
        rate = plans_list?.find((x) => x.id === plan_value)?.actual_price;
        setPlanAmount(rate);
      } else {
        setPlanAmount(disc_rate);
      }
    }
  }, [plan_value]);

  useEffect(() => {
    if (userPhotos) {
      let arr = userPhotos?.find((x) => x.is_main_photo === true);
      setImageList(arr);
    }
  }, [userPhotos]);

  useEffect(() => {
    if (activate_status === "success") {
      toast.success("Plan Activated");
      myPlan();
      handleSelectCancel();
      dispatch(dynamicClear("createUserSubscriptionPlanByOperator"));
      dispatch(dynamicClear("requestOTPByOperator"));
    } else if (activate_error) {
      toast.error(activate_error?.message);

      dispatch(dynamicClear("createUserSubscriptionPlanByOperator"));
      dispatch(dynamicClear("requestOTPByOperator"));
    }
  }, [activate_status]);

  useEffect(() => {
    if (requested_otp_status === "success") {
      toast.success("OTP sent");
    }
  }, [requested_otp_status]);

  return (
    <Box>
      <HStack
        // mt={"50px"}
        height={"70px"}
        // height={"100vh"}
        // width={{
        //   base: 300,
        //   md: 300,
        //   lg: 300,
        //   xl: 300,
        //   "2xl": 300,
        //   "3xl": 300,
        // }}
        // bgImage={"linear-gradient(234deg,  #7d566f,#fff)"}
        backgroundColor={"#712e5a"}
      >
        <HStack
          px={"2"}
          h="100%"
          w="100%"
          justifyContent={"space-equal"}
          style={{
            boxShadow: "0px 0px 20px rgb(229 218 255)",
          }}
        >
          <Box
            // width={"200px"}
            width={"20%"}
            alignItems={"center"}
            justifyContent={"center"}
            p="6"
            marginLeft={"-5"}
          >
            {/* <Image
              cursor={"pointer"}
              // px={2}
              // py={2}
              // size="80px"
              width={80}
              height={80}
              alt="logo"
              resizeMode={"contain"}
              source={logo}
            /> */}
          </Box>
          <Box my={"auto"} mx={"center"} width={"72%"}>
            {user_profile && (
              <Center>
                <Box alignItems={"center"}>
                  <Text fontSize={20} color={"#fff"} bold>
                    {user_profile?.name}
                  </Text>
                  <Text fontSize={18} color={"#fff"} bold>
                    {`( ${my_root?.profile_number} )`}
                  </Text>
                </Box>
              </Center>
            )}
          </Box>

          <Box my={"auto"} width={"8%"}>
            <Box>
              <Box right="10px" ml="6">
                <Menu
                  w="150"
                  position="absolute"
                  right="-60px"
                  top="30px"
                  style={{
                    border: "1px solid #d7d7d7",
                    boxShadow: "-2px 2px #7a7a7a42",
                  }}
                  shouldOverlapWithTrigger={true}
                  placement={"right top"}
                  trigger={(triggerProps) => {
                    return (
                      <Pressable
                        alignSelf="center"
                        variant="solid"
                        {...triggerProps}
                      >
                        {/* <Avatar
                            round
                            size="25"
                            src={
                              user_profile_pic
                              // ? user_profile_pic
                              // : "https://aim-assets.s3.ap-south-1.amazonaws.com/default-avatar.png"
                            }
                          /> */}
                        <Box my={"auto"} marginRight={"-80px"}>
                          <UserImage
                            src={
                              image_list?.cropped_photo_url
                                ? image_list?.cropped_photo_url
                                : user_profile?.gender === "m"
                                ? "https://assets.blr1.vultrobjects.com/male-avatar.png"
                                : "https://assets.blr1.vultrobjects.com/female-avatar.png"
                            }
                          />
                        </Box>
                      </Pressable>
                    );
                  }}
                >
                  <Menu.Item
                    onPress={() => {
                      dispatch(
                        dynamicSet("open_drawer", { is_drawer_open: false })
                      );
                      handleMatchesPage(false);
                      handlePreferencePage(false);
                      handlePrivacyPage(false);
                      handleProfilePage(true);
                    }}
                  >
                    Edit Profile
                  </Menu.Item>
                  <Menu.Item
                    onPress={() => {
                      dispatch(
                        dynamicSet("open_drawer", { is_drawer_open: false })
                      );
                      dispatch(dynamicSet("initial_filter_variables", {}));
                      handleMatchesPage(false);
                      handlePreferencePage(true);
                      handlePrivacyPage(false);

                      handleProfilePage(false);
                    }}
                  >
                    Edit Preferences
                  </Menu.Item>
                  <Menu.Item
                    onPress={() => {
                      handlePrivacyPage(true);
                      handleMatchesPage(false);
                      handlePreferencePage(false);
                      handleProfilePage(false);
                      // navigate(ROUTES.OPERATOR_USER_PRIVACY_SETTINGS);
                    }}
                  >
                    Privacy Settings
                  </Menu.Item>
                  <Menu.Item
                    onPress={() => {
                      // dispatch(
                      //   dynamicSet("open_drawer", { is_drawer_open: false })
                      // );

                      if (subscribed_plan) {
                        showModal();
                      } else {
                        setOTP("");
                        setPlanValue(null);
                        setPlanAmount("");
                        showSelectModal();
                      }
                    }}
                  >
                    <Box bgColor={"#6cb0f0"} m={-3} p="2">
                      <HStack
                      //  width={"100%"} backgroundColor={"#6cb0f0"}
                      // color={"#"}
                      >
                        <FaCrown size={20} color="#fff" />

                        <Text mx={2} color="#fff">
                          {subscribed_plan?.en_label
                            ? subscribed_plan?.en_label
                            : "Buy Plan"}
                        </Text>
                      </HStack>
                    </Box>
                  </Menu.Item>
                  {/* <Menu.Item onPress={handleLogout}>Logout</Menu.Item> */}
                </Menu>
              </Box>
            </Box>
          </Box>
        </HStack>
        {/* <div>OperatorMatchesHeader</div> */}
      </HStack>
      <Modal
        title="Current Plan"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <VStack>
          <HStack alignItems={"center"}>
            <FaCrown size={20} />
            <Text mx={2} fontSize={14} color={"#111"}>
              {subscribed_plan?.en_label}
            </Text>
          </HStack>
          <Text fontSize={14}>
            {`${subscribed_plan?.viewed_contact_details_count} / ${subscribed_plan?.allowed_contact_details_view} Contacts viewed`}
          </Text>

          <Text fontSize={14}>{`Valid till ${moment
            .unix(subscribed_plan?.subscription_till / 1000)
            .format("Do MMMM YYYY")}`}</Text>
        </VStack>
      </Modal>
      <Modal
        title="Select Plan"
        open={isSelectModalOpen}
        onCancel={handleSelectCancel}
        footer={[
          <Button key="back" onClick={handleSelectCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={activate_loading}
            onClick={handleActivatePlan}
          >
            Activate
          </Button>,
        ]}
        // onOk={handleActivatePlan}
        okText={"Activate"}
      >
        <VStack>
          <HStack my={5} alignItems={"center"}>
            <Box width={"50%"}>
              <Text fontSize={14} color={"#111"}>
                {"Plan"}
              </Text>
            </Box>
            <Box width={"50%"}>
              {/* <Select
                styles={selectStyles}
                onChange={(e) => {
                  setPlanValue(e.value);
                }}
                style={{
                  rounded: 25,
                }}
                options={all_plans}
              /> */}
              <Select
                onChange={(e) => {
                  setPlanValue(e);
                }}
                value={plan_value}
                allowClear={true}
              >
                {all_plans.map((plan) => (
                  <Option key={plan.value} value={plan.value}>
                    {plan.label}
                  </Option>
                ))}
              </Select>
            </Box>
          </HStack>

          <HStack zIndex={-99} width={"100%"} alignItems={"center"}>
            <Box width={"50%"}>
              <Text fontSize={14}>{"Amount"}</Text>
            </Box>
            <Box width={"50%"}>
              <Input
                disabled
                value={
                  plan_amount
                    ? formatter.format(plan_amount).replace("₹", "₹ ")
                    : 0
                }
              />
            </Box>
          </HStack>

          <HStack zIndex={-99} my={5} width={"100%"} alignItems={"center"}>
            <Box width={"50%"}>
              <Text fontSize={14}>{"OTP"}</Text>
            </Box>

            {requested_otp_status === "success" ? (
              <Box width={"50%"}>
                <Input
                  value={otp}
                  size="2xl"
                  height={"50px"}
                  placeholder="OTP"
                  onChange={(e) => {
                    setOTP(e.target.value);
                  }}
                />
              </Box>
            ) : (
              <Box width={"50%"}>
                <Button
                  key="submit"
                  type="ghost"
                  loading={requested_otp_loading}
                  onClick={() => {
                    requestOTPByOperatorQuery();
                  }}
                >
                  Send OTP
                </Button>
              </Box>
            )}
          </HStack>
        </VStack>
      </Modal>
    </Box>
  );
};

export default OperatorMatchesHeader;

import React, { useEffect, useState } from "react";
import { Box, HStack, Menu, VStack, Pressable, Text, Image } from "native-base";
import Avatar from "react-avatar";
import {
  clearLocalStorage,
  retrieveItem,
  storeItem,
  useStorageItem,
} from "@helpers/storage";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { navigate } from "@helpers/navigator";

import { useTranslation } from "react-i18next";
import logo from "@assets/icons/transparent_sm.png";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  getMyPhoto,
  getMyPlan,
  getMyPreference,
  useDynamicSelector,
} from "@services/redux";
import { MdOutlineNotifications } from "react-icons/md";
import { FaFilter, FaHeart } from "react-icons/fa";
import { Drawer, Button } from "antd";
import UserNotifications from "@views/components/user_notifications/user_notifications";
import PrintShortlists from "@views/components/operator_web/print_shortlists";
import { getUUID } from "@helpers/uuid";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import UserSearchForm from "@views/components/user_search_form/user_search_form";
import UserPreferenceFormOperator from "@views/components/user_manage_preference_operator/user_preference_form_operator";
import UserFilterForm from "@views/components/user_filter_form/user_filter_form";

let defaultStyles = {
  height: "70px",
};

const HeaderBarUser = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const current_name = retrieveItem("name");
  const roles = JSON.parse(retrieveItem("roles"));
  let svg_convert_ref = useRef();
  const [open, setOpen] = useState(false);
  const [filter_open, setFilterOpen] = useState(false);
  const [toPrintProfiles, setToPrintProfiles] = useState([]);
  const [image_list, setImageList] = useState([]);

  let user_profile_pic = retrieveItem("user_profile_pic");
  let height = props.height || props.h || defaultStyles.height;

  const { uuid } = useDynamicSelector("shortlisted_count");
  const { uuid_alternative } = useDynamicSelector("print_count_alternative");
  const { user_photos: userPhotos } = useDynamicSelector("my_photo");

  const { is_drawer_open } = useDynamicSelector("open_drawer");
  const handleLogout = () => {
    dispatch(dynamicClear("get_user_token_by_admin"));
    dispatch(dynamicSet("open_drawer", { is_drawer_open: false }));
    dispatch(dynamicSet("secondary_filter_variables", {}));
    dispatch(dynamicSet("initial_filter_variables", {}));

    // clearLocalStorage();
    storeItem("shortlisted_profiles", []);
    storeItem("name", "");
    navigate(ROUTES.OPERATOR_HOME);
  };

  const getTotalCount = () => {
    let total = retrieveItem("shortlisted_profiles");
    return total?.length;
  };

  const showFilterDrawer = () => {
    setFilterOpen(true);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onFilterClose = () => {
    setFilterOpen(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (userPhotos) {
      let arr = userPhotos?.find((x) => x.is_main_photo === true);
      if (arr) {
        dispatch(dynamicSet("my_profile_photo", arr?.cropped_photo_url));
      } else {
        dispatch(dynamicSet("my_profile_photo", null));
      }
      setImageList(arr);
    }
  }, [userPhotos]);
  useEffect(() => {
    getTotalCount();
  }, [uuid, uuid_alternative]);

  useEffect(() => {
    getMyPhotos();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => svg_convert_ref.current,
  });
  const getPreferencesValues = () => {
    let get_my_preference_key = [{ key: "my_preference", loading: true }];
    let get_my_preference_query = getMyPreference;
    let get_my_preference_variables = {};
    dispatch(
      dynamicRequest(
        get_my_preference_key,
        get_my_preference_query,
        get_my_preference_variables
      )
    );
  };

  useEffect(() => {
    let get_plan_key = [{ key: "my_plan", loading: true }];
    let get_plan_query = getMyPlan;
    let get_plan_variables = null;
    dispatch(dynamicRequest(get_plan_key, get_plan_query, get_plan_variables));
  }, []);

  const getMyPhotos = () => {
    let get_my_photo_key = [{ key: "my_photo", loading: true }];
    let get_my_photo_query = getMyPhoto;
    let get_my_photo_variables = {};
    dispatch(
      dynamicRequest(
        get_my_photo_key,
        get_my_photo_query,
        get_my_photo_variables
      )
    );
  };
  return (
    <Box
      h={height}
      w="100%"
      bgImage={"linear-gradient(234deg,  #7d566f,#fff)"}

      // bgImage={"linear-gradient(234deg,  #712e5a,#B6739F)"}
    >
      <HStack
        h="100%"
        w="100%"
        style={{
          boxShadow: "0px 0px 20px rgb(229 218 255)",
        }}
      >
        <Box
          width={"200px"}
          alignItems={"center"}
          justifyContent={"center"}
          p="1"
        >
          <Image
            cursor={"pointer"}
            // px={2}
            // py={2}
            // size="80px"
            width={80}
            height={80}
            alt="logo"
            resizeMode={"contain"}
            source={logo}
          />
        </Box>
        <Box flex={1} justifyContent="center" pl="4">
          {/* <Text bold fontSize="lg" fontWeight="bold" color={props.color}>
            {props.projectName}
          </Text> */}
        </Box>

        <Box my={"auto"}>
          <HStack>
            {is_drawer_open && (
              <Pressable
                onPress={() => {
                  dispatch(dynamicSet("secondary_filter_variables", {}));

                  // getPreferencesValues();

                  showFilterDrawer();
                }}
                w="80px"
              >
                <Box>
                  <FaFilter color="#fff" size={30} />
                </Box>
              </Pressable>
            )}
            <Pressable
              onPress={() => {
                let id_uuid = getUUID();
                showDrawer();
                dispatch(dynamicSet("rerender_state", id_uuid));
              }}
              w="80px"
            >
              <Box>
                <FaHeart color="#e33653" size={30} />
              </Box>
              {getTotalCount() > 0 && (
                <Box
                  style={{
                    position: "absolute",
                    height: 20,
                    width: 20,
                    borderRadius: 15,
                    backgroundColor: "#fff",
                    right: 40,
                    bottom: 18,
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 2000,
                  }}
                >
                  <Text
                    style={{
                      color: "#e33653",
                    }}
                    fontSize={12}
                  >
                    {getTotalCount()}
                  </Text>
                </Box>
              )}
            </Pressable>
            <Text bold fontSize="md" fontWeight="" color="white">
              Welcome, {current_name}
            </Text>

            <Box>
              <Box>
                <Box right="10px" ml="6">
                  <Menu
                    w="160"
                    position="absolute"
                    right="0px"
                    top="20px"
                    style={{
                      border: "1px solid #d7d7d7",
                      boxShadow: "-2px 2px #7a7a7a42",
                    }}
                    shouldOverlapWithTrigger={true}
                    placement={"left bottom"}
                    trigger={(triggerProps) => {
                      return (
                        <Pressable
                          alignSelf="center"
                          variant="solid"
                          {...triggerProps}
                        >
                          <Avatar
                            round
                            size="25"
                            src={
                              user_profile_pic
                              // ? user_profile_pic
                              // : "https://aim-assets.s3.ap-south-1.amazonaws.com/default-avatar.png"
                            }
                          />
                        </Pressable>
                      );
                    }}
                  >
                    <Menu.Item onPress={handleLogout}>Logout</Menu.Item>
                  </Menu>
                </Box>
              </Box>
            </Box>
          </HStack>
        </Box>
      </HStack>
      <Drawer
        width={400}
        className="ant-drawer-title ant-drawer-close"
        headerStyle={{
          backgroundColor: "#712e5a",
          textDecorationColor: "#fff",
        }}
        bodyStyle={{ backgroundColor: "#fef5fb" }}
        title={`Shortlists ( ${getTotalCount()} )`}
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Box overflowY={"hidden"} overflowX={"hidden"}>
          <PrintShortlists
            onClose={onClose}
            rerender={open}
            svg_convert_ref={svg_convert_ref}
          />
        </Box>
      </Drawer>
      <Drawer
        width={400}
        className="ant-drawer-title ant-drawer-close"
        headerStyle={{
          backgroundColor: "#712e5a",
          textDecorationColor: "#fff",
        }}
        bodyStyle={{ backgroundColor: "#fef5fb" }}
        title={"Filter"}
        placement="right"
        onClose={onFilterClose}
        open={filter_open}
      >
        <Box>
          <UserFilterForm onFilterClose={onFilterClose} />
        </Box>
      </Drawer>
    </Box>
  );
};

export default HeaderBarUser;

import React, { useEffect, useState } from "react";
import MemberRectangleView from "../member/member_rectangle_view";
import {
  Box,
  Spinner,
  FlatList,
  Text,
  Pressable,
  HStack,
  Hidden,
  Image,
  Center,
} from "native-base";
import axios from "axios";
import ScrollView from "@views/components/ui/scroll_view/scroll_view";
import { FcFilledFilter } from "react-icons/fc";
import { Drawer, Spin } from "antd";
import UserSearchBar from "./user_search_bar";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  get_user_search_filter,
  useDynamicSelector,
} from "@services/redux";
import { page_count } from "@helpers/constants";
import { useDispatch } from "react-redux";

const UserSearchContent = (props) => {
  const { t } = useTranslation();
  const { filter_variables, setFilterVariables } = props;
  const dispatch = useDispatch();
  const [is_loading, setIsLoading] = useState(false);

  const [searchDrawOpen, setSearchDrawOpen] = useState(false);

  const {
    items: filteredMembers,
    pagination: filtered_list_pagination,
    loading: filter_list_loading,
  } = useDynamicSelector("getMembersByFilter");

  const showSearchDrawer = () => {
    setSearchDrawOpen(true);
  };

  const onSearchClose = () => {
    setSearchDrawOpen(false);
  };

  const loadFilteredList = (page_number, should_append) => {
    let keys = [
      {
        key: "getMembersByFilter",
        append_keys: ["items"],
        loading: true,
        appending: should_append,
      },
    ];
    let query = get_user_search_filter;
    let variables = {
      filter: filter_variables,
      page_number: page_number,
      page_limit: page_count,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  useEffect(() => {
    if (is_loading) {
      loadMoreItem();
    }
  }, [is_loading]);

  const loadMoreItem = () => {
    if (
      filtered_list_pagination?.total_count || 0 > filteredMembers.length
        ? filteredMembers.length
        : 0
    ) {
      loadFilteredList(
        filtered_list_pagination?.page_number
          ? filtered_list_pagination?.page_number + 1
          : 1,
        true
      );
    }
  };

  useEffect(() => {
    if (!filter_list_loading) {
      setIsLoading(false);
    }
  }, [filter_list_loading]);

  const renderLoader = () => {
    return is_loading ? (
      <Box h="60px" w="100%">
        <Spinner size="lg" color="#712e5a" />
      </Box>
    ) : null;
  };

  return (
    <>
      {filter_list_loading &&
      (!filteredMembers || filteredMembers.length === 0) ? (
        <Box
          flex={1}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
        >
          <Spin size="large" />
        </Box>
      ) : (
        <>
          <Box flex={1} backgroundColor={"#fef5fb"} alignItems={"center"}>
            <Hidden from="md">
              <Box
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"#fef5fb"}
                position={"fixed"}
                // top={10}
                shadow={1}
                height={30}
                zIndex={3}
              >
                <HStack alignItems={"center"}>
                  <Pressable
                    onPress={() => {
                      showSearchDrawer();
                    }}
                    style={{
                      position: "fixed",
                      left: 20,
                      alignItems: "center",
                    }}
                  >
                    <FcFilledFilter color="#712e5a" size={20} />
                  </Pressable>
                  <Text bold color={"#712e5a"}>
                    {t("_filter")}
                  </Text>
                </HStack>
              </Box>
            </Hidden>
            <FlatList
              data={filteredMembers}
              renderItem={({ item, index }) => (
                <MemberRectangleView member={item} index={index} />
              )}
              keyExtractor={(item) => item?.id}
              ListFooterComponent={renderLoader}
              onEndReached={() => {
                if (
                  filtered_list_pagination?.total_count >
                  filteredMembers?.length
                ) {
                  setIsLoading(true);
                }
              }}
              onEndReachedThreshold={0}
              ListEmptyComponent={
                <Box
                  width={"100%"}
                  height={"100vh"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Image
                    source={{
                      uri: "https://blr1.vultrobjects.com/assets/no_data.gif",
                    }}
                    width={"80"}
                    height={"80"}
                  />
                  <Box
                    width={250}
                    height={250}
                    alignItems={"center"}
                    position={"absolute"}
                    bottom={10}
                  >
                    <Text textAlign={"center"} color={"#712e5a"}>
                      {t("search_profiles_no_data")}
                    </Text>
                  </Box>
                </Box>
              }
              showsHorizontalScrollIndicator={false}
            />
          </Box>
          <Hidden from="md">
            <Drawer
              className="ant-drawer-close anticon anticon-close ant-drawer-header-title"
              headerStyle={{
                backgroundColor: "#712e5a",
                textDecorationColor: "#fff",
                height: 40,
              }}
              bodyStyle={{ backgroundColor: "#712e5a" }}
              title={"Filter"}
              placement="left"
              onClose={onSearchClose}
              open={searchDrawOpen}
            >
              <UserSearchBar
                onSearchClose={onSearchClose}
                setFilterVariables={setFilterVariables}
              />
            </Drawer>
          </Hidden>
        </>
      )}
    </>
  );
};
export default UserSearchContent;

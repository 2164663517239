import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import logo from "@assets/icons/bg_design.jpeg";

import React, { useState } from "react";
import {
  Button,
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input,
  ScrollView,
} from "native-base";
import SM from "@assets/icons/bg_design.jpeg";
import HEART from "@assets/icons/SM.png";
import Entypo from "react-native-vector-icons/dist/Entypo";

import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import RegisterForm from "./register_form";
import OtpForm from "./otp_form";
import { useTranslation } from "react-i18next";
import OtpFormInOperator from "./otp_form_operator";
import RegisterFormInOperator from "./register_form_operator";

const RegisterInOperator = (props) => {
  const { t } = useTranslation();

  const [_showRegisterForm, setShowRegisterForm] = useState(false);
  const [_showOtpForm, setShowOtpForm] = useState(true);
  const [stateMobile, setStateMobile] = useState();
  const [stateEmail, setStateEmail] = useState();

  const showRegisterForm = (value1) => {
    setShowRegisterForm(value1);
  };
  const showOTPForm = (value2) => {
    setShowOtpForm(value2);
  };
  const stateValues = (mob, email) => {
    setStateMobile(mob);
    setStateEmail(email);
  };
  return (
    <>
      <Box
        safeAreaTop
        _light={{
          bg: "primary.900",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
      />
      {/* <Box w="100%" h="30px" bg="#fff" /> */}
      <Box
        alignItems={"center"}
        my="auto"
        _dark={{
          bg: "#fff",
        }}
        _light={{
          bg: "#fff",
        }}
        flex="1"
      >
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          h="100%"
          maxW={{
            md: "1016px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <VStack
            flex="1"
            px="6"
            py="9"
            _light={{
              bg: "#fff",
            }}
            _dark={{
              bg: "#fff",
            }}
            space="3"
            // justifyContent="space-between"
            borderTopRightRadius={{
              base: "2xl",
              md: "xl",
            }}
            borderBottomRightRadius={{
              base: "0",
              md: "xl",
            }}
            borderTopLeftRadius={{
              base: "2xl",
              md: "0",
            }}
          >
            {_showRegisterForm && !_showOtpForm && (
              <RegisterFormInOperator
                showOTPForm={showOTPForm}
                showRegisterForm={showRegisterForm}
                _mob={stateMobile}
                _email={stateEmail}
              />
            )}
            {!_showRegisterForm && _showOtpForm && (
              <OtpFormInOperator
                showOTPForm={showOTPForm}
                showRegisterForm={showRegisterForm}
                stateValues={stateValues}
              />
            )}
          </VStack>
        </Stack>
      </Box>
      <Box w="100%" h="30px" bg="#712e5a" />
    </>
  );
};

export default RegisterInOperator;

import React from "react";
import {
  Box,
  Text,
  HStack,
  Divider,
  Pressable,
  Skeleton,
  Image as _Image,
} from "native-base";
import { AiFillStar } from "react-icons/ai";
import {
  FaHeart,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaPrayingHands,
} from "react-icons/fa";
import MemberDetailCardAdmin from "@views/components/member/member_detail_card";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Popconfirm } from "antd";
import toast from "react-hot-toast";
import { CalculateAge } from "@helpers/calculate_age";
import {
  BsCardList,
  BsFillFlagFill,
  BsJournalBookmarkFill,
  BsPersonFill,
} from "react-icons/bs";
import {
  MdFamilyRestroom,
  MdLanguage,
  MdOutlineWheelchairPickup,
  MdWork,
} from "react-icons/md";
import { HiCurrencyRupee, HiUserGroup } from "react-icons/hi";
import { FcBusinessman, FcBusinesswoman, FcManager } from "react-icons/fc";
import { getUserDetailsFromValue } from "@helpers/functions";
import { FamilyTypeOptions } from "@master_data/profile_json/family_type";
import { FamilyStatusOptions } from "@master_data/profile_json/family_status";
import { FamilyValueOptions } from "@master_data/profile_json/family_value";
import { FathersOccupationOptions } from "@master_data/profile_json/fathers_occupations";
import { MothersOccupationOptions } from "@master_data/profile_json/mothers_occupation";
import MemberDetailCardAdminTelecaller from "@views/components/member/member_detail_card_tele_caller";
import { AnnualIncomeFromOptions } from "@master_data/preference_json/annual_income_from";
import { AnnualIncomeToOptions } from "@master_data/preference_json/annual_income_to";
import { EducationsOptions } from "@master_data/common_json/educations";
import { EmploymentTypesOptions } from "@master_data/common_json/employment_types";
import { getCountry, getDistrict } from "@helpers/get_country_state_city";
import { ImLocation, ImSpoonKnife } from "react-icons/im";
import { DoshamsOptions } from "@master_data/common_json/doshams";
import { StarsOptions } from "@master_data/common_json/stars";
import { SubCastesOptions } from "@master_data/common_json/sub_castes";
import { CasteOptions } from "@master_data/common_json/castes";
import { ReligionsOptions } from "@master_data/common_json/religions";
import { EatingHabitsOptions } from "@master_data/common_json/eating_habits";
import { MotherTongueOptions } from "@master_data/common_json/mother_tongue";
import { PhysicalStatusOptions } from "@master_data/common_json/physical_status";
import { MaritalStatusOptions } from "@master_data/common_json/marital_status";
import { GiBigDiamondRing, GiBodyHeight } from "react-icons/gi";
import { IoMdSchool } from "react-icons/io";

const PreferencesTeleCaller = (props) => {
  const { t } = useTranslation();
  const { member } = props;
  console.log("member", member);
  return (
    <Box
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 8,
        boxShadow: "0 0 10px #dcdcdc",
        elevation: 5,
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        borderBottomWidth: 3,
        borderBottomColor: "#712e5a",
        // marginVertical: 10,
        marginHorizontal: 5,
        paddingHorizontal: 5,
      }}
    >
      <HStack alignItems={"center"}>
        <Box
          style={{
            paddingHorizontal: 5,
          }}
        >
          <MdFamilyRestroom size={30} color={"#712e5a"} />
        </Box>
        <Box>
          <Text fontSize={25} fontWeight={"bold"}>
            {t("preferences")}
          </Text>
        </Box>
      </HStack>
      <Divider my={2} />

      {member?.member_preference?.age_from !== null &&
        member?.member_preference?.age_to !== null && (
          <HStack
            // key={index}
            alignItems={"center"}
            paddingX={2}
            paddingVertical={5}
          >
            <Box
              style={{
                paddingHorizontal: 5,
                width: "5%",
              }}
            >
              <BsPersonFill size={25} color={"#ab6c95"} />
            </Box>
            <Box width={"35%"}>
              <Text py={1} fontSize={20} color={"black"}>
                {"Age"}
              </Text>
            </Box>
            <Box width={"40%"}>
              <HStack>
                <Text py={1} fontSize={20} color={"black"}>
                  {`${
                    member?.member_preference?.age_from === -1
                      ? "Any"
                      : member?.member_preference?.age_from
                  } - ${
                    member?.member_preference?.age_to > 60
                      ? "Any"
                      : member?.member_preference?.age_to
                  }`}
                </Text>
              </HStack>
            </Box>

            <br />
          </HStack>
        )}

      {member?.member_preference?.height_from !== null &&
        member?.member_preference?.height_to !== null && (
          <HStack
            // key={index}
            alignItems={"center"}
            paddingX={2}
            paddingVertical={5}
          >
            <Box
              style={{
                paddingHorizontal: 5,
                width: "5%",
              }}
            >
              <GiBodyHeight size={25} color={"#ab6c95"} />
            </Box>
            <Box width={"35%"}>
              <Text py={1} fontSize={20} color={"black"}>
                {"Height"}
              </Text>
            </Box>
            <Box width={"40%"}>
              <HStack>
                <Text py={1} fontSize={20} color={"black"}>
                  {`${
                    member?.member_preference?.height_from === -1
                      ? "Any"
                      : member?.member_preference?.height_from
                  } - ${
                    member?.member_preference?.height_to === 999999999
                      ? "Any"
                      : member?.member_preference?.height_to
                  }`}
                </Text>
              </HStack>
            </Box>

            <br />
          </HStack>
        )}
      {member?.member_preference?.marital_statuses?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <GiBigDiamondRing size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Marital Status"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.marital_statuses?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getUserDetailsFromValue(MaritalStatusOptions, x)}${
                      i ===
                      member?.member_preference?.marital_statuses?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}
      {member?.member_preference?.physical_statuses?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <MdOutlineWheelchairPickup size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Physical Statuses"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.physical_statuses?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getUserDetailsFromValue(PhysicalStatusOptions, x)}${
                      i ===
                      member?.member_preference?.physical_statuses?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}
      {member?.member_preference?.mother_tongues?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <MdLanguage size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Mother Tongue"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.mother_tongues?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getUserDetailsFromValue(MotherTongueOptions, x)}${
                      i ===
                      member?.member_preference?.mother_tongues?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}
      {member?.member_preference?.eating_habits?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <ImSpoonKnife size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Eating Habits"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.eating_habits?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getUserDetailsFromValue(EatingHabitsOptions, x)}${
                      i === member?.member_preference?.eating_habits?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}
      {member?.member_preference?.religions?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <FaPrayingHands size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Religions"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.religions?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getUserDetailsFromValue(ReligionsOptions, x)}${
                      i === member?.member_preference?.religions?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}
      {member?.member_preference?.castes?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <BsJournalBookmarkFill size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Castes"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.castes?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getUserDetailsFromValue(CasteOptions, x)}${
                      i === member?.member_preference?.castes?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}
      {member?.member_preference?.sub_castes?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <BsJournalBookmarkFill size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Sub Castes"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.sub_castes?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getUserDetailsFromValue(SubCastesOptions, x)}${
                      i === member?.member_preference?.sub_castes?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}
      {member?.member_preference?.stars?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <AiFillStar size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Stars"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.stars?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getUserDetailsFromValue(StarsOptions, x)}${
                      i === member?.member_preference?.stars?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}
      {member?.member_preference?.doshams?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <FaHeart size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Doshams"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.doshams?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getUserDetailsFromValue(DoshamsOptions, x)}${
                      i === member?.member_preference?.doshams?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}
      {member?.member_preference?.countries?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <ImLocation size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Countries"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.countries?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getCountry(x)}${
                      i === member?.member_preference?.countries?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}
      {/* <MemberDetailCardAdminTelecaller
        element={t("state")}
        icon={<HiUserGroup size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          FamilyTypeOptions,
          member?.member_preference?.family_type
        )}
      /> */}
      {member?.member_preference?.districts?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <ImLocation size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Districts"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.districts?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getDistrict(x)}${
                      i === member?.member_preference?.districts?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}
      {member?.member_preference?.educations?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <IoMdSchool size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Educations"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.educations?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getUserDetailsFromValue(EducationsOptions, x)}${
                      i === member?.member_preference?.educations?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}
      {member?.member_preference?.employment_types?.length > 0 && (
        <HStack
          // key={index}
          alignItems={"center"}
          paddingX={2}
          paddingVertical={5}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              width: "5%",
            }}
          >
            <MdWork size={25} color={"#ab6c95"} />
          </Box>
          <Box width={"35%"}>
            <Text py={1} fontSize={20} color={"black"}>
              {"Employment Type"}
            </Text>
          </Box>
          <Box width={"40%"}>
            <HStack>
              {member?.member_preference?.employment_types?.map((x, i) => {
                return (
                  <Text py={1} fontSize={20} color={"black"}>
                    {`${getUserDetailsFromValue(EmploymentTypesOptions, x)}${
                      i ===
                      member?.member_preference?.employment_types?.length - 1
                        ? ""
                        : ", "
                    }`}
                  </Text>
                );
              })}
            </HStack>
          </Box>

          <br />
        </HStack>
      )}

      {member?.member_preference?.annual_income_from &&
        member?.member_preference?.annual_income_to && (
          <HStack
            // key={index}
            alignItems={"center"}
            paddingX={2}
            paddingVertical={5}
          >
            <Box
              style={{
                paddingHorizontal: 5,
                width: "5%",
              }}
            >
              <HiCurrencyRupee size={25} color={"#ab6c95"} />
            </Box>
            <Box width={"35%"}>
              <Text py={1} fontSize={20} color={"black"}>
                {"Annual Income"}
              </Text>
            </Box>
            <Box width={"40%"}>
              <HStack>
                <Text py={1} fontSize={20} color={"black"}>
                  {`${getUserDetailsFromValue(
                    AnnualIncomeFromOptions,
                    member?.member_preference?.annual_income_from
                  )}-${getUserDetailsFromValue(
                    AnnualIncomeToOptions,
                    member?.member_preference?.annual_income_to
                  )}`}
                </Text>
              </HStack>
            </Box>

            <br />
          </HStack>
        )}
    </Box>
  );
};
export default PreferencesTeleCaller;

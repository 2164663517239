import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import { startCase } from "lodash";
import MontserratBold from "@assets/fonts/Montserrat-Bold.ttf";
// import Montserrat-Regular from "@assets/fonts/Montserrat-Bold-Light.ttf";
// import RobotoMedium from "@assets/fonts/Montserrat-Bold-Medium.ttf";
import MontserratRegular from "@assets/fonts/Montserrat-Regular.ttf";
import Logo from "@assets/icons/big.png";
import HeaderLogo from "@assets/icons/sm123.png";

import { useTranslation } from "react-i18next";
import {
  getCountry,
  getDistrict,
  getState,
  getStateInvoiceCode,
} from "@helpers/get_country_state_city";

const Invoice = (props) => {
  const {
    order_id,
    customer_name,
    invoice_number,
    customer_mobile,
    customer_profile_id,
    customer_country,
    customer_state,
    customer_district,
    plan_name,
    plan_rate,
    plan_discounted_rate,
    order_datetime,
  } = props;
  const Discount = plan_discounted_rate;
  const { t } = useTranslation();
  useEffect(() => {
    Font.register({
      family: "Montserrat-Regular",
      src: MontserratRegular,
    });
    Font.register({
      family: "Montserrat-Bold",
      src: MontserratBold,
    });
    // Font.register({
    //   family: 'RobotoMedium',
    //   src:RobotoMedium,
    // });
    // Font.register({
    //   family: 'MontserratRegular',
    //   src:MontserratRegular,
    // });
  }, []);

  return (
    <Document>
      <Page size="A4">
        <Image style={styles.background_image} src={Logo} />
        <View style={styles.page_contents}>
          <View style={styles.head}>
            <View style={styles.header_left}>
              <Image style={styles.image} src={HeaderLogo} />
            </View>
            <View style={styles.header_right}>
              <Text style={styles.address_title}>
                {t("standard_matrimony")}
              </Text>
              <Text style={styles.address}>{t("door_no")}</Text>
              <Text style={styles.address}>
                {t("sathiya_road_gandhipuram")}
              </Text>
              <Text style={styles.address}>{t("coimbatore_pincode")}</Text>
              <Text style={styles.address}>{t("tamilnadu_India")}</Text>
              {/* <Text style={styles.address}>
                {t("contact_person")}
                {t("g_mahendran")}
              </Text> */}
              <Text style={styles.address}>
                {t("e_mail")}
                {"standardmatrimony@gmail.com"}
              </Text>
              <Text style={styles.address}>{t("phone")}</Text>
              <Text style={styles.address}>{t("gst_no")}</Text>
            </View>
          </View>

          <View style={styles.contents}>
            <View style={styles.invoice}>
              <Text style={styles.content_title}>{t("invoice")}</Text>
              <View style={styles.invoice_details}>
                <View>
                  <Text style={styles.address}>{t("invoice_number")}</Text>
                  <Text style={styles.address}>{t("order_id")}</Text>
                  <Text style={styles.address}>{t("date")}</Text>
                  <Text style={styles.address}>{t("state")}</Text>
                  <Text style={styles.address}>{t("gstin_unique_id")}</Text>
                  <Text style={styles.address}>{t("sac_code")}</Text>
                </View>
                <View>
                  {/* <View style={styles.address_details}> */}
                  <Text style={styles.address}>{invoice_number}</Text>
                  <Text style={styles.address}>{order_id || ""}</Text>
                  <Text style={styles.address}>
                    {moment(order_datetime).format("DD-MM-YYYY")}
                  </Text>
                  <Text style={styles.address}>
                    {`${
                      getStateInvoiceCode(customer_state, customer_country)
                        ?.name
                    } (${
                      getStateInvoiceCode(customer_state, customer_country)
                        ?.invoice_code
                    })`}
                  </Text>
                  <Text style={styles.address}>{"Unregistered"}</Text>
                  <Text style={styles.address}>{"998439"}</Text>
                </View>
              </View>
            </View>
            <View style={styles.customer}>
              <Text style={styles.content_title}>{t("customer_details")}</Text>
              <View>
                <Text style={styles.customer_name}>{customer_name}</Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.address}>{t("id")}</Text>
                  <Text style={styles.address}>{customer_profile_id}</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.address}>{t("mobile_")}</Text>
                  <Text style={styles.address}>{customer_mobile}</Text>
                </View>
                <Text style={styles.address}>
                  {getDistrict(customer_district)},
                </Text>
                <Text style={styles.address}>
                  {getState(customer_state, customer_country)},
                </Text>
                <Text style={styles.address}>
                  {getCountry(customer_country)}.
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.table_contents}>
            <View style={styles.table2}>
              <View style={styles.table_Row1}>
                <View style={styles.first_row}>
                  <Text style={styles.titles}>{t("subscribe_plan")}</Text>
                </View>
                <View style={styles.table_Col32}>
                  <Text style={styles.titles}>{t("quantity")}</Text>
                </View>
                <View style={styles.table_Col3}>
                  <Text style={styles.titles}>{t("rate")}</Text>
                </View>
                <View style={styles.table_Col32}>
                  <Text style={styles.titles}>{t("discount")}</Text>
                </View>
                <View style={styles.table_Col3}>
                  <Text style={styles.titles}>{t("amount")}</Text>
                </View>
              </View>
            </View>
            <View style={styles.table_Row2}>
              <View style={styles.first_input_row}>
                <Text style={styles.table_inputs}>{plan_name}</Text>
              </View>
              <View style={styles.table_input_Col3}>
                <Text style={styles.table_inputs}>1</Text>
              </View>
              <View style={styles.table_input_Col32}>
                <Text style={styles.table_inputs}>
                  &#8377; {Math.round((plan_rate + Number.EPSILON) * 100) / 100}
                </Text>
              </View>
              <View style={styles.table_input_Col3}>
                <Text style={styles.table_inputs}>
                  &#8377;
                  {Math.round((plan_rate - Discount + Number.EPSILON) * 100) /
                    100}
                </Text>
              </View>
              <View style={styles.table_input_Col32}>
                <Text style={styles.table_inputs}>
                  &#8377;
                  {Math.round((Discount + Number.EPSILON) * 100) / 100}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.price_contents}>
            <View style={styles.price_list}>
              <View>
                <Text style={styles.calculate_text}>{t("sub_total")}</Text>
                <Text style={styles.calculate_text}>{t("cgst")}</Text>
                <Text style={styles.calculate_text}>{t("sgst")}</Text>
                <Text style={styles.calculate_text}>{t("igst")}</Text>
              </View>
              <View>
                <Text style={styles.calculate_input}>
                  &#8377;
                  {Math.round((Discount + Number.EPSILON) * 100) / 100}
                </Text>
                <Text style={styles.calculate_input}>
                  &#8377;
                  {customer_state === "tn"
                    ? Math.round((Discount * 0.09 + Number.EPSILON) * 100) / 100
                    : 0}
                </Text>
                <Text style={styles.calculate_input}>
                  &#8377;
                  {customer_state === "tn"
                    ? Math.round((Discount * 0.09 + Number.EPSILON) * 100) / 100
                    : 0}
                </Text>
                <Text style={styles.calculate_input}>
                  &#8377;
                  {customer_state !== "tn"
                    ? Math.round((Discount * 0.18 + Number.EPSILON) * 100) / 100
                    : 0}
                </Text>
              </View>
            </View>
            <View style={styles.total}>
              <Text style={styles.over_total}>Total</Text>
              <Text style={styles.over_total_input}>
                &#8377;
                {Math.round(
                  (Discount +
                    Discount * 0.09 +
                    Discount * 0.09 +
                    Number.EPSILON) *
                    100
                ) / 100}
                .00
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.footer_box}>
          <Text style={styles.footer_content_one}>
            {t("thank_you_for_your_business")}
          </Text>
          <Text style={styles.footer_content_two}>
            {t(
              "for_any_queries,_please_write_to_us_at_standardmatrimony@gmail.com"
            )}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;

const styles = StyleSheet.create({
  address_title: {
    fontSize: "13px",
    fontFamily: "Montserrat-Bold",
    marginBottom: "3px",
  },
  content_title: {
    fontSize: "13px",
    fontFamily: "Montserrat-Bold",
    marginBottom: "5px",
  },
  customer_name: {
    fontSize: "10px",
    fontFamily: "Montserrat-Bold",
    marginBottom: "2px",
  },
  address: {
    fontSize: "10px",
    fontFamily: "Montserrat-Regular",
    marginBottom: "2px",
  },
  page: {
    flexDirection: "row",
  },
  invoice_details: { display: "flex", flexDirection: "row", gap: "10px" },
  address_details: {},
  page_contents: {
    padding: "10px",
    fontFamily: "Montserrat-Regular",
    zIndex: "-1",
    minHeight: "700px",
  },
  page1: {
    flexDirection: "row",
    marginLeft: "50px",
  },
  image: {
    width: "80%",
  },
  head: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  contents: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    minHeight: "130px",
    justifyContent: "center",
  },
  header_left: {
    flex: 1,
    alignItems: "start",
    justifyContent: "start",
  },
  header_right: {
    flexDirection: "column",
    flex: 1,
    alignItems: "flex-start",
  },
  table2: {
    color: "white",
    width: "100%",
  },
  table_contents: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  table_Row1: {
    display: "flex",
    flexDirection: "row",
    height: "25px",
    textAlign: "center",
    backgroundColor: "rgb(113, 46, 90)",
  },
  table_Row2: {
    display: "flex",
    flexDirection: "row",
    height: "20px",
    textAlign: "center",
    maxHeight: "100px",
  },
  titles: {
    fontSize: "10px",
    fontFamily: "Montserrat-Bold",
  },
  table_Col3: {
    paddingLeft: "5px",
    paddingRight: "5px",
    flex: 1,
    textAlign: "right",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  first_row: {
    width: "150px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table_Col32: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "right",
    justifyContent: "center",
    flex: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table_input_Col32: {
    paddingLeft: "5px",
    paddingRight: "5px",
    maxHeight: "100px",
    minHeight: "50px",
    textAlign: "right",
    justifyContent: "center",
    flex: 1,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    maxHeight: "100px",
  },
  first_input_row: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "150px",
    textAlign: "left",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    maxHeight: "100px",
    minHeight: "50px",
    // lineHeight: "100px",
  },
  table_input_Col3: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "right",
    justifyContent: "center",
    flex: 1,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    maxHeight: "100px",
    minHeight: "50px",
  },
  table_inputs: {
    fontSize: "8px",
    overflowWrap: "break-word",
  },
  price_contents: {
    marginTop: "30px",
    width: "93%",
    alignItems: "flex-end",
  },
  calculate_text: {
    fontSize: "10px",
    fontFamily: "Montserrat-Bold",
    marginBottom: "5px",
    color: "#000000bf",
  },
  over_total: {
    fontSize: "10px",
    color: "#000000bf",
    fontFamily: "Montserrat-Bold",
  },
  calculate_input: {
    fontSize: "10px",
    fontFamily: "Montserrat-Regular",
    marginBottom: "5px",
  },
  over_total_input: {
    fontSize: "10px",
    fontFamily: "Montserrat-Bold",
    color: "#000000bf",
  },
  remarksinput: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  customer: {
    flex: 1,
  },
  invoice: {
    flex: 1,
  },

  background_image: {
    width: "100%",
    zIndex: 1,
    position: "absolute",
    marginTop: "200px",
    opacity: "0.1",
  },

  background: {
    zIndex: "-1",
    position: "absolute",
  },
  total: {
    flexDirection: "row",
    gap: "60px",
    borderColor: "black",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingBottom: "10px",
    paddingTop: "10px",
    width: "150px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  price_list: {
    flexDirection: "row",
    gap: "20px",
    width: "150px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    gap: "40px",
    // backgroundColor: "#8080809c",
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
    width: "100%",
    orientation: "portrait",
  },
  footer_box: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  footer_content_one: {
    fontSize: "18px",
    marginBottom: "2px",
    fontFamily: "Montserrat-Bold",
  },
  footer_content_two: { fontSize: "10px", fontFamily: "Montserrat-Regular" },
});

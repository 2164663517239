import {
  Box,
  Card,
  Divider,
  Hidden,
  HStack,
  Skeleton,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input } from "antd";
import "../../../index.css";

import { useDispatch } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { AnnualIncomeFromOptions } from "@master_data/preference_json/annual_income_from";
import { AnnualIncomeToOptions } from "@master_data/preference_json/annual_income_to";
import { EducationsOptions } from "@master_data/common_json/educations";
import { EmploymentTypesOptions } from "@master_data/common_json/employment_types";
import { useDynamicSelector } from "@services/redux";

const UserPreferenceFormProfessionalDetails = (props) => {
  const { getOptions, form } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [income_to_values, setIncomeToValues] = useState([]);
  const [income_from_values, setIncomeFromValues] = useState([]);
  const [educations_list, setEducationsList] = useState([]);

  let income_value = Form.useWatch("annual_income_from");
  let income_to_value = Form.useWatch("annual_income_to");
  let selected_educations = Form.useWatch("educations");
  let selected_employment_types = Form.useWatch("employment_types");

  const { loading: my_preference_loading } =
    useDynamicSelector("my_preference");
  useEffect(() => {
    let _options = [];
    let _options1 = [];

    if (AnnualIncomeFromOptions || AnnualIncomeToOptions) {
      _options = AnnualIncomeFromOptions;
      _options1 = AnnualIncomeToOptions;
      setIncomeFromValues(_options);
    }
    for (let i = 0; i < _options?.length; i++) {
      let temp_value1 = _options?.[i];
      if (temp_value1?.value === income_value) {
        let temp_value3 = _options1.filter((x) => {
          return x.value >= temp_value1?.value;
        });
        setIncomeToValues(temp_value3);
      }
    }
    for (let j = 0; j < _options1?.length; j++) {
      let temp_value1 = _options1?.[j];

      if (temp_value1?.value === income_to_value) {
        let temp_value3 = _options.filter((x) => {
          return x.value <= temp_value1?.value;
        });
        setIncomeFromValues(temp_value3);
      }
    }
  }, [
    income_value,
    AnnualIncomeToOptions || AnnualIncomeFromOptions,
    income_to_value,
  ]);

  const handleEducationValuesChange = (values) => {
    console.log("handleEducationValuesChange", values);

    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1]?.startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id?.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id?.startsWith("any"));
    }
    form.setFieldsValue({
      educations: filtered_ids,
    });
  };

  const handleEmploymentTypeValuesChange = (values) => {
    let filtered_ids = values;
    if (
      values &&
      values.length > 0 &&
      values[values.length - 1]?.startsWith("any")
    ) {
      filtered_ids = filtered_ids?.filter((id) => id?.startsWith("any"));
    } else if (values && values.length > 0) {
      filtered_ids = filtered_ids?.filter((id) => !id?.startsWith("any"));
    }
    form.setFieldsValue({
      employment_types: filtered_ids,
    });
  };

  return (
    <>
      {my_preference_loading ? (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("professional_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <Hidden from="md">
            <Box width={"100%"}>
              <Skeleton.Text lines={5} />
            </Box>
          </Hidden>
          <Hidden till="md">
            <HStack space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
          </Hidden>
        </Card>
      ) : (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={14} bold color={"#712e5a"}>
              {t("professional_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <VForm.Select
            label={t("education")}
            field={"educations"}
            options={getOptions(EducationsOptions, "Education")}
            labelField={"en_label"}
            valueField={"value"}
            isMulti
            onChange={handleEducationValuesChange}
          />

          <VForm.Select
            label={t("employment_type")}
            field={"employment_types"}
            options={getOptions(EmploymentTypesOptions, "Employment Type")}
            labelField={"en_label"}
            valueField={"value"}
            isMulti
            onChange={handleEmploymentTypeValuesChange}
          />
          <HStack>
            <Box width={"45%"}>
              <Text py={2} color={"#712e5a"} textAlign={"left"}>
                {"Annual Income"}
              </Text>
            </Box>
            <Box width={"25%"}>
              <VForm.Select
                // label={t("From")}
                field={"annual_income_from"}
                options={income_from_values}
                labelField={"en_label"}
                valueField={"value"}
              />
            </Box>
            <Box alignItems={"center"} mx={2}>
              -
            </Box>
            <Box width={"25%"}>
              <VForm.Select
                // label={t("To")}
                options={income_to_values}
                field={"annual_income_to"}
                labelField={"en_label"}
                valueField={"value"}
              />
            </Box>
          </HStack>
        </Card>
      )}
    </>
  );
};
export default UserPreferenceFormProfessionalDetails;

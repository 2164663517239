import React from "react";
import {
  Box,
  Text,
  HStack,
  Divider,
  Pressable,
  Skeleton,
  Image as _Image,
} from "native-base";
import { AiFillStar } from "react-icons/ai";
import {
  FaHeart,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaPrayingHands,
} from "react-icons/fa";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Popconfirm } from "antd";
import toast from "react-hot-toast";
import { CalculateAge } from "@helpers/calculate_age";
import {
  BsCardList,
  BsFillFlagFill,
  BsJournalBookmarkFill,
  BsPersonFill,
} from "react-icons/bs";
import MemberDetailCardAdmin from "@views/components/member/member_detail_card";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { getUserDetailsFromValue } from "@helpers/functions";
import { ReligionsOptions } from "@master_data/common_json/religions";
import { CasteOptions } from "@master_data/common_json/castes";
import { SubCastesOptions } from "@master_data/common_json/sub_castes";
import { RaasiOptions } from "@master_data/profile_json/raasi";
import { StarsOptions } from "@master_data/common_json/stars";
import { DoshamsOptions } from "@master_data/common_json/doshams";

const ReligionDetails = (props) => {
  const { member } = props;
  const { t } = useTranslation();

  return (
    <Box
      // key={i}
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 8,
        boxShadow: "0 0 10px #dcdcdc",
        elevation: 5,
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        borderBottomWidth: 3,
        borderBottomColor: "#712e5a",
        // marginVertical: 10,
        marginHorizontal: 5,
        paddingHorizontal: 5,

        //width: "40%",
      }}
    >
      <HStack alignItems={"center"}>
        <Box
          style={{
            paddingHorizontal: 5,
          }}
        >
          <FaPrayingHands size={30} color={"#712e5a"} />
        </Box>
        <Box>
          <Text fontSize={25} fontWeight={"bold"}>
            {t("religion_information")}
          </Text>
        </Box>
      </HStack>
      <Divider my={2} />
      <MemberDetailCardAdmin
        element={t("religion")}
        icon={<FaPrayingHands size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          ReligionsOptions,
          member?.member_profile?.religion
        )}
      />
      <MemberDetailCardAdmin
        element={t("caste")}
        icon={<BsJournalBookmarkFill size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          CasteOptions,
          member?.member_profile?.caste
        )}
      />
      <MemberDetailCardAdmin
        element={t("sub_caste")}
        icon={<BsJournalBookmarkFill size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          SubCastesOptions,
          member?.member_profile?.sub_caste
        )}
      />
      <MemberDetailCardAdmin
        element={t("raasi")}
        icon={<AiFillStar size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          RaasiOptions,
          member?.member_profile?.raasi
        )}
      />

      <MemberDetailCardAdmin
        element={t("star")}
        icon={<AiFillStar size={18} color={"#ab6c95"} />}
        text={getUserDetailsFromValue(
          StarsOptions,
          member?.member_profile?.star
        )}
      />
      <MemberDetailCardAdmin
        element={t("gothram")}
        icon={<VscTypeHierarchySub size={18} color={"#ab6c95"} />}
        text={member?.member_profile?.gothram}
      />
      {member?.member_profile?.doshams?.length > 0 && (
        <MemberDetailCardAdmin
          element={t("doshams")}
          icon={<FaHeart size={18} color={"#ab6c95"} />}
          // text={member?.member_profile?.dhosams}
          list={member?.member_profile?.doshams}
          type={"multi"}
          options={DoshamsOptions}
        />
      )}
    </Box>
  );
};
export default ReligionDetails;

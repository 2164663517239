import React, { useState } from "react";
import {
  Box,
  HStack,
  Menu,
  VStack,
  Button,
  Pressable,
  Text,
  Image,
  Hidden,
} from "native-base";
import { Dimensions } from "react-native";

import logo from "@assets/icons/transparent_sm.png";
import {
  clearLocalStorage,
  retrieveItem,
  storeItem,
  useStorageItem,
} from "@helpers/storage";
import {
  dynamicClear,
  dynamicRequest,
  getMyPhoto,
  loginSelector,
  markNotificationsQuery,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useLocation } from "react-router-dom";
import { MdOutlineNotifications } from "react-icons/md";
import { Drawer } from "antd";
import { AiOutlineMenu } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { useEffect } from "react";
import UserNotifications from "../user_notifications/user_notifications";
import { getCroppedImageUrl, getProfileUrl } from "@helpers/get_profile_url";

let defaultStyles = {
  height: "70px",
};

const HeaderBarUserWeb = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  // const { Photo, name } = useStorageItem("user");
  const [login_user_id, setLoginUserID] = useState("");
  const [profile_pic, setProfilePic] = useState("");
  const [user_name, setUserName] = useState("");
  const [profile_id, setProfileID] = useState("");
  const [profile_photo, setProfilePhoto] = useState("");
  const [notify_count, setNotifyCount] = useState();
  const [notifications_data, setNotificationsData] = useState([]);
  const [gender, setGender] = useState();
  const [image_list, setImageList] = useState([]);

  const { my_notifications: notifications_list } =
    useDynamicSelector("notifications");

  const { user_photos: userPhotos } = useDynamicSelector("my_photo");

  let height = props.height || props.h || defaultStyles.height;

  const getMyPhotos = () => {
    let get_my_photo_key = [{ key: "my_photo", loading: true }];
    let get_my_photo_query = getMyPhoto;
    let get_my_photo_variables = {};
    dispatch(
      dynamicRequest(
        get_my_photo_key,
        get_my_photo_query,
        get_my_photo_variables
      )
    );
  };
  useEffect(() => {
    if (userPhotos) {
      let arr = userPhotos?.find((x) => x.is_main_photo === true);
      setImageList(arr);
    }
  }, [userPhotos]);
  useEffect(() => {
    if (notifications_list) {
      let _is_read_false_filter = notifications_list?.filter((x) => {
        return x.is_read === false;
      });
      setNotifyCount(_is_read_false_filter);

      setNotificationsData(notifications_list);
    }
  }, [notifications_list]);

  useEffect(() => {
    let login_user = retrieveItem("name");
    let login_user_id = retrieveItem("profile_id");
    let login_user_photo = retrieveItem("profile_photo");
    let user_gender = retrieveItem("gender");
    setGender(user_gender);

    setUserName(login_user);
    setProfileID(login_user_id);
    setProfilePhoto(login_user_photo);
    getMyPhotos();
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const Logout = () => {
    dispatch(dynamicClear("user_login"));
    clearLocalStorage();
    navigate(ROUTES.LOGIN);
  };
  const ImageLogo = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          borderRadius: "50%",
          objectFit: "cover",
        }}
        width={40}
        height={40}
      />
    );
  });

  return (
    <Box h={height} w={"100%"} bg="#ffffff">
      <HStack
        h="100%"
        w="100%"
        // backgroundColor={"#fff"}
        // bgImage={"linear-gradient(234deg,  #712e5a,#B6739F)"}
        bgImage={"linear-gradient(234deg,  #7d566f,#fff)"}
        style={{
          boxShadow: "0px 0px 20px rgb(229 218 255)",
        }}
      >
        <Pressable
          width={"200px"}
          onPress={() => {
            navigate(ROUTES.USER_HOME);
          }}
          pl={1}
          justifyContent="center"
          alignItems="center"
        >
          <Image
            cursor={"pointer"}
            // px={2}
            // py={2}
            //  size="3xl"
            width={80}
            height={80}
            alt="logo"
            resizeMode={"contain"}
            source={logo}
          />
        </Pressable>
        {/* <Hidden till="lg">
          {props.projectName && (
            <Box
              position={"absolute"}
              left={"52px"}
              top={"20px"}
              alignItems={"center"}
            >
              <VStack alignItems={"center"}>
                <Text
                  bold
                  fontSize={{
                    base: "sm",
                    //  xs: 80,
                    //  sm: 80,
                    md: "md",
                    lg: "lg",
                    xl: "lg",
                    "2xl": "lg",
                    "3xl": "lg",
                  }}
                  fontWeight="bold"
                  color={"#fff"}
                >
                  {props.projectName}
                </Text>
                <Text fontSize={10} color={"#fff"}>
                  {"Service from heart"}
                </Text>
              </VStack>
            </Box>
          )}
        </Hidden> */}

        <Box
          flex={1}
          marginLeft={{ base: 0, lg: 5, xl: 5 }}
          alignItems={"center"}
          justifyContent="center"
        >
          <Hidden till="md">
            <HStack
              space={{
                base: 20,
                // xs: 20,
                // sm: 20,
                md: 10,
                lg: 20,
                xl: 20,
                "2xl": 20,
                "3xl": 20,
              }}
            >
              <Box>
                <HStack>
                  <Text
                    textDecorationLine={pathname === "/" ? "underline" : "none"}
                    cursor={"pointer"}
                    bold
                    fontSize={{ base: "sm" }}
                    color={pathname === "/" ? "#111" : "#111"}
                    onPress={() => {
                      navigate(ROUTES.USER_HOME);
                    }}
                  >
                    HOME
                  </Text>
                </HStack>
              </Box>

              <Box>
                <HStack>
                  <Text
                    textDecorationLine={
                      pathname === "/matches" ? "underline" : "none"
                    }
                    fontSize={"sm"}
                    color={pathname === "/matches" ? "#111" : "#111"}
                    cursor={"pointer"}
                    bold
                    onPress={() => {
                      navigate(ROUTES.USER_MATCHES);
                    }}
                  >
                    MATCHES
                  </Text>
                </HStack>
              </Box>
              <Box>
                <HStack>
                  <Text
                    textDecorationLine={
                      pathname === "/search" ? "underline" : "none"
                    }
                    fontSize={"sm"}
                    color={pathname === "/search" ? "#111" : "#111"}
                    cursor={"pointer"}
                    bold
                    onPress={() => {
                      dispatch(dynamicClear("getMembersByFilter"));

                      navigate(ROUTES.USER_SEARCH);
                    }}
                  >
                    SEARCH
                  </Text>
                </HStack>
              </Box>
              <Box>
                <HStack>
                  <Text
                    textDecorationLine={
                      pathname === "/requests" ? "underline" : "none"
                    }
                    fontSize={"sm"}
                    color={pathname === "/requests" ? "#111" : "#111"}
                    cursor={"pointer"}
                    bold
                    onPress={() => {
                      dispatch(dynamicClear("user_requests"));

                      // oneMainUserResponse?.is_profile_added &&
                      //   oneMainUserResponse?.is_preference_added &&
                      navigate(ROUTES.USER_REQUESTS);
                    }}
                  >
                    REQUESTS
                  </Text>
                </HStack>
              </Box>
            </HStack>
          </Hidden>
        </Box>

        <Hidden till="md">
          <Box
            justifyContent={"center"}
            // position="absolute"

            // right="10px"
            width={"200px"}
            // bottom="5px"
            // top="5px"
          >
            <HStack justifyContent={"center"} alignItems={"center"}>
              <Pressable
                onPress={() => {
                  showDrawer();
                }}
                w="80px"

                // style={{
                //   position: "absolute",
                //   right: 70,
                //   top: 20,
                // }}
              >
                <Box>
                  <MdOutlineNotifications color="#fff" size={30} />
                </Box>
                {notify_count?.length > 0 && (
                  <Box
                    style={{
                      position: "absolute",
                      height: 20,
                      width: 20,
                      borderRadius: 15,
                      backgroundColor: "red",
                      right: 50,
                      bottom: 18,
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 2000,
                    }}
                  >
                    <Text
                      style={{
                        color: "#fff",
                      }}
                      fontSize={12}
                    >
                      {notify_count?.length}
                    </Text>
                  </Box>
                )}
              </Pressable>
              <Menu
                w="150px"
                // position="absolute"
                // right="10px"
                // top="20px"
                style={{
                  border: "1px solid #d7d7d7",
                  boxShadow: "-2px 2px #7a7a7a42",
                  backgroundColor: "#fff",
                }}
                shouldOverlapWithTrigger={true}
                placement={"left bottom"}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      alignSelf="center"
                      variant="solid"
                      {...triggerProps}
                    >
                      <ImageLogo
                        src={
                          // getCroppedImageUrl()
                          getCroppedImageUrl(
                            image_list?.cropped_photo_url,
                            gender
                          )
                          // profile_photo
                          //   ? profile_photo
                          //   : "https://aim-assets.s3.ap-south-1.amazonaws.com/default-avatar.png"
                        }
                      />
                    </Pressable>
                  );
                }}
              >
                <Menu.Item
                  onPress={() => {
                    navigate(ROUTES.USER_MANAGE_PROFILE);
                  }}
                >
                  <Text fontSize={16} color={"#712e5a"} bold>
                    {user_name}
                  </Text>
                  <Text fontSize={12} color={"#712e5a"}>
                    {`( ${profile_id} )`}
                  </Text>
                </Menu.Item>

                <Menu.Item
                  onPress={() => {
                    navigate(ROUTES.USER_SETTINGS);
                  }}
                >
                  Settings
                </Menu.Item>

                <Menu.Item
                  onPress={() => {
                    Logout();
                  }}
                >
                  Logout
                </Menu.Item>
              </Menu>
            </HStack>
          </Box>
        </Hidden>
      </HStack>

      <Drawer
        className="ant-drawer-title ant-drawer-close"
        headerStyle={{
          backgroundColor: "#712e5a",
          textDecorationColor: "#fff",
        }}
        bodyStyle={{ backgroundColor: "#fef5fb" }}
        title="Notifications"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Box>
          <UserNotifications
            onClose={onClose}
            notifications_data={notifications_data}
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default HeaderBarUserWeb;

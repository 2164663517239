import {
  Box,
  Card,
  Divider,
  Hidden,
  HStack,
  Skeleton,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input } from "antd";
import "../../../index.css";

import { useDispatch } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { DEFAULT_COUNTRY_CODE, DEFAULT_STATE_CODE } from "@helpers/constants";
import { getStatesList, getDistrictsList } from "@helpers/functions";
import { CountriesOptions } from "@master_data/common_json/countries";
import _ from "lodash";
import { NationalityOptions } from "@master_data/profile_json/nationality";
import { useDynamicSelector } from "@services/redux";
import { DistrictsOptions } from "@master_data/common_json/districts";

const UserProfileFormLocationDetails = (props) => {
  const { getOptions, initial_values, form } = props;
  const { t } = useTranslation();

  const [countries_loading, setCountriesLoading] = useState(false);
  const [states_loading, setStatesLoading] = useState(false);
  const [districts_loading, setDistrictsLoading] = useState(false);

  const [ancestral_loading, setAncestralLoading] = useState(false);
  const [nationality_list, setNationalities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [ancestral, setAncestral] = useState([]);

  const dispatch = useDispatch();
  const country = Form.useWatch("country", form);
  const state = Form.useWatch("state", form);

  const { loading: my_profile_loading } = useDynamicSelector("my_profile");

  useEffect(() => {
    let nationality_list = NationalityOptions?.map((x) => ({
      name: x.nationality,
      value: _.snakeCase(x.nationality),
    }));
    setNationalities(nationality_list);
  }, []);

  useEffect(() => {
    setAncestralLoading(true);
    let _ancestral = [];
    if (DEFAULT_COUNTRY_CODE && DEFAULT_STATE_CODE) {
      try {
        // _ancestral = getDistrictsList(DEFAULT_COUNTRY_CODE, DEFAULT_STATE_CODE);
        _ancestral = DistrictsOptions;
        _ancestral.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        _ancestral = _ancestral?.map((x) => ({
          name: x.name,
          value: _.snakeCase(x.name),
        }));
      } catch (e) {}
    }
    setAncestral(_ancestral);
    setAncestralLoading(false);
  }, []);

  useEffect(() => {
    setCountriesLoading(true);
    let _countries = [];
    try {
      _countries = CountriesOptions;
    } catch (e) {}
    _countries = _countries?.map((x) => ({
      name: x.name,
      value: _.snakeCase(x.i_c),
    }));
    console.log("_countries", _countries);
    setCountries(_countries);
    setCountriesLoading(false);
  }, []);

  useEffect(() => {
    setStatesLoading(true);
    let _states = [];
    if (country) {
      console.log("country", country);

      try {
        _states = getStatesList(_.snakeCase(country));
        _states.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      } catch (e) {}
    }
    setStates(_states);
    setStatesLoading(false);
  }, [country]);

  useEffect(() => {
    setDistrictsLoading(true);
    let _districts = [];
    if (country && state) {
      try {
        _districts = getDistrictsList(country, state);
        _districts.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      } catch (e) {}
    }
    setDistricts(_districts);
    setDistrictsLoading(false);
  }, [state]);
  const handleStateChange = (val) => {
    if (val) {
      form.setFieldsValue({
        district: null,
      });
    }
  };
  return (
    <>
      {my_profile_loading ? (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("location_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <Hidden from="md">
            <Box width={"100%"}>
              <Skeleton.Text lines={5} />
            </Box>
          </Hidden>
          <Hidden till="md">
            <HStack space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
          </Hidden>
        </Card>
      ) : (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={14} bold color={"#712e5a"}>
              {t("location_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <VForm.Select
            label={t("country")}
            field={"country"}
            loading={countries_loading}
            showSearch={true}
            options={countries}
            labelField={"name"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Country is required",
              },
            ]}
          />
          <VForm.Select
            label={t("state")}
            field={"state"}
            loading={states_loading}
            showSearch={true}
            onChange={handleStateChange}
            options={states}
            labelField={"name"}
            valueField={"i_c"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "State is required",
              },
            ]}
          />
          <VForm.Select
            label={t("district")}
            field={"district"}
            loading={districts_loading}
            showSearch={true}
            options={districts}
            labelField={"name"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "District is required",
              },
            ]}
          />
          <VForm.Select
            label={t("anchestor_origin")}
            field={"ancestor_origin"}
            loading={ancestral_loading}
            showSearch={true}
            options={ancestral}
            labelField={"name"}
            valueField={"value"}
            form_type={"profile"}
            // rules={[
            //   {
            //     required: true,
            //     message: "Anchestor Origin is required",
            //   },
            // ]}
          />
          {/* <VForm.TextBox
            label={t("anchestor_origin")}
            field={"anchestor_origin"}
            form_type={"profile"}
          /> */}

          <VForm.Select
            label={t("citizenship")}
            field={"citizenship"}
            showSearch={true}
            options={nationality_list}
            labelField={"name"}
            valueField={"value"}
            form_type={"profile"}
            // rules={[
            //   {
            //     required: true,
            //     message: "Citizenship is required",
            //   },
            // ]}
          />
        </Card>
      )}
    </>
  );
};
export default UserProfileFormLocationDetails;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  VStack,
  Text,
  HStack,
  ScrollView,
  Drawer,
  Pressable,
} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { navigate } from "@helpers/navigator";

import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  StopOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import {
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
  get_all_verifier_query,
  delete_verifier_mutation,
  get_all_members_query,
  delete_member_mutation,
  get_all_members_for_report_query,
  update_member_mutation,
} from "@services/redux";
import AntdModal from "../ui/antd_modal";
import {
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Tooltip,
  Space,
  Button,
  Input,
  Modal,
} from "antd";
import { showNotification } from "@helpers/notify";
import ActionButton from "../ui/dialog/action_button";
import VerifierDetails from "./manage_members_details";
import ManageMembersDetails from "./manage_members_details";
import moment from "moment";
import { debounce } from "lodash";
import { BiUpArrowAlt } from "react-icons/bi";
import { useRef } from "react";
import { generateExcelFromJSON } from "@helpers/functions";
import ManageMemberFilter from "./manage_member_filter";
import { AiFillEye } from "react-icons/ai";
import { CgUnblock } from "react-icons/cg";
import ManageMembersDetailsAdmin from "./manage_members_details_admin";
import colors from "@helpers/colors";
import { MaritalStatusOptions } from "@master_data/common_json/marital_status";
import { CreatedForOptions } from "@master_data/profile_json/created_for";
import { ReligionsOptions } from "@master_data/common_json/religions";
import { CasteOptions } from "@master_data/common_json/castes";
import { SubCastesOptions } from "@master_data/common_json/sub_castes";
import { EducationsOptions } from "@master_data/common_json/educations";
import { SubEducationsOptions } from "@master_data/common_json/sub_education";
import { EmploymentTypesOptions } from "@master_data/common_json/employment_types";
import { FamilyStatusOptions } from "@master_data/profile_json/family_status";
import { DistrictsOptions } from "@master_data/common_json/districts";
import { StatesOptions } from "@master_data/common_json/states";

const ManageMembersList = () => {
  const dispatch = useDispatch();
  const top_ref = useRef();

  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [delete_user_id, set_delete_user_id] = useState("");
  const [reject_reason, setRejectReason] = useState("");

  const [report_data, setReportData] = useState([]);
  const [current, setCurrent] = useState(1);

  const { status: updateStatus, error: updateError } =
    useDynamicSelector("updateMember");
  const {
    status: deleteStatus,
    error: deleteError,
    loading: deleteLoading,
  } = useDynamicSelector("deleteMember");
  const {
    items: members_items,
    loading: manage_members_list_loading,
    pagination: members_pagination,
  } = useDynamicSelector("manage_members_list");
  const {
    items: manage_members_for_report_items,
    loading: manage_members_for_report_loading,
  } = useDynamicSelector("manage_members_for_report");

  const handleRejectModalCancel = () => {
    setIsRejectModalOpen(false);
  };

  const showRejectModal = (record) => {
    setIsRejectModalOpen(true);
    set_delete_user_id(record?.id);
  };
  const handlePagePosition = () => {
    const element = top_ref?.current;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);
  const goToTop = () => {
    debouncedHandleTitleClick();
  };
  const handleEdit = (item) => {
    setActionItem(item);
    // setActionItem({
    //   name: item?.member_profile?.name,
    //   dob: item?.member_profile?.dob,
    //   gender: item?.member_profile?.gender,
    // });
    setActionType("edit");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const getAllMembersForReportList = () => {
    let keys = [{ key: "manage_members_for_report", loading: true }];
    let variables = {
      page_number: current,
      page_limit: 1000000,
      sort_by: "created",
    };
    dispatch(dynamicRequest(keys, get_all_members_for_report_query, variables));
  };

  const getAllMembersList = (search_string) => {
    let keys = [{ key: "manage_members_list", loading: true }];
    let variables = {
      search_string: search_string ? search_string : "",
      page_number: current,
      page_limit: 10,
      sort_by: "created",
    };
    dispatch(dynamicRequest(keys, get_all_members_query, variables));
  };

  const handleMemberSearch = (e) => {
    if (e.target.value?.length > 3) {
      getAllMembersList(e.target.value);
    } else if (e.target.value?.length === 0) {
      getAllMembersList();
    }
  };
  const handleEnterSearch = (e) => {
    getAllMembersList(e.target.value);
  };

  useEffect(() => {
    console.log(
      "manage_members_for_report_items",
      manage_members_for_report_items
    );
    if (manage_members_for_report_items?.length > 0) {
      setReportData(manage_members_for_report_items);
    }
  }, [manage_members_for_report_items]);

  useEffect(() => {
    if (updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
        message: t("member_updated"),
      });
      dispatch(dynamicClear("updateMember"));

      getAllMembersList();
      getAllMembersForReportList();
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: t("member_deleted"),
      });
      handleRejectModalCancel();
      setRejectReason("");
      dispatch(dynamicClear("deleteMember"));

      getAllMembersList();
    } else if (updateError) {
      showNotification({
        type: "error",
        message: updateError?.message,
      });
      dispatch(dynamicClear("updateMember"));
    } else if (deleteError) {
      showNotification({
        type: "error",
        message: deleteError?.message,
      });
      handleRejectModalCancel();
      setRejectReason("");
      dispatch(dynamicClear("deleteMember"));
    }
  }, [updateStatus, deleteStatus, updateError, deleteError]);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "manage_members_list", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_members_query, {
        page_number: page_number,
        page_limit: page_limit,
        sort_by: "created",
      })
    );
    setCurrent(page_number);
  };

  const handleDelete = () => {
    let keys = [{ key: "deleteMember", loading: true }];
    let variables = {
      id: delete_user_id,
      delete_reason: reject_reason,
    };
    dispatch(dynamicRequest(keys, delete_member_mutation, variables));
  };
  const handleBlockAndUnblock = (id, value) => {
    let keys = [{ key: "updateMember", loading: true }];
    let variables = {
      id,
      data: {
        is_blocked_by_admin: value,
      },
    };
    dispatch(dynamicRequest(keys, update_member_mutation, variables));
  };

  const handleView = (item) => {
    navigate(`/member-details-view/${item?.id}`);
  };

  useEffect(() => {
    getAllMembersForReportList();
    getAllMembersList();
  }, []);

  const fieldMappings = [
    {
      label: "Name",
      field: "name",
      options: null,
    },
    {
      label: "Profile Number",
      field: "profile_number",
      options: null,
    },
    {
      label: "Created For",
      field: "created_for",
      options: CreatedForOptions,
    },
    {
      label: "Mobile",
      field: "mobile",
      options: null,
    },
    {
      label: "Gender",
      field: "gender",
      options: null,
    },
    {
      label: "Religion",
      field: "religion",
      options: ReligionsOptions,
    },
    {
      label: "Caste",
      field: "caste",
      options: CasteOptions,
    },
    {
      label: "Sub Caste",
      field: "sub_caste",
      options: SubCastesOptions,
    },
    {
      label: "Educations",
      field: "educations",
      options: EducationsOptions,
    },
    {
      label: "Sub Education",
      field: "sub_educations",
      options: SubEducationsOptions,
    },
    {
      label: "Occupation",
      field: "employment_type",
      options: EmploymentTypesOptions,
    },
    {
      label: "Family Status",
      field: "family_status",
      options: FamilyStatusOptions,
    },
    {
      label: "Created At",
      field: "created",
      options: null,
    },
    {
      label: "Last Login",
      field: "last_login_datetime",
      options: null,
    },
    {
      label: "Date of Birth",
      field: "dob",
      options: null,
    },
    {
      label: "Marital Status",
      field: "marital_status",
      options: MaritalStatusOptions,
    },
    {
      label: "District",
      field: "district",
      options: DistrictsOptions,
    },
    {
      label: "City",
      field: "city",
      options: null,
    },
    {
      label: "State",
      field: "state",
      options: StatesOptions,
    },
    {
      label: "Pincode",
      field: "pincode",
      options: null,
    },
    {
      label: "Franchise",
      field: "franchise_name",
      options: null,
    },
    {
      label: "Promo Code",
      field: "promo_code",
      options: null,
    },

    // {
    //   label: "Number of Matches",
    //   field: "no_of_matches",
    //   options: null,
    // },
    {
      label: "Request Sent",
      field: "sent_request_count",
      options: null,
    },
    {
      label: "Request Received",
      field: "received_request_count",
      options: null,
    },
    {
      label: "Request Accepted",
      field: "accepted_request_count",
      options: null,
    },
    // {
    //   label: "Subscription",
    //   field: "subscription",
    //   options: null,
    // },
    {
      label: "Subscription Taken On",
      field: "subscription_from",
      options: null,
    },
    {
      label: "Subscription Status",
      field: "subscription_status",
      options: null,
    },
    {
      label: "Profile Status",
      field: "profile_status",
      options: null,
    },
  ];

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "",
      align: "left",
      key: "name",
      width: 100,
      fixed: "left",
      render: (record) => {
        return <Text>{record?.name}</Text>;
      },
    },
    {
      title: t("table:profile_number"),
      dataIndex: "",
      key: "profile_number",
      align: "left",
      render: (record) => {
        return <Text>{record?.profile_number}</Text>;
      },
    },
    {
      title: t("mobile"),
      dataIndex: "",
      align: "left",
      key: "mobile",
      width: 120,
      render: (record) => {
        return <Text>{record?.mobile}</Text>;
      },
    },

    {
      title: t("table:gender"),
      dataIndex: "",
      key: "gender",
      align: "left",
      render: (record) => {
        return <Text>{record?.gender === "m" ? "Male" : "Female"}</Text>;
      },
    },
    {
      title: t("table:dob"),
      dataIndex: "",
      key: "username",
      align: "left",
      render: (record) => {
        return <Text>{moment(record?.dob).format("DD/MM/YYYY")}</Text>;
      },
    },
    {
      title: "Created On",
      dataIndex: "",
      align: "left",
      key: "name",
      render: (record) => {
        return (
          <Text>{moment(record?.created).format("DD/MM/YYYY h:mm a")}</Text>
        );
      },
    },
    {
      title: t("sent_req_count"),
      dataIndex: "sent_request_count",
      key: "sent_request_count",
      align: "left",
      // render: (record) => {
      //   return (
      //     <Text>
      //       {record?.member_profile?.gender === "m" ? "Male" : "Female"}
      //     </Text>
      //   );
      // },
    },
    {
      title: t("receive_req_count"),
      dataIndex: "received_request_count",
      key: "received_request_count",
      align: "left",
      // render: (record) => {
      //   return (
      //     <Text>
      //       {record?.member_profile?.gender === "m" ? "Male" : "Female"}
      //     </Text>
      //   );
      // },
    },
    {
      title: t("app_used_count"),
      dataIndex: "app_used_count",
      key: "app_used_count",
      align: "left",
    },
    {
      title: "Pincode",
      dataIndex: "",
      align: "left",
      key: "pincode",
      render: (record) => {
        return <Text>{record?.pincode || "-"}</Text>;
      },
    },
    {
      title: "Franchise",
      dataIndex: "",
      align: "left",
      key: "franchise_name",
      render: (record) => {
        return <Text>{record?.franchise_name || "-"}</Text>;
      },
    },
    {
      title: "Promo Code",
      dataIndex: "",
      align: "left",
      key: "promo_code",
      render: (record) => {
        return <Text>{record?.promo_code || "-"}</Text>;
      },
    },
    {
      title: "Profile Status",
      dataIndex: "",
      align: "left",
      key: "profile_status",
      render: (record) => {
        return (
          <Text
            fontFamily={"MontSerrat-SemiBold"}
            color={
              record?.profile_status === "Verified"
                ? colors.green2
                : record?.profile_status === "Rejected"
                ? colors.red
                : colors.gray
            }
          >
            {record?.profile_status || "-"}
          </Text>
        );
      },
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <Space>
            <Space>
              <Typography.Link onClick={() => handleView(record)}>
                <Space>
                  <Tooltip title="View" placement="bottom">
                    <EyeOutlined />
                    {/* <AiFillEye color="#111" size={20} /> */}
                  </Tooltip>
                </Space>
              </Typography.Link>
            </Space>
            {!record.is_blocked_by_admin ? (
              <Popconfirm
                title="Sure to Block?"
                onConfirm={() => handleBlockAndUnblock(record?.id, true)}
              >
                <Space>
                  <Tooltip title="Block" placement="bottom">
                    <StopOutlined />
                  </Tooltip>
                </Space>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Sure to Unblock?"
                onConfirm={() => handleBlockAndUnblock(record?.id, false)}
              >
                <Space>
                  <Tooltip title="Unblock" placement="bottom">
                    <CgUnblock size={20} />
                  </Tooltip>
                </Space>
              </Popconfirm>
            )}
            <Typography.Link onClick={() => handleEdit(record)}>
              <Space>
                <Tooltip title="Edit" placement="bottom">
                  <EditOutlined />
                </Tooltip>
              </Space>
            </Typography.Link>
            {/* <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            > */}
            <Pressable
              onPress={() => {
                showRejectModal(record);
              }}
            >
              <Space>
                <Tooltip title="Delete" placement="bottom">
                  <DeleteOutlined />
                </Tooltip>
              </Space>
            </Pressable>

            {/* </Popconfirm> */}
          </Space>
        );
      },
    },
  ];

  return (
    <Box height={"100vh"} ref={top_ref}>
      <Box width="100%" mt="20px" marginBottom="20px">
        <Box top="0px" zIndex="2">
          <Text fontSize="md" fontWeight="bold">
            {`${t("total_members")} (${
              members_pagination?.total_count
                ? members_pagination?.total_count
                : 0
            })`}
          </Text>
        </Box>
        <ManageMemberFilter />

        <Box my={5} width={"150px"}>
          <Button
            loading={manage_members_for_report_loading}
            colorScheme={"danger.700"}
            onClick={() => {
              generateExcelFromJSON(
                report_data,
                "MembersList.xlsx",
                fieldMappings
              );
            }}
            style={{
              height: "50px",
              label: t("table:back"),
              variant: "outline",
            }}
          >
            {manage_members_for_report_loading ? "Wait..." : "Download Report"}
          </Button>
        </Box>

        <VStack space={5}>
          <Box style={{ alignItems: "flex-end" }}>
            <Input
              placeholder="Search Member"
              onChange={handleMemberSearch}
              suffix={<SearchOutlined />}
              allowClear
              style={{ width: 200 }}
              onPressEnter={handleEnterSearch}
            />
          </Box>
          <Table
            columns={columns}
            dataSource={members_items}
            pagination={false}
            loading={manage_members_list_loading}
            scroll={{
              x: 1300,
            }}
          />
          <HStack alignItems={"center"}>
            <Box width={"70%"}>
              <Pagination
                total={members_pagination?.total_count}
                showSizeChanger={true}
                current={current}
                hideOnSinglePage={false}
                onChange={fetchData}
                pageSizeOptions={[5, 10, 15, 20, 50, 100, 1000]}
              />
            </Box>
            <Box alignItems={"flex-end"} my={5} width={"30%"}>
              <Button
                colorScheme={"danger.700"}
                onClick={() => {
                  goToTop();
                }}
                style={{
                  label: t("table:back"),
                  variant: "outline",
                }}
              >
                <HStack width={"100%"} alignItems={"center"}>
                  <BiUpArrowAlt size={20} />
                  <Text>{"Go to Top"}</Text>
                </HStack>
              </Button>
            </Box>
          </HStack>
        </VStack>
      </Box>

      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        header={t("update_member")}
        destroyOnClose={true}
        component={
          <ManageMembersDetailsAdmin
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
            type={actionType === "add" ? "add" : "update"}
          />
        }
      />
      <Modal
        title="Reject Profile"
        open={isRejectModalOpen}
        onOk={() => {
          handleDelete();
        }}
        onCancel={handleRejectModalCancel}
        confirmLoading={deleteLoading}
      >
        <Box pt={"10px"} marginBottom={"10px"}>
          <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
            {t("reason_for_rejection")}
          </Text>
        </Box>

        <Box width={"470px"} my={5}>
          <Input
            size="2xl"
            height={"50px"}
            placeholder="Reason"
            onChange={(e) => {
              setRejectReason(e.target.value);
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};
export default ManageMembersList;

import { gql } from "@apollo/client";

export const get_user_search_filter = gql`
  query getMembersByFilter(
    $filter: member_filter_input
    $page_number: Float
    $page_limit: Float
  ) {
    getMembersByFilter(
      filter: $filter
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        member_profile {
          id
          name
          dob
          gender
        }
        is_shortlisted
        member_cover_photo {
          is_main_photo
          photo_url
          photo_view
          cropped_photo_url
        }
        member_interest_status {
          status
          request_id
        }
        member_root {
          profile_number
          last_login_datetime
        }
      }

      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

import UserSettingsSideBar from "@views/components/user_settings/user_settings_sidebar";
import { Box, HStack } from "native-base";
import React, { useEffect, useState } from "react";

import ScrollView from "@views/components/ui/scroll_view/scroll_view";
import UserSettingsContent from "@views/components/user_settings/user_settings_content";
import { dynamicRequest, getMyPhoto, getMyProfile } from "@services/redux";
import { useDispatch } from "react-redux";
import { retrieveItem } from "@helpers/storage";

const UserSettings = (props) => {
  const dispatch = useDispatch();
  const result = retrieveItem("is_preference_added");
  const getMyPhotos = () => {
    let get_my_photo_key = [{ key: "my_photo", loading: true }];
    let get_my_photo_query = getMyPhoto;
    let get_my_photo_variables = {};
    dispatch(
      dynamicRequest(
        get_my_photo_key,
        get_my_photo_query,
        get_my_photo_variables
      )
    );
  };

  const getUserDetails = () => {
    let get_my_profile = [{ key: "my_profile", loading: true }];
    let get_my_profile_query = getMyProfile;
    let get_my_profile_variables = {};

    dispatch(
      dynamicRequest(
        get_my_profile,
        get_my_profile_query,
        get_my_profile_variables
      )
    );
  };

  useEffect(() => {
    getMyPhotos();
    getUserDetails();
  }, []);
  return (
    <Box backgroundColor={"#fef5fb"} flex={1}>
      <Box>
        <UserSettingsContent />
      </Box>
    </Box>
  );
};
export default UserSettings;

export const FamilyValueOptions = [
  {
    value: "orthodox",
    p_o: [],
    en_label: "Orthodox",
    ta_label: "மரபுவழி குடும்பம்",
  },
  {
    value: "traditional",
    p_o: [],
    en_label: "Traditional",
    ta_label: "பாரம்பரிய குடும்பம்",
  },
  {
    value: "moderate",
    p_o: [],
    en_label: "Moderate",
    ta_label: "நடுத்தர குடும்பம்",
  },
  {
    value: "liberal",
    p_o: [],
    en_label: "Liberal",
    ta_label: "பரந்த கொள்கையடைய",
  },
];

import { gql } from "@apollo/client";

export const create_element_mutation = gql`
  mutation createElement($data: element_input) {
    create_element: createElement(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_element_mutation = gql`
  mutation updateElement($id: String!, $data: element_input) {
    update_element: updateElement(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_element_mutation = gql`
  mutation deleteElement($id: String!) {
    delete_element: deleteElement(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_all_elements_query = gql`
  query getAllElements {
    elements: getAllElements {
      items {
        id
        name
        element_options {
          id
          en_label
          ta_label
          parent_element_number_value_greater_than_equal
          parent_element_number_value_lesser_than_equal
          parent_element_options {
            id
            element_id
            value
            en_label
          }
        }
      }
    }
  }
`;

export const create_element_option_mutation = gql`
  mutation createElementOption($data: element_option_input) {
    create_element_option: createElementOption(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_element_option_mutation = gql`
  mutation updateElementOption(
    $id: String!
    $data: element_option_update_input
  ) {
    update_element_option: updateElementOption(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_element_option_mutation = gql`
  mutation deleteElementOption($id: String!) {
    delete_element_option: deleteElementOption(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_element_options_query = gql`
  query getElementOptions(
    $element_id: String
    $reference_id: String
    $page_number: Float
    $page_limit: Float
  ) {
    element_options: getElementOptions(
      element_id: $element_id
      reference_id: $reference_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      reference_id
      items {
        id
        sort_order
        value
        number_value
        range_from_value
        range_to_value
        parent_element_id
        parent_element {
          id
          name
        }
        parent_element_number_value_greater_than_equal
        parent_element_number_value_lesser_than_equal
        parent_element_options {
          id
          en_label
        }
        en_label
        ta_label
        element {
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

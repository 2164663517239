import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, Center } from "native-base";
import { getCountry, getState } from "@helpers/get_country_state_city";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
  incomplete_payment_profiles,
} from "@services/redux";
import { Button, Pagination, Table } from "antd";
import { CalculateAge, CalculateAgeNumber } from "@helpers/calculate_age";
import { navigateBack } from "@helpers/navigator";
import moment from "moment";
import {
  get_all_invoice_report,
  get_all_invoice_report_download,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_invoice_report";
import {
  generateExcelFromJSON,
  getUserDetailsFromValue,
} from "@helpers/functions";
import { SubCastesOptions } from "@master_data/common_json/sub_castes";
import { CasteOptions } from "@master_data/common_json/castes";

const AdminInvoiceReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [report_data, setReportData] = useState([]);

  const {
    items: reports_items,
    loading,
    pagination,
  } = useDynamicSelector("getInvoiceReport");

  const {
    items: get_all_invoice_download_items,
    loading: get_all_invoice_download_loading,
  } = useDynamicSelector("get_all_invoice_download");

  const get_invoice_report_list = () => {
    let key = [{ key: "getInvoiceReport", loading: true }];
    let query = get_all_invoice_report;
    let variable = {
      page_number: current,
      page_limit: current_limit,
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  // useEffect(() => {
  //   let keys = [{ key: "incomplete_payments_profiles", loading: true }];
  //   dispatch(
  //     dynamicRequest(keys, incomplete_payment_profiles, {
  //       page_number: current,
  //       page_limit: current_limit,
  //     })
  //   );
  // }, []);
  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "getInvoiceReport", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_invoice_report, {
        page_number: page_number,
        page_limit: page_limit,
      })
    );
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    if (get_all_invoice_download_items?.length > 0) {
      setReportData(get_all_invoice_download_items);
    }
  }, [get_all_invoice_download_items]);

  const getReports = () => {
    let keys = [{ key: "get_all_invoice_download", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_invoice_report_download, {
        page_number: 1,
        page_limit: 1000000,
      })
    );
  };
  const fieldMappings = [
    {
      label: "Invoice No",
      field: "invoice_no",
    },
    {
      label: "Invoice Date",
      field: "invoice_date",
    },

    {
      label: "Profile ID",
      field: "profile_id",
    },
    {
      label: "Name",
      field: "name",
    },
    {
      label: "Caste",
      field: "caste",
    },
    {
      label: "Sub Caste",
      field: "sub_caste",
    },
    {
      label: "Plan Name",
      field: "plan_name",
    },
    {
      label: "Plan Amount",
      field: "plan_amount",
    },
    {
      label: "Discount Amount",
      field: "discount_amount",
    },
    {
      label: "Promocode Amount",
      field: "discount_amount",
    },
    {
      label: "After Discount",
      field: "after_discount",
    },
    {
      label: "Basic Price",
      field: "basic_price",
    },
    {
      label: "SGST 9 %",
      field: "sgst_price",
    },
    {
      label: "CGST 9 %",
      field: "cgst_price",
    },
    {
      label: "Total Amount",
      field: "total_amount",
    },
    {
      label: "Franchise/Branch Name",
      field: "franchise_name",
    },
  ];

  let columns = [
    {
      title: "Invoice No",
      dataIndex: "invoice_no",
      align: "centre",
      key: "invoice_no",
      fixed: "left",
    },
    {
      title: "Invoice Date",
      dataIndex: "",
      align: "centre",
      key: "invoice_date",
      fixed: "left",
      render: (items) => {
        return (
          <>
            <Text>
              {moment(items?.invoice_date).format("DD/MM/YYYY hh:mm a")}
            </Text>
          </>
        );
      },
    },
    {
      title: "User ID",
      dataIndex: "profile_id",
      align: "centre",
      key: "user id",
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "centre",
      key: "user_name",
    },
    {
      title: "Caste",
      dataIndex: "",
      align: "centre",
      key: "caste",
      render: (record) => {
        return (
          <>
            <Text>
              {getUserDetailsFromValue(CasteOptions, record?.caste) || "-"}
            </Text>
          </>
        );
      },
    },
    {
      title: "Sub Caste",
      dataIndex: "",
      align: "centre",
      key: "sub_caste",
      render: (record) => {
        return (
          <>
            <Text>
              {getUserDetailsFromValue(SubCastesOptions, record?.sub_caste) ||
                "-"}
            </Text>
          </>
        );
      },
    },
    {
      title: "Premium Plan",
      dataIndex: "plan_name",
      align: "lefcentret",
      key: "mobile",
    },
    {
      title: "Plan Amount",
      dataIndex: "plan_amount",
      align: "centre",
      key: "location",
    },
    {
      title: "Promocode Discount",
      dataIndex: "discount_amount",
      align: "left",
      key: "promocode discount",
    },
    {
      title: "After Discount",
      dataIndex: "after_discount",
      align: "centre",
      key: "after discount",
    },
    {
      title: "Basic Price",
      dataIndex: "basic_price",
      align: "centre",
      key: "subscription_plan",
    },
    {
      title: "SGST 9 %",
      dataIndex: "sgst_price",
      align: "centre",
      key: "state_id",
    },
    {
      title: "CGST 9%",
      dataIndex: "cgst_price",
      align: "centre",
      key: "subscription_plan",
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      align: "centre",
      key: "subscription_plan",
    },

    {
      title: "Franchise/Branch Name",
      dataIndex: "",
      align: "center",
      key: "branch_name",
      render: (items) => {
        return (
          <>
            <Text>{items?.franchise_name || "-"}</Text>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    get_invoice_report_list();
  }, []);

  return (
    <Center>
      <Box width="100%" mt="20px" marginBottom="20px">
        <Box width="100%" top="0px" zIndex="2" mb="5">
          <Text fontSize="md" fontWeight="bold">
            {`${t("invoice_report")} (${
              pagination?.total_count ? pagination?.total_count : 0
            })`}
          </Text>
        </Box>
        <Box my={5} width={"150px"}>
          <Button
            loading={get_all_invoice_download_loading}
            colorScheme={"danger.700"}
            onClick={() => {
              generateExcelFromJSON(
                report_data,
                "InvoiceReport.xlsx",
                fieldMappings
              );
            }}
            style={{
              height: "50px",
              label: t("table:back"),
              variant: "outline",
            }}
          >
            {get_all_invoice_download_loading ? "Wait..." : "Download Report"}
          </Button>
        </Box>
        <Box my={5} width={"80px"}>
          <Button
            colorScheme={"danger.700"}
            onClick={() => {
              navigateBack();
            }}
            style={{
              label: t("table:back"),
              variant: "outline",
            }}
          >
            Back
          </Button>
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              columns={columns}
              pagination={false}
              loading={loading}
              dataSource={reports_items}
              scroll={{ x: 1500 }}
            />
          </Wrapper>
          <Pagination
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
            total={pagination?.total_count}
          />
        </VStack>
      </Box>
    </Center>
  );
};

export default AdminInvoiceReport;

import { gql } from "@apollo/client";

export const create_admin_subscription_plan_mutation = gql`
  mutation createSubscriptionPlan($data: subscription_plan_input) {
    create_subscription_plan: createSubscriptionPlan(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_admin_subscription_plan_mutation = gql`
  mutation updateSubscriptionPlan(
    $id: String!
    $data: subscription_plan_input
  ) {
    update_subscription_plan: updateSubscriptionPlan(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_subscription_plans_query = gql`
  query getSubscriptionPlans($page_number: Float, $page_limt: Float) {
    subscription_plans: getSubscriptionPlans(
      page_number: $page_number
      page_limit: $page_limt
    ) {
      items {
        id
        en_label
        ta_label
        period_in_days
        allowed_purchase_count
        actual_price
        discounted_price
        description
        is_recommended
        is_active
        allowed_contact_details_view
        order_id
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_subscription_plan_mutation = gql`
  mutation deleteSubscriptionPlan($id: String!) {
    delete_subscription_plan: deleteSubscriptionPlan(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const addMemberContactViewMutation = gql`
  mutation addMemberContactView($id: String) {
    addMemberContactView(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const activatePlanByOperator = gql`
  mutation createUserSubscriptionPlanByOperator(
    $data: create_user_subscription_by_operator_input
  ) {
    createUserSubscriptionPlanByOperator(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const get_order_status = gql`
  query getOrderStatus($order_id: String!) {
    getOrderStatus(order_id: $order_id) {
      order_id
      status
    }
  }
`;

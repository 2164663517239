import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Input, Button, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import {
  dynamicRequest,
  get_franchise_operators,
  useDynamicSelector,
  create_operator_mutation,
  update_operator_mutation,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { Box, HStack, Text, useBreakpointValue } from "native-base";
import "../../../index.css";
import { random } from "lodash";
import { useParams } from "react-router-dom";

const OperatorDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, type, franchise_id } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [random_string, setRandomString] = useState("");

  const { items: get_franchise_list } = useDynamicSelector(
    "get_all_franchises_for_operators"
  );
  const { loading: create_operator_loading } =
    useDynamicSelector("registerOperator");
  const { loading: update_operator_loading } =
    useDynamicSelector("updateOperator");
  useEffect(() => {
    let keys = [{ key: "get_all_franchises_for_operators", loading: true }];
    dispatch(dynamicRequest(keys, get_franchise_operators));
  }, []);
  useEffect(() => {
    if (type === "add") {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, type]);

  const handleSubmit = (values) => {
    values.franchise_id = franchise_id;
    values.mobile = String(values.mobile);
    if (initialValues.id) {
      values.system_user_id = initialValues.system_user_id;
      let variables = {
        id: initialValues.id,
        data: values,
      };
      let keys = [{ key: "updateOperator", loading: true }];
      dispatch(dynamicRequest(keys, update_operator_mutation, variables, "M"));
    } else {
      let variables = {
        data: values,
      };
      let keys = [{ key: "registerOperator", loading: true }];
      dispatch(dynamicRequest(keys, create_operator_mutation, variables, "M"));
    }
  };
  const columns = useBreakpointValue({
    base: 1,
    md: 2,
  });
  const handleChange = (b, a) => {};

  // useEffect(() => {
  //   console.log("initialValues", initialValues);
  //   if (random_string) {
  //     const updatedValues = {
  //       ...initialValues,
  //       password: random_string,
  //     };
  //     console.log("updatedValues", updatedValues);

  //     form.setFieldsValue(updatedValues);
  //   }
  // }, [random_string]);
  return (
    <Form
      form={form}
      name={"operator_list"}
      layout={"vertical"}
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.Grid columns={columns} spacingY={10} spacingX={10}>
        <VForm.TextBox
          label={t("name")}
          field={"name"}
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        />
        <VForm.Number
          label={t("mobile")}
          field={"mobile"}
          rules={[
            {
              required: true,
              message: "Mobile is required",
            },
          ]}
        />
        <VForm.TextBox
          label={t("username")}
          field={"username"}
          rules={[
            {
              required: initialValues?.id ? false : true,
              message: "Name is required",
            },
          ]}
          disabled={initialValues?.id ? true : false}
        />

        <VForm.Password
          label={t("password")}
          field={"password"}
          rules={[
            {
              required: initialValues?.id ? false : true,
              message: "Password is required",
            },
          ]}
        />

        {/* <VForm.Select
          label={t("franchise")}
          field={"franchise_id"}
          showSearch={"true"}
          options={get_franchise_list}
          labelField={"name"}
          valueField={"id"}
          rules={[
            {
              required: true,
              message: "Franchise is required",
            },
          ]}
        /> */}
      </VForm.Grid>

      {/* <Box mt={2} mx={5}>
          <Button
            colorScheme={"danger.700"}
            onClick={() => {
              const alphanumeric =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
              let sixDigitString = "";
              for (let i = 0; i < 6; i++) {
                sixDigitString += alphanumeric.charAt(
                  Math.floor(Math.random() * alphanumeric.length)
                );
              }
              setRandomString(sixDigitString);
              console.log("Generated string:", sixDigitString);
            }}
            style={{
              label: t("table:back"),
              variant: "outline",
            }}
          >
            Generate Password
          </Button>
        </Box> */}

      <HStack space="3" justifyContent={"flex-end"}>
        <Button onClick={props.close}>{t("close")}</Button>
        <VForm.Button
          loading={create_operator_loading || update_operator_loading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
      </HStack>
    </Form>
  );
};
export default OperatorDetails;

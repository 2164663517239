import { Box } from "native-base";
import React, { useEffect, useState } from "react";
import { navigate } from "@helpers/navigator";

import { clearLocalStorage } from "@helpers/storage";

const Logout = () => {
  useEffect(() => {
    clearLocalStorage();

    navigate("/login");
  }, []);
  return <Box flex={1}></Box>;
};
export default Logout;

import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
  useParams,
  useLocation,
} from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import dayjs from "dayjs";
import React, { useState } from "react";
import Select from "react-select";
import {
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input as Input_pincode,
  ScrollView,
  Pressable,
} from "native-base";
import { Scrollbars } from "react-custom-scrollbars-2";

import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { DatePicker, Button, Input, TimePicker, InputNumber } from "antd";
import toast from "react-hot-toast";
import {
  dynamicClear,
  dynamicRequest,
  getCreatedOptions,
  registerUser,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { storeItem } from "@helpers/storage";
import { useTranslation } from "react-i18next";
import { backgroundColor } from "styled-system";
import moment from "moment";
import colors from "@helpers/colors";
import { CreatedForOptions } from "@master_data/profile_json/created_for";
import { DistrictsOptions } from "@master_data/common_json/districts";

const RegisterForm = (props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { t } = useTranslation();
  const format = "HH:mm";
  const { _mob, _email } = props;
  const [email, setEmail] = useState(_email);
  const [created_for_options, setCreatedForOptions] = useState([]);
  const [district_options, setDistrictOptions] = useState([]);
  const [mobile, setMobile] = useState(_mob);
  const [mobile_otp, setMobileOTP] = useState("");
  const [pin_code, setPinCode] = useState("");
  const [name, setName] = useState();
  const [gender, setGender] = useState();
  const [created_for, setCreatedFor] = useState();
  const [dob, setDob] = useState();
  const [time, setTime] = useState("00:00");
  const [birth_place, setBirthPlace] = useState();

  const [terms_condition, setTermsCondition] = useState(false);

  const [password, setPassword] = useState("");
  const [my_promo_code, setPromoCode] = useState();
  const [confirm_password, setConfirmPassword] = useState();

  const [showPass, setShowPass] = React.useState(false);

  const { items: created_for_options_list } = useDynamicSelector(
    "getCreatedForElementOptions"
  );
  const {
    error,
    loading: registerLoading,
    status: registerStatus,
    name: registeredUserName,
    profile_id,
    is_preference_added,
    is_profile_added,
    gender: user_gender,
    session_id,
    page_accesses,
    page_component_accesses,
    home_page,
  } = useDynamicSelector("register_user");

  useEffect(() => {
    storeItem("session_id", session_id);
    storeItem("name", registeredUserName);
    storeItem("profile_id", profile_id);
    storeItem("gender", user_gender);
    storeItem("page_accesses", page_accesses);
    storeItem("page_component_accesses", page_component_accesses);

    if (error) {
      toast.error(error.message);
      dispatch(dynamicClear("register_user"));
    }
    if (home_page) {
      if (is_preference_added) {
        navigate(ROUTES.USER_HOME);
        toast.success(`Welcome ${registeredUserName}`);
      } else if (is_profile_added) {
        navigate(ROUTES.USER_MANAGE_PREFERENCE);
      } else {
        navigate(ROUTES.USER_MANAGE_PROFILE);
      }
      // navigate(ROUTES.USER_HOME);
    }
  }, [session_id, error, registeredUserName, profile_id, home_page]);

  useEffect(() => {
    if (registerStatus === "success") {
      toast.success(t("registered_successfully"));
      dispatch(dynamicClear("register_user"));
    }
  }, [registerStatus]);

  useEffect(() => {
    if (search?.split("=").length) {
      setPromoCode(search.split("=")[1]);
    }
  }, [search]);
  useEffect(() => {
    let all_items = [];
    if (CreatedForOptions && gender) {
      for (let s = 0; s < CreatedForOptions.length; s++) {
        let created_for_option = CreatedForOptions[s];
        all_items.push({
          label: created_for_option.en_label,
          value: created_for_option.value,
        });
      }
      if (gender === "m") {
        all_items = all_items?.filter((x) => {
          return (
            x.label !== "Daughter" &&
            x.label !== "Sister" &&
            x.label !== "Granddaughter"
          );
        });
      } else if (gender === "f") {
        all_items = all_items?.filter((x) => {
          return (
            x.label !== "Son" && x.label !== "Brother" && x.label !== "Grandson"
          );
        });
      }

      setCreatedForOptions(all_items);
    }
    if (DistrictsOptions) {
      let _districts = DistrictsOptions?.map((x) => ({
        label: x.name,
        value: x.name,
      }));
      _districts.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      );
      setDistrictOptions(_districts);
    }
  }, [CreatedForOptions, DistrictsOptions, gender]);

  const validateConditions = () => {
    if (confirm_password !== password) {
      toast.error(t("Password does not match"));
    } else if (!created_for) {
      toast.error(t("Please Select Created For"));
    } else if (!mobile_otp) {
      toast.error(t("Please Enter OTP"));
    } else if (!dob) {
      toast.error(t("Please Select Date of Birth"));
    } else if (!name) {
      toast.error(t("Please Enter Name"));
    } else if (!gender) {
      toast.error(t("Please Select Gender"));
    } else if (!time) {
      toast.error(t("Please Select Time"));
    } else if (!birth_place) {
      toast.error(t("Please Enter Birth Place"));
    } else if (!pin_code) {
      toast.error(t("Please Enter Pincode"));
    } else if (!terms_condition) {
      toast.error(t("Choose Terms and Conditions"));
    } else if (
      confirm_password === password &&
      name &&
      terms_condition &&
      mobile_otp &&
      dob &&
      gender &&
      time &&
      birth_place
    ) {
      Register();
    }
  };

  const Register = () => {
    let values = {
      pincode: pin_code,
      created_for: created_for,
      mobile: mobile,
      mobile_otp: mobile_otp,
      name: name,
      gender: gender,
      dob: dob,
      password: password,
      time_of_birth: time,
      place_of_birth: birth_place,
      promo_code: my_promo_code ? my_promo_code : null,
    };
    let user_register_key = [{ key: "register_user", loading: true }];
    let user_register_query = registerUser;
    let user_register_variables = { data: values };
    dispatch(
      dynamicRequest(
        user_register_key,
        user_register_query,
        user_register_variables,
        "M"
      )
    );
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      validateConditions();
    }
  };

  const handleInputChange = (text) => {
    const numericValue = text.replace(/[^0-9]/g, "");
    setPinCode(numericValue);
  };

  return (
    <Scrollbars
      style={{
        flex: 1,
        height: "100%",
      }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        _light={{
          bg: "#fff",
        }}
        _dark={{
          bg: "#fff",
        }}
        space="3"
        justifyContent="space-between"
        borderTopRightRadius={{
          base: "2xl",
          md: "xl",
        }}
        borderBottomRightRadius={{
          base: "0",
          md: "xl",
        }}
        borderTopLeftRadius={{
          base: "2xl",
          md: "0",
        }}
      >
        <VStack space="7">
          <VStack space="3">
            <Box>
              <ScrollView>
                {/* <Box paddingTop={4} paddingBottom={2}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    Email
                  </Text>
                </Box>

                <Input
                  _disabled={true}
                  //   variant="rounded"
                  placeholder="Email"
                  // onChangeText={(text) => setEmail(text)}
                  value={email}
                />
                <Box paddingTop={4} paddingBottom={2}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    Email OTP
                  </Text>
                </Box>

                <Input
                  //   variant="rounded"
                  placeholder="Email OTP"
                  onChangeText={(text) => setEmailOTP(text)}
                  value={email_otp}
                /> */}
                <Box paddingTop={4} paddingBottom={2}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    Mobile
                  </Text>
                </Box>

                <Input
                  disabled={true}
                  //   variant="rounded"
                  placeholder="Mobile"
                  // onChangeText={(text) => setEmail(text)}
                  value={mobile}
                />
                <Box paddingTop={4} paddingBottom={2}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    Mobile OTP
                  </Text>
                </Box>

                <Input
                  //   variant="rounded"
                  placeholder="Mobile OTP"
                  onChange={(e) => setMobileOTP(e.target.value)}
                  value={mobile_otp}
                />

                <Box paddingTop={4} paddingBottom={2}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    Bride / Groom Name
                  </Text>
                </Box>

                <Input
                  //   variant="rounded"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <HStack alignItems={"center"}>
                  <VStack width={"100%"}>
                    <HStack>
                      <Text color={colors.red}>*</Text>

                      <Text mx={1}>{t("name_instructions")}</Text>
                    </HStack>
                  </VStack>
                </HStack>
                <Box pt={"10px"} marginBottom={"10px"}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    Gender
                  </Text>
                </Box>

                <Select
                  onChange={(e) => {
                    setGender(e.value);
                  }}
                  style={{
                    rounded: 25,
                  }}
                  // value={gender}
                  options={[
                    {
                      label: "Female",
                      value: "f",
                    },
                    {
                      label: "Male",
                      value: "m",
                    },
                  ]}
                />

                <Box pt={"10px"} marginBottom={"10px"}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    Profile Created For
                  </Text>
                </Box>

                <Select
                  onChange={(e) => {
                    setCreatedFor(e.value);
                  }}
                  style={{
                    rounded: 25,
                  }}
                  // value={gender}
                  options={created_for_options}
                />

                {gender && (
                  <Box paddingTop={4} paddingBottom={2}>
                    <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                      Date of Birth
                    </Text>
                    <DatePicker
                      showToday={false}
                      disabledDate={(current) =>
                        gender === "f"
                          ? current && current > moment().subtract(18, "years")
                          : current && current > moment().subtract(21, "years")
                      }
                      onChange={(e, date) => {
                        setDob(date);
                      }}
                    />
                  </Box>
                )}
                <Box paddingTop={4} paddingBottom={2}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    Time of Birth
                  </Text>
                  <TimePicker
                    defaultValue={moment(time, format)}
                    format={format}
                    onChange={(value, dateString) => {
                      setTime(dateString);
                    }}
                  />
                </Box>
                <Box paddingTop={4} paddingBottom={2}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    Birth Place
                  </Text>
                </Box>

                <Select
                  onChange={(e) => {
                    setBirthPlace(e.value);
                  }}
                  style={{
                    rounded: 25,
                  }}
                  options={district_options}
                />
                {/* <Input
                  //   variant="rounded"
                  placeholder="Birth Place"
                  onChange={(e) => setBirthPlace(e.target.value)}
                  value={birth_place}
                /> */}
                <Box paddingTop={4} paddingBottom={2}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    Pincode
                  </Text>
                </Box>
                <Input_pincode
                  placeholder="Pincode"
                  onChangeText={handleInputChange}
                  value={pin_code}
                  maxLength={6}
                  keyboardType="numeric"
                />

                <Box pt={"10px"} marginBottom={"10px"}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    Password
                  </Text>
                </Box>

                <Input.Password
                  //   variant="rounded"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />

                <Box pt={"10px"} marginBottom={"10px"}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    Confirm Password
                  </Text>
                </Box>

                <Input.Password
                  onKeyPress={handleKeypress}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  //   variant="rounded"
                  placeholder="Confirm Password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  value={confirm_password}
                />
                <Box paddingTop={4} paddingBottom={2}>
                  <Text color={"#64748B"} fontWeight={"600"} fontSize={15}>
                    {"Promo Code (Optional)"}
                  </Text>
                </Box>

                <Input
                  disabled={search ? true : false}
                  //   variant="rounded"
                  placeholder="Promo Code"
                  onChange={(e) => setPromoCode(e.target.value)}
                  value={my_promo_code}
                />

                <Box pt={"10px"} paddingBottom={"10px"} flexDirection="row">
                  <HStack alignItems={"center"} width={"100%"}>
                    <Checkbox
                      size="lg"
                      // backgroundColor={"#712e5a"}
                      borderColor={"#712e5a"}
                      onChange={(e) => {
                        setTermsCondition(e);
                      }}
                      value="one"
                    >
                      <Text px={2} color="#596372">
                        I have read and agree to the
                      </Text>
                    </Checkbox>
                    <Pressable
                      // onPress={() => {
                      //   navigate(ROUTES.POLICY);
                      // }}
                      mb={1}
                    >
                      <a href="/terms" target="_blank">
                        <b>Term of Services</b>
                      </a>
                    </Pressable>
                  </HStack>
                </Box>
              </ScrollView>
            </Box>
            <Box my={5}>
              <Button
                loading={registerLoading}
                onClick={() => {
                  validateConditions();
                }}
                type={"primary"}
              >
                Register
              </Button>
            </Box>
          </VStack>
        </VStack>
        <HStack
          mb="4"
          space="1"
          safeAreaBottom
          alignItems="center"
          justifyContent="center"
          mt={{
            base: "auto",
            md: "8",
          }}
        >
          <Text
            _light={{
              color: "coolGray.800",
            }}
            _dark={{
              color: "coolGray.400",
            }}
          >
            Already have an account?
          </Text>
          <Link
            _text={{
              fontWeight: "bold",
              textDecoration: "none",
            }}
            _light={{
              _text: {
                color: "#712e5a",
              },
            }}
            _dark={{
              _text: {
                color: "#712e5a",
              },
            }}
            onPress={() => {
              navigate(ROUTES.LOGIN);
            }}
          >
            Login
          </Link>
        </HStack>
      </VStack>
    </Scrollbars>
  );
};

export default RegisterForm;

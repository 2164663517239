export const AnnualIncomeOptions = [
  {
    value: null,
    p_o: [],
    r_f_v: -99,
    r_t_v: -99,
    en_label: "Nil",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 100000,
    r_t_v: 200000,
    en_label: "1-2 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 200000,
    r_t_v: 300000,
    en_label: "2-3 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 300000,
    r_t_v: 400000,
    en_label: "3-4 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 400000,
    r_t_v: 500000,
    en_label: "4-5 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 500000,
    r_t_v: 600000,
    en_label: "5-6 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 600000,
    r_t_v: 700000,
    en_label: "6-7 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 700000,
    r_t_v: 800000,
    en_label: "7-8 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 800000,
    r_t_v: 900000,
    en_label: "8-9 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 900000,
    r_t_v: 1000000,
    en_label: "9-10 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 1000000,
    r_t_v: 1500000,
    en_label: "10-15 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 1500000,
    r_t_v: 2000000,
    en_label: "15-20 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 2000000,
    r_t_v: 2500000,
    en_label: "20-25 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 2500000,
    r_t_v: 3000000,
    en_label: "25-30 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 3000000,
    r_t_v: 4000000,
    en_label: "30-40 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 4000000,
    r_t_v: 5000000,
    en_label: "40-50 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 5000000,
    r_t_v: 6000000,
    en_label: "50-60 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 6000000,
    r_t_v: 7000000,
    en_label: "60-70 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 7000000,
    r_t_v: 8000000,
    en_label: "70-80 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 8000000,
    r_t_v: 9000000,
    en_label: "80-90 Lakhs",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 9000000,
    r_t_v: 10000000,
    en_label: "90 Lakhs - 1 Crore",
    ta_label: null,
  },
  {
    value: null,
    p_o: [],
    r_f_v: 10000000,
    r_t_v: 110000000,
    en_label: "1 Crore above",
    ta_label: null,
  },
];

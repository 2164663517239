import React from "react";
import { Box, Center, Spinner, Text } from "native-base";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { navigate } from "@helpers/navigator";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  get_order_status,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

const PaymentComplete = () => {
  // const location = useLocation();
  const { pathname } = useLocation();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  console.log("location", location);

  const { status } = useDynamicSelector("getOrderStatus");
  console.log("getOrderStatus", status);

  const getOrderStatus = (order_id) => {
    let key = [{ key: "getOrderStatus", loading: true }];
    let query = get_order_status;
    let variables = {
      order_id: order_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    let url = pathname.split("/");
    let params_order_id;
    if (url[2]) {
      params_order_id = url[2];
    }
    // const params = queryString.parse(location.search);

    if (params_order_id) {
      console.log("params_order_id", params_order_id);
      getOrderStatus(params_order_id);
    }
  }, [location.search]);

  useEffect(() => {
    if (status === "succeeded") {
      toast.success(t("payment_success"));

      navigate("/settings");
    } else if (status === "failed") {
      toast.error(t("payment_failed"));
      navigate("/settings");
    }
  }, [status]);

  return (
    <Center width={"100%"} height={"100%"} flex={1}>
      <Spinner />
      <Text>{t("processing_payment")}</Text>
    </Center>
  );
};

export default PaymentComplete;

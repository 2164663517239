import React from "react";
import { Card, Layout, Row } from "antd";
const { Header } = Layout;
import logo from "@assets/icons/SM.png";
import { Box, Button, Text } from "native-base";

const SuperAdminLayoutHeader = ({ children }) => {
  return (
    <Header
      style={{
        backgroundColor: "#191919",
        padding: 0,
        height: "50px",
        borderBottom: "1px solid #564f4f",
      }}
    >
      <Row style={{ height: "100%" }} span={4}>
        <Card
          bordered={false}
          bodyStyle={{ padding: 0 }}
          style={{
            backgroundColor: "transparent",
            borderRight: "1px solid #564f4f",
            color: "#ffffff",
            textAlign: "center",
            width: "256px",
            padding: 0,
            height: "100%",
            fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={logo} width={90} />
        </Card>
      </Row>
    </Header>
  );
};
export default SuperAdminLayoutHeader;

export const MarriedSistersOptions = [
  {
    value: 1,
    p_o: [1, 2, 3, 4, 5],
    en_label: "1",
    ta_label: null,
  },
  {
    value: 2,
    p_o: [2, 3, 4, 5],
    en_label: "2",
    ta_label: null,
  },
  {
    value: 3,
    p_o: [3, 4, 5],
    en_label: "3",
    ta_label: null,
  },
  {
    value: 4,
    p_o: [4, 5],
    en_label: "4",
    ta_label: null,
  },
  {
    value: 5,
    p_o: [5],
    en_label: "5",
    ta_label: null,
  },
  {
    value: 0,
    p_o: [0, 1, 2, 3, 4, 5],
    en_label: "None",
    ta_label: "இல்லை",
  },
];

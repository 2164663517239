import { gql } from "@apollo/client";
export const get_all_rejected_members = gql`
  query getRejectedMembers(
    $franchise_id: String
    $page_number: Float
    $page_limit: Float
    $search_string: String
  ) {
    get_all_rejected_members: getRejectedMembers(
      franchise_id: $franchise_id
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        name
        gender
        mobile
        dob
        country
        state
        district
        profile_id
        rejected_datetime
        created_datetime
        franchise_name
        reject_reason
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;

export const get_all_rejected_members_reports = gql`
  query getRejectedMembers(
    $franchise_id: String
    $page_number: Float
    $page_limit: Float
    $search_string: String
  ) {
    get_all_rejected_members_report: getRejectedMembers(
      franchise_id: $franchise_id
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        name
        gender
        mobile
        dob
        country
        state
        district
        profile_id
        rejected_datetime
        created_datetime
        franchise_name
        reject_reason
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;

import {
  Box,
  Card,
  Divider,
  Hidden,
  HStack,
  Skeleton,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import "../../../index.css";

import { useDispatch } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { Modal, Upload, Form, Button, Input } from "antd";
import { HeightOptions } from "@master_data/profile_json/height";
import { WeightOptions } from "@master_data/profile_json/weight";
import { ComplexionOptions } from "@master_data/profile_json/complexion";
import { MaritalStatusOptions } from "@master_data/common_json/marital_status";
import { NumberOfChildrenOptions } from "@master_data/profile_json/number_of_children";
import { BodyTypesOptions } from "@master_data/profile_json/body_type";
import { PhysicalStatusOptions } from "@master_data/common_json/physical_status";
import { MotherTongueOptions } from "@master_data/common_json/mother_tongue";
import { EatingHabitsOptions } from "@master_data/common_json/eating_habits";
import { DrinkingHabitsOptions } from "@master_data/common_json/drinking_habits";
import { SmokingHabitsOptions } from "@master_data/common_json/smoking_habits";
import { useDynamicSelector } from "@services/redux";

const UserProfileFormBasicDetailsOperator = (props) => {
  const { getOptions, form } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const marital_status = Form.useWatch("marital_status", form);

  const { loading: my_profile_loading } = useDynamicSelector("my_profile");
  return (
    <>
      {my_profile_loading ? (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("basic_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <Hidden from="md">
            <Box width={"100%"}>
              <Skeleton.Text lines={5} />
            </Box>
          </Hidden>
          <Hidden till="md">
            <HStack space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
          </Hidden>
        </Card>
      ) : (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("basic_details")}
            </Text>
          </Box>
          <Divider my={5} />
          {/* <VForm.Select
            label={t("profile_created_for")}
            field={"created_for"}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
          /> */}
          <VForm.Select
            label={t("height")}
            field={"height"}
            options={getOptions(HeightOptions, "Height")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Height is required",
              },
            ]}
          />
          <VForm.Select
            label={t("weight")}
            field={"weight"}
            options={getOptions(WeightOptions, "Weight")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            // rules={[
            //   {
            //     required: true,
            //     message: "Weight is required",
            //   },
            // ]}
          />
          <VForm.Select
            label={t("complexion")}
            field={"complexion"}
            options={getOptions(ComplexionOptions, "Complexion")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Complexion is required",
              },
            ]}
          />
          <VForm.Select
            label={t("marital_status")}
            field={"marital_status"}
            options={getOptions(MaritalStatusOptions, "Marital Status")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Marital Status is required",
              },
            ]}
          />
          {marital_status && marital_status !== "never_married" && (
            <VForm.Select
              label={t("no_of_children")}
              field={"number_of_children"}
              options={getOptions(NumberOfChildrenOptions, "No. of Children")}
              labelField={"en_label"}
              valueField={"value"}
              form_type={"profile"}
            />
          )}
          <VForm.Select
            label={t("body_type")}
            field={"body_type"}
            options={getOptions(BodyTypesOptions, "Body Type")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            // rules={[
            //   {
            //     required: true,
            //     message: "Body Type is required",
            //   },
            // ]}
          />
          <VForm.Select
            label={t("physical_status")}
            field={"physical_status"}
            options={getOptions(PhysicalStatusOptions, "Physical Status")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Physical Status is required",
              },
            ]}
          />
          <VForm.Select
            label={t("mother_tongue")}
            field={"mother_tongue"}
            options={getOptions(MotherTongueOptions, "Mother Tongue")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Mother Tongue is required",
              },
            ]}
          />
          <VForm.Select
            label={t("eating_habit")}
            field={"eating_habit"}
            options={getOptions(EatingHabitsOptions, "Eating Habits")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            rules={[
              {
                required: true,
                message: "Eating Habits is required",
              },
            ]}
          />
          <VForm.Select
            label={t("drinking_habit")}
            field={"drinking_habit"}
            options={getOptions(DrinkingHabitsOptions, "Drinking Habits")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            // rules={[
            //   {
            //     required: true,
            //     message: "Drinking Habits is required",
            //   },
            // ]}
          />
          <VForm.Select
            label={t("smoking_habit")}
            field={"smoking_habit"}
            options={getOptions(SmokingHabitsOptions, "Smoking Habits")}
            labelField={"en_label"}
            valueField={"value"}
            form_type={"profile"}
            // rules={[
            //   {
            //     required: true,
            //     message: "Smoking Habits is required",
            //   },
            // ]}
          />
        </Card>
      )}
    </>
  );
};
export default UserProfileFormBasicDetailsOperator;

import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input,
  ScrollView,
} from "native-base";
import logo from "@assets/icons/transparent_sm.png";

import SM from "@assets/icons/hands.png";
import Entypo from "react-native-vector-icons/dist/Entypo";
import { Button } from "antd";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  admin_login_query,
} from "@services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { retrieveItem, storeItem } from "@helpers/storage";

const AdminLoginForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = React.useState(false);

  const {
    error,
    session_id,
    page_accesses,
    page_component_accesses,
    name,
    roles,
    home_page,
    loading: loginLoading,
  } = useDynamicSelector("admin_login");
  useEffect(() => {
    storeItem("session_id", session_id);
    storeItem("operator_name", name);
    storeItem("page_accesses", page_accesses);
    storeItem("page_component_accesses", page_component_accesses);
    storeItem("role", roles?.[0]?.name);
    const role = retrieveItem("role");

    if (error) {
      toast.error(error.message);
    }
    if (home_page) {
      if (home_page === "Super Admin") {
        navigate(ROUTES.ACCESS_RIGHTS);
      } else if (home_page === "Operator Home") {
        navigate(ROUTES.OPERATOR_HOME);
      } else if (home_page === "Verification") {
        navigate(ROUTES.ADMIN_CROP_PHOTO_VERIFICATION);
      } else if (home_page === "Admin") {
        navigate(ROUTES.ADMIN_SUBSCRIPTION_PLAN);
      } else if (home_page === "Franchise") {
        navigate(ROUTES.FRANCHISE_MEMBERS);
      } else if (home_page === "Telecaller Home") {
        navigate(ROUTES.TELE_CALLER_HOME);
      }
    }
  }, [session_id, error, home_page]);

  const Login = () => {
    let user_login_key = [{ key: "admin_login", loading: true }];
    let user_login_query = admin_login_query;
    let user_login_variables = { username: userName, password: password };
    dispatch(
      dynamicRequest(user_login_key, user_login_query, user_login_variables)
    );
  };
  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      Login();
    }
  };
  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        _light={{
          bg: "#fff",
        }}
        _dark={{
          bg: "coolGray.800",
        }}
        space="3"
        justifyContent="space-between"
        borderTopRightRadius={{
          base: "2xl",
          md: "xl",
        }}
        borderBottomRightRadius={{
          base: "0",
          md: "xl",
        }}
        borderTopLeftRadius={{
          base: "2xl",
          md: "0",
        }}
      >
        <VStack space="7">
          <Hidden till="md">
            <HStack justifyContent={"center"} alignItems="center">
              <Image
                cursor={"pointer"}
                px={2}
                py={2}
                width={40}
                height={40}
                alt="logo"
                resizeMode={"contain"}
                source={logo}
              />
              {/* <Text
                color={"#712e5a"}
                bold
                fontSize={{ base: 30, xs: 20, sm: 20, md: 25 }}
                textAlign={"center"}
                fontWeight="normal"
              >
                Standard Matrimony
              </Text> */}
            </HStack>
          </Hidden>
          <VStack>
            <VStack space="3">
              <Box mx={5}>
                {/* <Box alignItems={"center"}> */}
                <VStack>
                  <Text
                    pt={2}
                    color={"#64748B"}
                    fontWeight={"600"}
                    fontSize={15}
                  >
                    Username
                  </Text>

                  <Input
                    // variant="rounded"
                    placeholder="Username"
                    onChangeText={(text) => setUserName(text)}
                    value={userName}
                  />
                  {/* </VStack>
                    </Box>
                    <Box alignItems={"center"}>
                      <VStack width={"50%"}> */}
                  <Text
                    pt={2}
                    color={"#64748B"}
                    fontWeight={"600"}
                    fontSize={15}
                  >
                    Password
                  </Text>

                  <Input
                    w={{
                      base: "100%",
                      // xs: "100%",
                      // sm: "100%",
                      // md: "100%",
                      // lg: "100%",
                      // xl: "50%",
                    }}
                    type={showPass ? "" : "password"}
                    // variant="rounded"
                    placeholder="Password"
                    onChangeText={(text) => setPassword(text)}
                    value={password}
                    onKeyPress={handleKeypress}
                    InputRightElement={
                      <IconButton
                        variant="unstyled"
                        icon={
                          showPass ? <AiOutlineEye /> : <AiFillEye />
                          // <Icon
                          //   size="4"
                          //   color="coolGray.400"
                          //   as={Entypo}
                          //   name={showPass ? "eye-with-line" : "eye"}
                          // />
                        }
                        onPress={() => {
                          {
                            !showPass ? setShowPass(true) : setShowPass(false);
                          }
                        }}
                      />
                    }
                  />
                </VStack>
                {/* </Box> */}
              </Box>
              <Box mx={5} marginY={5}>
                <Button
                  loading={loginLoading}
                  type="primary"
                  onClick={() => {
                    if (userName.length && password.length) {
                      Login();
                    } else if (!userName.length) {
                      toast.error("Please enter valid username");
                    } else if (!password.length) {
                      toast.error("Please enter valid password");
                    }
                  }}
                >
                  SIGN IN
                </Button>
              </Box>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </ScrollView>
  );
};

export default AdminLoginForm;

import { Box, Card, Divider, HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input } from "antd";
import "../../../index.css";
import {
  dynamicRequest,
  getMyPreference,
  get_user_search_filter,
  updateMyPreference,
  updateUserProfile,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

import toast from "react-hot-toast";
import UserSearchFormBasicDetails from "./user_search_basic_details";
import UserSearchFormReligionDetails from "./user_search_religion_details";
import UserSearchFormProfessionalDetails from "./user_search_professional_details";
import UserSearchFormLocationDetails from "./user_search_location_details";
import { page_count } from "@helpers/constants";

const UserSearchForm = (props) => {
  const { t } = useTranslation();
  const { setFilterVariables, onSearchClose } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [initial_values, setInitialValues] = useState([]);
  const [show_photo, set_show_photo] = useState(false);

  const handleSubmit = async (values) => {
    setFilterVariables(values);
    let get_filter_key = [{ key: "getMembersByFilter", loading: true }];
    let get_filter_query = get_user_search_filter;
    let search_variables = {
      ...values,
      show_only_with_open_photos: show_photo,
    };
    let get_filter_variables = {
      filter: search_variables,
      page_number: 1,
      page_limit: page_count,
    };
    dispatch(
      dynamicRequest(get_filter_key, get_filter_query, get_filter_variables)
    );
  };

  const getOptions = (all_options, element_name, parent_key) => {
    // let _options = all_options?.filter((x) => x.element.name === element_name);
    let _options = all_options;

    if (parent_key) {
      let parent_element_option = eval(parent_key);
      _options = _options?.filter(
        (x) => x.p_o.indexOf(parent_element_option) > -1
      );
    }
    if (!_options) {
      _options = [];
    }

    return _options;
  };

  useEffect(() => {
    let _initial_values = JSON.parse(JSON.stringify(initial_values));
    if (_initial_values.countries?.length === 0) {
      _initial_values.countries = ["any"];
    }
    if (
      _initial_values.countries?.length > 0 &&
      _initial_values.states?.length === 0
    ) {
      _initial_values.states = ["any"];
    }
    if (
      _initial_values.countries?.length > 0 &&
      _initial_values.states?.length > 0 &&
      _initial_values.districts?.length === 0
    ) {
      _initial_values.districts = ["any"];
    }
    form.setFieldsValue(_initial_values);
  }, [initial_values]);

  return (
    <Box>
      <Form
        form={form}
        name={"preference_form"}
        className="user_form"
        layout={"horizontal"}
        onFinish={handleSubmit}
        initialValues={initial_values}
      >
        <Box
          width={"100%"}
          backgroundColor={"#712e5a"}
          zIndex={99}
          position={"sticky"}
          top={0}
        >
          <HStack alignItems={"center"}>
            <Box mx={5} width={"50%"}>
              <Text mb={2} color="#fff">
                {"Search by Preferences"}
              </Text>
            </Box>
            <Box mb={2} marginLeft={3} width={"30%"}>
              <Button color="#fff" type="default" htmlType="submit">
                {t("apply_filter")}
              </Button>
            </Box>
          </HStack>
        </Box>
        <Box>
          <UserSearchFormBasicDetails getOptions={getOptions} form={form} />
          <UserSearchFormReligionDetails getOptions={getOptions} form={form} />
          <UserSearchFormLocationDetails getOptions={getOptions} form={form} />
          <UserSearchFormProfessionalDetails
            getOptions={getOptions}
            form={form}
            set_show_photo={set_show_photo}
          />
        </Box>
      </Form>
    </Box>
  );
};
export default UserSearchForm;

export const DoshamsOptions = [
  {
    value: "any",
    p_o: [],
    en_label: "Any",
    ta_label: "ஏதேனும்",
  },
  {
    value: "no_dosham",
    p_o: [],
    en_label: "No Dosham",
    ta_label: "தோஷம் இல்லை",
  },
  {
    value: "naga_dosham",
    p_o: [],
    en_label: "Naga Dosham",
    ta_label: "நாக தோஷம்",
  },
  {
    value: "kala_sarpa_dosham",
    p_o: [],
    en_label: "Kala Sarpa Dosham",
    ta_label: "கால சர்ப்ப தோஷம்",
  },
  {
    value: "rahu_dosham",
    p_o: [],
    en_label: "Rahu Dosham",
    ta_label: "ராகு தோஷம்",
  },
  {
    value: "kethu_dosham",
    p_o: [],
    en_label: "Kethu Dosham",
    ta_label: "கேது தோஷம்",
  },
  {
    value: "kalathra_dosham",
    p_o: [],
    en_label: "Kalathra Dosham",
    ta_label: "களத்திர தோஷம்",
  },
  {
    value: "chevvai_dosham",
    p_o: [],
    en_label: "Chevvai Dosham",
    ta_label: "செவ்வாய் தோஷம்",
  },
  {
    value: "parikara_chevvai_dosham",
    p_o: [],
    en_label: "Parikara Chevvai",
    ta_label: "பரிகார செவ்வாய்",
  },
  {
    value: "rahu_kethu_dosham",
    p_o: [],
    en_label: "Rahu Kethu Dosham",
    ta_label: "ராகு கேது தோஷம்",
  },
];

import React from "react";
import { Box } from "native-base";

import TelecallerHomeList from "@views/components/telecaller_home/telecaller_home_list";

const TelecallerHome = () => {
  return (
    <>
      <Box width="100%">
        <TelecallerHomeList />
      </Box>
    </>
  );
};
export default TelecallerHome;

import {
  Box,
  Card,
  Divider,
  Hidden,
  HStack,
  Skeleton,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";

import { useTranslation } from "react-i18next";
import { Modal, Upload, Form, Button, Input } from "antd";
import "../../../index.css";

import { useDispatch } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { dynamicRequest, useDynamicSelector } from "@services/redux";

const UserProfileFormHoroscopeDetails = (props) => {
  const { initial_values, form, options_loading } = props;
  const { t } = useTranslation();
  const [hide_horoscope, setHideHoroscope] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (initial_values) {
      setHideHoroscope(initial_values);
    }
  }, [initial_values]);
  return (
    <>
      {options_loading ? (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={16} bold color={"#712e5a"}>
              {t("horoscope_details")}
            </Text>
          </Box>
          <Divider my={5} />
          <Hidden from="md">
            <Box width={"100%"}>
              <Skeleton.Text lines={3} />
            </Box>
          </Hidden>
          <Hidden till="md">
            <HStack space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
            <HStack mt={5} space={10} width={"90%"}>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
              <Box width={"50%"}>
                <Skeleton.Text lines={1} />
              </Box>
            </HStack>
          </Hidden>
        </Card>
      ) : (
        <Card backgroundColor={"#fff"} width={"90%"} mx={5} my={5}>
          <Box>
            <Text fontSize={14} bold color={"#712e5a"}>
              {t("horoscope_details")}
            </Text>
          </Box>
          <Divider my={5} />

          {hide_horoscope?.time_of_birth ? (
            <VForm.TextBox
              label={t("time_of_birth")}
              field={"time_of_birth"}
              disabled={true}
              form={form}
            />
          ) : (
            <VForm.TextBox label={t("time_of_birth")} field={"time_of_birth"} />
            // <VForm.Time label={t("time_of_birth")} field={"time_of_birth"} />
          )}
          {hide_horoscope?.place_of_birth ? (
            <VForm.TextBox
              label={t("place_of_birth")}
              field={"place_of_birth"}
              form_type={"profile"}
              disabled={true}
              form={form}
            />
          ) : (
            <VForm.TextBox
              label={t("place_of_birth")}
              field={"place_of_birth"}
              form_type={"profile"}
            />
          )}
        </Card>
      )}
    </>
  );
};
export default UserProfileFormHoroscopeDetails;

import React, { useEffect, useState } from "react";
import { Box, Button, ScrollView, VStack } from "native-base";
import { ROUTES } from "@views/routes/my_routes";
import SideBar from "@views/components/ui/side_bar";
import SideBarHeader from "@views/components/modules/sidebar/sidebar_header";
import SideBarFooter from "@views/components/modules/sidebar/sidebar_footer";

import {
  MdSubscriptions,
  MdStore,
  MdVerified,
  MdVerifiedUser,
  MdOutlineSupervisedUserCircle,
  MdOutlineReport,
  MdMoneyOff,
  MdCall,
} from "react-icons/md";

import HeaderBar from "@views/components/ui/header_bar/header_bar";
import FooterBar from "@views/components/ui/footer_bar";
// import ScrollView from "@views/components/ui/scroll_view";
import { useTranslation } from "react-i18next";
import PaymentPending from "@assets/icons/payment_pending.png";
import NotusedProfiles from "@assets/icons/not_used_profile.png";
import DeletedProfile from "@assets/icons/delete_profile.png";
import PaymentReport from "@assets/icons/payment_report.png";
import InvoiceReport from "@assets/icons/invoice.png";
import { HiOutlineDocumentReport } from "react-icons/hi";
import {
  BsFillPersonCheckFill,
  BsFillPersonFill,
  BsPersonFillCheck,
  BsTelephone,
} from "react-icons/bs";
import { retrieveItem } from "@helpers/storage";
import { AiFillAndroid, AiFillFileImage } from "react-icons/ai";
import { dynamicClear, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";

const AdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const [role, setRole] = useState(false);
  const [menu, setMenu] = useState([]);
  const roles = JSON.parse(localStorage.getItem("roles"));
  const force_logout = useDynamicSelector("force_logout");

  const { t } = useTranslation();
  let controlled_access = [];

  controlled_access = [
    {
      title: "Subscription Plans",
      key: "Admin",
      icon: <MdSubscriptions />,
      url: ROUTES.ADMIN_SUBSCRIPTION_PLAN,
    },
    {
      title: "View Members",
      key: "Telecaller Home",
      icon: <BsFillPersonFill />,
      url: ROUTES.TELE_CALLER_HOME,
    },

    {
      title: "Verifications",
      key: "Verification",
      icon: <MdVerified />,
      url: ROUTES.ADMIN_CROP_PHOTO_VERIFICATION,
    },
    {
      title: "User Details Verifications",
      key: "Verification",
      icon: <MdVerified />,
      url: ROUTES.ADMIN_USER_DETAILS_VERIFICATION,
    },
    {
      title: "Franchises",
      key: "Admin",
      icon: <MdStore />,
      url: ROUTES.ADMIN_FRANCHISE,
    },
    {
      title: "Verifiers",
      key: "Admin",
      icon: <BsFillPersonCheckFill />,
      url: ROUTES.VERIFIER,
    },
    {
      title: "Telecallers",
      key: "Admin",
      icon: <MdCall />,
      url: ROUTES.TELECALLER,
    },

    {
      title: "Manage Members",
      key: "Admin",
      icon: <BsFillPersonFill />,
      url: ROUTES.MANAGE_MEMBERS,
    },
    {
      title: "Franchise Members",
      key: "Franchise",
      icon: <BsFillPersonFill />,
      url: ROUTES.FRANCHISE_MEMBERS,
    },
    {
      title: "Ad Image",
      key: "Admin",
      icon: <AiFillFileImage />,
      url: ROUTES.AD_IMAGE,
    },

    {
      title: "Reports",
      key: "Admin",
      icon: <HiOutlineDocumentReport />,
      // url: ROUTES.ADMIN_REPORTS,
      subMenu: [
        {
          title: "No Profile Photo Update Members",
          icon: <MdMoneyOff />,
          url: ROUTES.NO_PROFILE_UPDATE,
        },
        {
          title: "Payment Tried Members",
          key: "Payment Tried Members",
          icon: (
            <img
              src={PaymentPending}
              alt={"PaymentPending"}
              className={"icon-size"}
            />
          ),
          url: ROUTES.PAYMENT_TRIED_MEMBERS,
        },
        {
          title: "Idle Members",
          key: "Idle Members",
          icon: (
            <img
              src={NotusedProfiles}
              alt={"not_used_profile"}
              className={"icon-size"}
            />
          ),
          url: ROUTES.NOT_USED_MEMBERS,
        },
        {
          title: "Deleted Members",
          key: "Deleted Members",
          icon: (
            <img
              src={DeletedProfile}
              alt={"not_used_profile"}
              className={"icon-size"}
            />
          ),
          url: ROUTES.DELETED_USERS,
        },
        {
          title: "Rejected Members",
          key: "Rejected Members",
          icon: (
            <img
              src={NotusedProfiles}
              alt={"not_used_profile"}
              className={"icon-size"}
            />
          ),
          url: ROUTES.REJECTED_USERS,
        },
        {
          title: "Payment Report",
          key: "Payment Report",
          icon: (
            <img
              src={PaymentReport}
              alt={"not_used_profile"}
              className={"icon-size"}
            />
          ),
          url: ROUTES.PAYMENT_REPORT,
        },
        {
          title: "Invoice Report",
          key: "Invoice Report",
          icon: (
            <img
              src={InvoiceReport}
              alt={"not_used_profile"}
              className={"icon-size"}
            />
          ),
          url: ROUTES.INVOICE_REPORT,
        },
        {
          title: "Users Interest Count",
          key: "Users Interest Count",
          icon: <BsFillPersonFill />,
          url: ROUTES.USERS_INTEREST_COUNT,
        },
      ],
    },
  ];

  useEffect(() => {
    if (force_logout === true) {
      handleLogout();
    }
  }, [force_logout]);

  useEffect(() => {
    const page_accesses = retrieveItem("page_accesses");
    let temp1;
    let temp2;
    let _menu = [];
    for (let i = 0; i < page_accesses?.length; i++) {
      temp1 = page_accesses[i];
      for (let j = 0; j < controlled_access?.length; j++) {
        temp2 = controlled_access[j];

        if (temp1 === temp2?.key) {
          _menu.push(temp2);
        }
      }
    }
    setMenu(_menu);
  }, []);

  const handleLogout = () => {
    dispatch(dynamicClear("admin_login"));
    clearLocalStorage();
    storeItem("session_id", "");
    storeItem("operator_name", "");
    dispatch(dynamicClear("force_logout"));

    history.push({
      pathname: `${ROUTES.ADMIN_LOGIN}`,
    });
  };

  return (
    <Box flexDirection="row" flexGrow="1">
      <SideBar
        // image={
        //   "https://iukozdkcdaihzlywgtkr.supabase.co/storage/v1/object/sign/camp-transfer-docs/sidebar-image.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjYW1wLXRyYW5zZmVyLWRvY3Mvc2lkZWJhci1pbWFnZS5wbmciLCJpYXQiOjE2NTE4MzU2NDMsImV4cCI6MTk2NzE5NTY0M30.DpxMnGhIKHY-_DtLRDsd0k8vPHt9JbXNsB2Yws2_Dl4"
        // }
        iconShape="round"
        iconBgColor={"none"}
        iconColor={"#ffffff"}
        iconSize={25}
        iconSelectedColor={"#330000"}
        titleColor={"#adadad"}
        titleActiveColor={"#ffffff"}
        headerHeight={"100px"}
        footerHeight="50px"
        menu={menu}
        headerComponent={<SideBarHeader />}
        footerComponent={<SideBarFooter />}
        projectName={"Standard Matrimony"}
      />
      <VStack flex={1}>
        <HeaderBar projectName={"Standard Matrimony"} />
        <ScrollView height={"80vh"} showsVerticalScrollIndicator={true}>
          <Box flex={1} mb={"30px"} ml="10px" mr="10px" px="30px">
            {children}
          </Box>
        </ScrollView>
        <FooterBar />
      </VStack>
    </Box>
  );
};
export default AdminLayout;

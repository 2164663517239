import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Box, VStack, Text, HStack, Hidden, Pressable } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import ActionButton from "@views/components/ui/dialog/action_button";
import AntdModal from "@views/components/ui/antd_modal/antd_modal";
import {
  delete_promo_code_franchise_mutation,
  dynamicRequest,
  useDynamicSelector,
  get_promo_code_franchises_query,
  dynamicClear,
  dynamicSet,
} from "@services/redux";
import {
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Space,
  Tooltip,
  Button,
  Modal,
} from "antd";
import { showNotification } from "@helpers/notify";
import AdminFranchisePromoCodeDetails from "./admin_franchise_promo_code_details";
import QRCode from "qrcode.react";
import { PROMO_URL } from "@helpers/constants";
import { navigate, navigateBack, navigateWithProps } from "@helpers/navigator";
import { AiOutlineUser } from "react-icons/ai";
const AdminFranchisePlanPromoCode = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [action_type, setActionType] = useState(null);
  const [action_item, setActionItem] = useState({});
  const [current_code, setCurrentCode] = useState("");
  const { t } = useTranslation();
  const [modal_open, setModalOpen] = useState(false);
  const [qr_modal_open, setQRModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const { id } = useParams();
  const { status: createStatus, error: createError } =
    useDynamicSelector("create_promo_code");
  const { status: updateStatus, error: updateError } =
    useDynamicSelector("update_promo_code");
  const { items: get_promo_code_by_franchises } = useDynamicSelector(
    "get_promo_code_by_franchises"
  );
  const qr_code = useDynamicSelector("qr_code");
  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useDynamicSelector("delete_promo_code");

  const handleAdd = (e) => {
    setActionType("add");
    setModalOpen(true);
  };
  const handleBack = () => {
    navigateBack();
  };
  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
  };
  const handleDelete = (record) => {
    let keys = [{ key: "delete_promo_code", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_promo_code_franchise_mutation, {
        id: record?.id,
      })
    );
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleQRModalClose = () => {
    setQRModalOpen(false);
    dispatch(dynamicClear("qr_code"));
  };
  useEffect(() => {
    let keys = [{ key: "get_promo_code_by_franchises", loading: true }];
    dispatch(
      dynamicRequest(keys, get_promo_code_franchises_query, {
        // page_number: current,
        // page_limit: 10,
        franchise_id: id,
      })
    );
  }, []);

  useEffect(() => {
    if (action_type === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${"Promo Code"} added successfully`,
      });
      let keys = [{ key: "get_promo_code_by_franchises", loading: true }];

      dispatch(
        dynamicRequest(keys, get_promo_code_franchises_query, {
          franchise_id: id,
        })
      );

      dispatch(dynamicClear("create_promo_code"));
    } else if (action_type === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
        message: `Promo Code Update Successfully`,
      });
      dispatch(dynamicClear("update_promo_code"));

      let keys = [{ key: "get_promo_code_by_franchises", loading: true }];

      dispatch(
        dynamicRequest(keys, get_promo_code_franchises_query, {
          franchise_id: id,
        })
      );
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `Promo Code deleted successfully`,
      });
      dispatch(dynamicClear("delete_promo_code"));

      let keys = [{ key: "delete_promo_code", loading: true }];

      dispatch(
        dynamicRequest(keys, get_promo_code_franchises_query, {
          franchise_id: id,
        })
      );
    }
  }, [createStatus, updateStatus, deleteStatus]);

  useEffect(() => {
    if (!modal_open) {
      setActionItem({});
    }
  }, [modal_open]);

  useEffect(() => {
    console.log("qr_code", qr_code);
  }, [qr_code]);

  const downloadQRCode = () => {
    let qrCodeURL = document.getElementById("qrCodeEl");
    qrCodeURL = qrCodeURL
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;

    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const fetchData = (value) => {
    let keys = [{ key: "get_promo_code_by_franchises", loading: true }];
    dispatch(
      dynamicRequest(keys, get_promo_code_franchises_query, {
        // page_number: value,
        // page_limit: 10,
        franchise_id: id,
      })
    );
    setCurrent(value);
  };
  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      align: "left",
      key: "name",
    },
    {
      title: t("table:code"),
      dataIndex: "code",
      align: "left",
      key: "code",
    },
    {
      title: t("discount_percentage"),
      dataIndex: "discount_percentage",
      align: "left",
      key: "discount_percentage",
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span style={{}}>
            <Space>
              <Typography.Link
                style={{
                  marginLeft: 5,
                }}
                onClick={() => handleEdit(record)}
                // style={{ marginRight: 8 }}
              >
                <Space>
                  <Tooltip title="Edit">
                    <EditOutlined />
                  </Tooltip>
                </Space>
              </Typography.Link>
              <Box marginLeft={5}>
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => handleDelete(record)}
                >
                  <Space>
                    <Tooltip title="Delete">
                      <DeleteOutlined />
                    </Tooltip>
                  </Space>
                </Popconfirm>
              </Box>
              <Pressable
                marginLeft={5}
                onPress={() => {
                  if (record?.code) {
                    dispatch(dynamicSet("qr_code", record?.code));

                    setQRModalOpen(true);
                    // downloadQRCode(record?.code);
                  }
                }}
              >
                {/* <div style={{ visibility: "hidden" }} className="App">
                  <QRCode
                    id="qrCodeEl"
                    size={150}
                    // value={`${PROMO_URL}${get_promo_code_by_franchises?.[0]?.code}`}
                    value={`${PROMO_URL}${record?.code}`}
                  />
                </div> */}

                <Space>
                  <Tooltip title="Download">
                    <DownloadOutlined />
                  </Tooltip>
                </Space>
              </Pressable>
              <Box
                style={{
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                <Typography.Link
                  onClick={() =>
                    navigateWithProps(
                      `/admin/promo-code-user/${record?.id}`,
                      record?.name
                    )
                  }
                >
                  <Space>
                    <Tooltip title="Franchise User">
                      <AiOutlineUser size={15} />
                    </Tooltip>
                  </Space>
                </Typography.Link>
              </Box>
            </Space>
          </span>
        );
      },
    },
  ];
  let header_actions = [
    {
      height: "50px",
      label: t("table:add_promo_code"),
      colorScheme: "danger.700",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box width="100%" mt="20px" marginBottom="20px">
        <Box position="sticky" top="0px" zIndex="2" bg="white">
          <Text fontSize="md" fontWeight="bold">
            {`${location?.state} - ${t("table:promo_code")}`}
          </Text>
        </Box>
        <Box my={5} width={"80px"}>
          <Button
            colorScheme={"danger.700"}
            onClick={() => {
              handleBack();
            }}
            style={{
              label: t("table:back"),
              variant: "outline",
            }}
          >
            Back
          </Button>
        </Box>

        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            {header_actions.map((headerAction, index) => (
              <Box key={`header-action-${index}`}>
                <ActionButton {...headerAction} />
              </Box>
            ))}
          </HStack>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={get_promo_code_by_franchises}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </VStack>
      </Box>

      {/* <div style={{ visibility: "hidden" }} className="App">
        <QRCode
          id="qrCodeEl"
          size={150}
          // value={`${PROMO_URL}${get_promo_code_by_franchises?.[0]?.code}`}
          value={`${PROMO_URL}${code}`}
        />
      </div> */}

      <AntdModal
        isOpen={modal_open}
        onClose={handleModalClose}
        header={
          action_type === "add"
            ? t("table:add_promo_code")
            : t("table:update_promo_code")
        }
        component={
          <AdminFranchisePromoCodeDetails
            label={action_type === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={action_item}
            id={id}
          />
        }
      />
      <Modal
        open={qr_modal_open}
        onCancel={handleQRModalClose}
        title={"QR Code"}
        onOk={downloadQRCode}
        okText={"Download"}
      >
        <QRCode
          id="qrCodeEl"
          size={150}
          // value={`${PROMO_URL}${get_promo_code_by_franchises?.[0]?.code}`}
          value={`${PROMO_URL}${qr_code}`}
        />
      </Modal>
    </>
  );
};
export default AdminFranchisePlanPromoCode;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  VStack,
  Text,
  HStack,
  Pressable,
  Center,
  Switch,
} from "native-base";
import {
  getCountry,
  getDistrict,
  getState,
} from "@helpers/get_country_state_city";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  useDynamicSelector,
  get_all_no_photo_members,
} from "@services/redux";
import { Button, Pagination, Table } from "antd";
import { CalculateAge, CalculateAgeNumber } from "@helpers/calculate_age";
import { navigateBack } from "@helpers/navigator";
import { PDFViewer } from "@react-pdf/renderer";
import moment from "moment";
import {
  complete_payment_query,
  complete_payment_report_query,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_completed_payment_members";
import { generateExcelFromJSON } from "@helpers/functions";

const PaymentReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [report_data, setReportData] = useState([]);

  // const {
  //   items: reports,
  //   loading,
  //   pagination,
  // } = useDynamicSelector("get_all_no_photo_members");
  // useEffect(() => {
  //   let keys = [{ key: "get_all_no_photo_members", loading: true }];
  //   dispatch(
  //     dynamicRequest(keys, get_all_no_photo_members, {
  //       page_number: current,
  //       page_limit: current_limit,
  //     })
  //   );
  // }, []);

  const {
    items: Reports_items,
    loading: Reports_loading,
    error: Reports_error,
    pagination,
  } = useDynamicSelector("getCompletedPaymentMembers");

  const {
    items: complete_payment_report_items,
    loading: complete_payment_report_loading,
  } = useDynamicSelector("complete_payment_report");

  useEffect(() => {
    get_all_payment_members();
    getReports();
  }, []);

  useEffect(() => {
    if (complete_payment_report_items?.length > 0) {
      setReportData(complete_payment_report_items);
    }
  }, [complete_payment_report_items]);

  const get_all_payment_members = () => {
    let key = [{ key: "getCompletedPaymentMembers", loading: true }];
    let query = complete_payment_query;
    let variable = {
      page_number: current,
      page_limit: current_limit,
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getReports = () => {
    let keys = [{ key: "complete_payment_report", loading: true }];
    dispatch(
      dynamicRequest(keys, complete_payment_report_query, {
        page_number: 1,
        page_limit: 1000000,
      })
    );
  };

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "getCompletedPaymentMembers", loading: true }];
    dispatch(
      dynamicRequest(keys, complete_payment_query, {
        page_number: page_number,
        page_limit: page_limit,
      })
    );
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
      render: (item, items) => {
        console.log("items", items);
        return items?.name;
      },
    },
    {
      title: "Age",
      dataIndex: "dob",
      align: "left",
      key: "dob",
      render: (record, i) => {
        return (
          <>
            <Text>{CalculateAge(record)}</Text>
          </>
        );
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      align: "left",
      key: "gender",
      render: (record, i) => {
        return (
          <>
            <Text>{record === "m" ? "Male" : "Female"}</Text>
          </>
        );
      },
    },
    {
      title: "Profile Number",
      dataIndex: "profile_id",
      align: "left",
      key: "profile_id",
      render: (item, items) => {
        return items?.profile_id;
      },
    },
    {
      title: "Location",
      dataIndex: "",
      align: "left",
      key: "location",
      render: (item, items) => {
        let country = items?.country;
        return (
          <>
            <Text>
              {items?.country ? <>{getCountry(items?.country)},</> : null}
              {items?.state ? <>{getState(items?.state, country)},</> : null}
              {items?.district ? <>{getDistrict(items?.district)}.</> : null}
            </Text>
          </>
        );
      },
    },
    {
      title: "Franchise Name",
      dataIndex: "",
      align: "center",
      key: "name",
      render: (item, items) => {
        return <Text>{items?.franchise_name || "-"}</Text>;
      },
    },
    {
      title: "Mobile",
      dataIndex: "",
      align: "center",
      key: "name",
      render: (item, items) => {
        return <Text>{items?.mobile || "-"}</Text>;
      },
    },
    {
      title: "Pincode",
      dataIndex: "",
      align: "center",
      key: "name",
      render: (item, items) => {
        return <Text>{items?.pincode || "-"}</Text>;
      },
    },
    {
      title: "Basic price",
      dataIndex: "basic_price",
      align: "left",
      key: "name",
      render: (item, items) => {
        return items?.basic_price;
      },
    },
    {
      title: "GST",
      dataIndex: "gst",
      align: "left",
      key: "name",
      render: (item, items) => {
        return items?.gst_price;
      },
    },
    {
      title: "Payment Type",
      dataIndex: "",
      align: "left",
      key: "name",
      render: (item, items) => {
        return items?.payment_type === "online" ? "Online" : "By Operator";
      },
    },
    {
      title: "Created On",
      dataIndex: "",
      align: "left",
      key: "name",
      render: (items) => {
        return (
          <Text>
            {moment(items?.complete_before).format("DD/MM/YYYY h:mm a")}
          </Text>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "",
      align: "left",
      key: "name",
      render: (items) => {
        return items?.total_price;
      },
    },
  ];
  const fieldMappings = [
    {
      label: "Name",
      field: "name",
    },
    {
      label: "Profile Number",
      field: "profile_id",
    },

    {
      label: "Mobile",
      field: "mobile",
    },
    {
      label: "Created At",
      field: "complete_before",
    },
    {
      label: "Date of Birth",
      field: "dob",
    },
    {
      label: "Gender",
      field: "gender",
    },
    {
      label: "Franchise",
      field: "franchise_name",
    },
    {
      label: "Pincode",
      field: "pincode",
    },
    {
      label: "District",
      field: "district",
    },
    {
      label: "Basic Price",
      field: "basic_price",
    },
    {
      label: "GST",
      field: "gst_price",
    },
    {
      label: "Total Price",
      field: "total_price",
    },
    {
      label: "Payment Type",
      field: "payment_type",
    },
  ];

  return (
    <Center>
      <Box width="90%" mt="20px" marginBottom="20px">
        <Box top="0px" zIndex="2" mb="5">
          <Text fontSize="md" fontWeight="bold">
            {`${t("payment_report")} (${
              pagination?.total_count ? pagination?.total_count : 0
            })`}
          </Text>
        </Box>
        <Box my={5} width={"150px"}>
          <Button
            loading={complete_payment_report_loading}
            colorScheme={"danger.700"}
            onClick={() => {
              generateExcelFromJSON(
                report_data,
                "PaymentReport.xlsx",
                fieldMappings
              );
            }}
            style={{
              height: "50px",
              label: t("table:back"),
              variant: "outline",
            }}
          >
            {complete_payment_report_loading ? "Wait..." : "Download Report"}
          </Button>
        </Box>
        <Box my={5} width={"80px"}>
          <Button
            colorScheme={"danger.700"}
            onClick={() => {
              navigateBack();
            }}
            style={{
              label: t("table:back"),
              variant: "outline",
            }}
          >
            Back
          </Button>
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              columns={columns}
              pagination={false}
              loading={Reports_loading}
              dataSource={Reports_items}
            />
          </Wrapper>
          <Pagination
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
            total={pagination?.total_count}
          />
        </VStack>
      </Box>
    </Center>
  );
};

export default PaymentReport;

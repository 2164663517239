import { gql } from "@apollo/client";

export const updateUserProfile = gql`
  mutation updateUserProfile($data: user_profile_update_input) {
    updateUserProfile(data: $data) {
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateMyPreference = gql`
  mutation updateMyPreference($data: user_preference_update_input) {
    updateMyPreference(data: $data) {
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateMyPrivacy = gql`
  mutation updateMyPrivacy($data: user_privacy_update_input) {
    updateMyPrivacy(data: $data) {
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateMyPhoto = gql`
  mutation updateMyPhoto($data: user_photo_update_input) {
    updateMyPhoto(data: $data) {
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const deleteMyPhoto = gql`
  mutation deleteMyPhoto($id: String!) {
    deleteMyPhoto(id: $id) {
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const blockMember = gql`
  mutation blockMember($id: String!) {
    blockMember(id: $id) {
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const unblockMember = gql`
  mutation unblockMember($id: String!) {
    unblockMember(id: $id) {
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const ignoreMember = gql`
  mutation ignoreMember($id: String!) {
    ignoreMember(id: $id) {
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const unignoreMember = gql`
  mutation unignoreMember($id: String!) {
    unignoreMember(id: $id) {
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const shortlistMember = gql`
  mutation shortlistMember($id: String!) {
    shortlistMember(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const unshortlistMember = gql`
  mutation unshortlistMember($id: String!) {
    unshortlistMember(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const getMyProfile = gql`
  query getMyUser {
    my_profile: getMyUser {
      my_root {
        profile_number
      }
      user_profile {
        name
        dob
        gender
        about_family
        created_for
        created_for
        complexion
        height
        weight
        marital_status
        body_type
        physical_status
        mother_tongue
        eating_habit
        drinking_habit
        smoking_habit
        religion
        caste
        sub_caste
        star
        raasi
        doshams
        gothram
        country
        state
        city
        district
        ancestor_origin
        citizenship
        education
        educations
        sub_education
        sub_educations
        institution
        employment_type
        sub_employment_type
        employer
        annual_income_from
        annual_income_to
        father_name
        mother_name
        family_value
        family_type
        family_status
        father_occupation
        mother_occupation
        number_of_brothers
        number_of_sisters
        number_of_married_brothers
        number_of_married_sisters
        number_of_children
      }
      user_photos {
        id
        is_main_photo
        verification_status
        photo_url
        cropped_photo_url
      }
      user_horoscope {
        time_of_birth
        place_of_birth
        birth_chart_url
        navamansha_chart_url
      }
      my_subscription {
        en_label
        ta_label
        allowed_contact_details_view
        viewed_contact_details_count
        subscription_till
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const getMyPhoto = gql`
  query getMyUser {
    my_photo: getMyUser {
      user_photos {
        id
        is_main_photo
        verification_status
        photo_url
        cropped_photo_url
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const getMyPrivacySettings = gql`
  query getMyUser {
    my_privacy_settings: getMyUser {
      user_privacy {
        show_photo
        show_horoscope
        show_contact_details
        show_only_for_marital_statuses
        show_only_for_religions
        show_only_for_castes
        show_only_for_sub_castes
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const getMyPreference = gql`
  query getMyUser {
    my_preference: getMyUser {
      user_preference {
        age_from
        age_to
        height_from
        height_to
        annual_income_from
        annual_income_to
        marital_statuses
        body_types
        physical_statuses
        mother_tongues
        eating_habits
        drinking_habits
        smoking_habits
        religions
        castes
        sub_castes
        stars
        raasis
        doshams
        countries
        states
        cities
        districts
        educations
        employment_types
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const getMyPrivacy = gql`
  query getMyUser {
    my_privacy: getMyUser {
      user_privacy {
        show_photo
        show_horoscope
        show_contact_details
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const fileQuery = gql`
  query getUploadUrl($type: String, $fileName: String) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;

export const getMyBlockedProfiles = gql`
  query getMyUser {
    my_blocked_profile: getMyUser {
      my_blocked_members {
        id
        member_cover_photo {
          photo_url
          photo_view
          cropped_photo_url
        }
        member_profile {
          id
          name
          dob
          gender
        }
      }

      error {
        status_code
        message
      }
    }
  }
`;
export const getMyIgnoredProfiles = gql`
  query getMyUser {
    ignored_members: getMyUser {
      my_ignored_members {
        id

        member_root {
          profile_number
          last_login_datetime
        }
        member_cover_photo {
          photo_url
          photo_view
          cropped_photo_url
        }
        member_profile {
          id
          name
          dob
          gender
          religion
          caste
        }
      }

      error {
        status_code
        message
      }
    }
  }
`;

export const getVisitedMeProfiles = gql`
  query getMyUser {
    members_visited_me: getMyUser {
      members_visited_me_count
      members_visited_me {
        is_shortlisted
        member_interest_status {
          status
          request_id
        }
        id
        member_root {
          profile_number
          last_login_datetime
        }
        member_cover_photo {
          photo_url
          photo_view
          cropped_photo_url
        }
        member_profile {
          id
          name
          dob
          gender
          city
          district
          state
          country
          religion
          caste
        }
      }

      error {
        status_code
        message
      }
    }
  }
`;
export const getMyVisitsProfiles = gql`
  query getMyUser {
    my_visits_of_members: getMyUser {
      my_visits_of_members_count
      my_visits_of_members {
        is_shortlisted
        member_interest_status {
          status
          request_id
        }
        id
        member_root {
          profile_number
          last_login_datetime
        }
        member_cover_photo {
          photo_url
          photo_view
          cropped_photo_url
        }
        member_profile {
          id
          name
          dob
          gender
          city
          district
          state
          country
          religion
          caste
        }
      }

      error {
        status_code
        message
      }
    }
  }
`;
export const getMyShortlistedMembers = gql`
  query getMyUser {
    my_shortlists: getMyUser {
      my_shortlisted_members_count
      my_shortlisted_members {
        is_shortlisted
        member_interest_status {
          status
          request_id
        }
        id
        member_root {
          profile_number
          last_login_datetime
        }
        member_cover_photo {
          photo_url
          photo_view
          cropped_photo_url
        }
        member_profile {
          id
          name
          dob
          gender
          city
          district
          state
          country
          religion
          caste
        }
      }

      error {
        status_code
        message
      }
    }
  }
`;
export const getShortlistedMe = gql`
  query getMyUser {
    short_listed_me: getMyUser {
      shortlisted_me_count
      shortlisted_me {
        is_shortlisted
        member_interest_status {
          status
          request_id
        }
        id
        member_root {
          profile_number
          last_login_datetime
        }
        member_cover_photo {
          photo_url
          photo_view
          cropped_photo_url
        }
        member_profile {
          id
          name
          dob
          gender
          city
          district
          state
          country
          religion
          caste
        }
      }

      error {
        status_code
        message
      }
    }
  }
`;

export const getMyPlan = gql`
  query getMyUser {
    my_plan: getMyUser {
      user_profile {
        gender
      }
      my_subscription {
        en_label
        ta_label
        allowed_contact_details_view
        viewed_contact_details_count
        subscription_till
      }
      error {
        status_code
        message
      }
    }
  }
`;

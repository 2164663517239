import { Box, Text, CloseIcon, Pressable, Image } from "native-base";
import React from "react";

const PopupAd = (props) => {
  const { url, onClose } = props;
  return (
    <Box
      width={"50%"}
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          backgroundColor: "white",
          borderRadius: 10,
        }}
      >
        <Image
          width={"xl"}
          height={"xl"}
          source={{
            uri: url,
          }}
          alt="Ad"
        />
        <Pressable
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 3,
          }}
          onPress={onClose}
        >
          <CloseIcon size={6} color="#111" />
        </Pressable>
      </Box>
    </Box>
  );
};

export default PopupAd;

import React from "react";
import { Layout } from "antd";
const { Content } = Layout;
import { Scrollbars } from "react-custom-scrollbars-2";

const SuperAdminLayoutContent = ({ children }) => {
  return (
    <Layout>
      <Content style={{ backgroundColor: "#191919" }}>
        <Scrollbars
          id="sbSuperAdminContent"
          style={{ width: "100%", overflow: "hidden" }}
          renderThumbVertical={(props) => (
            <div {...props} className="sbContentHolder-thumb-vertical" />
          )}
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              style={{ display: "none" }}
              className="track-horizontal"
            />
          )}
          renderView={(props) => (
            <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
          )}
        >
          {children}
        </Scrollbars>
      </Content>
    </Layout>
  );
};
export default SuperAdminLayoutContent;

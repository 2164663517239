import UserHomeBanner from "@views/components/banner/user_home_banner";
import UserCover from "@views/components/user_home/user_cover";
import {
  Box,
  Hidden,
  HStack,
  Pressable,
  Skeleton,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useState } from "react";
import MembersHorizontalList from "@views/components/member/members_horizontal_list";
import { navigate, navigateBack } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { clearLocalStorage, retrieveItem, storeItem } from "@helpers/storage";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  getCreatedOptions,
  getMatches,
  useDynamicSelector,
} from "@services/redux";
import OperatorHomeCover from "@views/components/operator_web/operator_home/operator_home_cover";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Input } from "antd";
import OtpForm from "@views/components/auth/otp_form";
import RegisterInOperator from "@views/components/auth/register_operator";
import { toast } from "react-hot-toast";
import OperatorLoginByUser from "@views/components/operator_web/operator_login_by_user";

const OperatorHome = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [matches_list, setMatchesList] = useState([]);
  const [visited_me, setVisitedMe] = useState([]);

  const [show_login_page, setShowLoginPage] = useState(false);
  const [show_register_page, setShowRegisterPage] = useState(false);

  const {
    items: all_matches_list,
    pagination: all_matches_count,
    loading: all_matches_loading,
  } = useDynamicSelector("matches");

  const {
    members_visited_me: members_visited_me_list,
    loading: members_visited_me_loading,
  } = useDynamicSelector("members_visited_me");

  const {
    error,
    loading: registerLoading,
    status: registerStatus,
    name: registeredUserName,
    profile_id,
    is_preference_added,
    is_profile_added,
    gender: user_gender,
    session_id: user_session_id,
  } = useDynamicSelector("register_user");

  const handleRegisterPageVisible = (val) => {
    setShowRegisterPage(val);
  };
  const handleLoginPageVisible = (val) => {
    setShowLoginPage(val);
  };

  useEffect(() => {
    storeItem("name", registeredUserName);
    storeItem("user_session_id", user_session_id);

    storeItem("profile_id", profile_id);
    storeItem("gender", user_gender);

    if (error) {
      toast.error(error.message);
      dispatch(dynamicClear("register_user"));
    }
    if (user_session_id) {
      if (is_preference_added) {
        // navigate(ROUTES.USER_HOME);
        toast.success(`Welcome ${registeredUserName}`);
      } else if (is_profile_added) {
        // navigate(ROUTES.USER_MANAGE_PREFERENCE);
      } else {
        navigate("/operator/matches");
      }
    }
  }, [user_session_id, error, registeredUserName, profile_id]);

  useEffect(() => {
    if (registerStatus === "success") {
      toast.success(t("registered_successfully"));
      dispatch(dynamicClear("register_user"));
    }
  }, [registerStatus]);

  useEffect(() => {
    if (all_matches_list || members_visited_me_list) {
      setMatchesList(all_matches_list);
      setVisitedMe(members_visited_me_list);
    }
  }, [all_matches_list, members_visited_me_list]);

  const getCreatedForOptions = () => {
    let key = [{ key: "getCreatedForElementOptions", loading: true }];
    let query = getCreatedOptions;
    let variables = null;
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    dispatch(dynamicClear("updateMyPreference"));
  }, []);

  const handleLogout = () => {
    dispatch(dynamicClear("admin_login"));
    clearLocalStorage();
    navigate(ROUTES.ADMIN_LOGIN);
  };
  useEffect(() => {
    function handleBeforeUnload(event) {
      event.preventDefault();
      event.returnValue = "";
    }

    function handlePopState(event) {
      if (window.confirm("Are you sure you want to logout?")) {
        // window.history.back();
        handleLogout();
      } else {
        window.history.pushState(null, null, window.location.href);
      }
    }

    window.onbeforeunload = handleBeforeUnload;
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = handlePopState;

    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
    };
  }, []);

  return (
    <Box flex={1}>
      <HStack width={"100%"}>
        <Box
          height={"100vh"}
          width={{
            base: 300,
            md: 300,
            lg: 300,
            xl: 300,
            "2xl": 300,
            "3xl": 300,
          }}
        >
          <OperatorHomeCover
            matches_list={matches_list}
            visited_me={visited_me}
            handleRegisterPageVisible={handleRegisterPageVisible}
            handleLoginPageVisible={handleLoginPageVisible}
          />
        </Box>

        <Box flex={1}>
          {show_register_page && <RegisterInOperator />}
          {show_login_page && <OperatorLoginByUser />}
        </Box>
      </HStack>
    </Box>
  );
};
export default OperatorHome;

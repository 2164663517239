export const MotherTongueOptions = [
  {
    value: "any",
    en_label: "Any",
    ta_label: "ஏதேனும்",
    p_o: [],
  },
  {
    value: "tamil",
    en_label: "Tamil",
    ta_label: "தமிழ்",
    p_o: [],
  },
  {
    value: "telugu_tamilnadu",
    en_label: "Telugu (Tamilnadu)",
    ta_label: "தெலுங்கு ( தமிழ்நாடு )",
    p_o: [],
  },
  {
    value: "kannadam_tamilnadu",
    en_label: "Kannadam ( Tamilnadu )",
    ta_label: "கன்னடம் ( தமிழ்நாடு )",
    p_o: [],
  },
  {
    value: "urdu",
    en_label: "Urdu",
    ta_label: "உருது",
    p_o: [],
  },
  {
    value: "malayalam",
    en_label: "Malayalam",
    ta_label: "மலையாளம்",
    p_o: [],
  },
  {
    value: "hindi",
    en_label: "Hindi",
    ta_label: "ஹிந்தி",
    p_o: [],
  },
  {
    value: "saurashtra",
    en_label: "Saurashtra",
    ta_label: "சௌராஷ்டிரா",
    p_o: [],
  },
];

export default {
  normal: {
    objectFit: "cover",
  },
  hover: {
    objectFit: "cover",
    backgroundColor: "#712e5a",
    opacity: 0.1,
    color: "#111",
  },
};

import { dynamicRequest, getCreatedOptions } from "@services/redux";
import RegisterComponent from "@views/components/auth/register";
import { Box, Pressable, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
const Register = (props) => {
  const dispatch = useDispatch();

  return (
    <Box backgroundColor={"#712e5a"} flex={1}>
      <RegisterComponent />
    </Box>
  );
};
export default Register;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, HStack } from "native-base";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import {
  dynamicRequest,
  getMember,
  getMemberForAdmin,
  useDynamicSelector,
} from "@services/redux";
import { Button } from "antd";
import MemberDetailsCardViewAdmin from "../components/admin_verification/member_details_card_view_in_admin";
import UserPhotos from "@views/components/user_manage_profile/user_photos";

const UserDetailsForAdmin = () => {
  const dispatch = useDispatch();
  const { user_id } = useParams();

  const [actionItem, setActionItem] = useState({});

  const { t } = useTranslation();

  const getMemberById = () => {
    let key = [{ key: "member_for_admin", loading: true }];
    let query = getMemberForAdmin;
    let variables = { id: user_id };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getMemberById();
  }, [user_id]);

  return (
    <>
      <Box width="100%">
        <MemberDetailsCardViewAdmin />
      </Box>
    </>
  );
};
export default UserDetailsForAdmin;

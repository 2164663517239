export const CountriesOptions = [
  {
    i_c: "AF",
    name: "Afghanistan",
  },
  {
    i_c: "AX",
    name: "Aland Islands",
  },
  {
    i_c: "AL",
    name: "Albania",
  },
  {
    i_c: "DZ",
    name: "Algeria",
  },
  {
    i_c: "AS",
    name: "American Samoa",
  },
  {
    i_c: "AD",
    name: "Andorra",
  },
  {
    i_c: "AO",
    name: "Angola",
  },
  {
    i_c: "AI",
    name: "Anguilla",
  },
  {
    i_c: "AQ",
    name: "Antarctica",
  },
  {
    i_c: "AG",
    name: "Antigua And Barbuda",
  },
  {
    i_c: "AR",
    name: "Argentina",
  },
  {
    i_c: "AM",
    name: "Armenia",
  },
  {
    i_c: "AW",
    name: "Aruba",
  },
  {
    i_c: "AU",
    name: "Australia",
  },
  {
    i_c: "AT",
    name: "Austria",
  },
  {
    i_c: "AZ",
    name: "Azerbaijan",
  },
  {
    i_c: "BS",
    name: "The Bahamas",
  },
  {
    i_c: "BH",
    name: "Bahrain",
  },
  {
    i_c: "BD",
    name: "Bangladesh",
  },
  {
    i_c: "BB",
    name: "Barbados",
  },
  {
    i_c: "BY",
    name: "Belarus",
  },
  {
    i_c: "BE",
    name: "Belgium",
  },
  {
    i_c: "BZ",
    name: "Belize",
  },
  {
    i_c: "BJ",
    name: "Benin",
  },
  {
    i_c: "BM",
    name: "Bermuda",
  },
  {
    i_c: "BT",
    name: "Bhutan",
  },
  {
    i_c: "BO",
    name: "Bolivia",
  },
  {
    i_c: "BA",
    name: "Bosnia and Herzegovina",
  },
  {
    i_c: "BW",
    name: "Botswana",
  },
  {
    i_c: "BV",
    name: "Bouvet Island",
  },
  {
    i_c: "BR",
    name: "Brazil",
  },
  {
    i_c: "IO",
    name: "British Indian Ocean Territory",
  },
  {
    i_c: "BN",
    name: "Brunei",
  },
  {
    i_c: "BG",
    name: "Bulgaria",
  },
  {
    i_c: "BF",
    name: "Burkina Faso",
  },
  {
    i_c: "BI",
    name: "Burundi",
  },
  {
    i_c: "KH",
    name: "Cambodia",
  },
  {
    i_c: "CM",
    name: "Cameroon",
  },
  {
    i_c: "CA",
    name: "Canada",
  },
  {
    i_c: "CV",
    name: "Cape Verde",
  },
  {
    i_c: "KY",
    name: "Cayman Islands",
  },
  {
    i_c: "CF",
    name: "Central African Republic",
  },
  {
    i_c: "TD",
    name: "Chad",
  },
  {
    i_c: "CL",
    name: "Chile",
  },
  {
    i_c: "CN",
    name: "China",
  },
  {
    i_c: "CX",
    name: "Christmas Island",
  },
  {
    i_c: "CC",
    name: "Cocos (Keeling) Islands",
  },
  {
    i_c: "CO",
    name: "Colombia",
  },
  {
    i_c: "KM",
    name: "Comoros",
  },
  {
    i_c: "CG",
    name: "Congo",
  },
  {
    i_c: "CD",
    name: "Democratic Republic of the Congo",
  },
  {
    i_c: "CK",
    name: "Cook Islands",
  },
  {
    i_c: "CR",
    name: "Costa Rica",
  },
  {
    i_c: "CI",
    name: "Cote D'Ivoire (Ivory Coast)",
  },
  {
    i_c: "HR",
    name: "Croatia",
  },
  {
    i_c: "CU",
    name: "Cuba",
  },
  {
    i_c: "CY",
    name: "Cyprus",
  },
  {
    i_c: "CZ",
    name: "Czech Republic",
  },
  {
    i_c: "DK",
    name: "Denmark",
  },
  {
    i_c: "DJ",
    name: "Djibouti",
  },
  {
    i_c: "DM",
    name: "Dominica",
  },
  {
    i_c: "DO",
    name: "Dominican Republic",
  },
  {
    i_c: "TL",
    name: "East Timor",
  },
  {
    i_c: "EC",
    name: "Ecuador",
  },
  {
    i_c: "EG",
    name: "Egypt",
  },
  {
    i_c: "SV",
    name: "El Salvador",
  },
  {
    i_c: "GQ",
    name: "Equatorial Guinea",
  },
  {
    i_c: "ER",
    name: "Eritrea",
  },
  {
    i_c: "EE",
    name: "Estonia",
  },
  {
    i_c: "ET",
    name: "Ethiopia",
  },
  {
    i_c: "FK",
    name: "Falkland Islands",
  },
  {
    i_c: "FO",
    name: "Faroe Islands",
  },
  {
    i_c: "FJ",
    name: "Fiji Islands",
  },
  {
    i_c: "FI",
    name: "Finland",
  },
  {
    i_c: "FR",
    name: "France",
  },
  {
    i_c: "GF",
    name: "French Guiana",
  },
  {
    i_c: "PF",
    name: "French Polynesia",
  },
  {
    i_c: "TF",
    name: "French Southern Territories",
  },
  {
    i_c: "GA",
    name: "Gabon",
  },
  {
    i_c: "GM",
    name: "The Gambia",
  },
  {
    i_c: "GE",
    name: "Georgia",
  },
  {
    i_c: "DE",
    name: "Germany",
  },
  {
    i_c: "GH",
    name: "Ghana",
  },
  {
    i_c: "GI",
    name: "Gibraltar",
  },
  {
    i_c: "GR",
    name: "Greece",
  },
  {
    i_c: "GL",
    name: "Greenland",
  },
  {
    i_c: "GD",
    name: "Grenada",
  },
  {
    i_c: "GP",
    name: "Guadeloupe",
  },
  {
    i_c: "GU",
    name: "Guam",
  },
  {
    i_c: "GT",
    name: "Guatemala",
  },
  {
    i_c: "GG",
    name: "Guernsey and Alderney",
  },
  {
    i_c: "GN",
    name: "Guinea",
  },
  {
    i_c: "GW",
    name: "Guinea-Bissau",
  },
  {
    i_c: "GY",
    name: "Guyana",
  },
  {
    i_c: "HT",
    name: "Haiti",
  },
  {
    i_c: "HM",
    name: "Heard Island and McDonald Islands",
  },
  {
    i_c: "HN",
    name: "Honduras",
  },
  {
    i_c: "HK",
    name: "Hong Kong S.A.R.",
  },
  {
    i_c: "HU",
    name: "Hungary",
  },
  {
    i_c: "IS",
    name: "Iceland",
  },
  {
    i_c: "IN",
    name: "India",
  },
  {
    i_c: "ID",
    name: "Indonesia",
  },
  {
    i_c: "IR",
    name: "Iran",
  },
  {
    i_c: "IQ",
    name: "Iraq",
  },
  {
    i_c: "IE",
    name: "Ireland",
  },
  {
    i_c: "IL",
    name: "Israel",
  },
  {
    i_c: "IT",
    name: "Italy",
  },
  {
    i_c: "JM",
    name: "Jamaica",
  },
  {
    i_c: "JP",
    name: "Japan",
  },
  {
    i_c: "JE",
    name: "Jersey",
  },
  {
    i_c: "JO",
    name: "Jordan",
  },
  {
    i_c: "KZ",
    name: "Kazakhstan",
  },
  {
    i_c: "KE",
    name: "Kenya",
  },
  {
    i_c: "KI",
    name: "Kiribati",
  },
  {
    i_c: "KP",
    name: "North Korea",
  },
  {
    i_c: "KR",
    name: "South Korea",
  },
  {
    i_c: "KW",
    name: "Kuwait",
  },
  {
    i_c: "KG",
    name: "Kyrgyzstan",
  },
  {
    i_c: "LA",
    name: "Laos",
  },
  {
    i_c: "LV",
    name: "Latvia",
  },
  {
    i_c: "LB",
    name: "Lebanon",
  },
  {
    i_c: "LS",
    name: "Lesotho",
  },
  {
    i_c: "LR",
    name: "Liberia",
  },
  {
    i_c: "LY",
    name: "Libya",
  },
  {
    i_c: "LI",
    name: "Liechtenstein",
  },
  {
    i_c: "LT",
    name: "Lithuania",
  },
  {
    i_c: "LU",
    name: "Luxembourg",
  },
  {
    i_c: "MO",
    name: "Macau S.A.R.",
  },
  {
    i_c: "MK",
    name: "Macedonia",
  },
  {
    i_c: "MG",
    name: "Madagascar",
  },
  {
    i_c: "MW",
    name: "Malawi",
  },
  {
    i_c: "MY",
    name: "Malaysia",
  },
  {
    i_c: "MV",
    name: "Maldives",
  },
  {
    i_c: "ML",
    name: "Mali",
  },
  {
    i_c: "MT",
    name: "Malta",
  },
  {
    i_c: "IM",
    name: "Man (Isle of)",
  },
  {
    i_c: "MH",
    name: "Marshall Islands",
  },
  {
    i_c: "MQ",
    name: "Martinique",
  },
  {
    i_c: "MR",
    name: "Mauritania",
  },
  {
    i_c: "MU",
    name: "Mauritius",
  },
  {
    i_c: "YT",
    name: "Mayotte",
  },
  {
    i_c: "MX",
    name: "Mexico",
  },
  {
    i_c: "FM",
    name: "Micronesia",
  },
  {
    i_c: "MD",
    name: "Moldova",
  },
  {
    i_c: "MC",
    name: "Monaco",
  },
  {
    i_c: "MN",
    name: "Mongolia",
  },
  {
    i_c: "ME",
    name: "Montenegro",
  },
  {
    i_c: "MS",
    name: "Montserrat",
  },
  {
    i_c: "MA",
    name: "Morocco",
  },
  {
    i_c: "MZ",
    name: "Mozambique",
  },
  {
    i_c: "MM",
    name: "Myanmar",
  },
  {
    i_c: "NA",
    name: "Namibia",
  },
  {
    i_c: "NR",
    name: "Nauru",
  },
  {
    i_c: "NP",
    name: "Nepal",
  },
  {
    i_c: "BQ",
    name: "Bonaire, Sint Eustatius and Saba",
  },
  {
    i_c: "NL",
    name: "Netherlands",
  },
  {
    i_c: "NC",
    name: "New Caledonia",
  },
  {
    i_c: "NZ",
    name: "New Zealand",
  },
  {
    i_c: "NI",
    name: "Nicaragua",
  },
  {
    i_c: "NE",
    name: "Niger",
  },
  {
    i_c: "NG",
    name: "Nigeria",
  },
  {
    i_c: "NU",
    name: "Niue",
  },
  {
    i_c: "NF",
    name: "Norfolk Island",
  },
  {
    i_c: "MP",
    name: "Northern Mariana Islands",
  },
  {
    i_c: "NO",
    name: "Norway",
  },
  {
    i_c: "OM",
    name: "Oman",
  },
  {
    i_c: "PK",
    name: "Pakistan",
  },
  {
    i_c: "PW",
    name: "Palau",
  },
  {
    i_c: "PS",
    name: "Palestinian Territory Occupied",
  },
  {
    i_c: "PA",
    name: "Panama",
  },
  {
    i_c: "PG",
    name: "Papua new Guinea",
  },
  {
    i_c: "PY",
    name: "Paraguay",
  },
  {
    i_c: "PE",
    name: "Peru",
  },
  {
    i_c: "PH",
    name: "Philippines",
  },
  {
    i_c: "PN",
    name: "Pitcairn Island",
  },
  {
    i_c: "PL",
    name: "Poland",
  },
  {
    i_c: "PT",
    name: "Portugal",
  },
  {
    i_c: "PR",
    name: "Puerto Rico",
  },
  {
    i_c: "QA",
    name: "Qatar",
  },
  {
    i_c: "RE",
    name: "Reunion",
  },
  {
    i_c: "RO",
    name: "Romania",
  },
  {
    i_c: "RU",
    name: "Russia",
  },
  {
    i_c: "RW",
    name: "Rwanda",
  },
  {
    i_c: "SH",
    name: "Saint Helena",
  },
  {
    i_c: "KN",
    name: "Saint Kitts And Nevis",
  },
  {
    i_c: "LC",
    name: "Saint Lucia",
  },
  {
    i_c: "PM",
    name: "Saint Pierre and Miquelon",
  },
  {
    i_c: "VC",
    name: "Saint Vincent And The Grenadines",
  },
  {
    i_c: "BL",
    name: "Saint-Barthelemy",
  },
  {
    i_c: "MF",
    name: "Saint-Martin (French part)",
  },
  {
    i_c: "WS",
    name: "Samoa",
  },
  {
    i_c: "SM",
    name: "San Marino",
  },
  {
    i_c: "ST",
    name: "Sao Tome and Principe",
  },
  {
    i_c: "SA",
    name: "Saudi Arabia",
  },
  {
    i_c: "SN",
    name: "Senegal",
  },
  {
    i_c: "RS",
    name: "Serbia",
  },
  {
    i_c: "SC",
    name: "Seychelles",
  },
  {
    i_c: "SL",
    name: "Sierra Leone",
  },
  {
    i_c: "SG",
    name: "Singapore",
  },
  {
    i_c: "SK",
    name: "Slovakia",
  },
  {
    i_c: "SI",
    name: "Slovenia",
  },
  {
    i_c: "SB",
    name: "Solomon Islands",
  },
  {
    i_c: "SO",
    name: "Somalia",
  },
  {
    i_c: "ZA",
    name: "South Africa",
  },
  {
    i_c: "GS",
    name: "South Georgia",
  },
  {
    i_c: "SS",
    name: "South Sudan",
  },
  {
    i_c: "ES",
    name: "Spain",
  },
  {
    i_c: "LK",
    name: "Sri Lanka",
  },
  {
    i_c: "SD",
    name: "Sudan",
  },
  {
    i_c: "SR",
    name: "Suriname",
  },
  {
    i_c: "SJ",
    name: "Svalbard And Jan Mayen Islands",
  },
  {
    i_c: "SZ",
    name: "Swaziland",
  },
  {
    i_c: "SE",
    name: "Sweden",
  },
  {
    i_c: "CH",
    name: "Switzerland",
  },
  {
    i_c: "SY",
    name: "Syria",
  },
  {
    i_c: "TW",
    name: "Taiwan",
  },
  {
    i_c: "TJ",
    name: "Tajikistan",
  },
  {
    i_c: "TZ",
    name: "Tanzania",
  },
  {
    i_c: "TH",
    name: "Thailand",
  },
  {
    i_c: "TG",
    name: "Togo",
  },
  {
    i_c: "TK",
    name: "Tokelau",
  },
  {
    i_c: "TO",
    name: "Tonga",
  },
  {
    i_c: "TT",
    name: "Trinidad And Tobago",
  },
  {
    i_c: "TN",
    name: "Tunisia",
  },
  {
    i_c: "TR",
    name: "Turkey",
  },
  {
    i_c: "TM",
    name: "Turkmenistan",
  },
  {
    i_c: "TC",
    name: "Turks And Caicos Islands",
  },
  {
    i_c: "TV",
    name: "Tuvalu",
  },
  {
    i_c: "UG",
    name: "Uganda",
  },
  {
    i_c: "UA",
    name: "Ukraine",
  },
  {
    i_c: "AE",
    name: "United Arab Emirates",
  },
  {
    i_c: "GB",
    name: "United Kingdom",
  },
  {
    i_c: "US",
    name: "United States",
  },
  {
    i_c: "UM",
    name: "United States Minor Outlying Islands",
  },
  {
    i_c: "UY",
    name: "Uruguay",
  },
  {
    i_c: "UZ",
    name: "Uzbekistan",
  },
  {
    i_c: "VU",
    name: "Vanuatu",
  },
  {
    i_c: "VA",
    name: "Vatican City State (Holy See)",
  },
  {
    i_c: "VE",
    name: "Venezuela",
  },
  {
    i_c: "VN",
    name: "Vietnam",
  },
  {
    i_c: "VG",
    name: "Virgin Islands (British)",
  },
  {
    i_c: "VI",
    name: "Virgin Islands (US)",
  },
  {
    i_c: "WF",
    name: "Wallis And Futuna Islands",
  },
  {
    i_c: "EH",
    name: "Western Sahara",
  },
  {
    i_c: "YE",
    name: "Yemen",
  },
  {
    i_c: "ZM",
    name: "Zambia",
  },
  {
    i_c: "ZW",
    name: "Zimbabwe",
  },
  {
    i_c: "XK",
    name: "Kosovo",
  },
  {
    i_c: "CW",
    name: "Curaçao",
  },
  {
    i_c: "SX",
    name: "Sint Maarten (Dutch part)",
  },
];

import { gql } from "@apollo/client";

export const interest_count_query = gql`
  query getMemberRequestCount(
    $page_number: Float
    $page_limit: Float
    $interval: String
  ) {
    getMemberRequestCount(
      page_number: $page_number
      page_limit: $page_limit
      interval: $interval
    ) {
      items {
        id
        profile_id
        name
        gender
        country
        state
        district
        pincode
        mobile
        franchise_name
        dob
        created_at
        received_request_count
        sent_request_count
        accept_request_count
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const interest_count_report_query = gql`
  query getMemberRequestCount(
    $page_number: Float
    $page_limit: Float
    $interval: String
  ) {
    interest_count_report: getMemberRequestCount(
      page_number: $page_number
      page_limit: $page_limit
      interval: $interval
    ) {
      items {
        id
        profile_id
        name
        gender
        country
        state
        district
        pincode
        mobile
        franchise_name
        dob
        created_at
        received_request_count
        sent_request_count
        accept_request_count
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

import React, { useEffect } from "react";
import { useState } from "react";

import { Box, HStack } from "native-base";
import { Form, InputNumber, Switch, Input, Button, Divider } from "antd";
import "antd/dist/antd.css"; // Make sure to include Ant Design CSS
import VForm from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  create_new_version,
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { toast } from "react-hot-toast";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const AppVersionDetails = (props) => {
  const dispatch = useDispatch();
  const { close, initialValues, readOnly, actionType, get_all_version_query } =
    props;
  console.log("readOnly:", readOnly);
  console.log("actionType:", actionType);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { TextArea } = Input;

  const [textAreas, setTextAreas] = useState([{ key: "0" }]);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const {
    status: create_version_status,
    error: create_version_error,
    loading,
  } = useDynamicSelector("createVersion");
  console.log("create_version_status", create_version_status);
  const handleToggleDescription = () => {
    setIsDescriptionVisible(!isDescriptionVisible);
  };

  const onFinish = (values) => {
    console.log("Form values:", values);
    let key = [{ key: "createVersion", loading: true }];
    let query = create_new_version;
    let variable = {
      json: {
        version: values.version,
        is_mandatory: values.is_mandatory,
        current_changes: values.current_changes,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };
  useEffect(() => {
    if (create_version_status === "success") {
      toast.success(t("app_version_added_successfully"));
      close();
      get_all_version_query();
      dispatch(dynamicClear("createVersion"));
    } else if (create_version_error === "failure") {
      toast.error(t("Not Found"));
      dispatch(dynamicClear("createVersion"));
    } else if (create_version_error) {
      // Define the 'error' variable here or use 'create_version_error' as needed
      const error = create_version_error;
      toast.error(error?.message);
      dispatch(dynamicClear("createVersion"));
    } else {
      // Handle unexpected or undefined situations here
      console.error("Unhandled error case");
      dispatch(dynamicClear("createVersion"));
    }
  }, [create_version_status, create_version_error]);
  return (
    <Box flex={1}>
      <Form
        form={form}
        name="app_versions"
        layout="vertical"
        // name="dynamic_form_item"

        onFinish={onFinish}
        initialValues={initialValues}
        className="user_form ant-select-selector ant-select-selection-search"
      >
        <VForm.Number
          label={t("version")}
          field="version"
          rules={[
            {
              required: true,
              message: t("version_is_required"),
            },
          ]}
          disabled={actionType === "info" && readOnly}
        />
        <VForm.Switch
          label={t("is_mandatory")}
          field="is_mandatory"
          rules={[
            {
              required: true,
              message: t("is_mandatory is required"),
            },
          ]}
          disabled={actionType === "info"}
        />
        <Form.List name="current_changes">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    {/* <Divider>Field {index + 1}</Divider> */}
                    <Form.Item
                      name={[index]}
                      label="Description"
                      rules={[
                        { required: true, message: "Description is required" },
                      ]}
                    >
                      <TextArea disabled={actionType === "info"} />
                    </Form.Item>

                    {actionType !== "info" && fields.length > 1 ? (
                      <Button
                        style={{ marginBottom: "10px" }}
                        type="danger"
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                        icon={<MinusCircleOutlined />}
                      >
                        Remove
                      </Button>
                    ) : null}
                  </div>
                ))}
                {actionType !== "info" && (
                  <>
                    <Divider />

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: "100%" }}
                      >
                        <PlusOutlined /> Add
                      </Button>
                    </Form.Item>
                  </>
                )}
              </div>
            );
          }}
        </Form.List>
        <HStack space="3" justifyContent="flex-end">
          <Button onClick={() => close()}>{t("close")}</Button>
          {actionType !== "info" && (
            <VForm.Button loading={loading}>{t("Submit")} </VForm.Button>
          )}
        </HStack>
      </Form>
    </Box>
  );
};
export default AppVersionDetails;

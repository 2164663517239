import { gql } from "@apollo/client";

export const get_all_verifier_query = gql`
  query getVerifiers($json: get_all_verifier_input) {
    get_all_verifiers: getVerifiers(json: $json) {
      items { 
        id
        name
        mobile
        username 
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;


export const create_verifier_mutation = gql`
  mutation createVerifier($json: create_verifier_input) {
    create_verifier: createVerifier(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_verifier_mutation = gql`
  mutation updateVerifier($json: update_verifier_input) {
    update_verifier: updateVerifier(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_verifier_mutation = gql`
  mutation deleteVerifier($json: delete_verifier_input) {
    delete_verifier: deleteVerifier(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;